import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { staffTableColumns } from '../../../utils/data';
import { IUser, UserStatusENUM } from '../../../utils/types';
import { getDate, normalizePhone, normalizeStatusAndPosition } from '../../../utils/functions';
import { selectStaff } from '../../../store/slices/staffSlice';
import { userSelector } from '../../../store/slices/userSlice';
import GearsIcon from '../../../assets/images/clients/gears-icon.png';
import AddUser from '../../../components/AddUser/AddUser';
import StyledClientsTable from './StaffTable.style';

const ClientsTable = () => {
    const { allUsers } = useSelector(selectStaff);
    const { user } = useSelector(userSelector);
    const [selectedUser, setSelectedUser] = useState<null | IUser>(null);

    return (
        <StyledClientsTable>
            <div className="users-table__header">
                {staffTableColumns.map((column) => (
                    <div key={column.label} className="users-table__column-name">
                        <p>{column.label}</p>
                    </div>
                ))}
            </div>
            <div className="users-table__body">
                {allUsers.map((employee, index) => (
                    <div
                        key={employee.userId}
                        className={clsx('users-table__user', {
                            'users-table__even-field': index % 2 !== 0,
                            'users-table__suspended-user':
                                employee.status === UserStatusENUM.suspended,
                        })}
                    >
                        <p>{getDate(employee.createdAt || '', 'date')}</p>
                        <div>
                            <p>
                                {employee.fullName ||
                                    employee.shortName ||
                                    employee.login ||
                                    employee.email}
                            </p>
                        </div>
                        <p>{normalizeStatusAndPosition(employee.positionName || '')}</p>
                        <p>{normalizePhone(employee.phone || '')}</p>
                        <p>{employee.additNumber || ''}</p>
                        <p>{employee.email || ''}</p>
                        <p>{getDate(employee.birthDate, 'date')}</p>
                        <p className={clsx({ 'users-table__bold-text': employee.bot })}>
                            {employee.bot ? 'Вкл.' : 'Выкл.'}
                        </p>
                        <p
                            className={clsx({
                                'users-table__bold-text': employee.isCallRecordEnabled,
                            })}
                        >
                            {employee.isCallRecordEnabled ? 'Вкл.' : 'Выкл.'}
                        </p>
                        <p>{normalizeStatusAndPosition(employee.status || '')}</p>
                        {user?.isAdmin ? (
                            <img
                                onClick={() => setSelectedUser(employee)}
                                className="users-table__next-contact"
                                src={GearsIcon}
                            />
                        ) : null}
                    </div>
                ))}
            </div>
            {selectedUser ? (
                <AddUser close={() => setSelectedUser(null)} width={440} user={selectedUser} />
            ) : null}
        </StyledClientsTable>
    );
};

export default ClientsTable;
