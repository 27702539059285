/* eslint-disable no-unused-vars */
import {
    BankDocumentSectionTypes,
    BankProductCreateInterface,
    BankProductType,
    CallDirectionType,
    CashbackStatusType,
    FederalLawsTypes,
    lawFieldsType,
    LoanProductENUM,
    PartnerDocArrayTypes,
    PartnerFileTypes,
    PartnerSwitcherTypes,
    sortingFields,
    TaskCloseReasonsTypes,
    TaskImportanceTypes,
    TaskOfferStatusTypes,
    TaskProductType,
    TaskStatusTypes,
    UserDocTypes,
} from './types';
import BellIcon from '../assets/images/bell-icon.png';
import TopMarkIcon from '../assets/images/top-icon.png';
import FtsMarkIcon from '../assets/images/fts-icon.png';
import EyeIcon from '../assets/images/open-eye-icon.png';
import SelectedBellIcon from '../assets/images/selected-bell-icon.png';
import SelectedTopMarkIcon from '../assets/images/selected-top-icon.png';
import SelectedFtsMarkIcon from '../assets/images/selected-fts-icon.png';
import SelectedEyeIcon from '../assets/images/selected-open-eye-icon.png';

export type AdditionalMarkType = {
    searchParams: 'isMarked' | 'isHighlighted' | 'isFtsMarked' | 'hasReminder';
    name: 'top mark' | 'eye mark' | 'bell mark' | 'fts mark';
    fieldInCompany: 'isMarked' | 'isHighlighted' | 'reminders';
    icon: string;
    selectedIcon: string;
};

export const additionalMarks: AdditionalMarkType[] = [
    {
        searchParams: 'isMarked',
        name: 'top mark',
        fieldInCompany: 'isMarked',
        icon: TopMarkIcon,
        selectedIcon: SelectedTopMarkIcon,
    },
    {
        searchParams: 'isHighlighted',
        name: 'eye mark',
        fieldInCompany: 'isHighlighted',
        icon: EyeIcon,
        selectedIcon: SelectedEyeIcon,
    },
    {
        searchParams: 'hasReminder',
        name: 'bell mark',
        fieldInCompany: 'reminders',
        icon: BellIcon,
        selectedIcon: SelectedBellIcon,
    },
];

export type clientFilterFieldNameType =
    | 'contactDate'
    | 'region'
    | 'lastCommentDate'
    | 'importance'
    | 'manager'
    | 'isMarkSelected'
    | 'isWithDealOnly'
    | 'clientInterests';

export type clientFilterFieldType = {
    label: string;
    name: clientFilterFieldNameType;
    type: string;
    marks?: AdditionalMarkType[];
};

export const clientFilterFields: clientFilterFieldType[] = [
    {
        name: 'contactDate',
        label: 'Дата контакта',
        type: 'range',
    },
    {
        name: 'region',
        label: 'Регион',
        type: 'list',
    },
    {
        name: 'lastCommentDate',
        label: 'Дата последнего комментария (Дата п.к.)',
        type: 'range',
    },
    {
        name: 'importance',
        label: 'Важность',
        type: 'list',
    },
    {
        name: 'manager',
        label: 'Выбрать менеджера',
        type: 'list',
    },
    {
        name: 'isMarkSelected',
        label: 'Дополнительные метки',
        type: 'marks',
        marks: additionalMarks,
    },
    {
        name: 'clientInterests',
        label: 'Интересы клиента:',
        type: 'interests',
    },
    {
        name: 'isWithDealOnly',
        label: 'Только со сделками',
        type: 'toggle',
    },
];

export const clientDatabaseColumns: {
    name?: sortingFields;
    label: string;
    isSortingField: boolean;
}[] = [
    {
        name: 'contactDate',
        label: 'Дата контакта',
        isSortingField: true,
    },
    {
        label: 'Компания/ ИНН',
        isSortingField: false,
    },
    {
        name: 'region',
        label: 'Регион/город',
        isSortingField: true,
    },
    {
        name: 'clientTimezone',
        label: 'Время КЛ',
        isSortingField: true,
    },
    {
        name: 'lastCommentDate',
        label: 'Дата п.к.',
        isSortingField: true,
    },
    {
        name: 'clientIncome',
        label: 'Доход',
        isSortingField: true,
    },
    {
        name: 'doneTasks',
        label: 'БГ',
        isSortingField: true,
    },
    {
        name: 'importance',
        label: 'Важность',
        isSortingField: true,
    },
    {
        label: 'Менеджер',
        isSortingField: false,
    },
    {
        label: 'Интересы кл.',
        isSortingField: false,
    },
    {
        label: 'Доп метки',
        isSortingField: false,
    },
    {
        label: 'ДСК',
        isSortingField: false,
    },
];

export type FieldNameTypeInEditingClientForm =
    | 'inn'
    | 'name'
    | 'city'
    | 'addressLegal'
    | 'addressActual'
    | 'ogrn'
    | 'kpp'
    | 'okved'
    | 'headName'
    | 'headPost'
    | 'registrationDate';

export type FieldNameTypeInAddingClientForm =
    | 'inn'
    | 'name'
    | 'region'
    | 'city'
    | 'addressLegal'
    | 'addressActual'
    | 'ogrn'
    | 'kpp'
    | 'okved'
    | 'headName'
    | 'headPost'
    | 'contactPhone'
    | 'email'
    | 'comment'
    | 'registrationDate'
    | 'phones'
    | 'emails';

export type FieldsInAddingClientFormType = {
    label: string;
    isRequired: boolean;
    fieldName: FieldNameTypeInAddingClientForm;
    type: 'input' | 'dataset' | 'textarea' | 'date';
    isEditable: boolean;
};

export const fieldsInAddingClientForm: FieldsInAddingClientFormType[] = [
    {
        label: 'Введите ИНН компании',
        isRequired: true,
        fieldName: 'inn',
        type: 'input',
        isEditable: true,
    },
    {
        label: 'Наименование организации (ИП)',
        isRequired: true,
        fieldName: 'name',
        type: 'input',
        isEditable: true,
    },
    {
        label: 'Выберите регион',
        isRequired: true,
        fieldName: 'region',
        type: 'dataset',
        isEditable: true,
    },
    {
        label: 'Выберите город',
        isRequired: true,
        fieldName: 'city',
        type: 'dataset',
        isEditable: true,
    },
    {
        label: 'Юридический адрес',
        isRequired: true,
        fieldName: 'addressLegal',
        type: 'input',
        isEditable: true,
    },
    {
        label: 'Фактический адрес',
        isRequired: true,
        fieldName: 'addressActual',
        type: 'input',
        isEditable: true,
    },
    {
        label: 'ОГРН (ОГРНИП)',
        isRequired: true,
        fieldName: 'ogrn',
        type: 'input',
        isEditable: true,
    },
    {
        label: 'Дата регистрации компании',
        isRequired: false,
        fieldName: 'registrationDate',
        type: 'date',
        isEditable: true,
    },
    {
        label: 'КПП',
        isRequired: true,
        fieldName: 'kpp',
        type: 'input',
        isEditable: true,
    },
    {
        label: 'ОКВЭД',
        isRequired: true,
        fieldName: 'okved',
        type: 'input',
        isEditable: true,
    },
    {
        label: 'ФИО Руководителя',
        isRequired: true,
        fieldName: 'headName',
        type: 'input',
        isEditable: true,
    },
    {
        label: 'Должность руководителя',
        isRequired: true,
        fieldName: 'headPost',
        type: 'input',
        isEditable: true,
    },
    {
        label: 'Контактный номер телефона',
        isRequired: false,
        fieldName: 'contactPhone',
        type: 'input',
        isEditable: false,
    },
    {
        label: 'e-mail',
        isRequired: false,
        fieldName: 'email',
        type: 'input',
        isEditable: false,
    },
    {
        label: 'Комментарий',
        isRequired: false,
        fieldName: 'comment',
        type: 'textarea',
        isEditable: false,
    },
];

export const companiesPerPage = 10;

export type ClientInteractionTabNames =
    | 'clientCard'
    | 'clientDocuments'
    | 'closedDeals'
    | 'cashback'
    | 'acts'
    | 'calls';

export const clientInteractionTabs: {
    name: ClientInteractionTabNames;
    label: string;
}[] = [
    {
        name: 'clientCard',
        label: 'Карточка компании',
    },
    {
        name: 'clientDocuments',
        label: 'Документы компании',
    },
    {
        name: 'closedDeals',
        label: 'Завершенные сделки',
    },
    {
        name: 'cashback',
        label: 'Кэшбэк',
    },
    {
        name: 'acts',
        label: 'Акты',
    },
    {
        name: 'calls',
        label: 'Запись разговора',
    },
];

export type generalCompanyInfoFields =
    | 'name'
    | 'inn'
    | 'kpp'
    | 'ogrn'
    | 'registrationDate'
    | 'addressLegal'
    | 'addressActual'
    | 'okved'
    | 'headName'
    | 'headPost';

export const generalCompanyInfo: {
    label: string;
    field: generalCompanyInfoFields;
}[] = [
    {
        field: 'name',
        label: '',
    },
    {
        field: 'inn',
        label: 'ИНН:',
    },
    {
        field: 'kpp',
        label: 'КПП:',
    },
    {
        field: 'ogrn',
        label: 'ОГРН:',
    },
    {
        field: 'registrationDate',
        label: 'Дата регистрации:',
    },
    {
        field: 'addressLegal',
        label: 'Юридический адрес:',
    },
    {
        field: 'addressActual',
        label: 'Фактический адрес:',
    },
    {
        field: 'okved',
        label: 'Основной вид деятельности:',
    },
    {
        field: 'headName',
        label: 'Руководитель:',
    },
    {
        field: 'headPost',
        label: 'Должность:',
    },
];

export type contactHighlightColorsNames = 'white' | 'green' | 'red';

export const contactHighlightColors: {
    name: contactHighlightColorsNames;
    label: string;
}[] = [
    {
        name: 'white',
        label: 'Отсутствует',
    },
    {
        name: 'green',
        label: 'Зеленый',
    },
    {
        name: 'red',
        label: 'Красный',
    },
];

export type noteColorsNames = 'white' | 'yellow' | 'red';

export const noteColors: {
    name: noteColorsNames;
    label: string;
}[] = [
    {
        name: 'white',
        label: 'Отсутствует',
    },
    {
        name: 'yellow',
        label: 'Желтый',
    },
    {
        name: 'red',
        label: 'Красный',
    },
];

export const staffTableColumns: {
    label: string;
    isSortingField: boolean;
}[] = [
    {
        label: 'Дата приема',
        isSortingField: true,
    },
    {
        label: 'ФИО',
        isSortingField: true,
    },
    {
        label: 'Должность',
        isSortingField: true,
    },
    {
        label: 'Сотовый',
        isSortingField: true,
    },
    {
        label: 'Добавочный',
        isSortingField: true,
    },
    {
        label: 'e-mail',
        isSortingField: true,
    },
    {
        label: 'День рождения',
        isSortingField: false,
    },
    {
        label: 'Бот',
        isSortingField: false,
    },
    {
        label: 'Запись',
        isSortingField: false,
    },
    {
        label: 'Статус',
        isSortingField: false,
    },
    {
        label: '',
        isSortingField: false,
    },
];

export type FieldNameTypeInEditingUserForm =
    | 'fullName'
    | 'shortName'
    | 'positionName'
    | 'phone'
    | 'additNumber'
    | 'birthDate'
    | 'status'
    | 'bot'
    | 'isAdmin'
    | 'isCallRecordEnabled'
    | 'email';

export type FieldNameTypeInAddingUserForm =
    | 'fullName'
    | 'shortName'
    | 'positionName'
    | 'phone'
    | 'additNumber'
    | 'birthDate'
    | 'status'
    | 'bot'
    | 'isAdmin'
    | 'isCallRecordEnabled'
    | 'email'
    | 'password'
    | 'login'
    | 'isFullBankInfoEnabled'
    | UserDocTypes;

export interface BaseFieldsInFormInterface {
    label: string;
    isRequired: boolean;
    isEditable?: boolean;
}

export interface FieldsInAddingUserFormType extends BaseFieldsInFormInterface {
    fieldName: FieldNameTypeInAddingUserForm;
    type: 'input' | 'dataset' | 'textarea' | 'date' | 'switcher';
}

export type boomerangPositions = 'ПФМ' | 'ОСКО';

export const fieldsInAddingUserForm: FieldsInAddingUserFormType[] = [
    {
        label: 'ФИО полное',
        isRequired: true,
        fieldName: 'fullName',
        type: 'input',
        isEditable: true,
    },
    {
        label: 'ФИО сокращенное',
        isRequired: true,
        fieldName: 'shortName',
        type: 'input',
        isEditable: true,
    },
    {
        label: 'Должность',
        isRequired: true,
        fieldName: 'positionName',
        type: 'dataset',
        isEditable: true,
    },
    {
        label: 'Сотовый номер',
        isRequired: true,
        fieldName: 'phone',
        type: 'input',
        isEditable: true,
    },
    {
        label: 'Добавочный номер',
        isRequired: false,
        fieldName: 'additNumber',
        type: 'input',
        isEditable: true,
    },
    {
        label: 'e-mail',
        isRequired: true,
        fieldName: 'email',
        type: 'input',
        isEditable: true,
    },
    {
        label: 'Дата рождения',
        isRequired: true,
        fieldName: 'birthDate',
        type: 'date',
        isEditable: true,
    },
    {
        label: 'Статус',
        isRequired: true,
        fieldName: 'status',
        type: 'dataset',
        isEditable: true,
    },
    {
        label: 'Бот',
        isRequired: true,
        fieldName: 'bot',
        type: 'switcher',
        isEditable: true,
    },
    {
        label: 'Включить права администратора',
        isRequired: true,
        fieldName: 'isAdmin',
        type: 'switcher',
        isEditable: true,
    },
    {
        label: 'Доступ к информационному блоку банка',
        isRequired: true,
        fieldName: 'isFullBankInfoEnabled',
        type: 'switcher',
        isEditable: true,
    },
    {
        label: 'Запись разговора:',
        isRequired: true,
        fieldName: 'isCallRecordEnabled',
        type: 'switcher',
        isEditable: true,
    },
];

export interface FieldsInAddingUserDocFormType extends BaseFieldsInFormInterface {
    fieldName: UserDocTypes;
}

export const documentsInAddUserForm: FieldsInAddingUserDocFormType[] = [
    {
        label: 'Прикрепить паспорт',
        isRequired: true,
        fieldName: 'passport',
        isEditable: true,
    },
    {
        label: 'Прикрепить СНИЛС',
        isRequired: false,
        fieldName: 'snils',
        isEditable: true,
    },
    {
        label: 'Прикрепить ИНН',
        isRequired: true,
        fieldName: 'inn',
        isEditable: true,
    },
];

export type SectionNameTypes = 'additional' | 'basic';

export type DocumentSectionsType = {
    sectionName: SectionNameTypes;
    title: string;
};

export const documentSections: DocumentSectionsType[] = [
    {
        sectionName: 'basic',
        title: 'Базовый пакет документов',
    },
    {
        sectionName: 'additional',
        title: 'Дополнительный пакет документов',
    },
];

export type FieldNamesInAddingTaskType =
    | 'procurementProcedureNumber'
    | 'procurementProcedureLink'
    | 'federalLaw'
    | 'bankGuaranteeType'
    | 'bankGuaranteeAmount'
    | 'bankGuaranteeTermFrom'
    | 'bankGuaranteeTermTo'
    | 'bankGuaranteeTermDays'
    | 'contractAdvanceAvailability'
    | 'additComment'
    | 'executorId'
    | 'procurementProcedureName'
    | 'initialContractPrice'
    | 'contractConclusionDate'
    | 'postscript'
    | 'loanType'
    | 'loanAmount'
    | 'loanTermMonths';

export type NumericFieldsInAddTaskType =
    | 'bankGuaranteeAmount'
    | 'bankGuaranteeTermDays'
    | 'initialContractPrice'
    | 'loanAmount'
    | 'loanTermMonths';
export const numericFieldsInAddTask: FieldNamesInAddingTaskType[] = [
    'bankGuaranteeAmount',
    'bankGuaranteeTermDays',
    'initialContractPrice',
    'loanAmount',
    'loanTermMonths',
];

export interface TaskInputFieldsType extends BaseFieldsInFormInterface {
    fieldName: FieldNamesInAddingTaskType;
    type: 'input' | 'dataset' | 'textarea' | 'date';
    isAccessableInCreation: boolean;
}

export const fieldsInAddingBankGuarantee = [
    {
        label: '№ закупочной процедуры',
        isRequired: true,
        fieldName: 'procurementProcedureNumber',
        type: 'input',
        isEditable: true,
        isAccessableInCreation: true,
    },
    {
        label: 'Ссылка на закупочную процедуру (вставьте url-адрес)',
        isRequired: true,
        fieldName: 'procurementProcedureLink',
        type: 'input',
        isEditable: true,
        isAccessableInCreation: true,
    },
    {
        label: 'Федеральный закон (выберите из списка)',
        isRequired: true,
        fieldName: 'federalLaw',
        type: 'dataset',
        isEditable: true,
        isAccessableInCreation: true,
    },
    {
        label: 'Тип банковской гарантии (выберите из списка):',
        isRequired: true,
        fieldName: 'bankGuaranteeType',
        type: 'dataset',
        isEditable: true,
        isAccessableInCreation: true,
    },
    {
        label: 'Наименование закупки',
        isRequired: true,
        fieldName: 'procurementProcedureName',
        type: 'textarea',
        isEditable: true,
        isAccessableInCreation: false,
    },
    {
        label: 'Начальная цена контракта',
        isRequired: true,
        fieldName: 'initialContractPrice',
        type: 'input',
        isEditable: true,
        isAccessableInCreation: false,
    },
    {
        label: 'Сумма банковской гарантии (в руб.)',
        isRequired: true,
        fieldName: 'bankGuaranteeAmount',
        type: 'input',
        isEditable: true,
        isAccessableInCreation: true,
    },
    {
        label: 'Срок действия банковской гарантии (в днях)',
        isRequired: true,
        fieldName: 'bankGuaranteeTermDays',
        type: 'date',
        isEditable: true,
        isAccessableInCreation: true,
    },
    {
        label: 'Дата заключения контракта',
        isRequired: false,
        fieldName: 'contractConclusionDate',
        type: 'date',
        isEditable: true,
        isAccessableInCreation: false,
    },
    {
        label: 'Наличие аванса по контракту:',
        isRequired: true,
        fieldName: 'contractAdvanceAvailability',
        type: 'input',
        isEditable: false,
        isAccessableInCreation: true,
    },
    {
        label: 'Выберите (менеджера) исполнителя для задачи',
        isRequired: false,
        fieldName: 'executorId',
        type: 'dataset',
        isEditable: false,
        isAccessableInCreation: true,
    },
    {
        label: 'Примечание к № заявки',
        isRequired: false,
        fieldName: 'postscript',
        type: 'input',
        isEditable: true,
        isAccessableInCreation: false,
    },
    {
        label: 'Комментарий к задаче',
        isRequired: false,
        fieldName: 'additComment',
        type: 'textarea',
        isEditable: true,
        isAccessableInCreation: true,
    },
];

export const fieldsInAddingLoan = [
    {
        label: 'Выберите вид кредита',
        isRequired: true,
        fieldName: 'loanType',
        type: 'dataset',
        isEditable: true,
        isAccessableInCreation: true,
    },
    {
        label: 'Сумма кредита (руб.)',
        isRequired: true,
        fieldName: 'loanAmount',
        type: 'input',
        isEditable: true,
        isAccessableInCreation: true,
    },
    {
        label: 'Срок (в мес.)',
        isRequired: true,
        fieldName: 'loanTermMonths',
        type: 'input',
        isEditable: true,
        isAccessableInCreation: true,
    },
    {
        label: 'Выберите (менеджера) исполнителя для задачи',
        isRequired: false,
        fieldName: 'executorId',
        type: 'dataset',
        isEditable: false,
        isAccessableInCreation: true,
    },
    {
        label: 'Примечание к № заявки',
        isRequired: false,
        fieldName: 'postscript',
        type: 'input',
        isEditable: true,
        isAccessableInCreation: false,
    },
    {
        label: 'Комментарий к задаче',
        isRequired: false,
        fieldName: 'additComment',
        type: 'textarea',
        isEditable: true,
        isAccessableInCreation: true,
    },
];

export type federalLawsInAddPartnerPageType = {
    name: FederalLawsTypes;
    label: string;
};

export const federalLawsInAddPartnerPage: federalLawsInAddPartnerPageType[] = [
    {
        name: 'fz44',
        label: '44-ФЗ',
    },
    {
        name: 'fz223',
        label: '223-ФЗ',
    },
    {
        name: 'pp615',
        label: '615-ПП',
    },
    {
        name: 'commerce',
        label: 'Коммерческая',
    },
    {
        name: 'other',
        label: 'Иное',
    },
];

export type loanTypesInAddTaskPageType = {
    name: BankProductType;
    label: string;
};

export const loanTypesInAddTaskPage = [
    {
        name: LoanProductENUM.LOAN_UNDER_CONTRACT,
        label: 'Кредит под контракт (КИК)',
    },
    {
        name: LoanProductENUM.LOAN_REVOLVING,
        label: 'Кредит оборотный (КОБ)',
    },
];
export type bankGuaranteeTypesInAddPartnerPageType = {
    name: TaskProductType;
    label: string;
};

export const bankGuaranteeTypesInAddPartnerPage = [
    {
        name: 'contractExecution',
        label: 'Исполнение контракта (ИК)',
    },
    {
        name: 'applicationSecurity',
        label: 'Обеспечение заявки (ОЗ)',
    },
    {
        name: 'advanceRefund',
        label: 'Возврат аванса (ВА)',
    },
    {
        name: 'guaranteeObligation',
        label: 'Гарантийное обязательство (ГО)',
    },
    {
        name: 'other',
        label: 'Иное',
    },
];

//Add partner

export type FieldNameTypeInAddingPartnerForm =
    | 'name'
    | 'instructions'
    | 'logo'
    | 'isDisplayedInList'
    | 'isPriority'
    | 'additInfo';

export interface FieldsInAddingPartnerFormType extends BaseFieldsInFormInterface {
    fieldName: PartnerFileTypes | PartnerSwitcherTypes | PartnerDocArrayTypes;
}

export const documentsInAddPartnerForm: FieldsInAddingPartnerFormType[] = [
    {
        label: 'Добавить инструкцию:',
        isRequired: false,
        fieldName: 'instructions',
    },
    {
        label: 'Добавить логотип:',
        isRequired: false,
        fieldName: 'logo',
    },
];

export const switchersInAddPartnerForm: FieldsInAddingPartnerFormType[] = [
    {
        label: 'Отображать в списке:',
        isRequired: false,
        fieldName: 'isDisplayedInList',
    },
    {
        label: 'Установить приоритет:',
        isRequired: false,
        fieldName: 'isPriority',
    },
];

export const docArraysInAddingPartnerForm: FieldsInAddingPartnerFormType[] = [
    {
        label: 'Агентский договор',
        isRequired: false,
        fieldName: 'agencyContract',
    },
    {
        label: 'Доверенности банка',
        isRequired: false,
        fieldName: 'powersOfAttorney',
    },
];

export type FieldNameTypeInAddingCredentialsForm =
    | 'type'
    | 'lkLink'
    | 'lkOperator'
    | 'login'
    | 'password';

export interface FieldsInAddingCredentialsFormType extends BaseFieldsInFormInterface {
    fieldName: FieldNameTypeInAddingCredentialsForm;
    type: 'input' | 'dataset' | 'textarea' | 'date' | 'switcher';
}

export const fieldsInAddPCredentialsForm: FieldsInAddingCredentialsFormType[] = [
    {
        label: 'Продукт',
        isRequired: true,
        fieldName: 'type',
        type: 'dataset',
    },
    {
        label: 'Ссылка на ЛК',
        isRequired: true,
        fieldName: 'lkLink',
        type: 'input',
    },
    {
        label: 'Оператор ЛК',
        isRequired: true,
        fieldName: 'lkOperator',
        type: 'input',
    },
    {
        label: 'Логин',
        isRequired: true,
        fieldName: 'login',
        type: 'input',
    },
    {
        label: 'Пароль:',
        isRequired: true,
        fieldName: 'password',
        type: 'input',
    },
];
export type bankProductComponentType = {
    name: string;
    type: string;
};
export const bankProducts: bankProductComponentType[] = [
    {
        name: 'bankContractExecution',
        type: 'bankGuarantee',
    },
    {
        name: 'bankAdvanceRefund',
        type: 'bankGuarantee',
    },
    {
        name: 'bankGuaranteeObligation',
        type: 'bankGuarantee',
    },
    {
        name: 'applicationSecurity',
        type: 'bankGuarantee',
    },
    {
        name: 'commercialContractExecution',
        type: 'commercialGuarantee',
    },
    {
        name: 'commercialAdvanceRefund',
        type: 'commercialGuarantee',
    },
    {
        name: 'commercialGuaranteeObligation',
        type: 'commercialGuarantee',
    },
    {
        name: 'loanUnderContract',
        type: 'loan',
    },
    {
        name: 'loanRevolving',
        type: 'loan',
    },
];

export type BankProductsState = {
    bankContractExecution?: BankProductCreateInterface;
    bankAdvanceRefund?: BankProductCreateInterface;
    bankGuaranteeObligation?: BankProductCreateInterface;
    applicationSecurity?: BankProductCreateInterface;
    commercialContractExecution?: BankProductCreateInterface;
    commercialAdvanceRefund?: BankProductCreateInterface;
    commercialGuaranteeObligation?: BankProductCreateInterface;
    loanUnderContract?: BankProductCreateInterface;
    loanRevolving?: BankProductCreateInterface;
};

export const initialBankProductItem = {
    limit: '',
    rateFrom: '',
    term: '',
    hasFZ44: false,
    hasFZ223: false,
    hasPP615: false,
    experience: '',
    age: '',
    worksWithIP: false,
    worksWithAO: false,
    baseCommission: '',
    clientCommission: '',
    additInfo: '',
    documents: [],
    stopRegions: [],
    lists: [],
    registrationTerm: '',
    issuanceCommission: '',
};

export const initialBankProductsState: BankProductsState = {
    bankContractExecution: undefined,
    bankAdvanceRefund: undefined,
    bankGuaranteeObligation: undefined,
    applicationSecurity: undefined,
    commercialContractExecution: undefined,
    commercialAdvanceRefund: undefined,
    commercialGuaranteeObligation: undefined,
    loanUnderContract: undefined,
    loanRevolving: undefined,
};

export const bankProductTableHeadings = [
    'Банковская гарантия',
    'Лимит',
    'Ставки',
    'Срок',
    '44-ФЗ',
    '223-ФЗ',
    '615-ПП',
    'Опыт (к)',
    'В-ст (мес)',
    'ИП',
    'АО',
    'star',
    'БКВ (%)',
    'КВК (%)',
    '',
];

export const commerceProductTableHeadings = [
    'Коммерческая гарантия',
    'Лимит',
    'Ставки',
    'Срок',
    '',
    '',
    '',
    'Опыт (к)',
    'В-ст (мес)',
    'ИП',
    'АО',
    '',
    'БКВ (%)',
    'КВК (%)',
    '',
];

export const loanProductTableHeadings = [
    'Кредиты',
    'Лимит',
    'Ставки',
    '',
    'Срок (мес)',
    '',
    '',
    '',
    'Рег. (мес)',
    'ИП',
    '',
    '',
    'КЗВ (%)',
    'БКВ (%)',
    '',
];

type ProductTableHeadings = {
    [key: string]: string[];
};

export const productTableHeadings: ProductTableHeadings = {
    '0': bankProductTableHeadings,
    '4': commerceProductTableHeadings,
    '7': loanProductTableHeadings,
};

export type FieldNamesInAddBankProduct =
    | 'limit'
    | 'rateFrom'
    | 'term'
    | 'hasFZ44'
    | 'hasFZ223'
    | 'hasPP615'
    | 'experience'
    | 'age'
    | 'worksWithIP'
    | 'worksWithAO'
    | 'baseCommission'
    | 'clientCommission'
    | 'additInfo'
    | 'documents'
    | 'stopRegions'
    | 'lists'
    | 'registrationTerm'
    | 'issuanceCommission'
    | 'empty';

export enum productFieldNameEnum {
    limit = 'limit',
    rateFrom = 'rateFrom',
    term = 'term',
    hasFZ44 = 'hasFZ44',
    hasFZ223 = 'hasFZ223',
    hasPP615 = 'hasPP615',
    experience = 'experience',
    age = 'age',
    worksWithIP = 'worksWithIP',
    worksWithAO = 'worksWithAO',
    baseCommission = 'baseCommission',
    clientCommission = 'clientCommission',
    additInfo = 'additInfo',
    documents = 'documents',
    stopRegions = 'stopRegions',
    lists = 'lists',
    registrationTerm = 'registrationTerm',
    issuanceCommission = 'issuanceCommission',
    empty = 'empty',
}

export enum bankProductPrefixEnum {
    bank = 'bank',
    commercial = 'commercial',
    loan = 'loan',
}
export interface FieldsInAddingBankProductFormType extends BaseFieldsInFormInterface {
    fieldName: FieldNamesInAddBankProduct;
}

export interface AddingBankProductFormSectionsType {
    percent: FieldsInAddingBankProductFormType[];
    laws: FieldsInAddingBankProductFormType[];
    bankExperience: FieldsInAddingBankProductFormType[];
    registrationTerm: FieldsInAddingBankProductFormType[];
    workWith: FieldsInAddingBankProductFormType[];
    commission: FieldsInAddingBankProductFormType[];
    documents: FieldsInAddingBankProductFormType;
    additionalInfo: FieldsInAddingBankProductFormType;
}

const commonProductSections: AddingBankProductFormSectionsType = {
    percent: [
        {
            label: 'Лимит (руб.)',
            isRequired: true,
            fieldName: productFieldNameEnum.limit,
        },
        {
            label: 'Ставка (%)',
            isRequired: true,
            fieldName: productFieldNameEnum.rateFrom,
        },
    ],
    laws: [
        {
            label: '44-ФЗ',
            isRequired: true,
            fieldName: productFieldNameEnum.hasFZ44,
        },
        {
            label: '223-ФЗ',
            isRequired: true,
            fieldName: productFieldNameEnum.hasFZ223,
        },
        {
            label: '615-пп',
            isRequired: true,
            fieldName: productFieldNameEnum.hasPP615,
        },
    ],
    bankExperience: [
        {
            label: 'Опыт исполненных контрактов (шт.)',
            isRequired: true,
            fieldName: productFieldNameEnum.experience,
        },
        {
            label: 'Возраст организации (мес.)',
            isRequired: true,
            fieldName: productFieldNameEnum.age,
        },
    ],
    registrationTerm: [],
    workWith: [
        {
            label: 'Работают с ИП',
            isRequired: true,
            fieldName: productFieldNameEnum.worksWithIP,
        },
    ],
    commission: [
        {
            label: 'БКВ',
            isRequired: true,
            fieldName: productFieldNameEnum.baseCommission,
        },
    ],
    documents: {
        label: 'Документы:',
        isRequired: true,
        fieldName: productFieldNameEnum.documents,
    },
    additionalInfo: {
        label: 'Дополнительная информация',
        isRequired: true,
        fieldName: productFieldNameEnum.additInfo,
    },
};

export const addingBankProductFormSections: AddingBankProductFormSectionsType = {
    ...commonProductSections,
    percent: [
        ...commonProductSections.percent,
        {
            label: 'Срок (дней)',
            isRequired: true,
            fieldName: productFieldNameEnum.term,
        },
    ],
    workWith: [
        ...commonProductSections.workWith,
        {
            label: 'Работают с АО',
            isRequired: true,
            fieldName: productFieldNameEnum.worksWithAO,
        },
    ],
    commission: [
        ...commonProductSections.commission,
        {
            label: 'КВК',
            isRequired: true,
            fieldName: productFieldNameEnum.clientCommission,
        },
    ],
};

export const addingLoanBankProductFormSections: AddingBankProductFormSectionsType = {
    ...commonProductSections,
    percent: [
        ...commonProductSections.percent,
        {
            label: '',
            isRequired: false,
            fieldName: productFieldNameEnum.empty,
        },
        {
            label: 'Срок (мес)',
            isRequired: true,
            fieldName: productFieldNameEnum.term,
        },
    ],
    laws: [
        {
            label: '',
            isRequired: false,
            fieldName: productFieldNameEnum.empty,
        },
        {
            label: '',
            isRequired: false,
            fieldName: productFieldNameEnum.empty,
        },
        {
            label: '',
            isRequired: false,
            fieldName: productFieldNameEnum.empty,
        },
    ],
    bankExperience: [],
    registrationTerm: [
        {
            label: 'Регистрация (мес)',
            isRequired: true,
            fieldName: productFieldNameEnum.registrationTerm,
        },
    ],
    workWith: [
        {
            label: 'Работают с ИП',
            isRequired: true,
            fieldName: productFieldNameEnum.worksWithIP,
        },
        {
            label: '',
            isRequired: false,
            fieldName: productFieldNameEnum.empty,
        },
    ],
    commission: [
        {
            label: 'КЗВ',
            isRequired: true,
            fieldName: productFieldNameEnum.issuanceCommission,
        },
        ...commonProductSections.commission,
    ],
};

export type numericFieldsInAddBankProductType =
    | productFieldNameEnum.limit
    | productFieldNameEnum.term
    | productFieldNameEnum.experience
    | productFieldNameEnum.age
    | productFieldNameEnum.registrationTerm;
export const numericFieldsInAddBankProduct: FieldNamesInAddBankProduct[] = [
    productFieldNameEnum.limit,
    productFieldNameEnum.term,
    productFieldNameEnum.registrationTerm,
    productFieldNameEnum.experience,
    productFieldNameEnum.age,
];

// Partners Page

export const partnersTableColumns = [
    '№',
    '',
    'Банк',
    'ЛК',
    'ИК',
    'ВА',
    'ГО',
    'ОЗ',
    'КБГ',
    'Кредит',
    'Инф.',
    'Пр-т',
    'Сп-ок',
    'Инстр.',
    '',
];

export const partnersTableWithColumns = [
    '№',
    '',
    'Банк',
    'ЛК',
    'Вид',
    'Сумма (до)',
    'Дней (до)',
    'Ставка % (от)',
    'Сп-ок',
    '',
];

export const federalLawsInTable: {
    label: string;
    name: lawFieldsType;
}[] = [
    {
        label: '44',
        name: 'hasFZ44',
    },
    {
        label: '223',
        name: 'hasFZ223',
    },
    {
        label: '615',
        name: 'hasPP615',
    },
];

export const federalLaws = [
    {
        name: '',
        label: 'Все',
    },
    {
        name: 'fz44',
        label: '44-ФЗ',
    },
    {
        name: 'fz223',
        label: '223-ФЗ',
    },
    {
        name: 'pp615',
        label: '615-ПП',
    },
    {
        name: 'commerce',
        label: 'Коммерческая',
    },
];

export const bankGuaranteeTypes = [
    {
        name: '',
        label: 'Все',
    },
    {
        name: 'contractExecution',
        label: 'Исполнение контракта (ИК)',
    },
    {
        name: 'applicationSecurity',
        label: 'Обеспечение заявки (ОЗ)',
    },
    {
        name: 'advanceRefund',
        label: 'Возврат аванса (ВА)',
    },
    {
        name: 'guaranteeObligation',
        label: 'Гарантийное обязательство (ГО)',
    },
];

export type partnerFilterFieldNameType =
    | 'federalLaw'
    | 'guaranteeType'
    | 'term'
    | 'limit'
    | 'customerFromList';

export type partnerFilterFieldType = {
    label: string;
    name: partnerFilterFieldNameType;
    type: string;
};

export const partnerFilterFields: partnerFilterFieldType[] = [
    {
        name: 'federalLaw',
        label: 'ФЗ',
        type: 'list',
    },
    {
        name: 'guaranteeType',
        label: 'Вид гарантии',
        type: 'list',
    },
    {
        name: 'term',
        label: 'Срок в днях',
        type: 'input',
    },
    {
        name: 'limit',
        label: 'Сумма в руб.',
        type: 'input',
    },
    {
        name: 'customerFromList',
        label: 'Заказчик из списка',
        type: 'list',
    },
];

export const emptyPhoneWithErrorField = {
    phoneNumber: '',
    additInfo: '',
    isError: false,
};

export const emptyEmailWithErrorField = {
    email: '',
    additInfo: '',
    isError: false,
};

export const bankDocSectionAr: BankDocumentSectionTypes[] = [
    'agencyContract',
    'powersOfAttorney',
    'instructions',
];

// client tasks
export const clientBankGuaranteeTasksTableColumns = [
    'Дата создания',
    '№',
    'Тип гарантии',
    'Срок (дни)',
    'ФЗ',
    'Сумма',
    'Статус',
    'Исполнитель',
    'Наличие меток',
];

export const clientLoanTasksTableColumns = [
    'Дата создания',
    '№',
    'Тип кредита',
    'Срок (мес)',
    '',
    'Сумма',
    'Статус',
    'Исполнитель',
    'Наличие меток',
];

export type TaskStatusesArrType = {
    name: TaskStatusTypes;
    label: string;
};

export const TaskProducts = [
    {
        name: 'bankGuarantee',
        label: 'Гарантии',
    },
    {
        name: 'loan',
        label: 'Кредиты',
    },
];

export const BankGuaranteeTaskStatuses: TaskStatusesArrType[] = [
    {
        name: 'distribution',
        label: 'Распределение',
    },
    {
        name: 'analysis',
        label: 'Анализ',
    },
    {
        name: 'institution',
        label: 'Заведение',
    },
    {
        name: 'signing',
        label: 'Подписание КЛ',
    },
    {
        name: 'consideration',
        label: 'Рассмотрение',
    },
    {
        name: 'additRequest',
        label: 'Дозапрос',
    },
    {
        name: 'pending',
        label: 'Ожидание',
    },
    {
        name: 'decision',
        label: 'Решение',
    },
    {
        name: 'layout',
        label: 'Макет',
    },
    {
        name: 'onRelease',
        label: 'На выпуске',
    },
    {
        name: 'closed',
        label: 'Выдана',
    },
];

export const LoanTaskStatuses = BankGuaranteeTaskStatuses.map((item) => {
    if (item.name === 'layout') {
        return {
            name: item.name,
            label: 'Получение',
        };
    }
    return item;
});

export type TaskImportancesArrType = {
    name: TaskImportanceTypes;
    label: string;
};
export const TaskImportances: TaskImportancesArrType[] = [
    {
        name: 'pending',
        label: 'В ожидании',
    },
    {
        name: 'urgent',
        label: 'Срочно',
    },
];

export type TaskCloseReasonsArrType = {
    name: TaskCloseReasonsTypes;
    label: string;
};
export const TaskCloseReasons: TaskCloseReasonsArrType[] = [
    {
        name: 'clientRefused',
        label: 'Клиент отказался',
    },
    {
        name: 'noOffers',
        label: 'Нет предложений',
    },
    {
        name: 'termsExpired',
        label: 'Сроки',
    },
];

export const offersForBankGuaranteeTaskTableColumns = [
    'Банк',
    'Оператор ЛК',
    'Ставки (%)',
    'Комиссия банка',
    'Базовое КВ',
    'КВ Клиента',
    'Завышение',
    'Ком.',
    'Запрос',
    'Статус заявки',
    'Макет',
    'Гарантия',
    '',
];

export const productsForLoanTaskTableColumns = [
    'Банк',
    'Оператор ЛК',
    'Сумма',
    'Ставки (%)',
    'Комиссия банка',
    'Базовое КВ',
    'Ком.',
    'Запрос',
    'Статус заявки',
    '',
];

export const banksForBankGuaranteeTaskColumns = [
    'Банк',
    'Лимит',
    'Ставка (%)',
    'Оператор ЛК',
    'Срок',
    'Тип гарантии',
    'БКВ',
    'КВК',
    '',
];

export const banksForLoanTaskColumns = [
    'Банк',
    'Лимит',
    'Ставка (%)',
    'КЗВ (%)',
    'Оператор ЛК',
    'Срок',
    'Вид кредита',
    'БКВ',
    '',
];

export type OfferDocTypes = 'layout' | 'guarantee';

export type editOfferFieldNameType =
    | 'bankCommission'
    | 'baseCommission'
    | 'clientCommission'
    | 'comment'
    | 'overstatement'
    | 'status'
    | 'comment'
    | 'rateFrom'
    | 'amount'
    | 'term';

export type editOfferFieldType = {
    label: string;
    name: editOfferFieldNameType | OfferDocTypes;
    type: string;
    isRequired: boolean;
};

export const editBankGuaranteeOfferField: editOfferFieldType[] = [
    {
        name: 'bankCommission',
        label: 'Комиссия банка руб./%',
        type: 'input',
        isRequired: false,
    },
    {
        name: 'overstatement',
        label: 'Завышение в руб.',
        type: 'input',
        isRequired: false,
    },
    {
        name: 'status',
        label: 'Статус',
        type: 'list',
        isRequired: true,
    },
    {
        name: 'layout',
        label: 'Прикрепить макет',
        type: 'document',
        isRequired: false,
    },
    {
        name: 'guarantee',
        label: 'Прикрепить гарантию',
        type: 'document',
        isRequired: false,
    },
    {
        name: 'baseCommission',
        label: 'Базовое КВ руб./%',
        type: 'input',
        isRequired: true,
    },
    {
        name: 'clientCommission',
        label: 'КВ Клиента руб./%',
        type: 'input',
        isRequired: true,
    },
    {
        name: 'comment',
        label: 'Комментарий',
        type: 'textarea',
        isRequired: false,
    },
];

export const editLoanOfferField: editOfferFieldType[] = [
    {
        name: 'amount',
        label: 'Одобренная сумма (руб.)',
        type: 'input',
        isRequired: false,
    },
    {
        name: 'term',
        label: 'Срок кредита (мес.)',
        type: 'input',
        isRequired: false,
    },
    {
        name: 'bankCommission',
        label: 'Комиссия банка руб./%',
        type: 'input',
        isRequired: false,
    },
    {
        name: 'rateFrom',
        label: 'Ставка в %',
        type: 'input',
        isRequired: false,
    },
    {
        name: 'status',
        label: 'Статус',
        type: 'list',
        isRequired: true,
    },
    {
        name: 'baseCommission',
        label: 'Базовое КВ руб./%',
        type: 'input',
        isRequired: true,
    },
    {
        name: 'comment',
        label: 'Комментарий',
        type: 'textarea',
        isRequired: false,
    },
];

export const OfferDocSections: OfferDocTypes[] = ['layout', 'guarantee'];

export type TaskOfferStatusesArrType = {
    name: TaskOfferStatusTypes;
    label: string;
};

export const BankGuaranteeTaskOfferStatuses: TaskOfferStatusesArrType[] = [
    {
        name: 'started',
        label: 'Заведена',
    },
    {
        name: 'signing',
        label: 'Подписание заявки',
    },
    {
        name: 'consideration',
        label: 'Рассмотрение',
    },
    {
        name: 'additRequest',
        label: 'Дозапрос',
    },
    {
        name: 'pending',
        label: 'Ожидание',
    },
    {
        name: 'bankRefused',
        label: 'Отказ банка',
    },
    {
        name: 'clientRefused',
        label: 'Отказ клиента',
    },
    {
        name: 'commissionApproval',
        label: 'Согласование комиссии',
    },
    {
        name: 'offer',
        label: 'Предложение',
    },
    {
        name: 'fixed',
        label: 'Закрепленно за ДА',
    },
    {
        name: 'givenOut',
        label: 'Выдана',
    },
];

export const LoanTaskOfferStatuses = BankGuaranteeTaskOfferStatuses.filter((status) => {
    return status.name !== 'commissionApproval';
});

export type BankGuaranteeTaskInfoFieldNames =
    | 'info'
    | 'bankGuaranteeType'
    | 'procurementProcedureNumber'
    | 'federalLaw'
    | 'procurementProcedureName'
    | 'initialContractPrice'
    | 'bankGuaranteeAmount'
    | 'bankGuaranteeTermDays'
    | 'contractConclusionDate'
    | 'procurementProcedureLink'
    | 'contractAdvanceAvailability';
export type LoanTaskInfoFieldNames = 'info' | 'loanType' | 'loanAmount' | 'loanTermMonths';

export type BankGuaranteeTaskInfoType = {
    name: BankGuaranteeTaskInfoFieldNames;
    label: string;
};

export type LoanTaskInfoType = {
    name: LoanTaskInfoFieldNames;
    label: string;
};
export const BankGuaranteeTaskInfoFields: BankGuaranteeTaskInfoType[] = [
    {
        name: 'info',
        label: 'Информация о закупке',
    },
    {
        name: 'bankGuaranteeType',
        label: 'Тип гарантии',
    },
    {
        name: 'contractAdvanceAvailability',
        label: 'Наличие аванса по контракту',
    },
    {
        name: 'procurementProcedureNumber',
        label: '№ лота',
    },
    {
        name: 'procurementProcedureLink',
        label: 'Ссылка на закупочную процедуру',
    },
    {
        name: 'federalLaw',
        label: 'Федеральный закон',
    },
    {
        name: 'procurementProcedureName',
        label: 'Наименование закупки',
    },
    {
        name: 'initialContractPrice',
        label: 'Начальная цена контракта',
    },
    {
        name: 'bankGuaranteeAmount',
        label: 'Сумма банковской гарантии',
    },
    {
        name: 'contractConclusionDate',
        label: 'Дата заключения контракта',
    },
    {
        name: 'bankGuaranteeTermDays',
        label: 'Срок банковской гарантии',
    },
];

export const LoanTaskInfoFields: LoanTaskInfoType[] = [
    {
        name: 'info',
        label: 'Информация о кредите',
    },
    {
        name: 'loanType',
        label: 'Тип Кредита',
    },
    {
        name: 'loanAmount',
        label: 'Сумма кредита',
    },
    {
        name: 'loanTermMonths',
        label: 'Срок',
    },
];

export type TaskInfoSelectsNames = 'listOfStatuses' | 'listOfImportances';
export type TaskInfoSelectsType = {
    name: TaskInfoSelectsNames;
    label: string;
    width: number;
};

export const TaskInfoSelects: TaskInfoSelectsType[] = [
    {
        name: 'listOfStatuses',
        label: 'Этап работы с заявкой',
        width: 200,
    },
    {
        name: 'listOfImportances',
        label: 'Установить важность',
        width: 160,
    },
];

// Company cashback
export type CashbackFilterValueType = CashbackStatusType | '';
export type OfferStatusFilterFieldsType = {
    value: CashbackFilterValueType;
    label: string;
};
export const companyCashbackFilterFields: OfferStatusFilterFieldsType[] = [
    {
        value: 'paid',
        label: 'Выплаченный',
    },
    {
        value: 'accrued',
        label: 'Начисленный',
    },
    {
        value: '',
        label: 'Все',
    },
];

export type CashbackUnsortingFields = 'actions' | 'product';
export type CompanyCashbackSortingFields =
    | 'transactionDate'
    | 'clientCommission'
    | 'status'
    | 'paymentDate'
    | 'receiverName'
    | 'offer';

export type clientCashbackColumnType = {
    name: CashbackUnsortingFields | CompanyCashbackSortingFields;
    label: string;
    isSortingField: boolean;
};
export const clientCashbackColumns: clientCashbackColumnType[] = [
    {
        name: 'transactionDate',
        label: 'Дата сделки',
        isSortingField: true,
    },
    {
        name: 'offer',
        label: '№',
        isSortingField: true,
    },
    {
        name: 'product',
        label: 'Продукт',
        isSortingField: false,
    },
    {
        name: 'clientCommission',
        label: 'Сумма КВК',
        isSortingField: true,
    },
    {
        name: 'status',
        label: 'Статус',
        isSortingField: true,
    },
    {
        name: 'paymentDate',
        label: 'Дата выплаты',
        isSortingField: true,
    },
    {
        name: 'receiverName',
        label: 'Получатель',
        isSortingField: true,
    },
    {
        name: 'actions',
        label: '',
        isSortingField: false,
    },
];

export type AddBankToTaskFieldsType =
    | 'limit'
    | 'rateFrom'
    | 'lkOperator'
    | 'term'
    | 'type'
    | 'baseCommission'
    | 'clientCommission'
    | 'issuanceCommission';

export enum AddBankToTaskFieldsENUM {
    LIMIT = 'limit',
    RATE_FORM = 'rateFrom',
    LK_OPERATOR = 'lkOperator',
    TERM = 'term',
    TYPE = 'type',
    BASE_COMMISSION = 'baseCommission',
    CLIENT_COMMISSION = 'clientCommission',
    ISSUANCE_COMMISSION = 'issuanceCommission',
}

export const AddBankToBankGuaranteeTaskFields: AddBankToTaskFieldsType[] = [
    'limit',
    'rateFrom',
    'lkOperator',
    'term',
    'type',
    'baseCommission',
    'clientCommission',
];

export const AddBankToLoanTaskFields: AddBankToTaskFieldsType[] = [
    'limit',
    'rateFrom',
    'issuanceCommission',
    'lkOperator',
    'term',
    'type',
    'baseCommission',
];

export const AddBankToTaskPercentFields: AddBankToTaskFieldsType[] = [
    'rateFrom',
    'baseCommission',
    'clientCommission',
    'issuanceCommission',
];

export type editСashbackFieldNameType =
    | 'inn'
    | 'receiverName'
    | 'clientCommission'
    | 'receiverHeadName'
    | 'receiverHeadPost'
    | 'status'
    | 'paymentDate'
    | 'checkingAccount'
    | 'individualInn'
    | 'importantInfo'
    | 'correspondentAccount'
    | 'bankIdentificationCode';

export type editСashbackFieldType = {
    label: string;
    name: editСashbackFieldNameType | OfferDocTypes;
    type: string;
    isRequired: boolean;
};

export const editСashbackField: editСashbackFieldType[] = [
    {
        name: 'clientCommission',
        label: 'Сумма КВК (в руб.)',
        type: 'input',
        isRequired: false,
    },
    {
        name: 'status',
        label: 'Статус',
        type: 'list',
        isRequired: false,
    },
    {
        name: 'receiverName',
        label: 'Наименование получателя',
        type: 'input',
        isRequired: false,
    },
    {
        name: 'inn',
        label: 'ИНН',
        type: 'input',
        isRequired: false,
    },
    {
        name: 'receiverHeadName',
        label: 'ФИО',
        type: 'input',
        isRequired: false,
    },
    {
        name: 'receiverHeadPost',
        label: 'Должность',
        type: 'input',
        isRequired: false,
    },
    {
        name: 'paymentDate',
        label: 'Дата выплаты',
        type: 'date',
        isRequired: false,
    },
    {
        name: 'checkingAccount',
        label: 'Р/С',
        type: 'input',
        isRequired: false,
    },
    {
        name: 'bankIdentificationCode',
        label: 'БИК',
        type: 'input',
        isRequired: false,
    },
    {
        name: 'correspondentAccount',
        label: 'К/С',
        type: 'input',
        isRequired: false,
    },
    {
        name: 'individualInn',
        label: 'ИНН (для перевода Ф/Л)',
        type: 'input',
        isRequired: false,
    },
    {
        name: 'importantInfo',
        label: 'Важная информация:',
        type: 'textarea',
        isRequired: false,
    },
];

export const transferDetails = [
    'checkingAccount',
    'bankIdentificationCode',
    'correspondentAccount',
    'individualInn',
];

export type cashhbackStatusesArrType = {
    name: CashbackStatusType;
    label: string;
};
export const cashhbackStatuses: cashhbackStatusesArrType[] = [
    {
        name: 'accrued',
        label: 'Начислено',
    },
    {
        name: 'paid',
        label: 'Выплаченно',
    },
];

export const statCommentsColumns: {
    name: StatCommentsTabNames;
    label: string;
}[] = [
    {
        name: 'time',
        label: 'Время',
    },
    {
        name: 'company',
        label: 'Компания',
    },
    {
        name: 'task',
        label: '№ заяки',
    },
    {
        name: 'comment',
        label: 'Комментарий',
    },
];

export type StatCommentsTabNames = 'time' | 'company' | 'comment' | 'task';

// Company closed Requests
export type TaskСloseReasonFilter = 'successful' | 'unsuccessful';
export type CompanyClosedRequestsFilterValueTypes = TaskСloseReasonFilter | '';

export type CompanyClosedRequestsFilterFieldsType = {
    value: CompanyClosedRequestsFilterValueTypes;
    label: string;
};

export const companyClosedRequestsFilterFields = [
    {
        value: 'successful',
        label: 'Выданные',
    },
    {
        value: 'unsuccessful',
        label: 'Отказы',
    },
    {
        value: '',
        label: 'Все',
    },
];

export type CompanyClosedRequestsFields =
    | 'closeDate'
    | 'bankGuaranteeType'
    | 'taskId'
    | 'bankName'
    | 'bankGuaranteeAmount'
    | 'bankCommission'
    | 'baseCommission'
    | 'clientCommission'
    | 'income'
    | 'status'
    | 'executor'
    | 'loanIcon';

export type clientClosedRequestsColumnType = {
    name: CompanyClosedRequestsFields;
    label: string;
};
export const clientClosedRequestsColumns: clientClosedRequestsColumnType[] = [
    {
        name: 'closeDate',
        label: 'Дата закрытия',
    },
    {
        name: 'loanIcon',
        label: '',
    },
    {
        name: 'taskId',
        label: '№',
    },
    {
        name: 'bankGuaranteeType',
        label: 'Тип',
    },
    {
        name: 'bankName',
        label: 'Банк',
    },
    {
        name: 'bankGuaranteeAmount',
        label: 'Сумма БГ/K',
    },
    {
        name: 'bankCommission',
        label: 'КЗВ',
    },
    {
        name: 'baseCommission',
        label: 'Базовое КВ',
    },
    {
        name: 'clientCommission',
        label: 'КВ Клиента',
    },
    {
        name: 'income',
        label: 'Доход по клиенту',
    },
    {
        name: 'status',
        label: 'С',
    },
    {
        name: 'executor',
        label: 'Исполнитель',
    },
];

export type CommissionType = 'bankCommission' | 'baseCommission' | 'clientCommission';

export enum CommissionTypeENUM {
    BANK_COMMISSION = 'bankCommission',
    BASE_COMMISSION = 'baseCommission',
    CLIENT_COMMISSION = 'clientCommission',
}

export const commissionsInRequestTable: CommissionType[] = [
    'bankCommission',
    'baseCommission',
    'clientCommission',
];

// Active Requests
export type ActiveRequestsTableFields =
    | 'createdAt'
    | 'bankGuaranteeType'
    | 'taskId'
    | 'companyName'
    | 'bankGuaranteeAmount'
    | 'federalLaw'
    | 'bankGuaranteeTermDays'
    | 'manager'
    | 'icons'
    | 'status'
    | 'executor'
    | 'loanIcon';

export type ActiveRequestsTableColumnType = {
    name: ActiveRequestsTableFields;
    label: string;
};
export const activeRequestsTableColumns: ActiveRequestsTableColumnType[] = [
    {
        name: 'createdAt',
        label: 'Дата создания',
    },
    {
        name: 'loanIcon',
        label: '',
    },
    {
        name: 'taskId',
        label: '№ заявки',
    },
    {
        name: 'companyName',
        label: 'Компания',
    },
    {
        name: 'bankGuaranteeType',
        label: 'Тип БГ/К',
    },
    {
        name: 'federalLaw',
        label: 'ФЗ',
    },
    {
        name: 'bankGuaranteeAmount',
        label: 'Сумма',
    },
    {
        name: 'bankGuaranteeTermDays',
        label: 'Срок',
    },
    {
        name: 'status',
        label: 'Статус',
    },
    {
        name: 'manager',
        label: 'Менеджер',
    },
    {
        name: 'executor',
        label: 'Исполнитель',
    },
    {
        name: 'icons',
        label: '',
    },
];

export type federalLawsTaskFilterNames = FederalLawsTypes | '';

export type federalLawsInTaskFilterType = {
    name: federalLawsTaskFilterNames;
    label: string;
};

export const federalLawsInTaskFilter: federalLawsInTaskFilterType[] = [
    {
        name: '',
        label: 'Все',
    },
    {
        name: 'fz44',
        label: '44-ФЗ',
    },
    {
        name: 'fz223',
        label: '223-ФЗ',
    },
    {
        name: 'pp615',
        label: '615-ПП',
    },
    {
        name: 'commerce',
        label: 'Коммерческая',
    },
];

export type bankGuaranteeTaskFilterNames = TaskProductType | '';

export type bankGuaranteeTypesInTaskFilterType = {
    name: bankGuaranteeTaskFilterNames;
    label: string;
};

export const bankGuaranteeTypesInTaskFilter: bankGuaranteeTypesInTaskFilterType[] = [
    {
        name: '',
        label: 'Все',
    },
    {
        name: 'contractExecution',
        label: 'Исполнение контракта (ИК)',
    },
    {
        name: 'applicationSecurity',
        label: 'Обеспечение заявки (ОЗ)',
    },
    {
        name: 'advanceRefund',
        label: 'Возврат аванса (ВА)',
    },
    {
        name: 'guaranteeObligation',
        label: 'Гарантийное обязательство (ГО)',
    },
];

// Closed Requests
export type CloseRequestsTableFields =
    | 'bankGuaranteeType'
    | 'taskId'
    | 'companyName'
    | 'bankGuaranteeAmount'
    | 'federalLaw'
    | 'bankGuaranteeTermDays'
    | 'manager'
    | 'status'
    | 'executor'
    | 'closeDate'
    | 'bankName'
    | 'bankCommission'
    | 'baseCommission'
    | 'income'
    | 'clientCommission'
    | 'loanIcon'
    | 'lkCredential';

export type CloseRequestsTableColumnType = {
    name: CloseRequestsTableFields;
    label: string;
};
export const closeRequestsTableColumns: CloseRequestsTableColumnType[] = [
    {
        name: 'closeDate',
        label: 'Дата закр-я',
    },
    {
        name: 'loanIcon',
        label: '',
    },
    {
        name: 'taskId',
        label: '№ заявки',
    },
    {
        name: 'companyName',
        label: 'Компания',
    },
    {
        name: 'bankGuaranteeType',
        label: 'Тип',
    },
    {
        name: 'federalLaw',
        label: 'ФЗ',
    },
    {
        name: 'bankName',
        label: 'Банк',
    },
    {
        name: 'lkCredential',
        label: 'ЛК',
    },
    {
        name: 'bankGuaranteeAmount',
        label: 'Сумма БГ/К',
    },
    {
        name: 'bankCommission',
        label: 'Комиссия банка',
    },
    {
        name: 'baseCommission',
        label: 'Базовое КВ',
    },
    {
        name: 'clientCommission',
        label: 'КВ Клиента',
    },
    {
        name: 'income',
        label: 'Доход по клиенту',
    },
    {
        name: 'status',
        label: 'С',
    },
    {
        name: 'manager',
        label: 'Менеджер',
    },
    {
        name: 'executor',
        label: 'Исполнитель',
    },
];

// Acts
export type CompanyActsTableFields =
    | 'createdAt'
    | 'actNumber'
    | 'term'
    | 'amount'
    | 'pagesCount'
    | 'actions'
    | 'signing'
    | 'reciever'
    | 'unload';

export type CompanyActsColumnType = {
    name: CompanyActsTableFields;
    label: string;
};
export const companyActsColumns: CompanyActsColumnType[] = [
    {
        name: 'createdAt',
        label: 'Дата создания',
    },
    {
        name: 'actNumber',
        label: '№ Акта',
    },
    {
        name: 'term',
        label: 'Период',
    },
    {
        name: 'amount',
        label: 'Сумма',
    },
    {
        name: 'pagesCount',
        label: 'Кол-во сделок',
    },
    {
        name: 'unload',
        label: 'Выгрузить',
    },
    {
        name: 'signing',
        label: 'Подписанный',
    },
    {
        name: 'reciever',
        label: 'Получатель',
    },
    {
        name: 'actions',
        label: '',
    },
];

export type FieldNameTypeInAddingActForm = 'dateFrom' | 'dateTo' | 'receiverId';

export interface FieldsInAddingActFormType extends BaseFieldsInFormInterface {
    fieldName: FieldNameTypeInAddingActForm;
}

export const dateFieldsInAddingActForm: FieldsInAddingActFormType[] = [
    {
        label: 'Период с',
        isRequired: true,
        fieldName: 'dateFrom',
    },
    {
        label: 'Период по',
        isRequired: true,
        fieldName: 'dateTo',
    },
];

export type FieldNameTypeInAddingReceiverForm = 'inn' | 'name' | 'headName' | 'headPost';

export interface FieldsInAddingReceiverFormType extends BaseFieldsInFormInterface {
    fieldName: FieldNameTypeInAddingReceiverForm;
}

export const fieldsInAddingReceiverForm: FieldsInAddingReceiverFormType[] = [
    {
        label: 'Введите ИНН компании',
        isRequired: true,
        fieldName: 'inn',
    },
    {
        label: 'Наименование организации (ИП)',
        isRequired: true,
        fieldName: 'name',
    },
    {
        label: 'ФИО Руководителя',
        isRequired: true,
        fieldName: 'headName',
    },
    {
        label: 'Должность рководителя',
        isRequired: true,
        fieldName: 'headPost',
    },
];

export type StatisticCashbackFields =
    | 'transactionDate'
    | 'clientCommission'
    | 'status'
    | 'paymentDate'
    | 'receiverName'
    | 'company'
    | 'actions'
    | 'offer'
    | 'product';

export type StatisticCashbackColumnType = {
    name: StatisticCashbackFields;
    label: string;
    isSortingField: boolean;
};
export const statisticCashbackColumns: StatisticCashbackColumnType[] = [
    {
        name: 'transactionDate',
        label: 'Дата сделки',
        isSortingField: false,
    },
    {
        name: 'offer',
        label: '№ заявки',
        isSortingField: false,
    },
    {
        name: 'company',
        label: 'Компания',
        isSortingField: false,
    },
    {
        name: 'product',
        label: 'Продукт',
        isSortingField: false,
    },
    {
        name: 'clientCommission',
        label: 'Сумма КВ клиента',
        isSortingField: false,
    },
    {
        name: 'status',
        label: 'Статус',
        isSortingField: false,
    },
    {
        name: 'paymentDate',
        label: 'Дата выплаты',
        isSortingField: false,
    },
    {
        name: 'receiverName',
        label: 'Получатель',
        isSortingField: false,
    },
    {
        name: 'actions',
        label: '',
        isSortingField: false,
    },
];

// Calls records
export type CallsTableFields =
    | 'startDate'
    | 'startTime'
    | 'phone'
    | 'companyName'
    | 'staffer'
    | 'stafferPhone'
    | 'duration'
    | 'management'
    | 'download';

export type CallsTableColumnType = {
    name: CallsTableFields;
    label: string;
};
export const callsTableColumns: CallsTableColumnType[] = [
    {
        name: 'startDate',
        label: 'Дата',
    },
    {
        name: 'startTime',
        label: 'Начало',
    },
    {
        name: 'phone',
        label: 'Номер телефона',
    },
    {
        name: 'companyName',
        label: 'Компания',
    },
    {
        name: 'staffer',
        label: 'Сотрудник',
    },
    {
        name: 'stafferPhone',
        label: 'Исходящий номер',
    },
    {
        name: 'duration',
        label: 'Длительность',
    },
    {
        name: 'management',
        label: 'Управление',
    },
    {
        name: 'download',
        label: '',
    },
];

export const callsTabTableColumns: CallsTableColumnType[] = [
    {
        name: 'startDate',
        label: 'Дата',
    },
    {
        name: 'startTime',
        label: 'Начало',
    },
    {
        name: 'phone',
        label: 'Номер телефона',
    },
    {
        name: 'staffer',
        label: 'Сотрудник',
    },
    {
        name: 'duration',
        label: 'Длительность',
    },
    {
        name: 'management',
        label: 'Управление',
    },
    {
        name: 'download',
        label: '',
    },
];

export type СallDirectionFilterValueType = CallDirectionType | '';
export type СallDirectionFilterFieldsType = {
    value: СallDirectionFilterValueType;
    label: string;
};
export const callsDirectionFilterFields: СallDirectionFilterFieldsType[] = [
    {
        value: 'in',
        label: 'Входящие',
    },
    {
        value: 'out',
        label: 'Исходящие',
    },
    {
        value: '',
        label: 'Все',
    },
];
