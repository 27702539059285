import styled from 'styled-components/macro';

const StyledForm = styled.form<{ width: number }>`
    width: ${({ width }) => `${width}px`};
    position: relative;
    padding-bottom: 50px;
    .add-note__header {
        text-align: center;
    }

    .add-note__button {
        border: none;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #0038ff;
        position: absolute;
        right: 0;
        bottom: 10px;
    }

    .add-note__setting-time {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;
    }

    .add-note__input {
        margin: 30px 0 0;
        width: 100%;
        height: 24px;
        border: 1px solid #000;
        border-radius: 5px;
        padding: 2px 10px;
    }

    .add-note__text {
        margin-top: 20px;
        width: 100%;
        height: 155px;
        border: 1px solid #000;
        border-radius: 5px;
        padding: 10px;
    }

    .add-note__red-highlight {
        background: #ffdeda;
    }

    .add-note__yellow-highlight {
        background: #fff9da;
    }

    .add-note__red-highlight,
    .add-note__yellow-highlight {
        color: transparent;
    }

    .add-note__highlight {
        display: grid;
        grid-template-columns: 1fr 278px;
        align-items: center;
        margin-top: 15px;
    }
`;

export default StyledForm;
