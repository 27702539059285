import { IUser } from '../../utils/types';

/* eslint-disable */
export enum UserApiPath {
    LOGIN = '/auth/sign-in',
    LOGOUT = '/auth/logout',
    AUTH_ME = '/auth/me/',
}
/* eslint-enable */

export type LoginPropsType = {
    username: string;
    password: string;
    deviceId: string;
};

export type LogoutResponseType = {
    emailOrUsername: string;
    password: string;
};

export type AuthMeResponse =
    | {
          data: {
              message: string;
              user: IUser;
          };
      }
    | undefined;
