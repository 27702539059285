import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../store';
import { setAddedClient, clearCheckInnResult } from '../../store/slices/clientDatabaseSlice';
import { DocumentSectionsType } from '../../utils/data';
import { selectClientCard } from '../../store/slices/clientCardSlice';
import { DocumentCategoryInterface } from '../../utils/types';
import { selectClientDocument } from '../../store/slices/clientDocumentSlice';
import EditActions from './EditActions/EditActions';
import AddDocumentCategory from './AddDocumentCategory/AddDocumentCategory';
import EditOrDeleteCategory from './EditOrDeleteCategory/EditOrDeleteCategory';
import CommonModal from '../Common/CommonModal/CommonModal';
import StyledFormWrapper from './EditDocumentSection.style';

type EditDocumentSectionProps = {
    close: () => void;
    width: number;
    section: DocumentSectionsType;
    categories: DocumentCategoryInterface[];
};

const EditDocumentSection = ({ close, width, section, categories }: EditDocumentSectionProps) => {
    const dispatch = useAppDispatch();
    const firstUpdate = useRef(true);
    const { company } = useSelector(selectClientCard);
    const { isRequestFulfilled } = useSelector(selectClientDocument);
    const [step, setStep] = useState(1);

    const formWidth = step === 3 ? '80vw' : `${width}px`;

    const returnToHome = () => setStep(1);

    const getPageByStep = (step: number) => {
        if (!company) return null;
        switch (step) {
            case 1:
                return <EditActions setStep={setStep} title={section.title} />;
            case 2:
                return (
                    <AddDocumentCategory
                        previousStep={returnToHome}
                        section={section.sectionName}
                        companyId={company.companyId}
                    />
                );
            case 3:
                return (
                    <EditOrDeleteCategory
                        categories={categories}
                        previousStep={returnToHome}
                        companyId={company.companyId}
                    />
                );
            default:
                break;
        }
    };

    useEffect(() => {
        dispatch(setAddedClient({}));
        dispatch(clearCheckInnResult());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (step === 1) dispatch(clearCheckInnResult());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if (isRequestFulfilled.createDocumentCategory) {
            close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRequestFulfilled.createDocumentCategory]);

    return (
        <CommonModal hasCloseBtn={true} close={close}>
            <StyledFormWrapper width={formWidth}>{getPageByStep(step)}</StyledFormWrapper>
        </CommonModal>
    );
};

export default EditDocumentSection;
