import styled from 'styled-components/macro';

const StyledForm = styled.form<{ width: number }>`
    width: ${({ width }) => `${width}px`};
    position: relative;
    min-height: 100px;
    padding-bottom: 50px;
    margin-top: 20px;

    .reminder-list__button {
        border: none;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #0038ff;
        position: absolute;
        right: 0;
        bottom: 10px;
    }

    .reminder-list__no-reminders {
        text-align: center;
    }
`;

export default StyledForm;
