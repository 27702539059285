/* eslint-disable no-unreachable */
import React, { useEffect, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import toast from 'react-hot-toast';

import { useAppDispatch } from '../../store';
import { editOfferValidationSchema } from '../../validation/clientTask';
import {
    createTaskOfferDoc,
    deleteTaskOfferDoc,
    selectClientTask,
    updateTaskOffer,
} from '../../store/slices/clientTaskSlice';
import {
    BankGuaranteeTaskOfferStatuses,
    CommissionType,
    CommissionTypeENUM,
    LoanTaskOfferStatuses,
    OfferDocTypes,
    editBankGuaranteeOfferField,
    editLoanOfferField,
    editOfferFieldNameType,
    editOfferFieldType,
} from '../../utils/data';
import {
    EditOfferProps,
    Inputs,
    commissions,
    EditOfferInputType,
    EditOfferInputTypeENUM,
    AdminCommissionType,
} from './EditOffer.types';
import { stringToNumber, formatNum, getAmountInRub } from '../../utils/functions';
import { StyledScrollbars } from '../AddClient/AddClientsInfo/AddClientsInfo.style';
import { userSelector } from '../../store/slices/userSlice';
import CommonModal from '../Common/CommonModal/CommonModal';
import CustomSelect from '../Common/CustomSelect/CustomSelect';
import DeleteDocIcon from '../../assets/images/staff/delete-doc-icon.png';
import BtnLoader from '../Common/BtnLoader/BtnLoader';
import BulletListIcon from '../../assets/images/bullet-list-icon.png';
import AddFile from '../AddFile/AddFile';
import { StyledFormWrapper, StyledContainer } from './EditOffer.style';

const commissionsForAdminOnly: AdminCommissionType[] = [
    CommissionTypeENUM.BASE_COMMISSION,
    CommissionTypeENUM.CLIENT_COMMISSION,
];

const EditOffer = ({
    close,
    width,
    companyId,
    taskId,
    offer,
    bankGuaranteeAmount,
    bankGuaranteeTermDays,
    isLoan,
}: EditOfferProps) => {
    const dispatch = useAppDispatch();
    const firstUpdate = useRef(true);
    const { user } = useSelector(userSelector);
    const { loading, isRequestFulfilled } = useSelector(selectClientTask);
    const [addingDocument, setAddingDocument] = useState<OfferDocTypes | null>(null);
    const prevOverstatement = useRef(0);

    const [offerDocs, setOfferDocs] = useState({
        guarantee: offer.documents.find((doc) => doc.section === 'guarantee'),
        layout: offer.documents.find((doc) => doc.section === 'layout'),
    });

    const formOptions = {
        defaultValues: {
            bankCommission: offer.bankCommission || '',
            baseCommission: offer.baseCommission || '',
            clientCommission: offer.clientCommission || '',
            bankCommissionInRub: getAmountInRub(offer.bankCommissionInRub),
            baseCommissionInRub: getAmountInRub(offer.baseCommissionInRub),
            clientCommissionInRub: getAmountInRub(offer.clientCommissionInRub),
            comment: offer.comment || '',
            overstatement: getAmountInRub(offer.overstatement),
            status: offer.status || '',
            agentSeparation: offer.agentSeparation || '50',
            amount: getAmountInRub(offer.amount),
            rateFrom: offer.rateFrom || '',
            term: offer.term || '',
        },
        resolver: yupResolver(editOfferValidationSchema),
    };
    const { register, handleSubmit, formState, setValue, watch } = useForm<Inputs>(formOptions);
    const { errors } = formState;

    const offerStatus = watch(EditOfferInputTypeENUM.STATUS);
    const overstatement = watch(EditOfferInputTypeENUM.OVERSTATEMENT);
    const percentCommissions = {
        bankCommission: watch(EditOfferInputTypeENUM.BANK_COMMISSION),
        baseCommission: watch(EditOfferInputTypeENUM.BASE_COMMISSION),
        clientCommission: watch(EditOfferInputTypeENUM.CLIENT_COMMISSION),
    };
    const rubCommissions = {
        bankCommission: watch(EditOfferInputTypeENUM.BANK_COMMISSION_IN_RUB),
        baseCommission: watch(EditOfferInputTypeENUM.BASE_COMMISSION_IN_RUB),
        clientCommission: watch(EditOfferInputTypeENUM.CLIENT_COMMISSION_IN_RUB),
    };
    const agentSeparation = watch(EditOfferInputTypeENUM.AGENT_SEPARATION);
    const loanAmount = watch(EditOfferInputTypeENUM.AMOUNT);
    const term = watch(EditOfferInputTypeENUM.TERM);
    const rateFrom = watch(EditOfferInputTypeENUM.RATE_FROM);
    const height = offerStatus === 'givenOut' ? 512 : 410;
    const allowForNotGivenOffer = (fieldName: editOfferFieldNameType) => {
        return (
            fieldName !== EditOfferInputTypeENUM.BASE_COMMISSION &&
            fieldName !== EditOfferInputTypeENUM.CLIENT_COMMISSION
        );
    };

    const overstatementChangeHandler = (newOverstatement: string) => {
        const oldOverstatement = overstatement || '';
        const formattedNum = formatNum(newOverstatement, oldOverstatement, false);
        prevOverstatement.current = stringToNumber(oldOverstatement);
        setValue(EditOfferInputTypeENUM.OVERSTATEMENT, formattedNum);
    };

    const formFieldsByProductType = isLoan ? editLoanOfferField : editBankGuaranteeOfferField;
    const formFields = formFieldsByProductType.filter(
        (field) =>
            offerStatus === 'givenOut' ||
            allowForNotGivenOffer(field.name as editOfferFieldNameType),
    );

    const taskOfferStatuses = isLoan ? LoanTaskOfferStatuses : BankGuaranteeTaskOfferStatuses;

    const autoFillCommissions = (bankCommissions: number) => {
        const normalizedOverstatement = stringToNumber(overstatement);
        const agentCommission = Number(agentSeparation) * 0.01;
        commissionsForAdminOnly.forEach((type) => {
            const newCommissionInRub =
                type === CommissionTypeENUM.CLIENT_COMMISSION
                    ? Number(percentCommissions[type as AdminCommissionType]) *
                      bankCommissions *
                      0.01
                    : Number(percentCommissions[type as AdminCommissionType]) *
                          0.01 *
                          (bankCommissions - normalizedOverstatement) +
                      normalizedOverstatement * agentCommission;
            setValue(`${type}InRub`, formatNum(newCommissionInRub.toFixed(2)));
        });
    };

    const changeCommissionInRubHandler = (value: string, type: CommissionType) => {
        const commissionInRub = stringToNumber(value);
        const amountInRub = isLoan
            ? stringToNumber(loanAmount)
            : stringToNumber(bankGuaranteeAmount || '');

        if (Number.isNaN(commissionInRub)) {
            const oldValue = rubCommissions[type];
            setValue(`${type}InRub`, oldValue);
            return;
        }

        const noTermForGuarantee = !bankGuaranteeTermDays && !isLoan;
        const noValuesInRubles = !commissionInRub || !amountInRub;

        if (noValuesInRubles || noTermForGuarantee) {
            setValue(type, '0');

            if (type === CommissionTypeENUM.BANK_COMMISSION) {
                autoFillCommissions(0);
            }

            return;
        }

        setValue(`${type}InRub`, formatNum(value));

        let commissionInPercent = 0;

        if (isLoan) {
            commissionInPercent = (commissionInRub * 100) / amountInRub;
            setValue(type, String(commissionInPercent));
            return;
        }

        if (type === CommissionTypeENUM.BANK_COMMISSION) {
            commissionInPercent = bankGuaranteeTermDays
                ? (commissionInRub / amountInRub) * (365 / bankGuaranteeTermDays)
                : 0;
            autoFillCommissions(commissionInRub);
        } else {
            let bankCommissionInRub = stringToNumber(rubCommissions.bankCommission);
            const normalizedOverstatement = stringToNumber(overstatement);

            if (!bankCommissionInRub) {
                setValue(type, '0');
                return;
            }

            if (type === CommissionTypeENUM.BASE_COMMISSION) {
                const agentCommission = Number(agentSeparation) * 0.01;
                commissionInPercent =
                    (commissionInRub - normalizedOverstatement * agentCommission) /
                    (bankCommissionInRub - normalizedOverstatement);
            } else {
                commissionInPercent = commissionInRub / bankCommissionInRub;
            }
        }

        commissionInPercent *= 100;

        setValue(type, String(commissionInPercent));
    };

    const changeCommissionInPercentHandler = (
        value: string,
        type: CommissionType,
        newAgentSeparation?: string,
    ) => {
        const normalizedBankGuaranteeAmount = stringToNumber(bankGuaranteeAmount || '');

        const noGuaranteeTerm = !bankGuaranteeTermDays || !normalizedBankGuaranteeAmount;
        if (!isLoan && noGuaranteeTerm) return;

        const normalizedCommission = stringToNumber(value) * 0.01;

        if (Number.isNaN(normalizedCommission)) {
            const oldValue = percentCommissions[type];
            setValue(type, oldValue);
            return;
        }

        setValue(type, value);

        const amountInRub = isLoan
            ? stringToNumber(loanAmount)
            : normalizedBankGuaranteeAmount * ((bankGuaranteeTermDays || 0) / 365);
        const normalizedOverstatement = stringToNumber(overstatement);

        if (!normalizedCommission || !amountInRub) {
            setValue(`${type}InRub`, '0');

            if (!isLoan && type === CommissionTypeENUM.BANK_COMMISSION) {
                autoFillCommissions(0);
            }
            return;
        }

        if (isLoan) {
            const loanComissionInRub = amountInRub * normalizedCommission;
            setValue(`${type}InRub`, formatNum(loanComissionInRub.toFixed(2)));
            return;
        }

        let commissionInRub = 0;

        if (type === CommissionTypeENUM.BANK_COMMISSION) {
            commissionInRub = normalizedCommission * amountInRub;
            autoFillCommissions(commissionInRub);
        } else {
            let bankCommissionInRub = stringToNumber(rubCommissions.bankCommission);

            if (!bankCommissionInRub) {
                setValue(type, '0');
                return;
            }

            if (type === CommissionTypeENUM.BASE_COMMISSION) {
                const agentCommission = (Number(newAgentSeparation || agentSeparation) || 0) * 0.01;
                commissionInRub =
                    (bankCommissionInRub - normalizedOverstatement) * normalizedCommission +
                    normalizedOverstatement * agentCommission;
            } else {
                commissionInRub = bankCommissionInRub * normalizedCommission;
            }
        }
        setValue(`${type}InRub`, formatNum(commissionInRub.toFixed(2)));
    };

    const changeAgentSeparation = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isLoan) return;
        const oldAgentSeparation = agentSeparation;
        let { value } = e.target;
        const normalizedAgentSeparation = Number(value.split(',').join('.'));

        if (
            Number.isNaN(normalizedAgentSeparation) ||
            normalizedAgentSeparation > 100 ||
            value.startsWith('-')
        ) {
            value = oldAgentSeparation;
        }

        setValue(EditOfferInputTypeENUM.AGENT_SEPARATION, value);
        const baseCommission = percentCommissions.baseCommission;
        changeCommissionInPercentHandler(
            baseCommission,
            EditOfferInputTypeENUM.BASE_COMMISSION,
            value,
        );
    };

    const inputChangeHandler = (value: string, field: EditOfferInputType) => {
        const valueNumber = stringToNumber(value);
        if (Number.isNaN(valueNumber)) {
            let oldValue = '';
            if (field === EditOfferInputTypeENUM.AMOUNT) {
                oldValue = loanAmount;
            }
            if (field === EditOfferInputTypeENUM.TERM) {
                oldValue = term;
            }
            if (field === EditOfferInputTypeENUM.RATE_FROM) {
                oldValue = rateFrom;
            }
            setValue(field, oldValue);
            return;
        }
        if (field === EditOfferInputTypeENUM.AMOUNT) {
            setValue(field, formatNum(value));
            const bankCommissionInRubles = formatNum(
                (
                    (stringToNumber(value) * stringToNumber(percentCommissions.bankCommission)) /
                    100
                ).toFixed(2),
            );
            const baseCommissionInRubles = formatNum(
                (
                    (stringToNumber(value) * stringToNumber(percentCommissions.baseCommission)) /
                    100
                ).toFixed(2),
            );
            setValue(EditOfferInputTypeENUM.BANK_COMMISSION_IN_RUB, bankCommissionInRubles);
            setValue(EditOfferInputTypeENUM.BASE_COMMISSION_IN_RUB, baseCommissionInRubles);
        }
        if (field === EditOfferInputTypeENUM.TERM) {
            setValue(field, formatNum(value, term, true));
        }
    };

    const fileSelectionHandler = (name: string, file: string) => {
        if (!addingDocument) return;

        const value = {
            file,
            name,
        };
        dispatch(
            createTaskOfferDoc({
                ...value,
                section: addingDocument,
                taskId,
                offerId: offer.taskOfferId,
                companyId,
            }),
        );
    };

    const deleteDoc = (taskOfferDocumentId?: number) => {
        if (!taskOfferDocumentId) return;

        dispatch(
            deleteTaskOfferDoc({
                taskId,
                offerId: offer.taskOfferId,
                companyId,
                taskOfferDocumentId,
            }),
        );
    };

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const {
            bankCommission,
            baseCommission,
            clientCommission,
            overstatement,
            comment,
            status,
            bankCommissionInRub,
            baseCommissionInRub,
            clientCommissionInRub,
            amount,
            term,
            rateFrom,
        } = data;
        const normalizedBankCommission =
            bankCommission !== offer.bankCommission ? stringToNumber(bankCommission) : undefined;
        const normalizedСlientCommission =
            clientCommission !== offer.clientCommission
                ? stringToNumber(clientCommission)
                : undefined;
        const normalizedBaseCommission =
            baseCommission !== offer.baseCommission ? stringToNumber(baseCommission) : undefined;
        const normalizedOverstatement =
            overstatement.split(' ').join('') !== offer.overstatement
                ? stringToNumber(overstatement)
                : undefined;
        const normalizedBankCommissionInRub =
            bankCommissionInRub !== offer.bankCommissionInRub
                ? stringToNumber(bankCommissionInRub)
                : undefined;
        const normalizedСlientCommissionInRub =
            clientCommissionInRub !== offer.clientCommissionInRub
                ? stringToNumber(clientCommissionInRub)
                : undefined;
        const normalizedBaseCommissionInRub = stringToNumber(baseCommissionInRub);
        const normalizedAgentSeparation =
            agentSeparation !== offer.agentSeparation ? stringToNumber(agentSeparation) : undefined;
        const normalizedAmount = amount !== offer.amount ? stringToNumber(amount) : undefined;
        const normalizedTerm = term !== offer.term ? stringToNumber(term) : undefined;
        const normalizedRateFrom =
            rateFrom !== offer.rateFrom ? stringToNumber(rateFrom) : undefined;
        dispatch(
            updateTaskOffer({
                companyId,
                taskId,
                offerId: offer.taskOfferId,
                bankCommission: normalizedBankCommission,
                baseCommission: normalizedBaseCommission,
                clientCommission: normalizedСlientCommission,
                bankCommissionInRub: normalizedBankCommissionInRub,
                baseCommissionInRub: normalizedBaseCommissionInRub,
                clientCommissionInRub: normalizedСlientCommissionInRub,
                agentSeparation: normalizedAgentSeparation,
                comment: comment !== offer.comment ? comment : undefined,
                overstatement: normalizedOverstatement,
                status: status !== offer.status ? status : undefined,
                amount: normalizedAmount,
                term: normalizedTerm,
                rateFrom: normalizedRateFrom,
            }),
        );
    };

    const getInputField = (field: editOfferFieldType) => {
        const isCommission = field.name.endsWith('Commission');
        switch (field.type) {
            case 'input':
                return field.name === EditOfferInputTypeENUM.OVERSTATEMENT ? (
                    <>
                        <div>
                            <p>{field.label}:</p>
                            <input
                                className={clsx({
                                    'edit-offer__error-input':
                                        errors[field.name as editOfferFieldNameType]?.message,
                                })}
                                id={field.name}
                                {...register(field.name as editOfferFieldNameType)}
                                onChange={(e) => overstatementChangeHandler(e.target.value)}
                                disabled={!rubCommissions.bankCommission}
                            />
                        </div>
                        <div>
                            <p>Разделение:</p>
                            <div className="edit-offer__separation">
                                <p>Банк:</p>
                                <input value={100 - stringToNumber(agentSeparation)} disabled />
                                <p>Агент:</p>
                                <input
                                    className={clsx({
                                        'edit-offer__error-input':
                                            errors[field.name as CommissionType]?.message,
                                    })}
                                    id={EditOfferInputTypeENUM.AGENT_SEPARATION}
                                    {...register(EditOfferInputTypeENUM.AGENT_SEPARATION)}
                                    onChange={changeAgentSeparation}
                                />
                            </div>
                        </div>
                    </>
                ) : isCommission ? (
                    <div className="edit-offer__double-input">
                        <p>в руб.:</p>
                        <input
                            className={clsx({
                                'edit-offer__error-input':
                                    errors[field.name as CommissionType]?.message,
                            })}
                            id={`${field.name}InRub`}
                            {...register(`${field.name}InRub` as editOfferFieldNameType)}
                            disabled={
                                !(
                                    user?.isAdmin ||
                                    field.name === EditOfferInputTypeENUM.BANK_COMMISSION
                                )
                            }
                            onChange={(e) =>
                                changeCommissionInRubHandler(
                                    e.target.value,
                                    field.name as CommissionType,
                                )
                            }
                        />
                        <p>в %:</p>
                        <input
                            className={clsx({
                                'edit-offer__error-input':
                                    errors[field.name as CommissionType]?.message,
                            })}
                            id={field.name}
                            disabled={
                                !(
                                    user?.isAdmin ||
                                    field.name === EditOfferInputTypeENUM.BANK_COMMISSION
                                )
                            }
                            {...register(field.name as CommissionType)}
                            onChange={(e) =>
                                changeCommissionInPercentHandler(
                                    e.target.value,
                                    field.name as CommissionType,
                                )
                            }
                        />
                    </div>
                ) : (
                    <>
                        <div>
                            <input
                                className={clsx({
                                    'edit-offer__error-input':
                                        errors[field.name as editOfferFieldNameType]?.message,
                                })}
                                id={field.name}
                                {...register(field.name as editOfferFieldNameType)}
                                onChange={(e) =>
                                    inputChangeHandler(e.target.value, field.name as CommissionType)
                                }
                            />
                        </div>
                    </>
                );
            case 'list':
                return (
                    <CustomSelect width={401} height={24} backgroundImage={BulletListIcon}>
                        <select
                            {...register(field.name as editOfferFieldNameType)}
                            disabled={offer.status === 'givenOut'}
                        >
                            {taskOfferStatuses.map((item) => (
                                <option key={item.name} value={item.name}>
                                    {item.label}
                                </option>
                            ))}
                        </select>
                    </CustomSelect>
                );
            case 'textarea':
                return (
                    <textarea
                        className={clsx('edit-offer__comment', {
                            'edit-offer__error-input':
                                errors[field.name as editOfferFieldNameType]?.message,
                        })}
                        id={field.name}
                        {...register(field.name as editOfferFieldNameType)}
                    />
                );
            case 'document':
                return (
                    <div>
                        <div className="edit-offer__document">
                            <p>{field.label}</p>
                            <button
                                type="button"
                                onClick={() => setAddingDocument(field.name as OfferDocTypes)}
                            >
                                {!offerDocs[field.name as OfferDocTypes] ? 'Добавить' : 'Заменить'}
                            </button>
                        </div>
                        <div className="edit-offer__doc-name">
                            <p
                                className={clsx({
                                    'edit-offer__doc-link':
                                        offerDocs[field.name as OfferDocTypes]?.name,
                                })}
                            >
                                {offerDocs[field.name as OfferDocTypes]?.name ||
                                    'Загрузите документ'}
                            </p>
                            {offerDocs[field.name as OfferDocTypes]?.name ? (
                                <img
                                    src={DeleteDocIcon}
                                    onClick={() =>
                                        deleteDoc(
                                            offerDocs[field.name as OfferDocTypes]
                                                ?.taskOfferDocumentId,
                                        )
                                    }
                                />
                            ) : null}
                        </div>
                    </div>
                );
            default:
                break;
        }
    };

    useEffect(() => {
        if (!offer) return;

        setOfferDocs({
            guarantee: offer.documents.find((doc) => doc.section === 'guarantee'),
            layout: offer.documents.find((doc) => doc.section === 'layout'),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offer.documents]);

    useEffect(() => {
        commissions.forEach((type) => {
            const typeInRub = `${type}InRub` as editOfferFieldNameType;
            setValue(type, offer[type] || '');
            setValue(typeInRub, getAmountInRub(offer[typeInRub]));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offer, bankGuaranteeAmount]);

    useEffect(() => {
        if (firstUpdate.current || isLoan) return;

        const newOverstatement = stringToNumber(overstatement);

        if (newOverstatement === prevOverstatement.current) return;

        const oldBankCommission = rubCommissions.bankCommission;
        const newBankCommission =
            stringToNumber(oldBankCommission) - prevOverstatement.current + newOverstatement;
        changeCommissionInRubHandler(String(newBankCommission), CommissionTypeENUM.BANK_COMMISSION);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [overstatement, prevOverstatement.current]);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if (isRequestFulfilled.updateTaskOffer) {
            close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRequestFulfilled.updateTaskOffer]);

    useEffect(() => {
        Object.keys(editOfferValidationSchema.fields).forEach((key) => {
            const errorMessage = errors[key as editOfferFieldNameType]?.message;
            if (errorMessage) {
                toast.error(errorMessage);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);

    return (
        <CommonModal hasCloseBtn={true} close={close}>
            <StyledFormWrapper width={width}>
                <StyledContainer height={height}>
                    <h3 className="edit-offer__title">Редактировать предложение</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <StyledScrollbars
                            style={{ height: 'calc(100% - 30px)' }}
                            renderTrackHorizontal={(props) => (
                                <div
                                    {...props}
                                    style={{ display: 'none' }}
                                    className="track-horizontal"
                                />
                            )}
                            thumbSize={170}
                            thumbMinSize={30}
                        >
                            {formFields.map((field) => (
                                <div
                                    key={field.name}
                                    className={clsx('edit-offer__label', {
                                        'edit-offer__yellow-field':
                                            field.name === EditOfferInputTypeENUM.BASE_COMMISSION ||
                                            field.name === EditOfferInputTypeENUM.CLIENT_COMMISSION,
                                        'edit-offer__overstatement':
                                            field.name === EditOfferInputTypeENUM.OVERSTATEMENT,
                                    })}
                                >
                                    {field.type !== 'document' &&
                                        field.name !== EditOfferInputTypeENUM.OVERSTATEMENT && (
                                            <>
                                                {field.label}
                                                {field.isRequired && (
                                                    <span className="edit-offer__sign-of-obligatory-field">
                                                        *
                                                    </span>
                                                )}
                                                :
                                            </>
                                        )}
                                    {getInputField(field)}
                                </div>
                            ))}
                        </StyledScrollbars>
                        <BtnLoader
                            isLoading={loading.updateTaskOffer}
                            btnTitle="Сохранить"
                            btnClass={clsx('edit-offer__btn', {
                                'edit-offer__disabled-btn': loading.updateTaskOffer,
                            })}
                        />
                    </form>
                </StyledContainer>
                {addingDocument && (
                    <AddFile
                        close={() => setAddingDocument(null)}
                        width={400}
                        setFile={fileSelectionHandler}
                        title="Добавить документ"
                        loading={loading.uploadTaskFile}
                        isFulfilled={isRequestFulfilled.uploadFile}
                    />
                )}
            </StyledFormWrapper>
        </CommonModal>
    );
};

export default EditOffer;
