import { IUser, UserDocInterface } from '../../utils/types';

/* eslint-disable */
export enum UserApiPath {
    USER_CRUD = '/user',
    USER_DOCUMENTS = '/documents',
}
/* eslint-enable */

export type userDoc = {
    name: string;
    file: string;
    id?: number;
};

export type CreateUserType = {
    fullName?: string;
    shortName?: string;
    positionName: string;
    email: string;
    phone: string;
    status: string;
    bot: boolean;
    isAdmin: boolean;
    isCallRecordEnabled: boolean;
    isFullBankInfoEnabled: boolean;
    birthDate: string;
    login: string;
    additNumber: string;
    password: string;
    passport: userDoc;
    snils: userDoc;
    inn: userDoc;
};

export type UpdateUserType = {
    fullName?: string | null;
    shortName?: string | null;
    positionName?: string | null;
    email?: string;
    phone?: string | null;
    status?: string;
    bot?: boolean;
    isAdmin?: boolean;
    isCallRecordEnabled?: boolean;
    isFullBankInfoEnabled?: boolean;
    birthDate?: Date | string | null;
    login?: string | null;
    additNumber?: string | null;
    password?: string;
    passport?: userDoc;
    snils?: userDoc;
    inn?: userDoc;
    userId: number;
};

export type GetOneUserType = {
    userId: number;
};

export type GetAllUsersType = {
    onlyActiveUsers?: boolean;
    positionName?: 'OSKO' | 'PFM';
};

export type CreateUpdateUserResponse =
    | {
          data: IUser;
          message: string;
      }
    | undefined;

export type GetUserDocResponse =
    | {
          data: UserDocInterface[];
          message: string;
      }
    | undefined;

export type GetAllUserResponse =
    | {
          data: IUser[];
          message: string;
      }
    | undefined;
