import styled from 'styled-components/macro';

const StyledNews = styled.div`
    text-align: start;
    margin-right: 12px;
    p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #000;
    }

    .news_block {
        height: calc(100% - 16px);
        display: flex;
        justify-content: end;
        align-items: end;
        border-radius: 5px;
        border: 1px solid #000;
        span {
            margin-right: 5px;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            text-decoration-line: underline;
            color: #0038ff;
        }
    }
`;

export default StyledNews;
