import React, { useMemo } from 'react';
import clsx from 'clsx';
import { productTableHeadings } from '../../../../utils/data';
import StarIcon from '../../../../assets/images/partners/star-icon.png';

type BankProductHeadingsProps = {
    productIndex: number;
};

const BankProductHeadings = ({ productIndex }: BankProductHeadingsProps) => {
    const tableHeadings = useMemo(() => productTableHeadings[String(productIndex)], [productIndex]);
    return tableHeadings ? (
        <div className="bank-product-list__table-header">
            {tableHeadings.map((head, index) => (
                <div
                    key={head + index}
                    className={clsx({
                        'bank-product-list__table-header-right-position': index,
                        'bank-product-list__red-column': index > 11,
                    })}
                >
                    {head === 'star' ? <img src={StarIcon} /> : <p>{head}</p>}
                </div>
            ))}
        </div>
    ) : null;
};

export default BankProductHeadings;
