import { formatDateString } from '../../../utils/functions';
import { CallsDateRangesType, CallsFilterParams } from './CallsComponent.types';

export const callsDateRanges: CallsDateRangesType[] = ['dateFrom', 'dateTo'];

export const callsDefaultFilterParams: CallsFilterParams = {
    manager: '',
    dateFrom: formatDateString(new Date()),
    dateTo: formatDateString(new Date()),
    direction: '',
    phone: '',
    duration: '',
};
