import styled from 'styled-components/macro';

const StyledContainer = styled.div`
    font-weight: 300;
    font-size: 12px;

    .inn-is-occupied__inn-number {
        line-height: 14px;
        padding: 0;
        margin: 13px 0;

        span {
            font-weight: 510;
        }
    }

    .inn-is-occupied__description {
        font-style: italic;
        line-height: 14px;
        color: #000;
        margin: 8px 0 15px;
    }

    .inn-is-occupied__red-text {
        color: #ff0000;
    }

    .inn-is-occupied__client-name {
        font-weight: 510;
        padding: 4px 0;

        button {
            font-weight: 300;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .inn-is-occupied__manager {
        padding: 4px 0;
        font-weight: 510;
    }
`;

export default StyledContainer;
