import React, { useState } from 'react';
import { clsx } from 'clsx';

import CommonModal from '../Common/CommonModal/CommonModal';
import DefaultLoader from '../Common/BtnLoader/DefaultLoader';
import StyledWrapper from './AddFile.style';

type AddFileProps = {
    close: () => void;
    width: number;
    // eslint-disable-next-line no-unused-vars
    setFile: (name: string, file: string) => void;
    title: string;
    loading?: boolean;
    isFulfilled?: boolean;
};

const AddFile = ({ close, width, setFile, title, loading }: AddFileProps) => {
    const [selectedFile, setSelectedFile] = useState<null | File>(null);

    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event?.target?.files) return;
        const file = event.target.files[0];
        if (!file) return;
        setSelectedFile(file);
    };

    const addDocumentHandler = () => {
        if (!selectedFile) return;
        const reader = new FileReader();

        reader.onloadend = () => {
            if (!reader.result) return;

            setFile(selectedFile.name, String(reader.result));
            close();
        };
        reader.readAsDataURL(selectedFile);
    };

    return (
        <CommonModal hasCloseBtn={true} close={close}>
            <StyledWrapper width={width}>
                <h3 className="add-file__header">{title}</h3>
                <input
                    className="add-file__file-input"
                    type="file"
                    onChange={(e) => handleFileSelect(e)}
                />
                <button
                    type="button"
                    onClick={addDocumentHandler}
                    className={clsx('add-file__btn', {
                        'add-file__disabled-btn': loading,
                    })}
                >
                    {loading ? <DefaultLoader /> : 'Сохранить'}
                </button>
            </StyledWrapper>
        </CommonModal>
    );
};

export default AddFile;
