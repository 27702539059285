import * as Yup from 'yup';

import { onlyNumChecking } from '../utils/functions';

export const addClientValidationSchema = Yup.object().shape({
    inn: Yup.string()
        .required('Заполните поле ИНН')
        .test('num', 'ИНН должен состоять только из цифр', onlyNumChecking)
        .test(
            'len',
            'ИНН должен состоять из 10 и более цифр',
            (val) => typeof val === 'string' && val.length >= 10,
        ),
    name: Yup.string().required('Заполните поле наименование компании'),
    region: Yup.string().required('Заполните поле регион'),
    city: Yup.string().required('Заполните поле город'),
    addressLegal: Yup.string().required('Заполните поле юридический адрес'),
    addressActual: Yup.string().required('Заполните поле фактический адрес'),
    ogrn: Yup.string().required('Заполните поле ОГРН'),
    kpp: Yup.string().required('Заполните поле КПП'),
    okved: Yup.string().required('Заполните поле ОКВЭД  '),
    headName: Yup.string().required('Заполните поле ФИО руководителя'),
    headPost: Yup.string().required('Заполните поле должность руководителя'),
    contactPhone: Yup.string().notRequired(),
    registrationDate: Yup.string(),
    email: Yup.string().email('email заполнен некорректно').max(255),
    comment: Yup.string(),
});

export const checkINNValidationSchema = Yup.object().shape({
    inn: Yup.string()
        .required('Заполните ИНН')
        .test('num', 'ИНН должен состоять только из цифр', onlyNumChecking)
        .test(
            'len',
            'ИНН должен состоять из 10 и более цифр',
            (val) => typeof val === 'string' && val.length >= 10,
        ),
});
