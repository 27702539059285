import React from 'react';

import DefaultLoader from './DefaultLoader';

type BtnLoaderProps = {
    isLoading: boolean;
    btnTitle: string;
    btnClass?: string;
};

const BtnLoader = ({ isLoading, btnTitle, btnClass }: BtnLoaderProps) => {
    const className = btnClass || '';
    const loader = isLoading ? <DefaultLoader /> : btnTitle;

    return (
        <button disabled={isLoading} className={className}>
            {loader}
        </button>
    );
};

export default BtnLoader;
