import styled from 'styled-components/macro';

const StyledClientsPageWrapper = styled.div`
    .clients-page__heading {
        padding-top: 53px;
        padding-left: 231px;
        font-size: 32.8px;
        font-weight: 600;
    }
`;

export default StyledClientsPageWrapper;
