/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';
import { RotatingLines } from 'react-loader-spinner';

import { CallsTableColumnType } from '../../../../utils/data';
import StyledTable from './CallsTable.style';
import EmptyList from '../../../../components/EmptyList/EmptyList';
import CallsTableRow from '../CallsTableRow/CallsTableRow';
import { CallStatusENUM, CallsRecordInterface, IUser } from '../../../../utils/types';
import { formatTimeInSeconds } from '../../../../utils/functions';
import clsx from 'clsx';

type CallsTableProps = {
    callRecords: CallsRecordInterface[];
    callsLoading: boolean;
    tableColumns: CallsTableColumnType[];
    user: IUser | null;
    callInPlayer: CallsRecordInterface | null;
    isPlayingRecord: boolean;
    isTabView: boolean;
    playRecord: (record: CallsRecordInterface) => void;
    pauseRecord: (record: CallsRecordInterface) => void;
};
const CallsTable = ({
    callRecords,
    callsLoading,
    tableColumns,
    user,
    callInPlayer,
    isPlayingRecord,
    isTabView,
    playRecord,
    pauseRecord,
}: CallsTableProps) => {
    const callStatistic = useMemo(() => {
        const amount = `Кол-во: ${callRecords.length}`;
        const duration = callRecords.reduce((acc, call) => {
            if (call.status === CallStatusENUM.SUCCESS) {
                acc = acc + Number(call.duration);
            }
            return acc;
        }, 0);
        return {
            totalDuration: formatTimeInSeconds(duration),
            amount,
        };
    }, [callRecords]);
    const getCallsOrText = (callsArray: CallsRecordInterface[]) => {
        return !callsArray.length ? (
            <EmptyList />
        ) : (
            callsArray.map((call, index) => (
                <CallsTableRow
                    key={call.callId}
                    call={call}
                    index={index}
                    user={user}
                    playRecord={playRecord}
                    pauseRecord={pauseRecord}
                    callInPlayer={callInPlayer}
                    isPlayingRecord={isPlayingRecord}
                    isTabView={isTabView}
                />
            ))
        );
    };

    return (
        <StyledTable>
            <div className={clsx('calls-table__row', { 'calls-table__tab-row': isTabView })}>
                <div
                    className={clsx('calls-table__duration', {
                        'calls-table__tab-duration': isTabView,
                    })}
                >
                    {callStatistic.totalDuration}
                </div>
                <div
                    className={clsx('calls-table__amount', {
                        'calls-table__tab-amount': isTabView,
                    })}
                >
                    {callStatistic.amount}
                </div>
            </div>
            <div className={clsx('calls-table__headings', { 'calls-table__tab-row': isTabView })}>
                {tableColumns.map((column) => (
                    <div key={column.name} className="calls-table__column">
                        <p>{column.label}</p>
                    </div>
                ))}
            </div>
            <div className="calls-table__rows">
                {callsLoading ? (
                    <div className="calls-table__loader">
                        <RotatingLines strokeColor="grey" />
                    </div>
                ) : (
                    getCallsOrText(callRecords)
                )}
            </div>
        </StyledTable>
    );
};

export default CallsTable;
