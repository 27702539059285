import styled from 'styled-components/macro';

const StyledHeader = styled.div`
    margin: 15px;
    display: grid;
    grid-template-columns: 225px 1fr 321px;
    margin-bottom: 12px;
    font-size: 12px;

    .header__logo {
        margin: 24px 28px 2px 26px;
        width: 171px;
        height: 57px;
    }

    .header__personal-info-container {
        text-align: end;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 50px;

        p {
            margin: 0;
            word-break: break-word;
        }
    }

    .header__personal-info {
        text-align: end;
        display: flex;
        flex-direction: column;
        align-items: end;
    }

    .header__close-button {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        text-decoration-line: underline;
        color: #0038ff;
        cursor: pointer;
    }

    .header__bold-text {
        font-weight: 600;
    }

    .header__logo-with-time {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

export default StyledHeader;
