import styled from 'styled-components/macro';

const StyledDocContainer = styled.div`
    font-weight: 300;
    font-size: 14px;

    .bank-doc-item__header {
        line-height: 20px;
        padding: 5px 6px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fdd55b;
        margin-bottom: 2px;
    }

    .bank-doc-item__button {
        text-decoration: underline;
        color: #0038ff;
        background: transparent;
        border: none;
    }

    .bank-doc-item__doc-name-with-btn {
        display: grid;
        grid-template-columns: 1fr 20px;
        grid-column-gap: 10px;
        align-items: center;
        line-height: 17px;
        margin: 5px 5px 5px 0;

        img {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
    }

    .bank-doc-item__doc-name {
        display: grid;
        grid-template-columns: 50px 1fr;

        span {
            margin-left: 15px;
        }
    }

    .bank-doc-item__download-btn {
        word-break: break-word;
        line-height: 17px;
        height: 17px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-decoration: underline;
        color: #0038ff;
        padding: 0;
    }

    .bank-doc-item__non-downloadable-doc {
        cursor: auto;
    }
`;

export default StyledDocContainer;
