import styled from 'styled-components/macro';

const StyledActiveRequestsWrapper = styled.div`
    font-size: 12px;

    .active-requests__heading {
        padding: 5px 7px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        color: #fff;
        background: #959595;
    }

    .active-requests__count {
        padding: 8px 7px;
        background-color: #fdd55b;
    }
`;

export default StyledActiveRequestsWrapper;
