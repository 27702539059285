import React from 'react';
import { useSelector } from 'react-redux';

import { selectClientDatabase } from '../../../store/slices/clientDatabaseSlice';
import StyledContainer from './ClientIsAdded.style';
import { clientURL } from '../../../utils/consts';

const ClientIsAdded = () => {
    const { addedClient } = useSelector(selectClientDatabase);

    const handleBtnClick = () => {
        const clientUrl = `${clientURL}/clients/card/${addedClient.companyId}?tab=clientCard`;
        window.open(clientUrl, '_blank');
    };

    return (
        <StyledContainer>
            <h3>Добавлен новый клиент</h3>
            <p className="client-is-added__inn-number">
                ИНН {'inn' in addedClient ? addedClient.inn : ''}
            </p>
            <p className="client-is-added__client-name">
                {'name' in addedClient ? addedClient.name : ''}
            </p>
            <a onClick={handleBtnClick}>перейти в карточку клиента</a>
        </StyledContainer>
    );
};

export default ClientIsAdded;
