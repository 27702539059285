import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import queryString from 'query-string';

import { selectClientCard } from '../../../store/slices/clientCardSlice';
import { useAppDispatch } from '../../../store';
import { CompanyCashbackSortingFields } from '../../../utils/data';
import {
    getAllCompanyCashback,
    selectCompanyCashback,
} from '../../../store/slices/companyCashbackSlice';
import { CashbackStatusType } from '../../../utils/types';
import { TableOrderType } from '../../Clients/Filter/Filter.types';
import CashbackTable from './CashbackTable/CashbackTable';
import RadionButtonFilter from '../../../components/Common/RadionButtonFilter/RadionButtonFilter';
import StyledClientCashbackWrapper from './ClientCashback.style';

export type SearchParamsFieldNames = 'order' | 'sortBy' | 'status';

export type FilterParams = {
    order: TableOrderType;
    sortBy: CompanyCashbackSortingFields;
    status: string;
    tab: string;
};

export const defaultFilterParams: FilterParams = {
    tab: 'cashback',
    order: 'DESC',
    sortBy: 'offer',
    status: '',
};

const ClientCashback = () => {
    const firstPageUpdate = useRef(true);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();

    const firstUpdate = useRef(true);
    const { isRequestFulfilled } = useSelector(selectCompanyCashback);
    const { company } = useSelector(selectClientCard);
    const [filter, setFilter] = useState<FilterParams>(defaultFilterParams);

    const [isFilterRestoredAfterReload, setFilterRestoredAfterReload] = useState(false);

    const handleFilter = () => {
        if (!company?.companyId) return;

        const order = filter.order as TableOrderType;
        const sortBy = filter.sortBy as CompanyCashbackSortingFields;
        const status = filter.status ? (filter.status as CashbackStatusType) : undefined;
        const searchParams = {
            order,
            sortBy,
            status,
        };

        dispatch(
            getAllCompanyCashback({
                ...searchParams,
                companyId: company.companyId,
            }),
        );
    };

    const filterChangeHandler = (value: string) => {
        setFilter({
            ...filter,
            status: value,
        });
    };

    const serializeSearchParams = (obj: FilterParams) => {
        const str: string[] = [];
        Object.getOwnPropertyNames(obj).forEach((key) => {
            if (key in obj && obj[key as SearchParamsFieldNames]) {
                const encodedKey = encodeURIComponent(key);
                const encodedValue = encodeURIComponent(obj[key as SearchParamsFieldNames]);
                str.push(`${encodedKey}=${encodedValue}`);
            }
        });

        return str.join('&');
    };

    const saveFilter = () => {
        const query = serializeSearchParams({
            ...filter,
        });

        navigate({
            ...location,
            search: query,
        });
    };

    const setSortingField = (value: CompanyCashbackSortingFields) => {
        if (value === filter.sortBy) {
            const newOrder = filter.order === 'ASC' ? 'DESC' : 'ASC';
            setFilter({
                ...filter,
                order: newOrder,
            });
            return;
        }

        setFilter({ ...filter, sortBy: value, order: 'ASC' });
    };

    useEffect(() => {
        if (firstPageUpdate.current) {
            firstPageUpdate.current = false;
            return;
        }

        saveFilter();
        handleFilter();

        if (!isFilterRestoredAfterReload && queryString.parse(location.search)) {
            setFilterRestoredAfterReload(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, company]);

    useEffect(() => {
        const queryStringParams = queryString.parse(location.search);

        if (!queryStringParams) return;

        const order = queryStringParams?.order || defaultFilterParams.order;
        const sortBy = queryStringParams?.sortBy || defaultFilterParams.sortBy;
        const status = String(queryStringParams?.status || defaultFilterParams.status);
        const tab = defaultFilterParams.tab;
        const newFilter = {
            tab,
            order: order as TableOrderType,
            sortBy: sortBy as CompanyCashbackSortingFields,
            status,
        };
        setFilter(newFilter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if (isRequestFulfilled.updateCashback) {
            handleFilter();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRequestFulfilled.updateCashback]);

    return (
        <StyledClientCashbackWrapper>
            <RadionButtonFilter
                filterChangeHandler={filterChangeHandler}
                description="Отображать:"
                filterParams={filter}
                filterType="cashback"
            />
            <CashbackTable sortingField={filter.sortBy} setSortingField={setSortingField} />
        </StyledClientCashbackWrapper>
    );
};

export default ClientCashback;
