import styled from 'styled-components/macro';

const StyledContainer = styled.div`
    .calls-player {
        width: 100%;
        position: fixed;
        bottom: 0;
        background-color: white;
    }
    .calls-player__panel {
        width: calc(100% - 260px);
        margin: 0 10px 20px;
        border-radius: 5px;
        background-color: #f1f3f4;
        height: 70px;
        audio {
            margin-top: 10px;
            width: 100%;
            height: 30px;
        }
    }
    .calls-player__timeline {
        margin: 8px 10px;
        padding-top: 14px;
        display: flex;
        align-items: center;
        flex-direction: row;

        input[type='range'] {
            -webkit-appearance: none;
            /* -moz-appearance: none; */
            appearance: none;
            background: white;
            cursor: pointer;
            width: 100%;
            height: 5px;
        }
        input[type='range']::-webkit-slider-runnable-track {
            -webkit-appearance: none;
            background-color: white;
            border-radius: 4px;
            height: 5px;
        }
        input[type='range']::-webkit-slider-thumb {
            -webkit-appearance: none;
            height: 0;
            width: 4px;
        }
        input[type='range']::-moz-range-track {
            -moz-appearance: none;
            background-color: white;
            border-radius: 4px;
            height: 2px;
        }
        input[type='range']::-moz-range-thumb {
            -moz-appearance: none;
            height: 0;
            width: 0;
        }
    }
    .calls-player__slider {
        position: absolute;
        left: 1px;
        top: 6px;
        background: black;
        height: 5px;
        pointer-events: none;
        border-radius: 4px;
    }
    .calls-player__button {
        background: transparent;
        border: none;
        margin: 0 10px;
        padding: 0px;
        height: 20px;
        width: 20px;
        align-content: center;
    }
    .calls-player__duration {
        /* margin-right: 10px; */
        width: 100px;
    }
    .calls-player__info {
        margin: 0 auto;
        width: max-content;
        display: flex;
        align-items: center;
        flex-direction: row;
        p {
            margin-left: 10px;
            color: #696969;
        }
    }
`;

export default StyledContainer;
