import styled from 'styled-components/macro';

const StyledCompanyInfo = styled.div`
    font-weight: 500;
    font-size: 12px;
    .company-info__header {
        padding: 5px 7px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #fdd55b;
        height: 30px;
        img {
            width: 20px;
            height: 20px;
            margin-left: 10px;
            transform: rotate(180deg);
        }
    }

    .company-info__show-section-btn {
        display: flex;
        align-items: center;
        color: #0038ff;
        text-decoration: underline;
        cursor: pointer;
    }

    .company-info__opened-section {
        img {
            transform: rotate(0);
        }
    }

    .company-info__body {
        background: #e5e5e5;
        display: grid;
        grid-template-columns: 2fr 420px;
        max-height: 320px;
        overflow: hidden;
    }

    .company-info__general-information {
        max-height: inherit;
        overflow-y: auto;
        background: #efefef;
        padding: 9px 0 46px 6px;
        display: grid;
        grid-template-columns: 1fr 40px;
        img {
            margin: 0 5px;
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
    }

    .company-info__general-information-row {
        font-weight: 600;
        line-height: 17px;
        margin-bottom: 5px;
        span {
            font-weight: 500;
        }
    }

    .company-info__contacts-information {
        padding: 7px;
        max-height: inherit;
        overflow: hidden;
    }

    .company-info__contacts-section-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;
        button {
            border: none;
            color: #0038ff;
            text-decoration: underline;
            background: transparent;
        }
    }

    .company-info__contact-list {
        height: calc(100% - 17px);
        padding-right: 5px;
        overflow-y: auto;
    }
`;

export default StyledCompanyInfo;
