import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../store';
import { getAllUsers } from '../../store/slices/staffSlice';
import { userSelector } from '../../store/slices/userSlice';
import BagAddIcon from '../../assets/images/clients/bag-add-icon.png';
import AddUser from '../../components/AddUser/AddUser';
import StaffTable from './StaffTable/StaffTable';
import StyledStaffPageWrapper from './Staff.style';

const StaffPage = () => {
    const dispatch = useAppDispatch();
    const { user } = useSelector(userSelector);
    const [isAddUserOpen, setAddUserOpen] = useState(false);

    useEffect(() => {
        dispatch(getAllUsers({ onlyActiveUsers: false }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StyledStaffPageWrapper>
            <div className="staff-page__header">
                <p>Сотрудники</p>
                {user?.isAdmin ? (
                    <div className="filter__add-client-btn" onClick={() => setAddUserOpen(true)}>
                        <img src={BagAddIcon} />
                        <p>Добавить сотрудника</p>
                    </div>
                ) : null}
            </div>
            <StaffTable />
            {isAddUserOpen && <AddUser close={() => setAddUserOpen(false)} width={440} />}
        </StyledStaffPageWrapper>
    );
};

export default StaffPage;
