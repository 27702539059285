import styled from 'styled-components/macro';

const StyledClientCard = styled.div`
    font-weight: 400;
    font-size: 12px;
    .clients-table__header {
        padding: 5px 7px;
        display: grid;
        grid-template-columns: repeat(3, 3fr) repeat(2, 2fr) 1fr repeat(2, 2fr) 3fr 1fr;
        grid-column-gap: 10px;
        background: #e7e7e7;
        height: 30px;
        img {
            width: 10px;
            height: 10px;
            margin-left: 5px;
        }
    }

    .client-card__manager {
        cursor: pointer;
    }

    .client-card__row-of-header {
        display: grid;
        grid-template-columns: auto auto;
        grid-column-gap: 10px;
        align-items: center;
        padding: 5px;
        img {
            margin-right: 10px;
        }
    }

    .client-card__tools {
        display: flex;
        justify-content: space-between;
    }

    .client-card__tools-img-with-description {
        display: flex;
        align-items: center;
        cursor: pointer;
        select {
            margin-left: 12px;
        }
    }

    .client-card__tools-description {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-decoration-line: underline;
        color: #0038ff;
    }

    .client-card__header-info {
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #000;
        display: flex;
        p {
            font-weight: 500;
            margin-left: 4px;
        }
    }

    .client-card__reminder {
        position: relative;
    }

    .client-card__tooltip {
        position: absolute;
        right: 177px;
        top: -10px;
        max-width: 340px;
        max-height: 500px;
        padding: 10px;
        border-radius: 5px;
        background: lightgray;
        overflow-wrap: break-word;
        overflow-y: auto;
    }

    .client-card__tooltip-date {
        font-weight: 700;
        margin-bottom: 5px;
        min-width: 135px;
    }

    .client-card__top-mark {
        width: 140px;
    }

    .client-card__fts-mark {
        width: 70px;
    }

    .client-card__disabled-tool {
        cursor: auto;

        select {
            cursor: auto;
        }
    }

    .client-card__disabled-task {
        display: none;
    }

    .client-card__contact-date {
        margin-left: 4px;
        font-weight: 500;
        font-size: 14px;
        color: #0038ff;

        span {
            font-weight: 600;
        }
    }
`;

export default StyledClientCard;
