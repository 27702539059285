import React, { useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import toast from 'react-hot-toast';

import { useAppDispatch } from '../../store';
import {
    addBankGuaranteeValidationSchema,
    addLoanValidationSchema,
    editBankGuaranteeValidationSchema,
    editLoanValidationSchema,
} from '../../validation/clientTask';
import { createTask, selectClientTask, updateTask } from '../../store/slices/clientTaskSlice';
import {
    fieldsInAddingBankGuarantee,
    fieldsInAddingLoan,
    FieldNamesInAddingTaskType,
    NumericFieldsInAddTaskType,
    TaskInputFieldsType,
} from '../../utils/data';
import { formatNum, getDateAndTimeFromIsoString, strWithDateToIsoStr } from '../../utils/functions';
import { StyledFormWrapper, StyledContainer } from './AddBankTask.style';
import { getAllUsers, selectStaff } from '../../store/slices/staffSlice';
import { ClientTaskInterface, TaskProductENUM, TaskProductTypes } from '../../utils/types';
import CommonModal from '../Common/CommonModal/CommonModal';
import BtnLoader from '../Common/BtnLoader/BtnLoader';
import { StyledScrollbars } from '../AddClient/AddClientsInfo/AddClientsInfo.style';
import AddBankTaskInput from './AddBankTaskInput/AddBankTaskInput';
import { AddBanksWindowTitleENUM, ButtonTitleENUM } from '../../utils/consts';

type AddBankTaskProps = {
    close: () => void;
    width: number;
    companyId: number;
    task: ClientTaskInterface | null;
    clientTaskProductType?: TaskProductTypes | null;
};

export type AddTaskInputs = {
    procurementProcedureNumber: string;
    procurementProcedureLink: string;
    federalLaw: string;
    bankGuaranteeType: string;
    bankGuaranteeAmount: string;
    bankGuaranteeTermFrom?: string;
    bankGuaranteeTermTo?: string;
    bankGuaranteeTermDays: string;
    contractAdvanceAvailability: string;
    additComment?: string;
    executorId: number;
    procurementProcedureName: string;
    initialContractPrice: string;
    contractConclusionDate: string;
    postscript: string;
    loanType: string;
    loanAmount: string;
    loanTermMonths: string;
};

const AddBankTask = ({
    close,
    width,
    companyId,
    task,
    clientTaskProductType = TaskProductENUM.BANK_GUARANTEE,
}: AddBankTaskProps) => {
    const dispatch = useAppDispatch();
    const firstUpdate = useRef(true);
    const firstUpdateDates = useRef(true);
    const firstUpdatetermDays = useRef(true);
    const firstUpdateIsIncluding = useRef(true);
    const { loading, isRequestFulfilled } = useSelector(selectClientTask);
    const dayTo = dayjs(task?.bankGuaranteeTermTo);
    const dayFrom = dayjs(task?.bankGuaranteeTermFrom);
    const diffInDays = dayTo.diff(dayFrom, 'days');
    const termDays = task?.bankGuaranteeTermDays ?? 0;
    const [hasBeenChangedIncludingEndDate, setHasBeenChangedIncludingEndDate] = useState(false);
    const [isIncludingEndDate, setIncludingEndDate] = useState(termDays - diffInDays === 1);
    const { allUsers } = useSelector(selectStaff);

    const isLoading = loading.createTask || loading.updateTask;
    const btnTitle = task ? ButtonTitleENUM.SAVE : ButtonTitleENUM.CREATE_TASK;
    const createWindowHeight = clientTaskProductType === TaskProductENUM.BANK_GUARANTEE ? 680 : 450;
    const editWindowHeight = clientTaskProductType === TaskProductENUM.BANK_GUARANTEE ? 765 : 400;
    const windowHeight = task ? editWindowHeight : createWindowHeight;
    const addSchema =
        clientTaskProductType === TaskProductENUM.BANK_GUARANTEE
            ? addBankGuaranteeValidationSchema
            : addLoanValidationSchema;
    const editSchema =
        clientTaskProductType === TaskProductENUM.BANK_GUARANTEE
            ? editBankGuaranteeValidationSchema
            : editLoanValidationSchema;
    const validationSchema = !task ? addSchema : editSchema;
    const formOptions = {
        defaultValues: {
            procurementProcedureNumber: task?.procurementProcedureNumber || '',
            procurementProcedureLink: task?.procurementProcedureLink || '',
            federalLaw: task?.federalLaw || '',
            bankGuaranteeType: task?.bankGuaranteeType || '',
            procurementProcedureName: task?.procurementProcedureName || '',
            initialContractPrice: formatNum(String(task?.initialContractPrice ?? '')),
            contractConclusionDate: getDateAndTimeFromIsoString(task?.contractConclusionDate ?? '')
                .date,
            bankGuaranteeAmount: formatNum(String(task?.bankGuaranteeAmount ?? '')),
            bankGuaranteeTermDays: formatNum(String(task?.bankGuaranteeTermDays ?? '')),
            bankGuaranteeTermFrom: getDateAndTimeFromIsoString(task?.bankGuaranteeTermFrom ?? '')
                .date,
            bankGuaranteeTermTo: getDateAndTimeFromIsoString(task?.bankGuaranteeTermTo ?? '').date,
            additComment: task?.additComment || '',
            postscript: task?.postscript || '',

            loanType: task?.loanType || '',
            loanAmount: formatNum(String(task?.loanAmount ?? '')),
            loanTermMonths: formatNum(String(task?.loanTermMonths ?? '')),
        },
        resolver: yupResolver(validationSchema),
    };
    const { register, handleSubmit, formState, watch, setValue } =
        useForm<AddTaskInputs>(formOptions);
    const { errors } = formState;

    const header = useMemo(() => {
        if (task && task.type === TaskProductENUM.BANK_GUARANTEE) {
            return AddBanksWindowTitleENUM.GUARANTEE_TASK_INFO;
        }
        if (task && task.type === TaskProductENUM.LOAN) {
            return AddBanksWindowTitleENUM.LOAN_TASK_INFO;
        }
        if (clientTaskProductType === TaskProductENUM.BANK_GUARANTEE) {
            return AddBanksWindowTitleENUM.NEW_BANK_GUARANTEE_TASK;
        }
        if (clientTaskProductType === TaskProductENUM.LOAN) {
            return AddBanksWindowTitleENUM.NEW_LOAN_TASK;
        }
    }, [clientTaskProductType, task]);

    const fields = useMemo((): TaskInputFieldsType[] => {
        const taskAddingFields =
            clientTaskProductType === TaskProductENUM.BANK_GUARANTEE
                ? fieldsInAddingBankGuarantee
                : fieldsInAddingLoan;
        if (task) {
            return taskAddingFields.filter((field) => field.isEditable) as TaskInputFieldsType[];
        }
        return taskAddingFields.filter(
            (field) => field.isAccessableInCreation,
        ) as TaskInputFieldsType[];
    }, [clientTaskProductType, task]);

    const federalLaw = watch('federalLaw');
    const dateFrom = watch('bankGuaranteeTermFrom');
    const dateTo = watch('bankGuaranteeTermTo');
    const numericFields = {
        bankGuaranteeAmount: watch('bankGuaranteeAmount'),
        bankGuaranteeTermDays: watch('bankGuaranteeTermDays'),
        initialContractPrice: watch('initialContractPrice'),
        loanAmount: watch('loanAmount'),
        loanTermMonths: watch('loanTermMonths'),
    };

    const dateChangeHandler = (value: string) => {
        const oldValue = numericFields.bankGuaranteeTermDays || '';
        const formattedNum = formatNum(value, oldValue, true);
        setValue('bankGuaranteeTermDays', formattedNum);
    };

    const onSubmit: SubmitHandler<AddTaskInputs> = (data) => {
        const {
            procurementProcedureNumber,
            procurementProcedureLink,
            federalLaw,
            bankGuaranteeType,
            bankGuaranteeAmount,
            bankGuaranteeTermFrom,
            bankGuaranteeTermTo,
            bankGuaranteeTermDays,
            contractAdvanceAvailability,
            additComment,
            executorId,
            contractConclusionDate,
            procurementProcedureName,
            initialContractPrice,
            postscript,
            loanType,
            loanAmount,
            loanTermMonths,
        } = data;
        const normalizedBankGuaranteeAmount = Number(
            bankGuaranteeAmount.split(',').join('.').split(' ').join(''),
        );
        const normalizedBankGuaranteeTermDays = bankGuaranteeTermDays
            ? Number(bankGuaranteeTermDays.split(' ').join(''))
            : undefined;
        const normalizedInitialPrice = Number(
            initialContractPrice.split(',').join('.').split(' ').join(''),
        );
        const normalizedConclusionDate = strWithDateToIsoStr(contractConclusionDate);
        const normalizedBankGuaranteeTermFrom = strWithDateToIsoStr(bankGuaranteeTermFrom);
        const normalizedBankGuaranteeTermTo = strWithDateToIsoStr(bankGuaranteeTermTo);
        const normalizedLoanAmount = Number(loanAmount.split(',').join('.').split(' ').join(''));
        const normalizedLoanTermMonths = loanTermMonths
            ? Number(loanTermMonths.split(' ').join(''))
            : undefined;

        if (task) {
            const payload = {
                type: task.type,
                companyId,
                taskId: task.taskId,
                procurementProcedureNumber:
                    task.procurementProcedureNumber !== procurementProcedureNumber
                        ? procurementProcedureNumber
                        : undefined,
                procurementProcedureLink:
                    task.procurementProcedureLink !== procurementProcedureLink
                        ? procurementProcedureLink
                        : undefined,
                federalLaw: federalLaw !== task.federalLaw ? federalLaw : undefined,
                bankGuaranteeType:
                    bankGuaranteeType !== task.bankGuaranteeType ? bankGuaranteeType : undefined,
                bankGuaranteeAmount:
                    bankGuaranteeAmount !== task.bankGuaranteeAmount
                        ? normalizedBankGuaranteeAmount
                        : undefined,
                bankGuaranteeTermFrom:
                    normalizedBankGuaranteeTermFrom !== task.bankGuaranteeTermFrom
                        ? normalizedBankGuaranteeTermFrom
                        : undefined,
                bankGuaranteeTermTo:
                    normalizedBankGuaranteeTermTo !== task.bankGuaranteeTermTo
                        ? normalizedBankGuaranteeTermTo
                        : undefined,
                bankGuaranteeTermDays:
                    normalizedBankGuaranteeTermDays !== task.bankGuaranteeTermDays
                        ? normalizedBankGuaranteeTermDays
                        : undefined,
                additComment: additComment !== task.additComment ? additComment : undefined,
                contractConclusionDate:
                    normalizedConclusionDate !== task.contractConclusionDate
                        ? normalizedConclusionDate
                        : undefined,
                procurementProcedureName:
                    procurementProcedureName !== task.procurementProcedureName
                        ? procurementProcedureName
                        : undefined,
                initialContractPrice:
                    initialContractPrice !== task.initialContractPrice
                        ? normalizedInitialPrice
                        : undefined,
                postscript: postscript !== task.postscript ? postscript : undefined,
                loanType: loanType !== task.loanType ? loanType : undefined,
                loanAmount: loanAmount !== task.loanAmount ? normalizedLoanAmount : undefined,
                loanTermMonths:
                    normalizedLoanTermMonths !== task.loanTermMonths
                        ? normalizedLoanTermMonths
                        : undefined,
            };
            return dispatch(updateTask(payload));
        }
        const isGuarantee = clientTaskProductType === TaskProductENUM.BANK_GUARANTEE;
        const isGuaranteeRequiredFieldsPresent =
            procurementProcedureNumber &&
            procurementProcedureLink &&
            bankGuaranteeType &&
            normalizedBankGuaranteeAmount &&
            contractAdvanceAvailability &&
            federalLaw;
        const isNotGuaranteeFullData = !(isGuarantee && isGuaranteeRequiredFieldsPresent);

        const isLoan = clientTaskProductType === TaskProductENUM.LOAN;
        const isLoanRequiredFieldsPresent = loanType && loanAmount && loanTermMonths;
        const isNotLoanFullData = !(isLoan && isLoanRequiredFieldsPresent);

        if (isNotGuaranteeFullData && isNotLoanFullData) {
            return;
        }

        const payload = {
            type: clientTaskProductType,
            procurementProcedureNumber,
            procurementProcedureLink,
            federalLaw,
            bankGuaranteeType,
            bankGuaranteeAmount: normalizedBankGuaranteeAmount,
            bankGuaranteeTermFrom: normalizedBankGuaranteeTermFrom,
            bankGuaranteeTermTo: normalizedBankGuaranteeTermTo,
            bankGuaranteeTermDays: normalizedBankGuaranteeTermDays,
            contractAdvanceAvailability,
            executorId: Number(executorId) || undefined,
            additComment,
            companyId,
            loanType,
            loanAmount: normalizedLoanAmount,
            loanTermMonths: normalizedLoanTermMonths,
        };
        dispatch(createTask(payload));
    };

    const changNumericFieldHandler = (value: string, field: FieldNamesInAddingTaskType) => {
        const oldValue = numericFields[field as NumericFieldsInAddTaskType] || '';
        const isInteger = field === 'bankGuaranteeTermDays' || field === 'loanTermMonths';
        const formattedNum = formatNum(value, oldValue, isInteger);

        setValue(field, formattedNum);
    };

    useEffect(() => {
        dispatch(getAllUsers({ onlyActiveUsers: true, positionName: 'OSKO' }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (federalLaw) {
            setValue('bankGuaranteeType', '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [federalLaw]);

    useEffect(() => {
        let hasEmptyFieldError = false;
        Object.keys(validationSchema.fields).forEach((key) => {
            const errorMessage = errors[key as FieldNamesInAddingTaskType]?.message;
            if (errorMessage?.startsWith('Заполните') || errorMessage?.startsWith('Выберите')) {
                if (!hasEmptyFieldError) {
                    hasEmptyFieldError = true;
                    toast.error('Заполните необходимые поля');
                }
            } else if (errorMessage) {
                toast.error(errorMessage);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if (isRequestFulfilled.createTask || isRequestFulfilled.updateTask) {
            close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRequestFulfilled.createTask, isRequestFulfilled.updateTask]);

    useEffect(() => {
        if (firstUpdateDates.current) {
            firstUpdateDates.current = false;
            return;
        }
        if (!(dateFrom && dateTo)) return;

        if (hasBeenChangedIncludingEndDate && isIncludingEndDate) {
            setHasBeenChangedIncludingEndDate(false);
            return;
        }
        if (dateFrom > dateTo) {
            toast.error('Дата конца периода не может быть меньше даты его начала');
            return;
        }
        const datesRange = new Date(dateTo).getTime() - new Date(dateFrom).getTime();
        let termDays = datesRange / (1000 * 60 * 60 * 24);
        termDays = isIncludingEndDate ? termDays + 1 : termDays;

        setValue('bankGuaranteeTermDays', termDays.toString());
        !hasBeenChangedIncludingEndDate && setHasBeenChangedIncludingEndDate(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateFrom, dateTo]);

    useEffect(() => {
        if (!(dateFrom && dateTo)) return;

        if (firstUpdatetermDays.current) {
            firstUpdatetermDays.current = false;
            return;
        }

        if (hasBeenChangedIncludingEndDate && isIncludingEndDate) {
            setHasBeenChangedIncludingEndDate(false);
            return;
        }

        if (dateFrom > dateTo) {
            toast.error('Дата конца периода не может быть меньше даты его начала');
            return;
        }

        let newDateTo = dayjs(dateFrom)
            .add(Number(numericFields.bankGuaranteeTermDays.split(' ').join('')), 'days')
            .subtract(isIncludingEndDate ? 1 : 0, 'day');

        if (dayjs(dateFrom).isAfter(newDateTo)) {
            newDateTo = dayjs(newDateTo).add(1, 'day');
        }

        setValue('bankGuaranteeTermTo', getDateAndTimeFromIsoString(newDateTo.toString()).date);
        setHasBeenChangedIncludingEndDate(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numericFields.bankGuaranteeTermDays]);

    useEffect(() => {
        if (firstUpdateIsIncluding.current) {
            firstUpdateIsIncluding.current = false;
            return;
        }

        if (!(dateFrom && dateTo)) return;

        if (dateFrom > dateTo) {
            toast.error('Дата конца периода не может быть меньше даты его начала');
            return;
        }
        const newTermDays = isIncludingEndDate
            ? Number(numericFields.bankGuaranteeTermDays.split(' ').join('')) + 1
            : Number(numericFields.bankGuaranteeTermDays.split(' ').join('')) - 1;
        if (newTermDays < 0) {
            return;
        }
        setValue('bankGuaranteeTermDays', newTermDays.toString());
        setHasBeenChangedIncludingEndDate(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isIncludingEndDate]);

    useEffect(() => {
        if (!task?.bankGuaranteeType) return;
        setValue('bankGuaranteeType', task.bankGuaranteeType);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [task]);

    return (
        <CommonModal hasCloseBtn={true} close={close}>
            <StyledFormWrapper width={width}>
                <StyledContainer height={windowHeight}>
                    <h3>{header}</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <StyledScrollbars
                            style={{ height: 'calc(100% - 30px)' }}
                            renderTrackHorizontal={(props) => (
                                <div
                                    {...props}
                                    style={{ display: 'none' }}
                                    className="track-horizontal"
                                />
                            )}
                            thumbSize={170}
                            thumbMinSize={30}
                        >
                            {fields.map((field) => (
                                <div key={field.fieldName} className="add-bank-guarantee__label">
                                    <p
                                        className={clsx({
                                            'add-bank-guarantee__red-label':
                                                field.fieldName === 'executorId',
                                        })}
                                    >
                                        {field.label}
                                        {field.isRequired && (
                                            <span className="add-bank-guarantee__sign-of-obligatory-field">
                                                *
                                            </span>
                                        )}
                                        :
                                    </p>
                                    <AddBankTaskInput
                                        task={task}
                                        field={field}
                                        errors={errors}
                                        isIncludingEndDate={isIncludingEndDate}
                                        federalLaw={federalLaw}
                                        allUsers={allUsers}
                                        register={register}
                                        setIncludingEndDate={setIncludingEndDate}
                                        changNumericFieldHandler={changNumericFieldHandler}
                                        dateChangeHandler={dateChangeHandler}
                                    />
                                </div>
                            ))}
                        </StyledScrollbars>
                        <BtnLoader
                            isLoading={isLoading}
                            btnTitle={btnTitle}
                            btnClass={clsx('add-bank-guarantee__btn', {
                                'add-bank-guarantee__disabled-btn': isLoading,
                            })}
                        />
                    </form>
                </StyledContainer>
            </StyledFormWrapper>
        </CommonModal>
    );
};

export default AddBankTask;
