import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { clientClosedRequestsColumns } from '../../../../utils/data';
import { formatNum, getNormalizedSum } from '../../../../utils/functions';
import { selectClientTask } from '../../../../store/slices/clientTaskSlice';
import StyledTable from './ClientClosedRequestsTable.style';
import ClientClosedRequestsTableRow from '../ClientClosedRequestsTableRow/ClientClosedRequestsTableRow';

const RequestsTable = () => {
    const {
        clientTasks,
        clientTasksCount,
        clientCommissionAmount,
        clientIncomeAmount,
        clientTasksAmount,
    } = useSelector(selectClientTask);
    const totalAmountList = useMemo(() => {
        return [
            formatNum(getNormalizedSum(clientTasksAmount.toFixed(4))),
            formatNum(getNormalizedSum(clientCommissionAmount.toFixed(4))),
            formatNum(getNormalizedSum(clientIncomeAmount.toFixed(4))),
        ];
    }, [clientIncomeAmount, clientCommissionAmount, clientTasksAmount]);

    return (
        <StyledTable>
            <div className="requests-table__title">
                <p className="requests-table__stats">Завершенные сделки</p>
                <p>{clientTasksCount}</p>
            </div>
            <div className={clsx('requests-table__row')}>
                {totalAmountList.map((el, indx) => (
                    <div key={indx} className={clsx('row-cell-prio', `row-cell-prio--${indx}`)}>
                        {el}
                    </div>
                ))}
            </div>
            <div className="requests-table__headings">
                {clientClosedRequestsColumns.map((column, index) => (
                    <div
                        key={column.name}
                        className={clsx({
                            'close-requests-table__right-position': index > 4 && index < 11,
                        })}
                    >
                        <p
                            className={clsx('requests-table__column', {
                                'requests-table__green-field': column.name === 'income',
                                'requests-table__red-field': column.name === 'clientCommission',
                            })}
                        >
                            {column.label}
                        </p>
                    </div>
                ))}
            </div>
            <div>
                {clientTasks.map((task, index) => (
                    <ClientClosedRequestsTableRow key={task.taskId} task={task} index={index} />
                ))}
            </div>
        </StyledTable>
    );
};

export default RequestsTable;
