import * as Yup from 'yup';

import { onlyNonNegativeNumChecking } from '../utils/functions';

export const addActValidationSchema = Yup.object().shape({
    dateFrom: Yup.string().required('Заполните начало периода'),
    dateTo: Yup.string().required('Заполните конец периода'),
    receiverId: Yup.string().nullable().required('Выберите получателя'),
});

export const addReceiverSchema = Yup.object().shape({
    inn: Yup.string()
        .required('Заполните поле ИНН')
        .test('num', 'ИНН должен состоять только из цифр', onlyNonNegativeNumChecking)
        .test(
            'len',
            'ИНН должен состоять из 10 и более цифр',
            (val) => typeof val === 'string' && val.length >= 10,
        ),
    name: Yup.string().required('Заполните поле наименование компании'),
    headName: Yup.string().required('Заполните поле ФИО руководителя'),
    headPost: Yup.string().required('Заполните поле должность руководителя'),
});
