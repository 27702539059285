import styled from 'styled-components/macro';

const StyledContainer = styled.div`
    .credentials-item__table-row {
        display: grid;
        padding: 5px 10px;
        grid-template-columns: 65px 110px 3fr 1fr 2fr 8fr 30px;

        img {
            margin-left: 10px;
        }

        p,
        a {
            word-break: break-word;
            padding-right: 5px;
        }

        a {
            text-decoration: underline;
            color: #0038ff;
            width: fit-content;
            height: fit-content;
        }
    }

    .credentials-item__table-contact {
        display: grid;
        grid-template-columns: 3fr 3fr 2fr;
    }
`;

export default StyledContainer;
