import styled from 'styled-components/macro';

const StyledDiv = styled.div`
    .cashback-list_headings,
    .cashback-list__row {
        line-height: 20px;
        display: grid;
        grid-template-columns:
            minmax(120px, 180px) minmax(60px, 90px) 200px 1fr minmax(100px, 150px)
            minmax(120px, 180px) 2fr 80px;
        grid-column-gap: 10px;
        padding: 5px 7px;
    }

    .cashback-list__number {
        color: #0038ff;
        text-decoration: underline;
        width: fit-content;
        min-width: 20px;
    }

    .cashback-list__icons {
        display: flex;
        align-items: start;
        justify-content: end;
        height: 20px;

        img {
            margin-left: 20px;
        }
    }

    .cashback-list__manger-view-icon {
        justify-content: start;
    }

    .cashback-list__edit-icon {
        cursor: pointer;
    }

    .cashback-list__statistic-row {
        background: #e7e7e7;
        grid-template-columns:
            minmax(120px, 180px) minmax(60px, 90px) minmax(250px, 2fr) 90px minmax(150px, 1fr)
            minmax(70px, 130px) minmax(120px, 180px) minmax(250px, 2fr) 80px;

        .cashback-list__column {
            cursor: auto;
            font-weight: 500;

            img {
                display: none;
            }
        }
    }

    .cashback-list__row {
        background: #fff;
    }

    .cashback-list__disabled-link {
        cursor: auto;
    }

    .cashback-list__even-row {
        background: #f5f5f5;
    }

    .cashback-list__right-position {
        display: flex;
        justify-content: flex-end;
        margin-right: 10px;
    }
`;

export default StyledDiv;
