import styled from 'styled-components/macro';

const StyledCommentsTable = styled.div`
    font-weight: 400;
    font-size: 12px;

    .comments-table__header {
        padding: 5px 7px;
        font-weight: 500;
        display: grid;
        align-items: center;
        grid-template-columns: 90px 1fr 3fr;
        grid-column-gap: 10px;
        background: #e7e7e7;
        height: 30px;

        img {
            width: 10px;
            height: 10px;
            margin-left: 5px;
        }
    }

    .comments-table__comment {
        padding: 13px 15px 13px 7px;
        display: grid;
        grid-template-columns: 90px 1fr 3fr;
        grid-column-gap: 10px;
        border-bottom: 1px solid lightgray;

        p {
            overflow-wrap: break-word;
            word-break: break-word;
            text-overflow: ellipsis;
            overflow-x: auto;
        }

        img {
            width: 20px;
            height: 20px;
            margin-top: -6px;
        }
    }

    .comments-table__time {
        padding: 13px 15px 13px 7px;
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 10px;

        p {
            overflow-wrap: break-word;
            word-break: break-word;
            text-overflow: ellipsis;
            overflow-x: auto;
        }

        img {
            width: 20px;
            height: 20px;
            margin-top: -6px;
        }
    }

    .comments-table__company-name {
        color: #0038ff;
        text-decoration: underline;
        cursor: pointer;
    }

    .comments-table__comment-item {
        word-break: break-word;
    }

    .comment-table__text {
        ol {
            padding-left: 22px;
        }

        ul {
            padding: 0 0 0 20px !important;
            list-style-type: disc;
        }
    }

    .comments-table__time-divider,
    .comments-table__date-divider {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        padding: 7px;
        width: 100%;
        text-align: center;
        background: #f9f9f9;
        margin-top: 5px;
    }

    .comments-table__date-divider {
        background: #fff;
        font-weight: 600;
    }

    .comments-table__comment-author {
        color: #0038ff;
    }

    .comments-table__task-table-row {
        grid-template-columns: 90px 1fr 140px 3fr;

        a {
            width: fit-content;
            min-width: 20px;
        }
    }

    .comments-table__disabled-company {
        cursor: auto;
    }

    .comments-table__header {
        border-bottom: none;
    }
    .comments-table__loader {
        text-align: center;
        margin: 50px;
    }
`;

export default StyledCommentsTable;
