import styled from 'styled-components/macro';

const StyledFilterWrapper = styled.div`
    display: flex;
    padding: 5px 7px;

    .filter__product-container {
        display: flex;
        align-items: center;
        font-weight: 500;

        p {
            margin-right: 10px;
        }
    }
`;

export default StyledFilterWrapper;
