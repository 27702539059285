import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { selectClientDatabase, setCurrentPage } from '../../../../store/slices/clientDatabaseSlice';
import { useAppDispatch } from '../../../../store';
import StyledPagination from './Pagination.style';

const Pagination = () => {
    const dispatch = useAppDispatch();
    const { currentPage, pagesCount } = useSelector(selectClientDatabase);
    const handlePageSelecting = (page: number) => {
        if (page === currentPage) return;
        dispatch(setCurrentPage(page));
    };

    return (
        <StyledPagination>
            <div className="pagination__header">
                Стр.
                {Array.from(Array(pagesCount).keys()).map((pageNumber) => (
                    <span
                        key={pageNumber}
                        onClick={() => handlePageSelecting(pageNumber + 1)}
                        className={clsx({
                            'pagination__selected-page': pageNumber + 1 === currentPage,
                        })}
                    >{`${pageNumber + 1}${pageNumber < pagesCount - 1 ? '.' : ''}`}</span>
                ))}
            </div>
        </StyledPagination>
    );
};

export default Pagination;
