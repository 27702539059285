import styled from 'styled-components/macro';

const StyledDocumentCategoryList = styled.div`
    font-weight: 500;
    font-size: 12px;
    margin: 2px 0;

    .base-documents__actions {
        display: flex;
        justify-content: space-between;
        align-items: end;
        cursor: pointer;
        img {
            width: 20px;
            height: 20px;
            margin-left: 11px;
        }
    }

    .base-documents__header {
        height: 30px;
        padding: 5px 6px;
        background: #fdd55b;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`;

export default StyledDocumentCategoryList;
