import React, { Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';

type MyProps = {
    // eslint-disable-next-line no-unused-vars
    setEditorContent: (value: string) => void;
    editingComment?: string;
};

export default class TextEditor extends Component<MyProps> {
    state = {
        editorState: EditorState.createEmpty(),
    };

    onEditorStateChange = (editorState: EditorState) => {
        this.setState({
            editorState,
        });
        this.props.setEditorContent(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    componentDidMount() {
        const { editingComment } = this.props;
        if (!editingComment) return;
        const blocksFromHtml = htmlToDraft(editingComment);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        this.setState({
            editorState: EditorState.createWithContent(contentState),
        });
    }

    render() {
        const { editorState } = this.state;
        return (
            <Editor
                editorState={editorState}
                toolbarClassName="editor-toolbar"
                wrapperClassName="wrapperClassName"
                editorClassName="editor-content"
                onEditorStateChange={this.onEditorStateChange}
                toolbar={{
                    options: ['inline', 'list', 'emoji'],
                    inline: {
                        options: ['bold', 'italic'],
                    },
                    list: {
                        options: ['unordered', 'ordered'],
                    },
                }}
            />
        );
    }
}
