import styled from 'styled-components/macro';

const StyledCallsWrapper = styled.div`
    font-size: 12px;
    .calls__heading {
        padding: 8px 7px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        background: #959595;

        h5 {
            font-weight: 600;
            font-size: 14px;
        }
    }

    .calls__count {
        padding: 8px 7px;
        background-color: #fdd55b;
    }

    .calls__filter-row {
        display: flex;
        align-items: center;
        padding: 5px 7px;
        label {
            font-style: normal;
        }
    }
`;

export default StyledCallsWrapper;
