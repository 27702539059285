import styled from 'styled-components/macro';

const StyledComponent = styled.div`
    margin: 0 auto;
    height: 20px;
    width: 20px;
    border-radius: 5px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    background-color: rgba(253, 213, 91, 1);
    text-align: center;
    vertical-align: baseline;
`;

export default StyledComponent;
