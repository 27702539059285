import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import CloseIcon from '../../../assets/images/close-icon.png';
import StyledDialog from './CommonModal.style';

type CommonModalProps = {
    hasCloseBtn: boolean;
    header?: string;
    close: () => void;
    children: React.ReactNode;
};

const CommonModal = ({ hasCloseBtn, close, children, header }: CommonModalProps) => {
    const closeModal = (e: KeyboardEvent) => {
        if (e.code === 'Escape') {
            close();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', closeModal);
        return () => window.removeEventListener('keydown', closeModal);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return ReactDOM.createPortal(
        <StyledDialog>
            <div className="common-modal__window">
                {header ? (
                    <div className="common-modal__header">
                        <h3>{header}</h3>
                        {hasCloseBtn && <img src={CloseIcon} onClick={close} />}
                    </div>
                ) : (
                    hasCloseBtn && (
                        <img className="common-modal__close-btn" src={CloseIcon} onClick={close} />
                    )
                )}
                {children}
            </div>
        </StyledDialog>,
        document.body,
    );
};

export default CommonModal;
