import styled from 'styled-components/macro';

const StyledStaffPageWrapper = styled.div`
    .staff-page__header {
        padding: 5px 7px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        color: #fff;
        background: #959595;
        img {
            width: 20px;
            height: 20px;
            margin: 0 10px;
        }
    }

    .filter__add-client-btn {
        display: flex;
        align-items: center;
        text-decoration: underline;
        margin-right: 8px;
        cursor: pointer;
        p {
            width: fit-content;
        }
    }
`;

export default StyledStaffPageWrapper;
