import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import { useAppDispatch } from '../../../../store';
import { userSelector } from '../../../../store/slices/userSlice';
import { DocumentCategoryInterface } from '../../../../utils/types';
import {
    DeleteCompanyDocumentCategory,
    selectClientDocument,
} from '../../../../store/slices/clientDocumentSlice';
import EditCategory from '../../EditCategory/EditCategory';
import ConfirmModal from '../../../Common/ConfirmModal/ConfirmModal';
import FontEditIcon from '../../../../assets/images/clients/font-edit-icon.png';
import FontTrashIcon from '../../../../assets/images/clients/font-trash-icon.png';
import StyledCategoryItem from './CategoryItem.style';

type CategoryItemProps = {
    companyId: number;
    category: DocumentCategoryInterface;
};

const CategoryItem = ({ companyId, category }: CategoryItemProps) => {
    const firstUpdate = useRef(true);
    const dispatch = useAppDispatch();
    const { user } = useSelector(userSelector);
    const { loading, isRequestFulfilled } = useSelector(selectClientDocument);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

    const handleCategoryDeleting = () => {
        if (!user?.isAdmin) return toast.error('Недостаточно прав для удаления');
        dispatch(
            DeleteCompanyDocumentCategory({
                companyId,
                categoryId: category.companyDocumentCategoryId,
                hardDelete: true,
            }),
        );
    };

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if (isRequestFulfilled.deleteDocumentCategory) {
            setDeleteModalOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRequestFulfilled.deleteDocumentCategory]);

    return (
        <StyledCategoryItem>
            <div className="category-item__category-item" key={category.companyDocumentCategoryId}>
                {category.name}
                <img
                    src={FontEditIcon}
                    className="category-item__edit-icon"
                    onClick={() => setEditModalOpen(true)}
                />
                <img src={FontTrashIcon} onClick={() => setDeleteModalOpen(true)} />
            </div>
            {isEditModalOpen ? (
                <EditCategory
                    close={() => setEditModalOpen(false)}
                    width={400}
                    companyId={companyId}
                    categoryId={category.companyDocumentCategoryId}
                    previousCategoryName={category.name}
                    previousBasicName={category.basicDocumentName}
                />
            ) : null}
            {isDeleteModalOpen ? (
                <ConfirmModal
                    close={() => setDeleteModalOpen(false)}
                    confirmAction={handleCategoryDeleting}
                    width={280}
                    height={110}
                    headText="Вы уверены, что хотите удалить категорию?"
                    loading={loading.deleteDocumentCategory}
                />
            ) : null}
        </StyledCategoryItem>
    );
};

export default CategoryItem;
