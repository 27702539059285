import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import queryString from 'query-string';

import { selectClientCard } from '../../store/slices/clientCardSlice';
import { useAppDispatch } from '../../store';
import { selectCompanyCashback } from '../../store/slices/companyCashbackSlice';
import { CashbackStatusType } from '../../utils/types';
import { getCashbackStat, selectStatistics } from '../../store/slices/statisticsSlice';
import {
    formatDateString,
    formatNum,
    getFirstDayOfMonth,
    strWithDateToIsoStr,
} from '../../utils/functions';
import CashbackStatFilter from './CashbackStatFilter/CashbackStatFilter';
import CashbackTable from '../ClientInteraction/ClientCashback/CashbackTable/CashbackTable';
import StyledCashbackWrapper from './Cashback.style';

export type CashbackStatSearchParamsType = 'dateTo' | 'dateFrom' | 'status';

export type CashbackStatFilterParams = {
    dateFrom: string;
    dateTo: string;
    status: string;
};

export const defaultFilterParams: CashbackStatFilterParams = {
    dateFrom: formatDateString(getFirstDayOfMonth()),
    dateTo: formatDateString(new Date()),
    status: '',
};

const Cashback = () => {
    const firstPageUpdate = useRef(true);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();

    const firstUpdate = useRef(true);
    const { isRequestFulfilled } = useSelector(selectCompanyCashback);
    const { company } = useSelector(selectClientCard);
    const { cashbackRecords, cashbackAmount } = useSelector(selectStatistics);
    const [filter, setFilter] = useState<CashbackStatFilterParams>(defaultFilterParams);

    const [isFilterRestoredAfterReload, setFilterRestoredAfterReload] = useState(false);

    const handleFilter = () => {
        const dateTo = strWithDateToIsoStr(filter.dateTo);
        const dateFrom = strWithDateToIsoStr(filter.dateFrom);
        const status = filter.status ? (filter.status as CashbackStatusType) : undefined;
        const searchParams = {
            dateTo,
            dateFrom,
            status,
        };

        dispatch(
            getCashbackStat({
                ...searchParams,
            }),
        );
    };

    const filterChangeHandler = (value: string, field: CashbackStatSearchParamsType) => {
        setFilter({
            ...filter,
            [field]: value,
        });
    };

    const serializeSearchParams = (obj: CashbackStatFilterParams) => {
        const str: string[] = [];
        Object.getOwnPropertyNames(obj).forEach((key) => {
            if (key in obj && obj[key as CashbackStatSearchParamsType]) {
                const encodedKey = encodeURIComponent(key);
                const encodedValue = encodeURIComponent(obj[key as CashbackStatSearchParamsType]);
                str.push(`${encodedKey}=${encodedValue}`);
            }
        });

        return str.join('&');
    };

    const saveFilter = () => {
        const query = serializeSearchParams({
            ...filter,
        });

        navigate({
            ...location,
            search: query,
        });
    };

    useEffect(() => {
        if (firstPageUpdate.current) {
            firstPageUpdate.current = false;
            return;
        }

        saveFilter();
        handleFilter();

        if (!isFilterRestoredAfterReload && queryString.parse(location.search)) {
            setFilterRestoredAfterReload(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, company]);

    useEffect(() => {
        const queryStringParams = queryString.parse(location.search);

        if (!queryStringParams) return;

        const dateTo = (queryStringParams?.dateTo as string) || defaultFilterParams.dateTo;
        const dateFrom = (queryStringParams?.dateFrom as string) || defaultFilterParams.dateFrom;
        const status = String(queryStringParams?.status || defaultFilterParams.status);
        const newFilter = {
            dateTo,
            dateFrom,
            status,
        };
        setFilter(newFilter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if (isRequestFulfilled.updateCashback) {
            handleFilter();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRequestFulfilled.updateCashback]);

    return (
        <StyledCashbackWrapper>
            <div className="cashback-stat__header">
                <p>Статистика - Кэшбэк</p>
                <div className="cashback-stat__count-and-amount">
                    <p>Кол-во: {cashbackRecords}</p>
                    <p>Сумма: {formatNum(cashbackAmount.toFixed(2))}</p>
                </div>
            </div>
            <CashbackStatFilter filterChangeHandler={filterChangeHandler} filterParams={filter} />
            <CashbackTable isStatisticPage={true} />
        </StyledCashbackWrapper>
    );
};

export default Cashback;
