import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { useAppDispatch } from '../../../store';
import {
    createPartnerDoc,
    deletePartnerDoc,
    getOnePartner,
    selectPartner,
} from '../../../store/slices/partnerSlice';
import { downloadAsFile } from '../../../utils/functions';
import { PartnerFileWithName } from '../AddPartner.types';
import { PartnerDocArrayTypes } from '../../../utils/types';
import DeleteDocIcon from '../../../assets/images/staff/delete-doc-icon.png';
import AddFile from '../../AddFile/AddFile';
import StyledDocContainer from './BankDocItem.style';

type BankDocItemProps = {
    docs: PartnerFileWithName[];
    section: PartnerDocArrayTypes;
    title: string;
    // eslint-disable-next-line no-unused-vars
    setDoc: (value: PartnerFileWithName[]) => void;
    bankId: number | null;
    disabled: boolean;
};

const BankDocItem = ({ docs, title, setDoc, section, bankId, disabled }: BankDocItemProps) => {
    const dispatch = useAppDispatch();
    const [isAddFileModalOpen, setAddFileModalOpen] = useState(false);
    const [unloadedFile, setUnloadedFile] = useState<null | PartnerFileWithName>(null);
    const { currentPartner, loading, isRequestFulfilled } = useSelector(selectPartner);

    const successHandler = () => {
        setUnloadedFile(null);
    };

    const errorHandler = (doc: PartnerFileWithName) => {
        if (!bankId || unloadedFile) return;
        setUnloadedFile(doc);
        dispatch(getOnePartner({ bankId }));
    };

    const downloadDocument = (doc: PartnerFileWithName) => {
        downloadAsFile(doc.file, doc.name, () => errorHandler(doc), successHandler);
    };

    const deleteDoc = (index: number) => {
        const documentId = docs?.[index]?.bankDocumentId;
        if (currentPartner && documentId) {
            dispatch(
                deletePartnerDoc({
                    bankId: currentPartner.bankId,
                    documentId,
                }),
            );
            return;
        }
        const newDocs = [...docs];
        newDocs.splice(index, 1);
        setDoc(newDocs);
    };

    const addDoc = (name: string, file: string) => {
        if (currentPartner) {
            dispatch(
                createPartnerDoc({
                    bankId: currentPartner.bankId,
                    file,
                    name,
                    section,
                }),
            );
            return;
        }
        setDoc([...docs, { name, file }]);
    };

    useEffect(() => {
        if (!unloadedFile?.bankDocumentId) return;
        const doc = docs.find((doc) => doc?.bankDocumentId === unloadedFile.bankDocumentId);
        if (!doc) return;
        downloadDocument(doc);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docs]);

    return (
        <StyledDocContainer>
            <div className="bank-doc-item__header">
                <h4>{title}</h4>
                {!disabled && (
                    <button
                        className="bank-doc-item__button"
                        type="button"
                        onClick={() => setAddFileModalOpen(true)}
                    >
                        Добавить
                    </button>
                )}
            </div>
            {docs?.map((doc, index) => (
                <div key={doc.name + index} className="bank-doc-item__doc-name-with-btn">
                    <div className="bank-doc-item__doc-name">
                        <span>{index + 1}</span>
                        <button
                            type="button"
                            disabled={!doc?.bankDocumentId}
                            className={clsx('bank-doc-item__download-btn', {
                                'bank-doc-item__non-downloadable-doc': !doc?.bankDocumentId,
                            })}
                            onClick={() => downloadDocument(doc)}
                        >
                            {doc.name}
                        </button>
                    </div>
                    {!disabled && <img src={DeleteDocIcon} onClick={() => deleteDoc(index)} />}
                </div>
            ))}
            {isAddFileModalOpen && (
                <AddFile
                    close={() => setAddFileModalOpen(false)}
                    width={400}
                    setFile={addDoc}
                    title="Добавить документ"
                    loading={loading.uploadPartnerDocs}
                    isFulfilled={isRequestFulfilled.uploadFile}
                />
            )}
        </StyledDocContainer>
    );
};

export default BankDocItem;
