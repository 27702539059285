import styled from 'styled-components/macro';

const StyledTable = styled.div`
    font-weight: 300;
    font-size: 12px;
    margin-bottom: 10px;

    .acts-table__title {
        padding: 5px 7px;
        height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #e5e5e5;

        span {
            font-weight: 600;
        }
    }

    .acts-table__stats {
        display: flex;
        align-items: center;

        p {
            margin-left: 15px;
        }
    }

    .acts-table__column {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-weight: 600;

        img {
            width: 12px;
            height: 12px;
            margin-left: 5px;
        }
    }

    .acts-table__headings,
    .acts-table__row {
        line-height: 20px;
        display: grid;
        grid-template-columns:
            minmax(120px, 180px) minmax(60px, 90px) 140px minmax(160px, 1fr)
            repeat(2, 90px) minmax(140px, 250px) minmax(160px, 2fr) 60px;
        grid-column-gap: 20px;
        padding: 5px 7px;
    }

    .acts-table__icons {
        display: flex;
        align-items: start;
        justify-content: end;
    }

    .acts-table__sign {
        display: grid;
        grid-template-columns: 1fr 54px;
        grid-column-gap: 15px;
        justify-content: end;
        color: #0038ff;
        text-decoration: underline;
        margin: 0 5px;

        p {
            cursor: pointer;
        }
    }

    .acts-table__edit-icon {
        cursor: pointer;
    }

    .acts-table__receiver {
        width: 100%;
        text-align: end;
    }

    img {
        margin-left: 15px;
        cursor: pointer;
    }

    .acts-table__close-icon img {
        margin-left: 20px;
    }

    .acts-table__odd-row {
        background: #f5f5f5;
    }

    .acts-table__signing-doc {
        display: flex;
        justify-content: end;

        p {
            width: fit-content;
            min-width: 15px;
        }
    }
`;

export default StyledTable;
