import styled from 'styled-components/macro';

const StyledForm = styled.form<{ width: number }>`
    width: ${({ width }) => `${width}px`};
    max-width: 86vw;
    position: relative;
    padding-bottom: 10px;

    .add-comment__header {
        text-align: center;
        margin-bottom: 20px;
    }

    .add-comment__create-btn,
    .add-comment__close-btn {
        border: none;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
    }

    .add-comment__create-btn {
        color: #0038ff;
    }

    .add-comment__close-btn {
        color: #00892f;
        display: flex;
        align-items: center;

        img {
            margin-right: 5px;
        }
    }

    .add-comment__create-btn[disabled],
    .add-comment__close-btn[disabled] {
        opacity: 0.6;
        cursor: auto;
    }

    .editor-content {
        max-height: 70vh;
        background: #fff;
        overflow-y: auto;
        padding: 15px;

        ul,
        ol {
            padding-left: 10px;
            margin: 0;
        }
    }

    .rdw-emoji-modal {
        width: 800px;
        max-width: calc(86vw - 100px);
        left: -46px;
    }

    .public-DraftStyleDefault-ltr {
        margin: 0;
    }

    .editor-toolbar {
        background: #000;
    }

    .add-comment__actions {
        height: 30px;
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
    }
`;

export default StyledForm;
