import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import CustomSelect from '../CustomSelect/CustomSelect';
import { getAllUsers, selectStaff } from '../../../store/slices/staffSlice';
import { getManagersList } from '../../../utils/functions';
import { useAppDispatch } from '../../../store';
import { userSelector } from '../../../store/slices/userSlice';
import BulletListIcon from '../../../assets/images/bullet-list-icon.png';
import StyledFilter from './Filter.style';

export type ManagerAndDateFilterType = {
    manager: string;
    date: string;
};

export type ManagerAndDateSearchParamsNames = 'manager' | 'date';

type FilterProps = {
    // eslint-disable-next-line no-unused-vars
    filterChangeHandler: (value: string, field: ManagerAndDateSearchParamsNames) => void;
    filterParams: ManagerAndDateFilterType;
};

// eslint-disable-next-line no-unused-vars
const Filter = ({ filterChangeHandler, filterParams }: FilterProps) => {
    const dispatch = useAppDispatch();
    const { allUsers } = useSelector(selectStaff);
    const { user } = useSelector(userSelector);

    const managers = [{ fullName: 'нет', userId: '' }, ...getManagersList(allUsers)];

    useEffect(() => {
        dispatch(getAllUsers({ onlyActiveUsers: true }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StyledFilter>
            <div className="date-and-manager-filter__manager-filter">
                <p>Менеджер</p>
                <CustomSelect
                    width={262}
                    height={24}
                    backgroundImage={BulletListIcon}
                    backgroundColor="#fff"
                >
                    <select
                        value={user?.isAdmin ? Number(filterParams.manager) : user?.userId || 0}
                        onChange={(e) => filterChangeHandler(e.target.value, 'manager')}
                        disabled={!user?.isAdmin}
                    >
                        {managers.map((item) => (
                            <option key={item.userId} value={item.userId}>
                                {item.fullName}
                            </option>
                        ))}
                    </select>
                </CustomSelect>
            </div>
            <div className="date-and-manager-filter__date-filter">
                <p>Выбрать дату</p>
                <input
                    type="date"
                    value={filterParams.date}
                    onChange={(e) => filterChangeHandler(e.target.value, 'date')}
                />
            </div>
        </StyledFilter>
    );
};

export default Filter;
