import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { formatNum } from '../../utils/functions';
import CommonModal from '../Common/CommonModal/CommonModal';
import StyledFormWrapper from './Calculator.style';

type CalculatorProps = {
    close: () => void;
    width: number;
};

const cashbackPercent = 10;

const Calculator = ({ close, width }: CalculatorProps) => {
    const [sumOfMoney, setSumOfMoney] = useState('');
    const [daysRange, setDaysRange] = useState('');
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [isIncludingEndDate, setIncludingEndDate] = useState(false);
    const [baseCommission, setBaseCommission] = useState(0);
    const [percent, setPercent] = useState('');

    const calculate = () => {
        const sumWithoutSpaces = sumOfMoney.split(' ').join('');
        const daysRangeWithoutSpaces = daysRange.split(' ').join('');
        const normalizeSumOfMoney = Number(sumWithoutSpaces.split(',').join('.'));
        if (!normalizeSumOfMoney) {
            return toast.error('Сумма должна быть положительным числом');
        }
        if (!daysRangeWithoutSpaces) {
            return toast.error('Срок действия должен быть положительным числом');
        }
        setBaseCommission((normalizeSumOfMoney / 365) * Number(daysRangeWithoutSpaces));
    };

    const getCommission = (percent: number) => {
        return (percent / 100) * baseCommission;
    };

    const getCashback = (percent: number) => {
        return (percent / 100) * baseCommission * (cashbackPercent / 100);
    };

    const getPercent = (key: number) => {
        return key ? 1 + key * 0.5 : Number(percent.split(',').join('.'));
    };

    useEffect(() => {
        if (!(dateFrom && dateTo)) return;
        if (dateFrom > dateTo) {
            toast.error('Дата конца периода не может быть меньше даты его начала');
            return;
        }
        const datesRange = new Date(dateTo).getTime() - new Date(dateFrom).getTime();
        setDaysRange(
            formatNum(String(datesRange / (1000 * 60 * 60 * 24) + (isIncludingEndDate ? 1 : 0))),
        );
    }, [dateFrom, dateTo, isIncludingEndDate]);

    return (
        <CommonModal hasCloseBtn={true} close={close}>
            <StyledFormWrapper width={width}>
                <h3>Расчет стоимости</h3>
                <div>
                    <div>
                        Сумма БГ (указать в рублях):
                        <input
                            value={sumOfMoney}
                            onChange={(e) => setSumOfMoney(formatNum(e.target.value, sumOfMoney))}
                        />
                    </div>
                    <div>
                        Срок действия банковской гарантии (в днях):
                        <div className="calculator__container-for-period">
                            <input
                                className="calculator__period"
                                value={daysRange}
                                onChange={(e) =>
                                    setDaysRange(formatNum(e.target.value, String(daysRange), true))
                                }
                            />
                            <span>или выберите период с</span>
                            <input
                                type="date"
                                className="calculator__date-picker"
                                value={dateFrom}
                                onChange={(e) => setDateFrom(e.target.value)}
                            />
                            <span>по</span>
                            <input
                                type="date"
                                className="calculator__date-picker"
                                value={dateTo}
                                onChange={(e) => setDateTo(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="calculator__checkbox">
                        Включая конечную дату:
                        <input
                            checked={isIncludingEndDate}
                            type="checkbox"
                            onClick={() => setIncludingEndDate(!isIncludingEndDate)}
                        />
                    </div>
                    <div className="calculator__btn">
                        <p>Результаты расчета</p>
                        <button onClick={calculate}>Рассчитать</button>
                    </div>
                    <div className="calculator__calculation-results-header">
                        <p>Комиссия</p>
                        <p>Кэшбэк</p>
                        <p>Итоговая стоимость</p>
                    </div>
                    <div className="calculator__calculation-results">
                        {Array.from(Array(7).keys()).map((key) => (
                            <div key={key} className="calculator__calculation-results-row">
                                {key ? (
                                    <p className="calculator__percent">{getPercent(key)} %</p>
                                ) : (
                                    <input
                                        className="calculator__percent"
                                        value={percent}
                                        onChange={(e) =>
                                            setPercent(formatNum(e.target.value, percent))
                                        }
                                    />
                                )}
                                <div className="calculator__calculation-results-item calculator__commission">
                                    {formatNum(getCommission(getPercent(key)).toFixed(2))}
                                </div>
                                <div className="calculator__calculation-results-item calculator__cashback">
                                    {formatNum(getCashback(getPercent(key)).toFixed(2))}
                                </div>
                                <div className="calculator__calculation-results-item">
                                    {cashbackPercent}%
                                </div>
                                <div className="calculator__calculation-results-item calculator__total-cost">
                                    {formatNum(
                                        (
                                            getCommission(getPercent(key)) -
                                            getCashback(getPercent(key))
                                        ).toFixed(2),
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </StyledFormWrapper>
        </CommonModal>
    );
};

export default Calculator;
