import styled from 'styled-components/macro';

const StyledCell = styled.div`
    display: flex;
    align-items: center;
    p {
        width: 76px;
        margin-right: 10px;
    }
`;

export default StyledCell;
