import React from 'react';
import clsx from 'clsx';

import { getAmountInRub, getClosedOffer } from '../../../../utils/functions';
import { ClientTaskInterface } from '../../../../utils/types';
import StyledCell from './ClosedRequestIncome.style';

type ClosedRequestIncomeProps = {
    task: ClientTaskInterface;
};

const ClosedRequestIncome = ({ task }: ClosedRequestIncomeProps) => {
    return (
        <StyledCell>
            <p
                className={clsx({
                    'requests-table__green-field': getClosedOffer(task),
                })}
            >
                {getClosedOffer(task) ? getAmountInRub(getClosedOffer(task)?.income) : '---'}
            </p>
        </StyledCell>
    );
};

export default ClosedRequestIncome;
