import styled from 'styled-components/macro';

const StyledFilter = styled.div`
    font-weight: 300;
    font-size: 12px;
    margin-bottom: 1px;
    .filter__body {
        margin-top: 10px;
    }

    .filter__header {
        height: 32px;
        padding: 5px 7px;
        display: grid;
        grid-template-columns: 270px 1fr 170px;
        grid-column-gap: 14px;
        font-weight: 500;
        font-size: 14px;
        color: #fff;
        background: #959595;
        img {
            width: 20px;
            height: 20px;
            margin: 0 10px;
        }
    }

    .filter__search,
    .filter__minimize-filter-btn,
    .filter__add-client-btn,
    .filter__partners-count {
        display: flex;
        align-items: center;
        p {
            width: fit-content;
        }
    }

    .filter__partners-count {
        span {
            font-weight: 300;
            font-size: 12px;
        }
    }

    .filter__minimize-filter-btn {
        display: flex;
        justify-content: end;
        img {
            margin: 0 0 0 20px;
        }
    }

    .filter__add-client-btn,
    .filter__minimize-filter-btn {
        cursor: pointer;
    }

    .filter__field {
        font-weight: 300;
        height: 20px;
        margin-bottom: 10px;
        font-size: 12px;
        display: grid;
        grid-template-columns: 270px 1fr;
        grid-column-gap: 20px;
        p {
            display: flex;
            align-items: center;
        }
    }

    .filter__input {
        margin-left: 19px;
        width: 262px;
        background: #f9f9f9;
        border: 0.5px solid #000;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        padding: 0 5px 1px 10px;
    }

    .filter__apply-btn {
        font-weight: 600;
        font-size: 12px;
        line-height: 140%;
        margin: 46px 0 6px;
        text-decoration-line: underline;
        cursor: pointer;
        color: #0038ff;
    }

    .filter__minimized-filter {
        img {
            transform: rotate(180deg);
        }
    }

    .custom-select__field-with-padding {
        margin-left: 19px;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .filter__header-without-search {
        grid-template-columns: 1fr 170px;
    }
`;

export default StyledFilter;
