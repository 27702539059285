import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../../store';
import {
    downloadAllCompanyDocuments,
    getAllCompanyDocuments,
    selectClientDocument,
} from '../../../store/slices/clientDocumentSlice';
import { downloadAsFile } from '../../../utils/functions';
import { documentSections } from '../../../utils/data';
import { selectClientCard } from '../../../store/slices/clientCardSlice';
import DocumentCategoryList from './DocumentCategoryList/DocumentCategoryList';
import RarIcon from '../../../assets/images/clients/rar-file-icon.png';
import TaskDocumentSection from './TaskDocumentSection/TaskDocumentSection';
import StyledClientDocuments from './ClientDocuments.style';

const ClientDocuments = () => {
    const dispatch = useAppDispatch();
    const { company } = useSelector(selectClientCard);
    const firstUpdate = useRef(true);
    const { isRequestFulfilled, archive } = useSelector(selectClientDocument);
    const [isFailed, setFailed] = useState(false);

    const downloadHandler = () => {
        if (!company?.companyId) return;
        dispatch(downloadAllCompanyDocuments({ companyId: company.companyId }));
    };

    const downloadDocs = () => {
        if (!company) return;
        dispatch(getAllCompanyDocuments({ companyId: company.companyId }));
    };

    const successHandler = () => {
        setFailed(false);
    };

    const errorHandler = () => {
        if (isFailed) return;
        setFailed(true);
        downloadDocs();
    };

    useEffect(() => {
        downloadDocs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company]);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        const docName = `документы (${company?.name || ''})`;
        if (isRequestFulfilled.downloadArchive && archive) {
            downloadAsFile(archive, docName, errorHandler, successHandler);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRequestFulfilled.downloadArchive, archive]);

    return (
        <StyledClientDocuments>
            <div className="client-documents__header">
                <h3>Документы компании:</h3>
                <div className="client-documents__show-section-btn">
                    <p onClick={downloadHandler}>Скачать одним архивом</p>
                    <img src={RarIcon} />
                </div>
            </div>
            {documentSections.map((section) => (
                <DocumentCategoryList key={section.sectionName} section={section} />
            ))}
            <TaskDocumentSection />
        </StyledClientDocuments>
    );
};

export default ClientDocuments;
