import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';
import toast from 'react-hot-toast';

import { useAppDispatch } from '../../store';
import { clearState, loginUser, userSelector } from '../../store/slices/userSlice';
import { getItem } from '../../utils/functions';
import { authValidationSchema } from '../../validation/authValidation';
import PasswordEyeIcon from '../../assets/images/password-eye-icon.png';
import BtnLoader from '../../components/Common/BtnLoader/BtnLoader';
import { StyledAuthWrapper, LoginFormWrapper } from './Auth.style';

type Inputs = {
    username: string;
    password: string;
};

const Auth = () => {
    const dispatch = useAppDispatch();
    const [showPassword, setShowPassword] = useState(false);

    const formOptions = { resolver: yupResolver(authValidationSchema) };
    const { register, handleSubmit, formState } = useForm<Inputs>(formOptions);
    const history = useNavigate();
    const { errors } = formState;
    const { isFetching, isSuccess, isError, errorMessage, isAuth } = useSelector(userSelector);
    const onSubmit: SubmitHandler<Inputs> = ({
        username,
        password,
    }: {
        username: string;
        password: string;
    }) => {
        let deviceId = getItem('deviceId');
        if (!deviceId) {
            deviceId = uuid();
            if (!deviceId) return;
            localStorage.setItem('deviceId', deviceId);
        }
        dispatch(loginUser({ username, password, deviceId }));
    };

    useEffect(() => {
        if (isAuth) {
            history('/');
        }
        return () => {
            dispatch(clearState());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (errors.username?.message) {
            toast.error(errors.username.message);
        }
        if (errors.password?.message) {
            toast.error(errors.password.message);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);

    useEffect(() => {
        if (isError) {
            toast.error(errorMessage);
            dispatch(clearState());
        }
        if (isSuccess) {
            dispatch(clearState());
            history('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isError, isSuccess]);

    return (
        <StyledAuthWrapper>
            <div className="auth__heading">Авторизация</div>
            <LoginFormWrapper>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="auth__form-group">
                        <label>
                            Логин:
                            <input id="username" required {...register('username')} />
                        </label>
                    </div>
                    <div className="auth__form-group">
                        <label>
                            Пароль:
                            <input
                                id="password"
                                type={showPassword ? 'text' : 'password'}
                                required
                                {...register('password')}
                            />
                            <div className="auth__show-password-icon">
                                <img
                                    src={PasswordEyeIcon}
                                    onClick={() => setShowPassword(!showPassword)}
                                />
                            </div>
                        </label>
                    </div>
                    <BtnLoader
                        isLoading={isFetching}
                        btnTitle="Войти"
                        btnClass={clsx('auth__submit-btn', {
                            'auth__disabled-btn': isFetching,
                        })}
                    />
                </form>
            </LoginFormWrapper>
        </StyledAuthWrapper>
    );
};

export default Auth;
