import styled from 'styled-components/macro';

const StyledWindow = styled.div<{ width: number; height: number }>`
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    position: relative;
    .confirm-modal__header {
        text-align: center;
    }
    .confirm-modal__buttons {
        display: flex;
        justify-content: space-between;
        margin: 30px 10px 0;
        button {
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            color: #0038ff;
            width: 100px;
            height: 30px;
            border: 1px solid #000;
            opacity: 0.8;
            background-color: #e7e7e7;
            border-radius: 5px;
            text-decoration: none;
            &:hover {
                opacity: 1;
            }
        }
    }
`;

export default StyledWindow;
