import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import clsx from 'clsx';

import { useAppDispatch } from '../../store';
import {
    createCompanyNote,
    selectClientCard,
    updateCompanyNote,
} from '../../store/slices/clientCardSlice';
import { CompanyNoteInterface } from '../../utils/types';
import { noteColors, noteColorsNames } from '../../utils/data';
import CommonModal from '../Common/CommonModal/CommonModal';
import CustomSelect from '../Common/CustomSelect/CustomSelect';
import DefaultLoader from '../Common/BtnLoader/DefaultLoader';
import StyledForm from './AddNote.style';

type AddNoteProps = {
    close: () => void;
    width: number;
    companyId: number;
    note?: CompanyNoteInterface;
};

const AddNote = ({ close, width, companyId, note }: AddNoteProps) => {
    const dispatch = useAppDispatch();
    const { loading, isRequestFulfilled } = useSelector(selectClientCard);
    const [text, setText] = useState(note?.text || '');
    const initialColorState = () => {
        return note?.color ? note.color : 'white';
    };
    const [highlightColor, setHighlightColor] = useState<noteColorsNames>(initialColorState());
    const firstUpdate = useRef(true);

    const addNewNoteDate = () => {
        if (!text) return toast.error('Заметка не содержит записей');
        if (note) {
            return dispatch(
                updateCompanyNote({
                    companyId,
                    text,
                    noteId: note.companyNoteId,
                    color: highlightColor,
                }),
            );
        }
        dispatch(
            createCompanyNote({
                companyId,
                text,
                color: highlightColor,
            }),
        );
    };

    const actionName = note ? 'Редактировать' : 'Создать';

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if (isRequestFulfilled.note) {
            close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRequestFulfilled.note]);

    return (
        <CommonModal hasCloseBtn={true} close={close}>
            <StyledForm width={width}>
                <h3 className="add-note__header">{actionName} заметку</h3>
                <textarea
                    className="add-note__text"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                />
                <label className="add-note__highlight">
                    Выделить цветом:
                    <CustomSelect width={278} height={24} backgroundImage={'none'}>
                        <select
                            className={clsx({
                                'add-note__red-highlight': highlightColor === 'red',
                                'add-note__yellow-highlight': highlightColor === 'yellow',
                            })}
                            value={highlightColor}
                            onChange={(e) => setHighlightColor(e.target.value as noteColorsNames)}
                        >
                            {noteColors.map((color) => (
                                <option key={color.name} value={color.name}>
                                    {color.label}
                                </option>
                            ))}
                        </select>
                    </CustomSelect>
                </label>
                <button className="add-note__button" type="button" onClick={addNewNoteDate}>
                    {loading.note ? <DefaultLoader /> : actionName}
                </button>
            </StyledForm>
        </CommonModal>
    );
};

export default AddNote;
