import styled from 'styled-components/macro';

const StyledOffersForTask = styled.div<{ isLoan: boolean }>`
    font-weight: 500;
    font-size: 12px;

    .offers-for-task-table__header {
        padding: 5px 7px;
        align-items: center;
        display: grid;
        grid-template-columns: ${({ isLoan }) =>
            isLoan
                ? `100px minmax(130px, 4fr) minmax(130px, 2fr) minmax(70px, 2fr) repeat(2, minmax(130px, 4fr))
            35px 50px minmax(100px, 2fr) 20px`
                : `100px minmax(130px, 4fr) minmax(70px, 2fr) repeat(3, minmax(130px, 4fr))
            minmax(80px, 2fr) 35px 50px minmax(100px, 2fr)
            minmax(60px, 1fr) minmax(70px, 2fr) 20px`};
        grid-column-gap: 10px;
        height: 30px;
        border-bottom: 1px solid #959595;

        img {
            width: 20px;
            height: 20px;
            margin-left: 10px;
            transform: rotate(180deg);
        }
    }

    .offers-for-task-table__row {
        padding: 5px 7px;
        display: grid;
        grid-template-columns: ${({ isLoan }) =>
            isLoan
                ? `100px minmax(130px, 4fr) minmax(130px, 2fr) minmax(70px, 2fr) repeat(2, minmax(130px, 4fr))
            35px 50px minmax(100px, 2fr) 20px`
                : `100px minmax(130px, 4fr) minmax(70px, 2fr) repeat(3, minmax(130px, 4fr))
            minmax(80px, 2fr) 35px 50px minmax(100px, 2fr)
            minmax(60px, 1fr) minmax(70px, 2fr) 20px`};
        grid-column-gap: 10px;
        align-items: center;
    }

    .offers-for-task-table__odd-field {
        background: #f8f8f8;
    }

    .offers-for-task-table__general-info {
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
            line-height: 30px;
            font-weight: 600;
        }

        span {
            margin: 0 5px;
        }
    }

    .offers-for-task-table__grey-row {
        opacity: 0.4;
    }

    .offers-for-task-table__green {
        color: #00892f;
    }

    .offers-for-task-table__red {
        color: #d20202;
    }

    .offers-for-task-table__info-with-btn {
        background: #e7e7e7;
        display: grid;
        grid-template-columns: 1fr 110px;
        grid-column-gap: 10px;
        padding: 0 8px;

        button {
            text-decoration: underline;
            color: #0038ff;
            background: transparent;
            border: none;
        }
    }

    .offers-for-task-table__edit-icon {
        width: 18px;
        height: 18px;
        cursor: pointer;
    }

    .offers-for-task-table__add-bank-btn {
        padding: 8px 0;
        display: flex;
        align-items: end;
    }

    .offers-for-task-table__doc {
        text-decoration: underline;
        color: #0038ff;
        border: none;
        background: transparent;
        padding: 0;
        text-align: start;
    }

    .offers-for-task-table__request {
        display: flex;
        justify-content: end;
        align-items: center;
        margin-top: -4px;

        img {
            margin: 0 6px;
            cursor: pointer;
        }
    }

    .offers-for-task-table__request-number {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: #c4c4c4;
        color: #fff;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .offers-for-task-table__without-file {
        cursor: auto;
    }

    .offers-for-task-table__bank-field {
        display: flex;
        justify-content: space-between;
    }

    .offers-for-task-table__logo {
        object-fit: contain;
        width: 75px;
        height: 30px;
        margin: 0 !important;
    }

    .offers-for-task-table__active-instruction {
        cursor: pointer;
    }

    .offers-for-task-table__commission span {
        font-weight: 600;
    }

    .offers-for-task-table__disabled-offer {
        opacity: 0.5;

        .offers-for-task-table__edit-icon,
        .offers-for-task-table__request img {
            cursor: auto;
        }
    }

    .offers-for-task-table__active-request-number {
        background: #ff0000;
    }

    .offers-for-task-table__green-row {
        background: rgba(154, 255, 188, 0.5);
    }

    .offers-for-task-table__disabled-request img {
        cursor: auto;
    }

    .offers-for-task-table__right-position {
        display: flex;
        justify-content: flex-end;
    }
`;

export default StyledOffersForTask;
