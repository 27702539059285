/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';
import clsx from 'clsx';

import { OfferDocSections, OfferDocTypes } from '../../../utils/data';
import {
    convertFieldToFloat,
    getAmountInRub,
    getCredentialLkOperator,
    getLabelForTaskOfferStatus,
    getTooltipLength,
} from '../../../utils/functions';
import {
    OfferInterface,
    OfferWithBankProduct,
    PartnerDocument,
    TaskOfferStatusTypes,
    TaskProductENUM,
} from '../../../utils/types';
import EyeIcon from '../../../assets/images/open-eye-icon.png';
import SelectedEyeIcon from '../../../assets/images/selected-open-eye-icon.png';
import RequestIcon from '../../../assets/images/partners/request-for-offer.png';
import RedEditOfferIcon from '../../../assets/images/tasks/red-edit-offer-icon.png';
import EditOfferIcon from '../../../assets/images/tasks/edit-offer-icon.png';
import EnabledGearsIcon from '../../../assets/images/partners/green-gears-icon.png';
import DisabledGearsIcon from '../../../assets/images/partners/gray-gears-icon.png';
import TooltipReminder from '../../../components/Common/TooltipReminder/TooltipReminder';
import {
    OfferTableDocType,
    offerCommissionFieldsType,
} from '../OffersForTaskTable/OffersForTaskTable';
import GetCommission from '../../../components/GetCommission/GetCommission';

type OffersForTaskTableRowProps = {
    offer: OfferWithBankProduct;
    index: number;
    givenTaskOfferId?: number;
    status: TaskOfferStatusTypes;
    isLoan: boolean;
    isTaskClosed: boolean;
    downloadDocument: (value: OfferTableDocType) => void;
    setEditingOfferForReq: (value: React.SetStateAction<OfferInterface | null>) => void;
    setEditingOffer: (value: React.SetStateAction<OfferInterface | null>) => void;
};

const loanOfferCommissionFields: offerCommissionFieldsType[] = ['bankCommission', 'baseCommission'];

const bankGuaranteeOfferCommissionFields: offerCommissionFieldsType[] = [
    ...loanOfferCommissionFields,
    'clientCommission',
];

const OffersForTaskTableRow = ({
    offer,
    index,
    givenTaskOfferId,
    status,
    isLoan,
    isTaskClosed,
    downloadDocument,
    setEditingOfferForReq,
    setEditingOffer,
}: OffersForTaskTableRowProps) => {
    const getInstruction = (documents: PartnerDocument[]) => {
        return documents.find((doc) => doc.section === 'instructions') || null;
    };
    const offerCommissionFields = isLoan
        ? loanOfferCommissionFields
        : bankGuaranteeOfferCommissionFields;

    const downloadInstructions = (documents: PartnerDocument[]) => {
        const doc = getInstruction(documents);

        if (!doc) return;

        downloadDocument(doc);
    };
    const selectRequestForEditing = (offer: OfferInterface) => {
        if (isTaskClosed) return;

        setEditingOfferForReq(offer);
    };

    const selectOfferForEditing = (offer: OfferInterface) => {
        if (isTaskClosed && givenTaskOfferId !== offer.taskOfferId) return;

        setEditingOffer(offer);
    };
    const getDocument = (offer: OfferInterface, section: OfferDocTypes) => {
        const document = offer.documents.find((doc) => doc.section === section);
        return document;
    };

    const docClickHandler = (offer: OfferInterface, section: OfferDocTypes) => {
        const file = offer.documents.find((doc) => doc.section === section);
        if (!file) return;

        downloadDocument(file);
    };

    const rowClassName = clsx('offers-for-task-table__row', {
        'offers-for-task-table__odd-field': index % 2 === 0,
        'offers-for-task-table__disabled-offer':
            isTaskClosed && givenTaskOfferId !== offer.taskOfferId,
        'offers-for-task-table__green-row': givenTaskOfferId === offer.taskOfferId,
        'offers-for-task-table__grey-row':
            status === 'bankRefused' || status === 'clientRefused' || status === 'fixed',
    });
    const imgClasName = clsx({
        'offers-for-task-table__active-instruction': getInstruction(
            offer.bankProduct.bank.documents,
        ),
    });
    const requestBlockClassName = clsx('offers-for-task-table__request', {
        'offers-for-task-table__disabled-request': isTaskClosed,
    });
    const requestTooltipBadgeClassName = clsx('offers-for-task-table__request-number', {
        'offers-for-task-table__active-request-number': offer.requests?.[0]?.isActive,
    });

    const imgSource = useMemo(() => {
        return getInstruction(offer.bankProduct.bank.documents)
            ? EnabledGearsIcon
            : DisabledGearsIcon;
    }, [offer.bankProduct.bank.documents]);

    const amount = useMemo(
        () => (offer.amount ? getAmountInRub(offer.amount) : '---'),
        [offer.amount],
    );

    const rate = useMemo(() => Number(offer.rateFrom ?? '').toFixed(2), [offer.rateFrom]);

    const overstatement = useMemo(
        () => convertFieldToFloat(2, '---', offer.overstatement),
        [offer.overstatement],
    );
    const commentTooltipMinWidth = useMemo(() => {
        return getTooltipLength({
            textLength: offer.comment?.length,
            minLetters: 10,
            letterWidth: 6,
        });
    }, [offer.comment?.length]);

    const requesttTooltipMinWidth = useMemo(() => {
        return getTooltipLength({
            textLength: offer.requests?.[0]?.text?.length,
            minLetters: 10,
            letterWidth: 6,
        });
    }, [offer.requests]);

    const offerStatus = useMemo(
        () => (offer.status ? getLabelForTaskOfferStatus(offer.status) : '---'),
        [offer.status],
    );

    const credential = useMemo(() => {
        return getCredentialLkOperator(offer.bankProduct.bank.credentials, isLoan);
    }, [isLoan, offer.bankProduct.bank.credentials]);

    return (
        <div className={rowClassName}>
            <div className="offers-for-task-table__bank-field">
                <img
                    className="offers-for-task-table__logo"
                    src={offer.bankProduct.bank.logo || ''}
                />
                <img
                    className={imgClasName}
                    src={imgSource}
                    onClick={() => downloadInstructions(offer.bankProduct.bank.documents)}
                />
            </div>
            <p>{credential}</p>
            {isLoan ? <div className="offers-for-task-table__right-position">{amount}</div> : null}
            <div className="offers-for-task-table__right-position">
                <p>{rate}</p>
            </div>
            {offerCommissionFields.map((field) => (
                <div
                    key={field}
                    className="offers-for-task-table__commission offers-for-task-table__right-position"
                >
                    <GetCommission commissionType={field} offer={offer} />
                </div>
            ))}
            {!isLoan && (
                <div className="offers-for-task-table__right-position">
                    <p>{overstatement}</p>
                </div>
            )}
            <TooltipReminder
                text={offer.comment || ''}
                hasReminder={Boolean(offer.comment)}
                rightMargin={35}
                maxWidth={800}
                minWidth={commentTooltipMinWidth}
            >
                <img src={offer.comment ? SelectedEyeIcon : EyeIcon} />
            </TooltipReminder>
            <div className={requestBlockClassName}>
                {offer.requests.length ? (
                    <TooltipReminder
                        text={offer.requests?.[0]?.text || ''}
                        hasReminder={Boolean(offer.requests.length && offer.requests[0].isActive)}
                        rightMargin={18}
                        hasHtml={true}
                        maxWidth={800}
                        minWidth={requesttTooltipMinWidth}
                    >
                        <span className={requestTooltipBadgeClassName}>
                            {offer.requests.length}
                        </span>
                    </TooltipReminder>
                ) : null}
                <img src={RequestIcon} onClick={() => selectRequestForEditing(offer)} />
            </div>
            <p>{offerStatus}</p>
            {!isLoan &&
                OfferDocSections.map((section) => (
                    <button
                        type="button"
                        key={section}
                        className={clsx('offers-for-task-table__doc', {
                            'offers-for-task-table__without-file': !getDocument(offer, section),
                        })}
                        onClick={() => docClickHandler(offer, section)}
                    >
                        {getDocument(offer, section) ? 'Скачать' : '---'}
                    </button>
                ))}
            <img
                className="offers-for-task-table__edit-icon"
                src={givenTaskOfferId === offer.taskOfferId ? RedEditOfferIcon : EditOfferIcon}
                onClick={() => selectOfferForEditing(offer)}
            />
        </div>
    );
};

export default OffersForTaskTableRow;
