import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import clsx from 'clsx';
import 'react-phone-input-2/lib/style.css';

import { useAppDispatch } from '../../store';
import { setAddedClient, clearCheckInnResult } from '../../store/slices/clientDatabaseSlice';
import {
    createCompanyContact,
    getExternalContacts,
    selectClientCard,
    updateCompanyContact,
} from '../../store/slices/clientCardSlice';
import {
    contactHighlightColors,
    contactHighlightColorsNames,
    emptyEmailWithErrorField,
    emptyPhoneWithErrorField,
} from '../../utils/data';
import CustomSelect from '../Common/CustomSelect/CustomSelect';
import {
    CompanyContactInterface,
    PhoneWithAdditInfoType,
    EmailWithError,
    PhoneWithError,
    EmailWithAdditInfoType,
} from '../../utils/types';
import {
    addInContactCheckErrorField,
    checkEmailArray,
    checkPhonesArray,
    hasEmailOrPhoneError,
    normalizeEmails,
    normalizePhoneNumbers,
} from '../../utils/functions';
import CommonModal from '../Common/CommonModal/CommonModal';
import AddPhoneAndEmail from '../Common/AddPhoneAndEmail/AddPhoneAndEmail';
import DefaultLoader from '../Common/BtnLoader/DefaultLoader';
import StyledFormWrapper from './AddContact.style';

type AddContactProps = {
    close: () => void;
    width: number;
    companyId: number;
    contact?: CompanyContactInterface;
};

const AddContact = ({ close, width, companyId, contact }: AddContactProps) => {
    const dispatch = useAppDispatch();
    const firstUpdate = useRef(true);
    const { loading, isRequestFulfilled } = useSelector(selectClientCard);
    const getInitialState = (state: 'phone' | 'email') => {
        if (state === 'phone') {
            return contact?.phoneNumbers.length
                ? addInContactCheckErrorField(contact.phoneNumbers)
                : [{ ...emptyPhoneWithErrorField }];
        } else {
            return contact?.emails.length
                ? addInContactCheckErrorField(contact.emails)
                : [{ ...emptyEmailWithErrorField }];
        }
    };
    const [phoneNumbers, setPhoneNumbers] = useState(getInitialState('phone') as PhoneWithError[]);
    const [emails, setEmails] = useState(getInitialState('email') as EmailWithError[]);
    const [name, setName] = useState(contact ? contact.name : '');
    const [highlightColor, setHighlightColor] = useState(contact?.color ? contact.color : 'white');
    const [isPriority, setPriority] = useState(contact?.isPriority || false);

    const btnName = contact ? 'Обновить контакт' : 'Добавить контакт';

    const togglePriority = () => {
        setPriority((prev) => !prev);
    };

    const checkPhonesAndEmails = () => {
        let hasPhoneError = false;
        let hasEmailError = false;
        const newPhoneNumbers = checkPhonesArray(phoneNumbers);
        const newEmails = checkEmailArray(emails);
        if (!hasPhoneError && hasEmailOrPhoneError(newPhoneNumbers)) {
            hasPhoneError = true;
        }
        if (!hasEmailError && hasEmailOrPhoneError(newEmails)) {
            hasEmailError = true;
        }
        setPhoneNumbers(newPhoneNumbers);
        setEmails(newEmails);
        return hasEmailError || hasPhoneError;
    };

    const addContactHandler = () => {
        if (checkPhonesAndEmails()) return;
        const normalizedEmails: EmailWithAdditInfoType[] = normalizeEmails(emails);
        const normalizedPhones: PhoneWithAdditInfoType[] = normalizePhoneNumbers(phoneNumbers);

        if (!(normalizedPhones.length || normalizedEmails.length)) {
            return toast.error('Необходим как минимум один валидный имейл или телефон');
        }
        const newContact = {
            companyId,
            name: name || undefined,
            emails: normalizedEmails,
            phoneNumbers: normalizedPhones,
            isPriority: isPriority,
            color: highlightColor as contactHighlightColorsNames,
        };
        if (contact) {
            return dispatch(
                updateCompanyContact({
                    ...newContact,
                    contactId: contact.companyContactId,
                }),
            );
        }
        dispatch(createCompanyContact(newContact));
    };

    const getExternalContactsButton = async () => {
        const result = await dispatch(getExternalContacts({ companyId })).unwrap();
        setEmails(result.emails);
        setPhoneNumbers(result.phones);
    };

    useEffect(() => {
        dispatch(setAddedClient({}));
        dispatch(clearCheckInnResult());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if (isRequestFulfilled.contact) {
            close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRequestFulfilled.contact]);

    return (
        <CommonModal hasCloseBtn={true} close={close}>
            <StyledFormWrapper width={width}>
                <div className="add-contact__header">
                    <h3>{contact ? 'Редактировать' : 'Добавить новый'} контакт</h3>
                    {!contact ? (
                        loading.getExternalContacts ? (
                            <DefaultLoader />
                        ) : (
                            <button onClick={getExternalContactsButton}>Импорт контактов</button>
                        )
                    ) : null}
                </div>
                <label>
                    ФИО:
                    <input
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </label>
                <AddPhoneAndEmail
                    phoneNumbers={phoneNumbers}
                    emails={emails}
                    setEmails={setEmails}
                    setPhoneNumbers={setPhoneNumbers}
                />
                <label className="add-contact__priority">
                    Установить приоритет:
                    <input
                        checked={isPriority}
                        type="checkbox"
                        id="priority"
                        onChange={togglePriority}
                    />
                </label>
                <label className="add-contact__highlight">
                    Выделить цветом:
                    <CustomSelect width={278} height={24} backgroundImage={'none'}>
                        <select
                            className={clsx({
                                'add-contact__red-highlight': highlightColor === 'red',
                                'add-contact__green-highlight': highlightColor === 'green',
                            })}
                            value={highlightColor}
                            onChange={(e) =>
                                setHighlightColor(e.target.value as contactHighlightColorsNames)
                            }
                        >
                            {contactHighlightColors.map((color) => (
                                <option key={color.name} value={color.name}>
                                    {color.label}
                                </option>
                            ))}
                        </select>
                    </CustomSelect>
                </label>
                <div className="add-contact__btn">
                    <button
                        className={clsx({
                            'add-clients-info__disabled-btn': loading.contact,
                        })}
                        onClick={addContactHandler}
                        disabled={loading.contact}
                    >
                        {loading.contact ? <DefaultLoader /> : btnName}
                    </button>
                </div>
            </StyledFormWrapper>
        </CommonModal>
    );
};

export default AddContact;
