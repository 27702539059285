import styled from 'styled-components/macro';

const StyledReminderItem = styled.div`
    font-weight: 500;
    font-size: 12px;
    padding: 5px 10px;
    display: grid;
    grid-template-columns: 1fr 20px;
    grid-column-gap: 15px;
    background: #fff;
    border-bottom: 1px solid lightgray;
    margin-bottom: 10px;
    border-radius: 5px;

    .reminder-item__info {
        line-height: 17px;
        display: flex;
        flex-direction: column;
    }

    .reminder-item__text {
        font-weight: 600;
        margin: 0 7px;
        word-break: break-word;
    }

    .reminder-item__action {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }

    .reminder-item__date {
        color: #03f;
        margin-left: 7px;
    }
`;

export default StyledReminderItem;
