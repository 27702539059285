import styled from 'styled-components/macro';

const StyledForm = styled.form<{ width: number; height: number }>`
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    position: relative;
    .add-next-contact-date__button {
        border: none;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #0038ff;
        position: absolute;
        right: 0;
        bottom: 10px;
    }

    .add-next-contact-date__input {
        margin: 30px 0 0;
        width: 100%;
        height: 24px;
        border: 1px solid #000;
        border-radius: 5px;
        padding: 2px 10px;
    }
`;

export default StyledForm;
