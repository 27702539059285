import styled from 'styled-components/macro';

const StyledAppWrapper = styled.div`
    display: grid;
    grid-template-columns: 240px 1fr;
    .spinner {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const StyledSpinner = styled.div`
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export { StyledAppWrapper, StyledSpinner };
