import {
    CashbackInterface,
    CashbackStatusType,
    CompanyCommentInterface,
    StatisticTaskCommentInterface,
} from '../../utils/types';

/* eslint-disable */
export enum statisticsApiPath {
    COMPANY_COMMENTS = 'statistic/comments/companies',
    TASK_COMMENTS = 'statistic/comments/tasks',
    CASHBACK_STATISTIC = '/statistic/cashback',
}
/* eslint-enable */

export type GetAllCompanyCommentsPropsType = {
    date?: string;
    manager?: string;
    abortSignal?: AbortSignal;
};

export type GetCompanyCommentMeta = {
    page: number;
    perPage: number;
    totalPages: number;
    totalRecords: number;
};

export type GetAllCompanyCommentsResponseType =
    | {
          message: string;
          data: {
              commentsList: CompanyCommentInterface[];
              total: { records: number };
          };
          meta: GetCompanyCommentMeta;
      }
    | undefined;

export type GetAllRequestCommentsResponseType =
    | {
          message: string;
          data: {
              commentsList: StatisticTaskCommentInterface[];
              total: { records: number };
          };
          meta: GetCompanyCommentMeta;
      }
    | undefined;

export type GetAllCashbackPropsType = {
    dateFrom?: string;
    dateTo?: string;
    status?: CashbackStatusType;
};

export type GetAllCashbackResponseType =
    | {
          message: string;
          data: {
              cashbackList: CashbackInterface[];
              total: { records: number; amount: number };
          };
          meta: GetCompanyCommentMeta;
      }
    | undefined;
