import styled from 'styled-components/macro';

const StyledTaskPage = styled.div`
    display: flex;
    justify-content: center;
    align-items: start;
    margin-top: 10px;
`;

export default StyledTaskPage;
