import styled from 'styled-components/macro';

const StyledForm = styled.form<{ width: string }>`
    width: ${({ width }) => width};
    max-width: 86vw;
    padding-bottom: 90px;
    position: relative;

    .add-bank-to-task__header {
        text-align: center;
        margin-bottom: 20px;
    }

    .add-bank-to-task__button {
        margin: 12px 0 10px;
        border: none;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #0038ff;
        position: absolute;
        right: 0;
        bottom: 10px;
    }

    .add-bank-to-task__disabled-btn {
        margin: 0;
    }
`;

export default StyledForm;
