/* eslint-disable no-unused-vars */
import { CallsRecordInterface } from '../../utils/types';

export enum callsApiPath {
    CRUD_CALLS = '/call',
}

export type GetAllCallsPropsType = {
    managerId?: number;
    dateFrom?: string;
    dateTo?: string;
    phone?: string;
    duration?: number;
    direction?: string;
    abortSignal?: AbortSignal;
};

export type GetAllCallsResponseType =
    | {
          message: string;
          data: {
              callRecords: CallsRecordInterface[];
          };
      }
    | undefined;

export type UpdateCallPropsType = {
    callId: number;
    comment?: string;
};

export type UpdateCallResponseType =
    | {
          message: string;
          data: CallsRecordInterface;
      }
    | undefined;
