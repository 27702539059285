import React from 'react';

import CustomSelect from '../../../components/Common/CustomSelect/CustomSelect';
import BulletListIcon from '../../../assets/images/bullet-list-icon.png';
import StyledFilterWrapper from './ProductAndStatusFilter.style';
import { BankGuaranteeTaskStatuses, LoanTaskStatuses, TaskProducts } from '../../../utils/data';
import {
    ActiveRequestsFilterFieldENUM,
    ActiveRequestsFilterTitleENUM,
} from '../../../utils/consts';
import { ActiveRequestsFilterParams, TaskProductENUM } from '../../../utils/types';

export type fieldTypesInFilter = 'status' | 'type';

type ProductAndStatusFilterProps = {
    // eslint-disable-next-line no-unused-vars
    filterChangeHandler: (value: string, field: fieldTypesInFilter) => void;
    filterParams: ActiveRequestsFilterParams;
};

const ProductAndStatusFilter = ({
    filterChangeHandler,
    filterParams,
}: ProductAndStatusFilterProps) => {
    const statusOptions =
        filterParams[ActiveRequestsFilterFieldENUM.TYPE] === TaskProductENUM.LOAN
            ? LoanTaskStatuses
            : BankGuaranteeTaskStatuses;

    const filteredStatusOptions = statusOptions.filter((status) => status.name !== 'closed');

    const filters = [
        {
            title: ActiveRequestsFilterTitleENUM.PRODUCT_TYPE,
            field: ActiveRequestsFilterFieldENUM.TYPE,
            options: [{ name: '', label: 'Все' }, ...TaskProducts],
        },
        {
            title: ActiveRequestsFilterTitleENUM.STATUS,
            field: ActiveRequestsFilterFieldENUM.STATUS,
            options: [{ name: '', label: 'Все' }, ...filteredStatusOptions],
        },
    ];
    return (
        <StyledFilterWrapper>
            {filters.map((filter) => (
                <div key={filter.title} className="filter__product-container">
                    <p>{filter.title}</p>{' '}
                    <CustomSelect
                        width={180}
                        height={20}
                        backgroundImage={BulletListIcon}
                        backgroundColor="#f9f9f9"
                    >
                        <select
                            disabled={
                                filter.field === ActiveRequestsFilterFieldENUM.STATUS &&
                                filterParams[ActiveRequestsFilterFieldENUM.TYPE] === ''
                            }
                            value={filterParams[filter.field]}
                            onChange={(e) => filterChangeHandler(e.target.value, filter.field)}
                        >
                            {filter.options.map((item) => (
                                <option key={item.label} value={item.name}>
                                    {item.label}
                                </option>
                            ))}
                        </select>
                    </CustomSelect>
                </div>
            ))}
        </StyledFilterWrapper>
    );
};

export default ProductAndStatusFilter;
