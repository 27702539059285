import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import { appSlice } from './slices/appSlice/appSlice';
import { userSlice } from './slices/userSlice';
import { clientDatabaseSlice } from './slices/clientDatabaseSlice';
import { locationSlice } from './slices/locationSlice';
import { clientCardSlice } from './slices/clientCardSlice';
import { staffSlice } from './slices/staffSlice';
import { clientDocumentSlice } from './slices/clientDocumentSlice';
import { statisticsSlice } from './slices/statisticsSlice';
import { clientTaskSlice } from './slices/clientTaskSlice';
import { partnerSlice } from './slices/partnerSlice';
import { companyCashbackSlice } from './slices/companyCashbackSlice';
import { clientActSlice } from './slices/actSlice';
import { callsSlice } from './slices/callsSlice';

const rootReducer = combineReducers({
    user: userSlice.reducer,
    app: appSlice.reducer,
    clientDatabase: clientDatabaseSlice.reducer,
    clientDocument: clientDocumentSlice.reducer,
    location: locationSlice.reducer,
    clientCard: clientCardSlice.reducer,
    clientTask: clientTaskSlice.reducer,
    staff: staffSlice.reducer,
    statistics: statisticsSlice.reducer,
    partner: partnerSlice.reducer,
    companyCashback: companyCashbackSlice.reducer,
    clientAct: clientActSlice.reducer,
    calls: callsSlice.reducer,
});

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
    });
};

const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
