import React, { useMemo } from 'react';
import clsx from 'clsx';
import { PartnerInterface } from '../../../../utils/types';
import { getLabelByProductType } from '../../../../utils/functions';

type LabelByProductTypeProps = {
    partner: PartnerInterface;
    productType: string;
};

const LabelByProductType = ({ partner, productType }: LabelByProductTypeProps) => {
    const checkHasProduct = (partner: PartnerInterface, type: string) => {
        const product = partner.products.find((product) => product.type === type);
        return Boolean(product?.isActive);
    };
    const productTypeLabel = useMemo(() => getLabelByProductType(productType), [productType]);
    const labelsClass = useMemo(
        () =>
            clsx({
                'partners-table__active-law': checkHasProduct(partner, productType),
            }),
        [partner, productType],
    );
    return <p className={labelsClass}>{productTypeLabel}</p>;
};

export default LabelByProductType;
