import styled from 'styled-components/macro';

const StyledFormWrapper = styled.div`
    .add-partner-contact--delete-btn {
        color: #ff0000;
        text-decoration: underline;
        margin-bottom: 10px;
    }
`;

export default StyledFormWrapper;
