import React, { useEffect, useRef } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import toast from 'react-hot-toast';

import { useAppDispatch } from '../../store';
import { addReceiverSchema } from '../../validation/clientActValidation';
import { FieldNameTypeInAddingReceiverForm, fieldsInAddingReceiverForm } from '../../utils/data';
import { ReceiverInterface } from '../../utils/types';
import { StyledScrollbars } from '../AddClient/AddClientsInfo/AddClientsInfo.style';
import {
    createReceiver,
    getCompanyReceivers,
    selectClientAct,
    updateCompanyReceiver,
} from '../../store/slices/actSlice';
import { selectClientCard } from '../../store/slices/clientCardSlice';
import { getAllCompanyCashback } from '../../store/slices/companyCashbackSlice';
import { StyledFormWrapper } from '../AddBankTask/AddBankTask.style';
import CommonModal from '../Common/CommonModal/CommonModal';
import BtnLoader from '../Common/BtnLoader/BtnLoader';
import StyledContainer from './AddActReceiver.style';

type AddActReceiverProps = {
    close: () => void;
    width: number;
    companyId: number;
    receiver: ReceiverInterface | null;
};

type Inputs = {
    inn: string;
    name: string;
    headName: string;
    headPost: string;
};

const AddActReceiver = ({ close, width, companyId, receiver }: AddActReceiverProps) => {
    const dispatch = useAppDispatch();
    const firstUpdate = useRef(true);
    const { loading, isRequestFulfilled } = useSelector(selectClientAct);
    const { company } = useSelector(selectClientCard);

    const header = `${receiver ? 'Редактировать' : 'Добавить нового'} получателя`;
    const btnTitle = receiver ? 'Сохранить' : 'Добавить';
    const windowHeight = 300;

    const formOptions = {
        defaultValues: {
            inn: receiver?.inn ?? '',
            name: receiver?.name ?? '',
            headName: receiver?.headName ?? '',
            headPost: receiver?.headPost ?? '',
        },
        resolver: yupResolver(addReceiverSchema),
    };
    const { register, handleSubmit, formState } = useForm<Inputs>(formOptions);
    const { errors } = formState;

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const { name, headName, headPost, inn } = data;

        if (receiver) {
            dispatch(
                updateCompanyReceiver({
                    inn: inn !== receiver.inn ? inn : undefined,
                    headPost: headPost !== receiver.headPost ? headPost : undefined,
                    headName: headName !== receiver.headName ? headName : undefined,
                    name: name !== receiver.name ? name : undefined,
                    companyReceiverId: receiver.companyReceiverId,
                    companyId,
                }),
            );
            return;
        }

        if (!(inn && headPost && headName && name)) return;

        const payload = {
            inn,
            headPost,
            headName,
            name,
            companyId,
        };
        dispatch(createReceiver(payload));
    };

    useEffect(() => {
        if (!company) return;

        const { companyId } = company;

        dispatch(getCompanyReceivers({ companyId }));

        dispatch(
            getAllCompanyCashback({
                order: 'ASC',
                sortBy: 'transactionDate',
                hasAct: false,
                companyId,
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let hasEmptyFieldError = false;
        Object.keys(addReceiverSchema.fields).forEach((key) => {
            const errorMessage = errors[key as FieldNameTypeInAddingReceiverForm]?.message;
            if (errorMessage?.startsWith('Заполните') || errorMessage?.startsWith('Выберите')) {
                if (!hasEmptyFieldError) {
                    hasEmptyFieldError = true;
                    toast.error('Заполните необходимые поля');
                }
            } else if (errorMessage) {
                toast.error(errorMessage);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if (isRequestFulfilled.crudReceiver) {
            close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRequestFulfilled.crudReceiver]);

    return (
        <CommonModal hasCloseBtn={true} close={close}>
            <StyledFormWrapper width={width}>
                <StyledContainer height={windowHeight}>
                    <h3 className="add-receiver__title">{header}</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <StyledScrollbars
                            style={{ height: 'calc(100% - 30px)' }}
                            renderTrackHorizontal={(props) => (
                                <div
                                    {...props}
                                    style={{ display: 'none' }}
                                    className="track-horizontal"
                                />
                            )}
                            thumbSize={170}
                            thumbMinSize={30}
                        >
                            {fieldsInAddingReceiverForm.map((field) => (
                                <div key={field.fieldName} className="add-receiver__label">
                                    {field.label}{' '}
                                    {field.isRequired && (
                                        <span className="add-receiver__sign-of-obligatory-field">
                                            *
                                        </span>
                                    )}
                                    :
                                    <input
                                        className={clsx({
                                            'add-receiver__error-input': errors[field.fieldName],
                                        })}
                                        id={field.fieldName}
                                        {...register(field.fieldName)}
                                    />
                                </div>
                            ))}
                        </StyledScrollbars>
                        <BtnLoader
                            isLoading={loading.crudReceiver}
                            btnTitle={btnTitle}
                            btnClass={clsx('add-receiver__btn', {
                                'add-receiver__disabled-btn': loading.crudReceiver,
                            })}
                        />
                    </form>
                </StyledContainer>
            </StyledFormWrapper>
        </CommonModal>
    );
};

export default AddActReceiver;
