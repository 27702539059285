import React from 'react';

import DefaultLoader from '../BtnLoader/DefaultLoader';
import CommonModal from '../CommonModal/CommonModal';
import StyledWindow from './ConfirmModal.style';

type ConfirmModalProps = {
    close: () => void;
    width?: number;
    height?: number;
    headText: string;
    confirmAction: () => void;
    loading?: boolean;
};

const ConfirmModal = ({
    close,
    height = 220,
    width = 360,
    confirmAction,
    headText,
    loading,
}: ConfirmModalProps) => {
    return (
        <CommonModal hasCloseBtn={false} close={close}>
            <StyledWindow width={width} height={height}>
                <h3 className="confirm-modal__header">{headText}</h3>
                <div className="confirm-modal__buttons">
                    <button className="confirm-modal__yes-button" type="button" onClick={close}>
                        Нет
                    </button>
                    <button
                        className="confirm-modal__no-button"
                        type="button"
                        onClick={confirmAction}
                        disabled={loading}
                    >
                        {loading ? <DefaultLoader /> : 'Да'}
                    </button>
                </div>
            </StyledWindow>
        </CommonModal>
    );
};

export default ConfirmModal;
