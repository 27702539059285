import React, { useState } from 'react';
import clsx from 'clsx';

import {
    BankProductCreateInterface,
    BankProductDocumentCreateInterface,
    BankProductType,
} from '../../../../utils/types';
import { useSelector } from 'react-redux';
import { selectPartner, updateBankProduct } from '../../../../store/slices/partnerSlice';
import { useAppDispatch } from '../../../../store';
import {
    downloadAsFile,
    formatNum,
    getProductFromPartner,
    getProductTitle,
    stringToNumber,
} from '../../../../utils/functions';
import {
    productFieldNameEnum,
    FieldNamesInAddBankProduct,
    addingBankProductFormSections,
    addingLoanBankProductFormSections,
    numericFieldsInAddBankProduct,
    bankProductPrefixEnum,
} from '../../../../utils/data';
import { userSelector } from '../../../../store/slices/userSlice';
import DisabledStarIcon from '../../../../assets/images/partners/disabled-star-icon.png';
import EnabledStarIcon from '../../../../assets/images/partners/star-icon.png';
import EnabledPdfIcon from '../../../../assets/images/partners/pdf-icon.png';
import DisabledPdfIcon from '../../../../assets/images/partners/disabled-pdf-icon.png';
import EditIcon from '../../../../assets/images/partners/edit-bank-product-icon.png';
import EnabledInfoIcon from '../../../../assets/images/partners/additional-info-icon.png';
import DisabledInfoIcon from '../../../../assets/images/partners/disabled-info-icon.png';
import ToggleSwitch from '../../../Common/ToggleSwitch/ToggleSwitch';
import AddProduct from '../../AddProduct/AddProduct';
import StyledProductContainer from './BankProduct.style';
import TooltipAlt from '../../../Common/TooltipAlt/TooltipAlt';

type BankProductProps = {
    bankProduct?: BankProductCreateInterface;
    productType: BankProductType;
    index?: number;
    // eslint-disable-next-line no-unused-vars
    setProduct: (product: BankProductCreateInterface, type: BankProductType) => void;
};

const BankProduct = ({ bankProduct, productType, setProduct, index }: BankProductProps) => {
    const dispatch = useAppDispatch();
    const { currentPartner } = useSelector(selectPartner);
    const { user } = useSelector(userSelector);
    const [isAddProductModalOpen, setAddProductModalOpen] = useState(false);
    const [unloadedFile, setUnloadedFile] = useState<null | BankProductDocumentCreateInterface>(
        null,
    );
    const InfoIcon =
        bankProduct?.isActive && bankProduct?.additInfo ? EnabledInfoIcon : DisabledInfoIcon;
    const PdfIcon = bankProduct?.documents.length ? EnabledPdfIcon : DisabledPdfIcon;
    const StarIcon = bankProduct?.lists.length ? EnabledStarIcon : DisabledStarIcon;
    const isCommerce = productType.startsWith(bankProductPrefixEnum.commercial);
    const isLoan = productType.startsWith(bankProductPrefixEnum.loan);
    const sections = isLoan ? addingLoanBankProductFormSections : addingBankProductFormSections;

    const getYesOrNoCell = (
        fieldName: FieldNamesInAddBankProduct,
        isHiddenInCommercial?: boolean,
    ) => {
        if (!bankProduct?.isActive || (isHiddenInCommercial && isCommerce)) {
            return '';
        }

        if (fieldName === productFieldNameEnum.empty) {
            return '';
        }

        if (!bankProduct || !(fieldName in bankProduct)) return '---';

        return bankProduct[fieldName] ? 'ДА' : '---';
    };

    const getStringCell = (fieldName: FieldNamesInAddBankProduct) => {
        if (!bankProduct?.isActive) return '';

        if (!bankProduct || !(fieldName in bankProduct)) return '---';

        if (fieldName === productFieldNameEnum.empty) {
            return '';
        }

        let cellValue = bankProduct[fieldName] as string;

        if (fieldName === productFieldNameEnum.limit) {
            cellValue = Number(cellValue).toFixed(2);
        }

        if (numericFieldsInAddBankProduct.includes(fieldName)) {
            cellValue = formatNum(cellValue ? String(cellValue) : '');
        }

        if (fieldName.endsWith('Commission') || fieldName === productFieldNameEnum.rateFrom) {
            cellValue = cellValue ? `${stringToNumber(cellValue).toFixed(2)}%` : '';
        }

        return cellValue || '---';
    };

    const editBtnHandler = () => {
        if (!bankProduct?.isActive) return;

        setAddProductModalOpen(true);
    };

    const getCompaniesList = () => {
        return getProductFromPartner(productType, currentPartner)?.lists?.map((item) => item.name);
    };

    const toggleSwitch = () => {
        if (currentPartner) {
            const bankProductId = getProductFromPartner(productType, currentPartner)?.bankProductId;

            if (!bankProductId) return;

            dispatch(
                updateBankProduct({
                    bankId: currentPartner.bankId,
                    bankProductId,
                    isActive: !bankProduct?.isActive,
                }),
            );
            return;
        }

        if (bankProduct?.isActive) {
            setProduct(
                {
                    ...bankProduct,
                    isActive: false,
                },
                productType,
            );
            return;
        }

        setAddProductModalOpen(true);
    };

    const addProductHandler = (value: BankProductCreateInterface) => {
        setProduct(value, productType);
    };
    const successHandler = () => {
        setUnloadedFile(null);
    };

    const errorHandler = (doc: BankProductDocumentCreateInterface) => {
        if (unloadedFile) return;
        setUnloadedFile(doc);
    };

    const downloadDocument = (doc: BankProductDocumentCreateInterface | null) => {
        if (!doc) {
            return;
        }
        downloadAsFile(doc.file, doc.name, () => errorHandler(doc), successHandler);
    };
    const tooltipInfoId = `bank-product-info-tooltip-${productType}-${index}`;
    const tooltipStarId = `bank-product-star-tooltip-${productType}-${index}`;

    return (
        <StyledProductContainer>
            <div className="bank-product__first-cell">
                <img src={InfoIcon} id={tooltipInfoId} />
                {bankProduct?.isActive && bankProduct?.additInfo && (
                    <TooltipAlt anchor={tooltipInfoId} content={bankProduct.additInfo || ''} />
                )}
                <p
                    className={clsx('bank-product-name', {
                        'bank-product__disabled-name': !bankProduct?.isActive,
                    })}
                    onClick={editBtnHandler}
                >
                    {getProductTitle(productType)}
                </p>
            </div>
            {sections.percent.map(({ fieldName }) => (
                <p key={fieldName} className="bank-product__table-cell">
                    {getStringCell(fieldName)}
                </p>
            ))}
            {sections.laws.map(({ fieldName }) => (
                <p key={String(fieldName)} className="bank-product__table-cell">
                    {getYesOrNoCell(fieldName, true)}
                </p>
            ))}
            {sections.bankExperience.map(({ fieldName }) => (
                <p key={String(fieldName)} className="bank-product__table-cell">
                    {getStringCell(fieldName)}
                </p>
            ))}
            {sections.registrationTerm.map(({ fieldName }) => (
                <p key={String(fieldName)} className="bank-product__table-cell">
                    {getStringCell(fieldName)}
                </p>
            ))}
            {sections.workWith.map(({ fieldName }) => (
                <p key={String(fieldName)} className="bank-product__table-cell">
                    {getYesOrNoCell(fieldName)}
                </p>
            ))}
            <div className="bank-product__table-cell">
                {Boolean(bankProduct?.lists?.length) && <img src={StarIcon} id={tooltipStarId} />}
                {!isCommerce && bankProduct?.isActive && Boolean(bankProduct?.lists?.length) && (
                    <TooltipAlt anchor={tooltipStarId} content={getCompaniesList() || ''} />
                )}
            </div>
            {sections.commission.map(({ fieldName }) => (
                <p
                    key={String(fieldName)}
                    className="bank-product__table-cell bank-product__red-cell"
                >
                    {getStringCell(fieldName)}
                </p>
            ))}
            <div className="bank-product__actions">
                {bankProduct?.isActive ? (
                    <>
                        <img
                            className={
                                bankProduct.documents[0]
                                    ? `bank-product-name`
                                    : `bank-product__not-active-img`
                            }
                            src={PdfIcon}
                            onClick={() => downloadDocument(bankProduct.documents[0])}
                        />
                        {user?.isAdmin && (
                            <img
                                className={clsx({
                                    'bank-product__not-active-img': !bankProduct?.isActive,
                                })}
                                onClick={editBtnHandler}
                                src={EditIcon}
                            />
                        )}
                    </>
                ) : null}
                {user?.isAdmin && (
                    <ToggleSwitch
                        width={27}
                        height={16}
                        toggle={toggleSwitch}
                        checked={!!bankProduct?.isActive}
                    />
                )}
            </div>
            {isAddProductModalOpen && (
                <AddProduct
                    product={bankProduct}
                    productType={productType}
                    width={400}
                    close={() => setAddProductModalOpen(false)}
                    setProduct={addProductHandler}
                />
            )}
        </StyledProductContainer>
    );
};

export default BankProduct;
