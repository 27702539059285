import React, { useMemo } from 'react';
import clsx from 'clsx';

import CoexecutorIcon from '../../../../assets/images/tasks/coexecutor-icon.png';
import DisabledBellIcon from '../../../../assets/images/bell-icon.png';
import DefaultWarningIcon from '../../../../assets/images/clients/warning-icon.png';
import SelectedBellIcon from '../../../../assets/images/selected-bell-icon.png';
import SelectedWarningIcon from '../../../../assets/images/clients/selected-warning-icon.png';

import { ClientTaskInterface, IUser, TaskProductENUM } from '../../../../utils/types';
import TooltipReminder from '../../../../components/Common/TooltipReminder/TooltipReminder';
import {
    getAmountInRub,
    getBankProductAcronym,
    getDate,
    getLabelByLaw,
    getLabelForTaskStatus,
    getLinkForTask,
    getLinkToClientCardFromTask,
    getTaskNumber,
    getTooltipLength,
    getUserName,
} from '../../../../utils/functions';
import LoanProductLabel from '../../../../components/LoanProductLabel/LoanProductLabel';
import TooltipAlt from '../../../../components/Common/TooltipAlt/TooltipAlt';

type ActiveRequestsTableRowProps = {
    task: ClientTaskInterface;
    user: IUser | null;
    index: number;
};
const ActiveRequestsTableRow = ({ task, user, index }: ActiveRequestsTableRowProps) => {
    const checkRightForLinkToCard = (
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        task: ClientTaskInterface,
    ) => {
        const adminOrManager = user?.isAdmin || task.company.manager?.userId === user?.userId;

        if (!adminOrManager) {
            e.preventDefault();
            e.stopPropagation();
        }
    };
    const isLoan = task.type === TaskProductENUM.LOAN;
    const rowClass = useMemo(
        () =>
            clsx('active-requests-table__row', {
                'active-requests-table__manager-view': !user?.isAdmin,
                'active-requests-table__even-row': index % 2 !== 0,
                'active-requests-table__red-row': task.importance === 'urgent',
                'active-requests-table__green-row': task.status === 'onRelease',
            }),
        [index, task.importance, task.status, user?.isAdmin],
    );
    const taskDate = useMemo(
        () => getDate(task.createdAt, 'date with time') || '---',
        [task.createdAt],
    );
    const taskNumber = useMemo(() => {
        return {
            value: getTaskNumber(task),
            link: getLinkForTask(task),
        };
    }, [task]);

    const companyNameClass = useMemo(
        () =>
            clsx({
                'active-requests-table__link': task.company.name,
                'active-requests-table__disabled-link':
                    !user?.isAdmin && task.company.manager?.userId !== user?.userId,
            }),
        [task.company.manager?.userId, task.company.name, user?.isAdmin, user?.userId],
    );
    const taskCompany = useMemo(() => {
        return {
            name: task.company.name || '---',
            link: getLinkToClientCardFromTask(task, user),
        };
    }, [task, user]);
    const bankProductAcronym = useMemo(
        () => getBankProductAcronym(isLoan ? task.loanType : task.bankGuaranteeType),
        [isLoan, task.bankGuaranteeType, task.loanType],
    );
    const lawLabel = useMemo(() => getLabelByLaw(task.federalLaw) || '---', [task.federalLaw]);
    const taskAmount = useMemo(
        () => getAmountInRub(isLoan ? task.loanAmount : task.bankGuaranteeAmount || ''),
        [isLoan, task.bankGuaranteeAmount, task.loanAmount],
    );
    const taskTerm = useMemo(() => {
        const term = isLoan ? task.loanTermMonths : task.bankGuaranteeTermDays;
        const termString = getAmountInRub(String(term || ''), 0);
        const termUnit = isLoan ? ' мес.' : '';
        return termString + termUnit;
    }, [isLoan, task.bankGuaranteeTermDays, task.loanTermMonths]);
    const taskStatus = useMemo(() => getLabelForTaskStatus(task.status, task.type), [task]);
    const managerColumn = useMemo(
        () => (user?.isAdmin ? <p>{getUserName(task.company.manager)}</p> : null),
        [task.company.manager, user?.isAdmin],
    );
    const taskManagerName = useMemo(() => getUserName(task.executor), [task]);
    const taskCoExecutor = useMemo(
        () => (task.coExecutor ? <img src={CoexecutorIcon} /> : null),
        [task.coExecutor],
    );
    const remindersTooltipMinWidth = useMemo(
        () =>
            getTooltipLength({
                textLength: task.reminders?.[0]?.text?.length,
            }),
        [task.reminders],
    );
    const loanLabel = isLoan ? <LoanProductLabel /> : null;
    const tooltipInfoId = `bank-product-info-tooltip-${task.type}-${index}`;
    return (
        <div className={rowClass}>
            <p>{taskDate}</p>
            <div>{loanLabel}</div>
            <a
                className="active-requests-table__link"
                target="_blank"
                rel="noopener noreferrer"
                href={taskNumber.link}
            >
                {taskNumber.value}
            </a>
            <a
                className={companyNameClass}
                onClick={(e) => checkRightForLinkToCard(e, task)}
                href={taskCompany.link}
                target="_blank"
                rel="noopener noreferrer"
            >
                {taskCompany.name}
            </a>
            <p>{bankProductAcronym}</p>
            <p>{lawLabel}</p>
            <div className="active-requests-table__right-position">
                <p>{taskAmount}</p>
            </div>
            <div className="active-requests-table__right-position">
                <p>{taskTerm}</p>
            </div>
            <p>{taskStatus}</p>
            {managerColumn}
            <div className="active-requests-table__executor">
                <p>{taskManagerName}</p>
                {taskCoExecutor}
            </div>
            <div className="active-requests-table__actions">
                <img
                    id={tooltipInfoId}
                    src={task.importantInfo ? SelectedWarningIcon : DefaultWarningIcon}
                />
                {task.importantInfo && (
                    <TooltipAlt anchor={tooltipInfoId} content={task.importantInfo || ''} />
                )}
                <TooltipReminder
                    text={task.reminders?.[0]?.text || ''}
                    time={task.reminders?.[0]?.time || ''}
                    hasReminder={Boolean(task.reminders.length)}
                    rightMargin={20}
                    maxWidth={800}
                    minWidth={remindersTooltipMinWidth}
                >
                    <img src={task.reminders.length ? SelectedBellIcon : DisabledBellIcon} />
                </TooltipReminder>
            </div>
        </div>
    );
};

export default ActiveRequestsTableRow;
