import styled from 'styled-components/macro';

const StyledForm = styled.form<{ width: number }>`
    width: ${({ width }) => `${width}px`};
    max-width: 86vw;
    position: relative;
    padding-bottom: 50px;
    .add-comment__header {
        text-align: center;
        margin-bottom: 20px;
    }

    .add-comment__button {
        border: none;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #0038ff;
        position: absolute;
        right: 0;
        bottom: 10px;
    }

    .editor-content {
        max-height: 70vh;
        background: #fff;
        overflow-y: auto;
        padding: 15px;

        ul,
        ol {
            padding-left: 10px;
            margin: 0;
        }
    }

    .rdw-emoji-modal {
        width: 800px;
        max-width: calc(86vw - 100px);
        left: -46px;
    }

    .public-DraftStyleDefault-ltr {
        margin: 0;
    }

    .editor-toolbar {
        background: #000;
    }
`;

export default StyledForm;
