import styled from 'styled-components/macro';

const StyledContainer = styled.div`
    .check-inn__description {
        font-style: italic;
        font-weight: 300;
        font-size: 10px;
        line-height: 14px;
        color: #000;
        margin: 8px 0 15px;
    }

    .check-inn__disabled-btn {
        margin: 10px 0;
    }
`;

export default StyledContainer;
