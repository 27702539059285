import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';

import { useAppDispatch } from '../../store';
import { selectClientTask, updateTask } from '../../store/slices/clientTaskSlice';
import CommonModal from '../Common/CommonModal/CommonModal';
import DefaultLoader from '../Common/BtnLoader/DefaultLoader';
import StyledForm from './AddTaskImportantInfo.style';
import { TaskProductTypes } from '../../utils/types';

type AddTaskImportantInfoProps = {
    close: () => void;
    width: number;
    companyId: number;
    taskId: number;
    taskType: TaskProductTypes;
    initialText: string;
};

const AddTaskImportantInfo = ({
    close,
    width,
    companyId,
    taskId,
    taskType,
    initialText,
}: AddTaskImportantInfoProps) => {
    const dispatch = useAppDispatch();
    const firstUpdate = useRef(true);
    const { loading, isRequestFulfilled } = useSelector(selectClientTask);
    const [importantInfo, setImportantInfo] = useState(initialText);

    const addNewImportantInfo = () => {
        if (!importantInfo) return toast.error('Заполните важную информацию');

        dispatch(
            updateTask({
                companyId,
                taskId,
                importantInfo,
                type: taskType,
            }),
        );
    };

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if (isRequestFulfilled.updateTask) {
            toast.success('Информация добавлена');
            close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRequestFulfilled.updateTask]);

    return (
        <CommonModal hasCloseBtn={true} close={close}>
            <StyledForm width={width}>
                <h3 className="add-task-important-info__header">Добавить важную информацию</h3>
                <textarea
                    className="add-task-important-info__text"
                    value={importantInfo}
                    onChange={(e) => setImportantInfo(e.target.value)}
                />
                <button
                    className="add-task-important-info__button"
                    type="button"
                    onClick={addNewImportantInfo}
                >
                    {loading.updateTask ? <DefaultLoader /> : 'Создать'}
                </button>
            </StyledForm>
        </CommonModal>
    );
};

export default AddTaskImportantInfo;
