import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
    CreateCompanyPropsType,
    GetAllCompaniesMeta,
    GetAllCompaniesPropsType,
    UpdateCompanyPropsType,
} from './../../../api/clientDatabaseApi/clientDatabaseApi.types';
import { getError, isResponseOK } from '../../../utils/functions';
import { RootState } from '../..';
import { clientDatabaseApi } from '../../../api/clientDatabaseApi/clientDatabaseApi';
import { InitialStateType, ResultCompany } from './types';
import { getCompany } from '../clientCardSlice';
import { CompanyInterface, IUser } from '../../../utils/types';

export const createCompany = createAsyncThunk<
    IUser,
    CreateCompanyPropsType,
    {
        rejectValue: string;
    }
>('clientDatabase/createCompany', async ({ ...props }, { rejectWithValue }) => {
    try {
        const res = await clientDatabaseApi.createCompany({ ...props });
        if (res?.message === 'Success' || res?.message === 'Company created') {
            return res.data;
        } else {
            throw res?.data;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const restoreCompany = createAsyncThunk<
    any,
    {
        companyId: number;
    },
    {
        rejectValue: string;
    }
>('clientCard/restoreCompany', async ({ ...props }, { rejectWithValue }) => {
    try {
        const res = await clientDatabaseApi.restoreCompany({ ...props });
        if (isResponseOK(res)) {
            return res;
        } else {
            throw res;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const getCompanies = createAsyncThunk<
    {
        data: CompanyInterface[];
        meta: GetAllCompaniesMeta;
    },
    GetAllCompaniesPropsType,
    {
        rejectValue: string;
    }
>('clientDatabase/getCompanies', async ({ ...props }, { rejectWithValue }) => {
    try {
        const res = await clientDatabaseApi.getAllCompanies({ ...props });
        if (res?.message === 'Success') {
            return {
                data: res.data,
                meta: res.meta,
            };
        } else {
            throw res?.data;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const updateCompany = createAsyncThunk<
    undefined,
    UpdateCompanyPropsType,
    {
        rejectValue: string;
    }
>('clientDatabase/updateCompany', async ({ clientId, ...props }, { rejectWithValue, dispatch }) => {
    try {
        const res = await clientDatabaseApi.updateCompany({ ...props, clientId });
        if (isResponseOK(res)) {
            dispatch(getCompany({ companyId: clientId }));
        } else {
            throw res;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const checkINN = createAsyncThunk<
    ResultCompany,
    {
        inn: string;
    },
    {
        rejectValue: string;
    }
>('clientDatabase/checkINN', async ({ ...props }, { rejectWithValue }) => {
    try {
        const res = await clientDatabaseApi.checkInn({ ...props });
        if (res?.message === 'Success') {
            return res.data;
        } else {
            throw res?.data;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

const initialState: InitialStateType = {
    companies: [],
    currentPage: 1,
    clientsOrder: 'ASC',
    sortBy: 'contactDate',
    addedClient: {},
    checkInnResult: {},
    areCompaniesUpdated: false,
    pagesCount: 1,
    clientsCount: 0,
    loading: {
        companies: false,
        addingClient: false,
        checkINN: false,
        updateCompany: false,
    },
    error: '',
};

export const clientDatabaseSlice = createSlice({
    name: 'clientDatabase',
    initialState,
    reducers: {
        clearClientDatabaseState: (state) => {
            state = initialState;
            return state;
        },
        setCurrentPage: (state, { payload }) => {
            state.currentPage = payload;
            return state;
        },
        setSortingField: (state, { payload }) => {
            state.sortBy = payload;
            return state;
        },
        setClientsOrder: (state, { payload }) => {
            state.clientsOrder = payload;
            return state;
        },
        setSortBy: (state, { payload }) => {
            state.sortBy = payload;
            return state;
        },
        setAddedClient: (state, { payload }) => {
            state.addedClient = payload;
            return state;
        },
        clearCheckInnResult: (state) => {
            state.checkInnResult = {};
            return state;
        },
        setAreCompaniesUpdated: (state, { payload }) => {
            state.areCompaniesUpdated = payload;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createCompany.pending, (state) => {
            state.loading.addingClient = true;
        });
        builder.addCase(createCompany.fulfilled, (state, { payload }) => {
            state.addedClient = payload;
            state.areCompaniesUpdated = true;
            state.loading.addingClient = false;
        });
        builder.addCase(createCompany.rejected, (state, { payload }) => {
            state.addedClient = {};
            state.loading.addingClient = false;
            state.error = payload || '';
        });
        builder.addCase(getCompanies.pending, (state) => {
            state.loading.companies = true;
        });
        builder.addCase(getCompanies.fulfilled, (state, { payload }) => {
            state.loading.companies = false;
            state.companies = payload.data;
            state.pagesCount = payload.meta.totalPages || 1;
            state.clientsCount = payload.meta.totalRecords || 0;
        });
        builder.addCase(getCompanies.rejected, (state, { payload }) => {
            state.loading.companies = false;
            state.error = payload as string;
        });
        builder.addCase(checkINN.pending, (state) => {
            state.loading.checkINN = true;
        });
        builder.addCase(checkINN.fulfilled, (state, { payload }) => {
            state.loading.checkINN = false;
            state.checkInnResult = payload;
        });
        builder.addCase(checkINN.rejected, (state, { payload }) => {
            state.loading.checkINN = false;
            state.error = payload as string;
        });
        builder.addCase(updateCompany.pending, (state) => {
            state.loading.updateCompany = true;
        });
        builder.addCase(updateCompany.fulfilled, (state) => {
            state.loading.updateCompany = false;
            state.areCompaniesUpdated = true;
        });
        builder.addCase(updateCompany.rejected, (state, { payload }) => {
            state.loading.updateCompany = false;
            state.error = payload as string;
        });
    },
});

export const {
    clearClientDatabaseState,
    setClientsOrder,
    setCurrentPage,
    setSortingField,
    setAddedClient,
    clearCheckInnResult,
    setAreCompaniesUpdated,
    setSortBy,
} = clientDatabaseSlice.actions;

export const selectClientDatabase = (state: RootState) => state.clientDatabase;
