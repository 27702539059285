import React, { useEffect } from 'react';

import Filter from './Filter/Filter';
import ClientsTable from './ClientsTable/ClientsTable';
import { getRegions } from '../../store/slices/locationSlice';
import { useAppDispatch } from '../../store';
import StyledClientsPageWrapper from './Clients.style';

const ClientsPage = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getRegions({}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StyledClientsPageWrapper>
            <div className="clients-page__body">
                <Filter />
                <ClientsTable />
            </div>
        </StyledClientsPageWrapper>
    );
};

export default ClientsPage;
