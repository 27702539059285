import React, { useMemo } from 'react';

import { commissionsInRequestTable } from '../../../../utils/data';
import { getClosedOffer } from '../../../../utils/functions';
import { ClientTaskInterface } from '../../../../utils/types';
import TaskCommissionFieldComponent from './TaskCommissionFieldComponent/TaskCommissionFieldComponent';

type TaskCommissionFieldsProps = {
    task: ClientTaskInterface;
};

const TaskCommissionFields = ({ task }: TaskCommissionFieldsProps) => {
    const isLoan = task.type === 'loan';
    const closedOffer = useMemo(() => getClosedOffer(task) || null, [task]);
    return (
        <>
            {commissionsInRequestTable.map((commission, index) => (
                <TaskCommissionFieldComponent
                    key={`${task.taskId}-${index}`}
                    commission={commission}
                    closedOffer={closedOffer}
                    isLoan={isLoan}
                />
            ))}
        </>
    );
};

export default TaskCommissionFields;
