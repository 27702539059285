/* eslint-disable */
export enum locationApiPath {
    FIND_CITY = '/location/find-location/city',
    FIND_REGION = '/location/find-location/region',
}
/* eslint-enable */

export type GetCitiesType = {
    region: string;
    input?: string;
};

export type GetRegionsType = {
    input?: string;
};

export type GetRegionsResponseType =
    | {
          data: string[];
          message: string;
      }
    | undefined;
