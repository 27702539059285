import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';

import { useAppDispatch } from '../../store';
import { createCompanyReminder, selectClientCard } from '../../store/slices/clientCardSlice';
import { getDateAndTimeFromIsoString } from '../../utils/functions';
import CommonModal from '../Common/CommonModal/CommonModal';
import DefaultLoader from '../Common/BtnLoader/DefaultLoader';
import StyledForm from './AddReminder.style';
import { createTaskReminder, selectClientTask } from '../../store/slices/clientTaskSlice';

type AddReminderProps = {
    close: () => void;
    width: number;
    companyId: number;
    taskId?: number;
};

const AddReminder = ({ close, width, companyId, taskId }: AddReminderProps) => {
    const dispatch = useAppDispatch();
    const firstUpdate = useRef(true);
    const { loading: cardLoading, isRequestFulfilled: cardRequest } = useSelector(selectClientCard);
    const { loading: taskLoading, isRequestFulfilled: taskRequest } = useSelector(selectClientTask);
    const [reminderDate, setReminderDate] = useState('');
    const [reminderTime, setReminderTime] = useState('');
    const [text, setText] = useState('');
    const loading = taskId ? taskLoading : cardLoading;
    const isRequestFulfilled = taskId ? taskRequest : cardRequest;

    const handleDateChange = (value: string) => {
        setReminderDate(value);
    };

    const handleTimeChange = (value: string) => {
        setReminderTime(value);
    };

    const addNewReminderDate = () => {
        if (!(reminderDate && reminderTime)) return toast.error('Установите время напоминания');

        if (!text) return toast.error('Заполните текст напоминания');

        if (taskId) {
            const time = new Date(`${reminderDate} ${reminderTime}`).toISOString();
            dispatch(
                createTaskReminder({
                    time,
                    companyId,
                    taskId,
                    text,
                }),
            );
            return;
        }
        dispatch(
            createCompanyReminder({
                time: new Date(`${reminderDate} ${reminderTime}`).toISOString(),
                companyId,
                text,
            }),
        );
    };

    const minDate = getDateAndTimeFromIsoString(new Date().toISOString()).date;

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if (isRequestFulfilled.reminder) {
            toast.success('Напоминание установлено');
            close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRequestFulfilled.reminder]);

    return (
        <CommonModal hasCloseBtn={true} close={close}>
            <StyledForm width={width}>
                <h3 className="add-reminder__header">Установить напоминание</h3>
                <div className="add-reminder__setting-time">
                    <input
                        className="add-reminder__input"
                        type="date"
                        min={minDate}
                        value={reminderDate}
                        onChange={(e) => handleDateChange(e.target.value)}
                    />
                    <input
                        className="add-reminder__input"
                        type="time"
                        value={reminderTime}
                        onChange={(e) => handleTimeChange(e.target.value)}
                    />
                </div>
                <textarea
                    className="add-reminder__text"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                />
                <button className="add-reminder__button" type="button" onClick={addNewReminderDate}>
                    {loading.addingReminder ? <DefaultLoader /> : 'Создать'}
                </button>
            </StyledForm>
        </CommonModal>
    );
};

export default AddReminder;
