import React, { useEffect, useState } from 'react';
import { clsx } from 'clsx';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import 'react-phone-input-2/lib/style.css';

import {
    FieldNameTypeInAddingClientForm,
    FieldNameTypeInEditingClientForm,
    fieldsInAddingClientForm,
    FieldsInAddingClientFormType,
} from '../../../utils/data';
import {
    createCompany,
    selectClientDatabase,
    updateCompany,
} from '../../../store/slices/clientDatabaseSlice';
import { getCities, selectLocation } from '../../../store/slices/locationSlice';
import { addClientValidationSchema } from '../../../validation/addClientValidation';
import { useAppDispatch } from '../../../store';
import { CompanyInterface, ContactPhoneNumberType } from '../../../utils/types';
import { phoneRegExp } from '../../../utils/consts';
import { getDigitsFromString } from '../../../utils/functions';
import CustomSelect from '../../Common/CustomSelect/CustomSelect';
import BulletListIcon from '../../../assets/images/bullet-list-icon.png';
import BtnLoader from '../../Common/BtnLoader/BtnLoader';
import { StyledContainer, StyledPhoneInput, StyledScrollbars } from './AddClientsInfo.style';

type Inputs = {
    inn: string;
    name: string;
    region: string;
    city: string;
    addressLegal: string;
    addressActual: string;
    ogrn: string;
    kpp: string;
    okved: string;
    headName: string;
    headPost: string;
    contactPhone: string;
    email: string;
    comment: string;
    registrationDate?: string | null;
    emails?: string[];
    phones?: string[];
};

type AddClientInfoProps = {
    // eslint-disable-next-line no-unused-vars
    nextStep: () => void;
    inn: string;
    company?: CompanyInterface;
};

const AddClientInfo = ({ nextStep, inn, company }: AddClientInfoProps) => {
    const dispatch = useAppDispatch();
    const { addedClient, loading, checkInnResult } = useSelector(selectClientDatabase);
    const { regions, cities } = useSelector(selectLocation);
    const [wrongRegion, setWrongRegion] = useState(false);

    const isBtnDisabled = company ? loading.updateCompany : loading.addingClient;

    const getListByField = (field: 'region' | 'city') => {
        switch (field) {
            case 'region':
                return regions;
            case 'city':
                return cities;
            default:
                return [];
        }
    };

    const formOptions = { resolver: yupResolver(addClientValidationSchema) };
    const { register, handleSubmit, formState, watch, setValue } = useForm<Inputs>(formOptions);
    const { errors } = formState;
    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        const {
            addressActual,
            addressLegal,
            city,
            headPost,
            headName,
            inn,
            kpp,
            ogrn,
            name,
            okved,
            region,
            email,
            comment,
            registrationDate,
        } = data;
        if (company) {
            try {
                await dispatch(
                    updateCompany({
                        addressLegal:
                            company.addressLegal !== addressLegal ? addressLegal : undefined,
                        addressActual:
                            company.addressActual !== addressActual ? addressActual : undefined,
                        city: city !== company.city ? city : undefined,
                        regionName: region !== company.region.nameWithType ? region : undefined,
                        headName: headName !== company.headName ? headName : undefined,
                        headPost: headPost !== company.headPost ? headPost : undefined,
                        registrationDate:
                            registrationDate &&
                            new Date(registrationDate).toISOString() !== company.registrationDate
                                ? new Date(registrationDate).toISOString()
                                : undefined,
                        name: name !== company.name ? name : undefined,
                        kpp: kpp !== company.kpp ? kpp : undefined,
                        ogrn: ogrn !== company.ogrn ? ogrn : undefined,
                        okved: okved !== company.okved ? okved : undefined,
                        inn: inn !== company.inn ? inn.trim() : undefined,
                        clientId: company.companyId,
                    }),
                ).unwrap();
            } catch (error) {
                if (error === 'Region entity not found') {
                    setWrongRegion(true);
                    toast.error('Введите корректный регион');
                }
            } finally {
                // eslint-disable-next-line no-unsafe-finally
                return;
            }
        }
        if (
            !(
                addressActual &&
                addressLegal &&
                city &&
                headPost &&
                headName &&
                inn &&
                kpp &&
                name &&
                ogrn &&
                okved &&
                region
            )
        ) {
            return;
        }
        try {
            const phoneNumbers = getDefaultValue('phones') as ContactPhoneNumberType[];
            const emails = getDefaultValue('emails') as string[];
            await dispatch(
                createCompany({
                    addressLegal,
                    addressActual,
                    city,
                    regionName: region,
                    headName,
                    headPost,
                    registrationDate: registrationDate
                        ? new Date(registrationDate).toISOString()
                        : undefined,
                    name,
                    kpp,
                    ogrn,
                    okved,
                    inn,
                    email: email || undefined,
                    phone: contactPhone?.match(phoneRegExp)
                        ? getDigitsFromString(contactPhone)
                        : undefined,
                    comment: comment || undefined,
                    phoneNumbers,
                    emails,
                }),
            ).unwrap();
        } catch (error) {
            if (error === 'Region entity not found') {
                setWrongRegion(true);
                toast.error('Введите корректный регион');
            }
        }
    };

    const region = watch('region');
    const contactPhone = watch('contactPhone');
    const city = watch('city');

    const getRegistrationDate = () => {
        const date = company?.registrationDate || checkInnResult?.registrationDate;
        if (!date) return '';
        const dividerIndex = date.indexOf('T');
        return date.slice(0, dividerIndex);
    };

    const getFieldValueFromProps = (field: FieldNameTypeInAddingClientForm): string => {
        if (company && field === 'region') {
            return company[field].nameWithType;
        }
        if (company && field in company) {
            return company[field as FieldNameTypeInEditingClientForm] || '';
        }
        return '';
    };

    const getDefaultValue = (field: FieldNameTypeInAddingClientForm) => {
        switch (field) {
            case 'inn':
                return inn;
            case 'comment':
                return '';
            case 'registrationDate':
                return getRegistrationDate();
            default:
                return field in checkInnResult
                    ? checkInnResult[field] || ''
                    : getFieldValueFromProps(field);
        }
    };

    const getInputField = (field: FieldsInAddingClientFormType) => {
        switch (field.type) {
            case 'input':
                return field.fieldName === 'contactPhone' ? (
                    <StyledPhoneInput
                        disabled
                        country={'ru'}
                        placeholder="+7(000)000-00-00"
                        value={contactPhone || (getDefaultValue(field.fieldName) as string)}
                        onBlur={(e) => setValue('contactPhone', e.target.value)}
                    />
                ) : (
                    <input
                        disabled={field.fieldName === 'email'}
                        className={clsx({
                            'add-clients-info__error-input': errors[field.fieldName]?.message,
                        })}
                        id={field.fieldName}
                        defaultValue={getDefaultValue(field.fieldName) as string}
                        required={false}
                        {...register(field.fieldName)}
                    />
                );
            case 'date':
                return (
                    <input
                        className={clsx({
                            'add-clients-info__error-input': errors[field.fieldName]?.message,
                        })}
                        id={field.fieldName}
                        type="date"
                        defaultValue={getDefaultValue(field.fieldName) as string}
                        required={false}
                        {...register(field.fieldName)}
                    />
                );
            case 'dataset':
                return (
                    <CustomSelect width={388} height={24} backgroundImage={BulletListIcon}>
                        <input
                            className={clsx({
                                'add-clients-info__error-input':
                                    !!errors[field.fieldName]?.message ||
                                    (field.fieldName === 'region' && wrongRegion),
                            })}
                            id={field.fieldName}
                            required={false}
                            list={`${field.fieldName}List`}
                            {...register(field.fieldName)}
                            defaultValue={getDefaultValue(field.fieldName) as string}
                        />
                        <datalist id={`${field.fieldName}List`}>
                            {getListByField(field.fieldName as 'city' | 'region').map(
                                (item, index) => (
                                    <option key={item + index} value={item}>
                                        {item}
                                    </option>
                                ),
                            )}
                        </datalist>
                    </CustomSelect>
                );
            case 'textarea':
                return (
                    <textarea
                        className={clsx('add-clients-info__comment', {
                            'add-clients-info__error-input': errors[field.fieldName]?.message,
                        })}
                        id={field.fieldName}
                        defaultValue={getDefaultValue(field.fieldName) as string}
                        required={false}
                        {...register(field.fieldName)}
                    />
                );
            default:
                break;
        }
    };

    const fields = !company
        ? fieldsInAddingClientForm
        : fieldsInAddingClientForm.filter((field) => field.isEditable);

    useEffect(() => {
        let hasEmptyFieldError = false;
        Object.keys(addClientValidationSchema.fields).forEach((key) => {
            const errorMessage = errors[key as FieldNameTypeInAddingClientForm]?.message;
            if (errorMessage?.startsWith('Заполните') || errorMessage?.startsWith('Выберите')) {
                if (!hasEmptyFieldError) {
                    hasEmptyFieldError = true;
                    toast.error('Заполните необходимые поля');
                }
            } else if (errorMessage) {
                toast.error(errorMessage);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);

    useEffect(() => {
        if ('name' in addedClient) {
            nextStep();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addedClient]);

    useEffect(() => {
        if (region?.startsWith('г ') && region?.length > 3) {
            setValue('city', region);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [region]);

    useEffect(() => {
        if (!(region && city)) return;
        setWrongRegion(false);
        dispatch(getCities({ region, input: city }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [region, city]);

    return (
        <StyledContainer isCreating={!company}>
            <h3>
                {!company
                    ? 'Добавить нового клиента (шаг 2 из 2)'
                    : 'Обновить информацию о клиенте'}
            </h3>
            <StyledScrollbars
                style={{ height: 'calc(100% - 30px)' }}
                renderTrackHorizontal={(props) => (
                    <div {...props} style={{ display: 'none' }} className="track-horizontal" />
                )}
                thumbSize={170}
                thumbMinSize={30}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    {fields.map((field) => (
                        <label key={field.fieldName} className="add-clients-info__label">
                            {field.label}
                            {field.isRequired && (
                                <span className="add-clients-info__sign-of-obligatory-field">
                                    *
                                </span>
                            )}
                            :{getInputField(field)}
                        </label>
                    ))}
                    <BtnLoader
                        isLoading={isBtnDisabled}
                        btnTitle={`${!company ? 'Создать' : 'Обновить'} карточку клиента`}
                        btnClass={clsx('add-clients-info__btn', {
                            'add-clients-info__disabled-btn': isBtnDisabled,
                        })}
                    />
                </form>
            </StyledScrollbars>
        </StyledContainer>
    );
};

export default AddClientInfo;
