import styled from 'styled-components/macro';

const StyledTaskInfo = styled.div`
    font-weight: 500;
    font-size: 12px;

    .task-info__header {
        padding: 5px 7px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #fdd55b;
        height: 30px;
    }

    .task-info__general-information {
        background: #f9f9f9;
        padding-bottom: 7px;
        display: grid;
        grid-template-columns: 1fr 110px;
        grid-column-gap: 10px;
    }

    .task-info__general-information-row {
        font-weight: 600;
        line-height: 16px;
        margin-bottom: 5px;
        padding: 7px 5px;

        span {
            font-weight: 500;
        }

        .task-info__general-information-date {
            font-weight: 700;
        }

        a {
            text-decoration: underline;
            color: #0038ff;
        }
    }

    .task-info__general-information-date {
        font-weight: 700;
        line-height: 16px;
        margin-bottom: 5px;
        padding: 7px 5px;
        color: tomato;
        span {
            font-weight: 700;
            color: #000;
        }
    }

    .task-info__comment {
        padding: 9px 7px;
        font-style: italic;
        background: #fff;
        border-radius: 5px;
        margin: 5px;
        width: calc(100% - 10px);
    }

    .task-info__edit-btn {
        border: none;
        color: #0038ff;
        text-decoration: underline;
        background: transparent;
    }

    .task-info__actions {
        display: flex;
        justify-content: space-between;
        padding: 5px 50px;
    }

    .task-info__urgent-select {
        background-color: #ffd0d0;
    }

    .task-info__action-item {
        display: flex;
        align-items: center;

        p {
            weight: 600;
            margin-right: 10px;
        }
    }

    .task-info__close-task-btn {
        width: 145px;
        height: 24px;
        border-radius: 5px;
        background: #fdd55b;
        border: none;
        line-height: 16px;
        padding: 7px;
        display: flex;
        align-items: center;

        img {
            width: 16px;
            height: 16px;
            margin: -1px 0 0 10px;
        }
    }

    .task-info__disabled-btn {
        cursor: auto;
        background: #c4c4c4;
    }

    select[disabled] {
        cursor: auto;
    }

    select[disabled] {
        cursor: auto;
    }
`;

export default StyledTaskInfo;
