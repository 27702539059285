import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { DocumentCategoryInterface, DocumentInterface } from '../../../../../utils/types';
import { selectClientCard } from '../../../../../store/slices/clientCardSlice';
import { useAppDispatch } from '../../../../../store';
import {
    deleteCompanyDocument,
    getAllCompanyDocuments,
} from '../../../../../store/slices/clientDocumentSlice';
import { downloadAsFile } from '../../../../../utils/functions';
import { userSelector } from '../../../../../store/slices/userSlice';
import AddDocument from '../../../../../components/AddDocument/AddDocument';
import DeleteIcon from '../../../../../assets/images/clients/delete-document-icon.png';
import AddIcon from '../../../../../assets/images/clients/add-document-icon.png';
import StyledCategoryItem from './CategoryItem.style';
import ConfirmModal from '../../../../../components/Common/ConfirmModal/ConfirmModal';

type CategoryItemProps = {
    category: DocumentCategoryInterface;
    index: number;
};

const CategoryItem = ({ category, index }: CategoryItemProps) => {
    const dispatch = useAppDispatch();
    const { company } = useSelector(selectClientCard);
    const { user } = useSelector(userSelector);
    const isManagerOrAdmin = user?.isAdmin || company?.manager?.userId === user?.userId;
    const [isAddDocumentModalOpen, setAddDocumentModalOpen] = useState(false);
    const [unloadedFile, setUnloadedFile] = useState<null | DocumentInterface>(null);
    const [documentProcessedForDelete, setDocumentProcessedForDelete] =
        useState<null | DocumentInterface>(null);

    const deleteDocumentHandler = (companyDocumentId: number) => {
        if (!(companyDocumentId && company?.companyId)) return;
        dispatch(
            deleteCompanyDocument({
                companyId: company.companyId,
                companyDocumentId,
                hardDelete: true,
            }),
        );
        setDocumentProcessedForDelete(null);
    };

    const successHandler = () => {
        setUnloadedFile(null);
    };

    const errorHandler = (doc: DocumentInterface) => {
        if (!company || unloadedFile) return;
        setUnloadedFile(doc);
        dispatch(getAllCompanyDocuments({ companyId: company.companyId }));
    };

    const downloadDocument = (doc: DocumentInterface) => {
        downloadAsFile(
            doc.filePath,
            getDocumentName(doc.postfix),
            () => errorHandler(doc),
            successHandler,
        );
    };

    const getDocumentName = (postfix: string) => {
        const docName = `${category.basicDocumentName}${postfix}`;
        return docName;
    };

    useEffect(() => {
        if (!unloadedFile) return;
        const doc = category.documents.find(
            (doc) => doc.companyDocumentId === unloadedFile.companyDocumentId,
        );
        if (!doc) return;
        downloadDocument(doc);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category]);

    return (
        <StyledCategoryItem>
            <div
                className={clsx('category-item__container', {
                    'category-item__gray-background': index % 2 !== 0,
                })}
            >
                <div className="category-item__info">
                    <span>{index + 1}.</span>
                    {category.name}
                </div>
                <div className="category-item__actions">
                    <div
                        className={clsx('category-item__files-container', {
                            'category-item__executor-file-container': !isManagerOrAdmin,
                        })}
                    >
                        {category.documents.map((doc) => (
                            <div
                                key={doc.companyDocumentId}
                                className={clsx('category-item__file', {
                                    'category-item__executor-file': !isManagerOrAdmin,
                                })}
                            >
                                <button type="button" onClick={() => downloadDocument(doc)}>
                                    {getDocumentName(doc.postfix)}
                                </button>
                                {isManagerOrAdmin && (
                                    <img
                                        src={DeleteIcon}
                                        onClick={() => setDocumentProcessedForDelete(doc)}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                    {isManagerOrAdmin && (
                        <img
                            className="category-item__add-icon"
                            src={AddIcon}
                            onClick={() => setAddDocumentModalOpen(true)}
                        />
                    )}
                </div>
            </div>
            {isAddDocumentModalOpen && company ? (
                <AddDocument
                    category={category}
                    companyId={company?.companyId}
                    close={() => setAddDocumentModalOpen(false)}
                    width={400}
                />
            ) : null}
            {documentProcessedForDelete && (
                <ConfirmModal
                    close={() => setDocumentProcessedForDelete(null)}
                    confirmAction={() =>
                        deleteDocumentHandler(documentProcessedForDelete.companyDocumentId)
                    }
                    width={280}
                    height={85}
                    headText="Удалить документ?"
                />
            )}
        </StyledCategoryItem>
    );
};

export default CategoryItem;
