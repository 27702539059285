import styled from 'styled-components/macro';

const StyledTooltipReminder = styled.div<{
    rightMargin: number;
    maxWidth: number;
    minWidth: number;
    leftMargin: number;
}>`
    position: relative;

    .tooltip__container {
        font-weight: 400;
        font-size: 12px;
        position: absolute;
        right: ${({ rightMargin }) => (rightMargin ? `${rightMargin}px` : 'auto')};
        left: ${({ leftMargin }) => (leftMargin ? `${leftMargin}px` : 'auto')};
        top: -10px;
        width: ${({ minWidth }) => `${minWidth}px`};
        max-width: ${({ maxWidth }) => `${maxWidth}px`};
        padding: 10px;
        border-radius: 5px;
        background: lightgray;
        overflow-wrap: break-word;
        overflow-y: auto;
        z-index: 1;
    }

    .tooltip__date {
        font-weight: 700;
        margin-bottom: 5px;
        min-width: 135px;
    }

    ul {
        padding-left: 5px;
    }

    .tooltip__html * {
        background: inherit !important;
    }
`;

export default StyledTooltipReminder;
