import React from 'react';

import {
    callsDirectionFilterFields,
    companyCashbackFilterFields,
    companyClosedRequestsFilterFields,
} from '../../../utils/data';
import StyledFilterWrapper from './RadioButtonFilter.style';

type filterParamsType = {
    [key: string]: string;
};

type FilterProps = {
    // eslint-disable-next-line no-unused-vars
    filterChangeHandler: (value: string) => void;
    filterParams: filterParamsType;
    description?: string;
    filterType: 'cashback' | 'task' | 'callDirection';
    additionalFilter?: React.ReactNode;
    disabledFilter?: boolean;
};

const filters = {
    cashback: {
        filterFields: companyCashbackFilterFields,
        type: 'status',
    },
    task: {
        filterFields: companyClosedRequestsFilterFields,
        type: 'closeReason',
    },
    callDirection: {
        filterFields: callsDirectionFilterFields,
        type: 'direction',
    },
};

const RadionButtonFilter = ({
    filterChangeHandler,
    filterParams,
    description,
    filterType,
    additionalFilter,
    disabledFilter,
}: FilterProps) => {
    const filterFields = filters[filterType].filterFields;
    const checkedValue = filterParams[filters[filterType].type];
    return (
        <StyledFilterWrapper>
            {description && (
                <p className="radio-button-filter__filter-description">{description}</p>
            )}
            {additionalFilter ?? null}
            {filterFields.map((field) => (
                <div key={field.label} className="radio-button-filter__radio-btn">
                    <label className="radio-button-filter__btn-label">{field.label}</label>
                    <input
                        disabled={disabledFilter}
                        type="radio"
                        value={field.value}
                        checked={checkedValue === field.value}
                        onChange={(e) => filterChangeHandler(e.target.value)}
                    />
                </div>
            ))}
        </StyledFilterWrapper>
    );
};

export default RadionButtonFilter;
