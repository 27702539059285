import styled from 'styled-components/macro';

const StyledContainer = styled.div<{ height: number }>`
    height: 90vh;
    max-height: ${({ height }) => `${height}px`};
    margin-left: 6px;
    font-size: 12px;
    font-weight: 300;
    line-height: 17px;

    form {
        margin-right: 6px;
        display: flex;
        flex-direction: column;
        height: calc(100% - 30px);
    }

    input {
        width: 99%;
        margin: 5px 0;
        padding-left: 10px;

        &:focus {
            border: 2px solid #005fcc;
        }

        &:disabled {
            background: #fff;
        }
    }

    .add-act__error-input {
        border-color: #f00;
    }

    .add-act__label {
        line-height: 17px;
    }

    .add-act__btn {
        margin: 12px 0 10px;
        text-align: initial;
        justify-self: end;
        width: fit-content;
    }

    .add-act__date-picker {
        display: grid;
        grid-template-columns: 28px 1fr;
        grid-column-gap: 5px;
        align-items: center;

        p {
            width: 100%;
            text-align: right;
        }
    }

    .add-act__date-period {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding-right: 4px;
    }

    .add-act__disabled-btn {
        margin: 0;
    }

    .add-act__radio-btn,
    .add-act__checkbox {
        height: 24px;
        padding: 4px 0;
        display: grid;
        grid-template-columns: 24px 1fr;
        grid-column-gap: 15px;
        word-break: break-all;
        overflow: hidden;

        input {
            width: 16px;
            height: 16px;
            margin: 0;
        }
    }

    .add-act__radio-btn {
        grid-template-columns: 24px 1fr 30px;

        img {
            cursor: pointer;
        }
    }

    .add-act__tasks-label {
        font-weight: 500;
        color: #f00;
    }

    .add-act__add-receiver-btn {
        font-weight: 400;
        text-decoration: underline;
        margin: 7px 0;
    }

    .add-act__receivers-label {
        margin-top: 5px;
    }
`;

export default StyledContainer;
