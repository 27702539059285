import {
    StatusResponseType,
    clientDatabaseApiPath,
} from '../clientDatabaseApi/clientDatabaseApi.types';
import {
    CreateActPropsType,
    actApiPath,
    GetAllActsResponseType,
    GetAllActsPropsType,
    GetActFileResponseType,
    GetActFilePropsType,
    CreateActReceiverPropsType,
    GetCompanyReceiversResponseType,
    UpdateActReceiverPropsType,
    uploadSigningActProps,
    DeleteActPropsType,
} from './actsApi.types';
import instance from '../axiosInstance';

export const actsApi = {
    createAct: async ({ companyId, ...props }: CreateActPropsType): Promise<StatusResponseType> => {
        try {
            const res = await instance.post(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${actApiPath.CRUD_ACT}`,
                {
                    ...props,
                },
            );
            return res.status;
        } catch (error) {
            console.log(error);
        }
    },

    getActsList: async ({ companyId }: GetAllActsPropsType): Promise<GetAllActsResponseType> => {
        try {
            const res = await instance.get(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${actApiPath.CRUD_ACT}`,
            );
            return res.data;
        } catch (error) {
            console.log(error);
        }
    },

    getActFile: async ({
        companyId,
        actId,
        ...props
    }: GetActFilePropsType): Promise<GetActFileResponseType> => {
        try {
            const res = await instance.get(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${actApiPath.CRUD_ACT}/${actId}${actApiPath.GET_ACT_FILE}`,
                { params: { ...props } },
            );
            return res.data;
        } catch (error) {
            console.log(error);
        }
    },

    createActReceiver: async ({
        companyId,
        ...props
    }: CreateActReceiverPropsType): Promise<StatusResponseType> => {
        try {
            const res = await instance.post(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${actApiPath.CRUD_RECEIVER}`,
                {
                    ...props,
                },
            );
            return res.status;
        } catch (error) {
            console.log(error);
        }
    },

    getCompanyReceivers: async ({
        companyId,
    }: GetAllActsPropsType): Promise<GetCompanyReceiversResponseType> => {
        try {
            const res = await instance.get(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${actApiPath.CRUD_RECEIVER}`,
            );
            return res.data;
        } catch (error) {
            console.log(error);
        }
    },

    updateActReceiver: async ({
        companyId,
        companyReceiverId,
        ...props
    }: UpdateActReceiverPropsType): Promise<StatusResponseType> => {
        try {
            const res = await instance.patch(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${actApiPath.CRUD_RECEIVER}/${companyReceiverId}`,
                {
                    ...props,
                },
            );
            return res.status;
        } catch (error) {
            console.log(error);
        }
    },

    uploadSigningAct: async ({
        companyId,
        actId,
        ...props
    }: uploadSigningActProps): Promise<StatusResponseType> => {
        try {
            const res = await instance.post(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${actApiPath.CRUD_ACT}/${actId}${actApiPath.ACT_DOC}`,
                {
                    ...props,
                },
            );
            return res.status;
        } catch (error) {
            console.log(error);
        }
    },

    deleteAct: async ({
        companyId,
        actId,
        ...props
    }: DeleteActPropsType): Promise<StatusResponseType> => {
        try {
            const res = await instance.delete(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${actApiPath.CRUD_ACT}/${actId}`,
                { params: { ...props } },
            );
            return res.status;
        } catch (error) {
            console.log(error);
        }
    },
};
