import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { getBanksListForTask, selectClientTask } from '../../../store/slices/clientTaskSlice';
import { PartnerInterface, TaskProductENUM } from '../../../utils/types';
import { banksForBankGuaranteeTaskColumns, banksForLoanTaskColumns } from '../../../utils/data';
import { useAppDispatch } from '../../../store';
import { getAllPartners, selectPartner } from '../../../store/slices/partnerSlice';
import StyledScrollbars from '../../Common/StyledScrollbar/StyledScrollbar';
import ProductForTaskTableItem from '../ProductForTaskTableItem/ProductForTaskTableItem';
import DefaultLoader from '../../Common/BtnLoader/DefaultLoader';
import StyledClientRequests from './ProductsForTaskTable.style';
import { bankProductCategoryEnum } from '../../../api/partnerApi/partnerApi.types';

type TableWithBanksForTaskProps = {
    companyId: number;
    taskId: number;
    allBanks: boolean;
};

const TableWithBanksForTask = ({ companyId, taskId, allBanks }: TableWithBanksForTaskProps) => {
    const dispatch = useAppDispatch();
    const { banksForTaskList, currentTask } = useSelector(selectClientTask);
    const { partnersList, loading } = useSelector(selectPartner);
    const isLoan = currentTask?.type === TaskProductENUM.LOAN;
    const currentBankProductOffers =
        currentTask?.offers?.map((offer) => offer.bankProduct.bankProductId) || [];

    const banksForTaskColumns = isLoan ? banksForLoanTaskColumns : banksForBankGuaranteeTaskColumns;

    const banks: PartnerInterface[] = allBanks ? partnersList : banksForTaskList;
    const initialPartnersWithIsOpenFlag = banks?.map((partner) => {
        return {
            ...partner,
            isOpen: false,
        };
    });
    const [partnersWithIsOpenFlag, setPartnersWithIsOpen] = useState(initialPartnersWithIsOpenFlag);

    const countOfOpenProducts = partnersWithIsOpenFlag.reduce(
        (sum, current) => sum + (current.isOpen ? current.products.length : 0),
        0,
    );
    const height = (partnersWithIsOpenFlag.length || 2) * 24 + countOfOpenProducts * 33;

    const bankClickHandler = (index: number) => {
        const newValue = [...partnersWithIsOpenFlag];
        newValue[index] = {
            ...newValue[index],
            isOpen: !newValue[index].isOpen,
        };
        setPartnersWithIsOpen([...newValue]);
    };
    const promiseRef = useRef<{ abort: () => void } | undefined>();

    useEffect(() => {
        if (promiseRef.current) {
            promiseRef.current.abort();
        }
        if (allBanks) {
            const categoriesOption = isLoan
                ? [bankProductCategoryEnum.LOAN]
                : [
                      bankProductCategoryEnum.BANK_GUARANTEE,
                      bankProductCategoryEnum.COMMERCIAL_GUARANTEE,
                  ];
            const getAllPartnersOptions = {
                onlyEnabled: true,
                categories: categoriesOption,
            };
            promiseRef.current = dispatch(getAllPartners(getAllPartnersOptions));
        } else {
            dispatch(getBanksListForTask({ companyId, taskId }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allBanks]);

    useEffect(() => {
        if (!banks) return;

        setPartnersWithIsOpen(
            banks?.map((partner) => {
                return {
                    ...partner,
                    isOpen: false,
                };
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [banks]);

    return (
        <StyledClientRequests height={height} isLoan={isLoan}>
            <div className="table-with-banks-for-task__header">
                {banksForTaskColumns?.map((column, index) => (
                    <div
                        key={column}
                        className={clsx({ 'table-with-banks-for-task__right-position': index })}
                    >
                        <p>{column}</p>
                    </div>
                ))}
            </div>
            {allBanks && loading.getPartnerList ? (
                <div className="table-with-banks-for-task__loader">
                    <DefaultLoader />
                </div>
            ) : (
                <StyledScrollbars
                    style={{ height: 'calc(100%)' }}
                    renderTrackHorizontal={(props) => (
                        <div {...props} style={{ display: 'none' }} className="track-horizontal" />
                    )}
                    thumbSize={170}
                    thumbMinSize={30}
                >
                    {partnersWithIsOpenFlag.length ? (
                        partnersWithIsOpenFlag?.map((bank, index) => (
                            <div key={bank.bankId}>
                                <div
                                    className={clsx('table-with-banks-for-task__row', {
                                        'table-with-banks-for-task__odd-field': index % 2 === 0,
                                    })}
                                >
                                    <p onClick={() => bankClickHandler(index)}>{bank.name || ''}</p>
                                </div>
                                <ProductForTaskTableItem
                                    companyId={companyId}
                                    taskId={taskId}
                                    bank={bank}
                                    index={index}
                                    isOpen={bank.isOpen}
                                    currentBankProductOffers={currentBankProductOffers}
                                />
                            </div>
                        ))
                    ) : (
                        <p className="table-with-banks-for-task__placeholder">
                            Нет предложений, удовлетворяющих указанным параметрам
                        </p>
                    )}
                </StyledScrollbars>
            )}
        </StyledClientRequests>
    );
};

export default TableWithBanksForTask;
