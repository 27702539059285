import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { statCommentsColumns } from '../../../../utils/data';
import { selectStatistics } from '../../../../store/slices/statisticsSlice';
import { CommentTypes } from '../CommentsStatistic';
import { CompanyCommentInterface, TaskCommentInterface } from '../../../../utils/types';
import { userSelector } from '../../../../store/slices/userSlice';
import StyledCommentsTable from './StatisticCommentsTable.style';
import StatisticCommentsTableRow from '../StatisticCommentsTableRow/StatisticCommentsTableRow';
import EmptyList from '../../../EmptyList/EmptyList';
import { RotatingLines } from 'react-loader-spinner';
import { ManagerAndDateFilterType } from '../../ManagerAndDateFilter/Filter';

type StatsCommentsProps = {
    commentType?: CommentTypes;
    filterParams: ManagerAndDateFilterType;
};

const CommentsTable = ({ commentType, filterParams }: StatsCommentsProps) => {
    const isTaskTable = commentType === 'taskComments';
    const { companyComments, taskComments, loading } = useSelector(selectStatistics);
    const { user } = useSelector(userSelector);
    const comments = isTaskTable ? taskComments : companyComments;
    const commentTableColumns = statCommentsColumns.filter(
        (column) => isTaskTable || column.name !== 'task',
    );

    let currentHour: number | null = null;

    const getHourDivider = (date: string) => {
        const newHour = new Date(date).getHours();
        if (newHour === currentHour) return null;

        currentHour = newHour;
        return (
            <p className="comments-table__time-divider">
                {currentHour > 9 ? currentHour : `0${currentHour}`}:00:00
            </p>
        );
    };

    // const getDateDivider = (date: string) => {
    //     const newDate = getDate(date, 'date');

    //     if (newDate === currentDate) return null;

    //     currentDate = newDate;
    //     currentHour = null;
    //     return <p className="comments-table__date-divider">{currentDate}</p>;
    // };

    const getCommentId = (comment: CompanyCommentInterface | TaskCommentInterface) => {
        if ('companyCommentId' in comment) {
            return comment.companyCommentId;
        } else if ('taskCommentId' in comment) {
            return comment.taskCommentId;
        }
    };

    const commentsLoading = useMemo(
        () => loading.companyComments || loading.taskComments,
        [loading.companyComments, loading.taskComments],
    );

    const emptyListText = useMemo(() => {
        const adminNotAllFilters =
            user?.isAdmin && (filterParams.manager === '' || filterParams.date === '');
        const managerNotAllFilters = !user?.isAdmin && filterParams.date === '';

        return {
            header:
                adminNotAllFilters || managerNotAllFilters ? 'Выберите параметры фильтрации' : '',
            description: adminNotAllFilters || managerNotAllFilters ? ' ' : '',
        };
    }, [filterParams.date, filterParams.manager, user?.isAdmin]);

    return (
        <StyledCommentsTable>
            <div
                className={clsx('comments-table__header', {
                    'comments-table__task-table-row': isTaskTable,
                })}
            >
                {commentTableColumns.map((column) => (
                    <div key={column.label} className="comments-table__column-name">
                        <p>{column.label}</p>
                    </div>
                ))}
            </div>
            <div className="comments-table__body">
                {commentsLoading ? (
                    <div className="comments-table__loader">
                        <RotatingLines strokeColor="grey" />
                    </div>
                ) : comments.length ? (
                    comments.map((comment) => (
                        <StatisticCommentsTableRow
                            key={getCommentId(comment)}
                            user={user}
                            comment={comment}
                            isTaskTable={isTaskTable}
                            getHourDivider={getHourDivider}
                        />
                    ))
                ) : (
                    <EmptyList
                        header={emptyListText.header}
                        description={emptyListText.description}
                    />
                )}
            </div>
        </StyledCommentsTable>
    );
};

export default CommentsTable;
