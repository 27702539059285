import React, { useState } from 'react';
import clsx from 'clsx';

import { RequestInterface } from '../../../utils/types';
import { getDate } from '../../../utils/functions';
import ArrowIcon from '../../../assets/images/clients/arrow-on-black-square-icon.png';
import StyledRequestItem from './RequestItem.style';

type RequestItemProps = {
    request: RequestInterface;
    companyId: number;
    index: number;
};

const RequestItem = ({ request, index }: RequestItemProps) => {
    const [isRequestOpen, setRequestOpen] = useState(false);

    const toggleSection = () => {
        setRequestOpen((prev) => !prev);
    };

    return (
        <StyledRequestItem>
            <div className="request-item__header">
                <p>
                    Запрос № {index} - {getDate(request.createdAt, 'date with time')} /{' '}
                    {getDate(request.updatedAt, 'date with time')}
                </p>
                <div
                    onClick={toggleSection}
                    className={clsx({
                        'request-item__opened-section': isRequestOpen,
                    })}
                >
                    <img src={ArrowIcon} />
                </div>
            </div>

            {isRequestOpen ? (
                <div
                    className="request-item__text"
                    dangerouslySetInnerHTML={{ __html: request.text }}
                ></div>
            ) : null}
        </StyledRequestItem>
    );
};

export default RequestItem;
