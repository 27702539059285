import { createGlobalStyle } from 'styled-components/macro';

const GlobalStyle = createGlobalStyle`
    * {
        font-family: 'Montserrat', sans-serif;
    }

    html,
    body {
        padding: 0;
        margin: 0;
        height: 100%;
        overflow: auto;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    * {
        box-sizing: border-box;
    }

    ul {
        list-style-type: none;
    }

    label,
    button {
        cursor: pointer;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul,
    ol {
        margin: 0;
    }

    p {
        width: fit-content;
        word-break: break-word;
    }

    img {
        width: 20px;
        height: 20px;
    }
`;

export default GlobalStyle;
