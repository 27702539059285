import {
    statisticsApiPath,
    GetAllCompanyCommentsPropsType,
    GetAllCompanyCommentsResponseType,
    GetAllRequestCommentsResponseType,
    GetAllCashbackResponseType,
    GetAllCashbackPropsType,
} from './statisticsApi.types';

import instance from '../axiosInstance';

export const statisticsApi = {
    getCompanyComments: async ({
        ...props
    }: GetAllCompanyCommentsPropsType): Promise<GetAllCompanyCommentsResponseType> => {
        try {
            const { abortSignal, ...params } = props;

            const response = await instance.get(statisticsApiPath.COMPANY_COMMENTS, {
                params,
                signal: abortSignal,
            });
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },

    getTaskComments: async ({
        ...props
    }: GetAllCompanyCommentsPropsType): Promise<GetAllRequestCommentsResponseType> => {
        try {
            const { abortSignal, ...params } = props;

            const response = await instance.get(statisticsApiPath.TASK_COMMENTS, {
                params,
                signal: abortSignal,
            });
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },

    getAllCashback: async ({
        ...props
    }: GetAllCashbackPropsType): Promise<GetAllCashbackResponseType> => {
        try {
            const response = await instance.get(statisticsApiPath.CASHBACK_STATISTIC, {
                params: {
                    ...props,
                },
            });
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },
};
