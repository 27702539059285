import React from 'react';

import {
    bankGuaranteeTaskFilterNames,
    bankGuaranteeTypesInTaskFilter,
    federalLawsInTaskFilter,
    federalLawsTaskFilterNames,
} from '../../../utils/data';
import CustomSelect from '../../../components/Common/CustomSelect/CustomSelect';
import BulletListIcon from '../../../assets/images/bullet-list-icon.png';
import StyledFilterWrapper from './ClosedRequestsFilter.style';

export type TypesOfFilter = 'federalLaw' | 'bankGuaranteeType' | 'lkOperator';

type FilterParamsType = {
    federalLaw: federalLawsTaskFilterNames;
    bankGuaranteeType: bankGuaranteeTaskFilterNames;
    lkOperator: string;
};

const selectFilters: {
    name: TypesOfFilter;
    label: string;
    width: number;
}[] = [
    { name: 'lkOperator', label: 'ЛК', width: 262 },
    { name: 'federalLaw', label: 'ФЗ', width: 262 },
    { name: 'bankGuaranteeType', label: 'Тип БГ', width: 262 },
];

type FilterElementType = {
    name: string;
    label: string;
};

type FilterProps = {
    // eslint-disable-next-line no-unused-vars
    filterChangeHandler: (value: string, field: TypesOfFilter) => void;
    filterParams: FilterParamsType;
    credetialsFilterParams: FilterElementType[];
    disabledAllFilters?: boolean;
    disabledFilters: {
        lkOperator: boolean;
        federalLaw: boolean;
        bankGuaranteeType: boolean;
    };
};

const Filter = ({
    filterChangeHandler,
    filterParams,
    credetialsFilterParams,
    disabledFilters,
    disabledAllFilters,
}: FilterProps) => {
    const selectFilterValues = {
        federalLaw: federalLawsInTaskFilter,
        bankGuaranteeType: bankGuaranteeTypesInTaskFilter,
        lkOperator: credetialsFilterParams,
    };

    return (
        <StyledFilterWrapper>
            {selectFilters.map((filter) => (
                <div key={filter.name} className="filter__executor-container">
                    <p>{filter.label}</p>{' '}
                    <CustomSelect
                        width={filter.width}
                        height={20}
                        backgroundImage={BulletListIcon}
                        backgroundColor="#f9f9f9"
                    >
                        <select
                            disabled={disabledFilters[filter.name] || disabledAllFilters}
                            value={filterParams[filter.name]}
                            onChange={(e) => filterChangeHandler(e.target.value, filter.name)}
                        >
                            {selectFilterValues[filter.name].map((item) => (
                                <option key={item.name} value={item.name}>
                                    {item.label}
                                </option>
                            ))}
                        </select>
                    </CustomSelect>
                </div>
            ))}
        </StyledFilterWrapper>
    );
};

export default Filter;
