import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import { ClientTaskInterface, TaskDocInterface } from '../../../../../utils/types';
import { selectClientCard } from '../../../../../store/slices/clientCardSlice';
import { useAppDispatch } from '../../../../../store';
import { getAllCompanyDocuments } from '../../../../../store/slices/clientDocumentSlice';
import { downloadAsFile, getDate } from '../../../../../utils/functions';
import ArrowIcon from '../../../../../assets/images/clients/arrow-on-black-square-icon.png';
import StyledCategoryItem from './TaskDocsItem.style';

type CategoryItemProps = {
    task: ClientTaskInterface;
};

const CategoryItem = ({ task }: CategoryItemProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { company } = useSelector(selectClientCard);
    const [isSectionOpen, setSectionOpen] = useState(false);
    const [unloadedFile, setUnloadedFile] = useState<null | TaskDocInterface>(null);

    const successHandler = () => {
        setUnloadedFile(null);
    };

    const errorHandler = (doc: TaskDocInterface) => {
        if (!company || unloadedFile) return;
        setUnloadedFile(doc);
        dispatch(getAllCompanyDocuments({ companyId: company.companyId }));
    };

    const downloadDocument = (doc: TaskDocInterface) => {
        downloadAsFile(doc.filePath, doc.name, () => errorHandler(doc), successHandler);
    };

    const toTask = () => {
        if (!company) return;

        navigate(`/clients/${company.companyId}/task/${task.taskId}`);
    };

    const toggleSection = () => {
        setSectionOpen((prev) => !prev);
    };

    useEffect(() => {
        if (!unloadedFile) return;
        const doc = task.documents.find(
            (doc) => doc.taskDocumentId === unloadedFile.taskDocumentId,
        );
        if (!doc) return;
        downloadDocument(doc);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [task]);

    return (
        <StyledCategoryItem>
            <div className="task-docs-item__header">
                <p>
                    <span className="task-docs-item__task-number" onClick={toTask}>
                        {`Заявка № ${task.taskId}`}
                    </span>{' '}
                    от {getDate(task.createdAt, 'date')}
                </p>
                <div
                    onClick={toggleSection}
                    className={clsx('task-docs-item__show-section-btn', {
                        'task-docs-item__opened-section': isSectionOpen,
                    })}
                >
                    <p>
                        Кол-во файлов: <span>{task.documents.length}</span>
                    </p>
                    <img src={ArrowIcon} />
                </div>
            </div>
            {isSectionOpen &&
                task.documents.map((doc) => (
                    <div key={doc.taskDocumentId} className="task-docs-item__doc-row">
                        <p>{getDate(doc.createdAt, 'date with time', true)}</p>
                        <p>{doc.name}</p>
                        <button type="button" onClick={() => downloadDocument(doc)}>
                            Скачать
                        </button>
                    </div>
                ))}
        </StyledCategoryItem>
    );
};

export default CategoryItem;
