import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import clsx from 'clsx';

import { CompanyNoteInterface } from '../../../../../utils/types';
import { deleteCompanyNote } from '../../../../../store/slices/clientCardSlice';
import { getDate } from '../../../../../utils/functions';
import { userSelector } from '../../../../../store/slices/userSlice';
import { useAppDispatch } from '../../../../../store';
import AddNote from '../../../../../components/AddNote/AddNote';
import EditIcon from '../../../../../assets/images/clients/note-edit-icon.png';
import DeleteIcon from '../../../../../assets/images/clients/cross-in-red-circle.png';
import ConfirmModal from '../../../../../components/Common/ConfirmModal/ConfirmModal';
import StyledNoteItem from './NoteItem.style';

type NoteItemProps = {
    note: CompanyNoteInterface;
    companyId: number;
};

const NoteItem = ({ note, companyId }: NoteItemProps) => {
    const dispatch = useAppDispatch();
    const { user } = useSelector(userSelector);
    const [isEditNoteModalOpen, setEditNoteModalOpen] = useState(false);
    const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);

    const confirmAction = () => {
        if (!(note.companyNoteId && companyId)) return;
        if (!user?.isAdmin && note.user.userId !== user?.userId) {
            return toast.error('Недостаточно прав для удаления');
        }
        dispatch(
            deleteCompanyNote({
                companyId,
                noteId: note.companyNoteId,
                hardDelete: true,
            }),
        );
        setConfirmModalOpen(false);
    };

    const handleNoteEditing = () => {
        if (!user?.isAdmin && note.user.userId !== user?.userId) {
            return toast.error('Недостаточно прав для удаления');
        }
        setEditNoteModalOpen(true);
    };

    return (
        <StyledNoteItem>
            <div
                className={clsx('note-item__container', {
                    'note-item__red-highlight': note.color === 'red',
                    'note-item__yellow-highlight': note.color === 'yellow',
                })}
            >
                <div className="note-item__info">
                    <div className="note-item__date-with-author">
                        <span>{`${getDate(note.createdAt, 'date')} ${
                            note.user.fullName || note.user.login || note.user.email
                        }:`}</span>
                    </div>
                    <div
                        className={clsx('note-item__actions', {
                            'note-item__disabled-actions': user?.userId !== note.user.userId,
                        })}
                    >
                        <img src={EditIcon} onClick={handleNoteEditing} />
                        <img src={DeleteIcon} onClick={() => setConfirmModalOpen(true)} />
                    </div>
                </div>
                <div className="note-item__text">{note.text}</div>
            </div>
            {isEditNoteModalOpen && note ? (
                <AddNote
                    width={428}
                    companyId={companyId}
                    close={() => setEditNoteModalOpen(false)}
                    note={note}
                />
            ) : null}
            {isConfirmModalOpen ? (
                <ConfirmModal
                    close={() => setConfirmModalOpen(false)}
                    confirmAction={confirmAction}
                    width={280}
                    height={85}
                    headText="Удалить заметку?"
                />
            ) : null}
        </StyledNoteItem>
    );
};

export default NoteItem;
