import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';

import { bankProductComponentType, bankProducts, federalLawsInTable } from '../../../../utils/data';
import { lawFieldsType, PartnerDocument, PartnerInterface } from '../../../../utils/types';
import { downloadAsFile, getBankProductList } from '../../../../utils/functions';
import { useAppDispatch } from '../../../../store';
import { setNeedUpdatePartnerList } from '../../../../store/slices/partnerSlice';
import EnabledInfoIcon from '../../../../assets/images/partners/additional-info-icon.png';
import DisabledInfoIcon from '../../../../assets/images/partners/disabled-info-icon.png';
import EnabledStarIcon from '../../../../assets/images/partners/star-icon.png';
import DisabledStarIcon from '../../../../assets/images/partners/disabled-star-icon.png';
import EnabledPriorityIcon from '../../../../assets/images/partners/green-circle-with-arrow-up.png';
import DisabledPriorityIcon from '../../../../assets/images/partners/gray-circle-with-arrow-up.png';
import EnabledGearsIcon from '../../../../assets/images/partners/green-gears-icon.png';
import DisabledGearsIcon from '../../../../assets/images/partners/gray-gears-icon.png';
import TooltipAlt from '../../../../components/Common/TooltipAlt/TooltipAlt';
import LabelByProductType from '../LabelByProductType/LabelByProductType';

type PartnersTableProps = {
    numberInList: number;
    partner: PartnerInterface;
    // eslint-disable-next-line no-unused-vars
    setEditablePartnerId: (value: number) => void;
};

type ProductsDecomposeType = {
    [key: string]: bankProductComponentType[];
};

const TableAfterSearch = ({ setEditablePartnerId, numberInList, partner }: PartnersTableProps) => {
    const dispatch = useAppDispatch();
    const [unloadedFile, setUnloadedFile] = useState<null | PartnerDocument>(null);
    const [currentLogo, setCurrentLogo] = useState('');

    const getInstruction = () => {
        return partner.documents.find((doc) => doc.section === 'instructions') || null;
    };

    const successHandler = () => {
        setUnloadedFile(null);
    };

    const errorHandler = () => {
        const file = getInstruction();

        if (!file || unloadedFile) return;

        setUnloadedFile(file);
        dispatch(setNeedUpdatePartnerList(true));
    };

    const downloadDocument = () => {
        const file = getInstruction();
        if (!file) return;

        downloadAsFile(file.filePath, file.name, errorHandler, successHandler);
    };

    useEffect(() => {
        if (!unloadedFile) return;
        downloadDocument();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partner]);

    useEffect(() => {
        const fetchImage = async (logo: string) => {
            try {
                const response = await fetch(logo);
                const myBlob = await response.blob();
                const objectURL = URL.createObjectURL(myBlob);
                setCurrentLogo(objectURL);
            } catch (err) {
                setCurrentLogo('');
            }
        };
        if (partner.logo) {
            fetchImage(partner.logo);
        }
    }, [partner.logo]);

    const GearsIcon = getInstruction() ? EnabledGearsIcon : DisabledGearsIcon;
    const InfoIcon = partner.additInfo ? EnabledInfoIcon : DisabledInfoIcon;
    const productsList = getBankProductList(partner);
    const StarIcon = productsList ? EnabledStarIcon : DisabledStarIcon;
    const PriorityIcon = partner.isPriority ? EnabledPriorityIcon : DisabledPriorityIcon;
    const tooltipStarId = `my-star-tooltip-${numberInList}`;
    const tooltipInfoId = `my-info-tooltip-${numberInList}`;

    const checkHasLaw = (partner: PartnerInterface, type: string, law: lawFieldsType) => {
        const product = partner.products.find((product) => product.type === type);

        if (!product?.isActive) return false;

        return product[law];
    };

    const decomposeProducts = useMemo(() => {
        return bankProducts.reduce(
            (accum, current) => {
                accum[current.type].push(current);
                return accum;
            },
            {
                bankGuarantee: [],
                commercialGuarantee: [],
                loan: [],
            } as ProductsDecomposeType,
        );
    }, []);
    return (
        <>
            <p className="partners-table__field-with-margin">{numberInList}</p>
            <div className="partners-table__logo">
                {partner.logo ? <img src={currentLogo} alt="logo" /> : ''}
            </div>
            <p
                className={clsx('partners-table__field-with-margin', {
                    'partners-table__gray-field': !partner.isDisplayedInList,
                })}
            >
                {partner.name}
            </p>
            <p
                className={clsx('partners-table__field-with-margin', {
                    'partners-table__gray-field': !partner.isDisplayedInList,
                })}
            >
                {partner.credentials[0]?.lkOperator || '---'}
            </p>
            {decomposeProducts.bankGuarantee.map((productType, index) => (
                <div key={index} className="partners-table__bank-product">
                    {federalLawsInTable.map((law, index) => (
                        <p
                            key={index}
                            className={clsx({
                                'partners-table__active-law': checkHasLaw(
                                    partner,
                                    productType.name,
                                    law.name,
                                ),
                            })}
                        >
                            {law.label}
                        </p>
                    ))}
                </div>
            ))}
            <div className="partners-table__commercial-product">
                {decomposeProducts.commercialGuarantee.map((productType, index) => (
                    <LabelByProductType
                        key={index}
                        partner={partner}
                        productType={productType.name}
                    />
                ))}{' '}
            </div>
            <div className="partners-table__loan-product">
                {decomposeProducts.loan.map((productType, index) => (
                    <LabelByProductType
                        key={index}
                        partner={partner}
                        productType={productType.name}
                    />
                ))}{' '}
            </div>
            <div>
                <img src={InfoIcon} id={tooltipInfoId} />
                {partner.additInfo && (
                    <TooltipAlt anchor={tooltipInfoId} content={partner.additInfo || ''} />
                )}
            </div>
            <img src={PriorityIcon} />
            <div>
                <img src={StarIcon} id={tooltipStarId} />
                {Boolean(getBankProductList(partner)?.length) && (
                    <TooltipAlt
                        anchor={tooltipStarId}
                        content={getBankProductList(partner) || ''}
                    />
                )}
            </div>
            <img
                className={clsx({
                    'partners-table__instruction-icon': Boolean(getInstruction()),
                })}
                src={GearsIcon}
                onClick={downloadDocument}
            />
            <button
                className="partners-table__partner-card-btn"
                type="button"
                onClick={() => setEditablePartnerId(partner.bankId)}
            >
                Карточка банка
            </button>
        </>
    );
};

export default TableAfterSearch;
