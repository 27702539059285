import styled from 'styled-components/macro';

const StyledContainer = styled.div<{ height: number }>`
    height: 90vh;
    max-height: ${({ height }) => `${height}px`};
    margin-left: 6px;
    font-size: 12px;
    font-weight: 300;
    line-height: 17px;

    form {
        margin-right: 6px;
        display: flex;
        flex-direction: column;
        height: calc(100% - 38px);
    }

    .add-bank-guarantee__description {
        font-style: italic;
        font-weight: 300;
        font-size: 10px;
        line-height: 14px;
        color: #000;
        margin: 8px 0 15px;
    }

    .add-bank-guarantee__sign-of-obligatory-field {
        color: #ff0000;
    }

    .add-bank-guarantee__comment {
        height: 121px;
        padding: 5px 10px;
    }

    .add-bank-guarantee__short-comment {
        height: 61px;
    }

    input,
    select,
    textarea {
        width: 99%;
        margin: 5px 0;
        padding-left: 10px;

        &:focus {
            border: 2px solid #005fcc;
        }

        &:disabled {
            background: #fff;
        }
    }

    .add-bank-guarantee__error-input {
        border-color: #f00;
    }

    .add-bank-guarantee__label {
        line-height: 17px;
    }

    .add-bank-guarantee__btn {
        margin: 12px 0 10px;
        text-align: initial;
        justify-self: end;
        width: fit-content;
    }

    .add-bank-guarantee__login-password {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;
    }

    .add-bank-guarantee__date-picker {
        display: grid;
        grid-template-columns: 28px 1fr;
        grid-column-gap: 5px;
        align-items: center;

        p {
            width: 100%;
            text-align: right;
        }
    }

    .add-bank-guarantee__date-period {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding-right: 4px;
    }

    .add-bank-guarantee__checkbox {
        display: flex;
        justify-content: end;
        align-items: center;

        input {
            width: 24px;
            margin: 5px 12px 5px 10px;
            cursor: pointer;
        }
    }

    .add-bank-guarantee__conclusion-date {
        width: 100%;

        input {
            width: 120px;
        }
    }

    .add-bank-guarantee__disabled-btn {
        margin: 0;
    }

    .add-bank-guarantee__red-label {
        color: #ff0000;
    }
`;

const StyledFormWrapper = styled.div<{ width: number }>`
    width: ${({ width }) => `${width}px`};

    input,
    textarea {
        margin-top: 5px;
        height: 24px;
        border: 1px solid #000;
        border-radius: 5px;
        padding-left: 10px;
    }

    h3 {
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 13px;
    }

    button {
        border: none;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #0038ff;
        padding: 0;
    }

    select[disabled] {
        opacity: 0.4;
        cursor: auto;
    }
`;

export { StyledContainer, StyledFormWrapper };
