import React, { useMemo } from 'react';
import clsx from 'clsx';
import { ClientTaskInterface } from '../../../../../utils/types';
import {
    formatNum,
    getDate,
    getLabelByLaw,
    getLabelForTaskStatus,
    getLoanProductFullTitle,
    getProductTitle,
    getTaskNumber,
    getTooltipLength,
    getUserName,
    stringToNumber,
} from '../../../../../utils/functions';

import BellIcon from '../../../../../assets/images/bell-icon.png';
import WarningIcon from '../../../../../assets/images/clients/warning-icon.png';
import SelectedWarningIcon from '../../../../../assets/images/clients/selected-warning-icon.png';
import SelectedBellIcon from '../../../../../assets/images/selected-bell-icon.png';

import TooltipReminder from '../../../../../components/Common/TooltipReminder/TooltipReminder';

type ClientRequestsTableRowProps = {
    task: ClientTaskInterface;
    index: number;
    companyId: number;
    isLoan?: boolean;
};

const ClientRequestsTableRow = ({
    task,
    index,
    companyId,
    isLoan,
}: ClientRequestsTableRowProps) => {
    const taskDate = useMemo(
        () => getDate(task.createdAt, 'date with time', true),
        [task.createdAt],
    );
    const taskNumber = useMemo(() => getTaskNumber(task), [task]);
    const productTitle = useMemo(
        () =>
            !isLoan
                ? getProductTitle(task.bankGuaranteeType, true)
                : getLoanProductFullTitle(task.loanType),
        [isLoan, task.bankGuaranteeType, task.loanType],
    );
    const taskTerm = useMemo(
        () =>
            !isLoan
                ? task.bankGuaranteeTermDays
                    ? formatNum(String(task.bankGuaranteeTermDays))
                    : '---'
                : task.loanTermMonths
                ? formatNum(String(task.loanTermMonths))
                : '---',
        [isLoan, task.bankGuaranteeTermDays, task.loanTermMonths],
    );
    const taskLaw = useMemo(() => getLabelByLaw(task.federalLaw), [task.federalLaw]);
    const taskAmount = useMemo(
        () =>
            formatNum(
                stringToNumber(
                    (!isLoan ? task.bankGuaranteeAmount : task.loanAmount) || '',
                ).toFixed(2),
            ),
        [isLoan, task.bankGuaranteeAmount, task.loanAmount],
    );
    const taskStatus = useMemo(
        () => getLabelForTaskStatus(task.status, task.type),
        [task.status, task.type],
    );
    const taskExecutor = useMemo(() => getUserName(task.executor), [task.executor]);
    return (
        <div
            className={clsx('client-requests-table__row', {
                'clients-requests-table__odd-field': index % 2 === 0,
            })}
        >
            <p>{taskDate}</p>
            <a
                className="client-request-table__task-number"
                target="_blank"
                rel="noopener noreferrer"
                href={`/clients/${companyId}/task/${task.taskId}`}
            >
                {taskNumber}
            </a>
            <p>{productTitle}</p>
            <div className="clients-requests-table__right-position">
                <p>{taskTerm}</p>
            </div>
            <div className="clients-requests-table__right-position">
                <p>{taskLaw}</p>
            </div>
            <div className="clients-requests-table__right-position">
                <p>{taskAmount}</p>
            </div>
            <div className="clients-requests-table__right-position">
                <p>{taskStatus}</p>
            </div>
            <div className="clients-requests-table__right-position">
                <p>{taskExecutor}</p>
            </div>
            <div className="clients-requests-table__icons">
                <TooltipReminder
                    text={task.importantInfo || ''}
                    hasReminder={Boolean(task.importantInfo)}
                    rightMargin={20}
                    maxWidth={800}
                    minWidth={getTooltipLength({
                        textLength: task.importantInfo?.length,
                        minLetters: 10,
                        letterWidth: 9,
                    })}
                >
                    <img src={task.importantInfo ? SelectedWarningIcon : WarningIcon} />
                </TooltipReminder>
                <TooltipReminder
                    text={task.reminders?.length ? task.reminders[0]?.text : ''}
                    hasReminder={Boolean(task.reminders?.length)}
                    rightMargin={20}
                    maxWidth={800}
                    minWidth={getTooltipLength({
                        textLength: task.reminders?.[0]?.text?.length,
                        minLetters: 10,
                        letterWidth: 8,
                    })}
                >
                    <img src={task.reminders?.length ? SelectedBellIcon : BellIcon} />
                </TooltipReminder>
            </div>
        </div>
    );
};

export default ClientRequestsTableRow;
