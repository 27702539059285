import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import queryString from 'query-string';

import { selectClientCard } from '../../../store/slices/clientCardSlice';
import { useAppDispatch } from '../../../store';
import { getAllTasks, resetTasksList } from '../../../store/slices/clientTaskSlice';
import { TaskСloseReasonFilter } from '../../../utils/data';
import RequestsTable from './ClientClosedRequestsTable/ClientClosedRequestsTable';
import RadionButtonFilter from '../../../components/Common/RadionButtonFilter/RadionButtonFilter';
import StyledClientRequestsWrapper from './ClientClosedRequests.style';
import ClosedRequestsProductFilter from './ClosedRequestsProductFilter/ClosedRequestsProductFilter';

export type SearchParamsFieldNames = 'closeReason';

export type FilterParams = {
    closeReason: string;
    tab: string;
    type: string;
};

export const defaultFilterParams: FilterParams = {
    tab: 'closedDeals',
    closeReason: '',
    type: '',
};

const ClientRequests = () => {
    const firstPageUpdate = useRef(true);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();

    const { company } = useSelector(selectClientCard);
    const [filter, setFilter] = useState<FilterParams>(defaultFilterParams);

    const [isFilterRestoredAfterReload, setFilterRestoredAfterReload] = useState(false);

    const handleFilter = () => {
        if (!company?.companyId) return;
        const closeReason = filter.closeReason
            ? (filter.closeReason as TaskСloseReasonFilter)
            : undefined;
        const type = filter.type ? filter.type : undefined;
        const searchParams = {
            closeReason,
            type,
        };
        dispatch(
            getAllTasks({
                ...searchParams,
                companyId: company.companyId,
                activityType: 'inactive',
            }),
        );
    };

    const filterChangeHandler = (value: string, field?: string) => {
        if (field) {
            setFilter({
                ...filter,
                [field]: value,
            });
            return;
        }
        setFilter({
            ...filter,
            closeReason: value,
        });
    };

    const serializeSearchParams = (obj: FilterParams) => {
        const str: string[] = [];
        Object.getOwnPropertyNames(obj).forEach((key) => {
            if (key in obj && obj[key as SearchParamsFieldNames]) {
                const encodedKey = encodeURIComponent(key);
                const encodedValue = encodeURIComponent(obj[key as SearchParamsFieldNames]);
                str.push(`${encodedKey}=${encodedValue}`);
            }
        });
        return str.join('&');
    };

    const saveFilter = () => {
        const query = serializeSearchParams({
            ...filter,
        });
        navigate({
            ...location,
            search: query,
        });
    };

    useEffect(() => {
        if (firstPageUpdate.current) {
            firstPageUpdate.current = false;
            return;
        }

        saveFilter();
        handleFilter();

        if (!isFilterRestoredAfterReload && queryString.parse(location.search)) {
            setFilterRestoredAfterReload(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, company]);

    useEffect(() => {
        dispatch(resetTasksList());
        const queryStringParams = queryString.parse(location.search);

        if (!queryStringParams) return;

        const closeReason = queryStringParams?.closeReason || defaultFilterParams.closeReason;
        const type = queryStringParams?.type || defaultFilterParams.type;
        const tab = defaultFilterParams.tab;
        const newFilter = {
            tab,
            closeReason: closeReason as string,
            type: type as string,
        };
        setFilter(newFilter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StyledClientRequestsWrapper>
            <RadionButtonFilter
                filterChangeHandler={filterChangeHandler}
                description="Отображать:"
                filterParams={filter}
                filterType="task"
                additionalFilter={
                    <ClosedRequestsProductFilter
                        filterChangeHandler={filterChangeHandler}
                        filterParams={filter}
                    />
                }
            />
            <RequestsTable />
        </StyledClientRequestsWrapper>
    );
};

export default ClientRequests;
