import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { selectClientTask, updateTask } from '../../../store/slices/clientTaskSlice';
import { getGivenOutOffer, getTaskInfoField, getTaskStatuses } from '../../../utils/functions';
import { selectClientCard } from '../../../store/slices/clientCardSlice';
import { TaskImportanceTypes, TaskProductENUM, TaskStatusTypes } from '../../../utils/types';
import {
    TaskImportances,
    BankGuaranteeTaskInfoFields,
    TaskInfoSelects,
    TaskInfoSelectsNames,
    LoanTaskInfoFields,
} from '../../../utils/data';
import { useAppDispatch } from '../../../store';
import AddBankTask from '../../../components/AddBankTask/AddBankTask';
import BulletListIcon from '../../../assets/images/bullet-list-icon.png';
import CloseIcon from '../../../assets/images/partners/close-window-icon.png';
import CustomSelect from '../../../components/Common/CustomSelect/CustomSelect';
import CloseBankTask from '../../../components/CloseBankTask/CloseBankTask';
import StyledTaskInfo from './TaskInfo.style';
import { getDate } from '../../../utils/functions';

const TaskInfo = () => {
    const dispatch = useAppDispatch();

    const [addBankTaskOpen, setAddBankTaskOpen] = useState(false);
    const [closeBankTaskOpen, setCloseBankTaskOpen] = useState(false);
    const { currentTask } = useSelector(selectClientTask);
    const { company } = useSelector(selectClientCard);

    const showAddTaskModal = addBankTaskOpen && company;
    const showCloseTaskModal = closeBankTaskOpen && company && currentTask;
    const givenOffer = getGivenOutOffer(currentTask?.offers);
    const isTaskClosed = Boolean(currentTask?.closeDate || givenOffer || currentTask?.closeReason);

    const taskInfoFields = useMemo(() => {
        return currentTask?.type === TaskProductENUM.BANK_GUARANTEE
            ? BankGuaranteeTaskInfoFields
            : LoanTaskInfoFields;
    }, [currentTask?.type]);

    if (!currentTask) return null;

    const handleStatusSelection = (status: TaskStatusTypes) => {
        if (status === currentTask?.status || !(company && currentTask)) return;
        dispatch(
            updateTask({
                type: currentTask.type || TaskProductENUM.BANK_GUARANTEE,
                companyId: company.companyId,
                taskId: currentTask.taskId,
                status,
            }),
        );
    };

    const handleImportanceSelection = (importance: TaskImportanceTypes) => {
        if (importance === currentTask?.importance || !(company && currentTask)) return;
        dispatch(
            updateTask({
                type: currentTask.type || TaskProductENUM.BANK_GUARANTEE,
                companyId: company.companyId,
                taskId: currentTask.taskId,
                importance,
            }),
        );
    };

    const selectorHandler = (value: string, type: TaskInfoSelectsNames) => {
        if (type === 'listOfStatuses') {
            handleStatusSelection(value as TaskStatusTypes);
        } else if (type === 'listOfImportances') {
            handleImportanceSelection(value as TaskImportanceTypes);
        }

        return value;
    };

    const getSelectValue = (type: TaskInfoSelectsNames) => {
        let value = '';

        if (type === 'listOfStatuses') {
            value = currentTask?.status || 'distribution';
        } else if (type === 'listOfImportances') {
            value = currentTask?.importance || 'pending';
        }

        return value;
    };

    const taskStatuses = getTaskStatuses(currentTask.type);
    const getSelectorAr = (type: TaskInfoSelectsNames) => {
        if (type === 'listOfStatuses') {
            return taskStatuses;
        }
        return TaskImportances;
    };

    return (
        <StyledTaskInfo>
            <div className="task-info__general-information">
                <div>
                    {taskInfoFields.map((field) => (
                        <p key={field.name} className="task-info__general-information-row">
                            {field.label}:{' '}
                            {field.name === 'procurementProcedureLink' ? (
                                <a href={currentTask.procurementProcedureLink || ''}>
                                    {getTaskInfoField(currentTask, field.name)}
                                </a>
                            ) : (
                                <span>{getTaskInfoField(currentTask, field.name)}</span>
                            )}
                            {field.name === 'bankGuaranteeTermDays' && (
                                <>
                                    {currentTask.bankGuaranteeTermFrom &&
                                        currentTask.bankGuaranteeTermTo && (
                                            <span className="task-info__general-information-date">
                                                {'('}с{' '}
                                                {getDate(currentTask.bankGuaranteeTermFrom, 'date')
                                                    .split('/')
                                                    .join('.')}{' '}
                                                по{' '}
                                                {getDate(currentTask.bankGuaranteeTermTo, 'date')
                                                    .split('/')
                                                    .join('.')}
                                                {')'}
                                            </span>
                                        )}
                                </>
                            )}
                        </p>
                    ))}

                    <p className="task-info__general-information-row">Комментарий к задаче:</p>
                    <p className="task-info__comment">{currentTask.additComment}</p>
                </div>
                <div>
                    {!isTaskClosed && (
                        <button
                            className="task-info__edit-btn"
                            onClick={() => setAddBankTaskOpen(true)}
                        >
                            Редактировать
                        </button>
                    )}
                </div>
            </div>
            <div className="task-info__actions">
                {TaskInfoSelects.map((select) => (
                    <div key={select.name} className="task-info__action-item">
                        <p>{select.label}:</p>
                        <CustomSelect
                            width={select.width}
                            height={20}
                            backgroundImage={BulletListIcon}
                            backgroundColor="#f9f9f9"
                        >
                            <select
                                value={getSelectValue(select.name)}
                                className={clsx({
                                    'task-info__urgent-select':
                                        select.name === 'listOfImportances' &&
                                        currentTask?.importance === 'urgent',
                                })}
                                onChange={(e) => selectorHandler(e.target.value, select.name)}
                                disabled={isTaskClosed}
                            >
                                {getSelectorAr(select.name)?.map((item, index, arr) => {
                                    const isDisabled = item.name === 'closed';
                                    return (
                                        <option
                                            disabled={isDisabled}
                                            key={item.name}
                                            value={item.name}
                                        >
                                            {`${item.label} ${
                                                select.name === 'listOfStatuses'
                                                    ? `(${index + 1}/${arr.length})`
                                                    : ''
                                            }`}
                                        </option>
                                    );
                                })}
                            </select>
                        </CustomSelect>
                    </div>
                ))}
                <div className="task-info__action-item">
                    <p>Завершить сделку досрочно:</p>
                    <button
                        className={clsx('task-info__close-task-btn', {
                            'task-info__disabled-btn': isTaskClosed,
                        })}
                        disabled={isTaskClosed}
                        onClick={() => setCloseBankTaskOpen(true)}
                    >
                        Закрыть заявку:
                        <img src={CloseIcon} />
                    </button>
                </div>
            </div>
            {showAddTaskModal ? (
                <AddBankTask
                    width={468}
                    companyId={company.companyId}
                    close={() => setAddBankTaskOpen(false)}
                    task={currentTask}
                    clientTaskProductType={currentTask.type}
                />
            ) : null}
            {showCloseTaskModal ? (
                <CloseBankTask
                    width={468}
                    companyId={company.companyId}
                    close={() => setCloseBankTaskOpen(false)}
                    task={currentTask}
                />
            ) : null}
        </StyledTaskInfo>
    );
};

export default TaskInfo;
