import clsx from 'clsx';
import React, { useMemo } from 'react';
import { ClientTaskInterface, IUser, TaskProductENUM } from '../../../utils/types';
import {
    formatNum,
    getBankProductAcronym,
    getClosedOffer,
    getCredentialLkOperator,
    getDate,
    getLabelByLaw,
    getLinkForTask,
    getLinkToClientCardFromTask,
    getNormalizedSum,
    getTaskNumber,
    getUserName,
} from '../../../utils/functions';
import LoanProductLabel from '../../../components/LoanProductLabel/LoanProductLabel';
import TaskCommissionFields from '../../ClientInteraction/ClientClosedRequests/TaskCommissionFields/TaskCommissionFields';
import ClosedRequestIncome from '../../ClientInteraction/ClientClosedRequests/ClosedRequestIncome/ClosedRequestIncome';
import ClosedRequestsStatus from '../../ClientInteraction/ClientClosedRequests/ClosedRequestsStatus/ClosedRequestsStatus';

type ClosedRequestsTableRowProps = {
    task: ClientTaskInterface;
    user: IUser | null;
    index: number;
};
const ClosedRequestsTableRow = ({ task, user, index }: ClosedRequestsTableRowProps) => {
    const isLoan = task.type === TaskProductENUM.LOAN;
    const tableRowClass = useMemo(
        () =>
            clsx('close-requests-table__row', {
                'close-requests-table__manager-view': !user?.isAdmin,
                'close-requests-table__even-row': index % 2 !== 0,
            }),
        [index, user?.isAdmin],
    );
    const closeDate = useMemo(() => getDate(task.closeDate, 'date') || '---', [task.closeDate]);
    const taskNumber = useMemo(() => {
        return {
            value: getTaskNumber(task),
            link: getLinkForTask(task),
        };
    }, [task]);
    const companyName = useMemo(() => {
        return {
            className: clsx({
                'close-requests-table__link': task.company.name,
                'close-requests-table__disabled-link':
                    !user?.isAdmin && task.company.manager?.userId !== user?.userId,
            }),
            value: task.company.name || '---',
            link: getLinkToClientCardFromTask(task, user),
        };
    }, [task, user]);
    const bankProductAcronym = useMemo(
        () => getBankProductAcronym(isLoan ? task.loanType : task.bankGuaranteeType),
        [isLoan, task.bankGuaranteeType, task.loanType],
    );
    const loanLabel = isLoan ? <LoanProductLabel /> : null;
    const federalLaw = useMemo(
        () => (task.federalLaw ? getLabelByLaw(task.federalLaw, true) : '---'),
        [task.federalLaw],
    );
    const bankName = useMemo(() => getClosedOffer(task)?.bankProduct.bank.name || '---', [task]);
    const amount = useMemo(() => {
        const productAmount = isLoan ? task.loanAmount : task.bankGuaranteeAmount;
        return formatNum(getNormalizedSum(productAmount || ''));
    }, [isLoan, task.bankGuaranteeAmount, task.loanAmount]);
    const userName = useMemo(() => getUserName(task.executor), [task.executor]);
    const closedManagerName = useMemo(
        () => getUserName(task.userWhoClosedTask),
        [task.userWhoClosedTask],
    );
    const checkRightForLinkToCard = (
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        task: ClientTaskInterface,
    ) => {
        const adminOrManager = user?.isAdmin || task.company.manager?.userId === user?.userId;

        if (!adminOrManager) {
            e.preventDefault();
            e.stopPropagation();
        }
    };
    const lkOperator = useMemo(
        () =>
            getCredentialLkOperator(
                getClosedOffer(task)?.bankProduct.bank.credentials || [],
                isLoan,
            ),
        [isLoan, task],
    );
    return (
        <div className={tableRowClass} key={task.taskId}>
            <p>{closeDate}</p>
            <div>{loanLabel}</div>
            <a
                className="close-requests-table__link"
                target="_blank"
                rel="noopener noreferrer"
                href={taskNumber.link}
            >
                {taskNumber.value}
            </a>
            <a
                className={companyName.className}
                onClick={(e) => checkRightForLinkToCard(e, task)}
                href={companyName.link}
                target="_blank"
                rel="noopener noreferrer"
            >
                {companyName.value}
            </a>
            <p>{bankProductAcronym}</p>
            <p>{federalLaw}</p>
            <p>{bankName}</p>
            <p>{lkOperator}</p>
            <div className="close-requests-table__right-position">{amount}</div>
            <TaskCommissionFields task={task} />
            <ClosedRequestIncome task={task} />
            <ClosedRequestsStatus task={task} />
            {user?.isAdmin ? <p>{closedManagerName}</p> : null}
            <p>{userName}</p>
        </div>
    );
};

export default ClosedRequestsTableRow;
