/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';

import StyledFilterWrapper from './DurationFilter.style';
import { TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

const filter = {
    label: 'Длительность от:',
    parameter: 'duration',
};

type FilterProps = {
    filterChangeHandler: (value: string, field: string) => void;
    duration: string;
    disabledFilter?: boolean;
};

const initTime = dayjs().set('minute', 0).set('second', 0);

const parseDuration = (duration: number) => {
    if (!Number.isInteger(duration)) {
        return {
            minutes: 0,
            seconds: 0,
        };
    }
    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;
    return {
        minutes,
        seconds,
    };
};

const DurationFilter = ({ filterChangeHandler, duration, disabledFilter }: FilterProps) => {
    const [durationValue, setDurationValue] = useState<dayjs.Dayjs>(initTime);
    const customRef = useRef<HTMLInputElement>(null);
    const hiddenButtonRef = useRef<HTMLButtonElement>(null);

    const durationValueRef = React.useRef(durationValue);
    durationValueRef.current = durationValue;

    useEffect(() => {
        const inputElement = customRef.current;
        if (!inputElement) {
            return;
        }
        inputElement.addEventListener('blur', saveDuration);
        inputElement.addEventListener('keydown', enterKeyDownHandler);
        return () => {
            inputElement.removeEventListener('blur', saveDuration);
            inputElement.removeEventListener('keydown', enterKeyDownHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (duration) {
            const { minutes, seconds } = parseDuration(Number(duration));
            setDurationValue(dayjs().set('minute', minutes).set('second', seconds));
        } else {
            setDurationValue(initTime);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [duration]);

    const enterKeyDownHandler = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            saveDuration();
        }
    };
    const saveDuration = () => {
        if (!hiddenButtonRef.current) {
            return;
        }
        hiddenButtonRef.current.click();
    };

    const timeAcceptHandler = () => {
        const minutes = durationValueRef.current.get('minutes');
        const seconds = durationValueRef.current.get('seconds');
        const durationResult = (minutes || 0) * 60 + (seconds || 0);
        setDurationValue(
            dayjs()
                .set('minute', minutes || 0)
                .set('second', seconds || 0),
        );
        filterChangeHandler(durationResult.toString(), filter.parameter);
    };
    return (
        <StyledFilterWrapper>
            <div className="filter__container">
                <p>{filter.label}</p>
                <TimePicker
                    disabled={disabledFilter}
                    inputRef={customRef}
                    className="filter__picker-root"
                    views={['minutes', 'seconds']}
                    format="mm:ss"
                    disableOpenPicker
                    onChange={(value) => setDurationValue(value || initTime)}
                    value={durationValue}
                />
                <button hidden ref={hiddenButtonRef} onClick={timeAcceptHandler} />
            </div>
        </StyledFilterWrapper>
    );
};

export default DurationFilter;
