import React from 'react';
import DOMPurify from 'dompurify';

import { Tooltip } from 'react-tooltip';
import StyledTooltipWrapper from './TooltipAlt.style';

type TooltipAltProps = {
    anchor: string;
    content: string | string[];
};
const TooltipAlt = ({ anchor, content }: TooltipAltProps) => {
    const place = 'top-end';
    const infoValue = Array.isArray(content) ? (
        content.map((el, index) => (
            <p key={index} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(el) }}></p>
        ))
    ) : (
        <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}></p>
    );

    return (
        <StyledTooltipWrapper>
            <Tooltip
                className="tooltip__container"
                key={place}
                anchorSelect={`#${anchor}`}
                place={place}
                variant="light"
                opacity={1}
            >
                {infoValue}
            </Tooltip>
        </StyledTooltipWrapper>
    );
};

export default TooltipAlt;
