import React from 'react';
import 'react-phone-input-2/lib/style.css';

import { EmailWithError, PartnerContactWithError, PhoneWithError } from '../../../../utils/types';
import AddPhoneAndEmail from '../../../Common/AddPhoneAndEmail/AddPhoneAndEmail';
import StyledFormWrapper from './AddPartnerContact.style';

type AddPartnerContactProps = {
    contact: PartnerContactWithError;
    // eslint-disable-next-line no-unused-vars
    setContact: (value: PartnerContactWithError) => void;
    deleteContact: () => void;
};

const AddPartnerContact = ({ contact, setContact, deleteContact }: AddPartnerContactProps) => {
    const changeNameHandler = (name: string) => {
        const newContact = { ...contact };
        newContact.name = name;
        setContact(newContact);
    };

    const setPhoneNumbers = (phones: PhoneWithError[]) => {
        const newContact = { ...contact };
        newContact.phoneNumbers = phones;
        setContact(newContact);
    };

    const setEmails = (emails: EmailWithError[]) => {
        const newContact = { ...contact };
        newContact.emails = emails;
        setContact(newContact);
    };

    const emails = contact.emails.length
        ? contact.emails
        : [{ email: '', additInfo: '', isError: false }];

    const phoneNumbers = contact.phoneNumbers.length
        ? contact.phoneNumbers
        : [{ phoneNumber: '', additInfo: '', isError: false }];

    return (
        <StyledFormWrapper>
            <label>
                ФИО:
                <input
                    id="name"
                    value={contact.name}
                    onChange={(e) => changeNameHandler(e.target.value)}
                    required
                />
            </label>
            <AddPhoneAndEmail
                hideDisablingCheckbox
                phoneNumbers={phoneNumbers}
                emails={emails}
                setEmails={setEmails}
                setPhoneNumbers={setPhoneNumbers}
            />
            <button
                className="add-partner-contact--delete-btn"
                type="button"
                onClick={deleteContact}
            >
                Удалить
            </button>
        </StyledFormWrapper>
    );
};

export default AddPartnerContact;
