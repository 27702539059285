import styled from 'styled-components/macro';

const StyledClientRequests = styled.div`
    font-weight: 500;
    font-size: 12px;
    padding-bottom: 30px;

    button {
        text-decoration: underline;
        color: #0038ff;
        border: none;
        background: transparent;
    }

    .client-requests__header {
        padding: 5px 7px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #fdd55b;
        height: 30px;

        img {
            width: 20px;
            height: 20px;
            margin-left: 10px;
            transform: rotate(180deg);
        }
    }

    .client-requests__show-section-btn {
        display: flex;
        align-items: center;

        img {
            cursor: pointer;
        }
    }

    .client-requests__opened-section {
        img {
            transform: rotate(0);
        }
    }

    .client-requests__body {
        display: grid;
        grid-template-columns: 2fr 410px;
        max-height: 420px;
        overflow: hidden;
    }

    .client-requests__general-information {
        padding: 9px 0 46px 6px;
        max-height: inherit;
    }

    .client-requests__add-comment-btn {
        display: flex;
        justify-content: end;
    }

    .client-requests__comments-area {
        background: #fff;
        height: 340px;
        border-radius: 5px;
        margin: 7px 0;
        overflow-y: auto;
        padding: 5px;
    }

    .client-requests__stats {
        padding: 7px;
        max-height: inherit;
        overflow: hidden;
    }

    .client-requests__stats-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 7px;
        font-weight: 700;
        line-height: 17px;
    }

    .client-requests__stat-header {
        font-weight: 500;
        margin-bottom: 7px;
    }

    .client-requests__italic-row {
        font-style: italic;
        font-weight: 400;
    }

    .client-requests__note-list {
        height: calc(100% - 110px);
        padding-right: 5px;
        overflow-y: auto;
    }

    .client-requests__gray-background {
        background: #e7e7e7;
    }

    .client-requests__add-request-btn-with-info {
        display: flex;
        align-items: center;
        p {
            margin: 0 20px;
        }
        button {
            margin-bottom: 2px;
        }
    }
`;

export default StyledClientRequests;
