import * as Yup from 'yup';

import { onlyPositiveFloatChecking } from '../utils/functions';

export const editCashbackValidationSchema = Yup.object().shape({
    clientCommission: Yup.string().test(
        'num',
        'Сумма КВК должна быть положительным числом',
        onlyPositiveFloatChecking,
    ),
    status: Yup.string(),
    receiverName: Yup.string(),
    inn: Yup.string().test(
        'len',
        'ИНН должен состоять из 10 и более цифр',
        (val) => typeof val === 'string' && val.length >= 10,
    ),
    receiverHeadName: Yup.string(),
    receiverHeadPost: Yup.string(),
    paymentDate: Yup.string(),
    checkingAccount: Yup.string(),
    bankIdentificationCode: Yup.string(),
    correspondentAccount: Yup.string(),
    individualInn: Yup.string(),
    importantInfo: Yup.string(),
});
