import { AxiosError } from 'axios';

import {
    CreateCompanyContactType,
    DeleteCompanyContactType,
    UpdateCompanyContactType,
    CreateCompanyCommentType,
    DeleteCompanyCommentType,
    CreateCompanyReminderType,
    UpdateCompanyCommentType,
    CreateCompanyNoteType,
    UpdateCompanyNoteType,
    DeleteCompanyNoteType,
    GetAllCompanyDocumentsResponse,
    GetAllCompanyDocumentsType,
    CreateCompanyDocumentType,
    DeleteCompanyDocumentType,
    DownloadAllCompanyDocumentsType,
    DownloadCompanyDocumentType,
    CreateCompanyDocumentCategoryType,
    UpdateCompanyDocumentCategoryType,
    DeleteCompanyDocumentCategoryType,
    DeleteCompanyReminderType,
    StatusResponseType,
    DownloadResponseType,
    GetExternalContactsPropsType,
    GetExternalContactsResponseType,
    RestoreCompanyPropsType,
} from './clientDatabaseApi.types';
import instance from '../axiosInstance';
import {
    CreateCompanyPropsType,
    UpdateCompanyPropsType,
    CheckInnPropsType,
    GetCompanyPropsType,
    clientDatabaseApiPath,
    GetAllCompaniesPropsType,
    DeleteCompanyPropsType,
    GetCompanyResponseType,
    GetAllCompaniesResponseType,
    CheckInnResult,
    CreateCompanyResponseType,
} from './clientDatabaseApi.types';

export const clientDatabaseApi = {
    createCompany: async ({
        ...props
    }: CreateCompanyPropsType): Promise<CreateCompanyResponseType> => {
        try {
            const res = await instance.post(clientDatabaseApiPath.CRUD_COMPANY, {
                ...props,
            });
            return res.data;
        } catch (error) {
            const axiosError = error as Error | AxiosError;
            if (axiosError && axiosError instanceof AxiosError) {
                throw new Error(axiosError.response?.data.message);
            }
        }
    },
    checkInn: async ({ ...props }: CheckInnPropsType): Promise<CheckInnResult> => {
        try {
            const res = await instance.post(clientDatabaseApiPath.CHECK_INN, {
                ...props,
            });
            return res.data;
        } catch (error) {
            console.log(error);
        }
    },
    getCompany: async ({ companyId }: GetCompanyPropsType): Promise<GetCompanyResponseType> => {
        try {
            const response = await instance.get(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}`,
            );
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },
    getAllCompanies: async ({
        ...props
    }: GetAllCompaniesPropsType): Promise<GetAllCompaniesResponseType> => {
        try {
            const response = await instance.get(clientDatabaseApiPath.CRUD_COMPANY, {
                params: {
                    ...props,
                },
            });
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },
    updateCompany: async ({
        clientId,
        ...props
    }: UpdateCompanyPropsType): Promise<StatusResponseType> => {
        try {
            const response = await instance.patch(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${clientId}`,
                {
                    ...props,
                },
            );
            return response.status;
        } catch (error) {
            console.log(error);
            const axiosError = error as Error | AxiosError;
            if (axiosError && axiosError instanceof AxiosError) {
                throw new Error(axiosError.response?.data.message);
            }
        }
    },
    deleteCompany: async ({ companyId }: DeleteCompanyPropsType): Promise<StatusResponseType> => {
        try {
            const response = await instance.delete(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}`,
            );
            return response.status;
        } catch (e) {
            console.log(e);
        }
    },
    restoreCompany: async ({ companyId }: RestoreCompanyPropsType): Promise<StatusResponseType> => {
        try {
            const response = await instance.post(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}/restore`,
            );
            return response.status;
        } catch (e) {
            console.log(e);
        }
    },
    createCompanyContact: async ({
        companyId,
        ...props
    }: CreateCompanyContactType): Promise<StatusResponseType> => {
        try {
            const response = await instance.post(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${clientDatabaseApiPath.COMPANY_CONTACT}`,
                { ...props },
            );
            return response.status;
        } catch (e) {
            console.log(e);
        }
    },
    updateCompanyContact: async ({
        companyId,
        contactId,
        ...props
    }: UpdateCompanyContactType): Promise<StatusResponseType> => {
        try {
            const response = await instance.patch(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${clientDatabaseApiPath.COMPANY_CONTACT}/${contactId}`,
                { ...props },
            );
            return response.status;
        } catch (e) {
            console.log(e);
        }
    },
    deleteCompanyContact: async ({
        companyId,
        contactId,
        ...props
    }: DeleteCompanyContactType): Promise<StatusResponseType> => {
        try {
            const response = await instance.delete(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${clientDatabaseApiPath.COMPANY_CONTACT}/${contactId}`,
                { params: { ...props } },
            );
            return response.status;
        } catch (e) {
            console.log(e);
        }
    },
    createCompanyComment: async ({
        companyId,
        ...props
    }: CreateCompanyCommentType): Promise<StatusResponseType> => {
        try {
            const response = await instance.post(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${clientDatabaseApiPath.COMPANY_COMMENT}`,
                { ...props },
            );
            return response.status;
        } catch (e) {
            console.log(e);
        }
    },
    updateCompanyComment: async ({
        companyId,
        commentId,
        ...props
    }: UpdateCompanyCommentType): Promise<StatusResponseType> => {
        try {
            const response = await instance.patch(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${clientDatabaseApiPath.COMPANY_COMMENT}/${commentId}`,
                { ...props },
            );
            return response.status;
        } catch (e) {
            console.log(e);
        }
    },
    deleteCompanyComment: async ({
        companyId,
        commentId,
        ...props
    }: DeleteCompanyCommentType): Promise<StatusResponseType> => {
        try {
            const response = await instance.delete(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${clientDatabaseApiPath.COMPANY_COMMENT}/${commentId}`,
                { params: { ...props } },
            );
            return response.status;
        } catch (e) {
            console.log(e);
        }
    },
    createCompanyReminder: async ({
        companyId,
        ...props
    }: CreateCompanyReminderType): Promise<StatusResponseType> => {
        try {
            const response = await instance.post(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${clientDatabaseApiPath.COMPANY_REMINDER}`,
                { ...props },
            );
            return response.status;
        } catch (e) {
            console.log(e);
        }
    },
    createCompanyNote: async ({
        companyId,
        ...props
    }: CreateCompanyNoteType): Promise<StatusResponseType> => {
        try {
            const response = await instance.post(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${clientDatabaseApiPath.COMPANY_NOTE}`,
                { ...props },
            );
            return response.status;
        } catch (e) {
            console.log(e);
        }
    },
    updateCompanyNote: async ({
        companyId,
        noteId,
        ...props
    }: UpdateCompanyNoteType): Promise<StatusResponseType> => {
        try {
            const response = await instance.patch(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${clientDatabaseApiPath.COMPANY_NOTE}/${noteId}`,
                { ...props },
            );
            return response.status;
        } catch (e) {
            console.log(e);
        }
    },
    deleteCompanyNote: async ({
        companyId,
        noteId,
        ...props
    }: DeleteCompanyNoteType): Promise<StatusResponseType> => {
        try {
            const response = await instance.delete(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${clientDatabaseApiPath.COMPANY_NOTE}/${noteId}`,
                { params: { ...props } },
            );
            return response.status;
        } catch (e) {
            console.log(e);
        }
    },
    getAllCompanyDocuments: async ({
        companyId,
    }: GetAllCompanyDocumentsType): Promise<GetAllCompanyDocumentsResponse> => {
        try {
            const response = await instance.get(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${clientDatabaseApiPath.COMPANY_DOCUMENTS}`,
            );
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },
    downloadAllCompanyDocuments: async ({
        companyId,
    }: DownloadAllCompanyDocumentsType): Promise<DownloadResponseType> => {
        try {
            const response = await instance.get(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${clientDatabaseApiPath.DOWNLOAD_DOCUMENTS}`,
            );
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },
    downloadCompanyDocument: async ({
        companyId,
        companyDocumentId,
    }: DownloadCompanyDocumentType): Promise<DownloadResponseType> => {
        try {
            const response = await instance.get(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${clientDatabaseApiPath.DOWNLOAD_DOCUMENTS}/${companyDocumentId}`,
            );
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },
    deleteCompanyDocument: async ({
        companyId,
        companyDocumentId,
        ...props
    }: DeleteCompanyDocumentType): Promise<StatusResponseType> => {
        try {
            const response = await instance.delete(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${clientDatabaseApiPath.COMPANY_DOCUMENTS}/${companyDocumentId}`,
                { params: { ...props } },
            );
            return response.status;
        } catch (e) {
            console.log(e);
        }
    },
    deleteReminder: async ({
        companyId,
        reminderId,
        ...props
    }: DeleteCompanyReminderType): Promise<StatusResponseType> => {
        try {
            const response = await instance.delete(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${clientDatabaseApiPath.COMPANY_REMINDER}/${reminderId}`,
                { params: { ...props } },
            );
            return response.status;
        } catch (e) {
            console.log(e);
        }
    },
    createCompanyDocument: async ({
        companyId,
        ...props
    }: CreateCompanyDocumentType): Promise<StatusResponseType> => {
        const response = await instance.post(
            `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${clientDatabaseApiPath.COMPANY_DOCUMENTS}`,
            { ...props },
        );
        return response?.status;
    },
    createCompanyDocumentCategory: async ({
        companyId,
        ...props
    }: CreateCompanyDocumentCategoryType): Promise<StatusResponseType> => {
        try {
            const response = await instance.post(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${clientDatabaseApiPath.DOCUMENTS_CATEGORY}`,
                { ...props },
            );
            return response.status;
        } catch (e) {
            console.log(e);
        }
    },
    updateCompanyDocumentCategory: async ({
        companyId,
        categoryId,
        ...props
    }: UpdateCompanyDocumentCategoryType): Promise<StatusResponseType> => {
        try {
            const response = await instance.patch(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${clientDatabaseApiPath.DOCUMENTS_CATEGORY}/${categoryId}`,
                { ...props },
            );
            return response.status;
        } catch (e) {
            console.log(e);
        }
    },
    deleteCompanyDocumentCategory: async ({
        companyId,
        categoryId,
    }: DeleteCompanyDocumentCategoryType): Promise<StatusResponseType> => {
        try {
            const response = await instance.delete(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${clientDatabaseApiPath.DOCUMENTS_CATEGORY}/${categoryId}`,
            );
            return response.status;
        } catch (e) {
            console.log(e);
        }
    },
    getExternalContacts: async ({
        companyId,
    }: GetExternalContactsPropsType): Promise<GetExternalContactsResponseType> => {
        try {
            const response = await instance.get(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}/external-contacts`,
            );
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },
};
