import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RotatingLines } from 'react-loader-spinner';
import clsx from 'clsx';

import { clientDatabaseColumns } from '../../../utils/data';
import {
    selectClientDatabase,
    setSortingField,
    setClientsOrder,
    setSortBy,
} from '../../../store/slices/clientDatabaseSlice';
import { useAppDispatch } from '../../../store';
import { CompanyInterface, sortingFields } from '../../../utils/types';
import SortBtnIcon from '../../../assets/images/clients/arrows-up-and-down-icon.png';
import SelectedSortBtnIcon from '../../../assets/images/clients/selected-arrows-up-and-down-icon.png';
import Pagination from './Pagination/Pagination';
import AddNextContactDate from '../../../components/Common/AddNextContactDate/AddNextContactDate';
import StyledClientsTable from './ClientsTable.style';
import EmptyList from '../../../components/EmptyList/EmptyList';
import ClientsTableRow from './ClientsTableRow/ClientsTableRow';
import AssignManager from '../../../components/AssignManagerForCompany/AssignManagerForCompany';

const ClientsTable = () => {
    const dispatch = useAppDispatch();
    const { sortBy, clientsOrder, companies, loading } = useSelector(selectClientDatabase);

    const [selectedClient, setSelectedClient] = useState<null | CompanyInterface>(null);
    const [isDateChageOpen, setDateChangeOpen] = useState(false);
    const [isManagerChageOpen, setManagerChangeOpen] = useState(false);

    const handleDateChangeClose = () => {
        setSelectedClient(null);
        setDateChangeOpen(false);
    };

    const handleManagerChangeClose = () => {
        setSelectedClient(null);
        setManagerChangeOpen(false);
    };

    const handleColumnClick = (columnName?: sortingFields) => {
        if (!columnName) return;
        if (sortBy === columnName) {
            const order = clientsOrder === 'ASC' ? 'DESC' : 'ASC';
            dispatch(setClientsOrder(order));
            return;
        }
        dispatch(setSortingField(columnName));
        const order = columnName === ('contactDate' || 'lastCommentDate') ? 'DESC' : 'ASC';
        dispatch(setClientsOrder(order));
        dispatch(setSortBy(columnName));
    };
    return (
        <StyledClientsTable>
            <div className="clients-table__header">
                {clientDatabaseColumns.map((column) => (
                    <div
                        key={column.label}
                        className={
                            column.name === 'clientIncome'
                                ? clsx('clients-table__column-name', 'clients-table__income-row')
                                : 'clients-table__column-name'
                        }
                        onClick={() => handleColumnClick(column.name)}
                    >
                        <p>{column.label}</p>
                        {column.isSortingField && (
                            <img src={column.name === sortBy ? SelectedSortBtnIcon : SortBtnIcon} />
                        )}
                    </div>
                ))}
            </div>
            <div className="clients-table__body">
                {loading.companies ? (
                    <div className="clients-table__loader">
                        <RotatingLines strokeColor="grey" />
                    </div>
                ) : !companies.length ? (
                    <EmptyList />
                ) : (
                    companies.map((client, index) => (
                        <ClientsTableRow
                            key={client.companyId}
                            client={client}
                            index={index}
                            setSelectedClient={setSelectedClient}
                            setManagerOpen={setManagerChangeOpen}
                            setDateOpen={setDateChangeOpen}
                        />
                    ))
                )}
            </div>
            {Boolean(companies.length) && <Pagination />}
            {/* if adding more modals in the future, better to move them in ClientsTableRow component */}
            {isDateChageOpen && selectedClient && (
                <AddNextContactDate
                    close={handleDateChangeClose}
                    width={300}
                    height={180}
                    clientId={selectedClient.companyId}
                />
            )}
            {isManagerChageOpen && selectedClient && (
                <AssignManager
                    close={handleManagerChangeClose}
                    width={300}
                    clientId={selectedClient?.companyId || 0}
                    currentManager={selectedClient?.manager?.userId || 0}
                />
            )}
        </StyledClientsTable>
    );
};

export default ClientsTable;
