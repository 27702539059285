import React, { useEffect } from 'react';
import clsx from 'clsx';
import toast from 'react-hot-toast';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../store';
import {
    cashhbackStatuses,
    editСashbackField,
    editСashbackFieldNameType,
    editСashbackFieldType,
    transferDetails,
} from '../../utils/data';
import { EditCashbackProps, Inputs } from './EditCashback.types';
import { editCashbackValidationSchema } from '../../validation/editCashbackValidation';
import { userSelector } from '../../store/slices/userSlice';
import { StyledScrollbars } from '../AddClient/AddClientsInfo/AddClientsInfo.style';
import {
    selectCompanyCashback,
    updateCompanyCashback,
} from '../../store/slices/companyCashbackSlice';
import { getAmountInRub, getDateAndTimeFromIsoString } from '../../utils/functions';
import CommonModal from '../Common/CommonModal/CommonModal';
import CustomSelect from '../Common/CustomSelect/CustomSelect';
import BtnLoader from '../Common/BtnLoader/BtnLoader';
import BulletListIcon from '../../assets/images/bullet-list-icon.png';
import { StyledFormWrapper, StyledContainer } from './EditCashback.style';

const EditCashback = ({ close, width, companyId, taskId, cashback }: EditCashbackProps) => {
    const dispatch = useAppDispatch();
    const { user } = useSelector(userSelector);
    const { loading } = useSelector(selectCompanyCashback);

    const formOptions = {
        defaultValues: {
            clientCommission: getAmountInRub(cashback.cashbackSum),
            status: cashback.status || 'accrued',
            receiverName: cashback.receiverName || '',
            inn: cashback.receiverInn || '',
            receiverHeadName: cashback.receiverHeadName || '',
            receiverHeadPost: cashback.receiverHeadPost || '',
            paymentDate: getDateAndTimeFromIsoString(cashback.paymentDate || '').date,
            checkingAccount: cashback.checkingAccount || '',
            bankIdentificationCode: cashback.bankIdentificationCode || '',
            correspondentAccount: cashback.correspondentAccount || '',
            individualInn: cashback.individualInn || '',
            importantInfo: cashback.importantInfo || '',
        },
        resolver: yupResolver(editCashbackValidationSchema),
    };
    const { register, handleSubmit, formState } = useForm<Inputs>(formOptions);
    const { errors } = formState;

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const {
            receiverHeadName,
            receiverHeadPost,
            // clientCommission,
            paymentDate,
            importantInfo,
            status,
            checkingAccount,
            individualInn,
            bankIdentificationCode,
            correspondentAccount,
            inn,
            receiverName,
        } = data;

        // const commissionWithoutSpaces = clientCommission.split(' ').join('');

        // const normalizedСlientCommission =
        //     commissionWithoutSpaces !== cashback.offer.clientCommission
        //         ? Number(commissionWithoutSpaces)
        //         : undefined;

        const normalizedReceiverHeadName =
            receiverHeadName && receiverHeadName !== cashback.receiverHeadName
                ? receiverHeadName
                : undefined;

        const normalizedReceiverHeadPost =
            receiverHeadPost && receiverHeadPost !== cashback.receiverHeadPost
                ? receiverHeadPost
                : undefined;

        const normalizedImportantInfo =
            importantInfo && importantInfo !== cashback.importantInfo ? importantInfo : undefined;

        const normalizedPaymentDate =
            paymentDate && paymentDate !== cashback.paymentDate
                ? new Date(paymentDate).toISOString()
                : undefined;

        const normalizedIndividualInn =
            individualInn && individualInn !== cashback.individualInn ? individualInn : undefined;
        const normalizedCheckingAccount =
            checkingAccount && checkingAccount !== cashback.checkingAccount
                ? checkingAccount
                : undefined;

        const normalizedStatus = status !== cashback.status ? status : undefined;
        const normalizedCorrespondentAccount =
            correspondentAccount && correspondentAccount !== cashback.correspondentAccount
                ? correspondentAccount
                : undefined;

        const normalizedBankIdentificationCode =
            bankIdentificationCode && bankIdentificationCode !== cashback.bankIdentificationCode
                ? bankIdentificationCode
                : undefined;

        const payload = {
            companyId,
            taskId,
            cashbackId: cashback.cashbackId,
            offerId: cashback.offer.taskOfferId,
            receiverName,
            receiverInn: inn.trim(),
            receiverHeadName: normalizedReceiverHeadName,
            receiverHeadPost: normalizedReceiverHeadPost,
            // clientCommission: normalizedСlientCommission,
            importantInfo: normalizedImportantInfo,
            paymentDate: normalizedPaymentDate,
            status: normalizedStatus,
            checkingAccount: normalizedCheckingAccount,
            individualInn: normalizedIndividualInn,
            correspondentAccount: normalizedCorrespondentAccount,
            bankIdentificationCode: normalizedBankIdentificationCode,
        };

        dispatch(updateCompanyCashback(payload));
    };

    const getInputField = (field: editСashbackFieldType) => {
        switch (field.type) {
            case 'input':
                return !transferDetails.includes(field.name) ? (
                    <input
                        className={clsx({
                            'edit-cashback__error-input':
                                errors[field.name as editСashbackFieldNameType]?.message,
                        })}
                        id={field.name}
                        disabled={field.name === 'clientCommission'}
                        {...register(field.name as editСashbackFieldNameType)}
                    />
                ) : (
                    <div
                        className={clsx('edit-cashback__double-input', {
                            'edit-cashback__individual-inn': field.name === 'individualInn',
                        })}
                    >
                        <p>{field.label}:</p>
                        <input
                            className={clsx({
                                'edit-cashback__error-input':
                                    errors[field.name as editСashbackFieldNameType]?.message,
                            })}
                            id={field.name}
                            disabled={!user?.isAdmin}
                            {...register(field.name as editСashbackFieldNameType)}
                        />
                    </div>
                );
            case 'date':
                return (
                    <input
                        className={clsx({
                            'edit-cashback__error-input':
                                errors[field.name as editСashbackFieldNameType]?.message,
                        })}
                        id={field.name}
                        type="date"
                        required={false}
                        {...register(field.name as editСashbackFieldNameType)}
                    />
                );
            case 'list':
                return (
                    <CustomSelect width={401} height={24} backgroundImage={BulletListIcon}>
                        <select {...register(field.name as editСashbackFieldNameType)}>
                            {cashhbackStatuses.map((item) => (
                                <option key={item.name} value={item.name}>
                                    {item.label}
                                </option>
                            ))}
                        </select>
                    </CustomSelect>
                );
            case 'textarea':
                return (
                    <textarea
                        className={clsx('edit-cashback__comment', {
                            'edit-cashback__error-input':
                                errors[field.name as editСashbackFieldNameType]?.message,
                        })}
                        id={field.name}
                        {...register(field.name as editСashbackFieldNameType)}
                    />
                );

            default:
                break;
        }
    };

    useEffect(() => {
        let hasEmptyFieldError = false;
        Object.keys(editCashbackValidationSchema.fields).forEach((key) => {
            const errorMessage = errors[key as editСashbackFieldNameType]?.message;

            if (errorMessage?.startsWith('Заполните') || errorMessage?.startsWith('Выберите')) {
                if (!hasEmptyFieldError) {
                    hasEmptyFieldError = true;
                    toast.error('Заполните необходимые поля');
                }
            } else if (errorMessage) {
                toast.error(errorMessage);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);

    return (
        <CommonModal hasCloseBtn={true} close={close}>
            <StyledFormWrapper width={width}>
                <StyledContainer>
                    <h3 className="edit-cashback__title">Выплата кэшбэка</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <StyledScrollbars
                            style={{ height: 'calc(100% - 30px)' }}
                            renderTrackHorizontal={(props) => (
                                <div
                                    {...props}
                                    style={{ display: 'none' }}
                                    className="track-horizontal"
                                />
                            )}
                            thumbSize={170}
                            thumbMinSize={30}
                        >
                            {editСashbackField.map((field) => (
                                <div key={field.name} className="edit-cashback__label">
                                    {!transferDetails.includes(field.name) && (
                                        <>
                                            {field.label}
                                            {field.isRequired && (
                                                <span className="edit-cashback__sign-of-obligatory-field">
                                                    *
                                                </span>
                                            )}
                                            :
                                        </>
                                    )}
                                    {field.name === 'checkingAccount' && (
                                        <p className="edit-cashback__section-name">
                                            Реквизиты для перевода:
                                        </p>
                                    )}
                                    {getInputField(field)}
                                </div>
                            ))}
                        </StyledScrollbars>
                        <BtnLoader
                            isLoading={loading.updateCashback}
                            btnTitle="Сохранить"
                            btnClass={clsx('edit-cashback__btn', {
                                'edit-cashback__disabled-btn': loading.updateCashback,
                            })}
                        />
                    </form>
                </StyledContainer>
            </StyledFormWrapper>
        </CommonModal>
    );
};

export default EditCashback;
