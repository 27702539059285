import styled from 'styled-components/macro';

const StyledRequestItem = styled.div`
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 10px;

    .request-item__header {
        padding: 5px 7px;
        font-weight: 600;
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 30px;
        grid-column-gap: 15px;
        min-height: 30px;
        margin-bottom: 3px;

        img {
            width: 20px;
            height: 20px;
            margin-left: 10px;
            transform: rotate(180deg);
            cursor: pointer;
        }
    }

    .request-item__opened-section img {
        transform: rotate(0);
    }

    .request-item__text {
        padding: 5px 5px 5px 12px;
        ol {
            padding-left: 22px;
        }

        ul {
            padding: 0 0 0 20px !important;
            list-style-type: disc;
        }
    }
`;

export default StyledRequestItem;
