import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { selectClientTask } from '../../store/slices/clientTaskSlice';
import CommonModal from '../Common/CommonModal/CommonModal';
import DefaultLoader from '../Common/BtnLoader/DefaultLoader';
import ProductsForTaskTable from './ProductsForTaskTable/ProductsForTaskTable';
import StyledForm from './AddBankToTask.style';

type AddBankToTaskProps = {
    close: () => void;
    width: string;
    companyId: number;
    taskId: number;
};

const AddBankToTask = ({ close, width, companyId, taskId }: AddBankToTaskProps) => {
    const { loading } = useSelector(selectClientTask);
    const [allBanks, setAllBanks] = useState(false);
    const btnTitle = allBanks ? 'Назад' : 'Добавить из списка';

    const toggleMode = () => {
        setAllBanks((prev) => !prev);
    };

    return (
        <CommonModal hasCloseBtn={true} close={close}>
            <StyledForm width={width}>
                <h3 className="add-bank-to-task__header">Добавить банк:</h3>
                <ProductsForTaskTable companyId={companyId} taskId={taskId} allBanks={allBanks} />
                <button
                    className={clsx('add-bank-to-task__button', {
                        'add-bank-to-task__disabled-btn': loading.createTaskOffer,
                    })}
                    type="button"
                    onClick={toggleMode}
                >
                    {loading.createTaskOffer ? <DefaultLoader /> : btnTitle}
                </button>
            </StyledForm>
        </CommonModal>
    );
};

export default AddBankToTask;
