import styled from 'styled-components/macro';

const StyledClientCashback = styled.div`
    font-weight: 400;
    font-size: 12px;

    .cashback-stat__header {
        padding: 5px 7px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        color: #fff;
        background: #959595;
    }

    .cashback-stat__count-and-amount {
        display: flex;
        align-items: center;

        p {
            margin-left: 10px;
        }
    }
`;

export default StyledClientCashback;
