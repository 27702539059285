import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-hot-toast';

import {
    CreateCompanyDocumentType,
    DeleteCompanyDocumentType,
    DownloadAllCompanyDocumentsType,
    DownloadCompanyDocumentType,
    GetAllCompanyDocumentsType,
    CreateCompanyDocumentCategoryType,
    DeleteCompanyDocumentCategoryType,
    UpdateCompanyDocumentCategoryType,
} from '../../../api/clientDatabaseApi/clientDatabaseApi.types';
import { getError, isResponseOK } from '../../../utils/functions';
import { RootState } from '../..';
import { clientDatabaseApi } from '../../../api/clientDatabaseApi/clientDatabaseApi';
import { InitialStateType } from './types';
import { ClientTaskInterface, DocumentCategoryInterface } from '../../../utils/types';

export const getAllCompanyDocuments = createAsyncThunk<
    {
        companyDocuments: DocumentCategoryInterface[];
        tasks: ClientTaskInterface[];
    },
    GetAllCompanyDocumentsType,
    {
        rejectValue: string;
    }
>('clientDocument/getAllCompanyDocuments', async ({ ...props }, { rejectWithValue }) => {
    try {
        const res = await clientDatabaseApi.getAllCompanyDocuments({ ...props });
        if (res?.message === 'Success') {
            return {
                companyDocuments: res.data?.companyDocuments || [],
                tasks: res.data?.tasks || [],
            };
        } else {
            throw res?.data;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const createCompanyDocument = createAsyncThunk<
    number | undefined,
    CreateCompanyDocumentType,
    {
        rejectValue: string;
    }
>(
    'clientDocument/createCompanyDocument',
    async ({ companyId, ...props }, { rejectWithValue, dispatch }) => {
        try {
            const res = await clientDatabaseApi.createCompanyDocument({ ...props, companyId });
            if (isResponseOK(res)) {
                dispatch(getAllCompanyDocuments({ companyId }));
                return res;
            }
        } catch (e) {
            return rejectWithValue(
                getError(
                    'Ошибка загрузки файла. Превышен допустимый размер или формат файла не поддерживается.',
                ),
            );
        }
    },
);

export const deleteCompanyDocument = createAsyncThunk<
    number | undefined,
    DeleteCompanyDocumentType,
    {
        rejectValue: string;
    }
>(
    'clientDocument/deleteCompanyNote',
    async ({ companyId, ...props }, { rejectWithValue, dispatch }) => {
        try {
            const res = await clientDatabaseApi.deleteCompanyDocument({ ...props, companyId });
            if (isResponseOK(res)) {
                dispatch(getAllCompanyDocuments({ companyId }));
                return res;
            } else {
                throw res;
            }
        } catch (e) {
            return rejectWithValue(getError(e));
        }
    },
);

export const downloadAllCompanyDocuments = createAsyncThunk<
    string,
    DownloadAllCompanyDocumentsType,
    {
        rejectValue: string;
    }
>('clientDocument/downloadAllCompanyDocuments', async ({ ...props }, { rejectWithValue }) => {
    try {
        const res = await clientDatabaseApi.downloadAllCompanyDocuments({ ...props });
        if (res?.message === 'Success') {
            return res.data;
        } else {
            throw res?.data;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const downloadCompanyDocument = createAsyncThunk<
    { document: string; name: string },
    DownloadCompanyDocumentType,
    {
        rejectValue: string;
    }
>('clientDocument/downloadCompanyDocument', async ({ name, ...props }, { rejectWithValue }) => {
    try {
        const res = await clientDatabaseApi.downloadCompanyDocument({ ...props });
        if (res?.message === 'Success') {
            return {
                document: res.data,
                name: name || '',
            };
        } else {
            throw res?.data;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const CreateCompanyDocumentCategory = createAsyncThunk<
    number | undefined,
    CreateCompanyDocumentCategoryType,
    {
        rejectValue: string;
    }
>(
    'clientDocument/CreateCompanyDocumentCategory',
    async ({ companyId, ...props }, { rejectWithValue, dispatch }) => {
        try {
            const res = await clientDatabaseApi.createCompanyDocumentCategory({
                ...props,
                companyId,
            });
            if (isResponseOK(res)) {
                dispatch(getAllCompanyDocuments({ companyId }));
                return res;
            } else {
                throw res;
            }
        } catch (e) {
            return rejectWithValue(getError(e));
        }
    },
);

export const UpdateCompanyDocumentCategory = createAsyncThunk<
    number | undefined,
    UpdateCompanyDocumentCategoryType,
    {
        rejectValue: string;
    }
>(
    'clientDocument/UpdateCompanyDocumentCategory',
    async ({ companyId, ...props }, { rejectWithValue, dispatch }) => {
        try {
            const res = await clientDatabaseApi.updateCompanyDocumentCategory({
                ...props,
                companyId,
            });
            if (isResponseOK(res)) {
                dispatch(getAllCompanyDocuments({ companyId }));
                return res;
            } else {
                throw res;
            }
        } catch (e) {
            return rejectWithValue(getError(e));
        }
    },
);

export const DeleteCompanyDocumentCategory = createAsyncThunk<
    number | undefined,
    DeleteCompanyDocumentCategoryType,
    {
        rejectValue: string;
    }
>(
    'clientDocument/DeleteCompanyDocumentCategory',
    async ({ companyId, ...props }, { rejectWithValue, dispatch }) => {
        try {
            const res = await clientDatabaseApi.deleteCompanyDocumentCategory({
                ...props,
                companyId,
            });
            if (isResponseOK(res)) {
                dispatch(getAllCompanyDocuments({ companyId }));
                return res;
            } else {
                throw res;
            }
        } catch (e) {
            return rejectWithValue(getError(e));
        }
    },
);

const initialState: InitialStateType = {
    companyDocumentCategories: null,
    companyTasks: [],
    archive: '',
    currentDocument: null,
    isRequestFulfilled: {
        companyDocumentCategories: false,
        createDocument: false,
        downloadArchive: false,
        currentDocument: false,
        createDocumentCategory: false,
        updateDocumentCategory: false,
        deleteDocumentCategory: false,
    },
    loading: {
        companyDocumentCategories: false,
        createDocument: false,
        downloadArchive: false,
        currentDocument: false,
        createDocumentCategory: false,
        updateDocumentCategory: false,
        deleteDocumentCategory: false,
    },
    error: '',
};

export const clientDocumentSlice = createSlice({
    name: 'clientDocument',
    initialState,
    reducers: {
        clearClientDocumentState: (state) => {
            state = initialState;
            return state;
        },
        clearArchive: (state) => {
            state.archive = '';
            state.isRequestFulfilled.downloadArchive = false;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllCompanyDocuments.pending, (state) => {
            state.loading.companyDocumentCategories = true;
            state.isRequestFulfilled.companyDocumentCategories = false;
        });
        builder.addCase(getAllCompanyDocuments.fulfilled, (state, { payload }) => {
            state.companyDocumentCategories = payload.companyDocuments;
            state.companyTasks = payload.tasks;
            state.loading.companyDocumentCategories = false;
            state.isRequestFulfilled.companyDocumentCategories = true;
        });
        builder.addCase(getAllCompanyDocuments.rejected, (state, { payload }) => {
            state.loading.companyDocumentCategories = false;
            if (typeof payload !== 'string') return;
            toast.error(payload);
            state.error = payload as string;
        });
        builder.addCase(createCompanyDocument.pending, (state) => {
            state.loading.createDocument = true;
            state.isRequestFulfilled.createDocument = false;
        });
        builder.addCase(createCompanyDocument.fulfilled, (state) => {
            state.loading.createDocument = false;
            state.isRequestFulfilled.createDocument = true;
        });
        builder.addCase(createCompanyDocument.rejected, (state, { payload }) => {
            state.loading.createDocument = false;
            if (typeof payload !== 'string') return;
            toast.error(payload);
            state.error = payload as string;
        });
        builder.addCase(downloadAllCompanyDocuments.pending, (state) => {
            state.loading.downloadArchive = true;
            state.isRequestFulfilled.downloadArchive = false;
        });
        builder.addCase(downloadAllCompanyDocuments.fulfilled, (state, { payload }) => {
            state.archive = payload;
            state.isRequestFulfilled.downloadArchive = true;
            state.loading.downloadArchive = false;
        });
        builder.addCase(downloadAllCompanyDocuments.rejected, (state, { payload }) => {
            state.loading.downloadArchive = false;
            if (typeof payload !== 'string') return;
            toast.error(payload);
            state.error = payload as string;
        });
        builder.addCase(downloadCompanyDocument.pending, (state) => {
            state.loading.currentDocument = true;
            state.isRequestFulfilled.currentDocument = false;
        });
        builder.addCase(downloadCompanyDocument.fulfilled, (state, { payload }) => {
            state.isRequestFulfilled.currentDocument = true;
            state.loading.currentDocument = false;
            state.currentDocument = payload;
        });
        builder.addCase(downloadCompanyDocument.rejected, (state, { payload }) => {
            state.loading.currentDocument = false;
            if (typeof payload !== 'string') return;
            toast.error(payload);
            state.error = payload as string;
        });
        builder.addCase(CreateCompanyDocumentCategory.pending, (state) => {
            state.loading.createDocumentCategory = true;
            state.isRequestFulfilled.createDocumentCategory = false;
        });
        builder.addCase(CreateCompanyDocumentCategory.fulfilled, (state) => {
            state.loading.createDocumentCategory = false;
            state.isRequestFulfilled.createDocumentCategory = true;
        });
        builder.addCase(CreateCompanyDocumentCategory.rejected, (state, { payload }) => {
            state.loading.createDocumentCategory = false;
            if (typeof payload !== 'string') return;
            toast.error(payload);
            state.error = payload as string;
        });
        builder.addCase(UpdateCompanyDocumentCategory.pending, (state) => {
            state.loading.updateDocumentCategory = true;
            state.isRequestFulfilled.updateDocumentCategory = false;
        });
        builder.addCase(UpdateCompanyDocumentCategory.fulfilled, (state) => {
            state.loading.updateDocumentCategory = false;
            state.isRequestFulfilled.updateDocumentCategory = true;
        });
        builder.addCase(UpdateCompanyDocumentCategory.rejected, (state, { payload }) => {
            state.loading.updateDocumentCategory = false;
            if (typeof payload !== 'string') return;
            toast.error(payload);
            state.error = payload as string;
        });
        builder.addCase(DeleteCompanyDocumentCategory.pending, (state) => {
            state.loading.deleteDocumentCategory = true;
            state.isRequestFulfilled.deleteDocumentCategory = false;
        });
        builder.addCase(DeleteCompanyDocumentCategory.fulfilled, (state) => {
            state.loading.deleteDocumentCategory = false;
            state.isRequestFulfilled.deleteDocumentCategory = true;
        });
        builder.addCase(DeleteCompanyDocumentCategory.rejected, (state, { payload }) => {
            state.loading.deleteDocumentCategory = false;
            if (typeof payload !== 'string') return;
            toast.error(payload);
            state.error = payload as string;
        });
    },
});

export const { clearClientDocumentState, clearArchive } = clientDocumentSlice.actions;

export const selectClientDocument = (state: RootState) => state.clientDocument;
