import styled from 'styled-components/macro';

const StyledClientTaskActions = styled.div`
    font-weight: 400;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px;

    .client-task-actions__tools-img-with-description {
        display: flex;
        align-items: center;
        margin-right: 20px;
        cursor: pointer;

        img {
            margin-right: 10px;
        }
    }

    .client-task-actions__tools-description {
        text-decoration: underline;
        color: #0038ff;
    }

    .client-task-actions__disabled-action {
        cursor: auto;

        p,
        img {
            opacity: 0.6;
        }
    }

    .client-task-actions__auto-cursor {
        cursor: auto;
    }
`;

export default StyledClientTaskActions;
