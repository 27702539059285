import styled from 'styled-components/macro';

const StyledCommentItem = styled.div`
    font-weight: 500;
    font-size: 12px;
    padding: 5px 10px;
    display: grid;
    grid-template-columns: 1fr 52px;
    grid-column-gap: 15px;
    background: #fff;
    border-bottom: 1px solid lightgray;
    margin-bottom: 10px;
    .comment-item__info {
        line-height: 17px;
    }

    .comment-item__author {
        font-weight: 600;
        margin: 0 7px;
    }

    .comment-item__actions {
        display: flex;
        justify-content: space-between;
        align-items: end;
        img {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
    }

    .comment-item__not-admin-actions {
        justify-content: end;
    }

    .comment-item__subheader {
        color: #03f;
    }

    .comment-item__text {
        ol {
            padding-left: 22px;
        }
        ul {
            padding: 0 0 0 20px !important;
            list-style-type: disc;
        }
    }
`;

export default StyledCommentItem;
