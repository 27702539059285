import styled from 'styled-components/macro';

const StyledFormWrapper = styled.div<{ width: number }>`
    width: ${({ width }) => `${width}px`};
    line-height: 17px;
    font-weight: 300;
    font-size: 12px;
    input {
        margin: 5px 100px 7px 0;
        width: 400px;
        height: 24px;
        border: 1px solid #000;
        border-radius: 5px;
        padding-left: 10px;
    }

    h3 {
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 13px;
    }

    button {
        border: none;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #0038ff;
        padding: 0;
    }

    .calculator__btn {
        display: flex;
        justify-content: space-between;
        padding: 10px 0 13px;
        font-weight: 500;
    }

    .calculator__container-for-period {
        display: flex;
        align-items: center;
    }

    .calculator__period {
        width: 95px;
        margin-right: 11px;
    }

    .calculator__date-picker {
        width: 140px;
        margin: 0 11px;
    }

    .calculator__calculation-results {
        margin-top: 6px;
    }

    .calculator__calculation-results-header {
        display: grid;
        grid-template-columns: 167px 1fr 153px;
        grid-column-gap: 12px;
        p {
            text-align: end;
        }
    }

    .calculator__percent {
        text-align: end;
        margin-right: 5px;
    }

    .calculator__calculation-results-row {
        font-weight: 500;
        display: grid;
        grid-template-columns: 93px 123px 130px 50px 161px;
        grid-column-gap: 4px;
        margin-bottom: 9px;
        input {
            width: 100%;
            margin: 0;
            border: 1px solid #959595;
        }
    }

    .calculator__calculation-results-item {
        height: 24px;
        background: #fff;
        border: 1px solid #959595;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: end;
        padding-right: 10px;
        overflow: hidden;
    }

    .calculator__cashback {
        margin: 0 4px 0 9px;
    }

    .calculator__total-cost {
        margin-left: 8px;
        color: #00892f;
    }

    .calculator__commission {
        color: #f00;
    }

    .calculator__checkbox {
        display: flex;
        justify-content: end;
        align-items: center;
        input {
            width: 24px;
            margin: 5px 12px 5px 10px;
            cursor: pointer;
        }
    }
`;

export default StyledFormWrapper;
