import styled from 'styled-components/macro';

const StyledClientsTable = styled.div`
    font-weight: 400;
    font-size: 12px;

    .client-task-item__header {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fdd55b;
        min-height: 30px;

        img {
            width: 10px;
            height: 10px;
            margin-left: 5px;
        }
    }

    .client-task-item__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 30px;
    }

    .client-task-item__executor {
        display: flex;
        align-items: center;

        select {
            margin-left: 10px;
        }
    }

    .client-task-item__subheader {
        font-weight: 600;
        font-size: 12px;
        text-align: center;
        line-height: 14px;
        padding: 8px;
        background: #e7e7e7;
    }

    .task-info__disabled-header {
        opacity: 0.6;
    }

    .task-info__disabled-select {
        cursor: auto;
    }
`;

export default StyledClientsTable;
