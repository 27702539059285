import styled from 'styled-components/macro';

const StyledClientActs = styled.div`
    font-weight: 400;
    font-size: 12px;

    .client-acts__header {
        background: #e7e7e7;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 30px;
        font-weight: 600;
        font-size: 12px;
        padding: 5px 7px;
    }

    .client-acts__make-act {
        display: flex;
        align-items: center;

        button {
            border: none;
            background: transparent;
            text-decoration: underline;
            color: #0038ff;
            margin-right: 10px;
        }
    }

    .client-acts__stats {
        display: flex;
        align-items: center;
        font-weight: 300;

        p {
            margin-left: 15px;
        }

        span {
            font-weight: 600;
        }
    }

    .acts-table__right-position-cell {
        display: flex;
        justify-content: end;
    }
`;

export default StyledClientActs;
