import React from 'react';

import { RequestInterface } from '../../../utils/types';
import RequestItem from '../RequestItem/RequestItem';
import StyledOfferRequest from './ClosedOfferRequests.style';

type ClosedRequestProps = {
    closedRequests: RequestInterface[];
};

const ClosedRequest = ({ closedRequests }: ClosedRequestProps) => {
    return (
        <StyledOfferRequest>
            {closedRequests.length ? (
                <h3 className="closed-offer-request__header">Отработанные запросы:</h3>
            ) : null}
            {closedRequests.map((request, index) => (
                <RequestItem
                    key={request.taskOfferRequestId}
                    companyId={Number(request.taskOfferRequestId)}
                    request={request}
                    index={closedRequests.length - index}
                />
            ))}
        </StyledOfferRequest>
    );
};

export default ClosedRequest;
