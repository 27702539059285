import styled from 'styled-components/macro';

const StyledContainer = styled.div<{ height: number }>`
    height: ${({ height }) => `${height}px`};
    max-height: 80vh;
    max-height: 650px;
    margin-left: 6px;
    font-size: 12px;
    font-weight: 300;
    line-height: 17px;

    form {
        margin-right: 6px;
        display: flex;
        flex-direction: column;
        height: calc(100% - 38px);
    }

    .edit-offer__description {
        font-style: italic;
        font-weight: 300;
        font-size: 10px;
        line-height: 14px;
        color: #000;
        margin: 8px 0 15px;
    }

    .edit-offer__sign-of-obligatory-field {
        color: #ff0000;
    }

    .edit-offer__comment {
        height: 51px;
        padding: 5px 10px;
    }

    input,
    select,
    textarea {
        width: 99%;
        margin: 5px 0;
        padding-left: 10px;

        &:focus {
            border: 2px solid #005fcc;
        }

        &:disabled {
            background: #fff;
        }
    }

    .edit-offer__error-input {
        border-color: #f00;
    }

    .edit-offer__label {
        line-height: 17px;
    }

    .edit-offer__btn {
        margin: 12px 0 10px;
        text-align: initial;
        justify-self: end;
        width: fit-content;
    }

    .edit-offer__switcher {
        display: flex;
        justify-content: space-between;
        margin: 12px 0 6px;
    }

    .edit-offer__login-password {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;
    }

    .edit-offer__generator {
        padding-top: 6px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        button:disabled {
            opacity: 0.6;
            cursor: auto;
        }
    }

    .edit-offer__document {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 17px;
        margin: 5px 0;

        button {
            font-weight: 300;
            text-decoration: underline;
        }
    }

    .edit-offer__doc-name {
        display: grid;
        grid-template-columns: 1fr 20px;
        grid-column-gap: 10px;
        align-items: center;
        line-height: 17px;
        margin: 5px 0;

        img {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }

        p {
            word-break: break-word;
            display: -webkit-box;
            line-height: 17px;
            height: 17px;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            font-style: italic;
        }
    }

    .edit-offer__error-document {
        color: #f00;
    }

    .edit-offer__double-input {
        display: grid;
        grid-template-columns: 36px 1fr 28px 1fr;
        grid-column-gap: 10px;
        align-items: end;

        p {
            margin-bottom: 5px;
        }
    }

    .edit-offer__document {
        display: flex;
        justify-content: space-between;
    }

    .edit-offer__doc-name {
        display: grid;
        grid-template-columns: 1fr 20px;
        grid-column-gap: 10px;
        align-items: center;
        line-height: 17px;
        margin: 5px 0;

        img {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }

        p {
            word-break: break-word;
            display: -webkit-box;
            line-height: 17px;
            height: 17px;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            font-style: italic;
        }
    }

    .edit-offer__double-input {
        display: grid;
        grid-template-columns: 36px 1fr 28px 1fr;
        grid-column-gap: 10px;
        align-items: end;

        p {
            margin-bottom: 5px;
        }
    }

    .edit-offer__document {
        display: flex;
        justify-content: space-between;
    }

    .edit-offer__doc-name {
        display: grid;
        grid-template-columns: 1fr 20px;
        grid-column-gap: 10px;
        align-items: center;
        line-height: 17px;
        margin: 5px 0;

        img {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }

        p {
            word-break: break-word;
            display: -webkit-box;
            line-height: 17px;
            height: 17px;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            font-style: italic;
        }
    }

    .edit-offer__yellow-field {
        background: rgba(246, 228, 119, 0.5);
        padding: 0 2px;
    }

    .edit-offer__overstatement {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 20px;
    }

    .edit-offer__separation {
        display: flex;
        justify-content: space-between;
        align-items: center;

        input {
            width: 30%;
        }
    }
`;

const StyledFormWrapper = styled.div<{ width: number }>`
    width: ${({ width }) => `${width}px`};

    input,
    textarea {
        margin-top: 5px;
        height: 24px;
        border: 1px solid #000;
        border-radius: 5px;
        padding-left: 10px;
    }

    h3 {
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 13px;
    }

    button {
        border: none;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #0038ff;
        padding: 0;
    }
`;

export { StyledContainer, StyledFormWrapper };
