import styled from 'styled-components/macro';

type StyledToggleSwitchType = { width: number; height: number };

const StyledToggleSwitch = styled.div<StyledToggleSwitchType>`
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    position: relative;
    display: inline-block;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .toggle-switch__switcher {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 34px;
    }

    .toggle-switch__disabled-switcher {
        cursor: auto;
    }

    .toggle-switch__switcher:before {
        position: absolute;
        content: '';
        height: ${({ height }) => `${height - 4}px`};
        width: ${({ height }) => `${height - 4}px`};
        left: 2px;
        bottom: 2px;
        background-color: #fff;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
        opacity: 0.7;
    }

    input:checked + span {
        background-color: #4caf50;
    }

    input:focus + span {
        box-shadow: 0 0 1px #2196f3;
    }

    input:checked + span:before {
        -webkit-transform: ${({ width, height }) => `translateX(${width - height}px)`};
        -ms-transform: ${({ width, height }) => `translateX(${width - height}px)`};
        transform: ${({ width, height }) => `translateX(${width - height}px)`};
    }
`;

export default StyledToggleSwitch;
