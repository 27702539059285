import {
    companyCashbackPath,
    UpdateCashbackPropsType,
    CreateCashbackPropsType,
    GetCashbackPropsType,
    GetCompanyCashbackResponseType,
} from './companyCashbackApi.types';
import instance from '../axiosInstance';
import { StatusResponseType } from '../clientDatabaseApi/clientDatabaseApi.types';

export const companyCashbackApi = {
    createCashback: async ({
        companyId,
        taskId,
        offerId,
        ...props
    }: CreateCashbackPropsType): Promise<StatusResponseType> => {
        try {
            const res = await instance.post(
                `${companyCashbackPath.COMPANY}/${companyId}${companyCashbackPath.TASK}/${taskId}${companyCashbackPath.OFFER}/${offerId}${companyCashbackPath.CASHBACK}`,
                {
                    ...props,
                },
            );
            return res.status;
        } catch (error) {
            console.log(error);
        }
    },

    updateCashback: async ({
        companyId,
        taskId,
        offerId,
        cashbackId,
        ...props
    }: UpdateCashbackPropsType): Promise<StatusResponseType> => {
        try {
            const res = await instance.patch(
                `${companyCashbackPath.COMPANY}/${companyId}${companyCashbackPath.TASK}/${taskId}${companyCashbackPath.OFFER}/${offerId}${companyCashbackPath.CASHBACK}/${cashbackId}`,
                {
                    ...props,
                },
            );
            return res.status;
        } catch (error) {
            console.log(error);
        }
    },

    getCashback: async ({
        companyId,
        ...props
    }: GetCashbackPropsType): Promise<GetCompanyCashbackResponseType> => {
        try {
            const res = await instance.get(
                `${companyCashbackPath.COMPANY}/${companyId}${companyCashbackPath.CASHBACK}`,
                {
                    params: {
                        ...props,
                    },
                },
            );
            return res.data;
        } catch (error) {
            console.log(error);
        }
    },
};
