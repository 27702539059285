import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { useAppDispatch } from '../../store';
import { getAllTasks, resetTasksList, selectClientTask } from '../../store/slices/clientTaskSlice';
import { getAllUsers } from '../../store/slices/staffSlice';
import {
    TaskСloseReasonFilter,
    bankGuaranteeTaskFilterNames,
    federalLawsTaskFilterNames,
} from '../../utils/data';
import {
    formatDateString,
    formatNum,
    getFirstDayOfMonth,
    strWithDateToIsoStr,
} from '../../utils/functions';
import { userSelector } from '../../store/slices/userSlice';
import UserFilter from '../../components/Common/ManagerAndExecutorFilter/ManagerAndExecutorFilter';
import RequestsTable from './ClosedRequestsTable/ClosedRequestsTable';
import RadionButtonFilter from '../../components/Common/RadionButtonFilter/RadionButtonFilter';
import ClosedRequestsFilter from './ClosedRequestsFilter/ClosedRequestsFilter';
import StyledActiveRequestsWrapper from './ClosedRequests.style';
import ClosedRequestsProductFilter from './ClosedRequestsProductFilter/ClosedRequestsProductFilter';
import { getAllCredentials, selectPartner } from '../../store/slices/partnerSlice';
import { ClosedRequestsFilterFieldENUM } from '../../utils/consts';

type dateRangesType = 'closeDateFrom' | 'closeDateTo';

const dateRanges: dateRangesType[] = ['closeDateFrom', 'closeDateTo'];

type SearchParamsFieldNames =
    | 'manager'
    | 'executor'
    | 'closeReason'
    | 'federalLaw'
    | 'bankGuaranteeType'
    | 'closeDateFrom'
    | 'closeDateTo'
    | 'type'
    | 'lkOperator';

export type ClosedRequestsFilterParams = {
    manager: string;
    executor: string;
    closeReason: string;
    federalLaw: federalLawsTaskFilterNames;
    bankGuaranteeType: bankGuaranteeTaskFilterNames;
    closeDateFrom: string;
    closeDateTo: string;
    type: string;
    lkOperator: string;
};

const defaultFilterParams: ClosedRequestsFilterParams = {
    manager: '',
    executor: '',
    closeReason: '',
    federalLaw: '',
    bankGuaranteeType: '',
    closeDateFrom: formatDateString(getFirstDayOfMonth()),
    closeDateTo: formatDateString(new Date()),
    lkOperator: '',
    type: '',
};

const ActiveRequests = () => {
    const firstPageUpdate = useRef(true);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();

    const { user } = useSelector(userSelector);
    const { credentialsList } = useSelector(selectPartner);
    const { clientTasksCount, clientTasksAmount, loading } = useSelector(selectClientTask);
    const [filter, setFilter] = useState<ClosedRequestsFilterParams>(defaultFilterParams);

    const [isFilterRestoredAfterReload, setFilterRestoredAfterReload] = useState(false);
    const promiseRef = useRef<{ abort: () => void } | undefined>();

    const handleFilter = () => {
        const managerId = Number(filter.manager) || undefined;
        const executorId = Number(filter.executor) || undefined;
        const closeReason = (filter.closeReason as TaskСloseReasonFilter) || undefined;
        const federalLaw = filter.federalLaw || undefined;
        const bankGuaranteeType = filter.bankGuaranteeType || undefined;
        const closeDateFrom = strWithDateToIsoStr(filter.closeDateFrom);
        const closeDateTo = strWithDateToIsoStr(filter.closeDateTo);
        const type = filter.type || undefined;
        const lkOperator = filter.lkOperator || undefined;
        const searchParams = {
            managerId,
            executorId,
            closeReason,
            federalLaw,
            bankGuaranteeType,
            closeDateFrom,
            closeDateTo,
            type,
            lkOperator,
        };
        promiseRef.current?.abort();
        promiseRef.current = dispatch(
            getAllTasks({
                ...searchParams,
                activityType: 'inactive',
            }),
        );
    };

    const filterChangeHandler = (value: string, field: string) => {
        let actualFederalLawFilter = filter.federalLaw;
        let actualBankGuaranteeTypeFilter = filter.bankGuaranteeType;
        let actualLkOperatorFilter = filter.lkOperator;
        if (field === ClosedRequestsFilterFieldENUM.TYPE && filter[field] !== value) {
            actualFederalLawFilter = '';
            actualBankGuaranteeTypeFilter = '';
            actualLkOperatorFilter = '';
        }
        setFilter({
            ...filter,
            federalLaw: actualFederalLawFilter,
            bankGuaranteeType: actualBankGuaranteeTypeFilter,
            lkOperator: actualLkOperatorFilter,
            [field]: value,
        });
    };

    const serializeSearchParams = (obj: ClosedRequestsFilterParams) => {
        const str: string[] = [];
        Object.getOwnPropertyNames(obj).forEach((key) => {
            if (key in obj && obj[key as SearchParamsFieldNames]) {
                const encodedKey = encodeURIComponent(key);
                const encodedValue = encodeURIComponent(obj[key as SearchParamsFieldNames]);
                str.push(`${encodedKey}=${encodedValue}`);
            }
        });
        return str.join('&');
    };

    const saveFilter = () => {
        const query = serializeSearchParams({
            ...filter,
        });
        navigate({
            ...location,
            search: query,
        });
    };

    const closeReasonFilterHandler = (value: string) => {
        setFilter({
            ...filter,
            closeReason: value,
        });
    };

    const changeDateHandler = (value: string, dateRange: dateRangesType) => {
        setFilter({
            ...filter,
            [dateRange]: value,
        });
    };

    useEffect(() => {
        if (firstPageUpdate.current) {
            firstPageUpdate.current = false;
            return;
        }

        saveFilter();
        handleFilter();

        if (!isFilterRestoredAfterReload && queryString.parse(location.search)) {
            setFilterRestoredAfterReload(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    useEffect(() => {
        dispatch(resetTasksList());
        dispatch(getAllCredentials({}));
        const queryStringParams = queryString.parse(location.search);

        if (!queryStringParams) return;

        const manager = filter.manager;
        const executor = filter.executor;
        const closeReason = filter.closeReason;
        const federalLaw = filter.federalLaw;
        const bankGuaranteeType = filter.bankGuaranteeType;
        const closeDateFrom = (queryStringParams?.closeDateFrom as string) || filter.closeDateFrom;
        const closeDateTo = (queryStringParams?.closeDateTo as string) || filter.closeDateTo;
        const type = (queryStringParams?.type as string) || filter.type;
        const lkOperator = (queryStringParams?.lkOperator as string) || filter.lkOperator;
        const newFilter = {
            manager,
            executor,
            closeReason,
            federalLaw,
            bankGuaranteeType,
            closeDateFrom,
            closeDateTo,
            type,
            lkOperator,
        };

        setFilter(newFilter);
        dispatch(getAllUsers({ onlyActiveUsers: true }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const lkOperatorOptions = useMemo(() => {
        const options = [
            {
                name: '',
                label: 'Все',
            },
        ];
        if (filter.type) {
            credentialsList.map((credential) => {
                if (credential.type === filter.type) {
                    options.push({
                        name: credential.lkOperator,
                        label: credential.lkOperator,
                    });
                }
            });
        }
        return options;
    }, [credentialsList, filter.type]);

    const disabledFilters = useMemo(() => {
        const result = {
            lkOperator: true,
            federalLaw: true,
            bankGuaranteeType: true,
        };

        if (filter.type === 'loan') {
            result.lkOperator = false;
        }
        if (filter.type === 'bankGuarantee') {
            result.lkOperator = false;
            result.federalLaw = false;
            result.bankGuaranteeType = false;
        }
        return result;
    }, [filter.type]);

    const disabledAllFilters = loading.getAllTasks;

    return (
        <StyledActiveRequestsWrapper>
            <div className="closed-requests__heading">
                <h5>Завершенные заявки</h5>
            </div>
            <div className="closed-requests__filter-with-statistic">
                <div className="closed-requests__user-filter">
                    {user?.isAdmin || user?.positionName === 'PFM' ? (
                        <UserFilter
                            filterChangeHandler={filterChangeHandler}
                            filterParams={filter}
                            disabledValue={user?.fullName || ''}
                            disabledFilter={!user?.isAdmin ? 'manager' : null}
                            disabledAllFilters={disabledAllFilters}
                        />
                    ) : null}
                    <ClosedRequestsProductFilter
                        filterValue={filter.type}
                        filterChangeHandler={filterChangeHandler}
                        disabledFilters={disabledAllFilters}
                    />
                </div>
                <div className="closed-requests__statistic">
                    <p>
                        Кол-во: <span>{formatNum(String(clientTasksCount))}</span>
                    </p>
                    <p>
                        Сумма БКВ: <span>{formatNum(clientTasksAmount?.toFixed(2) || '')}</span>
                    </p>
                </div>
            </div>
            <div className="closed-requests__container-for-filter">
                <ClosedRequestsFilter
                    disabledFilters={disabledFilters}
                    disabledAllFilters={disabledAllFilters}
                    credetialsFilterParams={lkOperatorOptions}
                    filterChangeHandler={filterChangeHandler}
                    filterParams={filter}
                />
            </div>
            <div className="closed-requests__container-for-filter">
                {dateRanges.map((dateRange) => (
                    <div key={dateRange} className="closed-requests__date-filter">
                        <p>{dateRange === 'closeDateFrom' ? 'с' : 'по'}</p>
                        <input
                            type="date"
                            value={filter[dateRange]}
                            onChange={(e) => changeDateHandler(e.target.value, dateRange)}
                        />
                    </div>
                ))}
                <RadionButtonFilter
                    filterChangeHandler={closeReasonFilterHandler}
                    filterParams={filter}
                    filterType="task"
                    disabledFilter={disabledAllFilters}
                />
            </div>
            <RequestsTable />
        </StyledActiveRequestsWrapper>
    );
};

export default ActiveRequests;
