import styled from 'styled-components/macro';

const StyledActiveRequestsWrapper = styled.div`
    font-size: 12px;

    .closed-requests__heading {
        padding: 8px 7px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        background: #959595;

        h5 {
            font-weight: 600;
            font-size: 14px;
        }
    }
    .closed-requests__user-filter {
        display: flex;
    }

    .closed-requests__count {
        padding: 8px 7px;
        background-color: #fdd55b;
    }

    .closed-requests__container-for-filter {
        display: flex;
        align-items: center;
        margin-left: 10px;
        label {
            font-style: normal;
        }
    }

    .closed-requests__date-filter {
        display: flex;
        align-items: center;

        input {
            width: 120px;
            height: 24px;
            padding: 5px;
            margin: 0 10px;
            border: 1px solid #959595;
            border-radius: 5px;
            cursor: pointer;
        }
    }

    .closed-requests__filter-with-statistic,
    .closed-requests__statistic {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 10px;
    }

    .closed-requests__statistic {
        margin-right: 80px;

        p {
            margin-right: 20px;
        }

        span {
            font-size: 14px;
            font-weight: 600;
        }
    }
`;

export default StyledActiveRequestsWrapper;
