import React from 'react';
import { useSelector } from 'react-redux';

import { selectClientDocument } from '../../../../store/slices/clientDocumentSlice';
import CategoryItem from './TaskDocsItem/TaskDocsItem';
import StyledTaskDocumentSection from './TaskDocumentSection.style';

const TaskDocumentSection = () => {
    const { companyTasks } = useSelector(selectClientDocument);

    return (
        <StyledTaskDocumentSection>
            <div className="base-documents__header">
                <p>Документы к заявкам</p>
            </div>
            {companyTasks.map((task) => (
                <CategoryItem key={task.taskId} task={task} />
            ))}
        </StyledTaskDocumentSection>
    );
};

export default TaskDocumentSection;
