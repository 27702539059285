import React from 'react';

import { ClientTaskInterface, TaskProductENUM } from '../../../../../utils/types';
import StyledClientRequests from './ClientRequestsTable.style';
import ClientRequestsTableHeadings from '../ClientRequestsTableHeadings/ClientRequestsTableHeadings';
import ClientRequestsTableRow from '../ClientRequestsTableRow/ClientRequestsTableRow';

type ClientRequestsTableProps = {
    companyId: number;
    type: TaskProductENUM;
    clientTasksTableColumns: string[];
    tasks: ClientTaskInterface[];
};

const ClientRequestsTable = ({
    companyId,
    type,
    clientTasksTableColumns,
    tasks,
}: ClientRequestsTableProps) => {
    const isLoan = type === TaskProductENUM.LOAN;
    return (
        <StyledClientRequests>
            {tasks.length ? (
                <>
                    <ClientRequestsTableHeadings
                        clientTasksTableColumns={clientTasksTableColumns}
                    />
                    {tasks.map((task, index) => (
                        <ClientRequestsTableRow
                            key={task.taskId}
                            task={task}
                            index={index}
                            companyId={companyId}
                            isLoan={isLoan}
                        />
                    ))}
                </>
            ) : (
                <h2 className="clients-requests-table__without-tasks">Нет заявок</h2>
            )}
        </StyledClientRequests>
    );
};

export default ClientRequestsTable;
