import styled from 'styled-components/macro';

const StyledContactItem = styled.div`
    .contact-item__container {
        font-weight: 500;
        font-size: 12px;
        padding: 5px 10px;
        display: grid;
        grid-template-columns: 1fr 52px;
        grid-column-gap: 15px;
        min-height: 60px;
        background: #fff;
        border-radius: 5px;
        margin-bottom: 10px;
    }

    .contact-item__red-highlight {
        background: rgba(255, 53, 53, 0.4);
    }

    .contact-item__green-highlight {
        background: #dcffe8;
    }

    .contact-item__info {
        line-height: 17px;
    }

    .contact-item__actions {
        display: flex;
        justify-content: space-between;
        align-items: end;
        img {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
    }
    .contact-item__phnone {
        display: flex;
    }
    .contact-item__disabled-phnone {
        opacity: 0.5;
    }
    .contact-item__phnone-number {
        padding-left: 7px;
    }
    .contact-item__phnone-logo {
        padding: 0 2px;
    }
`;

export default StyledContactItem;
