import styled from 'styled-components/macro';

const StyledCell = styled.div`
    font-weight: 300;
    font-size: 12px;
    margin-bottom: 10px;
    display: flex;
    justify-content: end;

    .requests-table__green-field {
        color: #00892f;
    }
`;

export default StyledCell;
