import React from 'react';

import { DocumentCategoryInterface } from '../../../utils/types';
import CategoryItem from '../AddDocumentCategory/CategoryItem/CategoryItem';
import StyledContainer from './EditOrDeleteCategory.style';

type EditOrDeleteCategoryProps = {
    // eslint-disable-next-line no-unused-vars
    previousStep: () => void;
    companyId: number;
    categories: DocumentCategoryInterface[];
};

const EditOrDeleteCategory = ({
    previousStep,
    companyId,
    categories,
}: EditOrDeleteCategoryProps) => {
    return (
        <StyledContainer>
            <h3 className="edit-or-delete-category__category-name">
                Редактировать/удалить разделы
            </h3>
            {categories.map((category) => (
                <CategoryItem
                    key={category.companyDocumentCategoryId}
                    companyId={companyId}
                    category={category}
                />
            ))}
            <div className="edit-or-delete-category__actions">
                <button type="button" onClick={previousStep}>
                    Назад
                </button>
            </div>
        </StyledContainer>
    );
};

export default EditOrDeleteCategory;
