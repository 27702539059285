import React, { useMemo } from 'react';

import StyledComponent from './EmptyList.style';

type EmptyListProps = {
    header?: string;
    description?: string;
};

const EmptyList = ({ header, description }: EmptyListProps) => {
    const headerText = useMemo(() => (header ? header : 'Ничего не нашлось'), [header]);
    const descriptionText = useMemo(
        () => (description ? description : 'Попробуйте изменить параметры фильтрации'),
        [description],
    );
    return (
        <StyledComponent>
            <div className="empty-list">
                <h3>{headerText}</h3>
                <div>{descriptionText}</div>
            </div>
        </StyledComponent>
    );
};

export default EmptyList;
