/* eslint-disable no-unused-vars */
import { FieldErrorsImpl, UseFormRegister } from 'react-hook-form';

import { FieldNamesInAddingTaskType, TaskInputFieldsType } from '../../../utils/data';
import { ClientTaskInterface, IUser } from '../../../utils/types';
import { AddTaskInputs } from '../AddBankTask';

export type AddBankTaskInputProps = {
    task: ClientTaskInterface | null;
    field: TaskInputFieldsType;
    errors: Partial<FieldErrorsImpl<AddTaskInputs>>;
    isIncludingEndDate: boolean;
    federalLaw: string;
    allUsers: IUser[];
    register: UseFormRegister<AddTaskInputs>;
    setIncludingEndDate: (value: boolean) => void;
    changNumericFieldHandler: (value: string, field: FieldNamesInAddingTaskType) => void;
    dateChangeHandler: (value: string) => void;
};

export type AddBankTaskSelectFields =
    | 'executorId'
    | 'federalLaw'
    | 'bankGuaranteeType'
    | 'loanType';

export enum AddBankFieldsENUM {
    EXECUTOR_ID = 'executorId',
    FEDERAL_LAW = 'federalLaw',
    BANK_GUARANTEE_TYPE = 'bankGuaranteeType',
    LOAN_TYPE = 'loanType',
    CONTRACT_CONCLUSION_DATE = 'contractConclusionDate',
    BANK_GUARANTEE_TERM_TO = 'bankGuaranteeTermTo',
    BANK_GUARANTEE_TERM_FROM = 'bankGuaranteeTermFrom',
}
