import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import EditClientsInfo from '../AddClient/AddClientsInfo/AddClientsInfo';
import CommonModal from '../Common/CommonModal/CommonModal';
import { CompanyInterface } from '../../utils/types';
import { selectClientDatabase } from '../../store/slices/clientDatabaseSlice';
import StyledFormWrapper from './EditClient.style';

type EditClientProps = {
    close: () => void;
    width: number;
    company: CompanyInterface;
};

const EditClient = ({ close, width, company }: EditClientProps) => {
    const { loading, areCompaniesUpdated } = useSelector(selectClientDatabase);
    const [isCompanyUpdated, setCompanyUpdated] = useState(false);

    useEffect(() => {
        if (loading.updateCompany) {
            setCompanyUpdated(true);
            return;
        }
        if (isCompanyUpdated && areCompaniesUpdated) {
            close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading.updateCompany]);

    return (
        <CommonModal hasCloseBtn={true} close={close}>
            <StyledFormWrapper width={width}>
                <EditClientsInfo nextStep={close} inn={String(company.inn)} company={company} />
            </StyledFormWrapper>
        </CommonModal>
    );
};

export default EditClient;
