import styled from 'styled-components/macro';
import PhoneInput from 'react-phone-input-2';
import Scrollbars from 'rc-scrollbars';

const StyledContainer = styled.div`
    height: 90vh;
    max-height: 780px;
    margin-left: 6px;
    font-size: 12px;
    font-weight: 300;
    line-height: 17px;

    form {
        margin-right: 6px;
        display: flex;
        flex-direction: column;
        height: calc(100% - 38px);
    }

    .add-user__description {
        font-style: italic;
        font-weight: 300;
        font-size: 10px;
        line-height: 14px;
        color: #000;
        margin: 8px 0 15px;
    }

    .add-user__sign-of-obligatory-field {
        color: #ff0000;
    }

    .add-user__comment {
        height: 121px;
        padding: 5px 10px;
    }

    input,
    select {
        margin: 5px 0;
        padding-left: 10px;
        &:focus {
            border: 2px solid #005fcc;
        }
        &:disabled {
            background: #fff;
        }
    }

    .add-user__error-input {
        border-color: #f00;
    }

    .add-user__label {
        line-height: 17px;
    }

    .add-user__btn {
        margin: 12px 0 10px;
        text-align: initial;
        justify-self: end;
        width: fit-content;
    }

    .add-user__switcher {
        display: flex;
        justify-content: space-between;
        margin: 12px 0 6px;
    }

    .add-user__login-password {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;
    }

    .add-user__generator {
        padding-top: 6px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        button:disabled {
            opacity: 0.6;
            cursor: auto;
        }
    }

    .add-user__document {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 17px;
        margin: 5px 0;
        button {
            font-weight: 300;
            text-decoration: underline;
        }
    }

    .add-user__doc-name {
        display: grid;
        grid-template-columns: 1fr 20px;
        grid-column-gap: 10px;
        align-items: center;
        line-height: 17px;
        margin: 5px 0;
        img {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }

        p {
            word-break: break-word;
            display: -webkit-box;
            line-height: 17px;
            height: 17px;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            font-style: italic;
        }
    }

    .add-user__error-document {
        color: #f00;
    }

    .add-user__disabled-btn {
        margin: 0;
    }

    .add-user__password-rules {
        font-size: 10px;
        color: #492;
    }

    .add-user__password-disabled {
        display: none;
    }

    .add-user__doc-link {
        text-decoration: underline;
        color: #0038ff;
        cursor: pointer;
    }
`;

const StyledPhoneInput = styled(PhoneInput)`
    margin: 5px 0;
    .form-control {
        width: 100%;
        height: 24px;
        border: 1px solid #000;
    }
    .flag-dropdown {
        border: 1px solid #000;
    }
`;

const StyledScrollbars = styled(Scrollbars)`
    .rc-scrollbars-track.rc-scrollbars-track-v {
        right: -4px !important;
        top: 6px !important;
    }
`;

const StyledFormWrapper = styled.div<{ width: number }>`
    width: ${({ width }) => `${width}px`};
    input,
    textarea {
        margin-top: 5px;
        width: 100%;
        height: 24px;
        border: 1px solid #000;
        border-radius: 5px;
        padding-left: 10px;
    }

    h3 {
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 13px;
    }

    button {
        border: none;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #0038ff;
        padding: 0;
    }
`;

export { StyledContainer, StyledPhoneInput, StyledScrollbars, StyledFormWrapper };
