import styled from 'styled-components/macro';

const StyledClientsTable = styled.div`
    font-weight: 400;
    font-size: 12px;
    display: grid;
    grid-template-columns: repeat(11, minmax(105px, 1fr));
    align-items: center;
    background: #f9f9f9;

    .status-line__status {
        color: #959595;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 30px;
        padding: 5px;
    }

    .status-line__passed-status {
        background: #ffca00;
        color: #000;
    }
`;

export default StyledClientsTable;
