import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../store';
import { OfferInterface, RequestInterface } from '../../utils/types';
import {
    createTaskOfferRequest,
    selectClientTask,
    updateTaskOfferRequest,
} from '../../store/slices/clientTaskSlice';
import TextEditor from '../Common/TextEditor/TextEditor';
import CheckIcon from '../../assets/images/tasks/green-checked-icon.png';
import CommonModal from '../Common/CommonModal/CommonModal';
import DefaultLoader from '../Common/BtnLoader/DefaultLoader';
import ClosedRequest from './ClosedOfferRequests/ClosedOfferRequests';
import StyledForm from './AddRequestToTaskOffer.style';

type AddRequestToTaskOfferProps = {
    close: () => void;
    width: number;
    companyId: number;
    editingOffer: OfferInterface;
    taskId: number;
};

const AddRequestToTaskOffer = ({
    close,
    width,
    companyId,
    editingOffer,
    taskId,
}: AddRequestToTaskOfferProps) => {
    const dispatch = useAppDispatch();
    const { loading } = useSelector(selectClientTask);
    const [editorContent, setEditorContent] = useState('');

    const isContentEmpty = !editorContent || editorContent.startsWith('<p></p>');
    const closedRequests = editingOffer.requests.filter((request) => !request.isActive);
    const requestNumber = closedRequests.length + 1;
    const initialText =
        editingOffer.requests?.[0]?.isActive && editingOffer.requests?.[0]?.text
            ? editingOffer.requests?.[0]?.text
            : '';

    const editingRequest: RequestInterface | undefined = editingOffer.requests?.[0];
    const isEditing = editingRequest?.isActive;
    const header = `${isEditing ? 'Редактировать' : 'Создать'} запрос № ${requestNumber}`;

    const saveBtnHandler = () => {
        if (!(editorContent && companyId)) return;

        if (editingRequest?.isActive) {
            dispatch(
                updateTaskOfferRequest({
                    companyId,
                    taskId,
                    offerId: editingOffer.taskOfferId,
                    requestId: editingRequest.taskOfferRequestId,
                    text: editorContent,
                }),
            );
            return;
        }

        dispatch(
            createTaskOfferRequest({
                companyId,
                taskId,
                offerId: editingOffer.taskOfferId,
                text: editorContent,
            }),
        );
    };

    const doneBtnHandler = () => {
        if (!(editorContent && companyId && editingRequest)) return;

        dispatch(
            updateTaskOfferRequest({
                companyId,
                taskId,
                offerId: editingOffer.taskOfferId,
                requestId: editingRequest.taskOfferRequestId,
                isActive: false,
            }),
        );
    };

    useEffect(() => {
        const workAreaEditor = document.querySelector('.public-DraftEditor-content');
        if (!workAreaEditor) return;
        workAreaEditor.addEventListener('keyup', (event) => {
            if ('code' in event) {
                if (event.code !== 'Enter') return;
                const newElement = workAreaEditor.lastChild?.lastChild as HTMLElement;
                if (!newElement) return;
                newElement.scrollIntoView();
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const initialText = editingOffer.requests?.[0]?.isActive
            ? editingOffer.requests?.[0]?.text
            : '';

        if (initialText) {
            setEditorContent(initialText);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editingOffer]);

    return (
        <CommonModal hasCloseBtn={true} close={close}>
            <StyledForm width={width}>
                <h3 className="add-comment__header">{header}</h3>
                <TextEditor setEditorContent={setEditorContent} editingComment={initialText} />
                <div className="add-comment__actions">
                    <button
                        className="add-comment__create-btn"
                        type="button"
                        onClick={saveBtnHandler}
                        disabled={isContentEmpty}
                    >
                        {loading.request ? <DefaultLoader /> : 'Сохранить'}
                    </button>
                    {isEditing ? (
                        <button
                            className="add-comment__close-btn"
                            type="button"
                            onClick={doneBtnHandler}
                            disabled={isContentEmpty}
                        >
                            {loading.request ? (
                                <DefaultLoader />
                            ) : (
                                <>
                                    <img src={CheckIcon} />
                                    Запрос отработан
                                </>
                            )}
                        </button>
                    ) : null}
                </div>
            </StyledForm>
            <ClosedRequest closedRequests={closedRequests} />
        </CommonModal>
    );
};

export default AddRequestToTaskOffer;
