import axios, { AxiosRequestConfig, AxiosResponse, AxiosError, AxiosRequestHeaders } from 'axios';
import { toast } from 'react-hot-toast';

import { AuthResponse } from '../utils/types';
import { baseURL } from '../utils/consts';
import { getItem, setTokens } from '../utils/functions';
import { logoutUser } from '../store/slices/userSlice';
import store from '../store';

const authorize = async () => {
    const token = getItem('refreshToken') as string;
    const deviceId = getItem('deviceId');
    const response = await axios.post<AuthResponse>(`${baseURL}/auth/refresh-token`, {
        token,
        deviceId,
    });

    return response.data?.data?.tokens;
};

const instance = axios.create({
    baseURL,
});

instance.interceptors.request.use((config: AxiosRequestConfig) => {
    if (config.headers === undefined) {
        config.headers = {};
    }
    (config.headers as AxiosRequestHeaders & { authorization: string }).authorization = getItem(
        'accessToken',
    ) as string;

    return config;
});

type AuthPromiseType = Promise<{
    authorization: string;
    refresh: string;
}>;
let authPromise: AuthPromiseType | null = null;

instance.interceptors.response.use(
    (config: AxiosResponse) => {
        return config;
    },
    async (error: AxiosError) => {
        const originalRequest = error.config as AxiosError['config'] & {
            _isRetry: boolean;
        };
        if (error.message === 'Network Error') {
            toast.error(error.message);
        }
        const errorResponse = error.response as AxiosError['response'];
        if (errorResponse?.status === 413 || errorResponse?.status === 400) {
            toast.error((errorResponse as any).data.message);
        }
        if (errorResponse?.status === 401 && error.config && !originalRequest._isRetry) {
            originalRequest._isRetry = true;
            try {
                if (!authPromise) {
                    authPromise = authorize();
                }

                const token = await authPromise;

                if (!token) {
                    throw new Error('Authentication failed');
                }
                setTokens(token);
                authPromise = null;
                return instance(originalRequest);
            } catch (e) {
                console.log('Not authorized');
                authPromise = null;
                store.dispatch(logoutUser);
            }
        }
        throw error;
    },
);

export default instance;
