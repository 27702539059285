import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { selectClientDatabase, updateCompany } from '../../store/slices/clientDatabaseSlice';
import { useAppDispatch } from '../../store';
import { getAllUsers, selectStaff } from '../../store/slices/staffSlice';
import { getManagersList } from '../../utils/functions';
import CommonModal from '../Common/CommonModal/CommonModal';
import CustomSelect from '../Common/CustomSelect/CustomSelect';
import DefaultLoader from '../Common/BtnLoader/DefaultLoader';
import StyledWrapper from './AssignManagerForCompany.style';

type AssignManagerProps = {
    close: () => void;
    width: number;
    clientId: number;
    currentManager: number;
};

const AssignManager = ({ close, width, clientId, currentManager }: AssignManagerProps) => {
    const dispatch = useAppDispatch();
    const { allUsers } = useSelector(selectStaff);
    const [isManagerAssigned, setManagerAssigned] = useState(false);
    const { loading, areCompaniesUpdated } = useSelector(selectClientDatabase);
    const [selectedManager, setSelectedManager] = useState(currentManager);

    const managers = [{ fullName: 'нет', userId: 0 }, ...getManagersList(allUsers)];

    const addManagerHandler = () => {
        if (!selectedManager) return;
        dispatch(
            updateCompany({
                clientId,
                managerId: String(selectedManager),
            }),
        );
    };

    const managerSelection = (value: string) => {
        setSelectedManager(Number(value));
    };

    useEffect(() => {
        dispatch(getAllUsers({ onlyActiveUsers: true }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (loading.updateCompany) {
            setManagerAssigned(true);
            return;
        }
        if (isManagerAssigned && areCompaniesUpdated) {
            close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading.updateCompany]);

    return (
        <CommonModal hasCloseBtn={true} close={close}>
            <StyledWrapper width={width}>
                <h3 className="assign-manager__header">Назначить менеджера</h3>
                <CustomSelect
                    width={262}
                    height={20}
                    backgroundImage="none"
                    backgroundColor="#f9f9f9"
                >
                    <select
                        value={selectedManager}
                        onChange={(e) => managerSelection(e.target.value)}
                    >
                        {managers.map((item) => (
                            <option key={item.userId} value={item.userId}>
                                {item.fullName}
                            </option>
                        ))}
                    </select>
                </CustomSelect>
                <button
                    className={clsx('assign-manager__btn', {
                        'assign-manager__disabled-btn': loading.updateCompany,
                    })}
                    onClick={addManagerHandler}
                    disabled={loading.updateCompany}
                >
                    {loading.updateCompany ? <DefaultLoader /> : 'Сохранить'}
                </button>
            </StyledWrapper>
        </CommonModal>
    );
};

export default AssignManager;
