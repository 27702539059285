import { setTokens } from './../../../utils/functions';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { RootState } from '../..';
import { authAPI } from '../../../api/authApi/authApi';
import { IUser } from '../../../utils/types';
import { InitialStateType } from './types';
import { getError } from '../../../utils/functions';
import { LoginPropsType } from '../../../api/authApi/authApi.types';

export const loginUser = createAsyncThunk<
    IUser,
    LoginPropsType,
    {
        rejectValue: string;
    }
>('users/login', async ({ ...props }, { rejectWithValue }) => {
    try {
        const res = await authAPI.login({ ...props });
        if (res?.data?.data?.tokens) {
            setTokens(res?.data?.data?.tokens);
        } else {
            throw new Error('Ошибка авторизации');
        }
        return res?.data?.data?.user as IUser;
    } catch (e) {
        let error: AxiosError = e as AxiosError;
        let message = error.message;
        localStorage.clear();
        return rejectWithValue(getError(message));
    }
});

export const logoutUser = createAsyncThunk<
    void,
    void,
    {
        rejectValue: string;
    }
>('users/logout', async (_, { rejectWithValue }) => {
    try {
        localStorage.clear();
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const authMe = createAsyncThunk('user/authMe', async (_, { rejectWithValue }) => {
    try {
        const res = await authAPI.authMe();
        if (!res?.data) return null;
        return res?.data.user;
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

const initialState: InitialStateType = {
    user: null,
    allUsers: [],
    isAuth: false,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isFetching = false;
            return state;
        },
        setUser: (state, action: PayloadAction<IUser>) => {
            state.user = action.payload;
            state.isAuth = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loginUser.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(loginUser.fulfilled, (state, { payload }) => {
            state.user = payload;
            state.isFetching = false;
            state.isSuccess = true;
            state.isAuth = true;
            return state;
        });
        builder.addCase(loginUser.rejected, (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload as string;
        });
        builder.addCase(logoutUser.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(logoutUser.fulfilled, (state) => {
            state.isFetching = false;
            state.user = initialState.user;
            state.isAuth = false;
        });
        builder.addCase(logoutUser.rejected, (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload as string;
        });
    },
});

export const userSelector = (state: RootState) => state.user;

export const { clearState, setUser } = userSlice.actions;
