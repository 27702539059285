import React from 'react';
import { clsx } from 'clsx';
import {
    getDate,
    normalizeCashbackStatus,
    getTooltipLength,
    getTaskNumber,
    getAmountInRub,
} from '../../../../utils/functions';
import { CashbackInterface, CompanyInterface, IUser } from '../../../../utils/types';
import InfoIcon from '../../../../assets/images/partners/additional-info-icon.png';
import EditIcon from '../../../../assets/images/tasks/edit-offer-icon.png';
import TooltipReminder from '../../../../components/Common/TooltipReminder/TooltipReminder';
import EmptyList from '../../../../components/EmptyList/EmptyList';
import StyledDiv from './CashbackList.style';

type cashbackListProps = {
    cashbackListArray: CashbackInterface[];
    user: IUser | null;
    // eslint-disable-next-line no-unused-vars
    setSelectedCashback: (value: CashbackInterface) => void;
    isStatisticPage?: boolean;
};

const CashbackList = ({
    cashbackListArray,
    user,
    setSelectedCashback,
    isStatisticPage,
}: cashbackListProps) => {
    const highlightedNumber = isStatisticPage ? 0 : 1;
    const productField = isStatisticPage ? 'БГ' : 'Банковская гарантия';

    const getLinkForTask = (cashback: CashbackInterface) => {
        const taskId = cashback.offer.task?.taskId;
        const companyId = cashback.company.companyId;

        if (!(companyId && taskId)) return '';

        return `/clients/${companyId}/task/${taskId}`;
    };

    const checkRightForLinkToCard = (
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        company?: CompanyInterface,
    ) => {
        const adminOrManager = user?.isAdmin || company?.manager?.userId === user?.userId;

        if (!adminOrManager) {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    const getLinkForCompany = (cashback: CashbackInterface) => {
        const companyId = cashback.company.companyId;

        if (!companyId) return '';

        return `/clients/card/${companyId}?tab=clientCard`;
    };

    return !cashbackListArray.length ? (
        <EmptyList />
    ) : (
        <StyledDiv>
            {cashbackListArray.map((cashback, index) => (
                <div
                    key={cashback.cashbackId}
                    className={clsx('cashback-list__row', {
                        'cashback-list__statistic-row': isStatisticPage,
                        'cashback-list__even-row': index % 2 !== highlightedNumber,
                    })}
                >
                    <p>
                        {getDate(cashback.offer.task?.closeDate || '', 'date with time') || '---'}
                    </p>
                    <a
                        className="cashback-list__number"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={getLinkForTask(cashback)}
                    >
                        {getTaskNumber(cashback.offer.task)}
                    </a>
                    {isStatisticPage && (
                        <a
                            className={clsx('cashback-list__number', {
                                'cashback-list__disabled-link':
                                    !user?.isAdmin &&
                                    cashback?.company?.manager?.userId !== user?.userId,
                            })}
                            onClick={(e) => checkRightForLinkToCard(e, cashback.company)}
                            href={getLinkForCompany(cashback)}
                        >
                            {cashback.company.name}
                        </a>
                    )}
                    <p>{productField}</p>
                    <div className="cashback-list__right-position">
                        <p>{getAmountInRub(cashback?.cashbackSum)}</p>
                    </div>
                    <p>{normalizeCashbackStatus(cashback.status)}</p>
                    <p>{getDate(cashback.paymentDate, 'date with time', true) || '---'}</p>
                    <p>{cashback.receiverName || '---'}</p>
                    <div
                        className={clsx('cashback-list__icons', {
                            'cashback-list__manger-view-icon': !user?.isAdmin,
                        })}
                    >
                        {cashback.importantInfo ? (
                            <TooltipReminder
                                text={cashback.importantInfo || ''}
                                hasReminder={Boolean(cashback.importantInfo)}
                                rightMargin={20}
                                maxWidth={800}
                                minWidth={getTooltipLength({
                                    textLength: cashback.importantInfo?.length,
                                    minLetters: 10,
                                    letterWidth: 7,
                                })}
                            >
                                <img src={InfoIcon} />
                            </TooltipReminder>
                        ) : null}
                        {user?.isAdmin ? (
                            <img
                                className="cashback-list__edit-icon"
                                src={EditIcon}
                                onClick={() => setSelectedCashback(cashback)}
                            />
                        ) : null}
                    </div>
                </div>
            ))}
        </StyledDiv>
    );
};

export default CashbackList;
