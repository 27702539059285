import React from 'react';

import { companyCashbackFilterFields } from '../../../utils/data';
import { CashbackStatFilterParams, CashbackStatSearchParamsType } from '../Cashback';
import BulletListIcon from '../../../assets/images/bullet-list-icon.png';
import CustomSelect from '../../../components/Common/CustomSelect/CustomSelect';
import StyledFilterWrapper from './CashbackStatFilter.style';

type dateRangesType = 'dateFrom' | 'dateTo';

const dateRanges: dateRangesType[] = ['dateFrom', 'dateTo'];

type FilterProps = {
    // eslint-disable-next-line no-unused-vars
    filterChangeHandler: (value: string, field: CashbackStatSearchParamsType) => void;
    filterParams: CashbackStatFilterParams;
};

const Filter = ({ filterChangeHandler, filterParams }: FilterProps) => {
    const filterFields = companyCashbackFilterFields;

    const changeStatusHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        filterChangeHandler(e.target.value, 'status');
    };

    const changeDateHandler = (value: string, dateType: dateRangesType) => {
        filterChangeHandler(value, dateType);
    };

    return (
        <StyledFilterWrapper>
            <p className="cashback-stat-filter__label">Выбрать дату сделки:</p>
            {dateRanges.map((dateRange) => (
                <div key={dateRange} className="cashback-stat-filter__date-filter">
                    <p>{dateRange === 'dateFrom' ? 'с' : 'по'}</p>
                    <input
                        type="date"
                        value={filterParams[dateRange]}
                        onChange={(e) => changeDateHandler(e.target.value, dateRange)}
                    />
                </div>
            ))}
            <p className="cashback-stat-filter__label">Статус:</p>
            <CustomSelect width={151} height={24} backgroundImage={BulletListIcon}>
                <select value={filterParams.status} onChange={changeStatusHandler}>
                    {filterFields.map((field) => (
                        <option key={field.value} value={field.value}>
                            {field.label}
                        </option>
                    ))}
                </select>
            </CustomSelect>
        </StyledFilterWrapper>
    );
};

export default Filter;
