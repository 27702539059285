import instance from '../axiosInstance';
import { StatusResponseType } from '../clientDatabaseApi/clientDatabaseApi.types';
import {
    GetAllCallsPropsType,
    GetAllCallsResponseType,
    UpdateCallPropsType,
    callsApiPath,
} from './callsApi.types';

export const callsApi = {
    getAllCalls: async ({ ...props }: GetAllCallsPropsType): Promise<GetAllCallsResponseType> => {
        try {
            const { abortSignal, ...params } = props;

            const response = await instance.get(callsApiPath.CRUD_CALLS, {
                params,
                signal: abortSignal,
            });
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },

    updateCallComment: async ({
        callId,
        ...props
    }: UpdateCallPropsType): Promise<StatusResponseType> => {
        try {
            const res = await instance.patch(`${callsApiPath.CRUD_CALLS}/${callId}`, { ...props });
            return res.status;
        } catch (error) {
            console.log(error);
        }
    },
};
