import styled from 'styled-components/macro';

const StyledFormWrapper = styled.div<{ width: string }>`
    width: ${({ width }) => width};
    input {
        margin-top: 5px;
        width: 100%;
        height: 24px;
        border: 1px solid #000;
        border-radius: 5px;
        padding-left: 10px;
    }

    h3 {
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 13px;
    }

    button {
        border: none;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #0038ff;
        padding: 0;
    }
`;

export default StyledFormWrapper;
