/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';

import StyledContainer from './CallsPlayer.style';
import { CallDirectionENUM, CallsRecordInterface } from '../../../utils/types';
import { formatTimeInSeconds, getDate, normalizePhone } from '../../../utils/functions';

import AudioPlayIcon from '../../../assets/images/calls/audio-play-icon.png';
import AudioPauseIcon from '../../../assets/images/calls/audio-pause-icon.png';
import IncomingCallIcon from '../../../assets/images/calls/incoming-call-icon.png';
import OutgoingCallIcon from '../../../assets/images/calls/outgoing-call-icon.png';

type CallsPlayerProps = {
    callInPlayer: CallsRecordInterface | null;
    audioPlayer: React.RefObject<HTMLAudioElement>;
    isPlayingRecord: boolean;
    setPlayingRecord: (state: boolean) => void;
};

const CallsPlayer = ({
    callInPlayer,
    audioPlayer,
    isPlayingRecord,
    setPlayingRecord,
}: CallsPlayerProps) => {
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);

    useEffect(() => {
        const audio = audioPlayer.current;
        if (!audio) {
            return;
        }
        const handleTimeUpdate = () => {
            setCurrentTime(audio.currentTime);
        };

        const handleDurationChange = () => {
            setDuration(audio.duration);
        };

        const handleEnded = () => {
            setPlayingRecord(false);
        };

        audio.addEventListener('timeupdate', handleTimeUpdate);
        audio.addEventListener('durationchange', handleDurationChange);
        audio.addEventListener('ended', handleEnded);

        return () => {
            audio.removeEventListener('timeupdate', handleTimeUpdate);
            audio.removeEventListener('durationchange', handleDurationChange);
            audio.removeEventListener('ended', handleEnded);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const callPhone = useMemo(() => {
        if (!callInPlayer) {
            return;
        }
        return normalizePhone(callInPlayer.phone || '');
    }, [callInPlayer]);

    const callDate = useMemo(() => {
        if (!callInPlayer) {
            return;
        }
        return getDate(callInPlayer.startedAt, 'date');
    }, [callInPlayer]);

    const callStartTime = useMemo(() => {
        if (!callInPlayer) {
            return;
        }
        return getDate(callInPlayer.startedAt, 'time').slice(0, 6);
    }, [callInPlayer]);

    const directionIcon = useMemo(() => {
        if (!callInPlayer) {
            return;
        }
        return callInPlayer.direction === CallDirectionENUM.IN
            ? IncomingCallIcon
            : OutgoingCallIcon;
    }, [callInPlayer]);

    const durationRatio = useMemo(() => {
        const current = formatTimeInSeconds(Math.round(currentTime)).slice(3);
        const all = formatTimeInSeconds(Math.round(duration)).slice(3);
        return `${current} / ${all}`;
    }, [currentTime, duration]);

    const audioControlIcon = useMemo(() => {
        if (isPlayingRecord) {
            return AudioPauseIcon;
        }
        return AudioPlayIcon;
    }, [isPlayingRecord]);

    const playPauseRecord = () => {
        if (!audioPlayer.current) {
            return;
        }
        if (isPlayingRecord) {
            audioPlayer.current.pause();
            return setPlayingRecord(false);
        }
        audioPlayer.current.play();
        return setPlayingRecord(true);
    };

    const handleTimeSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const audio = audioPlayer.current;
        if (!audio) {
            return;
        }
        const newTime = e.target.value;
        audio.currentTime = +newTime;
        setCurrentTime(+newTime);
    };

    return (
        <StyledContainer>
            <div className="calls-player">
                <div className="calls-player__panel">
                    <div className="calls-player__timeline">
                        <button onClick={playPauseRecord} className="calls-player__button">
                            <img src={audioControlIcon} />
                        </button>
                        <div className="calls-player__duration">{durationRatio}</div>
                        <div style={{ position: 'relative', width: '100%' }}>
                            <div
                                className="calls-player__slider"
                                style={{
                                    right: `calc(${100 - (currentTime / duration) * 100}% - 2px)`,
                                }}
                            ></div>
                            <input
                                type="range"
                                value={currentTime}
                                max={duration}
                                onChange={handleTimeSliderChange}
                            />
                        </div>
                    </div>
                    {callInPlayer ? (
                        <div className="calls-player__info">
                            <img src={directionIcon} />
                            <div>{callPhone}</div>
                            <p>{callDate}</p>
                            <p>{callStartTime}</p>
                        </div>
                    ) : null}
                </div>
                <audio ref={audioPlayer}></audio>
            </div>
        </StyledContainer>
    );
};

export default CallsPlayer;
