import * as Yup from 'yup';

import { onlyNonNegativeFloatChecking, onlyPositiveFloatChecking } from '../utils/functions';

export const bankGuaranteeBaseSchema = {
    procurementProcedureNumber: Yup.string().required('Заполните поле № закупочной процедуры'),
    procurementProcedureLink: Yup.string().required(
        'Заполните поле cсылка на закупочную процедуру',
    ),
    federalLaw: Yup.string().required('Выберите Федеральный закон'),
    bankGuaranteeType: Yup.string().required('Выберите Тип банковской гарантии'),
    bankGuaranteeAmount: Yup.string()
        .required('Заполните поле Сумма банковской гарантии')
        .test(
            'num',
            'Сумма банковской гарантии должна быть неотрицательным числом',
            onlyNonNegativeFloatChecking,
        ),
    bankGuaranteeTermDays: Yup.string().required('Заполните Срок действия банковской гарантии'),
    bankGuaranteeTermFrom: Yup.string(),
    bankGuaranteeTermTo: Yup.string(),
    additComment: Yup.string(),
    procurementProcedureName: Yup.string(),
    contractConclusionDate: Yup.string(),
    contractAdvanceAvailability: Yup.string(),
    executorId: Yup.string(),
};

export const loanBaseSchema = {
    loanType: Yup.string().required('Выберите тип кридита'),
    loanAmount: Yup.string()
        .required('Заполните поле Сумма кредита')
        .test(
            'num',
            'Сумма кредита должна быть неотрицательным числом',
            onlyNonNegativeFloatChecking,
        ),
    loanTermMonths: Yup.string().required('Заполните поле Срок кредита'),
    additComment: Yup.string(),
    executorId: Yup.string(),
};

export const addBankGuaranteeValidationSchema = Yup.object().shape({
    ...bankGuaranteeBaseSchema,
    contractAdvanceAvailability: Yup.string().required(
        'Заполните поле Наличие аванса по контракту',
    ),
    executorId: Yup.string().required('Выберите исполнителя для задачи'),
    postscript: Yup.string(),
});

export const editBankGuaranteeValidationSchema = Yup.object().shape({
    ...bankGuaranteeBaseSchema,
    procurementProcedureName: Yup.string().required('Заполните поле Наименование закупки'),
    initialContractPrice: Yup.string()
        .required('Заполните поле Начальная цена контракта')
        .test(
            'num',
            'Начальная цена контракта быть положительным числом',
            onlyPositiveFloatChecking,
        ),
});

export const addLoanValidationSchema = Yup.object().shape({
    ...loanBaseSchema,
    executorId: Yup.string().required('Выберите исполнителя для задачи'),
    postscript: Yup.string(),
});

export const editLoanValidationSchema = Yup.object().shape(loanBaseSchema);

export const closeTaskValidationSchema = Yup.object().shape({
    closeReason: Yup.string().required('Выберите причину завершения сделки'),
    closeComment: Yup.string(),
});

export const editOfferValidationSchema = Yup.object().shape({
    bankCommission: Yup.string().test(
        'num',
        'Комиссия банка в процентах должна быть неотрицательным числом',
        (val) => !val || onlyNonNegativeFloatChecking(val),
    ),
    baseCommission: Yup.string().test(
        'num',
        'Базовое КВ в процентах должно быть неотрицательным числом',
        (val) => !val || onlyNonNegativeFloatChecking(val),
    ),
    clientCommission: Yup.string().test(
        'num',
        'КВ Клиента в процентах должно быть неотрицательным числом',
        (val) => !val || onlyNonNegativeFloatChecking(val),
    ),
    bankCommissionInRub: Yup.string().test(
        'num',
        'Комиссия банка в рублях должн быть неотрицательным числом',
        (val) => !val || onlyNonNegativeFloatChecking(val),
    ),
    baseCommissionInRub: Yup.string().test(
        'num',
        'Базовое КВ в рублях должно быть неотрицательным числом',
        (val) => !val || onlyNonNegativeFloatChecking(val),
    ),
    clientCommissionInRub: Yup.string().test(
        'num',
        'КВ Клиента в рублях должно быть неотрицательным числом',
        (val) => !val || onlyNonNegativeFloatChecking(val),
    ),
    agentSeparation: Yup.string().test(
        'num',
        'Поле Агент должно быть неотрицательным числом',
        (val) => !val || onlyNonNegativeFloatChecking(val),
    ),
    overstatement: Yup.string().test(
        'num',
        'Завышение должено быть быть неотрицательным числом',
        (val) => !val || onlyNonNegativeFloatChecking(val),
    ),
    status: Yup.string().required('Выберите статус'),
    comment: Yup.string(),
});
