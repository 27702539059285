import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';

const NavbarWrapper = styled.div`
    height: 100vh;
    width: 220px;
    padding-left: 20px;
    .navbar__navigation-container {
        padding-top: 3px;
    }

    .navbar__navigation-item {
        list-style-type: none;
    }

    .navbar__link-name-with-icon {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        img {
            height: 20px;
            width: 20px;
            margin-right: 12px;
        }
    }
`;

const StyledNavlink = styled(NavLink)`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #959595;
    text-decoration: none;
    .navbar__selected-link {
        color: #000;
        text-decoration: underline;
    }
`;

const StyledChildNavlink = styled(NavLink)`
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #959595;
    text-decoration: none;
    padding-left: 32px;
    &.active {
        color: #000;
    }
`;

export { NavbarWrapper, StyledNavlink, StyledChildNavlink };
