/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from 'react';
import clsx from 'clsx';

import IncomingCallIcon from '../../../../assets/images/calls/incoming-call-icon.png';
import OutgoingCallIcon from '../../../../assets/images/calls/outgoing-call-icon.png';
import AudioPlayIcon from '../../../../assets/images/calls/audio-play-icon.png';
import AudioPauseIcon from '../../../../assets/images/calls/audio-pause-icon.png';
import CommentIcon from '../../../../assets/images/calls/comment-icon.png';
import EmptyCommentIcon from '../../../../assets/images/calls/empty-comment-icon.png';

import {
    CallDirectionENUM,
    CallStatusENUM,
    CallsRecordInterface,
    IUser,
} from '../../../../utils/types';
import {
    downloadAsFile,
    formatTimeInSeconds,
    getDate,
    getLinkToClientCardFromCompany,
    getRecordFileName,
    normalizePhone,
} from '../../../../utils/functions';
import toast from 'react-hot-toast';
import { callsPageTexts } from '../../../../utils/consts';
import TooltipAlt from '../../../../components/Common/TooltipAlt/TooltipAlt';
import AddCallComment from '../../../../components/AddCallComment/AddCallComment';

type ClosedRequestsTableRowProps = {
    call: CallsRecordInterface;
    user: IUser | null;
    index: number;
    callInPlayer: CallsRecordInterface | null;
    isPlayingRecord: boolean;
    isTabView: boolean;
    playRecord: (record: CallsRecordInterface) => void;
    pauseRecord: (record: CallsRecordInterface) => void;
};
const CallsTableRow = ({
    call,
    user,
    index,
    callInPlayer,
    isPlayingRecord,
    isTabView,
    playRecord,
    pauseRecord,
}: ClosedRequestsTableRowProps) => {
    const [loadingFile, setLoadingFile] = useState(false);
    const [isCommentModalOpen, setCommentModalOpen] = useState(false);

    const isPlayingCurrentRecord = isPlayingRecord && call.callId === callInPlayer?.callId;

    const checkRightForLinkToCard = (
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        call: CallsRecordInterface,
    ) => {
        const adminOrManager = user?.isAdmin || call.company.manager?.userId === user?.userId;

        if (!adminOrManager || !call.company) {
            e.preventDefault();
            e.stopPropagation();
        }
    };
    const callRowClass = useMemo(
        () =>
            clsx('calls-table__row', {
                'calls-table__tab-row': isTabView,
                'calls-table__even-row': index % 2 !== 0,
            }),
        [index, isTabView],
    );

    const callDate = useMemo(() => getDate(call.startedAt, 'date'), [call.startedAt]);

    const callStartTime = useMemo(
        () => getDate(call.startedAt, 'time').slice(0, 6),
        [call.startedAt],
    );

    const callCompany = useMemo(() => {
        return {
            className: clsx({
                'calls-table__link': call.company?.name,
                'calls-table__disabled-link':
                    call.company && !user?.isAdmin && call.company.manager?.userId !== user?.userId,
                'calls-table__no-data': !call.company,
            }),
            name: call.company?.name || callsPageTexts.noData,
            link: call.company ? getLinkToClientCardFromCompany(call.company, user) : '',
            inn: call.company?.inn || '',
        };
    }, [call.company, user]);
    const successCall = call.status === CallStatusENUM.SUCCESS;
    const callDuration = useMemo(() => {
        return {
            className: clsx({
                'calls-table__no-data': !successCall,
            }),
            value: successCall
                ? formatTimeInSeconds(Number(call.duration)).slice(3)
                : callsPageTexts.didNotTakePlace,
        };
    }, [call.duration, successCall]);
    const callPhone = useMemo(() => normalizePhone(call.phone || ''), [call.phone]);
    const directionIcon = useMemo(
        () => (call.direction === CallDirectionENUM.IN ? IncomingCallIcon : OutgoingCallIcon),
        [call.direction],
    );
    const callStaffPhone = useMemo(() => normalizePhone(call.telnum || ''), [call.telnum]);

    const commentIcon = useMemo(
        () => (call.comment ? CommentIcon : EmptyCommentIcon),
        [call.comment],
    );

    const audioControlIcon = useMemo(() => {
        if (isPlayingCurrentRecord) {
            return AudioPauseIcon;
        }
        return AudioPlayIcon;
    }, [isPlayingCurrentRecord]);

    const successHandler = () => {
        setLoadingFile(false);
    };

    const errorHandler = () => {
        setLoadingFile(false);
        toast.error(callsPageTexts.downloadError);
    };

    const downloadDocument = (call: CallsRecordInterface | null) => {
        if (!call || !call.recordUrl) {
            return;
        }
        setLoadingFile(true);
        downloadAsFile(
            call.recordUrl,
            getRecordFileName(call),
            () => errorHandler(),
            successHandler,
            true,
        );
    };

    const adminOrManager = user?.isAdmin || user?.userId === call?.manager?.userId;

    const addCommentHandler = () => {
        if (!call || !adminOrManager) return;

        setCommentModalOpen(true);
    };

    const playPauseRecord = () => {
        if (isPlayingCurrentRecord) {
            return pauseRecord(call);
        }
        return playRecord(call);
    };

    const tooltipCommentId = `call-record-info-tooltip-${index}`;

    const getCompany = () => {
        if (isTabView) {
            return;
        }
        return call.company ? (
            <div className="calls-table__company">
                <a
                    className={callCompany.className}
                    onClick={(e) => checkRightForLinkToCard(e, call)}
                    href={callCompany.link}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {callCompany.name}
                </a>
                <p>{callCompany.inn}</p>
            </div>
        ) : (
            <p className="calls-table__no-data">{callsPageTexts.noData}</p>
        );
    };

    return (
        <div className={callRowClass} key={call.callId}>
            <p>{callDate}</p>
            <p>{callStartTime}</p>
            <div className="calls-table__phone">
                <img src={directionIcon} />
                <p>{callPhone}</p>
            </div>
            {getCompany()}
            <p>{call.manager?.shortName || '---'}</p>
            {!isTabView && <p>{callStaffPhone}</p>}
            <div className={callDuration.className}>{callDuration.value}</div>
            {successCall ? (
                <div className="calls-table__management">
                    <button onClick={playPauseRecord} className="calls-table__button">
                        <img src={audioControlIcon} />
                    </button>
                    {call.comment && (
                        <TooltipAlt anchor={tooltipCommentId} content={call.comment} />
                    )}
                    <button
                        onClick={addCommentHandler}
                        id={tooltipCommentId}
                        className="calls-table__button"
                    >
                        <img src={commentIcon} />
                    </button>
                </div>
            ) : null}
            {call.recordUrl && successCall ? (
                <a
                    className={clsx('calls-table__link', {
                        'calls-table__loading': loadingFile,
                    })}
                    onClick={() => downloadDocument(call)}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {callsPageTexts.download}
                </a>
            ) : null}
            {isCommentModalOpen && successCall ? (
                <AddCallComment
                    close={() => setCommentModalOpen(false)}
                    width={450}
                    initialText={call.comment || ''}
                    callId={call.callId}
                />
            ) : null}
        </div>
    );
};

export default CallsTableRow;
