import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { userSelector } from '../../../store/slices/userSlice';
import { updateCompany } from '../../../store/slices/clientDatabaseSlice';
import { useAppDispatch } from '../../../store';
import { selectClientCard } from '../../../store/slices/clientCardSlice';
import { importance as importanceList } from '../../../utils/consts';
import { ImportanceNameType, UserPositionNamesENUM } from '../../../utils/types';
import {
    getBackgroundByImportance,
    getDate,
    getInterestsByCompany,
    getTooltipLength,
} from '../../../utils/functions';
import { selectClientTask } from '../../../store/slices/clientTaskSlice';
import BellIcon from '../../../assets/images/bell-icon.png';
import TopMarkIcon from '../../../assets/images/top-icon.png';
import EyeIcon from '../../../assets/images/open-eye-icon.png';
import SelectedBellIcon from '../../../assets/images/selected-bell-icon.png';
import SelectedTopMarkIcon from '../../../assets/images/selected-top-icon.png';
import SelectedEyeIcon from '../../../assets/images/selected-open-eye-icon.png';
import CalculatorIcon from '../../../assets/images/clients/icon-calculator.png';
import ImportanceIcon from '../../../assets/images/clients/importance-icon.png';
import CalendarIcon from '../../../assets/images/clients/uncolored-calendar-icon.png';
import TickIcon from '../../../assets/images/clients/tick-icon.png';
import CustomSelect from '../../../components/Common/CustomSelect/CustomSelect';
import ClientTime from '../../../components/Common/ClientTime/ClientTime';
import CompanyInfo from './CompanyInfo/CompanyInfo';
import WorkArea from './WorkArea/WorkArea';
import AddNextContactDate from '../../../components/Common/AddNextContactDate/AddNextContactDate';
import Calculator from '../../../components/Calculator/Calculator';
import RemindersList from '../../../components/RemindersList/RemindersList';
import TooltipReminder from '../../../components/Common/TooltipReminder/TooltipReminder';
import ClientRequests from './ClientRequests/ClientRequests';
import AssignManager from '../../../components/AssignManagerForCompany/AssignManagerForCompany';
import ClientTaskItem from '../../ClientTaskPage/ClientTaskItem/ClientTaskItem';
import TaskInfo from '../../ClientTaskPage/TaskInfo/TaskInfo';
import ProductsForTaskTable from '../../ClientTaskPage/OffersForTaskTable/OffersForTaskTable';
import TaskComments from '../../ClientTaskPage/TaskComments/TaskComments';
import ClientTaskActions from '../../ClientTaskPage/ClientTaskActions/ClientTaskActions';
import ClientDocs from '../../ClientTaskPage/ClientTaskDocs/ClientTaskDocs';
import StyledClientCard from './ClientCard.style';
import InterestingsPicker from '../../../components/InterestingsPicker/InterestingsPicker';
import ConfirmModal from '../../../components/Common/ConfirmModal/ConfirmModal';

type ClientCardProps = {
    taskId?: number;
};

const ClientCard = ({ taskId }: ClientCardProps) => {
    const { company } = useSelector(selectClientCard);
    const { user } = useSelector(userSelector);
    const { currentTask } = useSelector(selectClientTask);
    const dispatch = useAppDispatch();
    const [isAddContactDateOpen, setAddContactDateOpen] = useState(false);
    const [isReminderOpen, setReminderOpen] = useState(false);
    const [isCalculatorOpen, setCalculatorOpen] = useState(false);
    const [isAssignManagerOpen, setAssignManagerOpen] = useState(false);
    const [confirmModal, setConfirmModal] = useState({ isOpen: false, text: '', data: '' });
    const importanceBackgroundColor = getBackgroundByImportance(
        (company?.importance || 'no') as ImportanceNameType,
    );

    const isManagerOrAdmin = user?.isAdmin || company?.manager?.userId === user?.userId;

    const managerOrAdmin = user?.isAdmin || company?.manager?.userId === user?.userId;
    const allowedCalculation = managerOrAdmin || user?.positionName === UserPositionNamesENUM.OSKO;

    const handleMarkClick = (additional: 'highlight' | 'reminders' | 'mark' | 'fts') => {
        if (!company?.companyId || !managerOrAdmin) return;

        const updates: {
            isHighlighted?: boolean;
            isMarked?: boolean;
            isFtsMarked?: boolean;
        } = {};

        switch (additional) {
            case 'highlight':
                updates.isHighlighted = !company.isHighlighted;
                break;
            case 'mark':
                updates.isMarked = !company.isMarked;
                break;
            case 'reminders':
                return setReminderOpen(true);
        }

        dispatch(updateCompany({ ...updates, clientId: company.companyId }));
    };

    const handleImportanceClick = (importance: ImportanceNameType) => {
        if (!company?.companyId && importance !== company?.importance) return;

        const updatedImportance = importance === 'no' ? null : importance;
        dispatch(updateCompany({ importance: updatedImportance, clientId: company.companyId }));
    };

    const openCalculatorHandler = () => {
        if (!allowedCalculation) return;

        setCalculatorOpen(true);
    };

    const openAddContactDateHandler = () => {
        if (!managerOrAdmin) return;

        setAddContactDateOpen(true);
    };

    const managerClickHandler = () => {
        if (!user?.isAdmin) return;

        setAssignManagerOpen(true);
    };

    const onChangeInterests = (value: string) => {
        const modalInfo = {
            isOpen: true,
            text: `Подтвердите ваше действие, пожалуйста`,
            data: value,
        };

        setConfirmModal(modalInfo);
    };

    const onCloseModal = () => setConfirmModal({ isOpen: false, text: '', data: '' });

    const interests = useMemo(() => (company ? getInterestsByCompany(company) : []), [company]);

    const onConfirmModal = () => {
        if (!company?.companyId) return;

        const isInclude = interests.includes(confirmModal.data);
        dispatch(updateCompany({ [confirmModal.data]: !isInclude, clientId: company.companyId }));
        onCloseModal();
    };

    return (
        <StyledClientCard>
            <div className="client-card__header">
                <div className="client-card__row-of-header">
                    <div className="client-card__header-info">
                        Время клиента:
                        {company?.region.timezone ? (
                            <ClientTime timezone={company.region.timezone} />
                        ) : null}
                    </div>
                    <div className="client-card__tools">
                        {isManagerOrAdmin && (
                            <InterestingsPicker value={interests} onChange={onChangeInterests} />
                        )}
                        <div
                            className={clsx('client-card__tools-img-with-description', {
                                'client-card__disabled-tool': !allowedCalculation,
                            })}
                            onClick={openCalculatorHandler}
                        >
                            <img src={CalculatorIcon} />
                            <p className="client-card__tools-description">Расчет стоимости</p>
                        </div>
                        <div
                            className={clsx(
                                'client-card__tools-img-with-description client-card__top-mark',
                                {
                                    'client-card__disabled-tool': !managerOrAdmin,
                                },
                            )}
                            onClick={() => handleMarkClick('mark')}
                        >
                            <img src={company?.isMarked ? SelectedTopMarkIcon : TopMarkIcon} />
                            <p className="client-card__tools-description">
                                {company?.isMarked ? 'Снять' : 'Добавить'} статус
                            </p>
                        </div>
                        <div
                            className={clsx('client-card__tools-img-with-description', {
                                'client-card__disabled-tool': !managerOrAdmin,
                            })}
                            onClick={() => handleMarkClick('highlight')}
                        >
                            <img src={company?.isHighlighted ? SelectedEyeIcon : EyeIcon} />
                            <p className="client-card__tools-description">Выделить в поиске</p>
                        </div>
                        <TooltipReminder
                            text={company?.reminders[0]?.text || ''}
                            time={company?.reminders[0]?.time || ''}
                            hasReminder={Boolean(company?.reminders?.length)}
                            rightMargin={177}
                            maxWidth={800}
                            minWidth={getTooltipLength({
                                textLength: company?.reminders?.[0]?.text?.length,
                            })}
                        >
                            <div
                                className={clsx(
                                    'client-card__tools-img-with-description client-card__reminder',
                                    {
                                        'client-card__disabled-tool': !managerOrAdmin,
                                    },
                                )}
                                onClick={() => handleMarkClick('reminders')}
                            >
                                <img
                                    src={company?.reminders?.length ? SelectedBellIcon : BellIcon}
                                />
                                <p className="client-card__tools-description">
                                    Создать напоминание
                                </p>
                            </div>
                        </TooltipReminder>
                    </div>
                </div>
                <p className="client-card__contact-date">
                    Дата следующего контакта:{' '}
                    <span>{getDate(company?.contactDate || '', 'date with time')}</span>
                </p>
                <div className="client-card__row-of-header">
                    <div className="client-card__header-info">
                        Менеджер:
                        <p
                            className={clsx({
                                'client-card__manager': user?.isAdmin,
                            })}
                            onClick={managerClickHandler}
                        >
                            {company?.manager
                                ? company.manager.fullName ||
                                  company.manager.shortName ||
                                  company.manager.login ||
                                  company.manager.email ||
                                  ''
                                : 'нет'}
                        </p>
                    </div>
                    <div className="client-card__tools">
                        <div
                            className={clsx('client-card__tools-img-with-description', {
                                'client-card__disabled-tool': !managerOrAdmin,
                            })}
                        >
                            <img src={ImportanceIcon} />
                            <p className="client-card__tools-description">Важность</p>
                            <CustomSelect
                                height={20}
                                width={173}
                                backgroundImage={TickIcon}
                                backgroundImageOffset={-5}
                                backgroundColor={importanceBackgroundColor}
                            >
                                <select
                                    className="client-card__select"
                                    value={company?.importance || importanceList[0].name}
                                    onChange={(e) =>
                                        handleImportanceClick(e.target.value as ImportanceNameType)
                                    }
                                    disabled={!managerOrAdmin}
                                >
                                    {importanceList.slice(1).map((item) => (
                                        <option key={item.name} value={item.name}>
                                            {item.label}
                                        </option>
                                    ))}
                                </select>
                            </CustomSelect>
                        </div>
                        <div
                            className={clsx('client-card__tools-img-with-description', {
                                'client-card__disabled-tool': !managerOrAdmin,
                            })}
                            onClick={openAddContactDateHandler}
                        >
                            <img src={CalendarIcon} />
                            <p className="client-card__tools-description">
                                Установить дату следующего контакта с клиентом
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <CompanyInfo />
            {!taskId ? (
                <>
                    <WorkArea />
                    <ClientRequests />
                </>
            ) : (
                <div
                    className={clsx({
                        'client-card__disabled-task': !currentTask,
                    })}
                >
                    <ClientTaskItem />
                    <TaskInfo />
                    {company?.companyId ? (
                        <>
                            <ProductsForTaskTable
                                companyId={Number(company.companyId)}
                                taskId={taskId}
                            />
                            <TaskComments />
                            <ClientTaskActions companyId={Number(company.companyId)} />
                            <ClientDocs companyId={Number(company.companyId)} />
                        </>
                    ) : null}
                </div>
            )}
            {isAddContactDateOpen && company ? (
                <AddNextContactDate
                    close={() => setAddContactDateOpen(false)}
                    width={300}
                    height={180}
                    clientId={company.companyId}
                />
            ) : null}
            {isAssignManagerOpen && company ? (
                <AssignManager
                    close={() => setAssignManagerOpen(false)}
                    width={300}
                    clientId={company.companyId}
                    currentManager={company.manager?.userId || 0}
                />
            ) : null}
            {isCalculatorOpen ? (
                <Calculator width={573} close={() => setCalculatorOpen(false)} />
            ) : null}
            {isReminderOpen && company ? (
                <RemindersList
                    close={() => setReminderOpen(false)}
                    width={450}
                    companyId={company.companyId}
                    reminders={company.reminders}
                />
            ) : null}
            {confirmModal.isOpen && (
                <ConfirmModal
                    close={onCloseModal}
                    confirmAction={onConfirmModal}
                    width={280}
                    height={120}
                    headText={confirmModal.text}
                />
            )}
        </StyledClientCard>
    );
};

export default ClientCard;
