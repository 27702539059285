import styled from 'styled-components/macro';

const StyledCell = styled.div`
    display: flex;
    margin-left: 40px;
    align-items: center;
    p {
        margin: 0 10px;
        font-style: italic;
    }
`;

export default StyledCell;
