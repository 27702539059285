import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RotatingLines } from 'react-loader-spinner';
import clsx from 'clsx';

import { closeRequestsTableColumns } from '../../../utils/data';
import { formatNum, getNormalizedSum } from '../../../utils/functions';
import { selectClientTask } from '../../../store/slices/clientTaskSlice';
import { userSelector } from '../../../store/slices/userSlice';
import { ClientTaskInterface } from '../../../utils/types';
import StyledTable from './ClosedRequestsTable.style';
import EmptyList from '../../../components/EmptyList/EmptyList';
import ClosedRequestsTableRow from '../ClosedRequestsTableRow/ClosedRequestsTableRow';
import ClosedRequestsTableExport from '../ClosedRequestsTableExport/ClosedRequestsTableExport';

const RequestsTable = () => {
    const { loading, clientTasks, clientCommissionAmount, clientIncomeAmount, clientTasksAmount } =
        useSelector(selectClientTask);
    const { user } = useSelector(userSelector);
    const tableColumns = user?.isAdmin
        ? closeRequestsTableColumns
        : closeRequestsTableColumns.filter((column) => column.name !== 'manager');

    const totalAmountList = useMemo(() => {
        return [
            formatNum(getNormalizedSum(clientTasksAmount.toFixed(4))),
            formatNum(getNormalizedSum(clientCommissionAmount.toFixed(4))),
            formatNum(getNormalizedSum(clientIncomeAmount.toFixed(4))),
        ];
    }, [clientIncomeAmount, clientCommissionAmount, clientTasksAmount]);

    const getTasksOrText = (clientTaskArray: ClientTaskInterface[]) => {
        return !clientTaskArray.length ? (
            <EmptyList />
        ) : (
            clientTaskArray.map((task, index) => (
                <ClosedRequestsTableRow key={task.taskId} task={task} user={user} index={index} />
            ))
        );
    };

    return (
        <StyledTable>
            <ClosedRequestsTableExport tasks={clientTasks} />
            <div
                className={clsx('close-requests-table__row', {
                    'close-requests-table__manager-view': !user?.isAdmin,
                })}
            >
                {totalAmountList.map((el, indx) => (
                    <div key={indx} className={clsx('row-cell-prio', `row-cell-prio--${indx}`)}>
                        {el}
                    </div>
                ))}
            </div>
            <div
                className={clsx('close-requests-table__headings', {
                    'close-requests-table__manager-view': !user?.isAdmin,
                })}
            >
                {tableColumns.map((column, index) => (
                    <div
                        key={column.name}
                        className={clsx('close-requests-table__column', {
                            'close-requests-table__right-position': index > 7 && index < 14,
                        })}
                    >
                        <p>{column.label}</p>
                    </div>
                ))}
            </div>
            <div>
                {loading.getAllTasks ? (
                    <div className="close-requests-table__loader">
                        <RotatingLines strokeColor="grey" />
                    </div>
                ) : (
                    getTasksOrText(clientTasks)
                )}
            </div>
        </StyledTable>
    );
};

export default RequestsTable;
