import React from 'react';

import StyledForm from './SearchField.style';

type SearchFieldProps = {
    placeholder?: string;
    width?: number;
    height?: number;
    background?: string;
    searchTerm: string;
    // eslint-disable-next-line no-unused-vars
    search: (value: string) => void;
};

const SearchField = ({
    placeholder = 'Поиск',
    width = 302,
    height = 22,
    background = '#fff',
    searchTerm,
    search,
}: SearchFieldProps) => {
    return (
        <StyledForm width={width} height={height} background={background}>
            <input
                type="search"
                placeholder={placeholder}
                value={searchTerm}
                onChange={(e) => search(e.target.value)}
            />
            <button />
        </StyledForm>
    );
};

export default SearchField;
