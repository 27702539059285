import styled from 'styled-components/macro';

const StyledStatisticCommentsWrapper = styled.div`
    .company-comments-page__header {
        padding: 5px 7px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        color: #fff;
        background: #959595;
    }

    .company-comments__heading {
        padding-top: 53px;
        padding-left: 231px;
        font-size: 32.8px;
        font-weight: 600;
    }
`;

export default StyledStatisticCommentsWrapper;
