import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { clsx } from 'clsx';

import { useAppDispatch } from '../../../store';
import { BankProductDocumentCreateInterface } from '../../../utils/types';
import { getOnePartner, selectPartner } from '../../../store/slices/partnerSlice';
import { downloadAsFile } from '../../../utils/functions';
import DeleteIcon from '../../../assets/images/staff/delete-doc-icon.png';
import AddFile from '../../AddFile/AddFile';

type BankProductDocProps = {
    // eslint-disable-next-line no-unused-vars
    setDocuments: (value: BankProductDocumentCreateInterface[]) => void;
    documents: BankProductDocumentCreateInterface[];
    onlyView: boolean;
};

const BankProductDoc = ({ setDocuments, documents, onlyView }: BankProductDocProps) => {
    const dispatch = useAppDispatch();
    const { currentPartner, loading, isRequestFulfilled } = useSelector(selectPartner);
    const [isAddFileModalOpen, setAddFileModalOpen] = useState(false);
    const [unloadedFile, setUnloadedFile] = useState<null | BankProductDocumentCreateInterface>(
        null,
    );

    const deleteDoc = (index: number) => {
        const newValue = [...documents];
        newValue.splice(index, 1);
        setDocuments(newValue);
    };

    const fileSelectionHandler = (name: string, file: string) => {
        const newValue = [
            ...documents,
            {
                file,
                name,
            },
        ];

        setDocuments(newValue);
    };

    const successHandler = () => {
        setUnloadedFile(null);
    };

    const errorHandler = (doc: BankProductDocumentCreateInterface) => {
        if (!currentPartner?.bankId || !unloadedFile) return;

        setUnloadedFile(doc);
        dispatch(getOnePartner({ bankId: currentPartner.bankId }));
    };

    const downloadDocument = (doc: BankProductDocumentCreateInterface) => {
        downloadAsFile(doc.file, doc.name, () => errorHandler(doc), successHandler);
    };

    useEffect(() => {
        if (!unloadedFile?.bankProductDocumentId) return;

        const doc = documents.find(
            (doc) => doc?.bankProductDocumentId === unloadedFile.bankProductDocumentId,
        );

        if (!doc) return;

        downloadDocument(doc);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documents]);

    return (
        <>
            <div className="add-product__product-docs">
                <div className="add-product__btn-with-title">
                    <p className="add-product__bold-title">Документы:</p>
                    {!onlyView && (
                        <button
                            type="button"
                            className="add-product__add-btn"
                            onClick={() => setAddFileModalOpen(true)}
                        >
                            Добавить
                        </button>
                    )}
                </div>
                {documents?.map((doc, index) => (
                    <div key={index} className="add-product__doc-item">
                        <p
                            onClick={() => downloadDocument(doc)}
                            className={clsx({
                                'add-product__active-doc-name': doc.bankProductDocumentId,
                            })}
                        >
                            {doc.name}
                        </p>
                        {!onlyView && <img src={DeleteIcon} onClick={() => deleteDoc(index)} />}
                    </div>
                ))}
            </div>
            {isAddFileModalOpen && (
                <AddFile
                    close={() => setAddFileModalOpen(false)}
                    width={400}
                    setFile={fileSelectionHandler}
                    title="Добавить документы"
                    loading={loading.uploadPartnerDocs}
                    isFulfilled={isRequestFulfilled.uploadFile}
                />
            )}
        </>
    );
};

export default BankProductDoc;
