import styled from 'styled-components/macro';

const StyledComponent = styled.div`
    .empty-list {
        text-align: center;
        font-size: 18px;
        margin: 30px;
    }
    .empty-list > div {
        margin-top: 10px;
    }
`;

export default StyledComponent;
