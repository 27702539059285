import React from 'react';

import StyledComponent from './ClosedRequestsTableExport.style';
import { exportToExcel, generateExportData } from '../../../utils/functions';
import { ClientTaskInterface } from '../../../utils/types';
import ExcelIcon from '../../../assets/images/excel.png';

type ClosedRequestsTableExportProps = {
    tasks: ClientTaskInterface[];
};

const ClosedRequestsTableExport = ({ tasks }: ClosedRequestsTableExportProps) => {
    const onClickHandler = () => {
        const exportData = generateExportData(tasks);
        exportToExcel(exportData);
    };
    return (
        <StyledComponent>
            <div className="export__button" onClick={onClickHandler}>
                <img src={ExcelIcon} />
                <p className="export__text">Экспорт таблицы</p>
            </div>
        </StyledComponent>
    );
};

export default ClosedRequestsTableExport;
