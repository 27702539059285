import React from 'react';
import { UseFormRegister, FieldErrorsImpl } from 'react-hook-form';
import 'react-phone-input-2/lib/style.css';

import { AddCredentialsInputs } from '../AddCredentials';
import AddCredentialsInputField from '../AddCredentialsInputField/AddCredentialsInputField';
import AddCredentialsDatasetField from '../AddCredentialsDatasetField/AddCredentialsDatasetField';
import { FieldsInAddingCredentialsFormType } from '../../../../utils/data';

type AddCredentialsGetInputFieldProps = {
    field: FieldsInAddingCredentialsFormType;
    errors: Partial<FieldErrorsImpl<AddCredentialsInputs>>;
    register: UseFormRegister<AddCredentialsInputs>;
};

const AddCredentialsGetInputField = ({
    field,
    errors,
    register,
}: AddCredentialsGetInputFieldProps) => {
    switch (field.type) {
        case 'input':
            return <AddCredentialsInputField field={field} errors={errors} register={register} />;
        case 'dataset':
            return <AddCredentialsDatasetField field={field} errors={errors} register={register} />;
        default:
            return null;
    }
};

export default AddCredentialsGetInputField;
