import React from 'react';

import StyledCustomSelect from './CustomSelect.style';

type CustomSelectProps = {
    width: number;
    height: number;
    backgroundImage: string;
    backgroundColor?: string;
    children: React.ReactNode;
    backgroundImageOffset?: number;
};

const CustomSelect = ({
    width,
    height,
    backgroundImage,
    backgroundColor = '#fff',
    children,
    backgroundImageOffset = 0,
}: CustomSelectProps) => {
    return (
        <StyledCustomSelect
            width={width}
            height={height}
            backgroundImage={backgroundImage}
            backgroundColor={backgroundColor}
            backgroundImageOffset={backgroundImageOffset}
        >
            {children}
        </StyledCustomSelect>
    );
};

export default CustomSelect;
