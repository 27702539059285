import React, { useState } from 'react';
import AddPartner from '../../components/AddPartner/AddPartner';

import PartnerTable from './PartnersTable/PartnersTable';
import Filter from './Filter/Filter';
import StyledPartnersPageWrapper from './Partners.style';
import { FilterParams, SearchParamsFieldNames } from './Filter/Filter.types';

export const defaultFilterParams: FilterParams = {
    federalLaw: '',
    guaranteeType: '',
    customerFromList: 0,
    term: '',
    limit: '',
    input: '',
};

const PartnersPage = () => {
    const [isAddPartnerOpen, setAddPartnerOpen] = useState(false);
    const [editablePartnerId, setEditablePartnerId] = useState<null | number>(null);
    const [isFilterOpen, setFilterOpen] = useState(false);

    const [filter, setFilter] = useState<FilterParams>(defaultFilterParams);
    const closeAddPartnerHandler = () => {
        setAddPartnerOpen(false);
        setEditablePartnerId(null);
    };

    const isFilterEmpty = () => {
        let isEmpty = true;
        Object.keys(filter).forEach((key) => {
            if (key !== 'input' && filter[key as SearchParamsFieldNames]) {
                isEmpty = false;
            }
        });
        return isEmpty;
    };

    if (isAddPartnerOpen || editablePartnerId) {
        return <AddPartner close={closeAddPartnerHandler} bankId={editablePartnerId} />;
    }

    return (
        <StyledPartnersPageWrapper>
            <Filter
                setAddPartnerOpen={() => setAddPartnerOpen(true)}
                isFilterOpen={isFilterOpen}
                setFilterOpen={setFilterOpen}
                filter={filter}
                setFilter={setFilter}
                isFilterEmpty={isFilterEmpty()}
            />
            <PartnerTable
                setEditablePartnerId={setEditablePartnerId}
                isFilterOpen={isFilterOpen}
                isFilterEmpty={isFilterEmpty()}
            />
        </StyledPartnersPageWrapper>
    );
};

export default PartnersPage;
