import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { RotatingLines } from 'react-loader-spinner';

import AppRouter from './components/AppRouter';
import Header from './components/Header/Header';
import GlobalStyle from './utils/globalStyles';
import Navbar from './components/Navbar/Navbar';
import { appSelector, initializeApp } from './store/slices/appSlice/appSlice';
import { useAppDispatch } from './store';
import { StyledSpinner, StyledAppWrapper } from './App.style';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

function App() {
    const dispatch = useAppDispatch();
    const { initialized } = useSelector(appSelector);

    useEffect(() => {
        dispatch(initializeApp());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!initialized) {
        return (
            <StyledSpinner>
                <RotatingLines strokeColor="grey" />
            </StyledSpinner>
        );
    }

    return (
        <div className="App">
            <Toaster />
            <GlobalStyle />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <BrowserRouter>
                    <Header />
                    <StyledAppWrapper>
                        <Navbar />
                        <AppRouter />
                    </StyledAppWrapper>
                </BrowserRouter>
            </LocalizationProvider>
        </div>
    );
}

export default App;
