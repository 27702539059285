import styled from 'styled-components/macro';

const StyledContainer = styled.div<{ width: number }>`
    width: ${({ width }) => `${width}px`};
    .edit-category__category-name {
        border-radius: 5px;
        padding: 10px;
        width: 100%;
        min-height: 100px;
        margin: 15px 0;
    }

    .edit-category__action {
        height: 40px;
    }
`;

export default StyledContainer;
