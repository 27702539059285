import { TableOrderType } from '../../pages/Clients/Filter/Filter.types';
import {
    BankProductRequest,
    PartnerCredential,
    CreatePartnerDocument,
    CashbackInterface,
    CashbackStatusType,
} from '../../utils/types';

/* eslint-disable */
export enum companyCashbackPath {
    CASHBACK = '/cashback',
    OFFER = '/offer',
    TASK = '/task',
    COMPANY = '/company',
}
/* eslint-enable */

interface BaseCrudCashbackInterface {
    companyId: number;
    logo?: string;
    isDisplayedInList?: boolean;
    isPriority?: boolean;
    additInfo?: string;
    documents?: CreatePartnerDocument[];
    credentials?: PartnerCredential[];
    bankContractExecution?: BankProductRequest;
    bankAdvanceRefund?: BankProductRequest;
    bankGuaranteeObligation?: BankProductRequest;
    applicationSecurity?: BankProductRequest;
    commercialContractExecution?: BankProductRequest;
    commercialAdvanceRefund?: BankProductRequest;
    commercialGuaranteeObligation?: BankProductRequest;
}

export interface CreateCashbackPropsType extends BaseCrudCashbackInterface {
    name: string;
    taskId: number;
    offerId: number;
}

export interface UpdateCashbackPropsType extends BaseCrudCashbackInterface {
    taskId: number;
    offerId: number;
    cashbackId: number;
    clientCommission?: number;
    status?: CashbackStatusType;
    receiverName?: string;
    receiverInn?: string;
    inn?: string;
    receiverHeadName?: string;
    receiverHeadPost?: string;
    paymentDate?: string;
    checkingAccount?: string;
    correspondentAccount?: string;
    individualInn?: string;
    importantInfo?: string;
    bankIdentificationCode?: string;
}

export type GetCashbackPropsType = {
    companyId: number;
    order: TableOrderType;
    sortBy: string;
    status?: CashbackStatusType;
    hasAct?: boolean;
};

export type GetCompanyCashbackResponseType =
    | {
          data: { cashbackList: CashbackInterface[]; total: { records: number; amount: number } };
          message: string;
      }
    | undefined;
