import PhoneInput from 'react-phone-input-2';
import styled from 'styled-components/macro';

const StyledFilterWrapper = styled.div`
    display: flex;
    padding: 5px 0;

    .filter__container {
        display: flex;
        align-items: center;
        font-weight: 500;

        p {
            width: 136px;
            margin-right: 10px;
        }
    }
`;

export const StyledPhoneInput = styled(PhoneInput)`
    /* margin: 5px 0; */

    .form-control {
        width: 262px;
        height: 20px;
        border: 0.5px solid #000;
        padding-left: 8px;
    }

    .flag-dropdown {
        border: 1px solid #000;
        display: none;
    }
`;

export default StyledFilterWrapper;
