import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { logoutUser, userSelector } from '../../store/slices/userSlice';
import { useAppDispatch } from '../../store';
import { normalizePhone } from '../../utils/functions';
import Timer from './Timer/Timer';
import CompanyLogo from '../../assets/images/boomerang_logo.png';
import News from '../News/News';
import ConfirmModal from '../Common/ConfirmModal/ConfirmModal';
import StyledHeader from './Header.style';

const Header = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { user } = useSelector(userSelector);
    const [isModalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        setModalOpen(false);
    }, []);

    if (location.pathname.includes('login')) {
        return null;
    }

    const confirmAction = () => {
        dispatch(logoutUser());
        setModalOpen(false);
    };

    return (
        <StyledHeader>
            <div className="header__logo-with-time">
                <img className="header__logo" src={CompanyLogo} />
                <Timer />
            </div>
            <News />
            <div className="header__personal-info-container">
                <div className="header__personal-info">
                    <p>
                        Вы вошли как: <span className="header__bold-text">{user?.fullName}</span>
                    </p>
                    {user?.phone ? (
                        <p>
                            Тел.: {normalizePhone(user.phone)}{' '}
                            {user.additNumber ? `доб. ${user.additNumber}` : null}
                        </p>
                    ) : null}
                    {user?.email ? <p>E-mail: {user.email}</p> : null}
                    {user?.telegramIntegrationCode && user?.bot ? (
                        <p>ТГ-код: {user.telegramIntegrationCode}</p>
                    ) : null}
                </div>
                <span onClick={() => setModalOpen(true)} className="header__close-button">
                    Завершить сеанс
                </span>
            </div>
            {isModalOpen ? (
                <ConfirmModal
                    close={() => setModalOpen(false)}
                    confirmAction={confirmAction}
                    width={280}
                    height={120}
                    headText="Вы уверены, что хотите выйти?"
                />
            ) : null}
        </StyledHeader>
    );
};

export default Header;
