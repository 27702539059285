import styled from 'styled-components/macro';

const StyledClientRequests = styled.div`
    font-weight: 500;
    font-size: 12px;

    .client-requests-table__row,
    .client-requests-table__header {
        padding: 5px 7px;
        display: grid;
        grid-template-columns:
            minmax(100px, 3fr) minmax(60px, 1fr) minmax(220px, 5fr) minmax(80px, 1fr)
            minmax(85px, 1fr) minmax(100px, 3fr) minmax(130px, 5fr) minmax(220px, 5fr) 90px;
        grid-column-gap: 10px;
    }

    .client-requests-table__header {
        align-items: center;
        height: 30px;
        border-bottom: 1px solid #959595;

        img {
            width: 20px;
            height: 20px;
            margin-left: 10px;
            transform: rotate(180deg);
        }
    }

    .clients-requests-table__odd-field {
        background: #f8f8f8;
    }

    .client-request-table__task-number {
        text-decoration-line: underline;
        color: #0038ff;
        cursor: pointer;
    }

    .clients-requests-table__icons {
        display: flex;
        justify-content: end;
        img {
            width: 20px;
            height: 20px;
            margin-left: 11px;
        }
    }

    .clients-requests-table__without-tasks {
        text-align: center;
        font-size: 14px;
        padding-top: 5px;
    }

    .clients-requests-table__right-position {
        display: flex;
        justify-content: flex-end;
    }
`;

export default StyledClientRequests;
