import styled from 'styled-components/macro';

const StyledFormWrapper = styled.form<{ width: number; height: number }>`
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    max-height: 80vh;
    font-size: 12px;

    input,
    select {
        margin: 5px 0;
        width: 100%;
        height: 24px;
        border: 1px solid #000;
        border-radius: 5px;
        padding-left: 10px;
    }

    button {
        border: none;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #0038ff;
        padding: 0;
    }

    .add-product__title {
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 13px;

        span {
            font-weight: 500;
        }
    }

    .add-product__btn {
        display: flex;
        justify-content: end;
        padding-top: 10px;
    }

    .add-product__error-field {
        border-color: #ff0000;
    }

    .add-product__sign-of-obligatory-field {
        color: #ff0000;
    }

    .add-product__scroll-container {
        margin-right: 5px;
    }

    .add-product__law-label-container,
    .add-product__laws,
    .add-product__works {
        display: flex;
        align-items: center;
    }

    .add-product__laws {
        margin-bottom: 6px;
    }

    .add-product__works {
        margin-top: 7px;
    }

    .add-product__law-label-container {
        margin-bottom: 5px;
    }

    .add-product__law-label {
        margin: 0 5px 0 10px;
    }

    .add-product__first-law {
        margin-left: 0;
    }

    .add-product__laws-title {
        margin: 12px 0;
    }

    .add-product__rate {
        display: flex;
        input {
            width: 115px;
            margin-left: 5px;
        }
        p {
            align-self: center;
        }
    }

    .add-product__work-with-container {
        display: flex;
        flex-direction: column;
        align-items: start;

        input {
            width: 115px;
            margin-left: 5px;
        }
    }

    .add-product__work-with-label {
        margin-bottom: 12px;
    }

    .add-product__work-with-switch,
    .add-product__same-line-title {
        display: flex;
        align-items: center;

        p {
            margin-right: 15px;
        }
    }

    .add-product__region-item {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        p {
            margin-left: 8px;
        }
    }

    .add-product__bold-title {
        margin: 7px 0;
        font-weight: 600;
        line-height: 20px;
        display: flex;
        align-items: center;

        img {
            width: 20px;
            height: 20px;
            margin-left: 8px;
        }
    }

    .add-product__same-line-title p {
        margin-right: 18px;
        color: #f00;
    }

    .add-product__product-list-item {
        display: grid;
        grid-template-columns: 130px 1fr 20px;
        align-items: center;
        margin-bottom: 8px;

        img {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
    }

    .add-product__commission {
        display: grid;
        grid-template-columns: 155px 1fr;

        input {
            width: 90px;
        }
    }

    .add-product__btn-with-title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
            text-decoration: underline;
        }
    }

    .add-product__doc-item {
        display: grid;
        grid-template-columns: 1fr 20px;
        grid-column-gap: 20px;
        margin-bottom: 10px;

        img {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
    }

    .add-product__temporary-input {
        display: grid;
        grid-template-columns: 1fr 65px 65px;
        grid-column-gap: 30px;
    }

    .add-product__delete-btn {
        color: #ff0000;
    }

    .add-product__text-area {
        width: 100%;
        height: 75px;
        padding: 10px;
        border-radius: 5px;
    }

    .add-product__first-work-switcher {
        margin-right: 35px;
    }

    .add-product__active-doc-name {
        text-decoration: underline;
        cursor: pointer;
        color: #0038ff;
    }
`;

export default StyledFormWrapper;
