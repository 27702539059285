import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-hot-toast';

import { getError, isResponseOK, normalizePhone } from '../../../utils/functions';
import {
    CreateCompanyCommentType,
    CreateCompanyNoteType,
    CreateCompanyReminderType,
    DeleteCompanyCommentType,
    DeleteCompanyNoteType,
    DeleteCompanyReminderType,
    UpdateCompanyCommentType,
    UpdateCompanyNoteType,
    CreateCompanyContactType,
    UpdateCompanyContactType,
    GetExternalContactsPropsType,
    GetExternalContactsType,
} from '../../../api/clientDatabaseApi/clientDatabaseApi.types';
import { RootState } from '../..';
import { clientDatabaseApi } from '../../../api/clientDatabaseApi/clientDatabaseApi';
import { InitialStateType } from './types';

export const getCompany = createAsyncThunk<
    any,
    {
        companyId: number;
    },
    {
        rejectValue: string;
    }
>('clientCard/getCompany', async ({ ...props }, { rejectWithValue }) => {
    try {
        const res = await clientDatabaseApi.getCompany({ ...props });
        if (res?.message === 'Success') {
            return res.data;
        } else {
            throw res?.data;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const createCompanyContact = createAsyncThunk<
    any,
    CreateCompanyContactType,
    {
        rejectValue: string;
    }
>(
    'clientCard/createCompanyContact',
    async ({ companyId, ...props }, { rejectWithValue, dispatch }) => {
        try {
            const res = await clientDatabaseApi.createCompanyContact({ ...props, companyId });
            if (isResponseOK(res)) {
                dispatch(getCompany({ companyId }));
                return res;
            } else {
                throw res;
            }
        } catch (e) {
            return rejectWithValue(getError(e));
        }
    },
);

export const updateCompanyContact = createAsyncThunk<
    any,
    UpdateCompanyContactType,
    {
        rejectValue: string;
    }
>(
    'clientCard/updateCompanyContact',
    async ({ companyId, ...props }, { rejectWithValue, dispatch }) => {
        try {
            const res = await clientDatabaseApi.updateCompanyContact({ ...props, companyId });
            if (isResponseOK(res)) {
                dispatch(getCompany({ companyId }));
                return res;
            } else {
                throw res;
            }
        } catch (e) {
            return rejectWithValue(getError(e));
        }
    },
);

export const deleteCompanyContact = createAsyncThunk<
    any,
    {
        companyId: number;
        contactId: number;
        hardDelete?: boolean;
    },
    {
        rejectValue: string;
    }
>(
    'clientCard/deleteCompanyContact',
    async ({ companyId, ...props }, { rejectWithValue, dispatch }) => {
        try {
            const res = await clientDatabaseApi.deleteCompanyContact({ ...props, companyId });
            if (isResponseOK(res)) {
                dispatch(getCompany({ companyId }));
                return res;
            } else {
                throw res;
            }
        } catch (e) {
            return rejectWithValue(getError(e));
        }
    },
);

export const createCompanyComment = createAsyncThunk<
    any,
    CreateCompanyCommentType,
    {
        rejectValue: string;
    }
>(
    'clientCard/createCompanyComment',
    async ({ companyId, ...props }, { rejectWithValue, dispatch }) => {
        try {
            const res = await clientDatabaseApi.createCompanyComment({ ...props, companyId });
            if (isResponseOK(res)) {
                dispatch(getCompany({ companyId }));
                return res;
            } else {
                throw res;
            }
        } catch (e) {
            return rejectWithValue(getError(e));
        }
    },
);

export const updateCompanyComment = createAsyncThunk<
    any,
    UpdateCompanyCommentType,
    {
        rejectValue: string;
    }
>(
    'clientCard/updateCompanyComment',
    async ({ companyId, ...props }, { rejectWithValue, dispatch }) => {
        try {
            const res = await clientDatabaseApi.updateCompanyComment({ ...props, companyId });
            if (isResponseOK(res)) {
                dispatch(getCompany({ companyId }));
                return res;
            } else {
                throw res;
            }
        } catch (e) {
            return rejectWithValue(getError(e));
        }
    },
);

export const deleteCompanyComment = createAsyncThunk<
    any,
    DeleteCompanyCommentType,
    {
        rejectValue: string;
    }
>(
    'clientCard/deleteCompanyComment',
    async ({ companyId, ...props }, { rejectWithValue, dispatch }) => {
        try {
            const res = await clientDatabaseApi.deleteCompanyComment({ ...props, companyId });
            if (isResponseOK(res)) {
                dispatch(getCompany({ companyId }));
                return res;
            } else {
                throw res;
            }
        } catch (e) {
            return rejectWithValue(getError(e));
        }
    },
);

export const deleteReminder = createAsyncThunk<
    any,
    DeleteCompanyReminderType,
    {
        rejectValue: string;
    }
>('clientCard/deleteReminder', async ({ companyId, ...props }, { rejectWithValue, dispatch }) => {
    try {
        const res = await clientDatabaseApi.deleteReminder({ ...props, companyId });
        if (isResponseOK(res)) {
            dispatch(getCompany({ companyId }));
            return res;
        } else {
            throw res;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const createCompanyReminder = createAsyncThunk<
    any,
    CreateCompanyReminderType,
    {
        rejectValue: string;
    }
>(
    'clientCard/createCompanyReminder',
    async ({ companyId, ...props }, { rejectWithValue, dispatch }) => {
        try {
            const res = await clientDatabaseApi.createCompanyReminder({ ...props, companyId });
            if (isResponseOK(res)) {
                dispatch(getCompany({ companyId }));
                return res;
            } else {
                throw res;
            }
        } catch (e) {
            return rejectWithValue(getError(e));
        }
    },
);

export const createCompanyNote = createAsyncThunk<
    any,
    CreateCompanyNoteType,
    {
        rejectValue: string;
    }
>(
    'clientCard/createCompanyNote',
    async ({ companyId, ...props }, { rejectWithValue, dispatch }) => {
        try {
            const res = await clientDatabaseApi.createCompanyNote({ ...props, companyId });
            if (isResponseOK(res)) {
                dispatch(getCompany({ companyId }));
                return res;
            } else {
                throw res;
            }
        } catch (e) {
            return rejectWithValue(getError(e));
        }
    },
);

export const updateCompanyNote = createAsyncThunk<
    any,
    UpdateCompanyNoteType,
    {
        rejectValue: string;
    }
>(
    'clientCard/updateCompanyNote',
    async ({ companyId, ...props }, { rejectWithValue, dispatch }) => {
        try {
            const res = await clientDatabaseApi.updateCompanyNote({ ...props, companyId });
            if (isResponseOK(res)) {
                dispatch(getCompany({ companyId }));
                return res;
            } else {
                throw res;
            }
        } catch (e) {
            return rejectWithValue(getError(e));
        }
    },
);

export const deleteCompanyNote = createAsyncThunk<
    any,
    DeleteCompanyNoteType,
    {
        rejectValue: string;
    }
>(
    'clientCard/deleteCompanyNote',
    async ({ companyId, ...props }, { rejectWithValue, dispatch }) => {
        try {
            const res = await clientDatabaseApi.deleteCompanyNote({ ...props, companyId });
            if (isResponseOK(res)) {
                dispatch(getCompany({ companyId }));
                return res;
            } else {
                throw res;
            }
        } catch (e) {
            return rejectWithValue(getError(e));
        }
    },
);

export const deleteCompany = createAsyncThunk<
    any,
    {
        companyId: number;
    },
    {
        rejectValue: string;
    }
>('clientCard/deleteCompany', async ({ ...props }, { rejectWithValue }) => {
    try {
        const res = await clientDatabaseApi.deleteCompany({ ...props });
        if (isResponseOK(res)) {
            return res;
        } else {
            throw res;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const getExternalContacts = createAsyncThunk<
    GetExternalContactsType,
    GetExternalContactsPropsType,
    {
        rejectValue: string;
    }
>('clientCard/getExternalContacts', async ({ ...props }, { rejectWithValue }) => {
    try {
        const res = await clientDatabaseApi.getExternalContacts({ ...props });
        if (res?.message === 'Success') {
            const contacts = res.data;
            const phones = contacts.phones.map((phone) => ({
                phoneNumber: normalizePhone(phone.phoneNumber),
                additInfo: phone.additInfo || '',
                isError: false,
            }));
            const emails = contacts.emails.map((email) => ({
                email,
            }));
            return {
                contactName: contacts.contactName,
                phones,
                emails,
            } as GetExternalContactsType;
        } else {
            throw res?.data;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

const initialState: InitialStateType = {
    company: null,
    isRequestFulfilled: {
        comment: false,
        reminder: false,
        note: false,
        contact: false,
        companyDeleting: false,
    },
    loading: {
        company: false,
        contact: false,
        addingReminder: false,
        comment: false,
        note: false,
        companyDeleting: false,
        getExternalContacts: false,
    },
    error: '',
};

export const clientCardSlice = createSlice({
    name: 'clientCard',
    initialState,
    reducers: {
        clearClientCardState: (state) => {
            state = initialState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCompany.pending, (state) => {
            state.loading.company = true;
        });
        builder.addCase(getCompany.fulfilled, (state, { payload }) => {
            state.loading.company = false;
            state.company = payload;
        });
        builder.addCase(getCompany.rejected, (state, { payload }) => {
            state.loading.company = false;
            state.error = payload as string;
        });
        builder.addCase(createCompanyContact.pending, (state) => {
            state.loading.contact = true;
            state.isRequestFulfilled.contact = false;
        });
        builder.addCase(createCompanyContact.fulfilled, (state) => {
            state.loading.contact = false;
            state.isRequestFulfilled.contact = true;
        });
        builder.addCase(createCompanyContact.rejected, (state, { payload }) => {
            state.loading.contact = false;
            state.error = payload as string;
        });
        builder.addCase(updateCompanyContact.pending, (state) => {
            state.loading.contact = true;
            state.isRequestFulfilled.contact = false;
        });
        builder.addCase(updateCompanyContact.fulfilled, (state) => {
            state.loading.contact = false;
            state.isRequestFulfilled.contact = true;
        });
        builder.addCase(updateCompanyContact.rejected, (state, { payload }) => {
            state.loading.contact = false;
            state.error = payload as string;
        });
        builder.addCase(deleteCompanyContact.rejected, (state, { payload }) => {
            if (typeof payload !== 'string') return;
            toast.error(payload);
            state.error = payload as string;
        });
        builder.addCase(createCompanyReminder.pending, (state) => {
            state.loading.addingReminder = true;
            state.isRequestFulfilled.reminder = false;
        });
        builder.addCase(createCompanyReminder.fulfilled, (state) => {
            state.loading.addingReminder = false;
            state.isRequestFulfilled.reminder = true;
        });
        builder.addCase(createCompanyReminder.rejected, (state, { payload }) => {
            state.loading.addingReminder = false;
            if (typeof payload !== 'string') return;
            toast.error(payload);
            state.error = payload as string;
        });
        builder.addCase(createCompanyComment.pending, (state) => {
            state.loading.comment = true;
            state.isRequestFulfilled.comment = false;
        });
        builder.addCase(createCompanyComment.fulfilled, (state) => {
            state.loading.comment = false;
            state.isRequestFulfilled.comment = true;
        });
        builder.addCase(createCompanyComment.rejected, (state, { payload }) => {
            state.loading.comment = false;
            if (typeof payload !== 'string') return;
            toast.error(payload);
            state.error = payload as string;
        });
        builder.addCase(updateCompanyComment.pending, (state) => {
            state.loading.comment = true;
            state.isRequestFulfilled.comment = false;
        });
        builder.addCase(updateCompanyComment.fulfilled, (state) => {
            state.loading.comment = false;
            state.isRequestFulfilled.comment = true;
        });
        builder.addCase(updateCompanyComment.rejected, (state, { payload }) => {
            state.loading.comment = false;
            if (typeof payload !== 'string') return;
            toast.error(payload);
            state.error = payload as string;
        });
        builder.addCase(createCompanyNote.pending, (state) => {
            state.loading.note = true;
            state.isRequestFulfilled.note = false;
        });
        builder.addCase(createCompanyNote.fulfilled, (state) => {
            state.loading.note = false;
            state.isRequestFulfilled.note = true;
        });
        builder.addCase(createCompanyNote.rejected, (state, { payload }) => {
            state.loading.note = false;
            if (typeof payload !== 'string') return;
            toast.error(payload);
            state.error = payload as string;
        });
        builder.addCase(updateCompanyNote.pending, (state) => {
            state.loading.note = true;
            state.isRequestFulfilled.note = false;
        });
        builder.addCase(updateCompanyNote.fulfilled, (state) => {
            state.loading.note = false;
            state.isRequestFulfilled.note = true;
        });
        builder.addCase(updateCompanyNote.rejected, (state, { payload }) => {
            state.loading.note = false;
            if (typeof payload !== 'string') return;
            toast.error(payload);
            state.error = payload as string;
        });
        builder.addCase(deleteCompany.pending, (state) => {
            state.loading.companyDeleting = true;
            state.isRequestFulfilled.companyDeleting = false;
        });
        builder.addCase(deleteCompany.fulfilled, (state) => {
            state.loading.companyDeleting = false;
            state.isRequestFulfilled.companyDeleting = true;
        });
        builder.addCase(deleteCompany.rejected, (state, { payload }) => {
            state.loading.companyDeleting = false;
            if (typeof payload !== 'string') return;
            toast.error(payload);
            state.error = payload as string;
        });
        builder.addCase(getExternalContacts.pending, (state) => {
            state.loading.getExternalContacts = true;
        });
        builder.addCase(getExternalContacts.fulfilled, (state) => {
            state.loading.getExternalContacts = false;
        });
        builder.addCase(getExternalContacts.rejected, (state, { payload }) => {
            state.loading.getExternalContacts = false;
            if (typeof payload !== 'string') return;
            toast.error(payload);
            state.error = payload as string;
        });
    },
});

export const { clearClientCardState } = clientCardSlice.actions;

export const selectClientCard = (state: RootState) => state.clientCard;
