import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import clsx from 'clsx';

import BagAddIcon from '../../../assets/images/clients/bag-add-icon.png';
import SquareWithArrowUpIcon from '../../../assets/images/clients/square-with-arrow-up-icon.png';
import ThreeDots from '../../../assets/images/three-dots.png';
import SearchField from '../../../components/Common/SearchField/SearchField';
import CustomSelect from '../../../components/Common/CustomSelect/CustomSelect';
import {
    bankGuaranteeTypes,
    federalLaws,
    partnerFilterFieldNameType,
    partnerFilterFields,
    partnerFilterFieldType,
} from '../../../utils/data';
import {
    getAllPartners,
    getProductList,
    selectPartner,
    setNeedUpdatePartnerList,
} from '../../../store/slices/partnerSlice';
import { useAppDispatch } from '../../../store';
import {
    FilterParams,
    GetOptionsArgsTypes,
    listsNames,
    SearchParams,
    SearchParamsFieldNames,
} from './Filter.types';
import { formatNum } from '../../../utils/functions';
import { userSelector } from '../../../store/slices/userSlice';
import { defaultFilterParams } from '../Partners';
import StyledFilter from './Filter.style';
import { bankProductCategoryEnum } from '../../../api/partnerApi/partnerApi.types';

type PartnerFilterProps = {
    filter: FilterParams;
    isFilterEmpty: boolean;
    // eslint-disable-next-line no-unused-vars
    setFilter: (value: FilterParams) => void;
    isFilterOpen: boolean;
    // eslint-disable-next-line no-unused-vars
    setFilterOpen: (value: boolean) => void;
    // eslint-disable-next-line no-unused-vars
    setAddPartnerOpen: (value: boolean) => void;
};

const PartnerFilter = ({
    setAddPartnerOpen,
    isFilterOpen,
    setFilterOpen,
    setFilter,
    filter,
    isFilterEmpty,
}: PartnerFilterProps) => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const { needUpdatePartnerList, productList, partnersCount, productsByFilter } =
        useSelector(selectPartner);
    const { user } = useSelector(userSelector);
    const firstPageUpdate = useRef(true);
    const countOfProducts = isFilterEmpty ? 0 : productsByFilter;

    const [searchTerm] = useState('');
    const [isFilterRestoredAfterReload, setFilterRestoredAfterReload] = useState(false);
    const promiseRef = useRef<{ abort: () => void } | undefined>();

    const handleFilter = () => {
        let federalLaw = filter.federalLaw || undefined;
        let guaranteeType = filter.guaranteeType || undefined;

        if (federalLaw === 'commerce') {
            if (guaranteeType === 'applicationSecurity') {
                guaranteeType = undefined;
            } else if (guaranteeType) {
                guaranteeType = `commercial${guaranteeType?.[0]?.toUpperCase()}${guaranteeType?.slice(
                    1,
                )}`;
            }
        } else if (federalLaw && guaranteeType && guaranteeType !== 'applicationSecurity') {
            guaranteeType = `bank${guaranteeType?.[0]?.toUpperCase()}${guaranteeType?.slice(1)}`;
        }

        const customerFromList =
            filter.federalLaw === 'fz223' && Number(filter.customerFromList)
                ? filter.customerFromList
                : undefined;
        const termStr = filter.term?.split(' ').join('');
        const term = Number(termStr) || undefined;
        const limitStr = filter.limit?.split(' ').join('');
        const limit = Number(limitStr) || undefined;
        const searchParams = isFilterOpen
            ? {
                  federalLaw,
                  guaranteeType,
                  customerFromList,
                  term,
                  limit,
              }
            : {};

        if (isFilterEmpty && isFilterOpen) return;
        promiseRef.current?.abort();
        promiseRef.current = dispatch(
            getAllPartners({
                ...searchParams,
                input: !isFilterOpen && filter.input ? filter.input : undefined,
                withAllProducts: !isFilterOpen,
                onlyEnabled: isFilterOpen,
                categories: isFilterOpen
                    ? [
                          bankProductCategoryEnum.BANK_GUARANTEE,
                          bankProductCategoryEnum.COMMERCIAL_GUARANTEE,
                      ]
                    : undefined,
            }),
        );
    };

    const listsForSelect = (type: listsNames, fieldName?: partnerFilterFieldNameType) => {
        switch (type) {
            case 'federalLaw':
                return federalLaws;
            case 'guaranteeType':
                return bankGuaranteeTypes.filter(
                    (type) =>
                        fieldName !== 'guaranteeType' ||
                        filter.federalLaw !== 'commerce' ||
                        type?.name !== 'applicationSecurity',
                );
            case 'customerFromList':
                return [{ bankProductListId: 0, name: 'Все' }, ...productList];
            default:
                return [];
        }
    };

    const getOptions = (item: GetOptionsArgsTypes) => {
        let key: string = '';
        if ('bankProductListId' in item) {
            key = String(item.bankProductListId);
        } else if ('name' in item) {
            key = item.name;
        }

        let value: string | number = '';
        if ('bankProductListId' in item) {
            value = item.bankProductListId;
        } else if ('name' in item) {
            value = item.name;
        }

        let label: string = '';
        if ('label' in item) {
            label = item.label;
        } else if ('name' in item) {
            label = item.name;
        }

        return (
            <option key={key} value={value}>
                {label}
            </option>
        );
    };

    const serializeSearchParams = (obj: SearchParams) => {
        const str: string[] = [];
        Object.getOwnPropertyNames(obj).forEach((key) => {
            if (key in obj && obj[key as SearchParamsFieldNames]) {
                const encodedKey = encodeURIComponent(key);
                const encodedValue = encodeURIComponent(obj[key as SearchParamsFieldNames]);
                str.push(`${encodedKey}=${encodedValue}`);
            }
        });
        return str.join('&');
    };

    const saveFilter = () => {
        const query = serializeSearchParams({
            ...filter,
        });
        navigate({
            ...location,
            search: query,
        });
    };

    const selectChangeHandler = (value: string, fieldName: listsNames) => {
        const newFilter = { ...filter };

        if (fieldName === 'federalLaw') {
            if (value !== 'fz223') {
                newFilter['customerFromList'] = 0;
            }

            if (!value) {
                newFilter['guaranteeType'] = '';
            } else if (value === 'commerce' && filter.guaranteeType === 'applicationSecurity') {
                newFilter['guaranteeType'] = '';
            }
        }

        setFilter({
            ...newFilter,
            [fieldName]: value,
        });
    };

    const getFilterField = (field: partnerFilterFieldType) => {
        switch (field.type) {
            case 'list':
                return (
                    <CustomSelect
                        width={262}
                        height={20}
                        backgroundImage={ThreeDots}
                        backgroundColor="#f9f9f9"
                    >
                        <select
                            className="custom-select__select custom-select__field-with-padding"
                            disabled={Boolean(
                                (filter.federalLaw !== 'fz223' &&
                                    field.name === 'customerFromList') ||
                                    (!filter.federalLaw && field.name === 'guaranteeType'),
                            )}
                            value={filter[field.name as listsNames]}
                            onChange={(e) =>
                                selectChangeHandler(e.target.value, field.name as listsNames)
                            }
                        >
                            {listsForSelect(field.name as listsNames, field?.name).map((item) => {
                                return getOptions(item);
                            })}
                        </select>
                    </CustomSelect>
                );
            case 'input':
                return (
                    <input
                        className="filter__input"
                        value={filter[field.name as 'term' | 'limit']}
                        onChange={(e) =>
                            setFilter({
                                ...filter,
                                [field.name]: formatNum(
                                    e.target.value,
                                    String(filter[field.name as 'term' | 'limit']),
                                    true,
                                ),
                            })
                        }
                    />
                );
            default:
                return null;
        }
    };

    useEffect(() => {
        if (needUpdatePartnerList) {
            handleFilter();
            dispatch(setNeedUpdatePartnerList(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needUpdatePartnerList]);

    useEffect(() => {
        if (firstPageUpdate.current) {
            return;
        }

        handleFilter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFilterOpen]);

    useEffect(() => {
        if (firstPageUpdate.current) {
            firstPageUpdate.current = false;
            return;
        }
        saveFilter();
        handleFilter();

        if (!isFilterRestoredAfterReload && queryString.parse(location.search)) {
            setFilterRestoredAfterReload(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, searchTerm]);

    useEffect(() => {
        dispatch(getProductList({}));

        const queryStringParams = queryString.parse(location.search);
        if (!queryStringParams) return;

        const federalLaw = String(queryStringParams?.federalLaw || '');
        const guaranteeType = String(queryStringParams?.guaranteeType || '');
        const customerFromList = Number(queryStringParams?.customerFromList || 0);
        const term = String(queryStringParams?.term || '');
        const limit = String(queryStringParams?.limit || '');
        const input = String(queryStringParams?.input || '');
        const newFilter = {
            federalLaw,
            guaranteeType,
            term,
            limit,
            input,
            customerFromList,
        };
        setFilter(newFilter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StyledFilter>
            <div
                className={clsx('filter__header', {
                    'filter__header-without-search': isFilterOpen,
                })}
            >
                <div className="filter__partners-count">
                    {!isFilterOpen ? (
                        <p>
                            Партнеры сервиса: <span> всего партнеров </span> ({partnersCount})
                        </p>
                    ) : (
                        <p>
                            Поиск по фильтрам: <span> Найдено </span> {countOfProducts}
                        </p>
                    )}
                </div>
                {!isFilterOpen && (
                    <>
                        <div className="filter__search">
                            <SearchField
                                background="#D9D9D9"
                                placeholder="Быстрый поиск партнера (название)"
                                searchTerm={filter.input}
                                search={(e) =>
                                    setFilter({
                                        ...filter,
                                        input: e,
                                    })
                                }
                            />
                            {user?.isAdmin && (
                                <div
                                    className="filter__add-client-btn"
                                    onClick={() => setAddPartnerOpen(true)}
                                >
                                    <img src={BagAddIcon} />
                                    <p>Добавить партнера</p>
                                </div>
                            )}
                        </div>
                    </>
                )}
                <div
                    className={clsx('filter__minimize-filter-btn', {
                        'filter__minimized-filter': !isFilterOpen,
                    })}
                    onClick={() => setFilterOpen(!isFilterOpen)}
                >
                    <p>{isFilterOpen ? 'Свернуть' : 'Развернуть'} фильтр</p>
                    <img src={SquareWithArrowUpIcon} />
                </div>
            </div>
            {isFilterOpen && (
                <div className="filter__body">
                    {partnerFilterFields.map((field) => (
                        <div key={field.label} className="filter__field">
                            <p>{field.label}</p>
                            {getFilterField(field)}
                        </div>
                    ))}
                </div>
            )}
            {isFilterOpen && (
                <p onClick={() => setFilter(defaultFilterParams)} className="filter__apply-btn">
                    Сбросить фильтр
                </p>
            )}
        </StyledFilter>
    );
};

export default PartnerFilter;
