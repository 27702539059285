import React from 'react';

import { BankProductsState } from '../../../utils/data';
import { BankProductCreateInterface, BankProductType } from '../../../utils/types';
import BankProduct from './BankProduct/BankProduct';
import StyledDocContainer from './BankProductList.style';
import BankProductHeadings from './BankProductHeadings/BankProductHeadings';

type BankProductListProps = {
    bankProducts: BankProductsState;
    // eslint-disable-next-line no-unused-vars
    setBankProducts: (value: BankProductsState) => void;
};

const BankProductList = ({ bankProducts, setBankProducts }: BankProductListProps) => {
    const changeBankProductState = (value: BankProductCreateInterface, type: BankProductType) => {
        const newValue = { ...bankProducts };
        newValue[type] = { ...value };
        setBankProducts(newValue);
    };

    return (
        <StyledDocContainer>
            <div className="bank-product-list__header">
                <h4>Продукты</h4>
            </div>
            {Object.keys(bankProducts).map((key, index) => (
                <div key={key}>
                    <BankProductHeadings productIndex={index} />
                    <BankProduct
                        index={index}
                        bankProduct={bankProducts[key as BankProductType]}
                        productType={key as BankProductType}
                        setProduct={(value: BankProductCreateInterface, type: BankProductType) =>
                            changeBankProductState(value, type)
                        }
                    />
                </div>
            ))}
        </StyledDocContainer>
    );
};

export default BankProductList;
