import React, { useEffect, useState } from 'react';

import { timeOptions } from '../../../utils/consts';
import StyledTimer from './Timer.style';

const Timer = () => {
    const [time, setTime] = useState('');
    const getTimer = () => {
        const now = new Date();
        setTime(now.toLocaleString('ru-RU', timeOptions));
    };

    useEffect(() => {
        const intervalId = setInterval(getTimer, 1000);
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return <StyledTimer>{time}</StyledTimer>;
};

export default Timer;
