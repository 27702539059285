import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';

import { getError, isResponseOK } from '../../../utils/functions';
import { RootState } from '../..';
import { InitialStateType } from './types';
import { ActInterface, ReceiverInterface } from '../../../utils/types';
import { StatusResponseType } from '../../../api/clientDatabaseApi/clientDatabaseApi.types';
import { actsApi } from '../../../api/actsApi/actsApi';
import {
    CreateActPropsType,
    CreateActReceiverPropsType,
    DeleteActPropsType,
    GetActFilePropsType,
    GetAllActsPropsType,
    UpdateActReceiverPropsType,
    uploadSigningActProps,
} from '../../../api/actsApi/actsApi.types';

export const getAllActs = createAsyncThunk<
    {
        acts: ActInterface[];
        count: number;
        amount: number;
    },
    GetAllActsPropsType,
    {
        rejectValue: string;
    }
>('clientAct/getAllActs', async ({ ...props }, { rejectWithValue }) => {
    try {
        const res = await actsApi.getActsList({ ...props });
        if (res?.message === 'Success') {
            return {
                acts: res.data.actsList,
                count: res.data.total.records,
                amount: res.data.total.amount,
            };
        } else {
            throw res?.data;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const createAct = createAsyncThunk<
    StatusResponseType,
    CreateActPropsType,
    {
        rejectValue: string;
    }
>('clientAct/createAct', async ({ companyId, ...props }, { rejectWithValue, dispatch }) => {
    try {
        const res = await actsApi.createAct({ ...props, companyId });
        if (isResponseOK(res)) {
            dispatch(getAllActs({ companyId }));
            return res;
        } else {
            throw res;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const createReceiver = createAsyncThunk<
    StatusResponseType,
    CreateActReceiverPropsType,
    {
        rejectValue: string;
    }
>('clientAct/createReceiver', async ({ companyId, ...props }, { rejectWithValue, dispatch }) => {
    try {
        const res = await actsApi.createActReceiver({ ...props, companyId });
        if (isResponseOK(res)) {
            dispatch(getCompanyReceivers({ companyId }));
            return res;
        } else {
            throw res;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const getCompanyReceivers = createAsyncThunk<
    ReceiverInterface[],
    GetAllActsPropsType,
    {
        rejectValue: string;
    }
>('clientAct/getCompanyReceivers', async ({ ...props }, { rejectWithValue }) => {
    try {
        const res = await actsApi.getCompanyReceivers({ ...props });
        if (res?.message === 'Success') {
            return res.data;
        } else {
            throw res?.data;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const updateCompanyReceiver = createAsyncThunk<
    StatusResponseType,
    UpdateActReceiverPropsType,
    {
        rejectValue: string;
    }
>(
    'clientAct/updateCompanyReceiver',
    async ({ companyId, ...props }, { rejectWithValue, dispatch }) => {
        try {
            const res = await actsApi.updateActReceiver({ companyId, ...props });
            if (isResponseOK(res)) {
                dispatch(getCompanyReceivers({ companyId }));
                return res;
            } else {
                throw res;
            }
        } catch (e) {
            return rejectWithValue(getError(e));
        }
    },
);

export const getActFile = createAsyncThunk<
    string,
    GetActFilePropsType,
    {
        rejectValue: string;
    }
>('clientAct/getActFile', async ({ ...props }, { rejectWithValue }) => {
    try {
        const res = await actsApi.getActFile({ ...props });
        if (res?.message === 'Success') {
            return res.data;
        } else {
            throw res?.data;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const uploadSigningAct = createAsyncThunk<
    StatusResponseType,
    uploadSigningActProps,
    {
        rejectValue: string;
    }
>('clientAct/uploadSigningAct', async ({ companyId, ...props }, { rejectWithValue, dispatch }) => {
    try {
        const res = await actsApi.uploadSigningAct({ companyId, ...props });
        if (isResponseOK(res)) {
            dispatch(getAllActs({ companyId }));
            return res;
        } else {
            throw res;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const deleteAct = createAsyncThunk<
    StatusResponseType,
    DeleteActPropsType,
    {
        rejectValue: string;
    }
>('clientAct/deleteAct', async ({ companyId, ...props }, { rejectWithValue, dispatch }) => {
    try {
        const res = await actsApi.deleteAct({ companyId, hardDelete: true, ...props });
        if (isResponseOK(res)) {
            dispatch(getAllActs({ companyId }));
            return res;
        } else {
            throw res;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

const initialState: InitialStateType = {
    companyReceivers: [],
    clientActs: [],
    actsCount: 0,
    actsAmount: 0,
    currentActDoc: null,
    loading: {
        createAct: false,
        crudReceiver: false,
        currentActDoc: false,
        deleteAct: false,
    },
    isRequestFulfilled: {
        deleteAct: false,
        createAct: false,
        crudReceiver: false,
        currentActDoc: false,
    },
};

export const clientActSlice = createSlice({
    name: 'clientAct',
    initialState,
    reducers: {
        clearClientActState: (state) => {
            state = initialState;
            return state;
        },
        clearCurrentActDoc: (state) => {
            state.currentActDoc = initialState.currentActDoc;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCompanyReceivers.fulfilled, (state, { payload }) => {
            state.companyReceivers = payload;
        });
        builder.addCase(getCompanyReceivers.rejected, (state, { payload }) => {
            if (typeof payload !== 'string') return;
            toast.error(payload);
        });
        builder.addCase(createAct.pending, (state) => {
            state.loading.createAct = true;
            state.isRequestFulfilled.createAct = false;
        });
        builder.addCase(createAct.fulfilled, (state) => {
            state.isRequestFulfilled.createAct = true;
            state.loading.createAct = false;
        });
        builder.addCase(createAct.rejected, (state, { payload }) => {
            state.loading.createAct = false;
            if (typeof payload !== 'string') return;
            toast.error(payload);
        });
        builder.addCase(getAllActs.fulfilled, (state, { payload }) => {
            state.clientActs = payload.acts;
            state.actsCount = payload.count;
            state.actsAmount = payload.amount;
        });
        builder.addCase(getAllActs.rejected, (state, { payload }) => {
            if (typeof payload !== 'string') return;
            toast.error(payload);
        });
        builder.addCase(createReceiver.pending, (state) => {
            state.loading.crudReceiver = true;
            state.isRequestFulfilled.crudReceiver = false;
        });
        builder.addCase(createReceiver.fulfilled, (state) => {
            state.isRequestFulfilled.crudReceiver = true;
            state.loading.crudReceiver = false;
        });
        builder.addCase(createReceiver.rejected, (state, { payload }) => {
            state.loading.crudReceiver = false;
            if (typeof payload !== 'string') return;
            toast.error(payload);
        });
        builder.addCase(updateCompanyReceiver.pending, (state) => {
            state.loading.crudReceiver = true;
            state.isRequestFulfilled.crudReceiver = false;
        });
        builder.addCase(updateCompanyReceiver.fulfilled, (state) => {
            state.isRequestFulfilled.crudReceiver = true;
            state.loading.crudReceiver = false;
        });
        builder.addCase(updateCompanyReceiver.rejected, (state, { payload }) => {
            state.loading.crudReceiver = false;
            if (typeof payload !== 'string') return;
            toast.error(payload);
        });
        builder.addCase(getActFile.pending, (state) => {
            state.loading.currentActDoc = true;
            state.isRequestFulfilled.currentActDoc = false;
        });
        builder.addCase(getActFile.fulfilled, (state, { payload }) => {
            state.isRequestFulfilled.currentActDoc = true;
            state.loading.currentActDoc = false;
            state.currentActDoc = payload;
        });
        builder.addCase(getActFile.rejected, (state, { payload }) => {
            state.loading.currentActDoc = false;
            if (typeof payload !== 'string') return;
            toast.error(payload);
        });
        builder.addCase(deleteAct.pending, (state) => {
            state.loading.deleteAct = true;
            state.isRequestFulfilled.deleteAct = false;
        });
        builder.addCase(deleteAct.fulfilled, (state) => {
            state.loading.deleteAct = false;
            state.isRequestFulfilled.deleteAct = true;
        });
        builder.addCase(deleteAct.rejected, (state, { payload }) => {
            state.loading.deleteAct = false;
            if (typeof payload !== 'string') return;
            toast.error(payload);
        });
    },
});

export const { clearClientActState, clearCurrentActDoc } = clientActSlice.actions;

export const selectClientAct = (state: RootState) => state.clientAct;
