import {
    partnerApiPath,
    GetPartnersResponseType,
    GetOnePartnerPropsType,
    GetOnePartnerResponseType,
    GetStopRegionsResponseType,
    GetProductListResponseType,
    CreateProductListPropsType,
    UpdatePartnerPropsType,
    CreatePartnerPropsType,
    StatusResponseType,
    CreatePartnerResponseType,
    UpdateBankProductProps,
    GetAllPartnersPropsType,
    UpdateCredentialProps,
    UpdateCredentialContactProps,
    CreateCredentialContactProps,
    CreateBankDocProps,
    DeleteBankDocProps,
    CreateBankProductDocProps,
    DeleteBankProductDocProps,
    CreateCredentialProps,
    DeleteProductListProps,
    GetAllCredentialsPropsType,
    GetAllCredentialsResponseType,
} from './partnerApi.types';
import instance from '../axiosInstance';

export const partnerApi = {
    createPartner: async ({
        ...props
    }: CreatePartnerPropsType): Promise<CreatePartnerResponseType> => {
        try {
            const res = await instance.post(partnerApiPath.CRUD_PARTNER, {
                ...props,
            });
            return res.data;
        } catch (error) {
            console.log(error);
        }
    },
    getAllPartners: async ({
        ...props
    }: GetAllPartnersPropsType): Promise<GetPartnersResponseType> => {
        try {
            const { abortSignal, ...params } = props;

            const response = await instance.get(partnerApiPath.CRUD_PARTNER, {
                params,
                signal: abortSignal,
            });
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },
    getOnePartner: async ({
        bankId,
    }: GetOnePartnerPropsType): Promise<GetOnePartnerResponseType> => {
        try {
            const response = await instance.get(`${partnerApiPath.CRUD_PARTNER}/${bankId}`);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },
    getStopRegions: async (): Promise<GetStopRegionsResponseType> => {
        try {
            const response = await instance.get(partnerApiPath.STOP_REGIONS);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },
    getProductList: async (): Promise<GetProductListResponseType> => {
        try {
            const response = await instance.get(partnerApiPath.PRODUCT_LIST);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },
    createProductList: async ({
        ...props
    }: CreateProductListPropsType): Promise<StatusResponseType> => {
        try {
            const res = await instance.post(partnerApiPath.PRODUCT_LIST, {
                ...props,
            });
            return res.status;
        } catch (error) {
            console.log(error);
        }
    },
    deleteProductList: async ({
        productListId,
    }: DeleteProductListProps): Promise<StatusResponseType> => {
        try {
            const res = await instance.delete(`${partnerApiPath.PRODUCT_LIST}/${productListId}`);
            return res.status;
        } catch (error) {
            console.log(error);
        }
    },
    updatePartner: async ({
        bankId,
        ...props
    }: UpdatePartnerPropsType): Promise<StatusResponseType> => {
        try {
            const res = await instance.patch(`${partnerApiPath.CRUD_PARTNER}/${bankId}`, {
                ...props,
            });
            return res.status;
        } catch (error) {
            console.log(error);
        }
    },
    updateBankProduct: async ({
        bankId,
        bankProductId,
        ...props
    }: UpdateBankProductProps): Promise<StatusResponseType> => {
        try {
            const res = await instance.patch(
                `${partnerApiPath.CRUD_PARTNER}/${bankId}${partnerApiPath.BANK_PRODUCT}/${bankProductId}`,
                {
                    ...props,
                },
            );
            return res.status;
        } catch (error) {
            console.log(error);
        }
    },
    createBankCredential: async ({
        bankId,
        ...props
    }: CreateCredentialProps): Promise<StatusResponseType> => {
        try {
            const res = await instance.post(
                `${partnerApiPath.CRUD_PARTNER}/${bankId}${partnerApiPath.BANK_CREDENTIALS}`,
                {
                    ...props,
                },
            );
            return res.status;
        } catch (error) {
            console.log(error);
        }
    },
    updateBankCredential: async ({
        bankId,
        credentialId,
        ...props
    }: UpdateCredentialProps): Promise<StatusResponseType> => {
        try {
            const res = await instance.patch(
                `${partnerApiPath.CRUD_PARTNER}/${bankId}${partnerApiPath.BANK_CREDENTIALS}/${credentialId}`,
                {
                    ...props,
                },
            );
            return res.status;
        } catch (error) {
            console.log(error);
        }
    },
    updateCredentialContact: async ({
        bankId,
        credentialId,
        contactId,
        ...props
    }: UpdateCredentialContactProps): Promise<StatusResponseType> => {
        try {
            const res = await instance.patch(
                `${partnerApiPath.CRUD_PARTNER}/${bankId}${partnerApiPath.BANK_CREDENTIALS}/${credentialId}${partnerApiPath.BANK_CONTACTS}/${contactId}`,
                {
                    ...props,
                },
            );
            return res.status;
        } catch (error) {
            console.log(error);
        }
    },
    createCredentialContact: async ({
        bankId,
        credentialId,
        ...props
    }: CreateCredentialContactProps): Promise<StatusResponseType> => {
        try {
            const res = await instance.post(
                `${partnerApiPath.CRUD_PARTNER}/${bankId}${partnerApiPath.BANK_CREDENTIALS}/${credentialId}${partnerApiPath.BANK_CONTACTS}`,
                {
                    ...props,
                },
            );
            return res.status;
        } catch (error) {
            console.log(error);
        }
    },
    deleteCredentialContact: async ({
        bankId,
        credentialId,
        contactId,
    }: UpdateCredentialContactProps): Promise<StatusResponseType> => {
        try {
            const res = await instance.delete(
                `${partnerApiPath.CRUD_PARTNER}/${bankId}${partnerApiPath.BANK_CREDENTIALS}/${credentialId}${partnerApiPath.BANK_CONTACTS}/${contactId}`,
            );
            return res.status;
        } catch (error) {
            console.log(error);
        }
    },
    getAllCredentials: async ({
        ...props
    }: GetAllCredentialsPropsType): Promise<GetAllCredentialsResponseType> => {
        try {
            const res = await instance.get(
                `${partnerApiPath.CRUD_PARTNER}${partnerApiPath.BANK_CREDENTIALS}`,
                {
                    params: {
                        ...props,
                    },
                },
            );
            return res.data;
        } catch (error) {
            console.log(error);
        }
    },
    createBankDoc: async ({
        bankId,
        ...props
    }: CreateBankDocProps): Promise<StatusResponseType> => {
        const res = await instance.post(
            `${partnerApiPath.CRUD_PARTNER}/${bankId}${partnerApiPath.CRUD_DOCUMENTS}`,
            {
                ...props,
            },
        );
        return res?.status;
    },
    deleteBankDoc: async ({
        bankId,
        documentId,
    }: DeleteBankDocProps): Promise<StatusResponseType> => {
        const res = await instance.delete(
            `${partnerApiPath.CRUD_PARTNER}/${bankId}${partnerApiPath.CRUD_DOCUMENTS}/${documentId}`,
        );
        return res?.status;
    },
    createBankProductDoc: async ({
        bankId,
        productId,
        ...props
    }: CreateBankProductDocProps): Promise<StatusResponseType> => {
        const res = await instance.post(
            `${partnerApiPath.CRUD_PARTNER}/${bankId}${partnerApiPath.BANK_PRODUCT}/${productId}${partnerApiPath.CRUD_DOCUMENTS}`,
            {
                ...props,
            },
        );
        return res?.status;
    },
    deleteBankProductDoc: async ({
        bankId,
        productId,
        documentId,
    }: DeleteBankProductDocProps): Promise<StatusResponseType> => {
        const res = await instance.delete(
            `${partnerApiPath.CRUD_PARTNER}/${bankId}${partnerApiPath.BANK_PRODUCT}/${productId}${partnerApiPath.CRUD_DOCUMENTS}/${documentId}`,
        );
        return res?.status;
    },
};
