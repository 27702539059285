import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { selectClientDocument } from '../../../../store/slices/clientDocumentSlice';
import { DocumentSectionsType } from '../../../../utils/data';
import { userSelector } from '../../../../store/slices/userSlice';
import GearsIcon from '../../../../assets/images/clients/gears-icon.png';
import CategoryItem from './CategoryItem/CategoryItem';
import EditDocumentSection from '../../../../components/EditDocumentSection/EditDocumentSection';
import StyledDocumentCategoryList from './DocumentCategoryList.style';

type DocumentCategoryListProps = {
    section: DocumentSectionsType;
};

const DocumentCategoryList = ({ section }: DocumentCategoryListProps) => {
    const { companyDocumentCategories } = useSelector(selectClientDocument);
    const { user } = useSelector(userSelector);
    const [isEdtModalOpen, setEdtModalOpen] = useState(false);
    const categoryList =
        companyDocumentCategories?.filter((category) => category.section === section.sectionName) ||
        [];

    return (
        <StyledDocumentCategoryList>
            <div className="base-documents__header">
                <p>{section.title}</p>
                {user?.isAdmin && (
                    <div className="base-documents__actions" onClick={() => setEdtModalOpen(true)}>
                        <p>Редактировать список</p>
                        <img src={GearsIcon} />
                    </div>
                )}
            </div>
            {categoryList.map((category, index) => (
                <CategoryItem
                    key={category.companyDocumentCategoryId}
                    category={category}
                    index={index}
                />
            ))}
            {isEdtModalOpen ? (
                <EditDocumentSection
                    close={() => setEdtModalOpen(false)}
                    width={400}
                    section={section}
                    categories={categoryList.filter((category) => category.editable)}
                />
            ) : null}
        </StyledDocumentCategoryList>
    );
};

export default DocumentCategoryList;
