import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, Navigate } from 'react-router-dom';
import { RotatingLines } from 'react-loader-spinner';

import { authRoutes, publicRoutes, clientsDatabaseRoutes } from '../routes';
import { userSelector } from '../store/slices/userSlice';
import ProtectedRoute from './ProtectedRoute';
import { Routes as RoutesConstant } from '../utils/consts';

const AppRouter = () => {
    const { isAuth } = useSelector(userSelector);
    const { user } = useSelector(userSelector);
    const enableCallsPage = user?.isAdmin || user?.isCallRecordEnabled;
    const authRoutesBlock = authRoutes.map(({ link, children, Component }) => {
        const isCallsPage = link === RoutesConstant.CALLS;
        if (isCallsPage && !enableCallsPage) {
            return;
        }
        if (!children) {
            return (
                <Route
                    key={link}
                    element={
                        <ProtectedRoute
                            isAllowed={Boolean(isAuth)}
                            redirectPath={isAuth ? '/clients' : '/login'}
                        />
                    }
                >
                    <Route path={link} element={<Component />} />
                </Route>
            );
        }
        return (
            <Route
                key={link}
                element={
                    <ProtectedRoute
                        isAllowed={Boolean(isAuth)}
                        redirectPath={isAuth ? '/clients' : '/login'}
                    />
                }
            >
                <>
                    <Route path={link} element={<Component />} />
                    {children.map(({ name: childrenName, link: childrenLink, Component }) => (
                        <Route
                            key={link + childrenName}
                            path={childrenLink}
                            element={<Component />}
                        />
                    ))}
                </>
            </Route>
        );
    });

    return (
        <Suspense fallback={<RotatingLines strokeColor="grey" />}>
            <Routes>
                {authRoutesBlock}
                {publicRoutes.map(({ path, Component }) => (
                    <Route key={path} path={path} element={<Component />} />
                ))}
                {clientsDatabaseRoutes.map(({ link, Component }) => (
                    <Route
                        key={link}
                        element={
                            <ProtectedRoute
                                isAllowed={Boolean(isAuth)}
                                redirectPath={isAuth ? '/clients' : '/login'}
                            />
                        }
                    >
                        <Route path={link} element={<Component />} />
                    </Route>
                ))}
                <Route path="*" element={<Navigate to="/clients" />} />
            </Routes>
        </Suspense>
    );
};

export default AppRouter;
