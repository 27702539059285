import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { getDate } from '../../../../utils/functions';
import { selectClientCard } from '../../../../store/slices/clientCardSlice';
import { generalCompanyInfo } from '../../../../utils/data';
import { userSelector } from '../../../../store/slices/userSlice';
import ArrowIcon from '../../../../assets/images/clients/arrow-on-black-square-icon.png';
import GearsIcon from '../../../../assets/images/clients/gears-icon.png';
import AddContact from '../../../../components/AddContact/AddContact';
import ContactItem from './ContactItem/ContactItem';
import EditClient from '../../../../components/EditClient/EditClient';
import StyledCompanyInfo from './CompanyInfo.style';

const CompanyInfo = () => {
    const { company } = useSelector(selectClientCard);
    const { user } = useSelector(userSelector);
    const [isSectionOpen, setSectionOpen] = useState(true);
    const [isAddContactModalOpen, setAddContactModalOpen] = useState(false);
    const [isEditCompanyModalOpen, setEditCompanyModalOpen] = useState(false);
    const managerOrAdmin = user?.isAdmin || company?.manager?.userId === user?.userId;

    const toggleSectionOpenness = () => {
        setSectionOpen((prev) => !prev);
    };

    return (
        <StyledCompanyInfo>
            <div className="company-info__header">
                <h3>Сведения о компании</h3>
                <div
                    onClick={toggleSectionOpenness}
                    className={clsx('company-info__show-section-btn', {
                        'company-info__opened-section': isSectionOpen,
                    })}
                >
                    <p>{isSectionOpen ? 'Свернуть' : 'Развернуть'}</p>
                    <img src={ArrowIcon} />
                </div>
            </div>
            {isSectionOpen && (
                <div className="company-info__body">
                    <div className="company-info__general-information">
                        <div>
                            {generalCompanyInfo.map((info) => (
                                <p
                                    key={info.field}
                                    className="company-info__general-information-row"
                                >
                                    {info.label}{' '}
                                    <span>
                                        {info.field === 'registrationDate'
                                            ? getDate(company?.registrationDate || null, 'date')
                                            : company?.[info.field]}
                                    </span>
                                </p>
                            ))}
                        </div>
                        {managerOrAdmin && (
                            <img src={GearsIcon} onClick={() => setEditCompanyModalOpen(true)} />
                        )}
                    </div>
                    <div className="company-info__contacts-information">
                        <div className="company-info__contacts-section-header">
                            <p>Контакты</p>
                            {managerOrAdmin && (
                                <button onClick={() => setAddContactModalOpen(true)}>
                                    Добавить контакт +
                                </button>
                            )}
                        </div>
                        <div className="company-info__contact-list">
                            {company?.contacts?.map((contact) => (
                                <ContactItem
                                    key={contact.companyContactId}
                                    contact={contact}
                                    companyId={company?.companyId}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            )}
            {isAddContactModalOpen && company?.companyId ? (
                <AddContact
                    width={428}
                    companyId={company?.companyId}
                    close={() => setAddContactModalOpen(false)}
                />
            ) : null}
            {isEditCompanyModalOpen && company ? (
                <EditClient
                    width={400}
                    close={() => setEditCompanyModalOpen(false)}
                    company={company}
                />
            ) : null}
        </StyledCompanyInfo>
    );
};

export default CompanyInfo;
