import styled from 'styled-components/macro';

const StyledDocContainer = styled.div`
    font-weight: 300;
    font-size: 14px;

    .bank-product-list__header {
        line-height: 20px;
        padding: 5px 6px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fdd55b;
        margin-bottom: 2px;
    }

    .bank-product-list__button {
        text-decoration: underline;
        color: #0038ff;
        background: transparent;
        border: none;
    }

    .bank-product-list__table-header {
        background: #d9d9d9;
        padding: 5px 10px;
        display: grid;
        align-items: center;
        grid-template-columns:
            225px minmax(50px, 1fr) repeat(2, 80px) repeat(3, 80px) 60px 70px repeat(2, 80px) 40px
            repeat(2, 80px) 100px;
        grid-column-gap: 10px;

        img {
            width: 20px;
            height: 20px;
        }
    }

    .bank-product-list__table-header-right-position {
        display: flex;
        justify-content: right;
    }

    .bank-product-list__red-column {
        color: #f00;
    }
`;

export default StyledDocContainer;
