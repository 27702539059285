import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import {
    selectClientDocument,
    UpdateCompanyDocumentCategory,
} from '../../../store/slices/clientDocumentSlice';
import { useAppDispatch } from '../../../store';
import CommonModal from '../../Common/CommonModal/CommonModal';
import DefaultLoader from '../../Common/BtnLoader/DefaultLoader';
import StyledContainer from './EditCategory.style';

type EditCategoryProps = {
    close: () => void;
    width: number;
    companyId: number;
    categoryId: number;
    previousCategoryName: string;
    previousBasicName: string;
};

const EditCategory = ({
    close,
    width,
    companyId,
    categoryId,
    previousCategoryName,
    previousBasicName,
}: EditCategoryProps) => {
    const dispatch = useAppDispatch();
    const firstUpdate = useRef(true);
    const { loading, isRequestFulfilled } = useSelector(selectClientDocument);
    const [categoryName, setCategoryName] = useState(previousCategoryName);
    const [basicDocumentName, setBasicDocumentName] = useState(previousBasicName);

    const addCategoryHandler = () => {
        if (!categoryName) return toast.error('Не заполнено имя раздела');
        dispatch(
            UpdateCompanyDocumentCategory({
                companyId,
                categoryId,
                name: categoryName,
                basicDocumentName,
            }),
        );
    };

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if (isRequestFulfilled.updateDocumentCategory) {
            close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRequestFulfilled.updateDocumentCategory]);

    return (
        <CommonModal hasCloseBtn={true} close={close}>
            <StyledContainer width={width}>
                <h3>Редактировать раздел</h3>
                <label>
                    Название раздела::
                    <textarea
                        className="edit-category__category-name"
                        value={categoryName}
                        onChange={(e) => setCategoryName(e.target.value)}
                    />
                </label>
                <label>
                    Форматирование:
                    <input
                        value={basicDocumentName}
                        onChange={(e) => setBasicDocumentName(e.target.value)}
                    />
                </label>
                <button
                    type="button"
                    className="edit-category__action"
                    onClick={addCategoryHandler}
                    disabled={loading.updateDocumentCategory}
                >
                    {loading.updateDocumentCategory ? <DefaultLoader /> : 'Сохранить'}
                </button>
            </StyledContainer>
        </CommonModal>
    );
};

export default EditCategory;
