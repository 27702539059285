import React, { useEffect, useRef, useState } from 'react';
import { clsx } from 'clsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import 'react-phone-input-2/lib/style.css';

import {
    FieldNameTypeInAddingUserForm,
    FieldsInAddingUserFormType,
    fieldsInAddingUserForm,
    documentsInAddUserForm,
} from '../../utils/data';
import {
    createUser,
    getOneUser,
    getUserDocuments,
    selectStaff,
    setSelectedUserDocs,
    updateUser,
} from '../../store/slices/staffSlice';
import { useAppDispatch } from '../../store';
import { IUser, UserDocInterface, UserDocTypes } from '../../utils/types';
import { emailRegExp, phoneRegExp } from '../../utils/consts';
import { downloadAsFile, normalizePhone, normalizeStatusAndPosition } from '../../utils/functions';
import { genPassword, getDigitsFromString } from '../../utils/functions';
import { userUpdatingValidation, userCreationValidation } from '../../validation/addUserValidation';
import DeleteDocIcon from '../../assets/images/staff/delete-doc-icon.png';
import CustomSelect from '../Common/CustomSelect/CustomSelect';
import BulletListIcon from '../../assets/images/bullet-list-icon.png';
import CommonModal from '../Common/CommonModal/CommonModal';
import ToggleSwitch from '../../components/Common/ToggleSwitch/ToggleSwitch';
import AddUserDoc from '../AddFile/AddFile';
import BtnLoader from '../Common/BtnLoader/BtnLoader';
import {
    StyledContainer,
    StyledPhoneInput,
    StyledScrollbars,
    StyledFormWrapper,
} from './AddUser.style';

type Inputs = {
    fullName: string;
    shortName: string;
    positionName: string;
    phone: string;
    additNumber: string;
    email: string;
    birthDate: string;
    status: string;
    bot: boolean;
    isAdmin: boolean;
    isCallRecordEnabled: boolean;
    isFullBankInfoEnabled: boolean;
    login: string;
    password: string;
    passport: string;
    snils: string;
    inn: string;
};

type AddUserProps = {
    // eslint-disable-next-line no-unused-vars
    close: () => void;
    user?: IUser;
    width: number;
};

const AddUser = ({ user, close, width }: AddUserProps) => {
    const firstUpdate = useRef(true);
    const dispatch = useAppDispatch();
    const { loading, isRequestFulfilled, selectedUserDocs } = useSelector(selectStaff);
    const [fileName, setFileName] = useState({
        passport: '',
        inn: '',
        snils: '',
    });
    const [addingDocument, setAddingDocument] = useState<UserDocTypes | null>(null);
    const [isPasswordActive, setPasswordActive] = useState(false);
    const [unloadedFile, setUnloadedFile] = useState<null | UserDocInterface>(null);

    const isBtnDisabled = user ? loading.updateUser : loading.createUser;
    const getDocByType = (type: UserDocTypes) => {
        return selectedUserDocs.find((doc) => doc.type === type);
    };

    const validationSchema = user ? userUpdatingValidation : userCreationValidation;
    const formOptions = {
        defaultValues: {
            fullName: user?.fullName ? user.fullName : '',
            shortName: user?.shortName ? user.shortName : '',
            positionName: user?.positionName ? normalizeStatusAndPosition(user.positionName) : '',
            additNumber: user?.additNumber ? user.additNumber : '',
            email: user?.email ? user.email : '',
            birthDate: user?.birthDate ? user.birthDate : '',
            status: user?.status ? normalizeStatusAndPosition(user.status) : '',
            login: user?.login ? user.login : '',
            bot: user ? user.bot : false,
            isAdmin: user ? user.isAdmin : false,
            isCallRecordEnabled: user ? user.isCallRecordEnabled : false,
            isFullBankInfoEnabled: user ? user.isFullBankInfoEnabled : true,
        },
        resolver: yupResolver(validationSchema),
    };
    const { register, handleSubmit, formState, watch, setValue, clearErrors } =
        useForm<Inputs>(formOptions);
    const { errors } = formState;

    const getListByField = (field: 'positionName' | 'status') => {
        switch (field) {
            case 'positionName':
                return ['ПФМ', 'ОСКО'];
            case 'status':
                return ['Активен', 'Уволен'];
            default:
                return [];
        }
    };

    const setDocName = (docType: UserDocTypes, value: string) => {
        const newNames = { ...fileName };
        newNames[docType] = value;
        setFileName(newNames);
        clearErrors(docType);
    };

    const deleteDoc = (docType: UserDocTypes) => {
        const newNames = { ...fileName };
        newNames[docType] = '';
        setFileName(newNames);
        setValue(docType, '');
    };

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const {
            fullName,
            shortName,
            positionName,
            phone,
            additNumber,
            email,
            birthDate,
            status,
            bot,
            isAdmin,
            isCallRecordEnabled,
            isFullBankInfoEnabled,
            login,
            password,
            passport,
            snils,
            inn,
        } = data;
        const normalizedPhone = phone?.match(phoneRegExp) ? getDigitsFromString(phone) : undefined;
        const normalizedStatus = normalizeStatusAndPosition(status);
        const normalizedPositionName = normalizeStatusAndPosition(positionName);

        if (user) {
            return dispatch(
                updateUser({
                    fullName: user.fullName !== fullName ? fullName : undefined,
                    shortName: user.shortName !== shortName ? shortName : undefined,
                    positionName:
                        normalizedPositionName !== user.positionName
                            ? normalizedPositionName
                            : undefined,
                    additNumber: additNumber !== user.additNumber ? additNumber : undefined,
                    birthDate: birthDate !== user.birthDate ? birthDate : undefined,
                    status: normalizedStatus !== user.status ? normalizedStatus : undefined,
                    phone: user.phone !== normalizedPhone ? normalizedPhone : undefined,
                    bot: bot !== user.bot ? bot : undefined,
                    isAdmin: isAdmin !== user.isAdmin ? isAdmin : undefined,
                    isCallRecordEnabled:
                        isCallRecordEnabled !== user.isCallRecordEnabled
                            ? isCallRecordEnabled
                            : undefined,
                    isFullBankInfoEnabled:
                        isFullBankInfoEnabled !== user.isFullBankInfoEnabled
                            ? isFullBankInfoEnabled
                            : undefined,
                    login: login !== user.login ? login : undefined,
                    password: password || undefined,
                    email: email !== user.email ? email : undefined,
                    passport:
                        getDocByType('passport')?.filePath !== passport
                            ? {
                                  file: data.passport,
                                  name: fileName.passport,
                              }
                            : undefined,
                    snils:
                        getDocByType('snils')?.filePath !== snils
                            ? {
                                  file: data.snils,
                                  name: fileName.snils,
                              }
                            : undefined,
                    inn:
                        getDocByType('inn')?.filePath !== inn
                            ? {
                                  file: data.inn,
                                  name: fileName.inn,
                              }
                            : undefined,
                    userId: user.userId,
                }),
            );
        }

        if (
            !(
                normalizedPositionName &&
                normalizedPhone &&
                additNumber &&
                birthDate &&
                normalizedStatus &&
                email &&
                snils &&
                inn &&
                passport &&
                password &&
                login &&
                fullName &&
                shortName
            )
        ) {
            return;
        }

        dispatch(
            createUser({
                fullName,
                shortName,
                positionName: normalizedPositionName,
                additNumber,
                birthDate,
                status: normalizedStatus,
                bot: Boolean(bot),
                isAdmin: Boolean(isAdmin),
                isCallRecordEnabled: Boolean(isCallRecordEnabled),
                isFullBankInfoEnabled: Boolean(isFullBankInfoEnabled),
                email,
                login,
                password,
                passport: {
                    file: data.passport,
                    name: fileName.passport,
                },
                snils: {
                    file: data.snils,
                    name: fileName.snils,
                },
                inn: {
                    file: data.inn,
                    name: fileName.inn,
                },
                phone: normalizedPhone,
            }),
        );
    };

    const contactPhone = watch('phone');
    const email = watch('email');

    // add default disabled: false for all fields to remove error in ToggleSwitch props, better for code readability then adding type
    const switcher = {
        bot: {
            value: watch('bot'),
            disabled: false,
        },
        isCallRecordEnabled: {
            value: watch('isCallRecordEnabled'),
            disabled: false,
        },
        isFullBankInfoEnabled: {
            value: watch('isFullBankInfoEnabled'),
            disabled: watch('isAdmin'),
        },
        isAdmin: {
            value: watch('isAdmin'),
            disabled: false,
        },
    };

    const fileSelectionHandler = (name: string, file: string) => {
        if (!addingDocument) return;

        setValue(addingDocument, file);
        setDocName(addingDocument, name);
    };

    const getInputField = (field: FieldsInAddingUserFormType) => {
        switch (field.type) {
            case 'input':
                return field.fieldName === 'phone' ? (
                    <StyledPhoneInput
                        country={'ru'}
                        placeholder="+7(000)000-00-00"
                        value={contactPhone}
                        onBlur={(e) => setValue('phone', e.target.value)}
                    />
                ) : (
                    <input
                        className={clsx({
                            'add-user__error-input': errors[field.fieldName]?.message,
                        })}
                        id={field.fieldName}
                        required={false}
                        {...register(field.fieldName)}
                    />
                );
            case 'date':
                return (
                    <input
                        className={clsx({
                            'add-user__error-input': errors[field.fieldName]?.message,
                        })}
                        id={field.fieldName}
                        type="date"
                        required={false}
                        {...register(field.fieldName)}
                    />
                );
            case 'switcher':
                return (
                    <ToggleSwitch
                        width={27}
                        height={16}
                        checked={switcher[field.fieldName as 'bot' | 'isAdmin'].value}
                        toggle={() =>
                            setValue(
                                field.fieldName as 'bot' | 'isAdmin',
                                !switcher[field.fieldName as 'bot' | 'isAdmin'].value,
                            )
                        }
                        disabled={switcher[field.fieldName as 'bot' | 'isAdmin'].disabled}
                    />
                );
            case 'dataset':
                return (
                    <CustomSelect width={428} height={24} backgroundImage={BulletListIcon}>
                        <select
                            className={clsx({
                                'add-user__error-input': errors[field.fieldName]?.message,
                            })}
                            id={field.fieldName}
                            required={false}
                            {...register(field.fieldName)}
                        >
                            {getListByField(field.fieldName as 'positionName' | 'status').map(
                                (item, index) => (
                                    <option key={item + index} value={item}>
                                        {item}
                                    </option>
                                ),
                            )}
                        </select>
                    </CustomSelect>
                );
            default:
                break;
        }
    };

    const fields = !user
        ? fieldsInAddingUserForm
        : fieldsInAddingUserForm.filter((field) => field.isEditable);

    const generateLoginAndPassword = () => {
        setValue('password', genPassword(10));
        const newLogin = email.split('@')[0] || '';
        setValue('login', newLogin);
    };

    const focusPasswordHandler = () => {
        setPasswordActive(true);
    };

    const blurPasswordHandler = () => {
        setPasswordActive(false);
    };

    const successHandler = () => {
        setUnloadedFile(null);
    };

    const errorHandler = (doc: UserDocInterface) => {
        if (!user || unloadedFile) return;
        setUnloadedFile(doc);
        dispatch(getUserDocuments({ userId: user.userId }));
    };

    const downloadDocument = (docType: UserDocTypes) => {
        const doc = selectedUserDocs.find((doc) => doc.type === docType);
        if (!doc || !user) return;
        downloadAsFile(doc.filePath, docType, () => errorHandler(doc), successHandler);
    };

    useEffect(() => {
        let hasEmptyFieldError = false;
        Object.keys(validationSchema.fields).forEach((key) => {
            const errorMessage = errors[key as FieldNameTypeInAddingUserForm]?.message;

            if (errorMessage && !(hasEmptyFieldError && errorMessage.startsWith('Заполните'))) {
                if (errorMessage.startsWith('Заполните') || errorMessage.startsWith('Выберите')) {
                    hasEmptyFieldError = true;
                    toast.error('Заполните необходимые поля');
                } else {
                    toast.error(errorMessage);
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            dispatch(setSelectedUserDocs([]));
            return;
        }

        if (isRequestFulfilled.createUser || isRequestFulfilled.updateUser) {
            close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRequestFulfilled]);

    useEffect(() => {
        if (!user) return;

        dispatch(getUserDocuments({ userId: user.userId }));
        setValue('phone', normalizePhone(user.phone || ''));
        dispatch(getOneUser({ userId: user.userId }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        const newFileName = { ...fileName };
        selectedUserDocs.forEach((doc) => {
            setValue(doc.type, doc.filePath);
            newFileName[doc.type] = doc.name;
        });
        setFileName(newFileName);

        if (unloadedFile) {
            downloadDocument(unloadedFile.type);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUserDocs]);
    useEffect(() => {
        if (switcher.isAdmin.value) {
            setValue('isFullBankInfoEnabled', true);
        }
    }, [setValue, switcher.isAdmin]);
    return (
        <CommonModal hasCloseBtn={true} close={close}>
            <StyledFormWrapper width={width}>
                <StyledContainer>
                    <h3>
                        {!user ? 'Добавить нового сотрудника' : 'Обновить информацию о сотруднике'}
                    </h3>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <StyledScrollbars
                            style={{ height: 'calc(100% - 30px)' }}
                            renderTrackHorizontal={(props) => (
                                <div
                                    {...props}
                                    style={{ display: 'none' }}
                                    className="track-horizontal"
                                />
                            )}
                            thumbSize={170}
                            thumbMinSize={30}
                        >
                            {fields.map((field) => (
                                <div
                                    key={field.fieldName}
                                    className={clsx('add-user__label', {
                                        'add-user__switcher': field.type === 'switcher',
                                    })}
                                >
                                    <div>
                                        {field.label}
                                        {field.isRequired && field.type !== 'switcher' && (
                                            <span className="add-user__sign-of-obligatory-field">
                                                *
                                            </span>
                                        )}
                                        :
                                    </div>
                                    {getInputField(field)}
                                </div>
                            ))}
                            <div>
                                <div className="add-user__generator">
                                    <div>
                                        {user
                                            ? 'логин/пароль'
                                            : 'Сгенерировать логин/пароль для входа в СРМ'}
                                        <span className="add-user__sign-of-obligatory-field">
                                            *
                                        </span>
                                        :
                                    </div>
                                    <button
                                        onClick={generateLoginAndPassword}
                                        type="button"
                                        disabled={!email?.match(emailRegExp)}
                                    >
                                        Сгенерировать {user ? 'новые' : ''}
                                    </button>
                                </div>
                                <div className="add-user__login-password">
                                    <input
                                        className={clsx({
                                            'add-user__error-input': errors.login?.message,
                                        })}
                                        id="login"
                                        disabled={true}
                                        {...register('login')}
                                    />
                                    <input
                                        className={clsx({
                                            'add-user__error-input': errors.password?.message,
                                        })}
                                        id="password"
                                        onFocus={focusPasswordHandler}
                                        {...register('password')}
                                        onBlur={blurPasswordHandler}
                                    />
                                </div>
                                <p
                                    className={clsx('add-user__password-rules', {
                                        'add-user__password-disabled': !isPasswordActive,
                                    })}
                                >
                                    Пароль должен содержать минимум 10 символов и включать буквы
                                    нижнего и верхнего регистров, цифры и специальные символы
                                </p>
                            </div>
                            {documentsInAddUserForm.map((document) => (
                                <div key={document.fieldName}>
                                    <div className="add-user__document">
                                        <p>
                                            {document.label}
                                            <span className="add-user__sign-of-obligatory-field">
                                                *
                                            </span>
                                            :
                                        </p>
                                        <button
                                            type="button"
                                            onClick={() => setAddingDocument(document.fieldName)}
                                        >
                                            Добавить
                                        </button>
                                    </div>
                                    <div className="add-user__doc-name">
                                        <p
                                            className={clsx({
                                                'add-user__doc-link': fileName[document.fieldName],
                                                'add-user__error-document':
                                                    errors[document.fieldName],
                                            })}
                                            onClick={() => downloadDocument(document.fieldName)}
                                        >
                                            {fileName[document.fieldName] || 'Загрузите документ'}
                                        </p>
                                        {fileName[document.fieldName] ? (
                                            <img
                                                src={DeleteDocIcon}
                                                onClick={() => deleteDoc(document.fieldName)}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            ))}
                        </StyledScrollbars>
                        <BtnLoader
                            isLoading={isBtnDisabled}
                            btnTitle="Сохранить"
                            btnClass={clsx('add-user__btn', {
                                'add-user__disabled-btn': isBtnDisabled,
                            })}
                        />
                    </form>
                </StyledContainer>
                {addingDocument && (
                    <AddUserDoc
                        close={() => setAddingDocument(null)}
                        width={400}
                        setFile={(name: string, file: string) => fileSelectionHandler(name, file)}
                        title="Добавить документ"
                    />
                )}
            </StyledFormWrapper>
        </CommonModal>
    );
};

export default AddUser;
