import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getError } from '../../../utils/functions';
import { RootState } from '../..';
import { locationApi } from '../../../api/locationApi/locationApi';
import { InitialStateType } from './types';

export const getCities = createAsyncThunk<
    any,
    {
        region: string;
        input?: string;
    },
    {
        rejectValue: string;
    }
>('location/getCities', async ({ ...props }, { rejectWithValue }) => {
    try {
        const res = await locationApi.getCities({ ...props });
        if (res?.message === 'Success') {
            return res.data;
        } else {
            throw res?.data;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const getRegions = createAsyncThunk<
    any,
    {
        input?: string;
    },
    {
        rejectValue: string;
    }
>('location/getRegions', async ({ ...props }, { rejectWithValue }) => {
    try {
        const res = await locationApi.getRegions({ ...props });
        if (res?.message === 'Success') {
            return res.data;
        } else {
            throw res?.data;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

const initialState: InitialStateType = {
    regions: [],
    cities: [],
    loading: {
        regions: false,
        cities: false,
    },
    error: '',
};

export const locationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {
        clearLocationState: (state) => {
            state = initialState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCities.pending, (state) => {
            state.loading.cities = true;
        });
        builder.addCase(getCities.fulfilled, (state, { payload }) => {
            state.loading.cities = false;
            state.cities = payload;
        });
        builder.addCase(getCities.rejected, (state, { payload }) => {
            state.loading.cities = false;
            state.error = payload as string;
        });
        builder.addCase(getRegions.pending, (state) => {
            state.loading.regions = true;
        });
        builder.addCase(getRegions.fulfilled, (state, { payload }) => {
            state.loading.regions = false;
            state.regions = payload;
        });
        builder.addCase(getRegions.rejected, (state, { payload }) => {
            state.loading.regions = false;
            state.error = payload as string;
        });
    },
});

export const { clearLocationState } = locationSlice.actions;

export const selectLocation = (state: RootState) => state.location;
