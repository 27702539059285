import styled from 'styled-components/macro';
import PhoneInput from 'react-phone-input-2';

const StyledFormWrapper = styled.div`
    .add-phone-and-email__phone {
        display: grid;
        grid-template-columns: 6fr 1fr 2fr;
        grid-column-gap: 15px;
    }

    .add-phone-and-email__email {
        display: grid;
        grid-template-columns: 3fr 1fr;
        grid-column-gap: 15px;
    }

    .add-phone-and-email__row-with-plus-icon {
        display: grid;
        grid-template-columns: 1fr 11px;
        grid-column-gap: 8px;
        align-items: center;

        span {
            font-weight: 700;
            font-size: 18px;
            color: #0038ff;
            padding-top: 8px;
            cursor: pointer;
        }
    }

    .add-phone-and-email__error-field input {
        border-color: #ff0000;
    }
`;

const StyledPhoneInput = styled(PhoneInput)`
    margin: 5px 0;

    .form-control {
        width: 100%;
        height: 24px;
        border: 1px solid #000;
    }

    .flag-dropdown {
        border: 1px solid #000;
    }
`;

export { StyledFormWrapper, StyledPhoneInput };
