import React from 'react';

import StyledFilterWrapper, { StyledPhoneInput } from './PhoneFilter.style';

const filter = {
    label: 'Поиск по номеру',
    parameter: 'phone',
};

type FilterProps = {
    // eslint-disable-next-line no-unused-vars
    filterChangeHandler: (value: string, field: string) => void;
    phone: string;
    disabledFilter?: boolean;
};

const PhoneFilter = ({ filterChangeHandler, phone, disabledFilter }: FilterProps) => {
    return (
        <StyledFilterWrapper>
            <div className="filter__container">
                <p>{filter.label}</p>
                <StyledPhoneInput
                    disabled={disabledFilter}
                    // country={'ru'}
                    placeholder=""
                    value={phone}
                    onBlur={(e) => filterChangeHandler(e.target.value, filter.parameter)}
                />
            </div>
        </StyledFilterWrapper>
    );
};

export default PhoneFilter;
