import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectStaff } from '../../../store/slices/staffSlice';
import { getManagersList } from '../../../utils/functions';
import CustomSelect from '../CustomSelect/CustomSelect';
import BulletListIcon from '../../../assets/images/bullet-list-icon.png';
import StyledFilterWrapper from './ManagerFilter.style';

const filter = {
    label: 'Менеджер',
    parameter: 'manager',
};

type FilterProps = {
    // eslint-disable-next-line no-unused-vars
    filterChangeHandler: (value: string, field: string) => void;
    filterParam: string;
    disabledFilter?: boolean;
};

const Filter = ({ filterChangeHandler, filterParam, disabledFilter }: FilterProps) => {
    const { allUsers } = useSelector(selectStaff);
    const managers = useMemo(() => {
        const managersWithRecordsEnabled = allUsers.filter((user) => user.isCallRecordEnabled);
        return [{ fullName: 'Все', userId: 0 }, ...getManagersList(managersWithRecordsEnabled)];
    }, [allUsers]);

    return (
        <StyledFilterWrapper>
            <div className="filter__manager-container">
                <p>{filter.label}</p>{' '}
                <CustomSelect
                    width={262}
                    height={20}
                    backgroundImage={BulletListIcon}
                    backgroundColor="#f9f9f9"
                >
                    <select
                        disabled={disabledFilter}
                        value={filterParam}
                        onChange={(e) => filterChangeHandler(e.target.value, filter.parameter)}
                    >
                        {managers.map((item) => (
                            <option key={item.userId + filter.parameter} value={item.userId}>
                                {item.fullName}
                            </option>
                        ))}
                    </select>
                </CustomSelect>
            </div>
        </StyledFilterWrapper>
    );
};

export default Filter;
