import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import 'react-phone-input-2/lib/style.css';

import { useAppDispatch } from '../../../store';
import {
    CreateCompanyDocumentCategory,
    selectClientDocument,
} from '../../../store/slices/clientDocumentSlice';
import { SectionNameTypes } from '../../../utils/data';
import DefaultLoader from '../../Common/BtnLoader/DefaultLoader';
import StyledContainer from './AddDocumentCategory.style';

type AddDocumentCategoryProps = {
    // eslint-disable-next-line no-unused-vars
    previousStep: () => void;
    section: SectionNameTypes;
    companyId: number;
};

const AddDocumentCategory = ({ previousStep, section, companyId }: AddDocumentCategoryProps) => {
    const dispatch = useAppDispatch();
    const { loading } = useSelector(selectClientDocument);
    const [categoryName, setCategoryName] = useState('');
    const [basicDocumentName, setBasicDocumentName] = useState('');

    const addCategoryHandler = () => {
        if (!(categoryName && basicDocumentName)) return toast.error('Не заполнено имя раздела');
        dispatch(
            CreateCompanyDocumentCategory({
                section,
                companyId,
                name: categoryName,
                basicDocumentName,
            }),
        );
    };

    return (
        <StyledContainer>
            <h3>Добавить раздел</h3>
            <label>
                Название раздела::
                <textarea
                    className="add-document-category__category-name"
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                />
            </label>
            <label>
                Форматирование:
                <input
                    value={basicDocumentName}
                    onChange={(e) => setBasicDocumentName(e.target.value)}
                />
            </label>
            <div className="add-document-category__actions">
                <button type="button" onClick={previousStep}>
                    Назад
                </button>
                <button
                    onClick={addCategoryHandler}
                    type="button"
                    disabled={loading.createDocumentCategory}
                >
                    {loading.createDocumentCategory ? <DefaultLoader /> : 'Сохранить'}
                </button>
            </div>
        </StyledContainer>
    );
};

export default AddDocumentCategory;
