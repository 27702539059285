import instance from '../axiosInstance';
import {
    UserApiPath,
    CreateUserType,
    UpdateUserType,
    GetOneUserType,
    CreateUpdateUserResponse,
    GetUserDocResponse,
    GetAllUserResponse,
    GetAllUsersType,
} from './userApi.types';

export const userAPI = {
    createUser: async ({ ...props }: CreateUserType): Promise<CreateUpdateUserResponse> => {
        try {
            const res = await instance.post(UserApiPath.USER_CRUD, {
                ...props,
            });
            return res.data;
        } catch (error) {
            console.log(error);
        }
    },
    updateUser: async ({ userId, ...props }: UpdateUserType): Promise<CreateUpdateUserResponse> => {
        try {
            const res = await instance.patch(`${UserApiPath.USER_CRUD}/${userId}`, {
                ...props,
            });
            return res.data;
        } catch (error) {
            console.log(error);
        }
    },
    getOneUser: async ({ userId }: GetOneUserType): Promise<CreateUpdateUserResponse> => {
        try {
            const res = await instance.get(`${UserApiPath.USER_CRUD}/${userId}`);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    },
    getAllUsers: async (params: GetAllUsersType): Promise<GetAllUserResponse> => {
        try {
            const res = await instance.get(UserApiPath.USER_CRUD, {
                params,
            });
            return res.data;
        } catch (e) {
            console.log(e);
        }
    },
    getUserDocuments: async ({ userId }: GetOneUserType): Promise<GetUserDocResponse> => {
        try {
            const res = await instance.get(
                `${UserApiPath.USER_CRUD}/${userId}${UserApiPath.USER_DOCUMENTS}`,
            );
            return res.data;
        } catch (e) {
            console.log(e);
        }
    },
};
