import styled from 'styled-components/macro';

const StyledClientsTable = styled.div`
    font-weight: 300;
    font-size: 12px;

    p {
        width: 100%;
    }

    .partners-table__header,
    .partners-table__partner {
        display: grid;
        grid-template-columns:
            27px 75px minmax(200px, 1fr) 85px repeat(4, 60px) 89px 59px repeat(4, 42px)
            110px;
        grid-column-gap: 10px;
    }

    .partners-table__header {
        padding: 5px 7px;
        background: #e7e7e7;
        height: 30px;

        img {
            width: 10px;
            height: 10px;
            margin-left: 5px;
        }

        p {
            font-weight: 500;
        }
    }

    .partners-table__column-name {
        font-weight: 500;
        cursor: pointer;
        color: #000;
        display: flex;
        align-items: center;

        p {
            width: fit-content;
        }
    }

    .partners-table__partner {
        padding: 13px 7px;

        img {
            width: 20px;
            height: 20px;
            margin-right: 15px;
        }
    }

    .partners-table__table-grid-open-filter {
        grid-template-columns: 30px 75px 3fr 90px 3fr 2fr repeat(2, 80px) 40px 180px;
    }

    .partners-table__even-field {
        background: #f8f8f8;
    }

    .partners-table__bank-product {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 1px;
        font-weight: 400;
        font-size: 10px;
        height: 20px;

        p {
            display: flex;
            height: 20px;
            align-items: center;
            justify-content: center;
            color: #c4c4c4;
        }
    }

    .partners-table__logo {
        img {
            object-fit: contain;
            width: 75px;
            height: 30px;
            margin: 0 !important;
        }
    }

    .partners-table__active-law {
        background: #fdd55b !important;
        color: #000 !important;
    }

    .partners-table__commercial-product {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 1px;
        font-weight: 400;
        font-size: 10px;
        height: 20px;
        margin-top: -5px;

        p {
            display: flex;
            height: 29px;
            align-items: center;
            justify-content: center;
            color: #c4c4c4;
            background: #f2f2f2;
        }
    }

    .partners-table__loan-product {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 1px;
        font-weight: 400;
        font-size: 10px;
        height: 20px;
        margin-top: -5px;

        p {
            display: flex;
            height: 29px;
            align-items: center;
            justify-content: center;
            color: #c4c4c4;
            background: #f2f2f2;
        }
    }

    .partners-table__partner-card-btn {
        border: none;
        background: transparent;
        color: #0038ff;
        text-decoration: underline;
        padding: 0;
        margin-top: -6px;
    }

    .partners-table__table-placeholder {
        text-align: center;
        font-size: 18px;
        color: #a12;
    }

    .partners-table__field-with-margin {
        margin-top: 4px;
    }

    .partners-table__actions-with-product {
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .partners-table__add-info-icon {
        margin-top: 3px;
    }

    .partners-table__instruction-icon {
        cursor: pointer;
    }

    .partners-table__loader {
        text-align: center;
        margin: 50px;
    }

    .partners-table__right-position {
        display: flex;
        justify-content: end;
        padding-right: 4px;

        p {
            width: fit-content;
        }
    }
`;

export default StyledClientsTable;
