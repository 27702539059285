import styled from 'styled-components/macro';

const StyledTaskComments = styled.div`
    font-weight: 500;
    font-size: 12px;
    background: #e5e5e5;

    button {
        text-decoration: underline;
        color: #0038ff;
        border: none;
        background: transparent;
    }

    .task-comments__general-information-header {
        height: 16px;
    }

    .task-comments__general-information {
        padding: 9px 7px;
        max-height: inherit;
    }

    .task-comments__add-comment-btn {
        display: flex;
        justify-content: end;
    }

    .task-comments__comments-area {
        background: #fff;
        height: 340px;
        border-radius: 5px;
        margin: 7px 0;
        overflow: auto;
        padding: 5px;
    }
`;

export default StyledTaskComments;
