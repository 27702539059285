import React, { useState } from 'react';
import { clsx } from 'clsx';

import { useAppDispatch } from '../../../../../store';
import FontEditIcon from '../../../../../assets/images/clients/font-edit-icon.png';
import FontTrashIcon from '../../../../../assets/images/clients/font-trash-icon.png';
import { CompanyContactInterface } from '../../../../../utils/types';
import AddContact from '../../../../../components/AddContact/AddContact';
import { deleteCompanyContact } from '../../../../../store/slices/clientCardSlice';
import ConfirmModal from '../../../../../components/Common/ConfirmModal/ConfirmModal';
import { normalizePhone } from '../../../../../utils/functions';
import StyledContactItem from './ContactItem.style';
import WhatsUpIcon from '../../../../../assets/images/clients/whatsapp-logo-icon.svg';
// import TelegramIcon from '../../../../../assets/images/clients/telegram-logo-icon.svg';

type ContactItemProps = {
    contact: CompanyContactInterface;
    companyId: number;
};

const MessangerType = {
    whatsup: 'whatsup',
    telegram: 'telegram',
};

const ContactItem = ({ contact, companyId }: ContactItemProps) => {
    const dispatch = useAppDispatch();
    const [isEditContactModalOpen, setEditContactModalOpen] = useState(false);
    const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);

    const confirmAction = () => {
        if (!(contact.companyContactId && companyId)) return;
        dispatch(
            deleteCompanyContact({
                companyId,
                contactId: contact.companyContactId,
                hardDelete: true,
            }),
        );
        setConfirmModalOpen(false);
    };

    const redirectToMessanger = (phone: string, type: string) => {
        const formattedPhoneNumber = phone.replace(/\D/g, '');
        if (type === MessangerType.whatsup) {
            const whatsappLink = `https://wa.me/${formattedPhoneNumber}`;
            window.open(whatsappLink, '_blank');
        }
        if (type === MessangerType.telegram) {
            const telegramLink = `tg://resolve?phone=${formattedPhoneNumber}`;
            window.open(telegramLink, '_blank');
        }
    };

    return (
        <StyledContactItem>
            <div
                className={clsx('contact-item__container', {
                    'contact-item__red-highlight': contact.color === 'red',
                    'contact-item__green-highlight': contact.color === 'green',
                })}
            >
                <div className="contact-item__info">
                    <h4>{contact.name || ''}</h4>
                    <div>
                        {contact.phoneNumbers.map((phone, index) => (
                            <div key={index}>
                                <div
                                    className={clsx('contact-item__phnone', {
                                        'contact-item__disabled-phnone': phone.isDisabled,
                                    })}
                                >
                                    <div className="contact-item__phnone-logo">
                                        <img
                                            src={WhatsUpIcon}
                                            onClick={() =>
                                                redirectToMessanger(
                                                    phone.phoneNumber,
                                                    MessangerType.whatsup,
                                                )
                                            }
                                        ></img>
                                    </div>
                                    {/* <div className="contact-item__phnone-logo">
                                        <img
                                            src={TelegramIcon}
                                            onClick={() =>
                                                redirectToMessanger(
                                                    phone.phoneNumber,
                                                    MessangerType.telegram,
                                                )
                                            }
                                        ></img>
                                    </div> */}
                                    <p
                                        key={phone.phoneNumber}
                                        className="contact-item__phnone-number"
                                    >
                                        {`${normalizePhone(phone.phoneNumber)} ${
                                            phone.additInfo ? `доб. ${phone.additInfo}` : ''
                                        }`}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>

                    {contact.emails.map((item) => (
                        <p key={item.email}>{`${item.email} ${
                            item.additInfo ? `доб. ${item.additInfo}` : ''
                        }`}</p>
                    ))}
                </div>
                <div className="contact-item__actions">
                    <img src={FontEditIcon} onClick={() => setEditContactModalOpen(true)} />
                    <img src={FontTrashIcon} onClick={() => setConfirmModalOpen(true)} />
                </div>
            </div>
            {isEditContactModalOpen && contact ? (
                <AddContact
                    width={428}
                    companyId={companyId}
                    close={() => setEditContactModalOpen(false)}
                    contact={contact}
                />
            ) : null}
            {isConfirmModalOpen ? (
                <ConfirmModal
                    close={() => setConfirmModalOpen(false)}
                    confirmAction={confirmAction}
                    width={280}
                    height={85}
                    headText="Удалить контакт?"
                />
            ) : null}
        </StyledContactItem>
    );
};

export default ContactItem;
