import styled from 'styled-components/macro';

const StyledClientsTable = styled.div`
    font-weight: 400;
    font-size: 12px;

    .users-table__header {
        padding: 5px 15px 5px 7px;
        display: grid;
        grid-template-columns: 2fr 4fr 2fr 4fr 2fr 5fr 2fr 1fr 1fr 2fr 20px;
        grid-column-gap: 10px;
        background: #e7e7e7;
        min-height: 30px;

        img {
            width: 10px;
            height: 10px;
            margin-left: 5px;
        }
    }

    .users-table__column-name {
        font-weight: 500;
        color: #000;
        display: flex;
        align-items: center;

        p {
            width: fit-content;
        }
    }

    .users-table__user {
        padding: 13px 15px 13px 7px;
        display: grid;
        grid-template-columns: 2fr 4fr 2fr 4fr 2fr 5fr 2fr 1fr 1fr 2fr 20px;
        grid-column-gap: 10px;

        p {
            overflow-wrap: break-word;
            word-break: break-word;
            text-overflow: ellipsis;
            overflow-x: auto;
        }

        img {
            width: 20px;
            height: 20px;
            margin-top: -6px;
        }
    }

    .users-table__bold-text {
        font-weight: 700;
    }

    .users-table__suspended-user {
        opacity: 0.5;
    }

    .users-table__even-field {
        background: #f8f8f8;
    }

    .users-table__company-name {
        color: #0038ff;
        text-decoration: underline;
        cursor: pointer;
    }

    .users-table__next-contact {
        cursor: pointer;
    }

    .users-table__importance {
        padding: 2px 5px;
        height: 18px;
        margin-top: -2px;
    }

    .users-table__low-importance {
        background: rgba(209, 254, 174, 0.5);
    }

    .users-table__medium-importance {
        background: rgba(253, 213, 91, 0.2);
    }

    .users-table__high-importance {
        background: rgba(255, 0, 0, 0.2);
    }

    .users-table__bank-guarantee {
        display: flex;
        align-items: start;

        p {
            width: fit-content;
        }

        img {
            margin: -3px 0 0 5px;
        }
    }

    .users-table__highlighted-field {
        background: rgba(145, 66, 245, 0.12);
    }

    .users-table__marks {
        display: flex;
    }
`;

export default StyledClientsTable;
