import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectClientCard } from '../../../store/slices/clientCardSlice';
import { selectClientTask } from '../../../store/slices/clientTaskSlice';
import CommentItem, {
    CommentType,
} from '../../ClientInteraction/ClientCard/WorkArea/CommentItem/CommentItem';
import AddComment from '../../../components/AddComment/AddComment';
import StyledTaskComments from './TaskComments.style';

const TaskComments = () => {
    const { company } = useSelector(selectClientCard);
    const { currentTask, isRequestFulfilled } = useSelector(selectClientTask);
    const firstUpdate = useRef(true);
    const [isAddCommentModalOpen, setAddCommentModalOpen] = useState(false);
    const [editingComment, setEditingComment] = useState<null | CommentType>(null);

    const selectCommentForEditing = (comment: CommentType) => {
        setEditingComment(comment);
        setAddCommentModalOpen(true);
    };

    const closeAddCommentModal = () => {
        setAddCommentModalOpen(false);
        setEditingComment(null);
    };

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if (isRequestFulfilled.comment) {
            closeAddCommentModal();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRequestFulfilled.comment]);

    return (
        <StyledTaskComments>
            <div className="task-comments__general-information">
                <h4 className="task-comments__general-information-header">Комментарии к заявке:</h4>
                <div className="task-comments__comments-area">
                    {company
                        ? currentTask?.comments?.map((comment) => (
                              <CommentItem
                                  key={comment.taskCommentId}
                                  companyId={Number(company.companyId)}
                                  taskId={currentTask.taskId}
                                  comment={comment}
                                  selectCommentForEditing={selectCommentForEditing}
                              />
                          ))
                        : null}
                </div>
                <div className="task-comments__add-comment-btn">
                    <button onClick={() => setAddCommentModalOpen(true)}>
                        Добавить комментарий
                    </button>
                </div>
            </div>
            {isAddCommentModalOpen && company?.companyId ? (
                <AddComment
                    close={closeAddCommentModal}
                    width={900}
                    companyId={company.companyId}
                    editingComment={editingComment}
                    taskId={currentTask?.taskId}
                />
            ) : null}
        </StyledTaskComments>
    );
};

export default TaskComments;
