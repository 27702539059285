import React, { useEffect, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import toast from 'react-hot-toast';

import { useAppDispatch } from '../../store';
import { addActValidationSchema } from '../../validation/clientActValidation';
import { dateFieldsInAddingActForm, FieldNameTypeInAddingActForm } from '../../utils/data';
import {
    formatNum,
    getDate,
    getNormalizedSum,
    getProductTitle,
    strWithDateToIsoStr,
} from '../../utils/functions';
import { StyledScrollbars } from '../AddClient/AddClientsInfo/AddClientsInfo.style';
import { createAct, getCompanyReceivers, selectClientAct } from '../../store/slices/actSlice';
import { selectClientCard } from '../../store/slices/clientCardSlice';
import {
    getAllCompanyCashback,
    selectCompanyCashback,
} from '../../store/slices/companyCashbackSlice';
import { ReceiverInterface } from '../../utils/types';
import { StyledFormWrapper } from '../AddBankTask/AddBankTask.style';
import CommonModal from '../Common/CommonModal/CommonModal';
import EditIcon from '../../assets/images/tasks/edit-offer-icon.png';
import BtnLoader from '../Common/BtnLoader/BtnLoader';
import AddActReceiver from '../AddActReceiver/AddActReceiver';
import StyledContainer from './AddAct.style';

type AddActProps = {
    close: () => void;
    width: number;
    companyId: number;
};

type Inputs = {
    dateFrom: string;
    dateTo: string;
    receiverId: string;
};

const AddAct = ({ close, width, companyId }: AddActProps) => {
    const dispatch = useAppDispatch();
    const firstUpdate = useRef(true);
    const { loading, isRequestFulfilled, companyReceivers } = useSelector(selectClientAct);
    const { companyCashbackList } = useSelector(selectCompanyCashback);
    const [includingTasks, setIncludingTasks] = useState<number[]>([]);
    const [isAddReceiverOpen, setAddReceiverOpen] = useState(false);
    const [selectedReceiver, setSelectedReceiver] = useState<null | ReceiverInterface>(null);
    const { company } = useSelector(selectClientCard);

    const header = 'Сформировать новый Акт';
    const btnTitle = 'Сформировать акт';
    const windowHeight = 238 + (companyCashbackList.length + companyReceivers.length) * 24;
    const validationSchema = addActValidationSchema;

    const receiverModalHandler = () => {
        setAddReceiverOpen((prev) => !prev);
    };

    const editReceiver = (receiver: ReceiverInterface) => {
        setSelectedReceiver(receiver);
        receiverModalHandler();
    };

    const closeEditModal = () => {
        setSelectedReceiver(null);
        receiverModalHandler();
    };

    const formOptions = {
        resolver: yupResolver(validationSchema),
    };
    const { register, handleSubmit, formState } = useForm<Inputs>(formOptions);
    const { errors } = formState;

    const includeTaskHandler = (value: string) => {
        const normalizedValue = Number(value);

        if (!normalizedValue) return;

        if (includingTasks.includes(normalizedValue)) {
            const index = includingTasks.indexOf(normalizedValue);
            includingTasks.splice(index, 1);
            setIncludingTasks([...includingTasks]);
            return;
        }

        setIncludingTasks([...includingTasks, normalizedValue]);
    };

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const { dateFrom, dateTo, receiverId } = data;

        if (!includingTasks.length) {
            toast.error('Как минимум одна сделка должна быть выбрана');
            return;
        }

        const normalizedReceiverId = Number(receiverId);
        const normalizedDateFrom = strWithDateToIsoStr(dateFrom);
        const normalizedDateTo = strWithDateToIsoStr(dateTo);

        if (
            !(
                normalizedDateFrom &&
                normalizedDateTo &&
                normalizedReceiverId &&
                includingTasks.length
            )
        )
            return;

        const payload = {
            receiverId: normalizedReceiverId,
            dateFrom: normalizedDateFrom,
            dateTo: normalizedDateTo,
            cashbackIds: includingTasks,
            companyId,
        };
        dispatch(createAct(payload));
    };

    useEffect(() => {
        if (!company) return;

        const { companyId } = company;

        dispatch(getCompanyReceivers({ companyId }));

        dispatch(
            getAllCompanyCashback({
                order: 'ASC',
                sortBy: 'transactionDate',
                hasAct: false,
                companyId,
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let hasEmptyFieldError = false;
        Object.keys(validationSchema.fields).forEach((key) => {
            const errorMessage = errors[key as FieldNameTypeInAddingActForm]?.message;
            if (errorMessage?.startsWith('Заполните')) {
                if (!hasEmptyFieldError) {
                    hasEmptyFieldError = true;
                    toast.error('Заполните необходимые поля');
                }
            } else if (errorMessage) {
                toast.error(errorMessage);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if (isRequestFulfilled.createAct) {
            close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRequestFulfilled.createAct]);

    return (
        <>
            <CommonModal hasCloseBtn={true} close={close}>
                <StyledFormWrapper width={width}>
                    <StyledContainer height={windowHeight}>
                        <h3 className="add-act__title">{header}</h3>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <StyledScrollbars
                                style={{ height: 'calc(100% - 30px)' }}
                                renderTrackHorizontal={(props) => (
                                    <div
                                        {...props}
                                        style={{ display: 'none' }}
                                        className="track-horizontal"
                                    />
                                )}
                                thumbSize={170}
                                thumbMinSize={30}
                            >
                                {dateFieldsInAddingActForm.map((field) => (
                                    <div key={field.fieldName} className="add-act__label">
                                        {field.label}:
                                        <input
                                            className={clsx({
                                                'add-act__error-input': errors[field.fieldName],
                                            })}
                                            type="date"
                                            id={field.fieldName}
                                            {...register(field.fieldName)}
                                        />
                                    </div>
                                ))}
                                <p className="add-act__tasks-label">Выбрать сделки по компании:</p>
                                {companyCashbackList.map((cashback) => (
                                    <div key={cashback.cashbackId} className="add-act__checkbox">
                                        <input
                                            type="checkbox"
                                            value={cashback.cashbackId}
                                            checked={includingTasks.includes(cashback.cashbackId)}
                                            onChange={(e) => includeTaskHandler(e.target.value)}
                                        />
                                        <label className="add-act__radio-btn-label">
                                            {getDate(cashback.offer?.task?.closeDate || '', 'date')}{' '}
                                            № {cashback.offer?.task?.taskId}{' '}
                                            {getProductTitle(
                                                cashback.offer.task?.bankGuaranteeType || '',
                                                false,
                                            )}
                                            {'  '}
                                            {formatNum(
                                                getNormalizedSum(cashback.cashbackSum || ''),
                                            )}
                                        </label>
                                    </div>
                                ))}
                                <p className="add-act__receivers-label">Получатель:</p>
                                {companyReceivers.map((receiver) => (
                                    <div
                                        key={receiver.companyReceiverId}
                                        className="add-act__radio-btn"
                                    >
                                        <input
                                            className={clsx({
                                                'add-act__error-input': errors.receiverId,
                                            })}
                                            type="radio"
                                            value={receiver.companyReceiverId}
                                            {...register('receiverId')}
                                        />
                                        <label className="add-act__radio-btn-label">
                                            {receiver.name} ИНН {receiver.inn}
                                        </label>
                                        {!receiver.default && (
                                            <img
                                                src={EditIcon}
                                                onClick={() => editReceiver(receiver)}
                                            />
                                        )}
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    className="add-act__add-receiver-btn"
                                    onClick={receiverModalHandler}
                                >
                                    {'Добавить нового >'}
                                </button>
                            </StyledScrollbars>
                            <BtnLoader
                                isLoading={loading.createAct}
                                btnTitle={btnTitle}
                                btnClass={clsx('add-act__btn', {
                                    'add-act__disabled-btn': loading.createAct,
                                })}
                            />
                        </form>
                    </StyledContainer>
                </StyledFormWrapper>
            </CommonModal>
            {isAddReceiverOpen && (
                <AddActReceiver
                    close={closeEditModal}
                    width={470}
                    companyId={companyId}
                    receiver={selectedReceiver}
                />
            )}
        </>
    );
};

export default AddAct;
