import styled from 'styled-components/macro';

const StyledTable = styled.div`
    font-weight: 300;
    font-size: 12px;
    margin-bottom: 10px;

    .close-requests-table__column {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-weight: 600;

        img {
            width: 12px;
            height: 12px;
            margin-left: 5px;
        }
    }

    .close-requests-table__headings,
    .close-requests-table__row {
        line-height: 20px;
        display: grid;
        grid-template-columns:
            minmax(73px, 180px) 40px minmax(70px, 90px) minmax(150px, 2fr) minmax(40px, 70px) 45px
            minmax(120px, 2fr) minmax(100px, 2fr) repeat(5, minmax(135px, 2fr)) 10px repeat(2, minmax(95px, 2fr));
        grid-column-gap: 10px;
        padding: 5px 7px;

        a {
            width: fit-content;
            min-width: 20px;
        }
    }

    .close-requests-table__headings {
        background: #e5e5e5;
    }

    .close-requests-table__manager-view {
        grid-template-columns:
            minmax(73px, 180px) 40px minmax(70px, 90px) minmax(150px, 2fr) minmax(40px, 70px) 45px
            minmax(120px, 2fr) minmax(100px, 2fr) repeat(5, minmax(135px, 2fr)) 10px minmax(180px, 2fr);
    }

    .close-requests-table__link {
        color: #0038ff;
        text-decoration: underline;
    }

    .close-requests-table__icons {
        display: flex;
        align-items: start;
        justify-content: end;

        img {
            margin-left: 20px;
        }
    }

    .close-requests-table__edit-icon {
        cursor: pointer;
    }

    .requests-table__commission span {
        font-weight: 600;
    }

    .close-requests-table__green-field {
        color: #00892f;
    }

    .requests-table__red-field,
    .close-requests-table__red-field {
        color: #ff0000;
    }

    .close-requests-table__disabled-link {
        cursor: auto;
    }

    .close-requests-table__even-row {
        background: #f5f5f5;
    }

    .close-requests-table__right-position,
    .requests-table__commission {
        display: flex;
        justify-content: flex-end;
    }
    .row-cell-prio {
        display: flex;
        justify-content: flex-end;
        font-weight: bold;
        &--0 {
            grid-column: 11;
        }
        &--1 {
            grid-column: 12;
            color: #ff0000;
        }
        &--2 {
            grid-column: 13;
            color: #00892f;
        }
    }
    .close-requests-table__loader {
        text-align: center;
        margin: 50px;
    }
    .close-requests-table__no-results {
        text-align: center;
        font-size: 18px;
        margin: 30px;
    }
    .close-requests-table__no-results > div {
        margin-top: 10px;
    }
`;

export default StyledTable;
