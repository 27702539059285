import { FieldNameTypeInAddingPartnerForm } from '../../utils/data';
import { BankDocumentSectionTypes, FileWithName, PartnerFileTypes } from '../../utils/types';

export const fieldNamesForRestoreFromCurrentPartner: FieldNameTypeInAddingPartnerForm[] = [
    'name',
    'logo',
    'isDisplayedInList',
    'isPriority',
    'additInfo',
];

export interface PartnerFileWithName extends FileWithName {
    bankDocumentId?: number;
}

export type AddPartnerProps = {
    close: () => void;
    bankId: number | null;
};

export type AddPartnerInputs = {
    name: string;
    instructions: string;
    logo: string;
    additInfo: string;
    isDisplayedInList: boolean;
    isPriority: boolean;
};

export type PartnerDocType = {
    agencyContract: PartnerFileWithName[];
    powersOfAttorney: PartnerFileWithName[];
    instructions: PartnerFileWithName[];
};

export type fileNameReducerStateType = {
    instructions: string;
    logo: string;
};

export type fileNameReducerAction = {
    type: PartnerFileTypes;
    file: string;
};

export type partnerDocTypeReducerAction = {
    type: BankDocumentSectionTypes;
    docs: PartnerFileWithName[];
};
