import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { useAppDispatch } from '../../../store';
import { getOneClientTask, selectClientTask } from '../../../store/slices/clientTaskSlice';
import {
    offersForBankGuaranteeTaskTableColumns,
    productsForLoanTaskTableColumns,
} from '../../../utils/data';
import {
    downloadAsFile,
    formatNum,
    getAmountInRub,
    getGivenOutOffer,
} from '../../../utils/functions';
import {
    OfferDocInterface,
    OfferInterface,
    PartnerDocument,
    TaskProductENUM,
} from '../../../utils/types';
import AddBankToTask from '../../../components/AddBankToTask/AddBankToTask';
import EditOffer from '../../../components/EditOffer/EditOffer';
import AddRequestToTaskOffer from '../../../components/AddRequestToTaskOffer/AddRequestToTaskOffer';
import StyledOffersForTask from './OffersForTaskTable.style';
import OffersForTaskTableRow from '../OffersForTaskTableRow/OffersForTaskTableRow';
import { TermENUM } from '../../../utils/consts';

type OffersForTaskTableProps = {
    companyId: number;
    taskId: number;
};

export type offerCommissionFieldsType = 'bankCommission' | 'baseCommission' | 'clientCommission';

export type OfferTableDocType = OfferDocInterface | PartnerDocument;

const OffersForTaskTable = ({ companyId, taskId }: OffersForTaskTableProps) => {
    const dispatch = useAppDispatch();
    const { currentTask } = useSelector(selectClientTask);
    const [isAddBankModalOpen, setAddBankModalOpen] = useState(false);
    const [editingOffer, setEditingOffer] = useState<null | OfferInterface>(null);
    const [editingOfferForRequest, setEditingOfferForReq] = useState<null | OfferInterface>(null);
    const [unloadedFile, setUnloadedFile] = useState<null | OfferTableDocType>(null);
    const isLoan = currentTask?.type === TaskProductENUM.LOAN;

    const sum = getAmountInRub(isLoan ? currentTask?.loanAmount : currentTask?.bankGuaranteeAmount);
    const taskTerm = isLoan ? currentTask?.loanTermMonths : currentTask?.bankGuaranteeTermDays;
    const term = taskTerm ? formatNum(String(taskTerm)) : '';
    const givenOffer = getGivenOutOffer(currentTask?.offers);
    const isTaskClosed = Boolean(givenOffer || currentTask?.closeReason || currentTask?.closeDate);

    const successHandler = () => {
        setUnloadedFile(null);
    };

    const errorHandler = (file: OfferTableDocType) => {
        if (unloadedFile) return;

        setUnloadedFile(file);
        dispatch(getOneClientTask({ companyId, taskId }));
    };

    const downloadDocument = (file: OfferTableDocType) => {
        downloadAsFile(file.filePath, file.name, () => errorHandler(file), successHandler);
    };

    const closeAddCommentModal = () => {
        setEditingOfferForReq(null);
    };

    useEffect(() => {
        if (editingOffer) {
            const offerFromTask = currentTask?.offers.find(
                (offer) => offer.taskOfferId === editingOffer.taskOfferId,
            );

            if (offerFromTask) {
                setEditingOffer(offerFromTask);
            }
        }

        if (!unloadedFile) return;

        downloadDocument(unloadedFile);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTask]);

    useEffect(() => {
        if (!(editingOfferForRequest && currentTask)) return;

        const updatedOffer = currentTask.offers.find(
            (offer) => offer.taskOfferId === editingOfferForRequest.taskOfferId,
        );

        if (!updatedOffer) return;

        setEditingOfferForReq(updatedOffer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTask?.offers]);

    const tableColumns = isLoan
        ? productsForLoanTaskTableColumns
        : offersForBankGuaranteeTaskTableColumns;
    const termText = !isLoan ? 'БГ ' : '' + 'Срок';
    const termUnit = !isLoan ? TermENUM.DAYS_SHORT : TermENUM.MONTHS_SHORT;
    return (
        <StyledOffersForTask isLoan={isLoan}>
            <div className="offers-for-task-table__info-with-btn">
                <div className="offers-for-task-table__general-info">
                    <p>Предложения от банков</p>
                    <p>
                        Сумма
                        <span className="offers-for-task-table__red">{sum}</span>
                        {termText}
                        <span className="offers-for-task-table__green">{term}</span>
                        {termUnit}
                    </p>
                </div>
                <div className="offers-for-task-table__add-bank-btn">
                    {!isTaskClosed && (
                        <button type="button" onClick={() => setAddBankModalOpen(true)}>
                            Добавить банк
                        </button>
                    )}
                </div>
            </div>
            <div className="offers-for-task-table__header">
                {tableColumns.map((column, index) => (
                    <div
                        key={column}
                        className={clsx({
                            'offers-for-task-table__right-position':
                                index > 1 && index < (isLoan ? 6 : 7),
                        })}
                    >
                        <p>{column}</p>
                    </div>
                ))}
            </div>
            {currentTask?.offers?.map((offer, index) => (
                <OffersForTaskTableRow
                    key={offer.taskOfferId}
                    offer={offer}
                    index={index}
                    givenTaskOfferId={givenOffer?.taskOfferId}
                    status={offer.status}
                    isLoan={isLoan}
                    isTaskClosed={isTaskClosed}
                    downloadDocument={downloadDocument}
                    setEditingOfferForReq={setEditingOfferForReq}
                    setEditingOffer={setEditingOffer}
                />
            ))}
            {editingOffer && (
                <EditOffer
                    companyId={companyId}
                    taskId={taskId}
                    offer={editingOffer}
                    bankGuaranteeAmount={currentTask?.bankGuaranteeAmount || null}
                    bankGuaranteeTermDays={currentTask?.bankGuaranteeTermDays || null}
                    close={() => setEditingOffer(null)}
                    width={618}
                    isLoan={isLoan}
                />
            )}
            {isAddBankModalOpen && (
                <AddBankToTask
                    companyId={companyId}
                    taskId={taskId}
                    close={() => setAddBankModalOpen(false)}
                    width="90vw"
                />
            )}
            {editingOfferForRequest ? (
                <AddRequestToTaskOffer
                    close={closeAddCommentModal}
                    width={900}
                    companyId={companyId}
                    editingOffer={editingOfferForRequest}
                    taskId={taskId}
                />
            ) : null}
        </StyledOffersForTask>
    );
};

export default OffersForTaskTable;
