/* eslint-disable no-unused-vars */
import React from 'react';

import {
    contactHighlightColorsNames,
    OfferDocTypes,
    noteColorsNames,
    SectionNameTypes,
} from './data';
import { Roles } from './consts';

export type Option = {
    value: string | number | null;
    label: string;
};

export enum UserStatusENUM {
    active = 'active',
    suspended = 'suspended',
}
export enum UserPositionNamesENUM {
    PFM = 'PFM',
    OSKO = 'OSKO',
}

export interface IUser {
    fullName: string | null;
    shortName: string | null;
    positionName: UserPositionNamesENUM | null;
    createdAt: string;
    deletedAt: string | null;
    email: string;
    phone: string | null;
    status: UserStatusENUM;
    updatedAt: string | null;
    userId: number;
    bot: boolean;
    isAdmin: boolean;
    isCallRecordEnabled: boolean;
    isFullBankInfoEnabled: boolean;
    telegramCode: string | number | null;
    birthDate: string | null;
    login: string | null;
    additNumber: string | null;
    passport?: string;
    snils?: string;
    inn?: string;
    telegramIntegrationCode?: string | null;
    telegramChatId?: string | null;
}

export interface AuthResponse {
    data: {
        tokens: {
            authorization: string;
            refresh: string;
        };
        message: string;
        user: IUser;
    };
}

export interface PhoneWithAdditInfoType {
    additInfo?: string;
    phoneNumber: string;
    isDisabled?: boolean;
}

export interface EmailWithAdditInfoType {
    additInfo?: string;
    email: string;
}

export interface EmailType {
    companyContactEmailId: number;
    createdAt: string;
    deletedAt: string | null;
    email: string;
    updatedAt: string | null;
    additInfo: null | string;
}

export interface CompanyContactInterface {
    companyContactId: number;
    emails: EmailWithAdditInfoType[];
    color: contactHighlightColorsNames;
    isPriority: boolean;
    name: string;
    phoneNumbers: PhoneWithAdditInfoType[];
    updatedAt: string;
    user: IUser;
}

export interface CompanyCommentInterface {
    companyCommentId: number;
    createdAt: string;
    deletedAt: string | null;
    text: string;
    updatedAt: string | null;
    user: IUser;
    company: CompanyInterface;
}

export interface TaskCommentInterface {
    taskCommentId: number;
    createdAt: string;
    deletedAt: string | null;
    text: string;
    updatedAt: string | null;
    user: IUser;
    task?: ClientTaskInterface;
    company?: CompanyInterface;
}

export interface CompanyNoteInterface {
    companyNoteId: number;
    createdAt: string;
    deletedAt: string | null;
    text: string;
    updatedAt: string | null;
    color: noteColorsNames;
    user: IUser;
}

export interface BaseReminderInterface {
    createdAt: string;
    deletedAt: string | null;
    text: string;
    updatedAt: string | null;
    time: string;
    user: IUser;
}

export interface CompanyReminderInterface extends BaseReminderInterface {
    companyReminderId: number;
    user: IUser;
}

export interface TaskReminderInterface extends BaseReminderInterface {
    taskReminderId: number;
}
export type ReminderInterface = TaskReminderInterface | CompanyReminderInterface;

export interface RegionInterface {
    name: string;
    nameWithType: string;
    regionId: number;
    timezone: number;
    type: string;
}

export interface CompanyInterface {
    companyId: number;
    addressActual: string;
    addressLegal: string;
    contactDate: string;
    inn?: string;
    ogrn?: string | null;
    kpp?: string | null;
    okved?: string | null;
    region: RegionInterface;
    city: string;
    clientTime: string;
    lastContactDate: string | null;
    lastCommentDate: string | null;
    BG: {
        requested: number;
        closed: number;
    };
    importance: ImportanceNameType | null;
    manager: IUser | null;
    user?: IUser | null;
    headName: string | null;
    headPost: string | null;
    hasAutoReminder: boolean;
    isMarked?: boolean;
    isHighlighted?: boolean;
    isCredit?: boolean;
    is44?: boolean;
    is223?: boolean;
    is615?: boolean;
    isKbg?: boolean;
    reminders: CompanyReminderInterface[];
    name: string | null;
    createdAt: string;
    updatedAt?: string | null;
    deletedAt?: string | null;
    contacts?: CompanyContactInterface[];
    comments?: CompanyCommentInterface[];
    notes?: CompanyNoteInterface[];
    registrationDate?: string | null;
    tasks?: ClientTaskInterface[];
    allTasks?: number;
    doneTasks?: number;
    successTasksCount?: number;
    income?: number;
}

export interface DocumentCreateInterface {
    file: string;
    name: string;
}

export interface DocumentInterface extends BaseDocInterface {
    postfix: string;
    companyDocumentId: number;
}

export interface DocumentCategoryInterface {
    companyDocumentCategoryId: number;
    createdAt: string;
    deletedAt: string | null;
    updatedAt: string | null;
    name: string;
    editable: boolean;
    section: SectionNameTypes;
    documents: DocumentInterface[];
    basicDocumentName: string;
}

export type ImportanceLabelType = 'Все' | 'Отсутствует' | 'Низкая' | 'Средняя' | 'Высокая';

export type ImportanceNameType = 'all' | 'no' | 'low' | 'medium' | 'high';

export type ContactPhoneNumberType = {
    phoneNumber: string;
    additInfo?: string;
    isDisabled?: boolean;
};

export type sortingFields =
    | 'contactDate'
    | 'region'
    | 'clientTimezone'
    | 'clientIncome'
    | 'doneTasks'
    | 'importance'
    | 'lastCommentDate';

export interface OfferDocInterface extends BaseDocInterface {
    name: string;
    section: OfferDocTypes;
    taskOfferDocumentId: number;
}

export interface RequestInterface {
    taskOfferRequestId: number;
    createdAt: string;
    updatedAt: string | null;
    deletedAt: string | null;
    isActive: boolean;
    text: string;
}

export interface BankProductInOfferInterface extends BankProductInterface {
    bank: PartnerInterface;
}

export interface OfferInterface {
    taskOfferId: number;
    createdAt: string;
    updatedAt: string | null;
    deletedAt: string | null;
    rateFrom: string;
    rateTo: string | null;
    comment: string | null;
    bankCommission: string | null;
    baseCommission: string | null;
    clientCommission: string | null;
    bankCommissionInRub: string | null;
    baseCommissionInRub: string | null;
    clientCommissionInRub: string | null;
    overstatement: string | null;
    status: TaskOfferStatusTypes;
    bankGuaranteeAmount: string | null;
    requests: RequestInterface[];
    documents: OfferDocInterface[];
    task?: ClientTaskInterface;
    bankProduct: BankProductInterface;
    agentSeparation?: string;
    income?: string;
    amount: string | null;
    term: string | null;
}

export interface TaskDocInterface extends BaseDocInterface {
    taskDocumentId: number;
    name: string;
}

export interface BankProductWithBank extends BankProductInterface {
    bank: PartnerInterface;
}

export interface OfferWithBankProduct extends OfferInterface {
    bankProduct: BankProductWithBank;
}

export interface ClientTaskInterface {
    taskId: number;
    createdAt: string;
    updatedAt: string | null;
    deletedAt: string | null;
    additComment: string | null;
    bankGuaranteeAmount: string | null;
    bankGuaranteeTermDays: number | null;
    bankGuaranteeTermFrom: string | null;
    bankGuaranteeTermTo: string | null;
    bankGuaranteeType: TaskProductType;
    closeComment: string | null;
    closeDate: string | null;
    closeReason: TaskCloseReasonsTypes | null;
    coExecutor: IUser | null;
    company: CompanyInterface;
    executor: IUser | null;
    contractAdvanceAvailability: string | null;
    contractConclusionDate: string | null;
    federalLaw: FederalLawsTypes | null;
    importance: TaskImportanceTypes | null;
    importantInfo: string | null;
    initialContractPrice: string | null;
    postscript: string | null;
    status: TaskStatusTypes;
    procurementProcedureLink: string | null;
    procurementProcedureName: string | null;
    procurementProcedureNumber: string | null;
    type: TaskProductTypes;
    loanType: LoanProductTypes;
    loanAmount: string | null;
    loanTermMonths: number | null;
    offers: OfferWithBankProduct[];
    comments: TaskCommentInterface[];
    reminders: TaskReminderInterface[];
    documents: TaskDocInterface[];
    userWhoClosedTask: IUser | null;
}

export type UserDocTypes = 'passport' | 'snils' | 'inn';

export interface BaseDocInterface {
    createdAt: string;
    deletedAt: string | null;
    updatedAt: string | null;
    filePath: string;
}

export type ActDocTypes = 'pdf' | 'docx';

export interface ActInterface {
    createdAt: string;
    deletedAt: string | null;
    updatedAt: string | null;
    actId: number;
    dateFrom: string;
    dateTo: string;
    document: ActDocInterface | null;
    cashbacks: CashbackInterface[];
    receiver: ReceiverInterface;
    totalCashbackAmount: number;
}

export interface UserDocInterface extends BaseDocInterface {
    name: string;
    type: UserDocTypes;
    userDocumentId: number;
}

export interface ActDocInterface extends BaseDocInterface {
    name: string;
    actDocumentId: number;
}

export type BankDocumentSectionTypes = 'instructions' | 'agencyContract' | 'powersOfAttorney';

export interface PartnerDocument {
    name: string;
    section: BankDocumentSectionTypes;
    filePath: string;
    createdAt?: string;
    deletedAt?: string | null;
    updatedAt?: string | null;
    bankDocumentId: number;
}

export interface BankProductDocument {
    name: string;
    filePath: string;
    createdAt?: string;
    deletedAt?: string | null;
    updatedAt?: string | null;
    bankProductDocumentId: number;
}

export interface CreatePartnerDocument {
    name: string;
    section: BankDocumentSectionTypes;
    file: string;
}

export interface BankProductDoc {
    name: string;
    file: string;
}

export interface BankProductRequest {
    limit: string;
    rateFrom: string;
    term: string;
    hasFZ44?: boolean;
    hasFZ223?: boolean;
    hasPP615?: boolean;
    experience: string;
    age: string;
    worksWithIP: boolean;
    worksWithAO: boolean;
    baseCommission: string;
    clientCommission: string;
    additInfo: string;
    stopRegions: string[];
    lists?: string[];
    documents: BankProductDoc[];
    isActive: boolean;
}

export type PartnerFileTypes = 'instructions' | 'logo';

export type PartnerSwitcherTypes = 'isDisplayedInList' | 'isPriority';

export type PartnerDocArrayTypes = 'agencyContract' | 'powersOfAttorney';

export type FederalLawsTypes = 'fz44' | 'fz223' | 'pp615' | 'commerce' | 'other';

export enum FederalLawsTypeENUM {
    FZ44 = 'fz44',
    FZ223 = 'fz223',
    PP615 = 'pp615',
    COMMERCE = 'commerce',
    OTHER = 'other',
}

export interface PartnerCredential {
    createdAt?: string;
    deletedAt?: string | null;
    updatedAt?: string | null;
    type: string;
    lkOperator: string;
    lkLink: string;
    login: string;
    password: string;
    contacts: PartnerContact[];
    bankCredentialId?: number;
}

export type TaskProductType =
    | 'contractExecution'
    | 'advanceRefund'
    | 'guaranteeObligation'
    | 'applicationSecurity'
    | 'other';

export enum TaskGuaranteeProductENUM {
    CONTRACT_EXECUTION = 'contractExecution',
    ADVANCE_REFUND = 'advanceRefund',
    GUARANTEE_OBLIGATION = 'guaranteeObligation',
    APPLICATION_SECURITY = 'applicationSecurity',
    OTHER = 'other',
}

export type BankProductType =
    | 'bankContractExecution'
    | 'bankAdvanceRefund'
    | 'bankGuaranteeObligation'
    | 'applicationSecurity'
    | 'commercialContractExecution'
    | 'commercialAdvanceRefund'
    | 'commercialGuaranteeObligation'
    | 'loanUnderContract'
    | 'loanRevolving';

export type BankProductCategoryType = 'commercialGuarantee' | 'bankGuarantee' | 'loan';

export interface FileWithName {
    name: string;
    file: string;
}

export interface PartnerContact {
    name: string;
    emails: EmailWithAdditInfoType[];
    phoneNumbers: PhoneWithAdditInfoType[];
    createdAt?: string;
    deletedAt?: string | null;
    updatedAt?: string | null;
    bankCredentialContactId?: number;
}

export interface PartnerFileInterface extends FileWithName {
    section: BankDocumentSectionTypes;
}

export interface EmailWithError extends EmailWithAdditInfoType {
    isError: boolean;
    additInfo: string;
}

export interface PhoneWithError extends PhoneWithAdditInfoType {
    isError: boolean;
    additInfo: string;
}

export interface PartnerContactWithError extends PartnerContact {
    emails: EmailWithError[];
    phoneNumbers: PhoneWithError[];
}

export interface CredentialsWithError {
    bankCredentialId?: number;
    type: string;
    lkLink: string;
    lkOperator: string;
    login: string;
    password: string;
    contacts: PartnerContactWithError[];
}

export interface BankProductListInterface {
    bankProductListId: number;
    createdAt: string;
    deletedAt: string | null;
    updatedAt: string | null;
    name: string;
}

export interface BankProductBaseInterface {
    limit: string | null;
    rateFrom: string | null;
    term: string | null;
    hasFZ44: boolean;
    hasFZ223: boolean;
    hasPP615: boolean;
    experience: string | null;
    age: string | null;
    worksWithIP: boolean;
    worksWithAO: boolean;
    baseCommission: string | null;
    clientCommission: string | null;
    additInfo: string | null;
    registrationTerm: string | null;
    issuanceCommission: string | null;
    category?: string | null;
}

export interface BankProductDocumentCreateInterface extends DocumentCreateInterface {
    bankProductDocumentId?: number;
}

export interface BankProductCreateInterface extends BankProductBaseInterface {
    limit: string;
    rateFrom: string;
    term: string;
    experience: string;
    age: string;
    baseCommission: string;
    clientCommission: string;
    additInfo: string;
    documents: BankProductDocumentCreateInterface[];
    lists: string[];
    stopRegions: string[];
    isActive: boolean;
    registrationTerm: string;
    issuanceCommission: string;
    empty: string;
}

export interface BankProductInterface extends BankProductBaseInterface {
    createdAt: string;
    deletedAt: string | null;
    updatedAt: string | null;
    bankProductId: number;
    type: BankProductType;
    isActive: boolean;
    documents: BankProductDocument[];
    lists: BankProductListInterface[];
    stopRegions: StopRegionsInterface[];
}

export interface PartnerInterface {
    createdAt: string;
    deletedAt: string | null;
    updatedAt: string | null;
    bankId: number;
    name: string;
    logo: string | null;
    isDisplayedInList: boolean;
    isPriority: boolean;
    additInfo: string | null;
    documents: PartnerDocument[];
    credentials: PartnerCredential[];
    products: BankProductInterface[];
    user: IUser;
}

export interface FileCreationInterface {
    file: string;
    name: string;
}

export interface StopRegionsInterface {
    createdAt: string;
    deletedAt: string | null;
    updatedAt: string | null;
    regionId: number;
    name: string;
    type: string;
    nameWithType: string;
    timezone: number;
}

export interface ProductListInterface {
    createdAt: string;
    deletedAt: string | null;
    updatedAt: string | null;
    bankProductListId: number;
    name: string;
}

export type lawFieldsType = 'hasFZ44' | 'hasFZ223' | 'hasPP615';
export type workWithFieldsType = 'worksWithIP' | 'worksWithAO';
export type TaskOfferStatusTypes =
    | 'started'
    | 'signing'
    | 'consideration'
    | 'additRequest'
    | 'pending'
    | 'bankRefused'
    | 'clientRefused'
    | 'commissionApproval'
    | 'offer'
    | 'givenOut'
    | 'fixed';

export enum TaskOfferStatusENUM {
    STARTED = 'started',
    SIGNING = 'signing',
    CONSIDERATION = 'consideration',
    ADDIT_REQUEST = 'additRequest',
    PENDING = 'pending',
    BANK_REFUSED = 'bankRefused',
    CLIENT_REFUSED = 'clientRefused',
    COMMISSION_APPROVAL = 'commissionApproval',
    OFFER = 'offer',
    GIVEN_OUT = 'givenOut',
    FIXED = 'fixed',
}

export type TaskStatusTypes =
    | 'distribution'
    | 'analysis'
    | 'institution'
    | 'signing'
    | 'consideration'
    | 'additRequest'
    | 'pending'
    | 'decision'
    | 'layout'
    | 'closed'
    | 'onRelease';

export type TaskImportanceTypes = 'pending' | 'urgent';
export type TaskUnsuccessfulCloseReasonsTypes = 'clientRefused' | 'noOffers' | 'termsExpired';
export type TaskCloseReasonsTypes = TaskUnsuccessfulCloseReasonsTypes | 'done';

export type TaskProductTypes = 'bankGuarantee' | 'loan';

export enum TaskProductENUM {
    BANK_GUARANTEE = 'bankGuarantee',
    LOAN = 'loan',
}

export enum LoanProductENUM {
    LOAN_UNDER_CONTRACT = 'loanUnderContract',
    LOAN_REVOLVING = 'loanRevolving',
}

export type LoanProductTypes = 'loanUnderContract' | 'loanRevolving';

export interface StatisticTaskCommentInterface extends TaskCommentInterface {
    task: ClientTaskInterface;
}

export type CashbackStatusType = 'paid' | 'accrued';
export interface CashbackInterface {
    createdAt: string;
    deletedAt: string | null;
    updatedAt: string | null;
    cashbackId: number;
    cashbackSum: string | null;
    receiverName: string;
    type: string;
    status: CashbackStatusType;
    paymentDate: string | null;
    receiverInn: string;
    receiverHeadName: string;
    receiverHeadPost: string;
    checkingAccount: string | null;
    bankIdentificationCode: string | null;
    correspondentAccount: string | null;
    individualInn: string | null;
    importantInfo: string | null;
    company: CompanyInterface;
    offer: OfferInterface;
}

export interface ReceiverInterface {
    createdAt: string;
    deletedAt: string | null;
    updatedAt: string | null;
    companyReceiverId: number;
    name: string;
    inn: string;
    headName: string;
    headPost: string;
    default: boolean;
}

export type AuthRoutesType = {
    name: string;
    link: string;
    Component: React.FC;
    role: Roles[];
    icon?: {
        selected: string;
        notSelected: string;
    };
    children?: AuthRoutesType[];
};

export type ClientTasksType = {
    loanTasks: ClientTaskInterface[];
    bankGuaranteeTasks: ClientTaskInterface[];
};

export type ClientTasksCountersType = {
    activeLoanTasks: number;
    activeBankGuaranteeTasks: number;
    closedLoanTasks: number;
    closedBankGuaranteeTasks: number;
};

export type ActiveRequestsFieldNameType = 'manager' | 'executor' | 'type' | 'status';

export type ActiveRequestsFilterParams = {
    manager: string;
    executor: string;
    type: string;
    status: string;
};

export interface CallsRecordInterface {
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date | null;
    callId: number;
    extCallId: string;
    direction: string;
    status: string;
    phone: string;
    telnum: string;
    duration: string;
    recordUrl: string | null;
    startedAt: Date;
    company: CompanyInterface;
    manager: IUser;
    comment: string;
}

export enum CallDirectionENUM {
    IN = 'in',
    OUT = 'out',
}

export type CallDirectionType = 'in' | 'out';

export enum CallStatusENUM {
    SUCCESS = 'Success',
    MISSED = 'missed',
    CANCEL = 'Cancel',
    BUSY = 'Busy',
    NOT_AVAILABLE = 'NotAvailable',
    NOT_ALLOWED = 'NotAllowed',
    NOT_FOUND = 'NotFound',
}
