import React, { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RotatingLines } from 'react-loader-spinner';
import clsx from 'clsx';

import { partnersTableColumns, partnersTableWithColumns } from '../../../utils/data';
import { setProductsByFilter } from '../../../store/slices/partnerSlice';
import { RootState, useAppDispatch } from '../../../store';
import { PartnerInterface } from '../../../utils/types';
import TableAfterSearch from './TableAfterSearch/TableAfterSearch';
import TableAfterFilter from './TableAfterFilter/TableAfterFilter';
import StyledClientsTable from './PartnersTable.style';

type PartnersTableProps = {
    isFilterOpen: boolean;
    // eslint-disable-next-line no-unused-vars
    setEditablePartnerId: (value: number) => void;
    isFilterEmpty: boolean;
};

const PartnersTable = ({
    setEditablePartnerId,
    isFilterOpen,
    isFilterEmpty,
}: PartnersTableProps) => {
    const dispatch = useAppDispatch();
    const { partnersList, loading } = useSelector((state: RootState) => state.partner);

    const columns = isFilterOpen ? partnersTableWithColumns : partnersTableColumns;
    const showTable = !(isFilterEmpty && isFilterOpen);

    const normalizedPartners = useMemo((): PartnerInterface[] => {
        if (!isFilterOpen) return partnersList;
        const normalizedPartners: PartnerInterface[] = [];

        partnersList.forEach((partner) => {
            partner.products.forEach((product) => {
                normalizedPartners.push({
                    ...partner,
                    products: [product],
                });
            });
        });
        return normalizedPartners;
    }, [partnersList, isFilterOpen]);

    useEffect(() => {
        if (!normalizedPartners) {
            return;
        }

        dispatch(setProductsByFilter(normalizedPartners.length));
    }, [dispatch, normalizedPartners]);

    return (
        <StyledClientsTable>
            {showTable ? (
                <>
                    <div
                        className={clsx('partners-table__header', {
                            'partners-table__table-grid-open-filter': isFilterOpen,
                        })}
                    >
                        {columns.map((column, index) => (
                            <div
                                key={index}
                                className={clsx('partners-table__column-name', {
                                    'partners-table__right-position':
                                        isFilterOpen && index > 4 && index < 7,
                                })}
                            >
                                <p>{column}</p>
                            </div>
                        ))}
                    </div>
                    {loading.getPartnerList ? (
                        <div className="partners-table__loader">
                            <RotatingLines strokeColor="grey" />
                        </div>
                    ) : (
                        <div className="partners-table__body">
                            {normalizedPartners.map((partner, index) => (
                                <div
                                    key={String(partner.bankId) + index}
                                    className={clsx('partners-table__partner', {
                                        'partners-table__table-grid-open-filter': isFilterOpen,
                                        'partners-table__even-field': index % 2 !== 0,
                                    })}
                                >
                                    {isFilterOpen ? (
                                        <TableAfterFilter
                                            numberInList={index + 1}
                                            partner={partner}
                                            setEditablePartnerId={setEditablePartnerId}
                                        />
                                    ) : (
                                        <TableAfterSearch
                                            numberInList={index + 1}
                                            partner={partner}
                                            setEditablePartnerId={setEditablePartnerId}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                </>
            ) : (
                <p className="partners-table__table-placeholder">Выберите параметры фильтрации</p>
            )}
        </StyledClientsTable>
    );
};

export default PartnersTable;
