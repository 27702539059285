import styled from 'styled-components/macro';

const StyledPartnersPageWrapper = styled.div`
    .partners-page__heading {
        padding-top: 53px;
        padding-left: 231px;
        font-size: 32.8px;
        font-weight: 600;
    }

    .partners-table__gray-field {
        color: #c4c4c4;
    }
`;

export default StyledPartnersPageWrapper;
