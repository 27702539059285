import styled from 'styled-components/macro';

const StyledContainer = styled.div`
    .edit-or-delete-category__category-name {
        padding: 10px 0;
    }

    .edit-or-delete-category__actions {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }
`;

export default StyledContainer;
