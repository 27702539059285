import React from 'react';

import StyledToggleSwitch from './ToggleSwitch.style';
import clsx from 'clsx';

type ToggleSwitchProps = {
    width?: number;
    height?: number;
    checked: boolean;
    disabled?: boolean;
    // eslint-disable-next-line no-unused-vars
    toggle: () => void;
};

const ToggleSwitch = ({
    width = 60,
    height = 34,
    checked,
    toggle,
    disabled,
}: ToggleSwitchProps) => {
    const toggleHandler = () => {
        if (disabled) return;

        toggle();
    };

    return (
        <StyledToggleSwitch width={width} height={height} onClick={toggleHandler}>
            <input type="checkbox" checked={checked} onChange={() => {}} />
            <span
                className={clsx('toggle-switch__switcher', {
                    'toggle-switch__disabled-switcher': disabled,
                })}
            />
        </StyledToggleSwitch>
    );
};

export default ToggleSwitch;
