import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { authRoutes } from '../../routes';
import { StyledNavlink, NavbarWrapper, StyledChildNavlink } from './Navbar.style';
import { userSelector } from '../../store/slices/userSlice';
import { Routes } from '../../utils/consts';

const Navbar = () => {
    const [selectedLink, setSelectedLink] = useState('');
    const { user } = useSelector(userSelector);
    const enableCallsPage = user?.isAdmin || user?.isCallRecordEnabled;
    const location = useLocation();

    useEffect(() => {
        const route = authRoutes.find((nav: { link: string }) =>
            location.pathname.includes(nav.link),
        );
        setSelectedLink(route?.name || '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    if (location.pathname.includes('login')) {
        return <div />;
    }

    return (
        <NavbarWrapper>
            <nav className="navbar__navigation-container">
                {authRoutes.map(({ link, name, children: childRoutes, icon }) => {
                    const isCallsPage = link === Routes.CALLS;
                    if (isCallsPage && !enableCallsPage) {
                        return;
                    }
                    return (
                        <li
                            key={link}
                            className="navbar__navigation-item"
                            style={{
                                height: `${
                                    selectedLink !== name
                                        ? 32
                                        : ((childRoutes?.length || 0) + 1) * 32
                                }px`,
                            }}
                        >
                            <StyledNavlink to={childRoutes ? childRoutes[0].link : link}>
                                <div
                                    className={clsx('navbar__link-name-with-icon', {
                                        'navbar__selected-link': selectedLink === name,
                                    })}
                                    onClick={() => setSelectedLink(name)}
                                >
                                    {icon ? (
                                        <img
                                            src={
                                                selectedLink === name
                                                    ? icon.selected
                                                    : icon.notSelected
                                            }
                                        />
                                    ) : (
                                        <div />
                                    )}
                                    {name}
                                </div>
                            </StyledNavlink>
                            {selectedLink === name && childRoutes ? (
                                <div className="navbar__navigation-children">
                                    {childRoutes.map(({ name, link }) => (
                                        <div className="navbar__link-name-with-icon" key={link}>
                                            <StyledChildNavlink className="" to={link}>
                                                {name}
                                            </StyledChildNavlink>
                                        </div>
                                    ))}
                                </div>
                            ) : null}
                        </li>
                    );
                })}
            </nav>
        </NavbarWrapper>
    );
};

export default Navbar;
