/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import clsx from 'clsx';

import { getDate, getUserName } from '../../../../utils/functions';
import {
    CompanyCommentInterface,
    CompanyInterface,
    IUser,
    StatisticTaskCommentInterface,
    TaskCommentInterface,
} from '../../../../utils/types';

type StatisticCommentsTableRowProps = {
    comment: CompanyCommentInterface | StatisticTaskCommentInterface;
    user: IUser | null;
    isTaskTable: boolean;
    getHourDivider: (date: string) => JSX.Element | null;
};

const StatisticCommentsTableRow = ({
    comment,
    user,
    isTaskTable,
    getHourDivider,
}: StatisticCommentsTableRowProps) => {
    const getCompany = (comment: CompanyCommentInterface | TaskCommentInterface) => {
        if ('company' in comment) {
            return comment.company;
        } else if ('task' in comment) {
            return comment?.task?.company;
        }
        return;
    };

    const getTaskId = (comment: StatisticTaskCommentInterface) => {
        const taskId = comment?.task?.taskId;

        if (!taskId) return;

        return taskId;
    };

    const navigateToCompany = (company?: CompanyInterface) => {
        if (!company || (!user?.isAdmin && user?.userId !== company?.manager?.userId)) return;

        return `/clients/card/${company.companyId}?tab=clientCard`;
    };

    const navigateToTask = (comment: StatisticTaskCommentInterface) => {
        const taskId = getTaskId(comment);

        const companyId = getCompany(comment)?.companyId;

        if (!taskId || !companyId) return;

        return `/clients/${companyId}/task/${taskId}`;
    };

    const commentData = useMemo(() => {
        return {
            hoursDivider: getHourDivider(comment.createdAt),
            date: getDate(comment.createdAt, 'time'),
        };
    }, [comment.createdAt]);

    const dateClassName = useMemo(
        () =>
            clsx('comments-table__comment', {
                'comments-table__task-table-row': isTaskTable,
            }),
        [isTaskTable],
    );

    const company = useMemo(() => {
        return {
            link: navigateToCompany(getCompany(comment)),
            className: clsx('comments-table__company-name', {
                'comments-table__disabled-company':
                    !user?.isAdmin && user?.userId !== getCompany(comment)?.manager?.userId,
            }),
            name: getCompany(comment)?.name || '---',
        };
    }, [comment, user]);

    const taskId = useMemo(() => {
        return {
            link: navigateToTask(comment as StatisticTaskCommentInterface),
            name: getTaskId(comment as StatisticTaskCommentInterface) || '---',
        };
    }, [comment]);

    const author = getUserName(comment.user || comment?.company?.user);

    return (
        <div>
            {commentData.hoursDivider}
            <div className={dateClassName}>
                <p>{commentData.date}</p>
                <a href={company.link} className={company.className}>
                    {company.name}
                </a>
                {isTaskTable && (
                    <a href={taskId.link} className="comments-table__company-name">
                        {taskId.name}
                    </a>
                )}
                <div className="comments-table__comment-item">
                    <p className="comments-table__comment-author">{author}:</p>
                    <div
                        className="comment-table__text"
                        dangerouslySetInnerHTML={{ __html: comment.text || '' }}
                    />
                </div>
            </div>
        </div>
    );
};

export default StatisticCommentsTableRow;
