import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { restoreCompany, selectClientDatabase } from '../../../store/slices/clientDatabaseSlice';
import { userSelector } from '../../../store/slices/userSlice';
import StyledContainer from './INNisOccupied.style';
import { getUserName } from '../../../utils/functions';
import { useAppDispatch } from '../../../store';
import { INNisOccupiedLabels } from './data';

type INNisOccupiedProps = {
    // eslint-disable-next-line no-unused-vars
    previousStep: () => void;
};

const INNisOccupied = ({ previousStep }: INNisOccupiedProps) => {
    const { checkInnResult } = useSelector(selectClientDatabase);
    const { user } = useSelector(userSelector);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const hasRight = Boolean(user?.isAdmin || user?.userId === checkInnResult?.manager?.userId);

    const handleRestoreCompany = () => {
        dispatch(restoreCompany({ companyId: Number(checkInnResult.companyId) }));
        navigate(`/clients/card/${checkInnResult.companyId}?tab=clientCard`);
    };

    return (
        <StyledContainer>
            <h3>Добавить нового клиента (шаг 1 из 2)</h3>
            <p className="inn-is-occupied__inn-number">
                клиент <span>ИНН {'inn' in checkInnResult ? checkInnResult.inn : ''}</span>
            </p>
            <p className="inn-is-occupied__red-text">
                {checkInnResult?.deletedAt
                    ? INNisOccupiedLabels.getCompanyArchivedLabel(user?.isAdmin)
                    : INNisOccupiedLabels.companyExist}
            </p>
            {checkInnResult?.deletedAt ? (
                <>
                    <p className="inn-is-occupied__client-name">
                        {'name' in checkInnResult ? checkInnResult.name : ''}{' '}
                        {user?.isAdmin && (
                            <span>
                                -{' '}
                                <button onClick={() => handleRestoreCompany()}>Восстановить</button>
                            </span>
                        )}
                    </p>
                    {checkInnResult?.manager && (
                        <p className="inn-is-occupied__manager">
                            Менеджер: {getUserName(checkInnResult.manager)}
                        </p>
                    )}
                    <p className="inn-is-occupied__description">
                        Данная компания находится в архиве
                    </p>
                </>
            ) : (
                <>
                    <p className="inn-is-occupied__client-name">
                        {'name' in checkInnResult ? checkInnResult.name : ''}{' '}
                        {hasRight && (
                            <span>
                                -{' '}
                                <button
                                    onClick={() =>
                                        navigate(
                                            `/clients/card/${checkInnResult.companyId}?tab=clientCard`,
                                        )
                                    }
                                >
                                    перейти в карточку клиента
                                </button>
                            </span>
                        )}
                    </p>
                    {checkInnResult?.manager ? (
                        <p className="inn-is-occupied__manager">
                            Менеджер: {getUserName(checkInnResult.manager)}
                        </p>
                    ) : null}
                    <p className="inn-is-occupied__description">
                        Вы не можете зарегистрировать данного клиента, вернитесь на предыдущий этап
                        и внесите новые сведения
                    </p>
                </>
            )}
            <button
                type="button"
                className="inn-is-occupied__submit-btn"
                onClick={() => previousStep()}
            >
                Вернуться назад
            </button>
        </StyledContainer>
    );
};

export default INNisOccupied;
