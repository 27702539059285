import styled from 'styled-components/macro';

const StyledTooltipWrapper = styled.div`
    .tooltip__container {
        max-width: 340px;
        background: lightgray;
        padding: 10px;
        font-weight: 400;
        font-size: 12px;
        border-radius: 5px;
        overflow-wrap: break-word;
        overflow-y: auto;
        white-space: pre-wrap;
    }
`;

export default StyledTooltipWrapper;
