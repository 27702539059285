import styled from 'styled-components/macro';

const StyledFilterWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 30px;

    p {
        font-weight: 500;
    }

    .cashback-stat-filter__date-filter {
        display: flex;
        align-items: center;
        height: 30px;

        input {
            margin: 0;
            padding: 2px 5px;
            border: 1px solid #959595;
            border-radius: 5px;
            height: 24px;
        }

        p {
            margin: 0 5px;
        }
    }

    .cashback-stat-filter__label {
        margin: 0 5px 0 15px;

        &:first-of-type {
            margin: 0 10px 0 7px;
        }
    }
`;

export default StyledFilterWrapper;
