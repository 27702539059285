import styled from 'styled-components/macro';

const StyledTimer = styled.p`
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #0038ff;
    text-align: center;
`;

export default StyledTimer;
