import React from 'react';
import clsx from 'clsx';
import 'react-phone-input-2/lib/style.css';

import { EmailWithError, PhoneWithError } from '../../../utils/types';
import { StyledFormWrapper, StyledPhoneInput } from './AddPhoneAndEmail.style';

type AddPhoneAndEmailProps = {
    phoneNumbers: PhoneWithError[];
    emails: EmailWithError[];
    // eslint-disable-next-line no-unused-vars
    setEmails: (value: EmailWithError[]) => void;
    // eslint-disable-next-line no-unused-vars
    setPhoneNumbers: (value: PhoneWithError[]) => void;
    hideDisablingCheckbox?: boolean;
};

const AddPhoneAndEmail = ({
    emails,
    phoneNumbers,
    setEmails,
    setPhoneNumbers,
    hideDisablingCheckbox,
}: AddPhoneAndEmailProps) => {
    const changeEmailHandler = (value: string, index: number, isAdditNum?: boolean) => {
        const newEmails = [...emails];
        if (!isAdditNum) {
            newEmails[index].email = value;
        } else {
            newEmails[index].additInfo = value;
        }
        setEmails([...newEmails]);
    };

    const changePhoneHandler = (value: string, index: number, isAdditNum?: boolean) => {
        const newPhones = [...phoneNumbers];
        if (!isAdditNum) {
            newPhones[index].phoneNumber = value;
        } else {
            newPhones[index].additInfo = value;
        }
        setPhoneNumbers([...newPhones]);
    };

    const changePhoneDisable = (index: number) => {
        const newPhones = [...phoneNumbers];
        newPhones[index].isDisabled = !newPhones[index].isDisabled;
        setPhoneNumbers([...newPhones]);
    };

    const addPhoneClickHandler = () => {
        const defaultPhoneNumber = { phoneNumber: '', additInfo: '', isError: false };
        setPhoneNumbers([...phoneNumbers, defaultPhoneNumber]);
    };

    const addEmailClickHandler = () => {
        const defaultEmail = { email: '', additInfo: '', isError: false };
        setEmails([...emails, defaultEmail]);
    };

    return (
        <StyledFormWrapper>
            {phoneNumbers.map((phone, index) => (
                <div
                    key={phone.phoneNumber + index}
                    className="add-phone-and-email__row-with-plus-icon"
                >
                    <div className="add-phone-and-email__phone">
                        <label
                            className={clsx({
                                'add-phone-and-email__error-field': phone.isError,
                            })}
                        >
                            Номер телефона:
                            <StyledPhoneInput
                                country={'ru'}
                                placeholder="+7(000)000-00-00"
                                value={phone.phoneNumber}
                                onBlur={(e) => changePhoneHandler(e.target.value, index)}
                            />
                        </label>
                        {!hideDisablingCheckbox ? (
                            <label>
                                Неакт.
                                <input
                                    id="isDisabled"
                                    checked={phone.isDisabled}
                                    type="checkbox"
                                    onChange={() => changePhoneDisable(index)}
                                />
                            </label>
                        ) : (
                            <div></div>
                        )}
                        <label>
                            Доб.
                            <input
                                id="additionalNumber"
                                value={phone.additInfo || ''}
                                onChange={(e) => changePhoneHandler(e.target.value, index, true)}
                            />
                        </label>
                    </div>
                    <span onClick={addPhoneClickHandler}>+</span>
                </div>
            ))}
            {emails.map((item, index) => (
                <div key={item.email + index} className="add-phone-and-email__row-with-plus-icon">
                    <div className="add-phone-and-email__email">
                        <label
                            className={clsx({
                                'add-phone-and-email__error-field': item.isError,
                            })}
                        >
                            e-mail:
                            <input
                                id="email"
                                type="email"
                                onBlur={(e) => changeEmailHandler(e.target.value, index)}
                                defaultValue={item.email}
                            />
                        </label>
                        <label>
                            Доб.
                            <input
                                id="additionalInfo"
                                value={item.additInfo || ''}
                                onChange={(e) => changeEmailHandler(e.target.value, index, true)}
                            />
                        </label>
                    </div>
                    <span onClick={addEmailClickHandler}>+</span>
                </div>
            ))}
        </StyledFormWrapper>
    );
};

export default AddPhoneAndEmail;
