import styled from 'styled-components/macro';

const StyledWorkArea = styled.div`
    font-weight: 500;
    font-size: 12px;
    button {
        text-decoration: underline;
        color: #0038ff;
        border: none;
        background: transparent;
    }

    .work-area__header {
        padding: 5px 7px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #fdd55b;
        height: 30px;
        img {
            width: 20px;
            height: 20px;
            margin-left: 10px;
            transform: rotate(180deg);
        }
    }

    .work-area__show-section-btn {
        display: flex;
        align-items: center;
        color: #0038ff;
        text-decoration: underline;
        cursor: pointer;
    }

    .work-area__opened-section {
        img {
            transform: rotate(0);
        }
    }

    .work-area__body {
        background: #e5e5e5;
        display: grid;
        grid-template-columns: 2fr 410px;
        max-height: 420px;
        overflow: hidden;
    }

    .work-area__general-information {
        padding: 9px 0 46px 6px;
        max-height: inherit;
        overflow: hidden;
    }

    .work-area__add-comment-btn {
        display: flex;
        justify-content: end;
    }

    .work-area__comments-area {
        background: #fff;
        height: 340px;
        border-radius: 5px;
        margin: 7px 0;
        overflow: auto;
        padding: 5px;
    }

    .work-area__stats {
        padding: 7px;
        max-height: inherit;
        overflow: hidden;
    }

    .work-area__stats-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 7px;
        font-weight: 700;
        line-height: 17px;
    }

    .work-area__stat-header {
        font-weight: 500;
        margin-bottom: 7px;
    }

    .work-area__italic-row {
        font-style: italic;
        font-weight: 400;
    }

    .work-area__note-list {
        height: calc(100% - 110px);
        padding-right: 5px;
        overflow-y: auto;
    }

    .work-area__green-row {
        color: #00892f;
    }
`;

export default StyledWorkArea;
