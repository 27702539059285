import styled from 'styled-components/macro';

const StyledClientRequests = styled.div<{ height: number; isLoan: boolean }>`
    font-weight: 500;
    font-size: 12px;
    height: ${({ height }) => `${height}px`};
    max-height: calc(80vh - 110px);

    button {
        text-decoration: underline;
        color: #0038ff;
        background: transparent;
        border: none;
    }

    .table-with-banks-for-task__header {
        padding: 5px 7px;
        align-items: center;
        display: grid;
        grid-template-columns: ${({ isLoan }) =>
            isLoan
                ? `175px minmax(130px, 4fr) minmax(70px, 2fr) minmax(75px, 2fr) repeat(3, minmax(130px, 4fr))
                minmax(80px, 2fr) 150px`
                : `175px minmax(130px, 4fr) minmax(70px, 2fr) repeat(3, minmax(130px, 4fr))
                minmax(80px, 2fr) minmax(75px, 2fr) 150px`};
        grid-column-gap: 10px;
        height: 30px;
        border-bottom: 1px solid #959595;

        img {
            width: 20px;
            height: 20px;
            margin-left: 10px;
            transform: rotate(180deg);
        }
    }

    .table-with-banks-for-task__row {
        padding: 5px 7px;
        display: grid;
        grid-template-columns: 175px 1fr;
        grid-column-gap: 10px;
        color: #0038ff;
        text-decoration: underline;
        cursor: pointer;
    }

    .table-with-banks-for-task__bank-product {
        padding: 5px 7px;
        display: grid;
        padding-left: 192px;
        grid-template-columns: ${({ isLoan }) =>
            isLoan
                ? `minmax(130px, 4fr) minmax(70px, 2fr) minmax(75px, 2fr) repeat(3, minmax(130px, 4fr))
                minmax(80px, 2fr) 150px`
                : `minmax(130px, 4fr) minmax(70px, 2fr) repeat(3, minmax(130px, 4fr))
                minmax(80px, 2fr) minmax(75px, 2fr) 150px`};

        grid-column-gap: 10px;
    }

    .table-with-banks-for-task__odd-field {
        background: #f8f8f8;
    }

    .client-request-table__task-number {
        text-decoration-line: underline;
        color: #0038ff;
        cursor: pointer;
    }

    .table-with-banks-for-task__icons {
        display: flex;
        justify-content: end;

        img {
            width: 20px;
            height: 20px;
            margin-left: 11px;
        }
    }

    .table-with-banks-for-task__general-info {
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
            line-height: 30px;
            font-weight: 600;
        }

        span {
            margin: 0 5px;
        }
    }

    .table-with-banks-for-task__green {
        color: #00892f;
    }

    .table-with-banks-for-task__red {
        color: #d20202;
    }

    .table-with-banks-for-task__info-with-btn {
        background: #e7e7e7;
        display: grid;
        grid-template-columns: 1fr 110px;
        grid-column-gap: 10px;
        padding: 0 8px;
    }

    .table-with-banks-for-task__actions-with-product {
        display: flex;
        justify-content: space-between;
    }

    .table-with-banks-for-task__placeholder {
        text-align: center;
        font-weight: 600;
        height: 30px;
        width: 100%;
        margin-top: 20px;
    }

    .table-with-banks-for-task__disabled-btn {
        opacity: 0.6;
        cursor: auto;
    }

    .table-with-banks-for-task__green-field {
        background: rgba(154, 255, 188, 0.5);
    }

    .table-with-banks-for-task__gray-field {
        opacity: 0.5;
    }

    .table-with-banks-for-task__right-position {
        display: flex;
        justify-content: flex-end;
        margin-right: 10px;
    }

    .table-with-banks-for-task__doc-icon {
        cursor: pointer;
    }

    .table-with-banks-for-task__loader {
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }
`;

export default StyledClientRequests;
