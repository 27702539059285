import React, { useEffect, useMemo, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import toast from 'react-hot-toast';

import { useAppDispatch } from '../../../store';
import { setAddedClient, clearCheckInnResult } from '../../../store/slices/clientDatabaseSlice';
import {
    addingBankProductFormSections,
    addingLoanBankProductFormSections,
    FieldsInAddingBankProductFormType,
    FieldNamesInAddBankProduct,
    numericFieldsInAddBankProduct,
    numericFieldsInAddBankProductType,
    productFieldNameEnum,
    bankProductPrefixEnum,
} from '../../../utils/data';
import {
    BankProductCreateInterface,
    lawFieldsType,
    workWithFieldsType,
    BankProductType,
    BankProductDocumentCreateInterface,
} from '../../../utils/types';
import {
    createBankProductDoc,
    createProductList,
    deleteBankProductDoc,
    deleteProductList,
    getProductList,
    getStopRegions,
    selectPartner,
    updateBankProduct,
} from '../../../store/slices/partnerSlice';
import {
    AddBankLoanProductValidationSchema,
    AddBankProductFloatFields,
    AddBankProductValidationSchema,
} from '../../../validation/partners';
import {
    doesArraysDiffer,
    formatNum,
    getProductFromPartner,
    getProductTitle,
    getLoanProductTitleLowerCase,
    normalizeStrWithNum,
} from '../../../utils/functions';
import { userSelector } from '../../../store/slices/userSlice';
import CommonModal from '../../Common/CommonModal/CommonModal';
import StyledScrollbars from '../../Common/StyledScrollbar/StyledScrollbar';
import ToggleSwitch from '../../Common/ToggleSwitch/ToggleSwitch';
import StarIcon from '../../../assets/images/partners/star-icon.png';
import DeleteIcon from '../../../assets/images/staff/delete-doc-icon.png';
import EnabledInfoIcon from '../../../assets/images/partners/additional-info-icon.png';
import DisabledInfoIcon from '../../../assets/images/partners/disabled-info-icon.png';
import DefaultLoader from '../../Common/BtnLoader/DefaultLoader';
import BtnLoader from '../../Common/BtnLoader/BtnLoader';
import ConfirmModal from '../../Common/ConfirmModal/ConfirmModal';
import BankProductDoc from '../BankProductDoc/BankProductDoc';
import StyledFormWrapper from './AddProduct.style';
import AddProductInput from './AddProductInput/AddProductInput';
import AddProductTitle from './AddProductTitle/AddProductTitle';

const initialProductList = ['РЖД', 'Транснефть'];

type AddProductsProps = {
    close: () => void;
    width: number;
    product?: BankProductCreateInterface;
    productType: BankProductType;
    // eslint-disable-next-line no-unused-vars
    setProduct: (product: BankProductCreateInterface) => void;
};

const AddProducts = ({ close, width, setProduct, product, productType }: AddProductsProps) => {
    const dispatch = useAppDispatch();
    const firstUpdate = useRef(true);
    const { stopRegions, productList, loading, isRequestFulfilled, currentPartner } =
        useSelector(selectPartner);
    const { user } = useSelector(userSelector);
    const [deletingProductList, setDeletingProductList] = useState<null | number>(null);
    const onlyView = !user?.isAdmin;

    const deleteList = () => {
        if (!deletingProductList) return;

        dispatch(deleteProductList({ productListId: deletingProductList }));
    };

    const isRegionInList = (regionId: number) => {
        let inList = false;
        product?.stopRegions?.forEach((region) => {
            if (region === String(regionId)) {
                inList = true;
            }
        });
        return inList;
    };
    const isProductInList = (listId: number) => {
        let inList = false;
        product?.lists?.forEach((list) => {
            if (list === String(listId)) {
                inList = true;
            }
        });
        return inList;
    };
    const initialStopRegionListState = stopRegions.map((region) => {
        return { ...region, isActive: isRegionInList(region.regionId) };
    });
    const [stopRegionsList, setStopRegionsList] = useState(initialStopRegionListState);
    const initialProductListState = productList.map((item) => {
        return { ...item, isActive: isProductInList(item.bankProductListId) };
    });
    const [productListNormalized, setProductListNormalized] = useState(initialProductListState);
    const [isProductListInputVisible, setProductListInputVisible] = useState(false);
    const [hasAdditionalInfo, setAdditionalInfo] = useState(Boolean(product?.additInfo));
    const [isBtnLoading, setBtnLoading] = useState(false);
    const [editableProductListItem, setEditableProductListItem] = useState('');

    const isCommerce = productType.startsWith(bankProductPrefixEnum.commercial);
    const isLoan = productType.startsWith(bankProductPrefixEnum.loan);

    const productTitle = isLoan
        ? getLoanProductTitleLowerCase(productType)
        : getProductTitle(productType);

    const productTypeTitle = useMemo(() => {
        if (!isCommerce && isLoan) {
            return 'Кредит';
        }
        if (!isCommerce && !isLoan) {
            return 'Банковская гарантия (ФЗ)';
        }
        if (isCommerce) {
            return 'Коммерческая гарантия';
        }
        return '';
    }, [isCommerce, isLoan]);

    const validationSchema = !isLoan
        ? AddBankProductValidationSchema
        : AddBankLoanProductValidationSchema;

    const formOptions = {
        defaultValues: {
            documents: product ? product.documents : [],
            hasFZ44: Boolean(product?.hasFZ44),
            hasFZ223: Boolean(product?.hasFZ223),
            hasPP615: Boolean(product?.hasPP615),
            worksWithIP: Boolean(product?.worksWithIP),
            worksWithAO: Boolean(product?.worksWithAO),
            limit: formatNum(String(product?.limit ?? '')),
            rateFrom: product?.rateFrom || '',
            term: formatNum(String(product?.term ?? '')),
            age: formatNum(String(product?.age ?? '')),
            experience: formatNum(String(product?.experience ?? '')),
            baseCommission: product?.baseCommission || '',
            clientCommission: product?.clientCommission || '',
            issuanceCommission: product?.issuanceCommission || '',
            registrationTerm: formatNum(String(product?.registrationTerm ?? '')),
            additInfo: product?.additInfo || '',
        },
        resolver: yupResolver(validationSchema),
    };
    const { register, handleSubmit, formState, watch, setValue } =
        useForm<BankProductCreateInterface>(formOptions);
    const { errors } = formState;

    const getFieldUpdate = (
        field: FieldNamesInAddBankProduct,
        newData: BankProductCreateInterface,
    ) => {
        if (product && field in product) {
            if (!(product[field] || newData[field])) {
                return undefined;
            }

            const oldValue =
                field === productFieldNameEnum.age || field === productFieldNameEnum.experience
                    ? String(product[field])
                    : product[field];
            let normalizedNewValue = newData[field];

            if (AddBankProductFloatFields.includes(field) && typeof newData[field] === 'string') {
                normalizedNewValue = String(normalizedNewValue).split(',').join('.');
            }

            if (
                numericFieldsInAddBankProduct.includes(field) &&
                typeof newData[field] === 'string'
            ) {
                normalizedNewValue = String(normalizedNewValue).split(' ').join('');
            }

            return normalizedNewValue !== oldValue ? normalizedNewValue : undefined;
        } else {
            return newData[field] || '';
        }
    };

    const getBooleanFieldForUpdate = (field?: unknown) => {
        return field === undefined ? undefined : Boolean(field);
    };

    const doesDocExist = (docId: number) => {
        let docExist = false;
        documents.forEach((doc) => {
            if (doc.bankProductDocumentId === docId) {
                docExist = true;
            }
        });
        return docExist;
    };

    const additionalInfoToggleHandler = () => {
        setAdditionalInfo((prev) => !prev);
    };

    const onSubmit: SubmitHandler<BankProductCreateInterface> = (data) => {
        setBtnLoading(true);
        let stopRegions: string[] | undefined = stopRegionsList
            .filter((region) => region.isActive)
            .map((region) => String(region.regionId));
        let lists: string[] | undefined = productListNormalized
            .filter((item) => item.isActive)
            .map((item) => String(item.bankProductListId));

        if (product && currentPartner) {
            const bankProductId = currentPartner.products.find(
                (product) => product.type === productType,
            )?.bankProductId;

            if (!bankProductId) return setBtnLoading(false);

            if (!doesArraysDiffer(stopRegions, product.stopRegions)) {
                stopRegions = undefined;
            }

            if (!doesArraysDiffer(lists, product.lists)) {
                lists = undefined;
            }

            let additInfo = getFieldUpdate(productFieldNameEnum.clientCommission, data) as
                | string
                | undefined;

            if (!hasAdditionalInfo) {
                additInfo = product.additInfo ? '' : undefined;
            } else {
                additInfo = getFieldUpdate(productFieldNameEnum.additInfo, data) as
                    | string
                    | undefined;
            }

            const currentProduct = getProductFromPartner(productType, currentPartner);

            if (currentProduct) {
                documents.forEach((doc) => {
                    if (doc?.bankProductDocumentId) return;

                    dispatch(
                        createBankProductDoc({
                            bankId: currentPartner.bankId,
                            productId: currentProduct.bankProductId,
                            ...doc,
                        }),
                    );
                });
                const docsForDeleting = product.documents.filter(
                    (doc) => doc.bankProductDocumentId && !doesDocExist(doc.bankProductDocumentId),
                );
                docsForDeleting.forEach((doc) => {
                    if (!doc?.bankProductDocumentId) return;

                    dispatch(
                        deleteBankProductDoc({
                            bankId: currentPartner.bankId,
                            productId: currentProduct.bankProductId,
                            documentId: doc.bankProductDocumentId,
                        }),
                    );
                });
            }

            const newProduct = {
                limit: getFieldUpdate(productFieldNameEnum.limit, data) as string | undefined,
                rateFrom: getFieldUpdate(productFieldNameEnum.rateFrom, data) as string | undefined,
                term: getFieldUpdate(productFieldNameEnum.term, data) as string | undefined,
                hasFZ44: getBooleanFieldForUpdate(
                    getFieldUpdate(productFieldNameEnum.hasFZ44, data),
                ),
                hasFZ223: getBooleanFieldForUpdate(
                    getFieldUpdate(productFieldNameEnum.hasFZ223, data),
                ),
                hasPP615: getBooleanFieldForUpdate(
                    getFieldUpdate(productFieldNameEnum.hasPP615, data),
                ),
                experience: getFieldUpdate(productFieldNameEnum.experience, data) as
                    | string
                    | undefined,
                age: getFieldUpdate(productFieldNameEnum.age, data) as string | undefined,
                worksWithIP: getBooleanFieldForUpdate(
                    getFieldUpdate(productFieldNameEnum.worksWithIP, data),
                ),
                worksWithAO: getBooleanFieldForUpdate(
                    getFieldUpdate(productFieldNameEnum.worksWithAO, data),
                ),
                baseCommission: getFieldUpdate(productFieldNameEnum.baseCommission, data) as
                    | string
                    | undefined,
                clientCommission: getFieldUpdate(productFieldNameEnum.clientCommission, data) as
                    | string
                    | undefined,
                issuanceCommission: getFieldUpdate(
                    productFieldNameEnum.issuanceCommission,
                    data,
                ) as string | undefined,
                registrationTerm: getFieldUpdate(productFieldNameEnum.registrationTerm, data) as
                    | string
                    | undefined,
                additInfo,
                stopRegions,
                lists,
            };
            dispatch(
                updateBankProduct({
                    bankId: currentPartner.bankId,
                    bankProductId,
                    ...newProduct,
                }),
            );
        } else {
            const newProduct = {
                limit: normalizeStrWithNum(data.limit) || '',
                rateFrom: normalizeStrWithNum(data.rateFrom) || '',
                term: normalizeStrWithNum(data.term) || '',
                hasFZ44: Boolean(data.hasFZ44),
                hasFZ223: Boolean(data.hasFZ223),
                hasPP615: Boolean(data.hasPP615),
                experience: normalizeStrWithNum(data.experience) || '',
                age: normalizeStrWithNum(data.age) || '',
                worksWithIP: Boolean(data.worksWithIP),
                worksWithAO: Boolean(data.worksWithAO),
                baseCommission: normalizeStrWithNum(data.baseCommission) || '',
                clientCommission: normalizeStrWithNum(data.clientCommission) || '',
                additInfo: data.additInfo && hasAdditionalInfo ? data.additInfo : '',
                documents,
                stopRegions,
                lists,
                registrationTerm: normalizeStrWithNum(data.registrationTerm) || '',
                issuanceCommission: normalizeStrWithNum(data.issuanceCommission) || '',
                empty: '',
            };
            setProduct({ ...newProduct, isActive: true });
        }
        setBtnLoading(false);
        close();
    };

    const switchers = {
        hasFZ44: watch(productFieldNameEnum.hasFZ44),
        hasFZ223: watch(productFieldNameEnum.hasFZ223),
        hasPP615: watch(productFieldNameEnum.hasPP615),
        worksWithIP: watch(productFieldNameEnum.worksWithIP),
        worksWithAO: watch(productFieldNameEnum.worksWithAO),
    };
    const documents = watch(productFieldNameEnum.documents);
    const numericFields = {
        limit: watch(productFieldNameEnum.limit),
        term: watch(productFieldNameEnum.term),
        age: watch(productFieldNameEnum.age),
        experience: watch(productFieldNameEnum.experience),
        registrationTerm: watch(productFieldNameEnum.registrationTerm),
    };

    const changNumericFieldHandler = (value: string, field: FieldNamesInAddBankProduct) => {
        const oldValue = numericFields[field as numericFieldsInAddBankProductType] || '';
        const isInteger = field !== productFieldNameEnum.limit;
        const formattedNum = formatNum(value, oldValue, isInteger);

        setValue(field, formattedNum);
    };

    const getInputField = (
        fields: FieldsInAddingBankProductFormType[],
        titleAtSameLine?: boolean,
    ) => {
        return fields.map(
            (field) =>
                field.fieldName !== productFieldNameEnum.empty && (
                    <div
                        key={field.fieldName}
                        className={clsx({
                            'add-product__same-line-title': Boolean(titleAtSameLine),
                        })}
                    >
                        <p>
                            {field.label}
                            {!titleAtSameLine && field.isRequired && (
                                <span className="add-product__sign-of-obligatory-field">*</span>
                            )}
                            {!titleAtSameLine && ':'}
                        </p>
                        <div
                            className={clsx({
                                'add-product__rate': field.fieldName === 'rateFrom',
                            })}
                        >
                            {field.fieldName === 'rateFrom' && <p>от</p>}
                            <AddProductInput
                                field={field}
                                errors={errors}
                                disabled={onlyView}
                                register={register}
                                changNumericFieldHandler={changNumericFieldHandler}
                            />
                        </div>
                    </div>
                ),
        );
    };

    const includeStopRegion = (isActive: boolean, index: number) => {
        const newValue = [...stopRegionsList];
        newValue[index].isActive = !isActive;
        setStopRegionsList(newValue);
    };

    const includeProductList = (isActive: boolean, index: number) => {
        const newValue = [...productListNormalized];
        newValue[index].isActive = !isActive;
        setProductListNormalized(newValue);
    };

    const handleDocs = (value: BankProductDocumentCreateInterface[]) => {
        setValue(productFieldNameEnum.documents, value);
    };

    const addListItemHandler = () => {
        if (!editableProductListItem) return;

        dispatch(createProductList({ name: editableProductListItem }));
    };

    const enterPressHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            addListItemHandler();
        }
    };

    const toggleHandler = (fieldType: lawFieldsType | workWithFieldsType) => {
        setValue(fieldType, !switchers[fieldType]);
    };

    const windowHeight = () => {
        let initialHeight = 530;
        initialHeight += hasAdditionalInfo ? 75 : 0;
        initialHeight += isCommerce || isLoan ? 0 : 65 + 34 + productList.length * 24;
        initialHeight += stopRegions.length * 24;
        initialHeight += documents?.length * 30;
        initialHeight += isProductListInputVisible ? 30 : 0;
        return initialHeight;
    };

    const resetNewProductList = () => {
        setProductListInputVisible(false);
        setEditableProductListItem('');
    };

    const bankProductFormSections = isLoan
        ? addingLoanBankProductFormSections
        : addingBankProductFormSections;
    const percentFields = getInputField(bankProductFormSections.percent);
    const registrationTermFields = getInputField(bankProductFormSections.registrationTerm);
    const experienceFields = getInputField(bankProductFormSections.bankExperience);
    const commissionFields = getInputField(bankProductFormSections.commission, true);
    const InfoIcon = hasAdditionalInfo ? EnabledInfoIcon : DisabledInfoIcon;

    useEffect(() => {
        dispatch(setAddedClient({}));
        dispatch(clearCheckInnResult());
        dispatch(getStopRegions({}));
        dispatch(getProductList({}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if (isRequestFulfilled.deleteProductList) {
            setDeletingProductList(null);
        }

        if (isRequestFulfilled.createProductList) {
            dispatch(getProductList({}));
            resetNewProductList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRequestFulfilled.createProductList, isRequestFulfilled.deleteProductList]);

    useEffect(() => {
        let hasEmptyFieldError = false;
        Object.keys(validationSchema.fields).forEach((key) => {
            const errorMessage = errors[key as FieldNamesInAddBankProduct]?.message;

            if (errorMessage?.startsWith('Заполните') || errorMessage?.startsWith('Выберите')) {
                if (!hasEmptyFieldError) {
                    hasEmptyFieldError = true;
                    toast.error('Заполните необходимые поля');
                }
            } else if (errorMessage) {
                toast.error(errorMessage);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);

    useEffect(() => {
        if (!(product && stopRegions)) return;

        const newStopRegions = stopRegions.map((region) => {
            return { ...region, isActive: isRegionInList(region.regionId) };
        });
        setStopRegionsList(newStopRegions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product, stopRegions]);

    useEffect(() => {
        if (!(product && stopRegions)) return;

        const newProductList = productList.map((item) => {
            return { ...item, isActive: isProductInList(item.bankProductListId) };
        });
        setProductListNormalized(newProductList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product, productList]);

    return (
        <CommonModal hasCloseBtn={true} close={close}>
            <StyledFormWrapper
                width={width}
                height={windowHeight()}
                onSubmit={handleSubmit(onSubmit)}
            >
                <AddProductTitle
                    productTypeTitle={productTypeTitle}
                    productTitle={productTitle}
                    isLoan={isLoan}
                />
                <StyledScrollbars
                    style={{ height: 'calc(100% - 61px)' }}
                    renderTrackHorizontal={(props) => (
                        <div {...props} style={{ display: 'none' }} />
                    )}
                    thumbSize={170}
                    thumbMinSize={30}
                >
                    <div className="add-product__scroll-container">
                        {percentFields}
                        {registrationTermFields}
                        {!isCommerce && !isLoan && (
                            <>
                                <p className="add-product__laws-title">Федеральные законы</p>
                                <div className="add-product__laws">
                                    {bankProductFormSections.laws.map((field, index) => (
                                        <div
                                            key={field.fieldName}
                                            className="add-product__law-label-container"
                                        >
                                            <p
                                                className={clsx('add-product__law-label', {
                                                    'add-product__first-law': !index,
                                                })}
                                            >
                                                {field.label}
                                            </p>
                                            <ToggleSwitch
                                                width={27}
                                                height={16}
                                                checked={
                                                    switchers[field.fieldName as lawFieldsType]
                                                }
                                                toggle={() =>
                                                    toggleHandler(field.fieldName as lawFieldsType)
                                                }
                                                disabled={onlyView}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                        {!isLoan && experienceFields}
                        <div className="add-product__works">
                            {bankProductFormSections.workWith.map(
                                (field, index) =>
                                    field.fieldName !== productFieldNameEnum.empty && (
                                        <div
                                            key={field.fieldName}
                                            className={clsx('add-product__work-with-container', {
                                                'add-product__first-work-switcher': !index,
                                            })}
                                        >
                                            <p className="add-product__work-with-label">
                                                {field.label}
                                            </p>
                                            <div className="add-product__work-with-switch">
                                                <p>Да</p>
                                                <ToggleSwitch
                                                    width={27}
                                                    height={16}
                                                    checked={
                                                        switchers[
                                                            field.fieldName as workWithFieldsType
                                                        ]
                                                    }
                                                    toggle={() =>
                                                        toggleHandler(
                                                            field.fieldName as workWithFieldsType,
                                                        )
                                                    }
                                                    disabled={onlyView}
                                                />
                                            </div>
                                        </div>
                                    ),
                            )}
                        </div>
                        <p className="add-product__bold-title">Комиссионное вознаграждение (%)</p>
                        <div className="add-product__commission">{commissionFields}</div>
                        <div className="add-product__stop-regions">
                            <p className="add-product__bold-title">Стоп регионы:</p>
                            {stopRegionsList.map((region, index) => (
                                <div key={region.regionId} className="add-product__region-item">
                                    <ToggleSwitch
                                        width={27}
                                        height={16}
                                        checked={region.isActive}
                                        toggle={() => includeStopRegion(region.isActive, index)}
                                        disabled={onlyView}
                                    />
                                    <p>{region.nameWithType}</p>
                                </div>
                            ))}
                        </div>
                        {!isCommerce && !isLoan && (
                            <div className="add-product__product-list">
                                <div className="add-product__btn-with-title">
                                    <p className="add-product__bold-title">
                                        Списки <img src={StarIcon} />
                                    </p>
                                    {!onlyView && (
                                        <button
                                            type="button"
                                            className="add-product__add-btn"
                                            onClick={() => setProductListInputVisible(true)}
                                        >
                                            Добавить
                                        </button>
                                    )}
                                </div>
                                {productListNormalized.map((item, index) => (
                                    <div
                                        key={item.bankProductListId}
                                        className="add-product__product-list-item"
                                    >
                                        <p>{item.name}</p>
                                        <ToggleSwitch
                                            width={27}
                                            height={16}
                                            checked={item.isActive}
                                            toggle={() => includeProductList(item.isActive, index)}
                                            disabled={onlyView}
                                        />
                                        {!(initialProductList.includes(item.name) || onlyView) && (
                                            <img
                                                src={DeleteIcon}
                                                onClick={() =>
                                                    setDeletingProductList(item.bankProductListId)
                                                }
                                            />
                                        )}
                                    </div>
                                ))}
                                {isProductListInputVisible && (
                                    <div className="add-product__temporary-input">
                                        <input
                                            value={editableProductListItem}
                                            onChange={(e) =>
                                                setEditableProductListItem(e.target.value)
                                            }
                                            onKeyDown={enterPressHandler}
                                        />
                                        <button
                                            className="add-product__delete-btn"
                                            type="button"
                                            onClick={resetNewProductList}
                                        >
                                            Удалить
                                        </button>
                                        <button
                                            type="button"
                                            onClick={addListItemHandler}
                                            disabled={loading.createProductList}
                                        >
                                            {loading.createProductList ? (
                                                <DefaultLoader />
                                            ) : (
                                                'Сохранить'
                                            )}
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                        <BankProductDoc
                            documents={documents}
                            setDocuments={handleDocs}
                            onlyView={onlyView}
                        />
                        <div className="add-product__additional-info">
                            <div className="add-product__btn-with-title">
                                <p className="add-product__bold-title">
                                    Дополнительная информация
                                    <img src={InfoIcon} />
                                </p>
                                <ToggleSwitch
                                    width={27}
                                    height={16}
                                    checked={hasAdditionalInfo}
                                    toggle={additionalInfoToggleHandler}
                                    disabled={onlyView}
                                />
                            </div>
                            {hasAdditionalInfo && (
                                <textarea
                                    className="add-product__text-area"
                                    {...register(productFieldNameEnum.additInfo)}
                                    disabled={onlyView}
                                />
                            )}
                        </div>
                    </div>
                </StyledScrollbars>
                <div className="add-product__btn">
                    {!onlyView && <BtnLoader isLoading={isBtnLoading} btnTitle="Сохранить" />}
                </div>
            </StyledFormWrapper>

            {deletingProductList ? (
                <ConfirmModal
                    close={() => setDeletingProductList(null)}
                    confirmAction={deleteList}
                    width={280}
                    height={120}
                    headText="Вы уверены, что хотите удалить элемент?"
                    loading={loading.deleteProductList}
                />
            ) : null}
        </CommonModal>
    );
};

export default AddProducts;
