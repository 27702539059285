import styled from 'styled-components/macro';
import Scrollbars from 'rc-scrollbars';

const StyledScrollbars = styled(Scrollbars)`
    .rc-scrollbars-track.rc-scrollbars-track-v {
        right: -4px !important;
        top: 6px !important;
    }
`;

export default StyledScrollbars;
