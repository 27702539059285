import React from 'react';

import { CredentialsWithError } from '../../../utils/types';
import StyledDocContainer from './Credentials.style';
import CredentialsItem from './CredentialsItem/CredentialsItem';

type CredentialsProps = {
    credentials: CredentialsWithError[];
    // eslint-disable-next-line no-unused-vars
    setCredentialsModalOpen: (value: boolean) => void;
    // eslint-disable-next-line no-unused-vars
    setEditingCredentials: (value: number) => void;
    disabled: boolean;
};

const headings = [
    'Продукт',
    'Оператор ЛК',
    'Ссылка на ЛК',
    'Логин',
    'Пароль',
    'ФИО',
    'e-mail',
    'Телефон',
];

const Credentials = ({
    credentials,
    setCredentialsModalOpen,
    setEditingCredentials,
    disabled,
}: CredentialsProps) => {
    return (
        <StyledDocContainer>
            <div className="credentials__header">
                <h4>Данные входа на площадку</h4>
                {!disabled && (
                    <button
                        className="credentials__button"
                        type="button"
                        onClick={() => setCredentialsModalOpen(true)}
                    >
                        Добавить
                    </button>
                )}
            </div>
            {credentials.length ? (
                <div className="credentials__table-head">
                    {headings.map((header) => (
                        <p key={header}>{header}</p>
                    ))}
                </div>
            ) : null}
            {credentials.map((cred, credIndex) => (
                <CredentialsItem
                    key={cred.bankCredentialId}
                    cred={cred}
                    credIndex={credIndex}
                    setEditingCredentials={setEditingCredentials}
                    disabled={disabled}
                />
            ))}
        </StyledDocContainer>
    );
};

export default Credentials;
