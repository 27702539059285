import React, { useState } from 'react';

import { BankProductDocument, PartnerInterface } from '../../../../utils/types';
import {
    downloadAsFile,
    formatNum,
    getBankProductDescription,
    getBankProductList,
    getProductTitle,
} from '../../../../utils/functions';
import EnabledInfoIcon from '../../../../assets/images/partners/additional-info-icon.png';
import DisabledInfoIcon from '../../../../assets/images/partners/disabled-info-icon.png';
import EnabledStarIcon from '../../../../assets/images/partners/star-icon.png';
import DisabledStarIcon from '../../../../assets/images/partners/disabled-star-icon.png';
import EnabledPdfIcon from '../../../../assets/images/partners/pdf-icon.png';
import DisabledPdfIcon from '../../../../assets/images/partners/disabled-pdf-icon.png';
import TooltipAlt from '../../../../components/Common/TooltipAlt/TooltipAlt';

type numericFieldsType = 'limit' | 'term' | 'rateFrom';

const numericFields: numericFieldsType[] = ['limit', 'term', 'rateFrom'];

type PartnersTableProps = {
    numberInList: number;
    partner: PartnerInterface;
    // eslint-disable-next-line no-unused-vars
    setEditablePartnerId: (value: number) => void;
};

const TableAfterFilter = ({ setEditablePartnerId, numberInList, partner }: PartnersTableProps) => {
    const partnerLogo = partner.logo ? <img src={partner.logo} /> : null;
    const PdfIcon = partner.products[0]?.documents.length ? EnabledPdfIcon : DisabledPdfIcon;
    const productInfo = getBankProductDescription(partner);
    const InfoIcon = productInfo ? EnabledInfoIcon : DisabledInfoIcon;
    const productsList = getBankProductList(partner);
    const StarIcon = productsList ? EnabledStarIcon : DisabledStarIcon;
    const [unloadedFile, setUnloadedFile] = useState<null | BankProductDocument>(null);
    const tooltipInfoId = `bank-product-search-info-tooltip-${partner.bankId}-${numberInList}`;
    const tooltipStarId = `bank-product-search-star-tooltip-${numberInList}-${partner.bankId}`;

    const normalizeNumericField = (value: string | null | number, type: numericFieldsType) => {
        if (Number.isNaN(Number(value))) return '---';
        if (typeof value === 'number') return value;
        let normalizedField = value || '';

        if (type !== 'term') {
            normalizedField = Number(value).toFixed(2);
        }

        return `${formatNum(normalizedField)}${type === 'rateFrom' ? '% ' : ''}`;
    };

    const successHandler = () => {
        setUnloadedFile(null);
    };

    const errorHandler = (doc: BankProductDocument) => {
        if (unloadedFile) return;
        setUnloadedFile(doc);
    };
    const downloadDocument = (doc: BankProductDocument | null) => {
        if (!doc) {
            return;
        }
        downloadAsFile(doc.filePath, doc.name, () => errorHandler(doc), successHandler);
    };

    return (
        <>
            <p className="partners-table__field-with-margin">{numberInList}</p>
            <div className="partners-table__logo">{partnerLogo}</div>
            <p className="partners-table__field-with-margin">{partner.name.toUpperCase()}</p>
            <p className="partners-table__field-with-margin">
                {partner.credentials[0]?.lkOperator || '---'}
            </p>
            <p>{getProductTitle(partner.products[0].type)}</p>
            {numericFields.map((field) => (
                <div key={field} className="partners-table__right-position">
                    <p>{normalizeNumericField(partner.products[0][field], field)}</p>
                </div>
            ))}
            <div>
                <img src={StarIcon} id={tooltipStarId} />
                {Boolean(getBankProductList(partner)?.length) && (
                    <TooltipAlt
                        anchor={tooltipStarId}
                        content={getBankProductList(partner) || ''}
                    />
                )}
            </div>
            <div className="partners-table__actions-with-product">
                <div>
                    <img
                        className="partners-table__add-info-icon"
                        src={InfoIcon}
                        id={tooltipInfoId}
                    />
                    {productInfo && (
                        <TooltipAlt anchor={tooltipInfoId} content={productInfo || ''} />
                    )}
                </div>
                <img
                    src={PdfIcon}
                    className={
                        partner.products[0].documents[0]
                            ? `partners-table__instruction-icon`
                            : `partners-table__add-info-icon`
                    }
                    onClick={() => downloadDocument(partner.products[0].documents[0])}
                />
                <button
                    className="partners-table__partner-card-btn"
                    type="button"
                    onClick={() => setEditablePartnerId(partner.bankId)}
                >
                    Карточка банка
                </button>
            </div>
        </>
    );
};

export default TableAfterFilter;
