import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import { useAppDispatch } from '../../../store';
import { deleteTaskReminder } from '../../../store/slices/clientTaskSlice';
import { deleteReminder } from '../../../store/slices/clientCardSlice';
import { getDate } from '../../../utils/functions';
import { userSelector } from '../../../store/slices/userSlice';
import { ReminderInterface } from '../../../utils/types';
import FontTrashIcon from '../../../assets/images/clients/font-trash-icon.png';
import ConfirmModal from '../../../components/Common/ConfirmModal/ConfirmModal';
import StyledReminderItem from './ReminderItem.style';

type ReminderItemProps = {
    reminderId: number;
    companyId: number;
    reminder: ReminderInterface;
    taskId?: number;
};

const ReminderItem = ({ companyId, reminder, taskId, reminderId }: ReminderItemProps) => {
    const dispatch = useAppDispatch();
    const { user } = useSelector(userSelector);
    const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
    const hasRightForDelete = Boolean(user?.isAdmin || reminder.user?.userId === user?.userId);

    const confirmAction = () => {
        if (!hasRightForDelete) return toast.error('Недостаточно прав для удаления');
        if (!companyId) return;
        if (taskId) {
            dispatch(
                deleteTaskReminder({
                    companyId,
                    taskId,
                    reminderId,
                }),
            );
        }
        dispatch(
            deleteReminder({
                companyId,
                reminderId,
            }),
        );
        setConfirmModalOpen(false);
    };

    return (
        <StyledReminderItem>
            <div className="reminder-item__info">
                <span className="reminder-item__date">
                    {getDate(reminder.time, 'date with time')}
                </span>
                <span className="reminder-item__text">{reminder.text}</span>
            </div>
            {hasRightForDelete && (
                <img
                    className="reminder-item__action"
                    src={FontTrashIcon}
                    onClick={() => setConfirmModalOpen(true)}
                />
            )}
            {isConfirmModalOpen ? (
                <ConfirmModal
                    close={() => setConfirmModalOpen(false)}
                    confirmAction={confirmAction}
                    width={280}
                    height={85}
                    headText="Удалить напоминание?"
                />
            ) : null}
        </StyledReminderItem>
    );
};

export default ReminderItem;
