import { toast } from 'react-hot-toast';

import { AuthResponse } from '../../utils/types';
import { AuthMeResponse, LoginPropsType, UserApiPath } from './authApi.types';
import instance from '../axiosInstance';

interface AuthError {
    response: {
        data: {
            message: string;
            status: number;
        };
    };
}

export const authAPI = {
    login: async ({
        username,
        password,
        deviceId,
    }: LoginPropsType): Promise<{ data: AuthResponse } | undefined> => {
        try {
            const response = await instance.post(UserApiPath.LOGIN, {
                login: username,
                password,
                deviceId,
            });
            return response;
        } catch (e) {
            const error: AuthError = e as AuthError;
            if (error?.response?.data?.message === 'Wrong password') {
                toast.error('Неправильный пароль');
            } else if (error?.response?.data?.message === 'User not found') {
                toast.error('Пользователь не найден');
            }
        }
    },
    logOut: async (): Promise<number | undefined> => {
        try {
            const response = await instance.delete(UserApiPath.LOGOUT);
            return response.status;
        } catch (e) {
            console.log(e);
        }
    },
    authMe: async (): Promise<AuthMeResponse> => {
        try {
            const response = await instance.get(UserApiPath.AUTH_ME);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },
};
