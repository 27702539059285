import React from 'react';
import clsx from 'clsx';
import { FieldsInAddingCredentialsFormType } from '../../../../utils/data';
import { FieldErrorsImpl, UseFormRegister } from 'react-hook-form';
import { AddCredentialsInputs } from '../AddCredentials';

type AddCredentialsInputFieldProps = {
    field: FieldsInAddingCredentialsFormType;
    errors: Partial<FieldErrorsImpl<AddCredentialsInputs>>;
    register: UseFormRegister<AddCredentialsInputs>;
};

const AddCredentialsInputField = ({ field, errors, register }: AddCredentialsInputFieldProps) => {
    return (
        <input
            className={clsx({
                'add-credentials__error-input': errors[field.fieldName]?.message,
            })}
            id={field.fieldName}
            required={false}
            {...register(field.fieldName)}
        />
    );
};

export default AddCredentialsInputField;
