import React from 'react';
import { FieldErrorsImpl, UseFormRegister } from 'react-hook-form';

import {
    FieldNamesInAddBankProduct,
    FieldsInAddingBankProductFormType,
    numericFieldsInAddBankProduct,
} from '../../../../utils/data';
import { BankProductCreateInterface } from '../../../../utils/types';
import clsx from 'clsx';

type AddProductInputProps = {
    field: FieldsInAddingBankProductFormType;
    errors: Partial<FieldErrorsImpl<BankProductCreateInterface>>;
    disabled: boolean;
    register: UseFormRegister<BankProductCreateInterface>;
    // eslint-disable-next-line no-unused-vars
    changNumericFieldHandler: (value: string, field: FieldNamesInAddBankProduct) => void;
};

const AddProductInput = ({
    field,
    errors,
    disabled,
    register,
    changNumericFieldHandler,
}: AddProductInputProps) => {
    const onChange = (value: string, field: FieldNamesInAddBankProduct) => {
        if (!numericFieldsInAddBankProduct.includes(field)) return;
        changNumericFieldHandler(value, field);
    };
    return (
        <input
            className={clsx({
                'add-product__error-field': errors[field.fieldName]?.message,
            })}
            id={field.fieldName}
            {...register(field.fieldName)}
            disabled={disabled}
            onChange={(e) => onChange(e.target.value, field.fieldName)}
        />
    );
};

export default AddProductInput;
