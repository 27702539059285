import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import queryString from 'query-string';

import { companiesPerPage, clientFilterFields, clientFilterFieldType } from '../../../utils/data';
import {
    getCompanies,
    selectClientDatabase,
    setAreCompaniesUpdated,
    setClientsOrder,
    setCurrentPage,
    setSortBy,
} from '../../../store/slices/clientDatabaseSlice';
import { useAppDispatch } from '../../../store';
import { selectLocation } from '../../../store/slices/locationSlice';
import { importance as importanceList } from '../../../utils/consts';
import { getManagersList } from '../../../utils/functions';
import { ImportanceLabelType, ImportanceNameType, sortingFields } from '../../../utils/types';
import {
    FilterDateType,
    FilterParams,
    GetOptionsArgsTypes,
    listsNames,
    SearchParams,
    SearchParamsFieldNames,
    TableOrderType,
} from './Filter.types';
import { getAllUsers, selectStaff } from '../../../store/slices/staffSlice';
import BagAddIcon from '../../../assets/images/clients/bag-add-icon.png';
import SquareWithArrowUpIcon from '../../../assets/images/clients/square-with-arrow-up-icon.png';
import ThreeDots from '../../../assets/images/three-dots.png';
import SearchField from '../../../components/Common/SearchField/SearchField';
import CustomSelect from '../../../components/Common/CustomSelect/CustomSelect';
import ToggleSwitch from '../../../components/Common/ToggleSwitch/ToggleSwitch';
import AddClient from '../../../components/AddClient/AddClient';
import StyledFilter from './Filter.style';
import { userSelector } from '../../../store/slices/userSlice';
import InterestingsPicker from '../../../components/InterestingsPicker/InterestingsPicker';

const Filter = () => {
    const { user } = useSelector(userSelector);

    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { currentPage, sortBy, clientsOrder } = useSelector(selectClientDatabase);

    const defaultFilterParams: FilterParams = {
        contactDateFrom: '',
        contactDateTo: '',
        lastCommentDateFrom: '',
        lastCommentDateTo: '',
        region: '',
        importance: 'all',
        manager: 0,
        isMarked: false,
        isHighlighted: false,
        isFtsMarked: false,
        hasReminder: false,
        isWithDealOnly: false,
        interests: [],
    };

    const [filter, setFilter] = useState<FilterParams>(defaultFilterParams);
    const { allUsers } = useSelector(selectStaff);
    const { areCompaniesUpdated, clientsCount } = useSelector(selectClientDatabase);
    const firstPageUpdate = useRef(true);
    const firstFilterUpdate = useRef(true);

    const { regions } = useSelector(selectLocation);

    const [isAddClientOpen, setAddClientOpen] = useState(false);
    const [isFilterOpen, setFilterOpen] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [isFilterRestoredAfterReload, setFilterRestoredAfterReload] = useState(false);

    const getImportanceForQuery = (importance: ImportanceNameType) => {
        switch (importance) {
            case 'all':
                return {};
            case 'no':
                return {
                    hasImportance: false,
                };
            default:
                return {
                    hasImportance: true,
                    importance,
                };
        }
    };

    const handleFilter = () => {
        dispatch(
            getCompanies({
                contactDateFrom: filter.contactDateFrom || undefined,
                contactDateTo: filter.contactDateTo || undefined,
                lastCommentDateFrom: filter.lastCommentDateFrom || undefined,
                lastCommentDateTo: filter.lastCommentDateTo || undefined,
                region: !filter.region ? undefined : filter.region,
                managerId: Number(filter.manager) || undefined,
                isMarked: filter.isMarked || undefined,
                isFtsMarked: filter.isFtsMarked || undefined,
                interests: filter.interests || [],
                isHighlighted: filter.isHighlighted || undefined,
                hasReminder: filter.hasReminder || undefined,
                page: currentPage,
                perPage: companiesPerPage,
                sortBy: sortBy,
                order: clientsOrder,
                input: searchTerm,
                isWithDealOnly: filter.isWithDealOnly,
                ...getImportanceForQuery(filter.importance),
            }),
        );
    };

    const rangeHandler = (
        isFrom: boolean,
        name: string,
        e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const date = e.target.value ? new Date(e.target.value).toISOString() : '';
        if (isFrom && name === 'Дата контакта') {
            return setFilter({
                ...filter,
                contactDateFrom: date,
            });
        } else if (isFrom) {
            return setFilter({
                ...filter,
                lastCommentDateFrom: date,
            });
        } else if (name === 'Дата контакта') {
            return setFilter({
                ...filter,
                contactDateTo: date,
            });
        } else {
            return setFilter({
                ...filter,
                lastCommentDateTo: date,
            });
        }
    };

    const listsForSelect = {
        Регион: { values: regions, disabled: false },
        'Выбрать менеджера': {
            values: [{ fullName: 'Все', userId: 0 }, ...getManagersList(allUsers)],
            disabled: false,
        },
        Важность: { values: [...importanceList], disabled: false },
    };

    if (user && !user.isAdmin) {
        listsForSelect['Выбрать менеджера'] = {
            values: [{ fullName: user.fullName || '-', userId: user.userId }],
            disabled: true,
        };
    }

    const getOptions = (item: GetOptionsArgsTypes) => {
        let key = '';
        if (typeof item === 'string') {
            key += item;
        } else if ('name' in item) {
            key += item.name;
        } else if ('userId' in item) {
            key += item.userId;
        }

        let value: ImportanceNameType | string | number = '';
        if (typeof item === 'string') {
            value = item;
        } else if ('name' in item) {
            value = item.name;
        } else if ('userId' in item) {
            value = item.userId;
        }

        let label: ImportanceLabelType | string = '';
        if (typeof item === 'string') {
            label = item;
        } else if ('label' in item) {
            label = item.label;
        } else if ('fullName' in item && item.fullName) {
            label = item.fullName || '';
        }

        return (
            <option key={key} value={value}>
                {label}
            </option>
        );
    };

    const toggleDealOnly = () => {
        setFilter({
            ...filter,
            isWithDealOnly: !filter.isWithDealOnly,
        });
    };

    const onChangeInterests = (value: string) => {
        if (!filter.interests.includes(value)) {
            setFilter({
                ...filter,
                interests: [...filter.interests, value],
            });
        } else {
            setFilter({
                ...filter,
                interests: filter.interests.filter((item) => item !== value),
            });
        }
    };

    const getRangeValue = (fieldName: FilterDateType, direction: 'From' | 'To') => {
        const date = filter[`${fieldName}${direction}`];
        if (!date) return '';
        const dividerIndex = date.indexOf('T');
        return date.slice(0, dividerIndex);
    };

    const serializeSearchParams = (obj: SearchParams) => {
        const str: string[] = [];
        Object.getOwnPropertyNames(obj).forEach((key) => {
            if (key in obj && obj[key as SearchParamsFieldNames]) {
                str.push(
                    `${encodeURIComponent(key)}=${encodeURIComponent(
                        obj[key as SearchParamsFieldNames],
                    )}`,
                );
            }
        });
        return str.join('&');
    };

    const saveFilter = () => {
        const query = serializeSearchParams({
            ...filter,
            order: clientsOrder,
            sortBy,
            page: currentPage,
            input: searchTerm,
        });
        navigate({
            ...location,
            search: query,
        });
    };

    const getFilterField = (field: clientFilterFieldType) => {
        switch (field.type) {
            case 'range':
                return (
                    <div className="filter__range">
                        <p>с </p>
                        <input
                            type="date"
                            className={clsx({
                                'filter__not-selected':
                                    !filter[`${field.name as FilterDateType}From`],
                            })}
                            onChange={(e) => rangeHandler(true, field.label, e)}
                            value={getRangeValue(field.name as FilterDateType, 'From')}
                        />

                        <p>по </p>
                        <input
                            type="date"
                            className={clsx({
                                'filter__not-selected':
                                    !filter[`${field.name as FilterDateType}To`],
                            })}
                            onChange={(e) => rangeHandler(false, field.label, e)}
                            value={getRangeValue(field.name as FilterDateType, 'To')}
                        />
                    </div>
                );
            case 'list':
                return (
                    <CustomSelect
                        width={262}
                        height={20}
                        backgroundImage={ThreeDots}
                        backgroundColor="#f9f9f9"
                    >
                        {field.name === 'region' ? (
                            <>
                                <input
                                    className="custom-select__select custom-select__field-with-padding"
                                    list={'regionList'}
                                    value={filter[field.name]}
                                    placeholder="Выбрать регион"
                                    onChange={(e) =>
                                        setFilter({
                                            ...filter,
                                            [field.name]: e.target.value,
                                        })
                                    }
                                />
                                <datalist id={'regionList'}>
                                    {regions.map((item) => (
                                        <option key={item} value={item}>
                                            {item}
                                        </option>
                                    ))}
                                </datalist>
                            </>
                        ) : (
                            <select
                                className={clsx(
                                    'custom-select__select custom-select__field-with-padding',
                                    {
                                        'filter__not-selected':
                                            !filter[field.name as 'importance' | 'manager'] ||
                                            filter[field.name as 'importance' | 'manager'] ===
                                                'all',
                                    },
                                )}
                                value={filter[field.name as 'importance' | 'manager']}
                                onChange={(e) =>
                                    setFilter({
                                        ...filter,
                                        [field.name]: e.target.value,
                                    })
                                }
                                disabled={listsForSelect[field.label as listsNames].disabled}
                            >
                                {listsForSelect[field.label as listsNames].values.map((item) => {
                                    return getOptions(item);
                                })}
                            </select>
                        )}
                    </CustomSelect>
                );
            case 'marks':
                return (
                    <div className="filter__additional-marks">
                        {field.marks?.map((mark) => (
                            <img
                                key={mark.name}
                                src={filter[mark.searchParams] ? mark.selectedIcon : mark.icon}
                                onClick={() =>
                                    setFilter({
                                        ...filter,
                                        [mark.searchParams]: !filter[mark.searchParams],
                                    })
                                }
                            />
                        ))}
                    </div>
                );
            case 'toggle':
                return (
                    <div className="filter__toggle-switch">
                        <ToggleSwitch
                            width={27}
                            height={16}
                            checked={filter.isWithDealOnly}
                            toggle={toggleDealOnly}
                        />
                    </div>
                );
            case 'interests':
                return (
                    <div className="filter__interestings-container">
                        <InterestingsPicker value={filter.interests} onChange={onChangeInterests} />
                    </div>
                );
            default:
                break;
        }
    };

    useEffect(() => {
        dispatch(getAllUsers({ onlyActiveUsers: true }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (areCompaniesUpdated) {
            handleFilter();
            dispatch(setAreCompaniesUpdated(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [areCompaniesUpdated]);

    useEffect(() => {
        if (firstPageUpdate.current) {
            firstPageUpdate.current = false;
            return;
        }
        saveFilter();
        handleFilter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, sortBy, searchTerm, clientsOrder, currentPage]);

    useEffect(() => {
        if (firstFilterUpdate.current) {
            firstFilterUpdate.current = false;
            return;
        }
        if (!isFilterRestoredAfterReload && queryString.parse(location.search)) {
            setFilterRestoredAfterReload(true);
        } else if (currentPage !== 1) {
            dispatch(setCurrentPage(1));
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, searchTerm]);

    useEffect(() => {
        const queryStringParams = queryString.parse(location.search);
        if (!queryStringParams) return;
        const newFilter = {
            contactDateFrom: String(queryStringParams?.contactDateFrom || ''),
            contactDateTo: String(queryStringParams?.contactDateTo || ''),
            lastCommentDateFrom: String(queryStringParams?.lastCommentDateFrom || ''),
            lastCommentDateTo: String(queryStringParams?.lastCommentDateTo || ''),
            region: String(queryStringParams?.region || ''),
            importance: String(queryStringParams?.importance || 'all') as ImportanceNameType,
            manager: Number(queryStringParams?.manager || 0),
            isMarked: Boolean(queryStringParams?.isMarked),
            isFtsMarked: Boolean(queryStringParams?.isFtsMarked),
            isHighlighted: Boolean(queryStringParams?.isHighlighted),
            hasReminder: Boolean(queryStringParams?.hasReminder),
            isWithDealOnly: Boolean(queryStringParams?.isWithDealOnly),
            interests:
                typeof queryStringParams.interests === 'string'
                    ? queryStringParams.interests.split(',')
                    : [],
        };
        if (queryStringParams?.page && Number(queryStringParams?.page)) {
            dispatch(setCurrentPage(Number(queryStringParams?.page || 1)));
        }
        if (queryStringParams?.sortBy) {
            dispatch(
                setSortBy(String(queryStringParams?.sortBy || 'contactDate') as sortingFields),
            );
        }
        if (queryStringParams?.order) {
            dispatch(
                setClientsOrder(String((queryStringParams?.order || 'ASC') as TableOrderType)),
            );
        }
        if (queryStringParams?.input) {
            setSearchTerm(String(queryStringParams?.input || ''));
        }
        setFilter(newFilter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StyledFilter>
            <div className="filter__header">
                <div className="filter__companies-count">
                    <p>
                        База клиентов: <span> всего компаний </span> ({clientsCount})
                    </p>
                </div>
                <div className="filter__search">
                    <SearchField
                        background="#D9D9D9"
                        placeholder="Быстрый поиск по базе ИНН/Название"
                        searchTerm={searchTerm}
                        search={setSearchTerm}
                    />
                    <div className="filter__add-client-btn" onClick={() => setAddClientOpen(true)}>
                        <img src={BagAddIcon} />
                        <p>Добавить клиента</p>
                    </div>
                </div>
                <div
                    className={clsx('filter__minimize-filter-btn', {
                        'filter__minimized-filter': !isFilterOpen,
                    })}
                    onClick={() => setFilterOpen(!isFilterOpen)}
                >
                    <p>{isFilterOpen ? 'Свернуть' : 'Развернуть'} фильтр</p>
                    <img src={SquareWithArrowUpIcon} />
                </div>
            </div>
            {isFilterOpen && (
                <div className="filter__body">
                    {clientFilterFields.map((field) => (
                        <div key={field.label} className="filter__field">
                            <p>{field.label}</p>
                            {getFilterField(field)}
                        </div>
                    ))}
                </div>
            )}
            {isFilterOpen && (
                <p onClick={() => setFilter(defaultFilterParams)} className="filter__apply-btn">
                    Сбросить фильтр
                </p>
            )}
            {isAddClientOpen && <AddClient close={() => setAddClientOpen(false)} width={400} />}
        </StyledFilter>
    );
};

export default Filter;
