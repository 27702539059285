import styled from 'styled-components/macro';

const StyledClientsTable = styled.div`
    font-weight: 400;
    font-size: 12px;

    .clients-table__header,
    .clients-table__client {
        display: grid;
        grid-template-columns: 1fr minmax(140px, 2fr) repeat(3, 1fr) max(80px) max(60px) max(90px) 1fr 2fr 1fr 1fr;
        grid-column-gap: 10px;
    }

    .clients-table__header {
        padding: 5px 7px;
        background: #e7e7e7;
        height: 40px;

        img {
            width: 10px;
            height: 10px;
            margin-left: 5px;
        }
    }

    .clients-table__column-name {
        font-weight: 500;
        cursor: pointer;
        color: #000;
        display: flex;
        align-items: center;

        p {
            width: fit-content;
        }
    }

    .clients-table__client {
        padding: 13px 7px;

        img {
            width: 20px;
            height: 20px;
            margin-right: 15px;
        }
    }

    .clients-table__even-field {
        background: #f8f8f8;
    }

    .clients-table__company-name {
        color: #0038ff;
        text-decoration: underline;
        cursor: pointer;
        word-break: break-all;
    }

    .clients-table__next-contact {
        cursor: pointer;
    }

    .clients-table__importance {
        padding: 2px 5px;
        height: 18px;
        margin-top: -2px;
    }

    .clients-table__low-importance {
        background: rgba(209, 254, 174, 0.5);
    }

    .clients-table__medium-importance {
        background: rgba(253, 213, 91, 0.2);
    }

    .clients-table__high-importance {
        background: rgba(255, 0, 0, 0.2);
    }

    .clients-table__bank-guarantee {
        display: flex;
        align-items: start;

        p {
            width: fit-content;
        }

        img {
            margin: -3px 0 0 5px;
        }
    }

    .clients-table__highlighted-field {
        background: rgba(145, 66, 245, 0.12);
    }

    .clients-table__client--credit {
        background: rgba(13, 190, 228, 0.39);
    }

    .clients-table__marks {
        display: flex;
    }

    .clients-table__green-row {
        color: #00892f;
        padding-right: 15px;
    }

    .clients-table__income-row {
        padding-right: 15px;
    }

    .clients-table__loader {
        text-align: center;
        margin: 50px;
    }
`;

export default StyledClientsTable;
