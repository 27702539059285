import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { FieldsInAddingPartnerFormType } from '../../../utils/data';
import { PartnerFileTypes } from '../../../utils/types';
import { useAppDispatch } from '../../../store';
import {
    createPartnerDoc,
    deletePartnerDoc,
    getOnePartner,
    selectPartner,
    updatePartner,
} from '../../../store/slices/partnerSlice';
import { downloadAsFile } from '../../../utils/functions';
import DeleteDocIcon from '../../../assets/images/staff/delete-doc-icon.png';
import AddFile from '../../AddFile/AddFile';
import StyledWrapper from './AddPartnerFile.style';

export type fileType = {
    path: string;
    name: string;
};

type AddPartnerFileProps = {
    document: FieldsInAddingPartnerFormType;
    bankId: number | null;
    file: fileType;
    // eslint-disable-next-line no-unused-vars
    deleteFile: (type: PartnerFileTypes) => void;
    // eslint-disable-next-line no-unused-vars
    addFile: (name: string, file: string, type: PartnerFileTypes) => void;
    disabled: boolean;
};

const AddPartnerFile = ({
    document,
    file,
    addFile,
    bankId,
    disabled,
    deleteFile,
}: AddPartnerFileProps) => {
    const dispatch = useAppDispatch();
    const [addingFile, setAddingFile] = useState<PartnerFileTypes | null>(null);
    const [unloadedFile, setUnloadedFile] = useState<null | fileType>(null);
    const { currentPartner, isRequestFulfilled, loading } = useSelector(selectPartner);
    const fileTitle = `Добавить ${addingFile === 'logo' ? 'логотип' : 'инструкцию'}`;
    const btnTitle = file.path ? 'Заменить' : 'Добавить';
    const isInstruction = document.fieldName === 'instructions';

    const deleteDocHandler = () => {
        if (currentPartner) {
            if (isInstruction) {
                const documentId = currentPartner.documents.find(
                    (doc) => doc.section === document.fieldName,
                )?.bankDocumentId;

                if (!documentId) return;

                dispatch(
                    deletePartnerDoc({
                        bankId: currentPartner.bankId,
                        documentId,
                    }),
                );
                return;
            }
        }
        deleteFile(document.fieldName as PartnerFileTypes);
    };

    const addDocHandler = (name: string, file: string) => {
        if (currentPartner) {
            if (document.fieldName === 'instructions') {
                dispatch(
                    createPartnerDoc({
                        bankId: currentPartner.bankId,
                        file,
                        name,
                        section: document.fieldName,
                    }),
                );
                return;
            }
            dispatch(
                updatePartner({
                    bankId: currentPartner.bankId,
                    logo: file,
                }),
            );
            return;
        }
        addFile(name, file, document.fieldName as PartnerFileTypes);
    };

    const addFileBtnClickHandler = () => {
        if (disabled) return;

        setAddingFile(document.fieldName as PartnerFileTypes);
    };

    const successHandler = () => {
        setUnloadedFile(null);
    };

    const errorHandler = () => {
        if (!bankId || unloadedFile) return;

        setUnloadedFile(file);
        dispatch(getOnePartner({ bankId }));
    };

    const downloadDocument = () => {
        downloadAsFile(file.path, file.name, errorHandler, successHandler);
    };

    useEffect(() => {
        if (!unloadedFile) return;
        downloadDocument();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file.path]);

    return (
        <>
            <StyledWrapper>
                <div className="add-partner-file__field">
                    <p>{document.label}</p>
                    {!disabled && (
                        <button type="button" onClick={addFileBtnClickHandler}>
                            {btnTitle}
                        </button>
                    )}
                </div>
                {file.path ? (
                    <div className="add-partner-file__field">
                        {document.fieldName === 'logo' ? (
                            <img className="add-partner-file__logo" src={file.path} />
                        ) : (
                            <button type="button" onClick={downloadDocument}>
                                {file.name}
                            </button>
                        )}
                        {!disabled && isInstruction && (
                            <img
                                src={DeleteDocIcon}
                                className="add-partner-file__delete-icon"
                                onClick={deleteDocHandler}
                            />
                        )}
                    </div>
                ) : null}
            </StyledWrapper>
            {addingFile && (
                <AddFile
                    close={() => setAddingFile(null)}
                    width={400}
                    setFile={addDocHandler}
                    title={fileTitle}
                    loading={loading.uploadPartnerDocs}
                    isFulfilled={isRequestFulfilled.uploadFile}
                />
            )}
        </>
    );
};

export default AddPartnerFile;
