import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectClientAct } from '../../../store/slices/actSlice';
import { selectClientCard } from '../../../store/slices/clientCardSlice';
import { formatNum } from '../../../utils/functions';
import ActsTable from './ActsTable/ActsTable';
import AddFileIcon from '../../../assets/images/acts/add-file-icon.png';
import AddAct from '../../../components/AddAct/AddAct';
import StyledClientActsWrapper from './ClientActs.style';

const ClientActs = () => {
    const firstUpdate = useRef(true);
    const { isRequestFulfilled, actsCount, actsAmount } = useSelector(selectClientAct);
    const { company } = useSelector(selectClientCard);

    const [isAddActModalOpen, setAddActModalOpen] = useState(false);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if (isRequestFulfilled.createAct) {
            setAddActModalOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRequestFulfilled.createAct]);

    return (
        <StyledClientActsWrapper>
            <div className="client-acts__header">
                <p>Акты</p>
                <div className="client-acts__make-act">
                    <button type="button" onClick={() => setAddActModalOpen(true)}>
                        Сформировать акт
                    </button>
                    <img src={AddFileIcon} />
                </div>
                <div className="client-acts__stats">
                    <p>
                        кол-во: <span>{formatNum(String(actsCount))}</span>
                    </p>
                    <p>
                        сумма: <span>{formatNum(actsAmount.toFixed(2))} руб.</span>
                    </p>
                </div>
            </div>
            <ActsTable />
            {isAddActModalOpen && company ? (
                <AddAct
                    width={468}
                    companyId={company.companyId}
                    close={() => setAddActModalOpen(false)}
                />
            ) : null}
        </StyledClientActsWrapper>
    );
};

export default ClientActs;
