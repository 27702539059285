import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getError, isResponseOK } from '../../../utils/functions';
import { RootState } from '../..';
import { InitialStateType } from './types';
import { callsApi } from '../../../api/callsApi/callsApi';
import { CallsRecordInterface } from '../../../utils/types';
import { GetAllCallsPropsType, UpdateCallPropsType } from '../../../api/callsApi/callsApi.types';
import { StatusResponseType } from '../../../api/clientDatabaseApi/clientDatabaseApi.types';

export const getAllCalls = createAsyncThunk<
    {
        callRecords: CallsRecordInterface[];
    },
    GetAllCallsPropsType,
    {
        rejectValue: string;
    }
>('calls/getAllCalls', async ({ ...props }, { rejectWithValue, signal }) => {
    try {
        const res = await callsApi.getAllCalls({ ...props, abortSignal: signal });
        if (res?.message === 'Success') {
            return {
                callRecords: res.data.callRecords,
            };
        } else {
            throw res?.data;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const updateCall = createAsyncThunk<
    StatusResponseType,
    UpdateCallPropsType,
    {
        rejectValue: string;
    }
>('calls/updateCall', async ({ callId, ...props }, { rejectWithValue }) => {
    try {
        const res = await callsApi.updateCallComment({ callId, ...props });
        if (isResponseOK(res)) {
            return res;
        } else {
            throw res;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

const initialState: InitialStateType = {
    callRecords: [],
    loading: {
        calls: false,
        updateCall: false,
    },
    isRequestFulfilled: {
        calls: false,
        updateCall: false,
    },
    error: '',
};

export const callsSlice = createSlice({
    name: 'calls',
    initialState,
    reducers: {
        clearCallsState: (state) => {
            state = initialState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllCalls.pending, (state) => {
            state.loading.calls = true;
            state.isRequestFulfilled.calls = false;
        });
        builder.addCase(getAllCalls.rejected, (state) => {
            state.loading.calls = false;
        });
        builder.addCase(getAllCalls.fulfilled, (state, { payload }) => {
            state.callRecords = payload.callRecords;
            state.loading.calls = false;
            state.isRequestFulfilled.calls = true;
        });
        builder.addCase(updateCall.pending, (state) => {
            state.loading.updateCall = true;
            state.isRequestFulfilled.updateCall = false;
        });
        builder.addCase(updateCall.fulfilled, (state) => {
            state.isRequestFulfilled.updateCall = true;
            state.loading.updateCall = false;
        });
        builder.addCase(updateCall.rejected, (state, { payload }) => {
            state.loading.updateCall = false;
            state.error = payload || '';
        });
    },
});

export const { clearCallsState } = callsSlice.actions;

export const selectCalls = (state: RootState) => state.calls;
