/* eslint-disable no-unused-vars */
import { CommissionType, OfferDocTypes } from '../../utils/data';
import { userDoc } from '../../api/userApi/userApi.types';
import { OfferInterface } from '../../utils/types';

export type EditOfferProps = {
    close: () => void;
    width: number;
    companyId: number;
    taskId: number;
    offer: OfferInterface;
    bankGuaranteeAmount: string | null;
    bankGuaranteeTermDays: number | null;
    isLoan: boolean;
};

export type Inputs = {
    bankCommission: string;
    baseCommission: string;
    clientCommission: string;
    bankCommissionInRub: string;
    baseCommissionInRub: string;
    clientCommissionInRub: string;
    comment: string;
    overstatement: string;
    status: string;
    agentSeparation: string;
    rateFrom: string;
    amount: string;
    term: string;
};

export type EditOfferInputType =
    | 'bankCommission'
    | 'baseCommission'
    | 'clientCommission'
    | 'bankCommissionInRub'
    | 'baseCommissionInRub'
    | 'clientCommissionInRub'
    | 'comment'
    | 'overstatement'
    | 'status'
    | 'agentSeparation'
    | 'rateFrom'
    | 'amount'
    | 'term';

export enum EditOfferInputTypeENUM {
    BANK_COMMISSION = 'bankCommission',
    BASE_COMMISSION = 'baseCommission',
    CLIENT_COMMISSION = 'clientCommission',
    BANK_COMMISSION_IN_RUB = 'bankCommissionInRub',
    BASE_COMMISSION_IN_RUB = 'baseCommissionInRub',
    CLIENT_COMMISSION_IN_RUB = 'clientCommissionInRub',
    COMMENT = 'comment',
    OVERSTATEMENT = 'overstatement',
    STATUS = 'status',
    AGENT_SEPARATION = 'agentSeparation',
    RATE_FROM = 'rateFrom',
    AMOUNT = 'amount',
    TERM = 'term',
}

export type CommissionReducer = {
    bankCommission: string;
    baseCommission: string;
    clientCommission: string;
};

export type DocReducer = {
    layout: userDoc;
    guarantee: userDoc;
};

export const commissions: CommissionType[] = [
    'baseCommission',
    'clientCommission',
    'bankCommission',
];

export type AdminCommissionType = 'baseCommission' | 'clientCommission';

export type CommissionReducerAction = {
    type: CommissionType;
    value: string;
};

export type DocReducerAction = {
    type: OfferDocTypes;
    value: userDoc;
};
