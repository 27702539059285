import styled from 'styled-components/macro';

const StyledFormWrapper = styled.div<{ width: number }>`
    width: ${({ width }) => `${width}px`};
    input,
    select {
        margin: 5px 0;
        width: 100%;
        height: 24px;
        border: 1px solid #000;
        border-radius: 5px;
        padding-left: 10px;
    }

    .add-contact__header {
        height: 34px;
        margin-right: 30px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        h3 {
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 13px;
        }
    }

    button {
        border: none;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #0038ff;
        padding: 0;
    }

    .add-contact__priority {
        display: grid;
        grid-template-columns: 1fr 24px;
        align-items: center;
        padding-top: 5px;
    }

    .add-contact__btn {
        height: 34px;
        display: flex;
        justify-content: end;
        padding-top: 10px;
    }

    .add-contact__red-highlight {
        background: rgba(255, 53, 53, 0.4);
    }

    .add-contact__green-highlight {
        background: #dcffe8;
    }

    .add-contact__red-highlight,
    .add-contact__green-highlight {
        color: transparent;
    }

    .add-contact__highlight {
        display: grid;
        grid-template-columns: 1fr 278px;
        align-items: center;
    }

    .add-contact__error-field input {
        border-color: #f00;
    }
`;

export default StyledFormWrapper;
