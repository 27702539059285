import styled from 'styled-components/macro';
import PhoneInput from 'react-phone-input-2';
import Scrollbars from 'rc-scrollbars';

const StyledContainer = styled.div<{ isCreating: boolean }>`
    height: 90vh;
    max-height: ${({ isCreating }) => `${isCreating ? '940' : '690'}px`};
    margin-left: 6px;
    form {
        margin-right: 6px;
    }

    .add-clients-info__description {
        font-style: italic;
        font-weight: 300;
        font-size: 10px;
        line-height: 14px;
        color: #000;
        margin: 8px 0 15px;
    }

    .add-clients-info__sign-of-obligatory-field {
        color: #ff0000;
    }

    .add-clients-info__comment {
        height: 121px;
        padding: 5px 10px;
    }

    input,
    select {
        margin: 5px 0;
        padding-left: 10px;
        &:focus {
            border: 2px solid #005fcc;
        }
    }

    .add-clients-info__error-input {
        border-color: #f00;
    }

    .add-clients-info__label {
        line-height: 17px;
    }

    .add-clients-info__btn {
        margin: 5px 0 10px;
    }
`;

const StyledPhoneInput = styled(PhoneInput)`
    margin: 5px 0;
    .form-control {
        width: 100%;
        height: 24px;
        border: 1px solid #000;
    }
    .flag-dropdown {
        border: 1px solid #000;
    }
`;

const StyledScrollbars = styled(Scrollbars)`
    .rc-scrollbars-track.rc-scrollbars-track-v {
        right: -4px !important;
        top: 6px !important;
    }
`;

export { StyledContainer, StyledPhoneInput, StyledScrollbars };
