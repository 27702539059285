import React, { useEffect, useReducer, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import clsx from 'clsx';

import {
    bankDocSectionAr,
    docArraysInAddingPartnerForm,
    documentsInAddPartnerForm,
    FieldNameTypeInAddingPartnerForm,
    initialBankProductsState,
    switchersInAddPartnerForm,
} from '../../utils/data';
import { useAppDispatch } from '../../store';
import {
    createPartner,
    getOnePartner,
    resetCurrentPartner,
    selectPartner,
    updatePartner,
} from '../../store/slices/partnerSlice';
import {
    PartnerDocArrayTypes,
    PartnerFileTypes,
    PartnerSwitcherTypes,
    CredentialsWithError,
    BankDocumentSectionTypes,
    CreatePartnerDocument,
} from '../../utils/types';
import {
    addInCredsCheckErrorField,
    convertPartnerDocToFileWithName,
    normalizeCredentialsForBankCreating,
} from '../../utils/functions';
import { AddPartnerValidationSchema } from '../../validation/partners';
import {
    AddPartnerProps,
    AddPartnerInputs,
    PartnerDocType,
    fieldNamesForRestoreFromCurrentPartner,
    partnerDocTypeReducerAction,
    PartnerFileWithName,
} from './AddPartner.types';
import { userSelector } from '../../store/slices/userSlice';
import InfoIcon from '../../assets/images/partners/additional-info-icon.png';
import DisabledInfoIcon from '../../assets/images/partners/disabled-info-icon.png';
import CloseIcon from '../../assets/images/partners/close-window-icon.png';
import ToggleSwitch from '../Common/ToggleSwitch/ToggleSwitch';
import BankDocItem from './BankDocItem/BankDocItem';
import AddPartnerFile from './AddPartnerFile/AddPartnerFile';
import BankProductList from './BankProductList/BankProductList';
import Credentials from './Credentials/Credentials';
import AddCredentials from './AddCredentials/AddCredentials';
import BtnLoader from '../Common/BtnLoader/BtnLoader';
import StyledWrapper from './AddPartner.style';

const AddPartner = ({ close, bankId }: AddPartnerProps) => {
    const dispatch = useAppDispatch();
    const { currentPartner, loading } = useSelector(selectPartner);
    const { user } = useSelector(userSelector);
    const [logoName, setLogoName] = useState('');
    const isAdminView = user?.isAdmin || false;
    const isFullBankInfoEnabled = user?.isFullBankInfoEnabled || false;
    const showFullInfo = isAdminView || isFullBankInfoEnabled;

    const initialPartnerDocsState: PartnerDocType = {
        agencyContract: [],
        powersOfAttorney: [],
        instructions: [],
    };
    function partnerDocsReducer(state: PartnerDocType, action: partnerDocTypeReducerAction) {
        switch (action.type) {
            case 'agencyContract':
                return {
                    ...state,
                    agencyContract: action.docs,
                };
            case 'powersOfAttorney':
                return {
                    ...state,
                    powersOfAttorney: action.docs,
                };
            case 'instructions':
                return {
                    ...state,
                    instructions: action.docs,
                };
            default:
                return state;
        }
    }
    const [partnerDocsState, dispatchPartnerDocs] = useReducer(
        partnerDocsReducer,
        initialPartnerDocsState,
    );
    const [hasInfo, setInfo] = useState(false);
    const [isAddCredentialsModalOpen, setCredentialsModalOpen] = useState(false);
    const [editingCredentials, setEditingCredentials] = useState<number | null>(null);
    const [credentials, setCredentials] = useState<CredentialsWithError[]>([]);
    const [bankProducts, setBankProducts] = useState({ ...initialBankProductsState });

    const currentInfoIcon = hasInfo ? InfoIcon : DisabledInfoIcon;

    const formOptions = {
        defaultValues: {
            name: currentPartner?.name || '',
            logo: currentPartner?.logo || '',
            isDisplayedInList: Boolean(currentPartner?.isDisplayedInList),
            isPriority: Boolean(currentPartner?.isPriority),
            additInfo: currentPartner?.additInfo || '',
        },
        resolver: yupResolver(AddPartnerValidationSchema),
    };
    const { register, handleSubmit, formState, watch, setValue, clearErrors } =
        useForm<AddPartnerInputs>(formOptions);
    const { errors } = formState;

    const switcher = {
        isDisplayedInList: watch('isDisplayedInList'),
        isPriority: watch('isPriority'),
    };
    const logo = watch('logo');
    const showAddCredentialModal = isAddCredentialsModalOpen || editingCredentials !== null;
    const credentialsForModal =
        editingCredentials !== null ? credentials[editingCredentials] : null;
    const header = `${currentPartner ? 'Редактирование' : 'Добавление нового'} партнера`;

    const closeAddCredentialsModal = () => {
        setCredentialsModalOpen(false);
        setEditingCredentials(null);
    };

    const addCredentialsHandler = (value: CredentialsWithError) => {
        const newValue = [...credentials];

        if (editingCredentials !== null) {
            newValue.splice(editingCredentials, 1, value);
        } else {
            newValue.push(value);
        }

        setCredentials(newValue);
    };

    const normalizeDocs = (docs: PartnerFileWithName[], section: BankDocumentSectionTypes) => {
        return docs?.map((doc) => {
            return {
                file: doc.file,
                name: doc.name,
                section,
            };
        });
    };

    const deleteFileHandler = (type: PartnerFileTypes) => {
        if (type === 'logo') {
            setLogoName('');
            setValue('logo', '');
            return;
        }
        dispatchPartnerDocs({
            type: 'instructions',
            docs: [],
        });
    };

    const fileSelectionHandler = (name: string, file: string, type: PartnerFileTypes) => {
        if (type === 'logo') {
            setLogoName(name);
            setValue('logo', file);
            clearErrors('logo');
            return;
        }
        dispatchPartnerDocs({
            type: 'instructions',
            docs: [{ file, name }],
        });
    };

    const getFile = (type: PartnerFileTypes) => {
        if (type === 'logo') {
            return {
                path: logo,
                name: logoName,
            };
        }
        if (!partnerDocsState.instructions.length) {
            return { path: '', name: '' };
        }
        return {
            name: partnerDocsState.instructions[0].name,
            path: partnerDocsState.instructions[0].file,
        };
    };

    const restoreDocs = (section: BankDocumentSectionTypes) => {
        const docs =
            currentPartner?.documents
                .filter((doc) => doc.section === section)
                .map((doc) => convertPartnerDocToFileWithName(doc)) || [];
        dispatchPartnerDocs({
            type: section,
            docs,
        });
    };

    const toggleInfo = () => {
        if (!isAdminView) return;

        setInfo((prev) => !prev);
    };

    const onSubmit: SubmitHandler<AddPartnerInputs> = (data) => {
        const { name, isPriority, isDisplayedInList, additInfo, logo } = data;
        const normalizedCreds = normalizeCredentialsForBankCreating(credentials);
        if (currentPartner) {
            dispatch(
                updatePartner({
                    bankId: bankId || currentPartner.bankId,
                    name: currentPartner.name !== name ? name : undefined,
                    additInfo:
                        currentPartner.additInfo !== additInfo &&
                        (currentPartner.additInfo || additInfo)
                            ? additInfo
                            : undefined,
                    isDisplayedInList:
                        currentPartner.isDisplayedInList !== isDisplayedInList
                            ? isDisplayedInList
                            : undefined,
                    isPriority: currentPartner.isPriority !== isPriority ? isPriority : undefined,
                }),
            );
            return;
        }
        const documents: CreatePartnerDocument[] = [];
        bankDocSectionAr.forEach((section: BankDocumentSectionTypes) => {
            documents.push(...normalizeDocs(partnerDocsState[section], section));
        });
        dispatch(
            createPartner({
                name,
                isPriority,
                isDisplayedInList,
                additInfo: hasInfo && additInfo ? additInfo : undefined,
                documents,
                logo: logo || undefined,
                credentials: normalizedCreds,
                ...bankProducts,
            }),
        );
    };

    useEffect(() => {
        dispatch(resetCurrentPartner());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        Object.keys(AddPartnerValidationSchema.fields).forEach((key) => {
            const errorMessage = errors[key as FieldNameTypeInAddingPartnerForm]?.message;
            if (errorMessage) {
                toast.error(errorMessage);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);

    useEffect(() => {
        if (!bankId) return;
        dispatch(getOnePartner({ bankId }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bankId]);

    useEffect(() => {
        const creds = [...addInCredsCheckErrorField(currentPartner?.credentials || [])];
        setCredentials(creds);
        fieldNamesForRestoreFromCurrentPartner.forEach((field) => {
            if (field === 'instructions') return;
            const newValue =
                field === 'isPriority' || field === 'isDisplayedInList'
                    ? Boolean(currentPartner?.[field])
                    : String(currentPartner?.[field] || '');
            setValue(field, newValue);
        });

        setInfo(!!currentPartner?.additInfo);

        setLogoName(currentPartner?.logo || '');

        bankDocSectionAr.forEach((section: BankDocumentSectionTypes) => {
            restoreDocs(section);
        });

        const newProductState = { ...initialBankProductsState };
        currentPartner?.products.forEach((product) => {
            const stopRegions = product.stopRegions.map((item) => String(item.regionId));
            const lists = product.lists.map((item) => String(item.bankProductListId));
            const documents = product.documents.map((doc) => {
                return {
                    file: doc.filePath,
                    name: doc.name,
                    bankProductDocumentId: doc.bankProductDocumentId,
                };
            });
            newProductState[product.type] = {
                limit: product.limit || '',
                rateFrom: product.rateFrom || '',
                term: product.term || '',
                hasFZ44: Boolean(product.hasFZ44),
                hasFZ223: Boolean(product.hasFZ223),
                hasPP615: Boolean(product.hasPP615),
                experience: product.experience || '',
                age: product.age || '',
                worksWithIP: Boolean(product.worksWithIP),
                worksWithAO: Boolean(product.worksWithAO),
                baseCommission: product.baseCommission || '',
                clientCommission: product.clientCommission || '',
                additInfo: product.additInfo || '',
                stopRegions,
                lists,
                documents,
                isActive: product.isActive,
                issuanceCommission: product.issuanceCommission || '',
                registrationTerm: product.registrationTerm || '',
                empty: '',
            };
        });
        setBankProducts({
            ...newProductState,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPartner]);

    return (
        <>
            <StyledWrapper>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="add-partner__header">
                        <h4>{header}</h4>
                        <div className="add-partner__actions">
                            {isAdminView && (
                                <BtnLoader isLoading={loading.crudPartner} btnTitle="Сохранить" />
                            )}
                            <img src={CloseIcon} onClick={close} />
                        </div>
                    </div>
                    <div className="add-partner__field">
                        <p>Наименование банка:</p>
                        <input id="name" {...register('name')} disabled={!isAdminView} />
                    </div>
                </form>
                {documentsInAddPartnerForm.map((document) => (
                    <AddPartnerFile
                        key={document.fieldName}
                        document={document}
                        file={getFile(document.fieldName as PartnerFileTypes)}
                        deleteFile={deleteFileHandler}
                        addFile={fileSelectionHandler}
                        bankId={bankId || null}
                        disabled={!isAdminView}
                    />
                ))}
                {switchersInAddPartnerForm.map((item) => (
                    <div key={item.fieldName} className="add-partner__field">
                        <p>{item.label}</p>
                        <ToggleSwitch
                            width={27}
                            height={16}
                            toggle={() =>
                                setValue(
                                    item.fieldName as PartnerSwitcherTypes,
                                    !switcher[item.fieldName as PartnerSwitcherTypes],
                                )
                            }
                            checked={switcher[item.fieldName as PartnerSwitcherTypes]}
                            disabled={!isAdminView}
                        />
                    </div>
                ))}
                <div className="add-partner__field">
                    <p>Дополнительная информация:</p>
                    <img
                        className={clsx({
                            'add-partner__inactive-icon': !isAdminView,
                        })}
                        src={currentInfoIcon}
                        onClick={toggleInfo}
                    />
                </div>
                {hasInfo && (
                    <textarea
                        className="add-partner__additional-info"
                        {...register('additInfo')}
                        disabled={!isAdminView}
                    />
                )}
                <div className="add-partner__sections">
                    {showFullInfo &&
                        docArraysInAddingPartnerForm.map((item) => (
                            <BankDocItem
                                key={item.fieldName}
                                section={item.fieldName as PartnerDocArrayTypes}
                                bankId={bankId || null}
                                title={item.label}
                                docs={partnerDocsState[item.fieldName as PartnerDocArrayTypes]}
                                setDoc={(value: PartnerFileWithName[]) =>
                                    dispatchPartnerDocs({
                                        type: item.fieldName as PartnerDocArrayTypes,
                                        docs: value,
                                    })
                                }
                                disabled={!isAdminView}
                            />
                        ))}
                    {showFullInfo && (
                        <Credentials
                            credentials={credentials}
                            setCredentialsModalOpen={setCredentialsModalOpen}
                            setEditingCredentials={setEditingCredentials}
                            disabled={!isAdminView}
                        />
                    )}
                    <BankProductList
                        bankProducts={bankProducts}
                        setBankProducts={setBankProducts}
                    />
                </div>
            </StyledWrapper>
            {showAddCredentialModal && (
                <AddCredentials
                    width={400}
                    credentials={credentialsForModal}
                    close={closeAddCredentialsModal}
                    setCredentials={(value: CredentialsWithError) => addCredentialsHandler(value)}
                />
            )}
        </>
    );
};

export default AddPartner;
