import styled from 'styled-components/macro';

const StyledFormWrapper = styled.form<{ width: number; height: number }>`
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    max-height: 80vh;
    input,
    select {
        margin: 5px 0;
        width: 100%;
        height: 24px;
        border: 1px solid #000;
        border-radius: 5px;
        padding-left: 10px;
    }

    h3 {
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 13px;
    }

    button {
        border: none;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #0038ff;
        padding: 0;
    }

    .add-credentials__btn {
        display: flex;
        justify-content: end;
        padding-top: 10px;
    }

    .add-credentials__error-field input {
        border-color: #ff0000;
    }

    .add-credentials__curators-header {
        font-size: 16px;
        font-weight: bold;
        color: purple;
    }

    .add-credentials__sign-of-obligatory-field {
        color: #ff0000;
    }

    .add-credentials__scroll-container {
        margin-right: 5px;
    }
`;

export default StyledFormWrapper;
