import styled from 'styled-components/macro';

const StyledClientDocuments = styled.div`
    font-weight: 500;
    font-size: 12px;
    button {
        text-decoration: underline;
        color: #0038ff;
        border: none;
        background: transparent;
    }

    .client-documents__header {
        padding: 5px 7px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 30px;
        img {
            width: 20px;
            height: 20px;
            margin-left: 10px;
        }
    }

    .client-documents__show-section-btn {
        display: flex;
        align-items: center;
        color: #0038ff;
        text-decoration: underline;
        cursor: pointer;
    }

    .client-documents__body {
        background: #e5e5e5;
        display: grid;
        grid-template-columns: 2fr 410px;
        max-height: 420px;
        overflow: hidden;
    }

    .client-documents__general-information {
        padding: 9px 0 46px 6px;
        max-height: inherit;
    }

    .client-documents__add-comment-btn {
        display: flex;
        justify-content: end;
    }

    .client-documents__comments-area {
        background: #fff;
        height: 340px;
        border-radius: 5px;
        margin: 7px 0;
        overflow-y: scroll;
        padding: 5px;
    }

    .client-documents__stats {
        padding: 7px;
        max-height: inherit;
        overflow: hidden;
    }

    .client-documents__stats-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 7px;
        font-weight: 700;
        line-height: 17px;
    }

    .client-documents__stat-header {
        font-weight: 500;
        margin-bottom: 7px;
    }

    .client-documents__italic-row {
        font-style: italic;
        font-weight: 400;
    }

    .client-documents__note-list {
        height: calc(100% - 110px);
        padding-right: 5px;
        overflow-y: auto;
    }
`;

export default StyledClientDocuments;
