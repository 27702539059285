import React from 'react';

import StyledContainer from './EditActions.style';

type EditActionsProps = {
    // eslint-disable-next-line no-unused-vars
    setStep: (value: number) => void;
    title: string;
};

const EditActions = ({ setStep, title }: EditActionsProps) => {
    return (
        <StyledContainer>
            <h3>{title}</h3>
            <div className="edit-actions__buttons">
                <button type="button" onClick={() => setStep(2)}>
                    Добавить новый раздел
                </button>
                <button type="button" onClick={() => setStep(3)}>
                    Редактировать/удалить разделы
                </button>
            </div>
        </StyledContainer>
    );
};

export default EditActions;
