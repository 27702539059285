import React, { useMemo } from 'react';

import clsx from 'clsx';
import GetCommission from '../../../../../components/GetCommission/GetCommission';
import { CommissionTypeENUM } from '../../../../../utils/data';

type TaskCommissionFieldComponentProps = {
    commission: any;
    closedOffer: any;
    isLoan: boolean;
};

const TaskCommissionFieldComponent = ({
    commission,
    closedOffer,
    isLoan,
}: TaskCommissionFieldComponentProps) => {
    const className = useMemo(
        () =>
            clsx([
                'requests-table__commission',
                {
                    'requests-table__red-field':
                        commission === CommissionTypeENUM.CLIENT_COMMISSION,
                },
            ]),
        [commission],
    );
    const offer = useMemo(
        () => (isLoan && commission === CommissionTypeENUM.CLIENT_COMMISSION ? null : closedOffer),
        [closedOffer, commission, isLoan],
    );

    return (
        <div key={commission} className={className}>
            <GetCommission commissionType={commission} offer={offer} />
        </div>
    );
};

export default TaskCommissionFieldComponent;
