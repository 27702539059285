import styled from 'styled-components/macro';

const StyledDialog = styled.div`
    background-color: rgba(0, 0, 0, 0.2);
    width: 100vw;
    height: 100vh;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    .common-modal__window {
        padding: 11px 12px 10px 16px;
        background: #fff;
        z-index: 2;
        background: #f2f2f2;
        border: 1px solid #000000;
        border-radius: 5px;
        position: relative;
    }

    .common-modal__close-btn {
        position: absolute;
        top: 10px;
        right: 12px;
        cursor: pointer;
        z-index: 3;
    }

    .common-modal__header {
        display: grid;
        grid-template-columns: 1fr 20px;
        grid-column-gap: 10px;
        h3 {
            text-align: center;
        }
        img {
            cursor: pointer;
        }
    }
`;

export default StyledDialog;
