import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../store';
import {
    createCompanyComment,
    selectClientCard,
    updateCompanyComment,
} from '../../store/slices/clientCardSlice';
import {
    createTaskComment,
    selectClientTask,
    updateTaskComment,
} from '../../store/slices/clientTaskSlice';
import { CommentType } from '../../pages/ClientInteraction/ClientCard/WorkArea/CommentItem/CommentItem';
import TextEditor from '../Common/TextEditor/TextEditor';
import CommonModal from '../Common/CommonModal/CommonModal';
import DefaultLoader from '../Common/BtnLoader/DefaultLoader';
import StyledForm from './AddComment.style';

type AddCommentProps = {
    close: () => void;
    width: number;
    companyId: number;
    editingComment: CommentType | null;
    taskId?: number;
};

const AddComment = ({ close, width, companyId, editingComment, taskId }: AddCommentProps) => {
    const dispatch = useAppDispatch();
    const firstUpdate = useRef(true);
    const { loading: taskLoading } = useSelector(selectClientTask);
    const { loading, isRequestFulfilled } = useSelector(selectClientCard);
    const [editorContent, setEditorContent] = useState('');

    const handleAddCommentClick = () => {
        if (!(editorContent && companyId)) return;

        if (editingComment) {
            let commentId: number = 0;

            if ('companyCommentId' in editingComment) {
                commentId = editingComment.companyCommentId;
            } else if ('taskCommentId' in editingComment) {
                commentId = editingComment.taskCommentId;
            }

            if (!commentId) return;

            if (taskId) {
                dispatch(
                    updateTaskComment({
                        companyId,
                        commentId,
                        taskId,
                        text: editorContent,
                    }),
                );
                return;
            }
            dispatch(
                updateCompanyComment({
                    companyId,
                    commentId,
                    text: editorContent,
                }),
            );
            return;
        }

        if (taskId) {
            dispatch(
                createTaskComment({
                    companyId,
                    taskId,
                    text: editorContent,
                }),
            );
            return;
        }
        dispatch(
            createCompanyComment({
                companyId,
                text: editorContent,
            }),
        );
    };

    const getActionName = () => {
        return editingComment ? 'Редактировать' : 'Создать';
    };

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if (isRequestFulfilled.comment) {
            close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRequestFulfilled.comment]);

    useEffect(() => {
        const workAreaEditor = document.querySelector('.public-DraftEditor-content');

        if (!workAreaEditor) return;

        workAreaEditor.addEventListener('keyup', (event) => {
            if ('code' in event) {
                if (event.code !== 'Enter') return;

                const newElement = workAreaEditor.lastChild?.lastChild as HTMLElement;

                if (!newElement) return;

                newElement.scrollIntoView();
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <CommonModal hasCloseBtn={true} close={close}>
            <StyledForm width={width}>
                <h3 className="add-comment__header">{getActionName()} комментарий</h3>
                <TextEditor
                    setEditorContent={setEditorContent}
                    editingComment={editingComment?.text || undefined}
                />
                <button
                    className="add-comment__button"
                    type="button"
                    onClick={handleAddCommentClick}
                >
                    {loading.comment || taskLoading.comment ? <DefaultLoader /> : getActionName()}
                </button>
            </StyledForm>
        </CommonModal>
    );
};

export default AddComment;
