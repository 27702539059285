import React, { useState } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { selectClientCard } from '../../../../store/slices/clientCardSlice';
import { useAppDispatch } from '../../../../store';
import { updateCompany } from '../../../../store/slices/clientDatabaseSlice';
import { formatNum } from '../../../../utils/functions';
import ArrowIcon from '../../../../assets/images/clients/arrow-on-black-square-icon.png';
import ToggleSwitch from '../../../../components/Common/ToggleSwitch/ToggleSwitch';
import CommentItem, { CommentType } from './CommentItem/CommentItem';
import AddComment from '../../../../components/AddComment/AddComment';
import AddNote from '../../../../components/AddNote/AddNote';
import NoteItem from './NoteItem/NoteItem';
import StyledWorkArea from './WorkArea.style';

const WorkArea = () => {
    const { company } = useSelector(selectClientCard);
    const dispatch = useAppDispatch();
    const [isSectionOpen, setSectionOpen] = useState(true);
    const [isAddCommentModalOpen, setAddCommentModalOpen] = useState(false);
    const [isAddNoteModalOpen, setAddNoteModalOpen] = useState(false);
    const [editingComment, setEditingComment] = useState<null | CommentType>(null);

    const selectCommentForEditing = (comment: CommentType) => {
        setEditingComment(comment);
        setAddCommentModalOpen(true);
    };

    const closeAddCommentModal = () => {
        setAddCommentModalOpen(false);
        setEditingComment(null);
    };

    const toggleHandler = () => {
        if (!company) return;
        dispatch(
            updateCompany({
                clientId: company.companyId,
                hasAutoReminder: !company.hasAutoReminder,
            }),
        );
    };

    return (
        <StyledWorkArea>
            <div className="work-area__header">
                <h3>Рабочая область</h3>
                <div
                    onClick={() => setSectionOpen(!isSectionOpen)}
                    className={clsx('work-area__show-section-btn', {
                        'work-area__opened-section': isSectionOpen,
                    })}
                >
                    <p>{isSectionOpen ? 'Свернуть' : 'Развернуть'}</p>
                    <img src={ArrowIcon} />
                </div>
            </div>
            {isSectionOpen && (
                <div className="work-area__body">
                    <div className="work-area__general-information">
                        <h4 className="work-area__general-information-header">
                            Комментарии к работе с карточкой
                        </h4>
                        <div className="work-area__comments-area">
                            {company?.comments?.map((comment) => (
                                <CommentItem
                                    key={comment.companyCommentId}
                                    companyId={Number(company.companyId)}
                                    comment={comment}
                                    selectCommentForEditing={selectCommentForEditing}
                                />
                            ))}
                        </div>
                        <div className="work-area__add-comment-btn">
                            <button onClick={() => setAddCommentModalOpen(true)}>
                                Добавить комментарий
                            </button>
                        </div>
                    </div>
                    <div className="work-area__stats">
                        <h4 className="work-area__stat-header">Статистика по клиенту</h4>
                        <div className="work-area__stats-row">
                            <p>Кол-во сделок:</p>
                            <p>{company?.successTasksCount || 0}</p>
                        </div>
                        <div className="work-area__stats-row work-area__green-row">
                            <p>Доход по клиенту:</p>
                            <p>{formatNum((company?.income ?? 0).toFixed(2))} руб.</p>
                        </div>
                        <div className="work-area__stats-row work-area__italic-row">
                            <p>Активировано напоминание</p>
                            <ToggleSwitch
                                toggle={toggleHandler}
                                checked={Boolean(company?.hasAutoReminder)}
                                width={27}
                                height={16}
                            />
                        </div>
                        <div className="work-area__stats-row">
                            <p>Пометки:</p>
                            <button type="button" onClick={() => setAddNoteModalOpen(true)}>
                                Добавить
                            </button>
                        </div>
                        <div className="work-area__note-list">
                            {company?.notes?.map((note) => (
                                <NoteItem
                                    key={note.companyNoteId}
                                    note={note}
                                    companyId={company.companyId}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            )}
            {isAddNoteModalOpen && company?.companyId ? (
                <AddNote
                    width={428}
                    companyId={company?.companyId}
                    close={() => setAddNoteModalOpen(false)}
                />
            ) : null}
            {isAddCommentModalOpen && company?.companyId ? (
                <AddComment
                    close={closeAddCommentModal}
                    width={900}
                    companyId={company.companyId}
                    editingComment={editingComment}
                />
            ) : null}
        </StyledWorkArea>
    );
};

export default WorkArea;
