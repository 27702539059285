import styled from 'styled-components/macro';

const StyledCategoryItem = styled.div`
    font-weight: 500;
    font-size: 12px;

    .task-docs-item__header {
        padding: 5px 7px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #e7e7e7;
        height: 30px;
        margin-bottom: 3px;

        img {
            width: 20px;
            height: 20px;
            margin-left: 10px;
            transform: rotate(180deg);
        }
    }

    .task-docs-item__show-section-btn {
        display: flex;
        align-items: center;
        cursor: pointer;

        span {
            font-weight: 600;
            padding-right: 15px;
        }
    }

    .task-docs-item__opened-section img {
        transform: rotate(0);
    }

    .task-docs-item__task-number {
        text-decoration-line: underline;
        color: #0038ff;
        cursor: pointer;
    }

    .task-docs-item__doc-row {
        font-weight: 300;
        padding: 5px 7px;
        display: grid;
        grid-template-columns: 150px 1fr 120px;
    }
`;

export default StyledCategoryItem;
