import styled from 'styled-components/macro';

const StyledCategoryItem = styled.div`
    font-weight: 500;
    font-size: 12px;

    .category-item__info {
        line-height: 16px;
        padding-top: 3px;

        span {
            font-weight: 700;
            margin-right: 5px;
        }
    }

    .category-item__container {
        display: flex;
        justify-content: space-between;
        padding: 5px 6px;
        min-height: 30px;
    }

    .category-item__actions {
        min-width: 200px;
        display: flex;
        justify-content: end;
        align-items: start;
        margin-left: 15px;

        img {
            width: 20px;
            height: 20px;
            cursor: pointer;
            margin-left: 11px;
        }
    }

    .category-item__gray-background {
        background: #f2f2f2;
    }

    .category-item__files-container {
        display: flex;
        flex-direction: column;
    }

    .category-item__file {
        display: flex;
        align-items: center;
        justify-content: end;
        color: #0038ff;
        text-decoration: underline;
        margin-bottom: 4px;

        button {
            padding: 0;
        }
    }

    .category-item__executor-file-container {
        margin-right: 15px;
    }

    .category-item__executor-file {
        min-height: 20px;
    }
`;

export default StyledCategoryItem;
