import React from 'react';
import { PartnerContactWithError } from '../../../../utils/types';

type CredentialsItemContactProps = {
    contact: PartnerContactWithError;
    contactIndex: number;
    credIndex: number;
};

const CredentialsItemContact = ({
    contact,
    contactIndex,
    credIndex,
}: CredentialsItemContactProps) => {
    return (
        <div
            key={`contact - ${credIndex} - ${contactIndex}`}
            className="credentials-item__table-contact"
        >
            <p>{contact.name}</p>
            <div>
                {contact.emails.map((item, emailIndex) => (
                    <div key={`email - ${credIndex} - ${contactIndex} - ${emailIndex}`}>
                        <p>{item.email}</p>
                        <p>{item.additInfo ? `доб. ${item.additInfo}` : ''}</p>
                    </div>
                ))}
            </div>
            <div>
                {contact.phoneNumbers.map((item, phoneIndex) => (
                    <div key={`phone - ${credIndex} - ${contactIndex} - ${phoneIndex}`}>
                        <p>{item.phoneNumber}</p>
                        <p>{item.additInfo ? `доб. ${item.additInfo}` : ''}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CredentialsItemContact;
