import {
    StatusResponseType,
    clientDatabaseApiPath,
} from '../clientDatabaseApi/clientDatabaseApi.types';
import {
    CreateTaskPropsType,
    clientTaskApiPath,
    CreateTaskResponseType,
    GetAllClientTasksPropsType,
    GetAllClientTasksResponseType,
    GetOneClientTaskPropsType,
    GetOneClientTaskResponseType,
    UpdateTaskPropsType,
    GetBanksForTaskResponseType,
    CreateTaskOfferPropsType,
    UpdateTaskOfferPropsType,
    CreateTaskCommentPropsType,
    UpdateTaskCommentPropsType,
    DeleteTaskCommentPropsType,
    CreateTaskReminderPropsType,
    CreateTaskDocPropsType,
    DelOrLoadTaskDocumentPropsType,
    DeleteTaskReminderPropsType,
    CreateTaskOfferDocProps,
    DeleteTaskOfferDocProps,
    CreateTaskOfferRequestProps,
    UpdateTaskOfferRequestProps,
} from './clientTaskApi.types';
import instance from '../axiosInstance';

export const clientTaskApi = {
    createClientTask: async ({
        companyId,
        ...props
    }: CreateTaskPropsType): Promise<CreateTaskResponseType> => {
        try {
            const res = await instance.post(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${clientTaskApiPath.CRUD_TASK}`,
                {
                    ...props,
                },
            );
            return res.data;
        } catch (error) {
            console.log(error);
        }
    },

    getClientTasks: async ({
        activityType,
        ...props
    }: GetAllClientTasksPropsType): Promise<GetAllClientTasksResponseType> => {
        try {
            const { abortSignal, ...params } = props;

            const res = await instance.get(`${clientTaskApiPath.GET_ALL_TASKS}/${activityType}`, {
                params,
                signal: abortSignal,
            });
            return res.data;
        } catch (error) {
            console.log(error);
        }
    },

    getOneClientTask: async ({
        companyId,
        taskId,
    }: GetOneClientTaskPropsType): Promise<GetOneClientTaskResponseType> => {
        try {
            const res = await instance.get(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${clientTaskApiPath.CRUD_TASK}/${taskId}`,
            );
            return res.data;
        } catch (error) {
            console.log(error);
        }
    },

    updateClientTask: async ({
        companyId,
        taskId,
        ...props
    }: UpdateTaskPropsType): Promise<StatusResponseType> => {
        try {
            const res = await instance.patch(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${clientTaskApiPath.CRUD_TASK}/${taskId}`,
                {
                    ...props,
                },
            );
            return res.status;
        } catch (error) {
            console.log(error);
        }
    },

    getBanksListForTask: async ({
        companyId,
        taskId,
    }: GetOneClientTaskPropsType): Promise<GetBanksForTaskResponseType> => {
        try {
            const res = await instance.get(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${clientTaskApiPath.CRUD_TASK}/${taskId}${clientTaskApiPath.GET_BANKS}`,
            );
            return res.data;
        } catch (error) {
            console.log(error);
        }
    },

    createTaskOffer: async ({
        companyId,
        taskId,
        productId,
    }: CreateTaskOfferPropsType): Promise<StatusResponseType> => {
        try {
            const res = await instance.post(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${clientTaskApiPath.CRUD_TASK}/${taskId}${clientTaskApiPath.CRUD_OFFER}/${productId}`,
            );
            return res.status;
        } catch (error) {
            console.log(error);
        }
    },

    updateTaskOffer: async ({
        companyId,
        taskId,
        offerId,
        ...props
    }: UpdateTaskOfferPropsType): Promise<StatusResponseType> => {
        try {
            const res = await instance.patch(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${clientTaskApiPath.CRUD_TASK}/${taskId}${clientTaskApiPath.CRUD_OFFER}/${offerId}`,
                { ...props },
            );
            return res.status;
        } catch (error) {
            console.log(error);
        }
    },

    createTaskOfferDoc: async ({
        companyId,
        taskId,
        offerId,
        ...props
    }: CreateTaskOfferDocProps): Promise<StatusResponseType> => {
        const res = await instance.post(
            `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${clientTaskApiPath.CRUD_TASK}/${taskId}${clientTaskApiPath.CRUD_OFFER}/${offerId}${clientTaskApiPath.CRUD_DOCS}`,
            { ...props },
        );
        return res?.status;
    },

    deleteTaskOfferDoc: async ({
        companyId,
        taskId,
        offerId,
        taskOfferDocumentId,
    }: DeleteTaskOfferDocProps): Promise<StatusResponseType> => {
        const res = await instance.delete(
            `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${clientTaskApiPath.CRUD_TASK}/${taskId}${clientTaskApiPath.CRUD_OFFER}/${offerId}${clientTaskApiPath.CRUD_DOCS}/${taskOfferDocumentId}`,
        );
        return res?.status;
    },

    createTaskComment: async ({
        companyId,
        taskId,
        ...props
    }: CreateTaskCommentPropsType): Promise<StatusResponseType> => {
        try {
            const res = await instance.post(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${clientTaskApiPath.CRUD_TASK}/${taskId}${clientTaskApiPath.CRUD_COMMENT}`,
                {
                    ...props,
                },
            );
            return res.status;
        } catch (error) {
            console.log(error);
        }
    },

    updateTaskComment: async ({
        companyId,
        taskId,
        commentId,
        ...props
    }: UpdateTaskCommentPropsType): Promise<StatusResponseType> => {
        try {
            const res = await instance.patch(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${clientTaskApiPath.CRUD_TASK}/${taskId}${clientTaskApiPath.CRUD_COMMENT}/${commentId}`,
                { ...props },
            );
            return res.status;
        } catch (error) {
            console.log(error);
        }
    },

    deleteTaskComment: async ({
        companyId,
        taskId,
        commentId,
    }: DeleteTaskCommentPropsType): Promise<StatusResponseType> => {
        try {
            const res = await instance.delete(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${clientTaskApiPath.CRUD_TASK}/${taskId}${clientTaskApiPath.CRUD_COMMENT}/${commentId}`,
            );
            return res.status;
        } catch (error) {
            console.log(error);
        }
    },

    createTaskReminder: async ({
        companyId,
        taskId,
        ...props
    }: CreateTaskReminderPropsType): Promise<StatusResponseType> => {
        try {
            const res = await instance.post(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${clientTaskApiPath.CRUD_TASK}/${taskId}${clientTaskApiPath.CRUD_REMINDER}`,
                {
                    ...props,
                },
            );
            return res.status;
        } catch (error) {
            console.log(error);
        }
    },

    deleteTaskReminder: async ({
        companyId,
        taskId,
        reminderId,
    }: DeleteTaskReminderPropsType): Promise<StatusResponseType> => {
        try {
            const res = await instance.delete(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${clientTaskApiPath.CRUD_TASK}/${taskId}${clientTaskApiPath.CRUD_REMINDER}/${reminderId}`,
            );
            return res.status;
        } catch (error) {
            console.log(error);
        }
    },

    createTaskDocument: async ({
        companyId,
        taskId,
        ...props
    }: CreateTaskDocPropsType): Promise<StatusResponseType> => {
        const res = await instance.post(
            `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${clientTaskApiPath.CRUD_TASK}/${taskId}${clientTaskApiPath.CRUD_DOCS}`,
            {
                ...props,
            },
        );
        return res?.status;
    },

    deleteTaskDocument: async ({
        companyId,
        taskId,
        documentId,
    }: DelOrLoadTaskDocumentPropsType): Promise<StatusResponseType> => {
        const res = await instance.delete(
            `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${clientTaskApiPath.CRUD_TASK}/${taskId}${clientTaskApiPath.CRUD_DOCS}/${documentId}`,
        );
        return res?.status;
    },

    createTaskOfferRequest: async ({
        companyId,
        taskId,
        offerId,
        ...props
    }: CreateTaskOfferRequestProps): Promise<StatusResponseType> => {
        try {
            const res = await instance.post(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${clientTaskApiPath.CRUD_TASK}/${taskId}${clientTaskApiPath.CRUD_OFFER}/${offerId}${clientTaskApiPath.REQUEST}`,
                {
                    ...props,
                },
            );
            return res.status;
        } catch (error) {
            console.log(error);
        }
    },

    updateTaskOfferRequest: async ({
        companyId,
        taskId,
        offerId,
        requestId,
        ...props
    }: UpdateTaskOfferRequestProps): Promise<StatusResponseType> => {
        try {
            const res = await instance.patch(
                `${clientDatabaseApiPath.CRUD_COMPANY}/${companyId}${clientTaskApiPath.CRUD_TASK}/${taskId}${clientTaskApiPath.CRUD_OFFER}/${offerId}${clientTaskApiPath.REQUEST}/${requestId}`,
                {
                    ...props,
                },
            );
            return res.status;
        } catch (error) {
            console.log(error);
        }
    },
};
