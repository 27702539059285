import styled from 'styled-components/macro';

const StyledDocContainer = styled.div`
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;

    .credentials__header {
        line-height: 20px;
        padding: 5px 6px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fdd55b;
        margin-bottom: 2px;
    }

    .credentials__button {
        text-decoration: underline;
        color: #0038ff;
        background: transparent;
        border: none;
    }

    .credentials__table-head,
    .credentials__table-row {
        display: grid;
        padding: 5px 10px;
        grid-template-columns: 65px 110px 3fr 1fr 2fr 8fr 30px;

        img {
            margin-left: 10px;
        }

        p,
        a {
            word-break: break-word;
            padding-right: 5px;
        }

        a {
            text-decoration: underline;
            color: #0038ff;
            width: fit-content;
            height: fit-content;
        }
    }

    .credentials__table-head {
        background: #d9d9d9;
        grid-template-columns: 65px 110px 3fr 1fr 2fr 3fr 3fr 2fr 30px;
    }

    .credentials__table-contact {
        display: grid;
        grid-template-columns: 3fr 3fr 2fr;
    }
`;

export default StyledDocContainer;
