import instance from '../axiosInstance';
import {
    GetCitiesType,
    GetRegionsResponseType,
    GetRegionsType,
    locationApiPath,
} from './locationApi.types';

export const locationApi = {
    getCities: async ({ ...props }: GetCitiesType): Promise<GetRegionsResponseType> => {
        try {
            const response = await instance.get(locationApiPath.FIND_CITY, {
                params: {
                    ...props,
                },
            });
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },
    getRegions: async ({ ...props }: GetRegionsType): Promise<GetRegionsResponseType> => {
        try {
            const response = await instance.get(locationApiPath.FIND_REGION, {
                params: {
                    ...props,
                },
            });
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },
};
