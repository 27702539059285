import styled from 'styled-components/macro';

const StyledNoteItem = styled.div`
    .note-item__container {
        font-weight: 500;
        font-size: 12px;
        padding: 5px 10px;
        background: #fff;
        border-bottom: 1px solid lightgray;
        margin-bottom: 10px;
        border-radius: 5px;
    }

    .note-item__red-highlight {
        background: #ffdeda;
    }

    .note-item__yellow-highlight {
        background: #fff9da;
    }

    .note-item__info {
        line-height: 17px;
        display: grid;
        grid-template-columns: 1fr 35px;
        grid-column-gap: 10px;
        margin-bottom: 2px;
    }

    .note-item__date-with-author {
        font-weight: 600;
        text-decoration: underline;
    }

    .note-item__author {
        margin: 0 7px;
    }

    .note-item__actions {
        display: flex;
        justify-content: space-between;
        align-items: end;
        img {
            width: 15px;
            height: 15px;
            cursor: pointer;
        }
    }

    .note-item__disabled-actions {
        display: none;
    }

    .note-item__text {
        ol {
            padding-left: 22px;
        }

        ul {
            padding: 0 0 0 20px !important;
            list-style-type: disc;
        }
    }
`;

export default StyledNoteItem;
