import styled from 'styled-components/macro';

type StyledCustomSelectType = {
    width: number;
    height: number;
    backgroundImage: string;
    backgroundColor: string;
    backgroundImageOffset: number;
};

const StyledCustomSelect = styled.div<StyledCustomSelectType>`
    width: ${({ width }) => `${width + 30}px`};
    overflow: hidden;
    select,
    input {
        width: ${({ width }) => `${width}px`} !important;
        height: ${({ height }) => `${height}px`};
        border: 0.5px solid #000;
        border-radius: 5px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: ${({ backgroundColor, backgroundImage, width, backgroundImageOffset }) =>
            `${backgroundColor} url(${backgroundImage}) ${
                width - 20 + backgroundImageOffset
            }px no-repeat`};
        padding: 0 30px 0 10px;
        outline: none;
        cursor: pointer;
        &::-ms-expand {
            display: none;
        }
        option {
            background: pink;
        }
    }

    input::-webkit-calendar-picker-indicator {
        display: none !important;
    }
`;

export default StyledCustomSelect;
