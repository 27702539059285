import React, { FC } from 'react';
import { StyledInterestingsPicker, StyledInterestingsBlock } from './InterestingsPicker.style';
import { CompanyInterface } from '../../utils/types';

type Props = {
    value: string[];
    // eslint-disable-next-line no-unused-vars
    onChange?: (value: string) => void;
    gap?: number;
};

export const blocks: { label: string; value: keyof CompanyInterface }[] = [
    { label: 'кред', value: 'isCredit' },
    { label: '44', value: 'is44' },
    { label: '223', value: 'is223' },
    { label: '615', value: 'is615' },
    { label: 'КБГ', value: 'isKbg' },
];

const InterestingsPicker: FC<Props> = ({ gap = 10, ...props }) => (
    <StyledInterestingsPicker gap={gap}>
        {blocks.map(({ label, value }) => (
            <StyledInterestingsBlock
                onClick={() => (props.onChange ? props.onChange(value) : null)}
                key={label}
                isActive={props.value?.includes(value)}
                isCredit={value === 'isCredit'}
            >
                {label}
            </StyledInterestingsBlock>
        ))}
    </StyledInterestingsPicker>
);

export default InterestingsPicker;
