import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import 'react-phone-input-2/lib/style.css';

import { useAppDispatch } from '../../store';
import { DocumentCategoryInterface } from '../../utils/types';
import {
    createCompanyDocument,
    selectClientDocument,
} from '../../store/slices/clientDocumentSlice';
import DefaultLoader from '../Common/BtnLoader/DefaultLoader';
import CommonModal from '../Common/CommonModal/CommonModal';
import StyledWrapper from './AddDocument.style';

type AddDocumentProps = {
    close: () => void;
    width: number;
    companyId: number;
    category: DocumentCategoryInterface;
};

const AddDocument = ({ close, width, companyId, category }: AddDocumentProps) => {
    const dispatch = useAppDispatch();
    const firstUpdate = useRef(true);
    const { loading, isRequestFulfilled } = useSelector(selectClientDocument);
    const [selectedFile, setSelectedFile] = useState<null | File>(null);
    const [fileName, setFileName] = useState('');

    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event?.target?.files) return;
        const file = event.target.files[0];
        if (!file) return;
        setSelectedFile(file);
    };

    const addDocumentHandler = () => {
        if (!selectedFile) return;
        const reader = new FileReader();
        reader.onloadend = () => {
            dispatch(
                createCompanyDocument({
                    companyId: companyId,
                    categoryId: String(category.companyDocumentCategoryId),
                    postfix: fileName,
                    file: reader.result,
                }),
            );
        };
        reader.readAsDataURL(selectedFile);
    };

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if (isRequestFulfilled.createDocument) {
            close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRequestFulfilled.createDocument]);

    return (
        <CommonModal hasCloseBtn={true} close={close}>
            <StyledWrapper width={width}>
                <h3 className="add-documents__header">Добавить документ</h3>
                <input
                    className="add-documents__file-input"
                    type="file"
                    onChange={handleFileSelect}
                />
                <label>
                    Дополнение к наименованию:
                    <input
                        className="add-documents__input"
                        value={fileName}
                        onChange={(e) => setFileName(e.target.value)}
                    />
                </label>
                <button
                    type="button"
                    className="add-documents__btn"
                    onClick={addDocumentHandler}
                    disabled={loading.createDocument}
                >
                    {loading.createDocument ? <DefaultLoader /> : 'Сохранить'}
                </button>
            </StyledWrapper>
        </CommonModal>
    );
};

export default AddDocument;
