import styled from 'styled-components/macro';

const StyledComponent = styled.div`
    font-weight: 300;
    font-size: 12px;
    margin-top: 5px;
    margin-left: 10px;
    width: fit-content;
    .export__button {
        display: flex;
        align-items: center;
        cursor: pointer;
        p {
            margin-left: 10px;
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-decoration-line: underline;
            color: #0038ff;
        }
    }
`;

export default StyledComponent;
