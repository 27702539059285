import styled from 'styled-components/macro';

const StyledFilterWrapper = styled.div`
    display: flex;
    /* padding: 5px 0; */

    .filter__manager-container {
        display: flex;
        align-items: center;
        font-weight: 500;

        p {
            width: 100px;
            margin-right: 10px;
        }
    }
`;

export default StyledFilterWrapper;
