import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { useAppDispatch } from '../../../store';
import { selectClientCard } from '../../../store/slices/clientCardSlice';
import { selectCompanyCashback } from '../../../store/slices/companyCashbackSlice';
import StatisticCommentsTable from './StatisticCommentsTable/StatisticCommentsTable';
import Filter, {
    ManagerAndDateFilterType,
    ManagerAndDateSearchParamsNames,
} from '../ManagerAndDateFilter/Filter';
import {
    clearStatisticsState,
    getCompanyComments,
    getTasksComments,
    selectStatistics,
} from '../../../store/slices/statisticsSlice';
import { formatDateString, strWithDateToIsoStr } from '../../../utils/functions';
import StyledStatisticCommentsWrapper from './CommentsStatistic.style';
import { userSelector } from '../../../store/slices/userSlice';

const defaultFilterParams: ManagerAndDateFilterType = {
    manager: '',
    date: '',
};

export type CommentTypes = 'companyComments' | 'taskComments';

type StatsCommentsProps = {
    commentType?: CommentTypes;
};

const StatisticComments = ({ commentType }: StatsCommentsProps) => {
    const firstPageUpdate = useRef(true);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();

    const firstUpdate = useRef(true);
    const { isRequestFulfilled } = useSelector(selectCompanyCashback);
    const { user } = useSelector(userSelector);
    const { company } = useSelector(selectClientCard);
    const { companyCommentsRecords, taskCommentsRecords } = useSelector(selectStatistics);
    const [filter, setFilter] = useState<ManagerAndDateFilterType>(defaultFilterParams);
    const [isFilterRestoredAfterReload, setFilterRestoredAfterReload] = useState(false);
    const isTaskTable = commentType === 'taskComments';
    const promiseTaskRef = useRef<{ abort: () => void } | undefined>();
    const promiseCommentRef = useRef<{ abort: () => void } | undefined>();

    const handleFilter = () => {
        const managerId = Number(filter.manager) || undefined;
        const date = strWithDateToIsoStr(filter.date);
        const searchParams = {
            managerId,
            date,
        };

        const adminNotAllFilters = user?.isAdmin && (filter.manager === '' || filter.date === '');
        const managerNotAllFilters = !user?.isAdmin && filter.date === '';

        if (adminNotAllFilters || managerNotAllFilters) {
            dispatch(clearStatisticsState());
            return;
        }

        if (isTaskTable) {
            promiseTaskRef.current?.abort();
            promiseTaskRef.current = dispatch(
                getTasksComments({
                    ...searchParams,
                }),
            );
            return;
        }

        promiseCommentRef.current?.abort();
        promiseCommentRef.current = dispatch(
            getCompanyComments({
                ...searchParams,
            }),
        );
    };

    const filterChangeHandler = (value: string, field: ManagerAndDateSearchParamsNames) => {
        setFilter({
            ...filter,
            [field]: value,
        });
    };

    const serializeSearchParams = (obj: ManagerAndDateFilterType) => {
        const str: string[] = [];
        Object.getOwnPropertyNames(obj).forEach((key) => {
            if (key in obj && obj[key as ManagerAndDateSearchParamsNames]) {
                const encodedKey = encodeURIComponent(key);
                const encodedValue = encodeURIComponent(
                    obj[key as ManagerAndDateSearchParamsNames],
                );
                str.push(`${encodedKey}=${encodedValue}`);
            }
        });

        return str.join('&');
    };

    const saveFilter = () => {
        const query = serializeSearchParams({
            ...filter,
        });

        navigate({
            ...location,
            search: query,
        });
    };

    useEffect(() => {
        if (firstPageUpdate.current) {
            firstPageUpdate.current = false;
            return;
        }

        saveFilter();
        handleFilter();

        if (!isFilterRestoredAfterReload && queryString.parse(location.search)) {
            setFilterRestoredAfterReload(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, company]);

    useEffect(() => {
        dispatch(clearStatisticsState());
        const queryStringParams = queryString.parse(location.search);

        if (!queryStringParams) return;
        let date = '';
        const manager = (queryStringParams?.manager as string) || defaultFilterParams.manager;
        if (queryStringParams?.date) {
            date = queryStringParams.date as string;
        } else {
            date = user?.isAdmin ? defaultFilterParams.date : formatDateString(new Date());
        }
        const newFilter = {
            manager,
            date,
        };
        setFilter(newFilter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if (isRequestFulfilled.updateCashback) {
            handleFilter();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRequestFulfilled.updateCashback]);

    return (
        <StyledStatisticCommentsWrapper>
            <div className="company-comments-page__header">
                <p>Статистика - Комментарии к {isTaskTable ? 'заявкам' : 'компаниям'}</p>
                <p>Кол-во: {isTaskTable ? taskCommentsRecords : companyCommentsRecords}</p>
            </div>
            <div className="company-comments-page__body">
                <Filter filterChangeHandler={filterChangeHandler} filterParams={filter} />
                <StatisticCommentsTable commentType={commentType} filterParams={filter} />
            </div>
        </StyledStatisticCommentsWrapper>
    );
};

export default StatisticComments;
