import styled from 'styled-components/macro';

const StyledTable = styled.div`
    font-weight: 300;
    font-size: 12px;
    margin-bottom: 10px;

    .active-requests-table__column {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-weight: 600;

        img {
            width: 12px;
            height: 12px;
            margin-left: 5px;
        }
    }

    .active-requests-table__headings,
    .active-requests-table__row {
        line-height: 20px;
        display: grid;
        grid-template-columns:
            minmax(120px, 180px) 40px minmax(70px, 90px) minmax(200px, 2fr) minmax(40px, 70px) 90px
            minmax(140px, 1fr) minmax(70px, 90px) minmax(120px, 180px) repeat(2, minmax(160px, 2fr))
            52px;
        grid-column-gap: 10px;
        padding: 5px 7px;
        align-items: center;

        a {
            width: fit-content;
            min-width: 20px;
        }
    }

    .active-requests-table__headings {
        background: #e5e5e5;
    }

    .active-requests-table__manager-view {
        grid-template-columns:
            minmax(120px, 180px) 40px minmax(70px, 90px) minmax(200px, 2fr) minmax(40px, 70px) 90px
            minmax(140px, 1fr) minmax(70px, 90px) minmax(120px, 180px) minmax(160px, 2fr) 52px;
    }

    .active-requests-table__link {
        color: #0038ff;
        text-decoration: underline;
    }

    .active-requests-table__actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .active-requests-table__disabled-link {
        cursor: auto;
    }

    .active-requests-table__executor {
        display: flex;

        img {
            margin-left: 15px;
        }
    }

    .active-requests-table__even-row {
        background: #f5f5f5;
    }

    .active-requests-table__red-row {
        background: rgba(255, 111, 111, 0.5);
    }

    .active-requests-table__green-row {
        background: rgba(235, 255, 235, 1);
    }

    .active-requests-table__right-position {
        display: flex;
        justify-content: flex-end;
        margin-right: 10px;
    }
`;

export default StyledTable;
