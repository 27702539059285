import styled from 'styled-components/macro';

const StyledFilter = styled.div`
    font-weight: 300;
    font-size: 12px;
    margin: 4px 6px;
    display: flex;

    select[disabled] {
        cursor: auto;
    }

    .date-and-manager-filter__manager-filter,
    .date-and-manager-filter__date-filter {
        display: flex;
        align-items: center;

        p {
            margin-right: 25px;
        }
    }

    .date-and-manager-filter__date-filter {
        margin-left: 25px;

        input {
            width: 140px;
            border: 1px solid #959595;
            border-radius: 5px;
            height: 24px;
            padding: 5px;
        }
    }
`;

export default StyledFilter;
