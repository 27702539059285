import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { useAppDispatch } from '../../store';
import { getAllTasks, resetTasksList, selectClientTask } from '../../store/slices/clientTaskSlice';
import { userSelector } from '../../store/slices/userSlice';
import StyledActiveRequestsWrapper from './ActiveRequests.style';
import Filter from './ManagerAndExecutorFilter/ManagerAndExecutorFilter';
import RequestsTable from './ActiveRequestsTable/ActiveRequestsTable';
import { getAllUsers } from '../../store/slices/staffSlice';
import ProductAndStatusFilter from './ProductAndStatusFilter/ProductAndStatusFilter';
import { ActiveRequestsFilterFieldENUM } from '../../utils/consts';
import { ActiveRequestsFieldNameType, ActiveRequestsFilterParams } from '../../utils/types';

const defaultFilterParams: ActiveRequestsFilterParams = {
    manager: '',
    executor: '',
    type: '',
    status: '',
};

const ActiveRequests = () => {
    const firstPageUpdate = useRef(true);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { user } = useSelector(userSelector);

    const { clientLoanTasksCount, clientGuaranteeTasksCount } = useSelector(selectClientTask);
    const [filter, setFilter] = useState<ActiveRequestsFilterParams>(defaultFilterParams);

    const [isFilterRestoredAfterReload, setFilterRestoredAfterReload] = useState(false);

    const handleFilter = () => {
        const managerId = Number(filter.manager) || undefined;
        const executorId = Number(filter.executor) || undefined;
        const status = filter.status || undefined;
        const type = filter.type || undefined;
        const searchParams = {
            managerId,
            executorId,
            status,
            type,
        };
        dispatch(
            getAllTasks({
                ...searchParams,
                activityType: 'active',
            }),
        );
    };

    const filterChangeHandler = (value: string, field: ActiveRequestsFieldNameType) => {
        let actualStatus = filter[ActiveRequestsFilterFieldENUM.STATUS];
        if (field === ActiveRequestsFilterFieldENUM.TYPE && filter[field] !== value) {
            actualStatus = '';
        }
        setFilter({
            ...filter,
            status: actualStatus,
            [field]: value,
        });
    };

    const serializeSearchParams = (obj: ActiveRequestsFilterParams) => {
        const str: string[] = [];
        Object.getOwnPropertyNames(obj).forEach((key) => {
            if (key in obj && obj[key as ActiveRequestsFieldNameType]) {
                const encodedKey = encodeURIComponent(key);
                const encodedValue = encodeURIComponent(obj[key as ActiveRequestsFieldNameType]);
                str.push(`${encodedKey}=${encodedValue}`);
            }
        });
        return str.join('&');
    };

    const saveFilter = () => {
        const query = serializeSearchParams({
            ...filter,
        });
        navigate({
            ...location,
            search: query,
        });
    };

    useEffect(() => {
        if (firstPageUpdate.current) {
            firstPageUpdate.current = false;
            return;
        }

        saveFilter();
        handleFilter();

        if (!isFilterRestoredAfterReload && queryString.parse(location.search)) {
            setFilterRestoredAfterReload(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    useEffect(() => {
        dispatch(resetTasksList());
        dispatch(getAllUsers({ onlyActiveUsers: true }));
        const queryStringParams = queryString.parse(location.search);

        if (!queryStringParams) return;

        const manager = filter.manager;
        const executor = filter.executor;
        const type = filter.type;
        const status = filter.status;
        const newFilter = {
            manager,
            executor,
            type,
            status,
        };

        setFilter(newFilter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const activeRequests = useMemo(
        () => `Активные заявки: ${clientLoanTasksCount + clientGuaranteeTasksCount}`,
        [clientGuaranteeTasksCount, clientLoanTasksCount],
    );
    const requestsInProgress = useMemo(
        () => `Заявок в работе ${clientGuaranteeTasksCount}/${clientLoanTasksCount}`,
        [clientGuaranteeTasksCount, clientLoanTasksCount],
    );
    return (
        <StyledActiveRequestsWrapper>
            <h5 className="active-requests__heading">{activeRequests}</h5>
            <p className="active-requests__count">{requestsInProgress}</p>
            {user?.isAdmin || user?.positionName === 'PFM' ? (
                <>
                    <ProductAndStatusFilter
                        filterChangeHandler={filterChangeHandler}
                        filterParams={filter}
                    />
                    <Filter
                        filterChangeHandler={filterChangeHandler}
                        filterParams={filter}
                        disabledValue={user?.fullName || ''}
                        disabledFilter={!user?.isAdmin ? 'manager' : null}
                    />
                </>
            ) : null}
            <RequestsTable />
        </StyledActiveRequestsWrapper>
    );
};

export default ActiveRequests;
