import styled from 'styled-components/macro';

const StyledCategoryItem = styled.div`
    .category-item__category-item {
        display: grid;
        grid-template-columns: 1fr 50px 20px;
        grid-column-gap: 10px;
        border-bottom: 1px solid #000;
        padding: 10px 0;
        img {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
    }

    .category-item__edit-icon {
        margin-left: 30px;
    }
`;

export default StyledCategoryItem;
