import styled from 'styled-components/macro';

const StyledContainer = styled.div`
    textarea,
    input {
        border: 1px solid #000;
        border-radius: 5px;
        margin: 5px 0;
    }
    .add-document-category__actions {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
    }

    .add-document-category__category-name {
        width: 100%;
        height: 100px;
        padding: 5px;
    }
`;

export default StyledContainer;
