import styled from 'styled-components/macro';

const StyledWrapper = styled.div`
    font-weight: 300;
    font-size: 14px;

    .add-partner-file__field {
        display: grid;
        grid-template-columns: 284px 1fr;
        align-items: center;
        margin-bottom: 5px;
        min-height: 25px;
    }

    .add-partner__delete-icon {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }

    .add-partner-file__logo {
        object-fit: contain;
        width: 75px;
        height: 30px;
        cursor: auto;
    }
`;

export default StyledWrapper;
