import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { GetAllCompanyComments } from '../../../api/clientDatabaseApi/clientDatabaseApi.types';
import { getError } from '../../../utils/functions';
import { RootState } from '../..';
import { statisticsApi } from '../../../api/statisticsApi/statisticsApi';
import { InitialStateType } from './types';
import {
    CashbackInterface,
    CompanyCommentInterface,
    StatisticTaskCommentInterface,
} from '../../../utils/types';
import { GetAllCashbackPropsType } from '../../../api/statisticsApi/statisticsApi.types';

export const getCompanyComments = createAsyncThunk<
    {
        records: number;
        comments: CompanyCommentInterface[];
    },
    GetAllCompanyComments,
    {
        rejectValue: string;
    }
>('statistics/getCompanyComments', async ({ ...props }, { rejectWithValue, signal }) => {
    try {
        const res = await statisticsApi.getCompanyComments({ ...props, abortSignal: signal });
        if (res?.message === 'Success') {
            return {
                comments: res.data.commentsList,
                records: res.data.total.records,
            };
        } else {
            throw res?.data;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const getTasksComments = createAsyncThunk<
    {
        records: number;
        comments: StatisticTaskCommentInterface[];
    },
    GetAllCompanyComments,
    {
        rejectValue: string;
    }
>('statistics/getTasksComments', async ({ ...props }, { rejectWithValue, signal }) => {
    try {
        const res = await statisticsApi.getTaskComments({ ...props, abortSignal: signal });
        if (res?.message === 'Success') {
            return {
                comments: res.data.commentsList,
                records: res.data.total.records,
            };
        } else {
            throw res?.data;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const getCashbackStat = createAsyncThunk<
    {
        records: number;
        amount: number;
        cashbackList: CashbackInterface[];
    },
    GetAllCashbackPropsType,
    {
        rejectValue: string;
    }
>('statistics/getCashbackStat', async ({ ...props }, { rejectWithValue }) => {
    try {
        const res = await statisticsApi.getAllCashback({ ...props });
        if (res?.message === 'Success') {
            return {
                cashbackList: res.data.cashbackList,
                records: res.data.total.records,
                amount: res.data.total.amount,
            };
        } else {
            throw res?.data;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

const initialState: InitialStateType = {
    companyComments: [],
    taskComments: [],
    statCashbackList: [],
    cashbackRecords: 0,
    cashbackAmount: 0,
    taskCommentsRecords: 0,
    companyCommentsRecords: 0,
    loading: {
        cashbackStatistic: false,
        companyComments: false,
        taskComments: false,
    },
    error: '',
};

export const statisticsSlice = createSlice({
    name: 'statistics',
    initialState,
    reducers: {
        clearStatisticsState: (state) => {
            state = initialState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCompanyComments.pending, (state) => {
            state.loading.companyComments = true;
        });
        builder.addCase(getCompanyComments.rejected, (state) => {
            state.loading.companyComments = false;
        });
        builder.addCase(getCompanyComments.fulfilled, (state, { payload }) => {
            state.companyComments = payload.comments;
            state.companyCommentsRecords = payload.records;
            state.loading.companyComments = false;
        });
        builder.addCase(getTasksComments.pending, (state) => {
            state.loading.taskComments = true;
        });
        builder.addCase(getTasksComments.rejected, (state) => {
            state.loading.taskComments = false;
        });
        builder.addCase(getTasksComments.fulfilled, (state, { payload }) => {
            state.taskComments = payload.comments;
            state.taskCommentsRecords = payload.records;
            state.loading.taskComments = false;
        });
        builder.addCase(getCashbackStat.pending, (state) => {
            state.loading.cashbackStatistic = true;
        });
        builder.addCase(getCashbackStat.fulfilled, (state, { payload }) => {
            state.loading.cashbackStatistic = false;
            state.statCashbackList = payload.cashbackList;
            state.cashbackRecords = payload.records;
            state.cashbackAmount = payload.amount;
        });
    },
});

export const { clearStatisticsState } = statisticsSlice.actions;

export const selectStatistics = (state: RootState) => state.statistics;
