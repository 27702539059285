import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { clsx } from 'clsx';
import toast from 'react-hot-toast';

import { useAppDispatch } from '../../../../../store';
import { CompanyCommentInterface, TaskCommentInterface } from '../../../../../utils/types';
import { deleteCompanyComment } from '../../../../../store/slices/clientCardSlice';
import { getDate, getUserName } from '../../../../../utils/functions';
import { deleteTaskComment } from '../../../../../store/slices/clientTaskSlice';
import { userSelector } from '../../../../../store/slices/userSlice';
import FontEditIcon from '../../../../../assets/images/clients/font-edit-icon.png';
import FontTrashIcon from '../../../../../assets/images/clients/font-trash-icon.png';
import ConfirmModal from '../../../../../components/Common/ConfirmModal/ConfirmModal';
import StyledCommentItem from './CommentItem.style';

export type CommentType = CompanyCommentInterface | TaskCommentInterface;

type CommentItemProps = {
    comment: CommentType;
    companyId: number;
    // eslint-disable-next-line no-unused-vars
    selectCommentForEditing: (value: CommentType) => void;
    taskId?: number;
};

const CommentItem = ({ comment, companyId, selectCommentForEditing, taskId }: CommentItemProps) => {
    const dispatch = useAppDispatch();
    const { user } = useSelector(userSelector);
    const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
    const hasRightForEdit = user?.isAdmin || comment.user.userId === user?.userId;
    const hasRightForDelete = Boolean(user?.isAdmin);

    const getCommentId = (comment: CommentType) => {
        let commentId = 0;

        if ('companyCommentId' in comment) {
            commentId = comment.companyCommentId;
        } else if ('taskCommentId' in comment) {
            commentId = comment.taskCommentId;
        }

        return commentId;
    };

    const confirmAction = () => {
        if (!hasRightForDelete) {
            return toast.error('Недостаточно прав для удаления');
        }
        let commentId = getCommentId(comment);

        if (!(commentId && companyId)) return;

        if (taskId) {
            dispatch(
                deleteTaskComment({
                    companyId,
                    commentId,
                    taskId,
                }),
            );
        } else {
            dispatch(
                deleteCompanyComment({
                    companyId,
                    commentId,
                }),
            );
        }
        setConfirmModalOpen(false);
    };

    const handleCommentEditing = (comment: CommentType) => {
        if (!hasRightForEdit) {
            return toast.error('Недостаточно прав для редактирования');
        }

        selectCommentForEditing(comment);
    };

    const checkDate = (date: string) => {
        const today = getDate(new Date().toISOString(), 'date');
        return date === today;
    };

    const isEditingPossible = () => {
        return checkDate(getDate(comment.createdAt, 'date')) && hasRightForEdit;
    };

    return (
        <StyledCommentItem>
            <div className="comment-item__info">
                <div className="comment-item__subheader">
                    <span className="comment-item__date">
                        {getDate(comment.createdAt, 'date with time')}
                    </span>
                    <span className="comment-item__author">{getUserName(comment.user)}:</span>
                </div>
                <div
                    className="comment-item__text"
                    dangerouslySetInnerHTML={{ __html: comment.text }}
                ></div>
            </div>
            <div
                className={clsx('comment-item__actions', {
                    'comment-item__not-admin-actions': !user?.isAdmin,
                })}
            >
                {isEditingPossible() && (
                    <>
                        <img src={FontEditIcon} onClick={() => handleCommentEditing(comment)} />
                        {user?.isAdmin ? (
                            <img src={FontTrashIcon} onClick={() => setConfirmModalOpen(true)} />
                        ) : null}
                    </>
                )}
            </div>
            {isConfirmModalOpen ? (
                <ConfirmModal
                    close={() => setConfirmModalOpen(false)}
                    confirmAction={confirmAction}
                    width={280}
                    height={85}
                    headText="Удалить комментарий?"
                />
            ) : null}
        </StyledCommentItem>
    );
};

export default CommentItem;
