import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { clsx } from 'clsx';

import { selectClientTask } from '../../../store/slices/clientTaskSlice';
import { userSelector } from '../../../store/slices/userSlice';
import { selectClientCard } from '../../../store/slices/clientCardSlice';
import { getTooltipLength } from '../../../utils/functions';
import SelectedBellIcon from '../../../assets/images/selected-bell-icon.png';
import DisabledBellIcon from '../../../assets/images/bell-icon.png';
import DefaultWarningIcon from '../../../assets/images/clients/warning-icon.png';
import SelectedWarningIcon from '../../../assets/images/clients/selected-warning-icon.png';
import TooltipReminder from '../../../components/Common/TooltipReminder/TooltipReminder';
import RemindersList from '../../../components/RemindersList/RemindersList';
import AddTaskImportantInfo from '../../../components/AddTaskImportantInfo/AddTaskImportantInfo';
import StyledClientTaskActions from './ClientTaskActions.style';

type ClientTaskActionsProps = {
    companyId: number;
};

const ClientTaskActions = ({ companyId }: ClientTaskActionsProps) => {
    const [isReminderModalOpen, setReminderModalOpen] = useState(false);
    const [isImportantInfoModalOpen, setImportantInfoModalOpen] = useState(false);
    const { user } = useSelector(userSelector);
    const { company } = useSelector(selectClientCard);
    const { currentTask } = useSelector(selectClientTask);

    const WarningIcon = currentTask?.importantInfo ? SelectedWarningIcon : DefaultWarningIcon;
    const reminderText = currentTask?.reminders?.[0]?.text || '';
    const reminderTime = currentTask?.reminders?.[0]?.time || '';
    const hasReminder = Boolean(currentTask?.reminders?.length);
    const BellIcon = hasReminder ? SelectedBellIcon : DisabledBellIcon;
    const isTaskClosed = Boolean(currentTask?.closeDate);
    const adminOrManager =
        user?.isAdmin ||
        user?.userId === company?.manager?.userId ||
        user?.userId === currentTask?.coExecutor?.userId ||
        user?.userId === currentTask?.executor?.userId;

    const AddImportantInfoHandler = () => {
        if (isTaskClosed || !adminOrManager) return;

        setImportantInfoModalOpen(true);
    };

    return (
        <>
            <StyledClientTaskActions>
                <div
                    className="client-task-actions__tools-img-with-description client-task-actions__reminder"
                    onClick={() => setReminderModalOpen(true)}
                >
                    <TooltipReminder
                        text={reminderText}
                        time={reminderTime}
                        hasReminder={hasReminder}
                        rightMargin={30}
                        maxWidth={800}
                        minWidth={getTooltipLength({
                            textLength: reminderText?.length,
                        })}
                    >
                        <img src={BellIcon} />
                    </TooltipReminder>
                    <p className="client-task-actions__tools-description">
                        Поставить уведомление по заявке
                    </p>
                </div>
                <div
                    className={clsx('client-task-actions__tools-img-with-description', {
                        'client-task-actions__disabled-action': isTaskClosed,
                        'client-task-actions__auto-cursor': !adminOrManager,
                    })}
                    onClick={AddImportantInfoHandler}
                >
                    <TooltipReminder
                        text={currentTask?.importantInfo || ''}
                        hasReminder={Boolean(currentTask?.importantInfo)}
                        rightMargin={30}
                        maxWidth={800}
                        minWidth={getTooltipLength({
                            textLength: currentTask?.importantInfo?.length,
                            minLetters: 10,
                            letterWidth: 8,
                        })}
                    >
                        <img src={WarningIcon} />
                    </TooltipReminder>
                    <p className="client-task-actions__tools-description">
                        Важная информация по заявке
                    </p>
                </div>
            </StyledClientTaskActions>{' '}
            {isReminderModalOpen && currentTask ? (
                <RemindersList
                    close={() => setReminderModalOpen(false)}
                    width={450}
                    companyId={companyId}
                    reminders={currentTask.reminders}
                    taskId={currentTask.taskId}
                />
            ) : null}
            {isImportantInfoModalOpen && currentTask ? (
                <AddTaskImportantInfo
                    close={() => setImportantInfoModalOpen(false)}
                    width={450}
                    initialText={currentTask.importantInfo || ''}
                    companyId={companyId}
                    taskId={currentTask.taskId}
                    taskType={currentTask.type}
                />
            ) : null}
        </>
    );
};

export default ClientTaskActions;
