import React, { useEffect, useState } from 'react';
import { clsx } from 'clsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

import { selectClientDatabase, checkINN } from '../../../store/slices/clientDatabaseSlice';
import { useAppDispatch } from '../../../store';
import { checkINNValidationSchema } from '../../../validation/addClientValidation';
import BtnLoader from '../../Common/BtnLoader/BtnLoader';
import StyledContainer from './CheckINN.style';

type Inputs = {
    inn: string;
};

type CheckINNProps = {
    // eslint-disable-next-line no-unused-vars
    setStep: (value: number) => void;
    // eslint-disable-next-line no-unused-vars
    setInn: (value: string) => void;
};

const CheckINN = ({ setStep, setInn }: CheckINNProps) => {
    const dispatch = useAppDispatch();
    const { checkInnResult, loading } = useSelector(selectClientDatabase);
    const [isInnChecked, setInnChecked] = useState(false);

    const formOptions = { resolver: yupResolver(checkINNValidationSchema) };
    const { register, handleSubmit, formState, watch } = useForm<Inputs>(formOptions);
    const { errors } = formState;
    const onSubmit: SubmitHandler<Inputs> = ({ inn }: { inn: string }) => {
        dispatch(checkINN({ inn: inn.trim() }));
    };

    const inn = watch('inn');

    useEffect(() => {
        if (errors.inn?.message) {
            toast.error(errors.inn.message);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);

    useEffect(() => {
        if (!isInnChecked) {
            return;
        }
        setInn(inn);
        if ('createdAt' in checkInnResult) {
            setStep(0);
        } else {
            setStep(2);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkInnResult]);

    useEffect(() => {
        if (loading.checkINN) {
            setInnChecked(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading.checkINN]);

    return (
        <StyledContainer>
            <h3>Добавить нового клиента (шаг 1 из 2)</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <label>
                        Введите ИНН компании
                        <input id="inn" required {...register('inn')} />
                    </label>
                </div>
                <p className="check-inn__description">
                    Для корректной проверки необходимо ввести данные ИНН клиента без пробелов, после
                    чего нажмите кнопку проверить
                </p>
                <BtnLoader
                    isLoading={loading.checkINN}
                    btnTitle="Проверить"
                    btnClass={clsx({
                        'check-inn__disabled-btn': !loading.checkINN,
                    })}
                />
            </form>
        </StyledContainer>
    );
};

export default CheckINN;
