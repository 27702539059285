import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import {
    createTaskDoc,
    deleteTaskDoc,
    getOneClientTask,
    selectClientTask,
} from '../../../store/slices/clientTaskSlice';
import { useAppDispatch } from '../../../store';
import { downloadAsFile, getDate } from '../../../utils/functions';
import { TaskDocInterface } from '../../../utils/types';
import CloseIcon from '../../../assets/images/partners/close-window-icon.png';
import AddFile from '../../../components/AddFile/AddFile';
import ConfirmModal from '../../../components/Common/ConfirmModal/ConfirmModal';
import StyledClientDocs from './ClientTaskDocs.style';

type ClientDocsProps = {
    companyId: number;
};

const ClientDocs = ({ companyId }: ClientDocsProps) => {
    const firstUpdate = useRef(true);
    const dispatch = useAppDispatch();
    const [isAddFileModalOpen, setAddFileModalOpen] = useState(false);
    const [deletingDoc, setDeletingDoc] = useState<null | number>(null);
    const [unloadedFile, setUnloadedFile] = useState<null | TaskDocInterface>(null);

    const { currentTask, loading, isRequestFulfilled } = useSelector(selectClientTask);

    const fileSelectionHandler = (name: string, file: string) => {
        if (!currentTask) return;

        dispatch(
            createTaskDoc({
                companyId,
                taskId: currentTask.taskId,
                file,
                name,
            }),
        );
    };

    const fileDeleteHandler = (documentId: number) => {
        if (!currentTask) return;

        dispatch(
            deleteTaskDoc({
                companyId,
                taskId: currentTask.taskId,
                documentId,
            }),
        );
    };

    const successHandler = () => {
        setUnloadedFile(null);
    };

    const errorHandler = (file: TaskDocInterface) => {
        if (!currentTask || unloadedFile) return;

        setUnloadedFile(file);
        dispatch(getOneClientTask({ companyId, taskId: currentTask.taskId }));
    };

    const downloadDocument = (file: TaskDocInterface) => {
        downloadAsFile(file.filePath, file.name, errorHandler, successHandler);
    };

    useEffect(() => {
        if (!unloadedFile?.taskDocumentId) return;

        const doc = currentTask?.documents.find(
            (doc) => doc?.taskDocumentId === unloadedFile.taskDocumentId,
        );

        if (!doc) return;

        downloadDocument(doc);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTask?.documents]);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if (isRequestFulfilled.deleteTaskDoc) {
            setDeletingDoc(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRequestFulfilled.deleteTaskDoc]);

    return (
        <StyledClientDocs>
            <div className="task-docs__header">
                <p>Документы к заявке</p>
                <button onClick={() => setAddFileModalOpen(true)}>Добавить документ</button>
            </div>
            <div className="task-docs__table-head">
                <p>Дата добавления</p>
                <p>Наименование документа</p>
                <p>Файл</p>
            </div>
            {currentTask?.documents?.map((doc, index) => (
                <div
                    key={doc.taskDocumentId}
                    className={clsx('task-docs__doc-row', {
                        'task-docs__even-field': index % 2 !== 0,
                    })}
                >
                    <p>{getDate(doc.createdAt, 'date with time', true)}</p>
                    <p>{doc.name}</p>
                    <div className="task-docs__actions">
                        <button type="button" onClick={() => downloadDocument(doc)}>
                            Скачать
                        </button>
                        <img src={CloseIcon} onClick={() => setDeletingDoc(doc.taskDocumentId)} />
                    </div>
                </div>
            ))}
            {isAddFileModalOpen && (
                <AddFile
                    close={() => setAddFileModalOpen(false)}
                    width={400}
                    setFile={fileSelectionHandler}
                    title="Добавить документы"
                    loading={loading.uploadTaskFile}
                    isFulfilled={isRequestFulfilled.uploadFile}
                />
            )}
            {deletingDoc ? (
                <ConfirmModal
                    close={() => setDeletingDoc(null)}
                    confirmAction={() => fileDeleteHandler(deletingDoc)}
                    width={280}
                    height={110}
                    headText="Вы уверены, что хотите удалить документ?"
                    loading={loading.deleteTaskDoc}
                />
            ) : null}
        </StyledClientDocs>
    );
};

export default ClientDocs;
