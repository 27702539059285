import styled from 'styled-components/macro';

const StyledForm = styled.form<{ width: number }>`
    width: ${({ width }) => `${width}px`};
    position: relative;
    padding-bottom: 50px;

    .add-call-comment__header {
        text-align: center;
    }

    .add-call-comment__button {
        border: none;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #0038ff;
        position: absolute;
        right: 0;
        bottom: 10px;
    }

    .add-call-comment__setting-time {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;
    }

    .add-call-comment__input {
        margin: 30px 0 0;
        width: 100%;
        height: 24px;
        border: 1px solid #000;
        border-radius: 5px;
        padding: 2px 10px;
    }

    .add-call-comment__text {
        margin-top: 20px;
        width: 100%;
        height: 155px;
        border: 1px solid #000;
        border-radius: 5px;
        padding: 10px;
    }
    .add-call-comment__counter {
        font-size: 13px;
    }
`;

export default StyledForm;
