import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import queryString from 'query-string';

import { deleteCompany, getCompany, selectClientCard } from '../../store/slices/clientCardSlice';
import { useAppDispatch } from '../../store';
import { clientInteractionTabs, ClientInteractionTabNames } from '../../utils/data';
import { userSelector } from '../../store/slices/userSlice';
import { selectClientTask } from '../../store/slices/clientTaskSlice';
import DeleteCompanyIcon from '../../assets/images/clients/delete-company-icon.png';
import ClientCard from './ClientCard/ClientCard';
import ClientDocuments from './ClientDocuments/ClientDocuments';
import ConfirmModal from '../../components/Common/ConfirmModal/ConfirmModal';
import DefaultLoader from '../../components/Common/BtnLoader/DefaultLoader';
import ClientCashback from './ClientCashback/ClientCashback';
import ClientRequests from './ClientClosedRequests/ClientClosedRequests';
import ClientActs from './ClientActs/ClientActs';
import StyledClientInteractionWrapper from './ClientInteraction.style';
import { TaskProductENUM } from '../../utils/types';
import CallsComponent from '../../components/Common/CallsComponent/CallsComponent';

type ClientInteractionProps = {
    taskId?: number;
};
const taskProductTypeLabels = {
    loan: '(К)',
    bankGuarantee: '(БГ)',
};

const ClientInteraction = ({ taskId }: ClientInteractionProps) => {
    const firstUpdate = useRef(true);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();

    const { companyId } = useParams();
    const { user } = useSelector(userSelector);
    const { company, isRequestFulfilled, loading } = useSelector(selectClientCard);
    const { currentTask } = useSelector(selectClientTask);
    const [isDeleteCompanyModalOpen, setDeleteCompanyModalOpen] = useState(false);
    const isManagerOrAdmin = user?.isAdmin || company?.manager?.userId === user?.userId;
    const callsTabPermission =
        user?.isAdmin || (company?.manager?.userId === user?.userId && user?.isCallRecordEnabled);
    const isUserExecutor =
        taskId &&
        (currentTask?.executor?.userId === user?.userId ||
            currentTask?.coExecutor?.userId === user?.userId);

    const queryStringParams = queryString.parse(location.search);
    const selectedTab =
        typeof queryStringParams?.tab !== 'string' ? 'clientCard' : queryStringParams.tab;
    const taskProductTypeLabel =
        currentTask?.type === TaskProductENUM.BANK_GUARANTEE
            ? taskProductTypeLabels.bankGuarantee
            : taskProductTypeLabels.loan;

    const tabLabel =
        selectedTab === 'clientCard' && taskId
            ? `Заявка № ${taskId} ${taskProductTypeLabel}`
            : clientInteractionTabs.find((tab) => tab.name === selectedTab)?.label || '';
    const headerStart = 'База клиентов > ';
    const headerFinish = ` > ${tabLabel}`;

    const getCallsTabComponent = () => {
        if (!callsTabPermission) return tabNavigationHandler('clientCard');
        return (
            <CallsComponent
                isTabView
                currentCompanyId={company?.companyId || 0}
                hideManagerFilter={!user?.isAdmin}
            />
        );
    };

    const getPageByTab = (tab: ClientInteractionTabNames) => {
        switch (tab) {
            case 'clientCard':
                return <ClientCard taskId={taskId} />;
            case 'clientDocuments':
                return <ClientDocuments />;
            case 'closedDeals':
                return <ClientRequests />;
            case 'cashback':
                return <ClientCashback />;
            case 'acts':
                return <ClientActs />;
            case 'calls':
                return getCallsTabComponent();

            default:
                break;
        }
    };

    const getRightForTab = (tab: ClientInteractionTabNames) => {
        switch (tab) {
            case 'clientCard':
            case 'closedDeals':
            case 'clientDocuments':
                return !(isManagerOrAdmin || isUserExecutor);
            case 'cashback':
            case 'acts':
                return !isManagerOrAdmin;
            case 'calls':
                return !callsTabPermission;
            default:
                return true;
        }
    };

    const confirmAction = () => {
        if (!companyId) return;
        dispatch(deleteCompany({ companyId: Number(companyId) }));
        setDeleteCompanyModalOpen(false);
    };

    const tabNavigationHandler = (tabName: ClientInteractionTabNames) => {
        navigate({
            ...location,
            search: `tab=${tabName}`,
        });
    };

    const linkForCompany = companyId ? `/clients/card/${companyId}?tab=clientCard` : '';

    const openDeleteModal = () => setDeleteCompanyModalOpen(true);

    useEffect(() => {
        if (!companyId) return;
        dispatch(getCompany({ companyId: Number(companyId) }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyId]);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if (isRequestFulfilled.companyDeleting) {
            navigate('/clients');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRequestFulfilled.companyDeleting]);

    const isContentReadyToShow =
        loading.companyDeleting || company?.companyId !== Number(companyId);

    return (
        <StyledClientInteractionWrapper>
            {isContentReadyToShow ? (
                <div className="client-interaction__spinner">
                    <DefaultLoader />
                </div>
            ) : (
                <>
                    <div className="client-interaction__header">
                        <h2>
                            {headerStart}
                            {isUserExecutor && !isManagerOrAdmin ? (
                                <span>
                                    {company?.name || ''} ИНН {company?.inn || ''}
                                </span>
                            ) : (
                                <a target="_blank" rel="noopener noreferrer" href={linkForCompany}>
                                    {company?.name || ''} ИНН {company?.inn || ''}
                                </a>
                            )}

                            {headerFinish}
                        </h2>
                        {user?.isAdmin && !company?.tasks?.length && (
                            <img src={DeleteCompanyIcon} onClick={openDeleteModal} />
                        )}
                    </div>
                    <div className="client-interaction__nav-tab-container">
                        {clientInteractionTabs.map((tab) => (
                            <button
                                type="button"
                                key={tab.name}
                                className={clsx('client-interaction__nav-tab', {
                                    'client-interaction__selected-nav-tab':
                                        selectedTab === tab.name,
                                })}
                                disabled={getRightForTab(tab.name)}
                                onClick={() => tabNavigationHandler(tab.name)}
                            >
                                {tab.label}
                            </button>
                        ))}
                    </div>
                    {getPageByTab(selectedTab as ClientInteractionTabNames)}
                    {isDeleteCompanyModalOpen ? (
                        <ConfirmModal
                            close={() => setDeleteCompanyModalOpen(false)}
                            confirmAction={confirmAction}
                            width={280}
                            height={120}
                            headText="Вы уверены, что хотите удалить компанию?"
                        />
                    ) : null}
                </>
            )}
        </StyledClientInteractionWrapper>
    );
};

export default ClientInteraction;
