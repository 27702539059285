import styled from 'styled-components/macro';

const StyledTable = styled.div`
    font-weight: 300;
    font-size: 12px;
    margin-bottom: 10px;

    .cashback-table__title {
        padding: 5px 7px;
        height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #e5e5e5;

        span {
            font-weight: 600;
        }
    }

    .cashback-table__stats {
        display: flex;
        align-items: center;

        p {
            margin-left: 15px;
        }
    }

    .cashback-table__column {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-weight: 600;

        img {
            width: 12px;
            height: 12px;
            margin-left: 5px;
        }
    }

    .cashback-table__headings,
    .cashback-table__row {
        line-height: 20px;
        display: grid;
        grid-template-columns:
            minmax(120px, 180px) minmax(60px, 90px) 200px 1fr minmax(100px, 150px)
            minmax(120px, 180px) 2fr 80px;
        grid-column-gap: 10px;
        padding: 5px 7px;
    }

    .cashback-table__number {
        color: #0038ff;
        text-decoration: underline;
        width: fit-content;
        min-width: 20px;
    }

    .cashback-table__icons {
        display: flex;
        align-items: start;
        justify-content: end;
        height: 20px;

        img {
            margin-left: 20px;
        }
    }

    .cashback-table__manger-view-icon {
        justify-content: start;
    }

    .cashback-table__edit-icon {
        cursor: pointer;
    }

    .cashback-table__statistic-row {
        background: #e7e7e7;
        grid-template-columns:
            minmax(120px, 180px) minmax(60px, 90px) minmax(250px, 2fr) 90px minmax(150px, 1fr)
            minmax(70px, 130px) minmax(120px, 180px) minmax(250px, 2fr) 80px;

        .cashback-table__column {
            cursor: auto;
            font-weight: 500;

            img {
                display: none;
            }
        }
    }

    .cashback-table__row {
        background: #fff;
    }

    .cashback-table__sorting-column {
        cursor: auto;
    }

    .cashback-table__disabled-link {
        cursor: auto;
    }

    .cashback-table__even-row {
        background: #f5f5f5;
    }

    .cashback-table__right-position {
        display: flex;
        justify-content: flex-end;
        margin-right: 10px;
    }
    .cashback-table__loader {
        margin: 50px;
        text-align: center;
    }
    .cashback-table__no-results {
        text-align: center;
        font-size: 18px;
        margin: 30px;
    }
    .cashback-table__no-results > div {
        margin-top: 10px;
    }
`;

export default StyledTable;
