import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RotatingLines } from 'react-loader-spinner';
import { clsx } from 'clsx';

import { selectCompanyCashback } from '../../../../store/slices/companyCashbackSlice';
import {
    CompanyCashbackSortingFields,
    StatisticCashbackColumnType,
    clientCashbackColumns,
    statisticCashbackColumns,
} from '../../../../utils/data';
import { formatNum } from '../../../../utils/functions';
import { CashbackInterface } from '../../../../utils/types';
import { selectStatistics } from '../../../../store/slices/statisticsSlice';
import { userSelector } from '../../../../store/slices/userSlice';
import SortBtnIcon from '../../../../assets/images/clients/arrows-up-and-down-icon.png';
import SelectedSortBtnIcon from '../../../../assets/images/clients/selected-arrows-up-and-down-icon.png';
import EditCashback from '../../../../components/EditCashback/EditCashback';
import StyledTable from './CashbackTable.style';
import CashbackList from '../CashbackList/CashbackList';

type cashbackTableProps = {
    sortingField?: CompanyCashbackSortingFields;
    // eslint-disable-next-line no-unused-vars
    setSortingField?: (value: CompanyCashbackSortingFields) => void;
    isStatisticPage?: boolean;
};

const CashbackTable = ({ sortingField, setSortingField, isStatisticPage }: cashbackTableProps) => {
    const firstUpdate = useRef(true);
    const {
        loading: companyCahbackLoading,
        totalAmount,
        records,
        companyCashbackList,
        isRequestFulfilled,
    } = useSelector(selectCompanyCashback);
    const { user } = useSelector(userSelector);
    const { loading: statisticLoading, statCashbackList } = useSelector(selectStatistics);
    const [selectedCashback, setSelectedCashback] = useState<null | CashbackInterface>(null);
    const cashbackColumns = isStatisticPage ? statisticCashbackColumns : clientCashbackColumns;
    const cashbackList = isStatisticPage ? statCashbackList : companyCashbackList;

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if (isRequestFulfilled.updateCashback) {
            setSelectedCashback(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRequestFulfilled.updateCashback]);

    const columnClickHandler = (column: StatisticCashbackColumnType) => {
        if (!column.isSortingField || !setSortingField) return;

        setSortingField(column.name as CompanyCashbackSortingFields);
    };

    const getColumnClass = (column: StatisticCashbackColumnType) => {
        return clsx('cashback-table__column', {
            'cashback-table__sorting-column': !column.isSortingField,
            'cashback-table__right-position': column.name === 'clientCommission',
        });
    };

    const headingsClass = clsx('cashback-table__headings', {
        'cashback-table__statistic-row': isStatisticPage,
    });

    return (
        <StyledTable>
            {!isStatisticPage ? (
                <div className="cashback-table__title">
                    <span>Статистика выплаты кэшбэка</span>
                    <div className="cashback-table__stats">
                        <p>
                            кол-во: <span>{formatNum(String(records))}</span>
                        </p>
                        <p>
                            сумма: <span>{formatNum(totalAmount.toFixed(2))} руб.</span>
                        </p>
                    </div>
                </div>
            ) : null}
            <div className={headingsClass}>
                {cashbackColumns.map((column) => (
                    <div
                        key={column.name}
                        className={getColumnClass(column)}
                        onClick={() => columnClickHandler(column)}
                    >
                        <p>{column.label}</p>
                        {column.isSortingField && (
                            <img
                                src={
                                    column.name === sortingField ? SelectedSortBtnIcon : SortBtnIcon
                                }
                            />
                        )}
                    </div>
                ))}
            </div>
            <div>
                {companyCahbackLoading.getCashback || statisticLoading.cashbackStatistic ? (
                    <div className="cashback-table__loader">
                        <RotatingLines strokeColor="grey" />
                    </div>
                ) : (
                    <CashbackList
                        cashbackListArray={cashbackList}
                        user={user}
                        setSelectedCashback={setSelectedCashback}
                        isStatisticPage={isStatisticPage}
                    />
                )}
            </div>
            {selectedCashback ? (
                <EditCashback
                    companyId={selectedCashback.company.companyId}
                    taskId={selectedCashback.offer.task?.taskId || 0}
                    cashback={selectedCashback}
                    bankGuaranteeAmount={selectedCashback.offer.bankGuaranteeAmount || null}
                    close={() => setSelectedCashback(null)}
                    width={418}
                />
            ) : null}
        </StyledTable>
    );
};

export default CashbackTable;
