import { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

import { emailRegExp, excelFileExtension, excelFileType, phoneRegExp } from './consts';
import {
    ImportanceNameType,
    EmailWithAdditInfoType,
    PhoneWithAdditInfoType,
    PartnerCredential,
    EmailWithError,
    PhoneWithError,
    CredentialsWithError,
    PartnerContact,
    BankProductType,
    IUser,
    TaskProductType,
    FederalLawsTypes,
    TaskOfferStatusTypes,
    PartnerDocument,
    PartnerInterface,
    TaskStatusTypes,
    TaskCloseReasonsTypes,
    ClientTaskInterface,
    OfferWithBankProduct,
    TaskProductENUM,
    ClientTasksType,
    TaskProductTypes,
    OfferInterface,
    CompanyInterface,
    CallsRecordInterface,
    UserStatusENUM,
} from './types';
import {
    BankGuaranteeTaskInfoFieldNames,
    BankGuaranteeTaskStatuses,
    CommissionTypeENUM,
    LoanTaskInfoFieldNames,
    LoanTaskStatuses,
    federalLawsInAddPartnerPage,
} from './data';
import { PartnerFileWithName } from '../components/AddPartner/AddPartner.types';
import { offerCommissionFieldsType } from '../pages/ClientTaskPage/OffersForTaskTable/OffersForTaskTable';
import { blocks } from '../components/InterestingsPicker/InterestingsPicker';

export const getError = (e: unknown) => {
    if (typeof e === 'string') {
        return e;
    } else if (Array.isArray(e) && e[0]?.message) {
        return e[0].message;
    }
    const error: AxiosError = e as AxiosError;
    const message = error.message;
    return message;
};

export const catchErrorInApi = (error: unknown) => {
    console.log(error);
    let er: AxiosError = error as AxiosError;
    return er.response;
};

export const getBackgroundByImportance = (importance: ImportanceNameType) => {
    switch (importance) {
        case 'no':
            return 'rgba(217, 217, 217, 0.2)';
        case 'low':
            return 'rgba(0, 255, 102, 0.2)';
        case 'medium':
            return 'rgba(255, 230, 0, 0.2)';
        case 'high':
            return 'rgba(255, 0, 0, 0.2)';
        default:
            return 'transparent';
    }
};

export const getDigitsFromString = (str: string) => {
    let digits = '';
    for (let i = 0; i < str.length; i++) {
        if (Number(str[i]) || str[i] === '0') {
            digits += str[i];
        }
    }
    return digits;
};

export const normalizePhone = (str: string) => {
    if (!str) return '';
    let digits = '+';
    for (let i = 0; i < str?.length; i++) {
        if (i === 1) {
            digits += ' (';
        }
        if (i === 4) {
            digits += ') ';
        }
        if (i === 7 || i === 9) {
            digits += '-';
        }
        digits += str[i];
    }
    return digits;
};

export const getDate = (
    isoDate: string | Date | null,
    type: 'date with time' | 'date' | 'time' | 'hours',
    withoutSeconds?: boolean,
) => {
    if (!isoDate) return '';
    if (typeof isoDate !== 'string') return isoDate.toLocaleString();
    const date = new Date(isoDate);
    let normalizedDate = date.toLocaleString();
    const dividerIndex = normalizedDate.indexOf(',');
    if (type === 'date') {
        normalizedDate = normalizedDate.slice(0, dividerIndex);
    } else if (type === 'time') {
        normalizedDate = normalizedDate.slice(dividerIndex + 1);
    } else if (type === 'hours') {
        normalizedDate = `${date.getHours()}`;
    }
    if (type.includes('time') && withoutSeconds) {
        normalizedDate = normalizedDate.slice(0, -3);
    }
    return normalizedDate;
};

export const getNormalizedDateOrTime = (date: number) => {
    if (date >= 10) {
        return date;
    }
    return `0${date}`;
};

export const getDateAndTimeFromIsoString = (string: string) => {
    if (!string) {
        return {
            date: '',
            time: '',
        };
    }
    const localDate = new Date(string);
    const date = `${localDate.getFullYear()}-${getNormalizedDateOrTime(
        localDate.getMonth() + 1,
    )}-${getNormalizedDateOrTime(localDate.getDate())}`;
    const time = `${getNormalizedDateOrTime(localDate.getHours())}:${getNormalizedDateOrTime(
        localDate.getMinutes(),
    )}`;
    return { date, time };
};

export const getImportanceLabelByName = (name?: ImportanceNameType | null) => {
    switch (name) {
        case 'low':
            return 'Низкая';
        case 'medium':
            return 'Средняя';
        case 'high':
            return 'Высокая';
        default:
            return 'Отсутствует';
    }
};

export const genPassword = (len: number) => {
    let password = '';
    const symbols = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!№;%:?*()_+=';
    let multiplier = symbols.length;
    let shift = 0;
    if (len < 4) {
        for (let i = 0; i < len; i++) {
            password += symbols.charAt(Math.floor(Math.random() * multiplier) + shift);
        }
        return password;
    }
    const cases = [0, len - 1, 1, len - 2];
    for (let i = 0; i < len; i++) {
        switch (i) {
            case cases[0]:
                multiplier = 26;
                shift = 0;
                break;
            case cases[1]:
                shift = 26;
                multiplier = 26;
                break;
            case cases[2]:
                multiplier = 10;
                shift = 52;
                break;
            case cases[3]:
                multiplier = 12;
                shift = 62;
                break;
            default:
                multiplier = symbols.length;
                shift = 0;
                break;
        }
        password += symbols.charAt(Math.floor(Math.random() * multiplier) + shift);
    }
    return password;
};

export const getItem = (name: string) => {
    return localStorage.getItem(name) || '';
};

export const setTokens = (tokens?: { authorization: string; refresh: string }) => {
    localStorage.setItem('accessToken', tokens?.authorization || '');
    localStorage.setItem('refreshToken', tokens?.refresh || '');
};

export const downloadAsFile = (
    dataurl: string,
    name: string,
    errorHandler: Function,
    successHandler: Function,
    isCall?: boolean,
) => {
    if (isCall) {
        try {
            const link = document.createElement('a');
            link.href = dataurl;
            link.target = '_blank';
            link.download = name || 'file';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            successHandler();
        } catch (error) {
            errorHandler();
        }
        return;
    }
    fetch(dataurl)
        .then((response) => {
            if (response.ok) {
                return response.blob();
            } else {
                throw new Error(response.statusText);
            }
        })
        .then((blob) => {
            const link = window.document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = name || 'file';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            successHandler();
        })
        .catch(() => {
            errorHandler();
        });
};

export const normalizeStatusAndPosition = (value: string) => {
    switch (value) {
        case 'ПФМ':
            return 'PFM';
        case 'ОСКО':
            return 'OSKO';
        case 'Активен':
            return 'active';
        case 'Уволен':
            return 'suspended';
        case 'PFM':
            return 'ПФМ';
        case 'OSKO':
            return 'ОСКО';
        case 'active':
            return 'Активен';
        case 'suspended':
            return 'Уволен';
        default:
            return '';
    }
};

export const addInContactCheckErrorField = (
    contact: (EmailWithAdditInfoType | PhoneWithAdditInfoType)[],
) => {
    return contact?.map((item) => {
        if ('phoneNumber' in item) {
            return {
                ...item,
                phoneNumber: normalizePhone(item.phoneNumber),
                isError: false,
            };
        }
        return {
            ...item,
            isError: false,
        };
    });
};

export const addInCredsCheckErrorField = (creds: PartnerCredential[]) => {
    return creds.map((cred) => {
        const contacts = cred.contacts.map((contact) => {
            const emails = addInContactCheckErrorField(contact.emails) as EmailWithError[];
            const phoneNumbers = addInContactCheckErrorField(
                contact.phoneNumbers,
            ) as PhoneWithError[];
            return {
                ...contact,
                emails,
                phoneNumbers,
            };
        });
        return {
            ...cred,
            contacts,
        };
    });
};

export const formatNum = (sum: string, oldValue: string = '', isInteger?: boolean) => {
    const digits = sum.split(' ').join('');
    let dividerIndex = digits.indexOf(',');
    const convertedNumber = digits.split(',').join('.');

    if (Number.isNaN(Number(convertedNumber)) || digits[0] === '-') {
        return oldValue;
    }

    let integerPart = digits;
    let fractionalPart = '';

    if (dividerIndex < 0) {
        dividerIndex = digits.indexOf('.');
    }

    if (dividerIndex >= 0) {
        integerPart = digits.slice(0, dividerIndex);
        fractionalPart = digits.slice(dividerIndex);
    }

    let result = '';
    for (let i = 1; i <= integerPart.length; i++) {
        result += integerPart[i - 1];

        if ((integerPart.length - i) % 3 === 0 && i !== integerPart.length) {
            result += ' ';
        }
    }

    if (!isInteger) {
        result += fractionalPart;
    }

    return result;
};

export const checkPhonesArray = (phoneNumbers: PhoneWithError[]) => {
    return phoneNumbers.map((phone) => {
        const isError = !phone.phoneNumber.match(phoneRegExp) && phone.phoneNumber.length > 3;
        return {
            ...phone,
            isError,
        };
    });
};

export const checkEmailArray = (emails: EmailWithError[]) => {
    return emails.map((item) => {
        const isError = !item.email.match(emailRegExp) && item.email !== '';
        return {
            ...item,
            isError,
        };
    });
};

export const hasEmailOrPhoneError = (emails: (EmailWithError | PhoneWithError)[]) => {
    return Boolean(emails.find((item) => item.isError));
};

export const normalizeEmails = (emails: EmailWithError[]) => {
    return emails
        .filter((item) => item.email.match(emailRegExp))
        .map((item) => {
            return {
                email: item.email,
                additInfo: item.additInfo || undefined,
            };
        });
};

export const normalizePhoneNumbers = (phones: PhoneWithError[]) => {
    return phones
        .filter((phone) => phone.phoneNumber.match(phoneRegExp))
        .map((phone) => {
            return {
                phoneNumber: getDigitsFromString(phone.phoneNumber),
                additInfo: phone.additInfo || undefined,
                isDisabled: phone.isDisabled,
            };
        });
};

export const normalizeContactsForBankCreating = (credentials: CredentialsWithError[]) => {
    const normalizedCredentials = credentials.map((cred) => {
        const newContacts: PartnerContact[] = cred.contacts.map((contact) => {
            const newPhoneNumbers = contact.phoneNumbers
                .filter((item) => item.phoneNumber)
                .map((phone) => {
                    return {
                        phoneNumber: getDigitsFromString(phone.phoneNumber),
                        additInfo: phone.additInfo || undefined,
                    };
                });
            const newEmails = contact.emails
                .filter((item) => item.email)
                .map((item) => {
                    return {
                        email: item.email,
                        additInfo: item.additInfo || undefined,
                    };
                });
            return {
                name: contact.name,
                phoneNumbers: newPhoneNumbers,
                emails: newEmails,
            };
        });
        return {
            type: cred.type,
            lkOperator: cred.lkOperator,
            lkLink: cred.lkLink,
            login: cred.login,
            password: cred.password,
            contacts: newContacts,
        };
    });
    return normalizedCredentials;
};

export const normalizeCredentialsForBankCreating = (credentials: CredentialsWithError[]) => {
    const normalizedCredentials = credentials.map((cred) => {
        const newContacts: PartnerContact[] = cred.contacts.map((contact) => {
            const newPhoneNumbers = contact.phoneNumbers
                .filter((item) => item.phoneNumber?.length > 3)
                .map((phone) => {
                    return {
                        phoneNumber: getDigitsFromString(phone.phoneNumber),
                        additInfo: phone.additInfo || undefined,
                    };
                });
            const newEmails = contact.emails
                .filter((item) => item.email)
                .map((item) => {
                    return {
                        email: item.email,
                        additInfo: item.additInfo || undefined,
                    };
                });
            return {
                name: contact.name,
                phoneNumbers: newPhoneNumbers,
                emails: newEmails,
            };
        });
        return {
            type: cred.type,
            lkOperator: cred.lkOperator,
            lkLink: cred.lkLink,
            login: cred.login,
            password: cred.password,
            contacts: newContacts,
        };
    });
    return normalizedCredentials;
};

export const getProductTitle = (
    type: BankProductType | TaskProductType | '',
    withAcronym?: boolean,
) => {
    if (!type) return '';
    switch (type) {
        case 'bankContractExecution':
        case 'commercialContractExecution':
        case 'contractExecution':
            return `Исполнение контракта${withAcronym ? ' (ИК)' : ''}`;
        case 'bankGuaranteeObligation':
        case 'commercialGuaranteeObligation':
        case 'guaranteeObligation':
            return `Гарантийное обязательство${withAcronym ? ' (ГО)' : ''}`;
        case 'bankAdvanceRefund':
        case 'commercialAdvanceRefund':
        case 'advanceRefund':
            return `Возврат аванса${withAcronym ? ' (ВА)' : ''}`;
        case 'applicationSecurity':
            return `Обеспечение заявки${withAcronym ? ' (ОЗ)' : ''}`;
        case 'loanUnderContract':
            return `Под контракт (КИК)`;
        case 'loanRevolving':
            return `Оборотный (КОБ)`;
        case 'other':
            return 'Иное';
        default:
            return '';
    }
};

export const getLoanProductTitleLowerCase = (type: BankProductType | '') => {
    if (!type) return '';
    switch (type) {
        case 'loanUnderContract':
            return `под контракт (КИК)`;
        case 'loanRevolving':
            return `оборотный (КОБ)`;
        default:
            return '';
    }
};

export const getLoanProductFullTitle = (type: BankProductType | '') => {
    if (!type) return '';
    switch (type) {
        case 'loanUnderContract':
            return `Кредит под контракт (КИК)`;
        case 'loanRevolving':
            return `Кредит оборотный (КОБ)`;
        default:
            return '';
    }
};

export const getUserName = (user: IUser | null) => {
    return String(user?.shortName || user?.fullName || user?.login || user?.email || '');
};

export const getManagersList = (users: IUser[]) => {
    const managers: { fullName: string; userId: number }[] = [];
    const activeUsers = users.filter((user) => user.status === UserStatusENUM.active);
    if (activeUsers.length) {
        managers.push(
            ...activeUsers.map((user) => ({
                fullName: getUserName(user),
                userId: Number(user.userId),
            })),
        );
    }
    return managers;
};

export const getLabelByLaw = (law: FederalLawsTypes | null, isShort?: boolean) => {
    let label = federalLawsInAddPartnerPage.find(({ name }) => name === law)?.label;

    if (isShort && law === 'commerce') {
        label = label?.slice(0, 3);
    }
    return label || '';
};

export const getLabelForTaskOfferStatus = (status: TaskOfferStatusTypes) => {
    switch (status) {
        case 'started':
            return 'Заведена';
        case 'signing':
            return 'Подписание заявки';
        case 'consideration':
            return 'Рассмотрение';
        case 'pending':
            return 'Ожидание';
        case 'bankRefused':
            return 'Отказ банка';
        case 'clientRefused':
            return 'Отказ клиента';
        case 'additRequest':
            return 'Дозапрос';
        case 'fixed':
            return 'Закрепленно за ДА';
        case 'commissionApproval':
            return 'Согласование комиссии';
        case 'offer':
            return 'Предложение';
        case 'givenOut':
            return 'Выдана';
        default:
            return '';
    }
};

interface testObj {
    [key: string]: any;
}
export const isObjectEmpty = (obj: testObj) => {
    let countOfDefinedFields = 0;
    Object.keys(obj).forEach((key) => {
        if (key in obj && obj[key] !== undefined) {
            countOfDefinedFields += 1;
        }
    });
    return !countOfDefinedFields;
};

export const doesEmailArraysDiffer = (
    newEmails: EmailWithAdditInfoType[],
    oldEmails: EmailWithAdditInfoType[],
) => {
    let hasDifference = false;
    newEmails.forEach((item, index) => {
        if (!oldEmails[index]) {
            hasDifference = true;
            return;
        }
        if (
            oldEmails[index]?.email !== item?.email ||
            oldEmails[index]?.additInfo !== item?.additInfo
        ) {
            hasDifference = true;
        }
    });
    return hasDifference;
};

export const doesPhoneArraysDiffer = (
    newPhones: PhoneWithAdditInfoType[],
    oldPhones: PhoneWithAdditInfoType[],
) => {
    let hasDifference = false;
    newPhones.forEach((item, index) => {
        if (!oldPhones[index]) {
            hasDifference = true;
            return;
        }

        const normalizedOldPhone = getDigitsFromString(oldPhones[index]?.phoneNumber || '');
        const normalizedNewPhone = getDigitsFromString(item?.phoneNumber || '');
        const oldInfo = oldPhones[index]?.additInfo;
        const newInfo = item?.additInfo;

        if (normalizedOldPhone !== normalizedNewPhone || oldInfo !== newInfo) {
            hasDifference = true;
        }
    });
    return hasDifference;
};

export const removeIsErrorFieldFromContact = (
    contact: (PhoneWithError | EmailWithError)[],
): (PhoneWithAdditInfoType | EmailWithAdditInfoType)[] => {
    return contact
        ?.map((item) => {
            if ('phoneNumber' in item) {
                let phoneNumber: string = getDigitsFromString(item.phoneNumber);
                if (phoneNumber === '7') {
                    phoneNumber = '';
                }
                return {
                    phoneNumber,
                    additInfo: item.additInfo,
                };
            }
            return {
                email: item.email,
                additInfo: item.additInfo,
            };
        })
        .filter((item) => {
            if ('phoneNumber' in item) {
                return Boolean(item.phoneNumber);
            }
            return Boolean(item.email);
        });
};

export const removeDuplicatesFromAr = (ar: unknown[]) => {
    return ar.filter((item, index) => {
        return ar.indexOf(item) === index;
    });
};

export const doesArraysDiffer = (firstAr: unknown[], secondAr: unknown[]) => {
    const uniqueFirstAr = removeDuplicatesFromAr(firstAr);
    const uniqueSecondAr = removeDuplicatesFromAr(secondAr);
    let hasDifference = false;
    if (uniqueFirstAr.length !== uniqueSecondAr.length) return true;
    for (let i = 0; i < uniqueFirstAr.length; i++) {
        const sameValue = uniqueSecondAr.find((item) => item === uniqueFirstAr[i]);
        if (!sameValue) {
            hasDifference = true;
            break;
        }
    }
    return hasDifference;
};

export const convertPartnerDocToFileWithName = (doc: PartnerDocument): PartnerFileWithName => {
    return {
        file: doc.filePath,
        name: doc.name,
        bankDocumentId: doc?.bankDocumentId,
    };
};

export const getProductFromPartner = (
    productType: string,
    currentPartner: null | PartnerInterface,
) => {
    if (!currentPartner) return;
    return currentPartner.products.find((product) =>
        product.type.toLowerCase().includes(productType.toLowerCase()),
    );
};

export const convertStrToNum = (val?: string) => Number(normalizeStrWithNum(val));

export const normalizeStrWithNum = (val?: string) => val?.split(',').join('.').split(' ').join('');

export const isNaNChecking = (val?: string) => Number.isNaN(convertStrToNum(val));

export const isIntegerChecking = (val?: string) =>
    Number.isInteger(convertStrToNum(val)) && Boolean(val ? !/[.,]/.test(val) : false);

export const onlyNumChecking = (val?: string) => val !== '' && isIntegerChecking(val);

export const onlyPositiveNumChecking = (val?: string) =>
    isIntegerChecking(val) && convertStrToNum(val) > 0;

export const onlyNonNegativeNumChecking = (val?: string) =>
    val !== '' && isIntegerChecking(val) && convertStrToNum(val) >= 0;

export const onlyFloatChecking = (val?: string) => val !== '' && !isNaNChecking(val);

export const onlyPositiveFloatChecking = (val?: string) =>
    !!convertStrToNum(val) && convertStrToNum(val) > 0;

export const onlyNonNegativeFloatChecking = (val?: string) =>
    val !== '' && !isNaNChecking(val) && convertStrToNum(val) >= 0;

export const isResponseOK = (res?: number) => {
    return Boolean(
        res === StatusCodes.ACCEPTED ||
            res === StatusCodes.CREATED ||
            res === StatusCodes.OK ||
            res === StatusCodes.NO_CONTENT,
    );
};

export const getLabelForTaskStatus = (
    status: TaskStatusTypes,
    type: TaskProductTypes | null = TaskProductENUM.BANK_GUARANTEE,
) => {
    const statuses = getTaskStatuses(type);
    return statuses.find((item) => item.name === status)?.label || '';
};

export const strWithDateToIsoStr = (date?: string) => {
    if (!date) return;
    return new Date(date).toISOString();
};

export const getFirstDayOfMonth = (): Date => {
    const date = new Date();
    date.setDate(1);
    date.setUTCHours(0, 0);
    return date;
};

export const formatDateString = (date: Date): string => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const monthString = month < 10 ? `0${month}` : month;
    const dayString = day < 10 ? `0${day}` : day;
    return `${year}-${monthString}-${dayString}`;
};

export const getBankProductList = (partner: PartnerInterface) => {
    const bankProductList: string[] = [];
    partner.products
        .filter((bankProduct) => bankProduct.isActive)
        .forEach((bankProduct) => {
            bankProduct.lists.forEach((item) => {
                if (!bankProductList.includes(item.name)) {
                    bankProductList.push(item.name);
                }
            });
        });
    return bankProductList.length ? bankProductList : null;
};

export const formatTooltipString = (info: string) => {
    return info
        .split('\n')
        .map((line) => line.trim())
        .filter((line) => line !== '')
        .join('<br>');
};

export const getBankProductDescription = (partner: PartnerInterface) => {
    const activeProduct = partner.products
        .filter((bankProduct) => bankProduct.isActive)
        .find((bankProduct) => bankProduct.additInfo);

    return activeProduct?.additInfo || null;
};

export const normalizeCashbackStatus = (value: string) => {
    switch (value) {
        case 'paid':
            return 'Выплачено';
        case 'Выплачено':
            return 'paid';
        case 'accrued':
            return 'Начислено';
        case 'Начислено':
            return 'accrued';
        default:
            return '';
    }
};

export const normalizeTaskStatus = (value: TaskCloseReasonsTypes | '') => {
    switch (value) {
        case 'done':
            return 'В';
        case 'noOffers':
        case 'termsExpired':
        case 'clientRefused':
            return 'О';
        default:
            return '';
    }
};

export const getBankProductAcronym = (type: BankProductType | TaskProductType | '') => {
    if (!type) return '';
    switch (type) {
        case 'bankContractExecution':
        case 'commercialContractExecution':
        case 'contractExecution':
            return 'ИК';
        case 'bankGuaranteeObligation':
        case 'commercialGuaranteeObligation':
        case 'guaranteeObligation':
            return 'ГО';
        case 'bankAdvanceRefund':
        case 'commercialAdvanceRefund':
        case 'advanceRefund':
            return 'ВА';
        case 'applicationSecurity':
            return 'ОЗ';
        case 'loanUnderContract':
            return 'КИК';
        case 'loanRevolving':
            return 'КОБ';
        default:
            return '';
    }
};

export const getClosedOffer = (task?: ClientTaskInterface | null) => {
    const closedOffer = task?.offers?.find((offer) => offer.status === 'givenOut');
    return closedOffer;
};

export const getLinkForTask = (task: ClientTaskInterface) => {
    const taskId = task.taskId;
    const companyId = task.company.companyId;
    if (!(companyId && taskId)) return '';
    return `/clients/${companyId}/task/${taskId}`;
};

export const getLinkToClientCardFromTask = (task: ClientTaskInterface, user?: IUser | null) => {
    const companyId = task.company.companyId;
    const adminOrManager = user?.isAdmin || task.company.manager?.userId === user?.userId;

    if (!companyId || !adminOrManager) return '';

    return `/clients/card/${companyId}`;
};

export const getLinkToClientCardFromCompany = (company: CompanyInterface, user?: IUser | null) => {
    const companyId = company.companyId;
    const adminOrManager = user?.isAdmin || company.manager?.userId === user?.userId;

    if (!companyId || !adminOrManager) return '';

    return `/clients/card/${companyId}`;
};

export const getNormalizedSum = (sum: string) => {
    const sumNumberType = Number(sum);

    if (!sumNumberType) return '0,00';

    return sumNumberType.toFixed(2);
};

export const stringToNumber = (val?: string) =>
    val ? Number(val.split(',').join('.').split(' ').join('')) : 0;

export const getGivenOutOffer = (offers?: OfferWithBankProduct[]) =>
    offers?.find((offer) => offer.status === 'givenOut');

export const convertFieldToFloat = (
    fractionalLength: number,
    substitute: string,
    field?: string | null,
) => {
    if (field === null) return substitute;

    const numberFromField = Number(field);
    return Number.isNaN(numberFromField)
        ? substitute
        : formatNum(numberFromField.toFixed(fractionalLength));
};

export const getTooltipLength = ({
    textLength,
    minLetters = 23,
    letterWidth = 7,
    string,
}: {
    textLength?: number;
    minLetters?: number;
    letterWidth?: number;
    string?: string;
}) => {
    let lettersCount = 0;

    if (string && string.indexOf('<br>') >= 0) {
        const strArray = string.split('<br>');
        const sortedArray = strArray.sort((a, b) => b.length - a.length);
        lettersCount = sortedArray[0].length;
    } else if (textLength && textLength > minLetters) {
        lettersCount = textLength;
    } else {
        lettersCount = minLetters;
    }

    let tooltipLength = lettersCount * letterWidth + 20;
    tooltipLength += lettersCount < 20 ? 20 : 0;

    return tooltipLength < 800 ? tooltipLength : 800;
};

export const getLongestString = (list?: string[]) => {
    if (!list) {
        return 0;
    }

    let maxLength = 0;

    for (let i = 0; i < list.length; i++) {
        if (list[i].length > maxLength) {
            maxLength = list[i].length;
        }
    }

    return maxLength;
};

export const serializeSearchParams = (obj: any) => {
    const str: string[] = [];
    Object.getOwnPropertyNames(obj).forEach((key) => {
        if (key in obj && obj[key]) {
            const encodedKey = encodeURIComponent(key);
            const encodedValue = encodeURIComponent(obj[key]);
            str.push(`${encodedKey}=${encodedValue}`);
        }
    });

    return str.join('&');
};

export const getTaskNumber = (task?: ClientTaskInterface) => {
    return task?.taskId ? `${task.taskId} ${task.postscript ? `(${task.postscript})` : ''}` : '---';
};

export const getAmountInRub = (amount?: string | null, accuracy: number = 2) => {
    return formatNum(Number(amount || '').toFixed(accuracy));
};

export const decomposeClientTasks = (tasks: ClientTaskInterface[]) => {
    const result = tasks.reduce(
        (accum, current) => {
            if (current.type === TaskProductENUM.BANK_GUARANTEE) {
                if (current.closeDate) {
                    accum.counters.closedBankGuaranteeTasks++;
                } else {
                    accum.tasks.bankGuaranteeTasks.push(current);
                    accum.counters.activeBankGuaranteeTasks++;
                }
            }
            if (current.type === TaskProductENUM.LOAN) {
                if (current.closeDate) {
                    accum.counters.closedLoanTasks++;
                } else {
                    accum.tasks.loanTasks.push(current);
                    accum.counters.activeLoanTasks++;
                }
            }
            return accum;
        },
        {
            tasks: {
                loanTasks: [],
                bankGuaranteeTasks: [],
            } as ClientTasksType,
            counters: {
                activeLoanTasks: 0,
                activeBankGuaranteeTasks: 0,
                closedLoanTasks: 0,
                closedBankGuaranteeTasks: 0,
            },
        },
    );
    return result;
};

export const getTaskStatuses = (type: TaskProductTypes | null) => {
    return type === TaskProductENUM.BANK_GUARANTEE ? BankGuaranteeTaskStatuses : LoanTaskStatuses;
};

const getBankGuaranteeTerm = (currentTask: ClientTaskInterface) => {
    let term = formatNum(
        currentTask?.bankGuaranteeTermDays ? String(currentTask.bankGuaranteeTermDays || '') : '',
    );
    if (currentTask?.bankGuaranteeTermFrom && currentTask?.bankGuaranteeTermTo) {
        term ==
            ` (${getDate(currentTask.bankGuaranteeTermFrom, 'date')} - ${getDate(
                currentTask.bankGuaranteeTermTo,
                'date',
            )})`;
    }
    return `${term} д.`;
};

export const getTaskInfoField = (
    currentTask: ClientTaskInterface,
    field: BankGuaranteeTaskInfoFieldNames | LoanTaskInfoFieldNames,
) => {
    let amountUnit = '';
    let termMonthsUnit = '';
    if (field !== 'info' && currentTask?.[field]) {
        amountUnit = ' руб.';
        termMonthsUnit = ' мес.';
    }

    switch (field) {
        case 'bankGuaranteeType':
        case 'loanType':
            return getProductTitle(currentTask?.[field] || '');
        case 'federalLaw':
            return getLabelByLaw(currentTask?.[field] || null);
        case 'bankGuaranteeTermDays':
            return getBankGuaranteeTerm(currentTask);
        case 'contractConclusionDate':
            return getDate(currentTask?.[field] || null, 'date');
        case 'bankGuaranteeAmount':
        case 'contractAdvanceAvailability':
        case 'initialContractPrice':
        case 'loanAmount':
            return `${formatNum(String(currentTask?.[field] || ''))}${amountUnit}`;
        case 'loanTermMonths':
            return `${currentTask?.[field]}${termMonthsUnit}`;
        case 'info':
            return '';
        default:
            return currentTask?.[field] || '';
    }
};

export const getLabelByProductType = (type: string) => {
    switch (type) {
        case 'commercialContractExecution':
            return 'ИК';
        case 'commercialAdvanceRefund':
            return 'ВА';
        case 'commercialGuaranteeObligation':
            return 'ГО';
        case 'loanUnderContract':
            return 'КИК';
        case 'loanRevolving':
            return 'КОБ';
        default:
            return '';
    }
};

export const normalizeBankProductType = (value: string) => {
    switch (value) {
        case 'bankGuarantee':
        case 'БГ':
            return 'Банковская гарантия';
        case 'loan':
        case 'К':
            return 'Кредит';
        case 'Банковская гарантия':
            return 'bankGuarantee';
        case 'Кредит':
            return 'loan';
        default:
            return '';
    }
};

export const normalizeBankProductForTable = (value: string) => {
    switch (value) {
        case 'bankGuarantee':
        case 'Банковская гарантия':
        case 'БГ':
            return 'БГ';
        case 'loan':
        case 'Кредит':
        case 'К':
            return 'К';
        default:
            return '';
    }
};

export const getCredentialLkOperator = (credentials: PartnerCredential[], isLoan: boolean) => {
    const credentialsByType = credentials.filter((cred) => {
        if (isLoan) {
            return cred.type === TaskProductENUM.LOAN;
        }
        return cred.type === TaskProductENUM.BANK_GUARANTEE;
    });
    return credentialsByType[0]?.lkOperator || '---';
};

export const exportToExcel = (exportData: Record<string, string>[]) => {
    const ws = XLSX.utils.json_to_sheet(exportData);

    ws['!cols'] = [
        { width: 12 }, // Дата закр-я
        { width: 13 }, // Тип продукта
        { width: 10 }, // № заявки
        { width: 21 }, // Компания
        { width: 5 }, // Тип
        { width: 7 }, // ФЗ
        { width: 25 }, // Банк
        { width: 16 }, // ЛК
        { width: 16 }, // Сумма БГ/К
        { width: 22 }, // Комиссия банка
        { width: 22 }, // Базовое КВ
        { width: 17 }, // КВ Клиента
        { width: 17 }, // Доход по клиенту
        { width: 7 }, // Статус
        { width: 21 }, // Менеджер
        { width: 21 }, // Исполнитель
    ];

    const blueColumn = { font: { color: { rgb: '0038FF' } } }; // Синий цвет шрифта
    Object.keys(ws)
        .filter((cell) => cell.startsWith('C') || cell.startsWith('D'))
        .forEach((cell) => {
            ws[cell].s = blueColumn;
        });
    const greenColumn = { font: { color: { rgb: '00892F' } } }; // Зеленый цвет шрифта
    Object.keys(ws)
        .filter((cell) => cell.startsWith('M'))
        .forEach((cell) => {
            ws[cell].s = greenColumn;
        });

    const redColumn = { font: { color: { rgb: 'FF0000' } } }; // Красный цвет шрифта
    Object.keys(ws)
        .filter((cell) => cell.startsWith('L'))
        .forEach((cell) => {
            ws[cell].s = redColumn;
        });

    const headerCellStyle = { font: { bold: true } };
    Object.keys(ws)
        .filter((cell) => cell.match(/\d+/)?.[0] === '1')
        .forEach((cell) => {
            ws[cell].s = headerCellStyle;
        });

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: excelFileType });
    FileSaver.saveAs(data, `boomerang-export${excelFileExtension}`);
};

const formatCommision = (
    commissionType: offerCommissionFieldsType,
    offer: OfferInterface | null,
) => {
    if (!offer || offer[commissionType] === null || offer[`${commissionType}InRub`] === null)
        return '---';

    const percent = Number(offer[commissionType]);
    const rubles = Number(offer[`${commissionType}InRub`]);

    if (Number.isNaN(percent) || Number.isNaN(rubles)) return '---';
    const formatPercent = `${percent.toFixed(2)}%`;
    const formatRubles = formatNum(rubles.toFixed(2));
    return `${formatRubles} (${formatPercent})`;
};

export const generateExportData = (tasks: ClientTaskInterface[]) => {
    const exportData = tasks.map((task) => {
        const closedOffer = getClosedOffer(task) || null;
        const isLoan = task.type === TaskProductENUM.LOAN;
        const clientCommisionOffer = isLoan ? null : closedOffer;
        const productAmount = isLoan ? task.loanAmount : task.bankGuaranteeAmount;

        const closeDate = getDate(task.closeDate, 'date') || '---';
        const productType = isLoan ? 'К' : '';
        const taskNumber = getTaskNumber(task);
        const companyName = task.company.name || '---';
        const type = getBankProductAcronym(isLoan ? task.loanType : task.bankGuaranteeType);
        const federalLaw = task.federalLaw ? getLabelByLaw(task.federalLaw, true) : '---';
        const bankName = closedOffer?.bankProduct.bank.name || '---';
        const lkOperator = getCredentialLkOperator(
            closedOffer?.bankProduct.bank.credentials || [],
            isLoan,
        );
        const amount = formatNum(getNormalizedSum(productAmount || ''));

        const bankCommission = formatCommision(CommissionTypeENUM.BANK_COMMISSION, closedOffer);
        const baseCommission = formatCommision(CommissionTypeENUM.BASE_COMMISSION, closedOffer);
        const clientCommission = formatCommision(
            CommissionTypeENUM.CLIENT_COMMISSION,
            clientCommisionOffer,
        );
        const clientIncome = getClosedOffer(task)
            ? getAmountInRub(getClosedOffer(task)?.income)
            : '---';
        const status = getClosedOffer(task) ? 'В' : 'О';
        const manager = getUserName(task.company.manager);
        const executor = getUserName(task.executor);

        return {
            'Дата закр-я ': closeDate,
            'Тип продукта ': productType,
            '№ заявки ': taskNumber,
            'Компания ': companyName,
            'Тип ': type,
            'ФЗ ': federalLaw,
            'Банк ': bankName,
            'ЛК ': lkOperator,
            'Сумма БГ/К': amount,
            'Комиссия банка': bankCommission,
            'Базовое КВ': baseCommission,
            'КВ Клиента': clientCommission,
            'Доход по клиенту': clientIncome,
            'Статус ': status,
            'Менеджер ': manager,
            'Исполнитель ': executor,
        };
    });
    return exportData;
};

export const formatTimeInSeconds = (seconds: number) => {
    const date = new Date(0);
    date.setSeconds(seconds || 0);
    return date.toISOString().substring(11, 19);
};

export const getRecordFileName = (call: CallsRecordInterface) => {
    const date = new Date(call.startedAt);
    const dateStr = `${date.getFullYear()}_${date.getMonth() + 1}_${date.getDate()}`;
    const phone = call.phone || '';
    const managerPhone = call.telnum || '';
    const direction = call.direction || '';
    return `${managerPhone}_${direction}_${dateStr}_${phone}.mp3`;
};

export const getNameInitials = (value: string) => {
    const [name, surname, lastname] = value.split(' ');
    return `${name} ${surname ? `${surname[0]}.` : ''} ${lastname ? `${lastname[0]}.` : ''}`;
};

export const getInterestsByCompany = (company?: CompanyInterface) => {
    if (!company) return [];

    const res: string[] = [];

    blocks.forEach(({ value }) => {
        if (company[value]) {
            res.push(value);
        }
    });

    return res;
};
