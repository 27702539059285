import styled from 'styled-components/macro';

const StyledContainer = styled.div`
    .edit-actions__buttons {
        display: flex;
        flex-direction: column;
        button {
            margin: 20px 0;
        }
    }
`;

export default StyledContainer;
