import { IUser, UserDocInterface } from './../../../utils/types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
    CreateUserType,
    GetAllUsersType,
    GetOneUserType,
    UpdateUserType,
} from './../../../api/userApi/userApi.types';
import { getError } from '../../../utils/functions';
import { RootState } from '../..';
import { InitialStateType } from './types';
import { userAPI } from '../../../api/userApi/userApi';

export const getOneUser = createAsyncThunk<
    IUser,
    GetOneUserType,
    {
        rejectValue: string;
    }
>('staff/getOneUser', async ({ ...props }, { rejectWithValue }) => {
    try {
        const res = await userAPI.getOneUser({ ...props });
        if (res?.message === 'Success') {
            return res.data;
        } else {
            throw res?.data;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const getAllUsers = createAsyncThunk<
    IUser[],
    GetAllUsersType,
    {
        rejectValue: string;
    }
>('staff/getAllUsers', async ({ ...props }, { rejectWithValue }) => {
    try {
        const res = await userAPI.getAllUsers({ ...props });
        if (res?.message === 'Success') {
            return res.data;
        } else {
            throw res?.data;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const createUser = createAsyncThunk<
    IUser,
    CreateUserType,
    {
        rejectValue: string;
    }
>('staff/createUser', async ({ ...props }, { rejectWithValue, dispatch }) => {
    try {
        const res = await userAPI.createUser({ ...props });
        if (res?.message === 'Success' || res?.message === 'User created') {
            dispatch(getAllUsers({ onlyActiveUsers: true }));
            return res.data;
        } else {
            throw res?.data;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const updateUser = createAsyncThunk<
    IUser,
    UpdateUserType,
    {
        rejectValue: string;
    }
>('staff/updateUser', async ({ ...props }, { rejectWithValue, dispatch }) => {
    try {
        const res = await userAPI.updateUser({ ...props });
        if (res?.message === 'Success') {
            dispatch(getAllUsers({ onlyActiveUsers: false }));
            return res.data;
        } else {
            throw res?.data;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const getUserDocuments = createAsyncThunk<
    UserDocInterface[],
    GetOneUserType,
    {
        rejectValue: string;
    }
>('staff/getUserDocuments', async ({ ...props }, { rejectWithValue }) => {
    try {
        const res = await userAPI.getUserDocuments({ ...props });
        if (res?.message === 'Success') {
            return res.data;
        } else {
            throw res?.data;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

const initialState: InitialStateType = {
    allUsers: [],
    selectedUser: null,
    selectedUserDocs: [],
    isRequestFulfilled: {
        createUser: false,
        updateUser: false,
    },
    loading: {
        createUser: false,
        updateUser: false,
    },
    error: '',
};

export const staffSlice = createSlice({
    name: 'staff',
    initialState,
    reducers: {
        clearStaffState: (state) => {
            state = initialState;
            return state;
        },
        setSelectedUserDocs: (state, { payload }) => {
            state.selectedUserDocs = payload;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getOneUser.fulfilled, (state, { payload }) => {
            state.selectedUser = payload;
        });
        builder.addCase(getOneUser.rejected, (state, { payload }) => {
            state.error = payload as string;
        });
        builder.addCase(getAllUsers.fulfilled, (state, { payload }) => {
            state.allUsers = payload;
        });
        builder.addCase(getAllUsers.rejected, (state, { payload }) => {
            state.error = payload as string;
        });
        builder.addCase(createUser.pending, (state) => {
            state.loading.createUser = true;
            state.isRequestFulfilled.createUser = false;
        });
        builder.addCase(createUser.fulfilled, (state) => {
            state.loading.createUser = false;
            state.isRequestFulfilled.createUser = true;
        });
        builder.addCase(createUser.rejected, (state, { payload }) => {
            state.loading.createUser = false;
            state.error = payload as string;
        });
        builder.addCase(updateUser.pending, (state) => {
            state.loading.updateUser = true;
            state.isRequestFulfilled.updateUser = false;
        });
        builder.addCase(updateUser.fulfilled, (state) => {
            state.loading.updateUser = false;
            state.isRequestFulfilled.updateUser = true;
        });
        builder.addCase(updateUser.rejected, (state, { payload }) => {
            state.loading.updateUser = false;
            state.error = payload as string;
        });
        builder.addCase(getUserDocuments.fulfilled, (state, { payload }) => {
            state.selectedUserDocs = payload;
        });
        builder.addCase(getUserDocuments.rejected, (state, { payload }) => {
            state.error = payload as string;
        });
    },
});

export const { clearStaffState, setSelectedUserDocs } = staffSlice.actions;

export const selectStaff = (state: RootState) => state.staff;
