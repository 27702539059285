import React from 'react';

import GearsIcon from '../../../../assets/images/clients/gears-icon.png';
import StyledContainer from './CredentialsItem.style';
import { normalizeBankProductForTable } from '../../../../utils/functions';
import { CredentialsWithError } from '../../../../utils/types';
import CredentialsItemContact from '../CredentialsItemContacts/CredentialsItemContacts';

type CredentialsItemProps = {
    cred: CredentialsWithError;
    credIndex: number;
    // eslint-disable-next-line no-unused-vars
    setEditingCredentials: (value: number) => void;
    disabled: boolean;
};

const CredentialsItem = ({
    cred,
    credIndex,
    setEditingCredentials,
    disabled,
}: CredentialsItemProps) => {
    const productType = normalizeBankProductForTable(cred.type);
    return (
        <StyledContainer>
            <div key={`cred - ${credIndex}`} className="credentials-item__table-row">
                <p>{productType}</p>
                <p>{cred.lkOperator}</p>
                <a target="_blank" rel="noopener noreferrer" href={cred.lkLink}>
                    {cred.lkLink}
                </a>
                <p>{cred.login}</p>
                <p>{cred.password}</p>
                <div>
                    {cred.contacts.map((contact, contactIndex) => (
                        <CredentialsItemContact
                            key={contact.bankCredentialContactId}
                            contact={contact}
                            contactIndex={contactIndex}
                            credIndex={credIndex}
                        />
                    ))}
                </div>
                {!disabled && (
                    <img src={GearsIcon} onClick={() => setEditingCredentials(credIndex)} />
                )}
            </div>
        </StyledContainer>
    );
};

export default CredentialsItem;
