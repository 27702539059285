import styled from 'styled-components/macro';

const StyledContainer = styled.div`
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .client-is-added__inn-number {
        line-height: 14px;
        padding: 0;
        margin: 3px 0 5px;
        font-weight: 510;
    }

    .client-is-added__client-name {
        font-weight: 510;
    }

    a {
        font-weight: 500;
        text-decoration: underline;
        line-height: 14px;
        color: #0038ff;
        cursor: pointer;
        margin: 29px 0 52px;
    }
`;

export default StyledContainer;
