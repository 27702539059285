import React, { useMemo } from 'react';
import clsx from 'clsx';

import { additionalMarks, AdditionalMarkType } from '../../../../utils/data';
import { CompanyInterface } from '../../../../utils/types';
import {
    formatNum,
    getDate,
    getImportanceLabelByName,
    getInterestsByCompany,
    getNameInitials,
    getNormalizedSum,
    getTooltipLength,
} from '../../../../utils/functions';
import ClientTime from '../../../../components/Common/ClientTime/ClientTime';
import CalendarIcon from '../../../../assets/images/clients/calendar-icon.png';
import DollarIcon from '../../../../assets/images/clients/dollar-icon.png';
import GreenDollarIcon from '../../../../assets/images/clients/green-dollar-icon.png';
import TooltipReminder from '../../../../components/Common/TooltipReminder/TooltipReminder';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../../store/slices/userSlice';
import InterestingsPicker from '../../../../components/InterestingsPicker/InterestingsPicker';

type ClientsTableRowProps = {
    client: CompanyInterface;
    index: number;
    setSelectedClient: React.Dispatch<React.SetStateAction<CompanyInterface | null>>;
    setManagerOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setDateOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ClientsTableRow = ({
    client,
    index,
    setSelectedClient,
    setManagerOpen,
    setDateOpen,
}: ClientsTableRowProps) => {
    const { user } = useSelector(userSelector);
    const getMark = (mark: AdditionalMarkType, company: CompanyInterface) => {
        if (mark.fieldInCompany === 'reminders') {
            return (
                <TooltipReminder
                    text={company?.reminders[0]?.text || ''}
                    time={company?.reminders[0]?.time || ''}
                    hasReminder={Boolean(company?.reminders?.length)}
                    rightMargin={35}
                    key={mark.name + company.companyId}
                    maxWidth={800}
                    minWidth={getTooltipLength({
                        textLength: company?.reminders?.[0]?.text?.length,
                    })}
                >
                    <img
                        src={company[mark.fieldInCompany]?.length ? mark.selectedIcon : mark.icon}
                    />
                </TooltipReminder>
            );
        }
        return (
            <img
                key={mark.name + company.companyId}
                src={company[mark.fieldInCompany] ? mark.selectedIcon : mark.icon}
            />
        );
    };

    const rowClassName = useMemo(
        () =>
            clsx('clients-table__client', {
                'clients-table__even-field': index % 2 !== 0,
                'clients-table__highlighted-field': client.isHighlighted,
                'clients-table__client--credit': client.isCredit,
            }),
        [client.isHighlighted, client.isCredit, index],
    );
    const contactDate = useMemo(
        () => getDate(client.contactDate, 'date with time'),
        [client.contactDate],
    );
    const lastCommentDate = useMemo(
        () => getDate(client.lastCommentDate, 'date'),
        [client.lastCommentDate],
    );
    const income = useMemo(() => {
        return {
            className: client.income ? 'clients-table__green-row' : 'clients-table__income-row',
            value: formatNum(getNormalizedSum(client.income?.toFixed(4) || '')) || '0,00',
        };
    }, [client.income]);
    const importance = useMemo(() => {
        return {
            className: clsx('clients-table__importance', {
                'clients-table__low-importance': client.importance === 'low',
                'clients-table__medium-importance': client.importance === 'medium',
                'clients-table__high-importance': client.importance === 'high',
            }),
            value: getImportanceLabelByName(client.importance),
        };
    }, [client.importance]);
    const managerName =
        client?.manager?.fullName ||
        client?.manager?.shortName ||
        client?.manager?.login ||
        client?.manager?.email ||
        '';
    const clientInterests = useMemo(() => getInterestsByCompany(client), [client]);

    const handleDateChange = (client: CompanyInterface) => {
        setDateOpen(true);
        setSelectedClient(client);
    };

    const handleManagerChange = (client: CompanyInterface) => {
        if (!user?.isAdmin) return;

        setManagerOpen(true);
        setSelectedClient(client);
    };
    return (
        <div className={rowClassName}>
            <p>{contactDate}</p>
            <div>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`/clients/card/${client.companyId}?tab=clientCard`}
                    className="clients-table__company-name"
                >
                    {client.name}
                </a>
                <p>{client.inn}</p>
            </div>
            <div>
                <p>{client.region.nameWithType}</p>
                <p>{client.city}</p>
            </div>
            <ClientTime timezone={client.region.timezone} />
            <p>{lastCommentDate}</p>
            <div className={income.className}>{income.value}</div>
            <div className="clients-table__bank-guarantee">
                <p>
                    {client.doneTasks || 0}/{client.allTasks || 0}
                </p>
                <img src={client.doneTasks ? GreenDollarIcon : DollarIcon} />
            </div>
            <p className={importance.className}>{importance.value}</p>
            <p onClick={() => handleManagerChange(client)}>{getNameInitials(managerName)}</p>
            <InterestingsPicker gap={2} value={clientInterests} />
            <div className="clients-table__marks">
                {additionalMarks.map((mark) => getMark(mark, client))}
            </div>
            <img
                onClick={() => handleDateChange(client)}
                className="clients-table__next-contact"
                src={CalendarIcon}
            />
        </div>
    );
};

export default ClientsTableRow;
