import * as Yup from 'yup';

import {
    onlyPositiveFloatChecking,
    onlyPositiveNumChecking,
    onlyNonNegativeNumChecking,
} from '../utils/functions';

export const AddPartnerValidationSchema = Yup.object().shape({
    name: Yup.string().required('Заполните поле имя'),
    logo: Yup.string(),
    additInfo: Yup.string(),
    isDisplayedInList: Yup.boolean(),
    isPriority: Yup.boolean(),
});

export const AddBankProductFloatFields = [
    'limit',
    'rateFrom',
    'baseCommission',
    'clientCommission',
    'issuanceCommission',
];

const BaseProductValidationShape = {
    limit: Yup.string()
        .required('Заполните поле Лимит')
        .test('num', 'Лимит должен быть положительным числом', onlyPositiveFloatChecking),
    rateFrom: Yup.string()
        .required('Заполните поле Ставка')
        .test('num', 'Ставка должна быть положительным числом', onlyPositiveFloatChecking),
    term: Yup.string()
        .required('Заполните поле Срок')
        .test('num', 'Срок должен быть положительным целым числом', onlyPositiveNumChecking),
    worksWithIP: Yup.boolean().required(),
    baseCommission: Yup.string()
        .required('Заполните поле БКВ')
        .test('num', 'БКВ должна быть положительным числом', onlyPositiveFloatChecking),
    additInfo: Yup.string(),
    documents: Yup.array().of(
        Yup.object().shape({
            file: Yup.string(),
            name: Yup.string(),
        }),
    ),
};

export const AddBankProductValidationSchema = Yup.object().shape({
    ...BaseProductValidationShape,
    hasFZ44: Yup.boolean().required(),
    hasFZ223: Yup.boolean().required(),
    hasPP615: Yup.boolean().required(),
    experience: Yup.string()
        .required('Заполните поле Опыт исполненных контрактов')
        .test(
            'num',
            'Опыт исполненных контрактов должен быть неотрицательным целым числом',
            onlyNonNegativeNumChecking,
        ),
    age: Yup.string()
        .required('Заполните поле Возраст организации')
        .test(
            'num',
            'Возраст организации должен быть неотрицательным целым числом',
            onlyNonNegativeNumChecking,
        ),
    worksWithAO: Yup.boolean().required(),
    clientCommission: Yup.string()
        .required('Заполните поле КВК')
        .test('num', 'КВК должна быть положительным числом', onlyPositiveFloatChecking),
});

export const AddBankLoanProductValidationSchema = Yup.object().shape({
    ...BaseProductValidationShape,
    registrationTerm: Yup.string()
        .required('Заполните поле Регистрация')
        .test('num', 'Регистрация должна быть положительным целым числом', onlyPositiveNumChecking),
    issuanceCommission: Yup.string()
        .required('Заполните поле КЗВ')
        .test('num', 'КЗВ должна быть положительным числом', onlyPositiveFloatChecking),
});

export const addCredentialsValidationSchema = Yup.object().shape({
    type: Yup.string().required(),
    lkLink: Yup.string().required(),
    lkOperator: Yup.string().required(),
    login: Yup.string().required(),
    password: Yup.string().required(),
});
