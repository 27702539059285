import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../store';
import { closeTaskValidationSchema } from '../../validation/clientTask';
import { selectClientTask, updateTask } from '../../store/slices/clientTaskSlice';
import { TaskCloseReasons } from '../../utils/data';
import { ClientTaskInterface } from '../../utils/types';
import CommonModal from '../Common/CommonModal/CommonModal';
import CustomSelect from '../Common/CustomSelect/CustomSelect';
import BulletListIcon from '../../assets/images/bullet-list-icon.png';
import BtnLoader from '../Common/BtnLoader/BtnLoader';
import { StyledFormWrapper, StyledContainer } from './CloseBankTask.style';

type CloseBankTaskProps = {
    close: () => void;
    width: number;
    companyId: number;
    task: ClientTaskInterface;
};

type Inputs = {
    closeReason: string;
    closeComment: string;
};

const CloseBankTask = ({ close, width, companyId, task }: CloseBankTaskProps) => {
    const dispatch = useAppDispatch();
    const firstUpdate = useRef(true);
    const { loading, isRequestFulfilled } = useSelector(selectClientTask);

    const formOptions = {
        resolver: yupResolver(closeTaskValidationSchema),
    };
    const { register, handleSubmit, formState } = useForm<Inputs>(formOptions);
    const { errors } = formState;

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const { closeReason, closeComment } = data;
        dispatch(
            updateTask({
                type: task.type,
                companyId,
                taskId: task.taskId,
                closeReason,
                closeComment,
            }),
        );
    };

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if (isRequestFulfilled.updateTask) {
            close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRequestFulfilled.updateTask]);

    return (
        <CommonModal hasCloseBtn={true} close={close}>
            <StyledFormWrapper width={width}>
                <StyledContainer>
                    <h3 className="add-product__title">Завершить сделку досрочно</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <label className="add-bank-guarantee__label">
                            Причина завершения сделки досрочно
                            <span className="add-bank-guarantee__sign-of-obligatory-field">*</span>:
                            <CustomSelect width={451} height={24} backgroundImage={BulletListIcon}>
                                <select
                                    id="closeReason"
                                    {...register('closeReason')}
                                    className={clsx({
                                        'add-bank-guarantee__error-input':
                                            errors['closeReason']?.message,
                                    })}
                                >
                                    {TaskCloseReasons.map((item) => (
                                        <option key={item.name} value={item.name}>
                                            {item.label}
                                        </option>
                                    ))}
                                </select>
                            </CustomSelect>
                        </label>
                        <label className="add-bank-guarantee__label">
                            Причина завершения сделки досрочно:
                            <textarea
                                className={clsx('add-bank-guarantee__comment', {
                                    'add-bank-guarantee__error-input':
                                        errors['closeComment']?.message,
                                })}
                                id="closeComment"
                                {...register('closeComment')}
                            />
                        </label>
                        <BtnLoader
                            isLoading={loading.updateTask}
                            btnTitle="Сохранить"
                            btnClass={clsx('add-bank-guarantee__btn', {
                                'add-bank-guarantee__disabled-btn': loading.updateTask,
                            })}
                        />
                    </form>
                </StyledContainer>
            </StyledFormWrapper>
        </CommonModal>
    );
};

export default CloseBankTask;
