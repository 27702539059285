import styled from 'styled-components/macro';

const StyledClientDocs = styled.div`
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 30px;

    button {
        text-decoration: underline;
        color: #0038ff;
        background: transparent;
        border: none;
    }

    .task-docs__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px;
        background: #e7e7e7;
    }

    .task-docs__table-head {
        font-weight: 600;
        display: grid;
        grid-template-columns: 120px 1fr 35px;
        grid-column-gap: 20px;
        padding: 8px;
    }

    .task-docs__doc-row {
        display: grid;
        grid-template-columns: 120px 1fr 100px;
        grid-column-gap: 20px;
        padding: 8px;

        p {
            line-height: 14px;
        }
    }

    .task-docs__even-field {
        background: #f8f8f8;
    }

    .task-docs__actions {
        display: flex;
        align-items: center;

        img {
            margin-left: 13px;
            cursor: pointer;
        }
    }
`;

export default StyledClientDocs;
