import React, { useEffect, useState } from 'react';

import CheckINN from './CheckINN/CheckINN';
import INNisOccupied from './INNisOccupied/INNisOccupied';
import AddClientsInfo from './AddClientsInfo/AddClientsInfo';
import ClientIsAdded from './ClientIsAdded/ClientIsAdded';
import CommonModal from '../Common/CommonModal/CommonModal';
import { useAppDispatch } from '../../store';
import { setAddedClient, clearCheckInnResult } from '../../store/slices/clientDatabaseSlice';
import StyledFormWrapper from './AddClient.style';

type AddClientProps = {
    close: () => void;
    width: number;
};

const AddClient = ({ close, width }: AddClientProps) => {
    const dispatch = useAppDispatch();
    const [step, setStep] = useState(1);
    const [inn, setInn] = useState('');

    const getPageByStep = (step: number) => {
        switch (step) {
            case 0:
                return <INNisOccupied previousStep={() => setStep(1)} />;
            case 1:
                return <CheckINN setStep={setStep} setInn={setInn} />;
            case 2:
                return <AddClientsInfo nextStep={() => setStep(3)} inn={inn} />;
            case 3:
                return <ClientIsAdded />;
            default:
                break;
        }
    };

    useEffect(() => {
        dispatch(setAddedClient({}));
        dispatch(clearCheckInnResult());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (step === 1) dispatch(clearCheckInnResult());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    return (
        <CommonModal hasCloseBtn={true} close={close}>
            <StyledFormWrapper width={width}>{getPageByStep(step)}</StyledFormWrapper>
        </CommonModal>
    );
};

export default AddClient;
