import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getError } from './../../../utils/functions';
import { RootState } from '../..';
import { IUser } from '../../../utils/types';
import { authMe, setUser } from '../userSlice';

export const initializeApp = createAsyncThunk<
    void,
    void,
    {
        rejectValue: string;
    }
>('app/initializeApp', async (_, { rejectWithValue, dispatch }) => {
    try {
        const user = await dispatch(authMe());
        if (user?.payload) {
            dispatch(setUser(user.payload as IUser));
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const appSlice = createSlice({
    name: 'app',
    initialState: {
        initialized: false,
        error: '',
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(initializeApp.fulfilled, (state) => {
            state.initialized = true;
            state.error = '';
        });
        builder.addCase(initializeApp.rejected, (state, { payload }) => {
            state.initialized = true;
            state.error = payload as string;
        });
    },
});

export const appSelector = (state: RootState) => state.app;
