import React from 'react';
import clsx from 'clsx';

import { getClosedOffer } from '../../../../utils/functions';
import { ClientTaskInterface } from '../../../../utils/types';
import StyledCell from './ClosedRequestsStatus.style';

type ClosedRequestsStatusProps = {
    task: ClientTaskInterface;
};

const ClosedRequestsStatus = ({ task }: ClosedRequestsStatusProps) => {
    return (
        <StyledCell>
            <p
                className={clsx({
                    'close-requests-status__green-field': getClosedOffer(task),
                    'close-requests-status__red-field': !getClosedOffer(task),
                })}
            >
                {getClosedOffer(task) ? 'В' : 'О'}
            </p>
        </StyledCell>
    );
};

export default ClosedRequestsStatus;
