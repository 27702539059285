import styled from 'styled-components/macro';

const StyledOfferRequest = styled.div`
    .closed-offer-request__header {
        font-weight: 600;
        font-size: 12px;
        border-top: 1px solid #000;
        padding: 5px 0 15px;
    }
`;

export default StyledOfferRequest;
