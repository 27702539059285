import styled from 'styled-components/macro';

const StyledFilterWrapper = styled.div`
    display: flex;
    padding: 5px 0;

    .filter__container {
        display: flex;
        align-items: center;
        font-weight: 500;
        margin-left: 14px;
        p {
            width: 100px;
            margin-right: 10px;
        }
    }
    .filter__picker-root {
        height: 20px;
        input {
            width: 71px;
            border: 0.5px solid #000 !important;
            border-radius: 5px;
            padding: 2px 0 0;
            font-size: 12px;
            text-align: center;
        }
        fieldset {
            border: none;
        }
    }
`;

export default StyledFilterWrapper;
