import styled from 'styled-components/macro';

const StyledTaskDocumentSection = styled.div`
    font-weight: 500;
    font-size: 12px;
    margin: 2px 0;

    .base-documents__header {
        height: 30px;
        padding: 5px 6px;
        background: #fdd55b;
        display: flex;
        align-items: center;
    }
`;

export default StyledTaskDocumentSection;
