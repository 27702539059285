import styled from 'styled-components/macro';

const StyledFilterWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 30px;

    .radio-button-filter__radio-btn {
        display: flex;
        align-items: center;
        height: 30px;

        input {
            margin: 0;
            cursor: pointer;
        }

        label {
            cursor: auto;
        }
    }

    .radio-button-filter__filter-description {
        font-weight: 600;
        margin-left: 7px;
    }

    .radio-button-filter__btn-label {
        margin: 1px 10px 0 20px;
        font-style: italic;
    }
`;

export default StyledFilterWrapper;
