import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import 'react-phone-input-2/lib/style.css';

import { useAppDispatch } from '../../../store';
import {
    emptyEmailWithErrorField,
    emptyPhoneWithErrorField,
    fieldsInAddPCredentialsForm,
} from '../../../utils/data';
import {
    PartnerContactWithError,
    CredentialsWithError,
    EmailWithAdditInfoType,
    PhoneWithAdditInfoType,
} from '../../../utils/types';
import {
    checkEmailArray,
    checkPhonesArray,
    doesEmailArraysDiffer,
    doesPhoneArraysDiffer,
    hasEmailOrPhoneError,
    isObjectEmpty,
    normalizeBankProductType,
    removeIsErrorFieldFromContact,
} from '../../../utils/functions';
import {
    createBankCredential,
    createCredentialContact,
    deleteCredentialContact,
    selectPartner,
    updateBankCredential,
    updateCredentialContact,
} from '../../../store/slices/partnerSlice';
import { addCredentialsValidationSchema } from '../../../validation/partners';
import CommonModal from '../../Common/CommonModal/CommonModal';
import AddPartnerContact from '../AddCredentials/AddPartnerContact/AddPartnerContact';
import StyledScrollbars from '../../Common/StyledScrollbar/StyledScrollbar';
import StyledFormWrapper from './AddCredentials.style';
import AddCredentialsGetInputField from './AddCredentialsGetInputField/AddCredentialsGetInputField';

type AddCredentialsProps = {
    close: () => void;
    width: number;
    credentials: CredentialsWithError | null;
    // eslint-disable-next-line no-unused-vars
    setCredentials: (credentials: CredentialsWithError) => void;
};

export type AddCredentialsInputs = {
    type: string;
    lkLink: string;
    lkOperator: string;
    login: string;
    password: string;
};

const AddCredentials = ({ close, width, credentials, setCredentials }: AddCredentialsProps) => {
    const dispatch = useAppDispatch();
    const [contacts, setContacts] = useState<PartnerContactWithError[]>([]);
    const { currentPartner } = useSelector(selectPartner);

    const formOptions = {
        defaultValues: {
            type: normalizeBankProductType(credentials?.type || ''),
            lkLink: credentials?.lkLink || '',
            lkOperator: credentials?.lkOperator || '',
            login: credentials?.login || '',
            password: credentials?.password || '',
        },
        resolver: yupResolver(addCredentialsValidationSchema),
    };
    const { register, handleSubmit, formState } = useForm<AddCredentialsInputs>(formOptions);
    const { errors } = formState;

    const btnName = credentials ? 'Обновить контакт' : 'Добавить контакт';
    const windowHeight = 380 + contacts.length * 183;
    const header = `${credentials ? 'Редактировать' : 'Добавить'} данные для аккаунта`;

    const checkPhonesAndEmails = () => {
        let hasPhoneError = false;
        let hasEmailError = false;
        const newContacts = contacts.map((contact) => {
            const newPhoneNumbers = checkPhonesArray(contact.phoneNumbers);
            const newEmails = checkEmailArray(contact.emails);

            if (!hasPhoneError && hasEmailOrPhoneError(newPhoneNumbers)) {
                hasPhoneError = true;
            }

            if (!hasEmailError && hasEmailOrPhoneError(newEmails)) {
                hasEmailError = true;
            }

            return {
                ...contact,
                phoneNumbers: newPhoneNumbers,
                emails: newEmails,
            };
        });
        setContacts(newContacts);

        if (hasPhoneError) {
            toast.error('Телефон заполнен некорректно');
        }

        if (hasEmailError) {
            toast.error('Имейл заполнен некорректно');
        }

        return hasEmailError || hasPhoneError;
    };

    const doesContactExist = (contactId: number) => {
        let contactExist = false;
        contacts.forEach((contact) => {
            if (contact?.bankCredentialContactId === contactId) {
                contactExist = true;
            }
        });
        return contactExist;
    };

    const onSubmit: SubmitHandler<AddCredentialsInputs> = (data) => {
        if (checkPhonesAndEmails()) return;
        const normalizedType = normalizeBankProductType(data.type);

        if (credentials?.bankCredentialId && currentPartner) {
            const contactsForUpdating = contacts.filter(
                (contact) => contact?.bankCredentialContactId,
            );

            const contactsForCreating = contacts.filter(
                (contact) => !contact?.bankCredentialContactId,
            );
            const oldContacts =
                currentPartner?.credentials?.find(
                    (cred) => cred?.bankCredentialId === credentials.bankCredentialId,
                )?.contacts || [];

            if (oldContacts.length) {
                const contactsForDeleting = oldContacts.filter(
                    (contact) =>
                        contact?.bankCredentialContactId &&
                        !doesContactExist(contact.bankCredentialContactId),
                );
                contactsForDeleting.forEach((contact) => {
                    if (!contact.bankCredentialContactId) return;

                    dispatch(
                        deleteCredentialContact({
                            bankId: currentPartner.bankId,
                            credentialId: Number(credentials.bankCredentialId),
                            contactId: contact.bankCredentialContactId,
                        }),
                    );
                });
            }

            contactsForCreating.forEach((contact) => {
                const emails = removeIsErrorFieldFromContact(contact.emails);
                const phoneNumbers = removeIsErrorFieldFromContact(contact.phoneNumbers);
                dispatch(
                    createCredentialContact({
                        bankId: currentPartner.bankId,
                        credentialId: Number(credentials.bankCredentialId),
                        name: contact.name,
                        emails: emails as EmailWithAdditInfoType[],
                        phoneNumbers: phoneNumbers as PhoneWithAdditInfoType[],
                    }),
                );
            });
            contactsForUpdating.forEach((contact) => {
                if (!contact.bankCredentialContactId) return;

                const currentContact = oldContacts.find(
                    (item) => item.bankCredentialContactId === contact.bankCredentialContactId,
                );
                const emailsWithoutIsErrorField = removeIsErrorFieldFromContact(
                    contact.emails,
                ) as EmailWithAdditInfoType[];
                const phonesWithoutIsErrorField = removeIsErrorFieldFromContact(
                    contact.phoneNumbers,
                ) as PhoneWithAdditInfoType[];

                const emails = doesEmailArraysDiffer(
                    emailsWithoutIsErrorField,
                    currentContact?.emails || [],
                )
                    ? emailsWithoutIsErrorField
                    : undefined;

                const phoneNumbers = doesPhoneArraysDiffer(
                    phonesWithoutIsErrorField,
                    currentContact?.phoneNumbers || [],
                )
                    ? phonesWithoutIsErrorField
                    : undefined;

                const updatedContactOptions = {
                    name: currentContact?.name !== contact.name ? contact.name : undefined,
                    emails,
                    phoneNumbers,
                };

                if (isObjectEmpty(updatedContactOptions)) return;
                dispatch(
                    updateCredentialContact({
                        bankId: currentPartner.bankId,
                        credentialId: Number(credentials.bankCredentialId),
                        contactId: contact.bankCredentialContactId,
                        ...updatedContactOptions,
                    }),
                );
            });
            const updateCredentialOptions = {
                lkOperator:
                    data.lkOperator !== credentials.lkOperator ? data.lkOperator : undefined,
                type: normalizedType !== credentials.type ? normalizedType : undefined,
                lkLink: data.lkLink !== credentials.lkLink ? data.lkLink : undefined,
                login: data.login !== credentials.login ? data.login : undefined,
                password: data.password !== credentials.password ? data.password : undefined,
            };

            if (isObjectEmpty(updateCredentialOptions)) {
                return close();
            }

            dispatch(
                updateBankCredential({
                    bankId: currentPartner.bankId,
                    credentialId: credentials.bankCredentialId,
                    ...updateCredentialOptions,
                }),
            );
        } else if (currentPartner) {
            const normalizedContacts = contacts.map((contact) => {
                const emails = removeIsErrorFieldFromContact(contact.emails).filter(
                    (item) => 'email' in item && item.email,
                );
                const phoneNumbers = removeIsErrorFieldFromContact(contact.phoneNumbers).filter(
                    (item) => 'phoneNumber' in item && item.phoneNumber,
                );
                return {
                    name: contact.name,
                    emails: emails as EmailWithAdditInfoType[],
                    phoneNumbers: phoneNumbers as PhoneWithAdditInfoType[],
                };
            });
            dispatch(
                createBankCredential({
                    ...data,
                    type: normalizedType,
                    contacts: normalizedContacts,
                    bankId: currentPartner.bankId,
                }),
            );
        } else {
            setCredentials({ ...data, contacts, type: normalizedType });
        }
        close();
    };

    const addContact = (value: PartnerContactWithError, index: number) => {
        const newContacts = [...contacts];
        newContacts[index] = value;
        setContacts(newContacts);
    };

    const deleteContact = (index: number) => {
        const newContacts = [...contacts];
        newContacts.splice(index, 1);
        setContacts(newContacts);
    };

    const addEmptyContactBtnHandler = () => {
        setContacts([
            ...contacts,
            {
                name: '',
                emails: [{ ...emptyEmailWithErrorField }],
                phoneNumbers: [{ ...emptyPhoneWithErrorField }],
            },
        ]);
    };

    useEffect(() => {
        if (!credentials) return;

        setContacts(credentials.contacts);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [credentials]);

    return (
        <CommonModal hasCloseBtn={true} close={close}>
            <StyledFormWrapper
                width={width}
                height={windowHeight}
                onSubmit={handleSubmit(onSubmit)}
            >
                <h3>{header}</h3>
                <StyledScrollbars
                    style={{ height: 'calc(100% - 30px)' }}
                    renderTrackHorizontal={(props) => (
                        <div {...props} style={{ display: 'none' }} />
                    )}
                    thumbSize={170}
                    thumbMinSize={30}
                >
                    <div className="add-credentials__scroll-container">
                        {fieldsInAddPCredentialsForm.map((field) => (
                            <div key={field.fieldName} className="add-credentials__label">
                                <div>
                                    {field.label}
                                    {field.isRequired && (
                                        <span className="add-credentials__sign-of-obligatory-field">
                                            *
                                        </span>
                                    )}
                                    :
                                </div>
                                <AddCredentialsGetInputField
                                    field={field}
                                    errors={errors}
                                    register={register}
                                />
                            </div>
                        ))}
                        <h3 className="add-credentials__curators-header">
                            Контактные данные (кураторы)
                        </h3>
                        {contacts.map((contact, index) => (
                            <AddPartnerContact
                                key={index}
                                contact={contact}
                                setContact={(value: PartnerContactWithError) =>
                                    addContact(value, index)
                                }
                                deleteContact={() => deleteContact(index)}
                            />
                        ))}
                        <button type="button" onClick={addEmptyContactBtnHandler}>
                            Добавить
                        </button>
                        <div className="add-credentials__btn">
                            <button>{btnName} </button>
                        </div>
                    </div>
                </StyledScrollbars>
            </StyledFormWrapper>
        </CommonModal>
    );
};

export default AddCredentials;
