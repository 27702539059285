import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../../store';
import { updateCompany } from '../../../store/slices/clientDatabaseSlice';
import { selectClientDatabase } from '../../../store/slices/clientDatabaseSlice';
import { getDateAndTimeFromIsoString } from '../../../utils/functions';
import CommonModal from '../CommonModal/CommonModal';
import DefaultLoader from '../BtnLoader/DefaultLoader';
import StyledForm from './AddNextContactDate.style';

type AddNextContactDateProps = {
    close: () => void;
    width: number;
    height: number;
    clientId: number;
};

const AddNextContactDate = ({ close, height, width, clientId }: AddNextContactDateProps) => {
    const dispatch = useAppDispatch();
    const { loading, areCompaniesUpdated } = useSelector(selectClientDatabase);
    const [lastContactDate, setLastContactDate] = useState('');
    const [lastContactTime, setLastContactTime] = useState('');
    const [isDateAdded, setDateAdded] = useState(false);

    const handleDateChange = (value: string) => {
        setLastContactDate(value);
    };

    const handleTimeChange = (value: string) => {
        setLastContactTime(value);
    };

    const addNewContactDate = () => {
        if (!(lastContactDate && lastContactTime)) return;

        const contactDate = new Date(`${lastContactDate} ${lastContactTime}`).toISOString();
        dispatch(
            updateCompany({
                contactDate,
                clientId,
            }),
        );
    };
    const minDate = getDateAndTimeFromIsoString(new Date().toISOString()).date;

    useEffect(() => {
        if (loading.updateCompany) {
            setDateAdded(true);
            return;
        }

        if (isDateAdded && areCompaniesUpdated) {
            close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading.updateCompany]);

    useEffect(() => {
        const today = new Date().toISOString();
        const { date: contactDate, time } = getDateAndTimeFromIsoString(today);
        setLastContactDate(contactDate);
        setLastContactTime(time.slice(0, 5));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <CommonModal hasCloseBtn={true} close={close}>
            <StyledForm width={width} height={height}>
                <h3>Перенести компанию на дату</h3>
                <input
                    className="add-next-contact-date__input"
                    type="date"
                    min={minDate}
                    value={lastContactDate}
                    onChange={(e) => handleDateChange(e.target.value)}
                />
                <input
                    className="add-next-contact-date__input"
                    type="time"
                    value={lastContactTime}
                    onChange={(e) => handleTimeChange(e.target.value)}
                />
                <button
                    className="add-next-contact-date__button"
                    type="button"
                    onClick={addNewContactDate}
                >
                    {loading.updateCompany ? <DefaultLoader /> : 'Сохранить'}
                </button>
            </StyledForm>
        </CommonModal>
    );
};

export default AddNextContactDate;
