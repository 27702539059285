import React from 'react';
import clsx from 'clsx';

type ClientRequestsTableHeadingsProps = {
    clientTasksTableColumns: string[];
};

const ClientRequestsTableHeadings = ({
    clientTasksTableColumns,
}: ClientRequestsTableHeadingsProps) => {
    return (
        <div className="client-requests-table__header">
            {clientTasksTableColumns.map((column, index) => (
                <div
                    key={column}
                    className={clsx({
                        'clients-requests-table__right-position': index >= 3,
                    })}
                >
                    <p>{column}</p>
                </div>
            ))}
        </div>
    );
};

export default ClientRequestsTableHeadings;
