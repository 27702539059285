import * as Yup from 'yup';

import { passwordRegExp } from '../utils/consts';
import { onlyNumChecking } from '../utils/functions';

const addUserBaseSchema = {
    fullName: Yup.string().required('Заполните поле имя'),
    shortName: Yup.string().required('Заполните поле сокращенное имя'),
    positionName: Yup.string().required('Заполните поле должность'),
    phone: Yup.string().required('Заполните поле телефон'),
    additNumber: Yup.string()
        .required('Заполните поле дополнительный номер')
        .max(4, 'Добавочный номер должен быть не более 4 символов')
        .test('num', 'Добавочный номер должен состоять только из цифр', onlyNumChecking),
    email: Yup.string()
        .required('Заполните поле email')
        .email('email заполнен некорректно')
        .max(255),
    birthDate: Yup.string().required('Заполните поле день рождения'),
    status: Yup.string().required('Заполните поле статус'),
    bot: Yup.boolean().required(),
    isAdmin: Yup.boolean().required(),
    login: Yup.string().required('Заполните поле логин'),
    passport: Yup.string().required('Заполните поле пасспорт'),
    snils: Yup.string().required('Заполните поле СНИЛС'),
    inn: Yup.string().required('Заполните поле ИНН'),
};

export const userCreationValidation = Yup.object().shape({
    ...addUserBaseSchema,
    password: Yup.string().test('pass', 'Пароль не соответветствует требованиям', (val) =>
        Boolean(val?.match(passwordRegExp) && val?.length > 9),
    ),
});

export const userUpdatingValidation = Yup.object().shape({
    ...addUserBaseSchema,
    password: Yup.string().test('pass', 'Пароль не соответветствует требованиям', (val) =>
        Boolean(!val || (val?.match(passwordRegExp) && val?.length > 9)),
    ),
});
