import React, { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

type ProtectedRouteProps = {
    isAllowed: boolean;
    redirectPath?: string;
};

//@ts-ignore
const ProtectedRoute: FC<ProtectedRouteProps> = ({ isAllowed, redirectPath = '/', children }) => {
    if (!isAllowed) {
        return <Navigate to={redirectPath} replace />;
    }

    return children ? children : <Outlet />;
};

export default ProtectedRoute;
