import React from 'react';

import { offerCommissionFieldsType } from '../../pages/ClientTaskPage/OffersForTaskTable/OffersForTaskTable';
import { formatNum } from '../../utils/functions';
import { OfferInterface } from '../../utils/types';

type GetCommissionProps = {
    commissionType: offerCommissionFieldsType;
    offer: OfferInterface | null;
};
const GetCommission = ({ commissionType, offer }: GetCommissionProps) => {
    if (!offer || offer[commissionType] === null || offer[`${commissionType}InRub`] === null)
        return <span>---</span>;

    const percent = Number(offer[commissionType]);
    const rubles = Number(offer[`${commissionType}InRub`]);

    if (Number.isNaN(percent) || Number.isNaN(rubles)) return <span>---</span>;

    const formatPercent = `${percent.toFixed(2)}%`;
    const formatRubles = formatNum(rubles.toFixed(2));

    return (
        <p>
            {formatRubles} <span>({formatPercent})</span>
        </p>
    );
};

export default GetCommission;
