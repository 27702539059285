import React from 'react';

import StyledCell from './ClosedRequestsProductFilter.style';
import CustomSelect from '../../../../components/Common/CustomSelect/CustomSelect';
import BulletListIcon from '../../../../assets/images/bullet-list-icon.png';
import { FilterParams } from '../ClientClosedRequests';
import { TaskProducts } from '../../../../utils/data';

type ClosedRequestsProductFilterProps = {
    filterParams: FilterParams;
    // eslint-disable-next-line no-unused-vars
    filterChangeHandler: (value: string, field: string) => void;
};

const ClosedRequestsProductFilter = ({
    filterParams,
    filterChangeHandler,
}: ClosedRequestsProductFilterProps) => {
    const productOptions = [{ name: '', label: 'Все' }, ...TaskProducts];
    return (
        <StyledCell>
            <p>Вид продукта:</p>
            <CustomSelect
                width={180}
                height={20}
                backgroundImage={BulletListIcon}
                backgroundColor="#f9f9f9"
            >
                <select
                    value={filterParams.type}
                    onChange={(e) => filterChangeHandler(e.target.value, 'type')}
                >
                    {productOptions.map((item) => (
                        <option key={item.label} value={item.name}>
                            {item.label}
                        </option>
                    ))}
                </select>
            </CustomSelect>
        </StyledCell>
    );
};

export default ClosedRequestsProductFilter;
