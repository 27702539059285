import React, { useState } from 'react';

import { getDate } from '../../../utils/functions';
import StyledTooltipReminder from './TooltipReminder.style';

type TooltipReminderProps = {
    time?: string;
    text: string | string[];
    hasReminder: boolean;
    children: React.ReactNode;
    maxWidth?: number;
    minWidth?: number;
    rightMargin?: number;
    leftMargin?: number;
    hasHtml?: boolean;
};
const TooltipReminder = ({
    time,
    text,
    hasReminder,
    children,
    maxWidth = 340,
    minWidth = 100,
    rightMargin = 0,
    leftMargin = 0,
    hasHtml,
}: TooltipReminderProps) => {
    const [showReminderTooltip, setShowReminderTooltip] = useState(false);

    const getText = (text: string) => {
        if (hasHtml) {
            return <div className="tooltip__html" dangerouslySetInnerHTML={{ __html: text }} />;
        }

        return <p>{text}</p>;
    };

    return (
        <StyledTooltipReminder
            rightMargin={rightMargin}
            leftMargin={leftMargin}
            maxWidth={maxWidth}
            minWidth={minWidth}
            onMouseOver={() => setShowReminderTooltip(true)}
            onMouseOut={() => setShowReminderTooltip(false)}
        >
            {hasReminder && showReminderTooltip ? (
                <div className="tooltip__container">
                    {time ? (
                        <p className="tooltip__date">{getDate(time, 'date with time')}</p>
                    ) : null}
                    {typeof text === 'string' ? (
                        getText(text)
                    ) : (
                        <ul>
                            {text.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    )}
                </div>
            ) : null}
            {children}
        </StyledTooltipReminder>
    );
};

export default TooltipReminder;
