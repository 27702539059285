import React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { activeRequestsTableColumns } from '../../../utils/data';

import { selectClientTask } from '../../../store/slices/clientTaskSlice';
import { userSelector } from '../../../store/slices/userSlice';
import StyledTable from './ActiveRequestsTable.style';
import ActiveRequestsTableRow from './ActiveRequestsTableRow/ActiveRequestsTableRow';
import EmptyList from '../../../components/EmptyList/EmptyList';

const RequestsTable = () => {
    const { clientTasks } = useSelector(selectClientTask);
    const { user } = useSelector(userSelector);
    const tableColumns = user?.isAdmin
        ? activeRequestsTableColumns
        : activeRequestsTableColumns.filter((column) => column.name !== 'manager');

    return (
        <StyledTable>
            <div
                className={clsx('active-requests-table__headings', {
                    'active-requests-table__manager-view': !user?.isAdmin,
                })}
            >
                {tableColumns.map((column, index) => (
                    <div
                        key={column.name}
                        className={clsx({
                            'active-requests-table__right-position': index > 5 && index < 8,
                        })}
                    >
                        <p className="active-requests-table__column">{column.label}</p>
                    </div>
                ))}
            </div>
            <div>
                {!clientTasks.length ? (
                    <EmptyList />
                ) : (
                    clientTasks.map((task, index) => (
                        <ActiveRequestsTableRow
                            key={task.taskId}
                            task={task}
                            user={user}
                            index={index}
                        />
                    ))
                )}
            </div>
        </StyledTable>
    );
};

export default RequestsTable;
