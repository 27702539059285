import { ResultCompany } from '../../store/slices/clientDatabaseSlice/types';
import { contactHighlightColorsNames, noteColorsNames, SectionNameTypes } from '../../utils/data';
import {
    CompanyInterface,
    DocumentCategoryInterface,
    ImportanceNameType,
    sortingFields,
    EmailWithAdditInfoType,
    PhoneWithAdditInfoType,
    IUser,
    ClientTaskInterface,
    PhoneWithError,
    EmailWithError,
    ContactPhoneNumberType,
} from '../../utils/types';
import { TableOrderType } from '../../pages/Clients/Filter/Filter.types';

/* eslint-disable */
export enum clientDatabaseApiPath {
    CRUD_COMPANY = '/company',
    CHECK_INN = '/company/check-inn',
    COMPANY_CONTACT = '/contact',
    COMPANY_COMMENT = '/comment',
    COMPANY_REMINDER = '/reminder',
    COMPANY_NOTE = '/note',
    COMPANY_DOCUMENTS = '/documents',
    DOWNLOAD_DOCUMENTS = '/documents/download',
    DOCUMENTS_CATEGORY = '/documents/category',
}
/* eslint-enable */

export type CreateCompanyPropsType = {
    name: string;
    regionName: string;
    city: string;
    addressLegal: string;
    addressActual: string;
    inn: string;
    ogrn: string;
    kpp: string;
    okved: string;
    headName: string;
    headPost: string;
    phone?: string;
    email?: string;
    comment?: string;
    registrationDate?: string;
    emails?: string[];
    phoneNumbers?: ContactPhoneNumberType[];
};

export type UpdateCompanyPropsType = {
    name?: string;
    regionName?: string;
    city?: string;
    addressLegal?: string;
    addressActual?: string;
    inn?: string;
    ogrn?: string;
    kpp?: string;
    okved?: string;
    headName?: string;
    headPost?: string;
    contactPhone?: string;
    email?: string;
    comment?: string;
    isMarked?: boolean;
    isFtsMarked?: boolean;
    isHighlighted?: boolean;
    managerId?: string;
    contactDate?: string;
    importance?: ImportanceNameType | null;
    clientId: number;
    registrationDate?: string;
    hasAutoReminder?: boolean;
};

export type GetCompanyPropsType = {
    companyId: number;
};

export type GetAllCompaniesPropsType = {
    contactDateFrom?: string;
    contactDateTo?: string;
    lastCommentDateFrom?: string;
    lastCommentDateTo?: string;
    region?: string;
    importance?: ImportanceNameType | null;
    hasImportance?: boolean;
    managerId?: number;
    isMarked?: boolean;
    isFtsMarked?: boolean;
    interests?: string[];
    isHighlighted?: boolean;
    hasReminder?: boolean;
    page: number;
    perPage: number;
    sortBy: sortingFields;
    order: TableOrderType;
    input?: string;
    isWithDealOnly?: boolean;
};

export type DeleteCompanyPropsType = {
    companyId: number;
};

export type RestoreCompanyPropsType = {
    companyId: number;
};

export type CheckInnPropsType = {
    inn: string;
};

export type CheckInnResult =
    | {
          data: ResultCompany;
          message: string;
      }
    | undefined;

export type GetCompanyResponseType =
    | {
          data: CompanyInterface;
          message: string;
      }
    | undefined;

export type GetAllCompaniesMeta = {
    page: number;
    perPage: number;
    totalPages: number;
    totalRecords: number;
};

export type GetAllCompaniesResponseType =
    | {
          message: string;
          data: CompanyInterface[];
          meta: GetAllCompaniesMeta;
      }
    | undefined;

export type CreateCompanyResponseType =
    | {
          data: IUser;
          message: string;
      }
    | undefined;

export type UpdateCompanyResponseType =
    | {
          data: IUser;
          message: string;
      }
    | undefined;

export type CreateCompanyContactType = {
    companyId: number;
    name?: string;
    phoneNumbers: PhoneWithAdditInfoType[];
    emails: EmailWithAdditInfoType[];
    isPriority?: boolean;
    color: contactHighlightColorsNames;
};

export type UpdateCompanyContactType = {
    companyId: number;
    name?: string;
    phoneNumbers: PhoneWithAdditInfoType[];
    emails: EmailWithAdditInfoType[];
    contactId: number;
    isPriority?: boolean;
    color?: contactHighlightColorsNames;
};

export type DeleteCompanyContactType = {
    companyId: number;
    contactId: number;
    hardDelete?: boolean;
};

export type CreateCompanyCommentType = {
    companyId: number;
    text: string;
};

export type UpdateCompanyCommentType = {
    commentId: number;
    companyId: number;
    text: string;
};

export type DeleteCompanyCommentType = {
    companyId: number;
    commentId: number;
    hardDelete?: boolean;
};

export type CreateCompanyReminderType = {
    text: string;
    time: string;
    companyId: number;
};

export type DeleteCompanyReminderType = {
    companyId: number;
    reminderId: number;
    hardDelete?: boolean;
};

export type CreateCompanyNoteType = {
    companyId: number;
    text: string;
    color: noteColorsNames;
};

export type UpdateCompanyNoteType = {
    noteId: number;
    companyId: number;
    text: string;
    color: noteColorsNames;
};

export type DeleteCompanyNoteType = {
    noteId: number;
    companyId: number;
    hardDelete?: boolean;
};

export type StatusResponseType = number | undefined;

export type GetAllCompanyDocumentsType = {
    companyId: number;
};

export type GetAllCompanyDocumentsResponse =
    | {
          message: string;
          data: {
              companyDocuments: DocumentCategoryInterface[];
              tasks: ClientTaskInterface[];
          };
      }
    | undefined;

export type CreateCompanyDocumentType = {
    companyId: number;
    postfix: string;
    categoryId: string;
    file: string | ArrayBuffer | null;
};

export type DeleteCompanyDocumentType = {
    companyId: number;
    companyDocumentId: number;
    hardDelete?: boolean;
};

export type DownloadCompanyDocumentType = {
    companyId: number;
    companyDocumentId: number;
    name?: string;
};

export type DownloadAllCompanyDocumentsType = {
    companyId: number;
};

export type CreateCompanyDocumentCategoryType = {
    name: string;
    section: SectionNameTypes;
    companyId: number;
    basicDocumentName: string;
};

export type UpdateCompanyDocumentCategoryType = {
    name: string;
    companyId: number;
    categoryId: number;
    basicDocumentName?: string;
};

export type DeleteCompanyDocumentCategoryType = {
    companyId: number;
    categoryId: number;
    hardDelete?: boolean;
};

export type DeleteResponseType = number | undefined;

export type DownloadResponseType = { data: string; message: string } | undefined;

export type GetAllCompanyComments = {
    manager?: string;
    date?: string;
};

export type GetExternalContactsPropsType = {
    companyId: number;
};

export type ExternalContactsType = {
    contactName: string;
    phones: ContactPhoneNumberType[];
    emails: string[];
};

export type GetExternalContactsType = {
    contactName: string;
    emails: EmailWithError[];
    phones: PhoneWithError[];
};

export type GetExternalContactsResponseType =
    | {
          data: ExternalContactsType;
          message: string;
      }
    | undefined;
