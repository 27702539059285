import React from 'react';
import clsx from 'clsx';

import ArrowIcon from '../../../../../assets/images/clients/arrow-on-black-square-icon.png';

type ClientRequestsTableHeaderProps = {
    header: string;
    activeTaskCount: number;
    closedTaskCount: number;
    isSectionOpen: boolean;
    // eslint-disable-next-line no-unused-vars
    onClickAddBankTask: (value: boolean) => void;
    toggleSectionOpenness: () => void;
};

const ClientRequestsTableHeader = ({
    header,
    isSectionOpen,
    activeTaskCount,
    closedTaskCount,
    onClickAddBankTask,
    toggleSectionOpenness,
}: ClientRequestsTableHeaderProps) => {
    return (
        <div className="client-requests__header client-requests__gray-background">
            <h3>{header}</h3>
            <div className="client-requests__add-request-btn-with-info">
                <button type="button" onClick={() => onClickAddBankTask(true)}>
                    Создать задачу+
                </button>
                <div
                    className={clsx('client-requests__show-section-btn', {
                        'client-requests__opened-section': isSectionOpen,
                    })}
                >
                    <p>
                        {activeTaskCount}/{closedTaskCount}
                    </p>
                    <img src={ArrowIcon} onClick={toggleSectionOpenness} />
                </div>
            </div>
        </div>
    );
};

export default ClientRequestsTableHeader;
