import styled from 'styled-components/macro';

const StyledWrapper = styled.div<{ width: number }>`
    width: ${({ width }) => `${width}px`};
    input {
        margin: 5px 0;
        width: 100%;
        height: 24px;
        border: 1px solid #000;
        border-radius: 5px;
        padding-left: 10px;
        ::-webkit-file-upload-button {
            cursor: pointer;
        }
    }

    .add-documents__header {
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 13px;
    }

    .add-documents__btn {
        border: none;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #0038ff;
        padding: 0;
        margin-top: 20px;
    }

    .add-documents__file-input {
        border: none;
        padding: 0;
        cursor: pointer;
    }
`;

export default StyledWrapper;
