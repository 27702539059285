import styled from 'styled-components/macro';

const StyledAuthWrapper = styled.div`
    .auth__heading {
        font-size: 32.8px;
        font-weight: 600;
        width: 100%;
        text-align: center;
        margin-top: 84px;
        margin-left: -120px;
    }

    .auth__submit-btn {
        cursor: pointer;
        margin-top: 20px;
        width: 160px;
        height: 40px;
    }

    .auth__form-group {
        width: 270px;
        margin-bottom: 15px;
    }
`;

const LoginFormWrapper = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 45%;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;

    .auth__form-group {
        display: flex;
        flex-direction: column;
        position: relative;

        label {
            font-size: 14px;
            display: grid;
            grid-template-columns: 1fr 200px;
            grid-column-gap: 10px;
            align-items: center;
        }
        input {
            border: 1px solid #959595;
            border-radius: 5px;
            padding: 6px 35px 6px 6px;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
        }

        .auth__show-password-icon {
            position: absolute;
            right: 8px;
            top: 8px;
            width: 21px;
            height: 13px;

            img {
                width: 21px;
                height: 13px;
            }
        }
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    button {
        background: #092e94;
        border: 1px solid #959595;
        border-radius: 5px;
        color: #fff;
    }
`;

export { StyledAuthWrapper, LoginFormWrapper };
