import styled from 'styled-components/macro';

const StyledPagination = styled.div`
    font-weight: 300;
    font-size: 12px;

    .pagination__header {
        padding: 5px 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #e7e7e7;
        min-height: 30px;
        flex-wrap: wrap;

        span {
            width: fit-content;
            margin-left: 5px;
            cursor: pointer;
        }
    }

    .pagination__selected-page {
        color: #0038ff;
        text-decoration: underline;
        cursor: auto;
    }
`;

export default StyledPagination;
