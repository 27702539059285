import styled from 'styled-components/macro';

const StyledWrapper = styled.div`
    font-weight: 300;
    font-size: 14px;

    p {
        word-break: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    button {
        border: none;
        background: transparent;
        color: #0038ff;
        text-decoration: underline;
        background: inherit;
        width: fit-content;
        padding: 5px 0;
    }

    img {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }

    .add-partner__header {
        display: flex;
        padding: 5px;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        background: #959595;
        margin-bottom: 10px;
        height: 40px;
    }

    .add-partner__actions {
        display: flex;
        align-items: center;

        p {
            color: #0038ff;
            text-decoration: underline;
            cursor: pointer;
            font-weight: 500;
        }

        img {
            margin-left: 13px;
        }
    }

    .add-partner__field {
        display: grid;
        grid-template-columns: 284px 1fr;
        align-items: center;
        margin-bottom: 5px;
        min-height: 25px;

        input {
            max-width: 334px;
            height: 24px;
            border-radius: 5px;
            border: 1px solid #959595;
            padding: 0 5px;
        }

        img {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }

        p {
            line-height: 17px;
            height: 17px;
        }
    }

    .add-partner__additional-info {
        height: 103px;
        border-radius: 5px;
        width: 90%;
        margin-top: 5px;
        padding: 10px;
    }

    .add-partner__sections {
        margin-top: 20px;
    }

    .add-partner__inactive-icon {
        cursor: auto !important;
    }
`;

export default StyledWrapper;
