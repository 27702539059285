import React from 'react';
import { FieldErrorsImpl, UseFormRegister } from 'react-hook-form';
import clsx from 'clsx';
import 'react-phone-input-2/lib/style.css';

import CustomSelect from '../../../Common/CustomSelect/CustomSelect';
import { FieldsInAddingCredentialsFormType } from '../../../../utils/data';
import { AddCredentialsInputs } from '../AddCredentials';
import BulletListIcon from '../../../../assets/images/bullet-list-icon.png';

type AddCredentialsDatasetFieldProps = {
    field: FieldsInAddingCredentialsFormType;
    errors: Partial<FieldErrorsImpl<AddCredentialsInputs>>;
    register: UseFormRegister<AddCredentialsInputs>;
};

const AddCredentialsDatasetField = ({
    field,
    errors,
    register,
}: AddCredentialsDatasetFieldProps) => {
    const getListByField = (field: string) => {
        switch (field) {
            case 'type':
                return ['Банковская гарантия', 'Кредит'];
            default:
                return [];
        }
    };
    const valueList = getListByField(field.fieldName);
    return (
        <CustomSelect width={395} height={24} backgroundImage={BulletListIcon}>
            <select
                className={clsx({
                    'add-user__error-input': errors[field.fieldName]?.message,
                })}
                id={field.fieldName}
                required={false}
                {...register(field.fieldName)}
            >
                {valueList.map((item, index) => (
                    <option key={item + index} value={item}>
                        {item}
                    </option>
                ))}
            </select>
        </CustomSelect>
    );
};

export default AddCredentialsDatasetField;
