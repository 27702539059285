import React from 'react';
import clsx from 'clsx';

import { TaskProductENUM, TaskProductTypes, TaskStatusTypes } from '../../../utils/types';
import StyledStatusLine from './StatusLine.style';
import { getTaskStatuses } from '../../../utils/functions';

type StatusLineProps = {
    status: TaskStatusTypes | null;
    type?: TaskProductTypes | null;
};

const StatusLine = ({ status, type = TaskProductENUM.BANK_GUARANTEE }: StatusLineProps) => {
    const statuses = getTaskStatuses(type);

    const statusIndex = statuses.findIndex((item) => item.name === status);

    return (
        <StyledStatusLine>
            {statuses.map((item, index) => (
                <div
                    key={item.name}
                    className={clsx('status-line__status', {
                        'status-line__passed-status': index <= statusIndex,
                    })}
                >
                    <p>{item.label}</p>
                </div>
            ))}
        </StyledStatusLine>
    );
};

export default StatusLine;
