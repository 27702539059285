import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { createTaskOffer, selectClientTask } from '../../../store/slices/clientTaskSlice';
import {
    BankProductDocument,
    BankProductInterface,
    PartnerInterface,
    TaskProductENUM,
} from '../../../utils/types';
import {
    AddBankToBankGuaranteeTaskFields,
    AddBankToLoanTaskFields,
    AddBankToTaskFieldsENUM,
    AddBankToTaskFieldsType,
    AddBankToTaskPercentFields,
} from '../../../utils/data';
import { useAppDispatch } from '../../../store';
import {
    downloadAsFile,
    formatTooltipString,
    getAmountInRub,
    getCredentialLkOperator,
    getGivenOutOffer,
    getProductTitle,
} from '../../../utils/functions';
import InfoIcon from '../../../assets/images/partners/additional-info-icon.png';
import DisabledInfoIcon from '../../../assets/images/partners/disabled-info-icon.png';
import PdfIcon from '../../../assets/images/partners/pdf-icon.png';
import DisabledPdfIcon from '../../../assets/images/partners/disabled-pdf-icon.png';
import { bankProductCategoryEnum } from '../../../api/partnerApi/partnerApi.types';
import { TermENUM } from '../../../utils/consts';
import TooltipAlt from '../../Common/TooltipAlt/TooltipAlt';

type TableWithBanksForTaskProps = {
    companyId: number;
    taskId: number;
    isOpen: boolean;
    bank: PartnerInterface;
    index: number;
    currentBankProductOffers: number[];
};

const TableWithBanksForTask = ({
    companyId,
    taskId,
    isOpen,
    bank,
    index,
    currentBankProductOffers,
}: TableWithBanksForTaskProps) => {
    const dispatch = useAppDispatch();
    const { loading, currentTask } = useSelector(selectClientTask);
    const givenOffer = getGivenOutOffer(currentTask?.offers);
    const givenOfferProductId = givenOffer?.bankProduct.bankProductId;
    const [unloadedFile, setUnloadedFile] = useState<null | BankProductDocument>(null);

    const isLoanTask = currentTask?.type === TaskProductENUM.LOAN;
    const AddBankToTaskFields = isLoanTask
        ? AddBankToLoanTaskFields
        : AddBankToBankGuaranteeTaskFields;
    const addOffer = (productId?: number) => {
        if (!productId) return;

        dispatch(createTaskOffer({ companyId, taskId, productId }));
    };

    const getFieldContent = (
        bank: PartnerInterface,
        product: BankProductInterface,
        field: AddBankToTaskFieldsType,
    ) => {
        const isLoanProduct = product.category === bankProductCategoryEnum.LOAN;
        const termUnit = isLoanProduct ? TermENUM.MONTHS_SHORT : TermENUM.DAYS;

        const credential = getCredentialLkOperator(bank.credentials, isLoanProduct);

        if (field === AddBankToTaskFieldsENUM.TYPE) {
            const title = getProductTitle(product.type || '');
            return title || '---';
        }

        if (field !== AddBankToTaskFieldsENUM.LK_OPERATOR) {
            if (!product || !product[field]) return '---';

            if (AddBankToTaskPercentFields.includes(field)) {
                const percent = Number(product[field]);
                return `${percent.toFixed(2)}%`;
            }

            if (field === AddBankToTaskFieldsENUM.LIMIT) {
                return `${getAmountInRub(product.limit)} р`;
            }

            if (field === AddBankToTaskFieldsENUM.TERM) {
                return `${getAmountInRub(product.term, 0)} ${termUnit}`;
            }

            return product[field];
        }

        return credential;
    };
    const successHandler = () => {
        setUnloadedFile(null);
    };

    const errorHandler = (doc: BankProductDocument) => {
        if (unloadedFile) return;
        setUnloadedFile(doc);
    };
    const downloadDocument = (doc: BankProductDocument | null) => {
        if (!doc) {
            return;
        }
        downloadAsFile(doc.filePath, doc.name, () => errorHandler(doc), successHandler);
    };

    const tooltipInfoId = `bank-product-info-tooltip-${currentTask?.type}-${index}`;
    return (
        <>
            {isOpen &&
                bank.products.map((product) => (
                    <div
                        key={product.bankProductId}
                        className={clsx('table-with-banks-for-task__bank-product', {
                            'table-with-banks-for-task__odd-field': index % 2 === 0,
                            'table-with-banks-for-task__green-field':
                                givenOfferProductId === product.bankProductId,
                            'table-with-banks-for-task__gray-field':
                                givenOffer && givenOfferProductId !== product.bankProductId,
                        })}
                    >
                        {AddBankToTaskFields.map((field) => (
                            <div
                                key={product.bankProductId + field}
                                className="table-with-banks-for-task__right-position"
                            >
                                <p>{getFieldContent(bank, product, field)}</p>
                            </div>
                        ))}
                        <div className="table-with-banks-for-task__actions-with-product">
                            <img
                                id={tooltipInfoId}
                                className="table-with-banks-for-task__add-info-icon"
                                src={product?.additInfo ? InfoIcon : DisabledInfoIcon}
                            />
                            {product?.additInfo && (
                                <TooltipAlt
                                    anchor={tooltipInfoId}
                                    content={formatTooltipString(product.additInfo)}
                                />
                            )}
                            <img
                                className={
                                    product?.documents.length
                                        ? 'table-with-banks-for-task__doc-icon '
                                        : 'table-with-banks-for-task__add-info-icon'
                                }
                                src={product?.documents.length ? PdfIcon : DisabledPdfIcon}
                                onClick={() => downloadDocument(product.documents[0])}
                            />
                            <button
                                className={clsx('table-with-banks-for-task__partner-card-btn', {
                                    'table-with-banks-for-task__disabled-btn':
                                        loading.createTaskOffer ||
                                        currentBankProductOffers.includes(product.bankProductId),
                                })}
                                type="button"
                                onClick={() => addOffer(product?.bankProductId)}
                                disabled={
                                    loading.createTaskOffer ||
                                    currentBankProductOffers.includes(product.bankProductId)
                                }
                            >
                                {currentBankProductOffers.includes(product.bankProductId)
                                    ? 'Добавлен'
                                    : 'Добавить'}
                            </button>
                        </div>
                    </div>
                ))}
        </>
    );
};

export default TableWithBanksForTask;
