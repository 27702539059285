import { ImportanceLabelType, ImportanceNameType } from './types';

/* eslint-disable */
export const enum Routes {
    LOGIN_ROUTE = '/login',
    ACTIVE_REQUESTS = '/active_requests',
    CLOSED_REQUESTS = '/closed_requests',
    CLIENTS = '/clients',
    STAFF = '/staff',
    CALLS = '/calls',
    PARTNERS = '/partners',
    STATS = '/stats',
    COMPANY_COMMENTS = '/stats/company_comments',
    REQUESTS_COMMENTS = '/stats/requests_comments',
    CASHBACK = '/stats/cashback',
    CARD_OF_CLIENT = '/clients/card/:companyId',
    CLIENT_TASK_PAGE = '/clients/:companyId/task/:taskId',
}
export const enum Roles {
    ADMIN = 'admin',
    MANAGER = 'manager',
}

export enum ClientRequestsTableHeadersENUM {
    BANK_GUARANTEE = 'Банковские гарантии (активные заявки)',
    LOAN = 'Кредитование (активные заявки)',
}

export enum ActiveRequestsFilterTitleENUM {
    PRODUCT_TYPE = 'Продукт',
    STATUS = 'Статус',
}

export enum ActiveRequestsFilterFieldENUM {
    TYPE = 'type',
    STATUS ='status',
};

export enum ClosedRequestsFilterFieldENUM {
    MANAGER = 'manager',
    EXECUTOR = 'executor',
    CLOSE_REASON = 'closeReason',
    FEDERAL_LAW = 'federalLaw',
    BANK_GUARANTEE_TYPE = 'bankGuaranteeType',
    CLOSE_DATE_FROM = 'closeDateFrom',
    CLOSE_DATE_TO = 'closeDateTo',
    TYPE = 'type',
    LK_OPERATOR = 'lkOperator',
}
type SearchParamsFieldNames =
    | 'manager'
    | 'executor'
    | 'closeReason'
    | 'federalLaw'
    | 'bankGuaranteeType'
    | 'closeDateFrom'
    | 'closeDateTo'
    | 'type'
    | 'lkOperator';
export enum TermENUM {
    MONTHS_SHORT = 'мес.', 
    DAYS = 'дней',
    DAYS_SHORT = 'д.',
};

export enum ButtonTitleENUM  {
    SAVE = 'Сохранить',
    CREATE_TASK = 'Создать задачу',
};

export enum AddBanksWindowTitleENUM {
    GUARANTEE_TASK_INFO = 'Информация о закупке',
    LOAN_TASK_INFO = 'Информация о кредите',
    NEW_BANK_GUARANTEE_TASK = 'Новая задача на выпуск Банковской гарантии',
    NEW_LOAN_TASK = 'Новая задача на получение кредита',
};

/* eslint-enable */

export const baseURL =
    process.env?.REACT_APP_BASE_API_URL || 'http://boomerang.fusion-tech.pro/api/v1';

export const clientURL = process.env?.REACT_APP_URL || 'https://crm.fsbg.online/';

export const importance: {
    name: ImportanceNameType;
    label: ImportanceLabelType;
    backgroundColor?: string;
}[] = [
    {
        name: 'all',
        label: 'Все',
    },
    {
        name: 'no',
        label: 'Отсутствует',
        backgroundColor: 'rgba(217, 217, 217, 0.2)',
    },
    {
        name: 'low',
        label: 'Низкая',
        backgroundColor: 'rgba(255, 0, 0, 0.2)',
    },
    {
        name: 'medium',
        label: 'Средняя',
        backgroundColor: 'rgba(255, 230, 0, 0.2)',
    },
    {
        name: 'high',
        label: 'Высокая',
        backgroundColor: 'gba(0, 255, 102, 0.2)',
    },
];

export const phoneRegExp = /(^\+\d\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}$)/;

export const emailRegExp = /.+@.+\..+/i;

export type TimeOptionsType = {
    timeZone: string;
    hour: 'numeric' | '2-digit';
    minute: 'numeric' | '2-digit';
    second: 'numeric' | '2-digit';
};

export const timeOptions: TimeOptionsType = {
    timeZone: 'Europe/Saratov',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
};

const specialSymbols = '~`!@#$%\\^&*()\\-_=+{}[\\]\\\\|;:\'"<>,.\\/?№';

export const passwordRegExp = new RegExp(
    `^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[${specialSymbols}])[A-Za-z\\d${specialSymbols}]{8,50}$`,
);

export const excelFileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
export const excelFileExtension = '.xlsx';

export const callsPageTexts = {
    callRecord: 'Запись разговора',
    noData: 'нет данных',
    didNotTakePlace: 'не состоялся',
    downloadError: 'Сбой при загрузке файла',
    download: 'Скачать',
};
