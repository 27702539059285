import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';

import { useAppDispatch } from '../../store';
import CommonModal from '../Common/CommonModal/CommonModal';
import DefaultLoader from '../Common/BtnLoader/DefaultLoader';
import StyledForm from './AddCallComment.style';
import { selectCalls, updateCall } from '../../store/slices/callsSlice';

type AddCallCommentProps = {
    close: () => void;
    width: number;
    callId: number;
    initialText: string;
};

const AddCallComment = ({ close, width, callId, initialText }: AddCallCommentProps) => {
    const dispatch = useAppDispatch();
    const firstUpdate = useRef(true);
    const { loading, isRequestFulfilled } = useSelector(selectCalls);
    const [comment, setComment] = useState(initialText);

    const addComment = () => {
        if (!comment) return toast.error('Заполните комментарий');

        dispatch(updateCall({ callId, comment }));
    };

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if (isRequestFulfilled.updateCall) {
            toast.success('Комментарий добавлен');
            close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRequestFulfilled.updateCall]);

    const onTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setComment(e.target.value.slice(0, 1000));
    };

    return (
        <CommonModal hasCloseBtn={true} close={close}>
            <StyledForm width={width}>
                <h3 className="add-call-comment__header">Добавить комментарий</h3>
                <textarea
                    className="add-call-comment__text"
                    value={comment}
                    onChange={onTextChange}
                />
                <div className="add-call-comment__counter">{comment.length}/1000</div>
                <button className="add-call-comment__button" type="button" onClick={addComment}>
                    {loading.updateCall ? <DefaultLoader /> : 'Добавить'}
                </button>
            </StyledForm>
        </CommonModal>
    );
};

export default AddCallComment;
