import styled from 'styled-components/macro';

const StyledClientInteractionWrapper = styled.div`
    overflow: hidden;
    .client-interaction__header {
        background: #959595;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 6px;

        h2 {
            font-weight: 500;
            font-size: 14px;
            color: #fff;
            width: fit-content;
        }

        img {
            cursor: pointer;
        }

        a {
            text-decoration: underline;
            color: #0038ff;
        }
    }

    .client-interaction__nav-tab-container {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-column-gap: 3px;
        height: 30px;
    }

    .client-interaction__nav-tab {
        width: 100%;
        background: #f9f9f9;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.03em;
        color: #000;

        &[disabled] {
            cursor: auto;
            opacity: 0.6;
        }
    }

    .client-interaction__selected-nav-tab {
        background: #fdd55b;

        p {
            font-weight: 500;
        }
    }

    .client-interaction__spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 100px;
    }
`;

export default StyledClientInteractionWrapper;
