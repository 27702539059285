import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { companyActsColumns } from '../../../../utils/data';
import { downloadAsFile, formatNum, getDate } from '../../../../utils/functions';
import { ActDocTypes, ActInterface } from '../../../../utils/types';
import {
    clearCurrentActDoc,
    deleteAct,
    getActFile,
    getAllActs,
    selectClientAct,
    uploadSigningAct,
} from '../../../../store/slices/actSlice';
import { useAppDispatch } from '../../../../store';
import { selectClientCard } from '../../../../store/slices/clientCardSlice';
import PdfIcon from '../../../../assets/images/acts/act-pdf-icon.png';
import DocIcon from '../../../../assets/images/acts/act-doc-icon.png';
import CloseIcon from '../../../../assets/images/partners/close-window-icon.png';
import ConfirmModal from '../../../../components/Common/ConfirmModal/ConfirmModal';
import AddFile from '../../../../components/AddFile/AddFile';
import StyledTable from './ActsTable.style';
import { userSelector } from '../../../../store/slices/userSlice';

const ActsTable = () => {
    const dispatch = useAppDispatch();
    const firstUpdate = useRef(true);
    const { clientActs, currentActDoc, loading, isRequestFulfilled } = useSelector(selectClientAct);
    const { company } = useSelector(selectClientCard);
    const [selectedAct, setSelectedAct] = useState<null | ActInterface>(null);
    const [selectedActType, setSelectedActType] = useState<ActDocTypes>('docx');
    const [unloadedAct, setUnloadedAct] = useState<null | ActInterface>(null);
    const [isAddFileModalOpen, setAddFileModalOpen] = useState(false);
    const [deletingAct, setDeletingAct] = useState<null | ActInterface>(null);
    const [unloadedSigningDoc, setUnloadedSigningDoc] = useState<null | ActInterface>(null);
    const { user } = useSelector(userSelector);

    const successHandler = () => {
        setUnloadedAct(null);
        setSelectedAct(null);
        dispatch(clearCurrentActDoc());
    };

    const errorHandler = (act: ActInterface) => {
        if (!company || unloadedAct) return;

        setUnloadedAct(act);
        dispatch(
            getActFile({
                actId: act.actId,
                companyId: company.companyId,
                type: selectedActType,
            }),
        );
    };

    const downloadDocument = (filePath: string, act: ActInterface) => {
        if (!selectedAct) return;

        downloadAsFile(
            filePath,
            `Акт № ${selectedAct.actId + 309}`,
            () => errorHandler(act),
            successHandler,
        );
    };

    const docClickHandler = (act: ActInterface, type: ActDocTypes) => {
        if (!company) return;

        setSelectedActType(type);
        setSelectedAct(act);
        dispatch(getActFile({ actId: act.actId, companyId: company.companyId, type }));
    };

    const uploadHandler = (act: ActInterface) => {
        if (!company) return;

        setAddFileModalOpen(true);
        setSelectedAct(act);
    };

    const closeAddFileModal = () => {
        setAddFileModalOpen(false);
        setSelectedAct(null);
    };

    const deleteActHandler = () => {
        if (!company || !deletingAct) return;

        dispatch(deleteAct({ companyId: company.companyId, actId: deletingAct.actId }));
    };

    const fileSelectionHandler = (name: string, file: string) => {
        if (!company || !selectedAct) return;

        dispatch(
            uploadSigningAct({
                name,
                file,
                companyId: company.companyId,
                actId: selectedAct.actId,
            }),
        );
    };

    const SigningDocSuccessHandler = () => {
        setUnloadedSigningDoc(null);
    };

    const SigningDocErrorHandler = (act: ActInterface) => {
        if (!company || unloadedSigningDoc) return;

        setUnloadedSigningDoc(act);
        dispatch(
            getAllActs({
                companyId: company.companyId,
            }),
        );
    };

    const downloadSigningDoc = (act: ActInterface) => {
        if (!act.document) return;

        downloadAsFile(
            act.document.filePath,
            act.document.name,
            () => SigningDocErrorHandler(act),
            SigningDocSuccessHandler,
        );
    };

    useEffect(() => {
        if (!company) return;

        dispatch(getAllActs({ companyId: company.companyId }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company]);

    useEffect(() => {
        if (!currentActDoc || !selectedAct) return;

        downloadDocument(currentActDoc, selectedAct);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentActDoc, selectedAct]);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if (isRequestFulfilled.deleteAct) {
            setDeletingAct(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRequestFulfilled.deleteAct]);

    useEffect(() => {
        if (!unloadedSigningDoc || !clientActs) return;

        const actForDownload = clientActs.find((act) => act.actId === unloadedSigningDoc.actId);

        if (!actForDownload) return;

        downloadSigningDoc(actForDownload);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientActs]);

    return (
        <StyledTable>
            <div className="acts-table__headings">
                {companyActsColumns.map((column, index) => (
                    <div
                        key={column.name}
                        className={clsx('acts-table__column', {
                            'acts-table__right-position-cell': index > 2,
                        })}
                    >
                        <p>{column.label}</p>
                    </div>
                ))}
            </div>
            <div>
                {clientActs.map((act, index) => (
                    <div
                        key={act.actId}
                        className={clsx('acts-table__row', {
                            'acts-table__odd-row': index % 2 === 0,
                        })}
                    >
                        <p>{getDate(act.createdAt, 'date with time') || '---'}</p>
                        <p>{act.actId ? act.actId + 309 : '---'}</p>
                        <p>
                            {getDate(act.dateFrom, 'date')} - {getDate(act.dateTo, 'date')}
                        </p>
                        <div className="acts-table__right-position-cell">
                            <p>{formatNum((act.totalCashbackAmount || 0).toFixed(2))}</p>
                        </div>
                        <div className="acts-table__right-position-cell">
                            <p>{formatNum(String(act.cashbacks.length || 0))}</p>
                        </div>
                        <div className="acts-table__icons">
                            <img src={DocIcon} onClick={() => docClickHandler(act, 'docx')} />
                            <img src={PdfIcon} onClick={() => docClickHandler(act, 'pdf')} />
                        </div>
                        <div className="acts-table__sign">
                            <div className="acts-table__signing-doc">
                                <p onClick={() => downloadSigningDoc(act)}>
                                    {act.document?.name || ''}
                                </p>
                            </div>
                            <div className="acts-table__signing-doc">
                                <p onClick={() => uploadHandler(act)}>
                                    {act.document ? 'Заменить' : '---'}
                                </p>
                            </div>
                        </div>
                        <p className="acts-table__receiver">{act.receiver.name || '---'}</p>
                        {user?.isAdmin ? (
                            <div className="acts-table__close-icon">
                                <img src={CloseIcon} onClick={() => setDeletingAct(act)} />
                            </div>
                        ) : null}
                    </div>
                ))}
            </div>
            {isAddFileModalOpen && (
                <AddFile
                    close={closeAddFileModal}
                    width={400}
                    setFile={fileSelectionHandler}
                    title="Добавить документы"
                />
            )}
            {deletingAct ? (
                <ConfirmModal
                    close={() => setDeletingAct(null)}
                    confirmAction={deleteActHandler}
                    width={280}
                    height={120}
                    headText="Вы уверены, что хотите удалить акт?"
                    loading={loading.deleteAct}
                />
            ) : null}
        </StyledTable>
    );
};

export default ActsTable;
