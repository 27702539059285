import React, { useEffect, useState } from 'react';

type ClientTimeProps = {
    timezone: number;
};

const saratovTimezone = 4;

const ClientTime = ({ timezone }: ClientTimeProps) => {
    const [time, setTime] = useState('');
    const calcTime = () => {
        const now = new Date();
        const utc = now.getTime() + now.getTimezoneOffset() * 60000;
        const localTime = new Date(utc + 3600000 * timezone);
        let normalizedTime = localTime.toLocaleString();
        const dividerIndex = normalizedTime.indexOf(' ');
        normalizedTime = normalizedTime.slice(dividerIndex + 1, dividerIndex + 6);
        setTime(normalizedTime);
    };

    const offset = timezone - saratovTimezone;
    const offsetDescription = offset ? `(${offset > 0 ? '+' : ''}${offset})` : '';

    useEffect(() => {
        calcTime();
        const intervalId = setInterval(calcTime, 20000);
        return () => {
            clearInterval(intervalId);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timezone]);

    return <p>{`${time} ${offsetDescription}`}</p>;
};

export default ClientTime;
