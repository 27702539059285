import React from 'react';

import StyledCell from './ClosedRequestsProductFilter.style';
import CustomSelect from '../../../components/Common/CustomSelect/CustomSelect';
import BulletListIcon from '../../../assets/images/bullet-list-icon.png';
import { TaskProducts } from '../../../utils/data';

type ClosedRequestsProductFilterProps = {
    filterValue: string;
    // eslint-disable-next-line no-unused-vars
    filterChangeHandler: (value: string, field: string) => void;
    disabledFilters?: boolean;
};

const ClosedRequestsProductFilter = ({
    filterValue,
    filterChangeHandler,
    disabledFilters,
}: ClosedRequestsProductFilterProps) => {
    const productOptions = [{ name: '', label: 'Все' }, ...TaskProducts];
    return (
        <StyledCell>
            <p>Продукт</p>
            <CustomSelect
                width={262}
                height={20}
                backgroundImage={BulletListIcon}
                backgroundColor="#f9f9f9"
            >
                <select
                    disabled={disabledFilters}
                    value={filterValue}
                    onChange={(e) => filterChangeHandler(e.target.value, 'type')}
                >
                    {productOptions.map((item) => (
                        <option key={item.label} value={item.name}>
                            {item.label}
                        </option>
                    ))}
                </select>
            </CustomSelect>
        </StyledCell>
    );
};

export default ClosedRequestsProductFilter;
