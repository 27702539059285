import styled from 'styled-components/macro';

const StyledProductContainer = styled.div`
    font-weight: 300;
    min-height: 33px;
    font-size: 14px;
    padding: 5px 10px;
    display: grid;
    align-items: center;
    grid-template-columns:
        225px minmax(120px, 2fr) repeat(2, 80px) repeat(3, 80px) 60px 70px repeat(2, 80px) 40px
        repeat(2, 80px) 100px;
    grid-column-gap: 10px;

    img {
        width: 20px;
        height: 20px;
    }

    .bank-product__actions {
        display: flex;
        justify-content: end;
        align-items: center;

        img {
            margin-right: 12px;
        }
    }

    .bank-product__table-cell {
        display: flex;
        justify-content: right;
        align-items: center;
    }

    .bank-product__first-cell {
        display: flex;
        align-items: center;

        img {
            margin-right: 5px;
            cursor: auto;
        }
    }

    .bank-product__not-active-img {
        cursor: auto;
    }

    .bank-product__disabled-actions {
        justify-content: end;
    }

    .bank-product-name {
        cursor: pointer;
    }

    .bank-product__disabled-name {
        color: #c4c4c4;
        cursor: auto;
    }

    .bank-product__table-cell {
        width: 100%;
        text-align: right;
    }

    .bank-product__red-cell {
        color: #f00;
    }
`;

export default StyledProductContainer;
