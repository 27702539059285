import { Roles, Routes } from './utils/consts';
import Auth from './pages/Auth/Auth';
import Cashback from './pages/Cashback/Cashback';
import ActiveRequests from './pages/ActiveRequests/ActiveRequests';
import ClosedRequests from './pages/ClosedRequests/ClosedRequests';
import Staff from './pages/Staff/Staff';
import Partners from './pages/Partners/Partners';
import Clients from './pages/Clients/Clients';
import ClientInteraction from './pages/ClientInteraction/ClientInteraction';
import ClientTaskPage from './pages/ClientTaskPage/ClientTaskPage';

import ActiveRequestsIcon from './assets/images/navbar/active-requests-icon.png';
import ClosedRequestsIcon from './assets/images/navbar/closed-requests-icon.png';
import PartnersIcon from './assets/images/navbar/partners-icon.png';
import ClientsIcon from './assets/images/navbar/clients-icon.png';
import StaffIcon from './assets/images/navbar/staff-icon.png';
import StatsIcon from './assets/images/navbar/stats-icon.png';
import CallsIcon from './assets/images/navbar/calls-icon.png';

import ActiveRequestsIconSelected from './assets/images/navbar/active-requests-selected-icon.png';
import ClosedRequestsIconSelected from './assets/images/navbar/closed-requests-selected-icon.png';
import PartnersIconSelected from './assets/images/navbar/partners-selected-icon.png';
import ClientsIconSelected from './assets/images/navbar/clients-selected-icon.png';
import StaffIconSelected from './assets/images/navbar/staff-selected-icon.png';
import StatsIconSelected from './assets/images/navbar/stats-selected-icon.png';
import CallsIconSelected from './assets/images/navbar/calls-selected-icon.png';
import { AuthRoutesType } from './utils/types';
import CompanyComments from './pages/CompanyComments/CompanyComments';
import RequestComments from './pages/RequestComments/RequestComments';
import Calls from './pages/Calls/Calls';

export const authRoutes: AuthRoutesType[] = [
    {
        name: 'Активные заявки',
        link: Routes.ACTIVE_REQUESTS,
        Component: ActiveRequests,
        role: [Roles.ADMIN, Roles.MANAGER],
        icon: {
            selected: ActiveRequestsIconSelected,
            notSelected: ActiveRequestsIcon,
        },
    },
    {
        name: 'Завершенные заявки',
        link: Routes.CLOSED_REQUESTS,
        Component: ClosedRequests,
        role: [Roles.ADMIN, Roles.MANAGER],
        icon: {
            selected: ClosedRequestsIconSelected,
            notSelected: ClosedRequestsIcon,
        },
    },
    {
        name: 'База клиентов',
        link: Routes.CLIENTS,
        Component: Clients,
        role: [Roles.ADMIN, Roles.MANAGER],
        icon: {
            selected: ClientsIconSelected,
            notSelected: ClientsIcon,
        },
    },
    {
        name: 'Партнеры сервиса',
        link: Routes.PARTNERS,
        Component: Partners,
        role: [Roles.ADMIN, Roles.MANAGER],
        icon: {
            selected: PartnersIconSelected,
            notSelected: PartnersIcon,
        },
    },
    {
        name: 'Сотрудники',
        link: Routes.STAFF,
        Component: Staff,
        role: [Roles.ADMIN, Roles.MANAGER],
        icon: {
            selected: StaffIconSelected,
            notSelected: StaffIcon,
        },
    },
    {
        name: 'Статистика',
        link: Routes.STATS,
        Component: Cashback,
        role: [Roles.ADMIN, Roles.MANAGER],
        icon: {
            selected: StatsIconSelected,
            notSelected: StatsIcon,
        },
        children: [
            {
                name: 'Комментарии к компаниям',
                link: Routes.COMPANY_COMMENTS,
                role: [Roles.ADMIN, Roles.MANAGER],
                Component: CompanyComments,
            },
            {
                name: 'Комментарии к заявкам',
                link: Routes.REQUESTS_COMMENTS,
                role: [Roles.ADMIN, Roles.MANAGER],
                Component: RequestComments,
            },
            {
                name: 'Кэшбэк',
                link: Routes.CASHBACK,
                role: [Roles.ADMIN, Roles.MANAGER],
                Component: Cashback,
            },
        ],
    },
    {
        name: 'Запись разговора',
        link: Routes.CALLS,
        Component: Calls,
        role: [Roles.ADMIN, Roles.MANAGER],
        icon: {
            selected: CallsIconSelected,
            notSelected: CallsIcon,
        },
    },
];

export const clientsDatabaseRoutes = [
    {
        name: 'client card',
        link: Routes.CARD_OF_CLIENT,
        Component: ClientInteraction,
        role: [Roles.ADMIN, Roles.MANAGER],
    },
    {
        name: 'client task',
        link: Routes.CLIENT_TASK_PAGE,
        Component: ClientTaskPage,
        role: [Roles.ADMIN, Roles.MANAGER],
    },
];

export const publicRoutes = [
    {
        path: Routes.LOGIN_ROUTE,
        Component: Auth,
    },
];
