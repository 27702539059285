import { ActDocTypes, ActInterface, ReceiverInterface } from '../../utils/types';

/* eslint-disable */
export enum actApiPath {
    CRUD_ACT = '/act',
    ACT_DOC = '/document',
    GET_ACT_FILE = '/file',
    CRUD_RECEIVER= '/receiver',
}
/* eslint-enable */

export type CreateActPropsType = {
    cashbackIds: number[];
    dateFrom: string;
    dateTo: string;
    receiverId: number;
    companyId: number;
};

export type GetActFilePropsType = {
    companyId: number;
    actId: number;
    type: ActDocTypes;
};

export type GetAllActsPropsType = {
    companyId: number;
};

export type GetAllActsResponseType =
    | {
          data: {
              actsList: ActInterface[];
              total: {
                  records: number;
                  amount: number;
              };
          };
          message: string;
      }
    | undefined;

export type GetActFileResponseType =
    | {
          data: string;
          message: string;
      }
    | undefined;

export type CreateActReceiverPropsType = {
    headPost: string;
    name: string;
    inn: string;
    headName: string;
    companyId: number;
};

export type GetCompanyReceiversResponseType =
    | {
          data: ReceiverInterface[];
          message: string;
      }
    | undefined;

export type UpdateActReceiverPropsType = {
    headPost?: string;
    name?: string;
    inn?: string;
    headName?: string;
    companyId: number;
    companyReceiverId: number;
};

export type uploadSigningActProps = {
    name: string;
    file: string;
    actId: number;
    companyId: number;
};

export type DeleteActPropsType = {
    hardDelete?: boolean;
    companyId: number;
    actId: number;
};
