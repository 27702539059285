import styled from 'styled-components/macro';
import MagnifierIcon from '../../../assets/images/magnifier-icon.png';

const StyledForm = styled.div<{ width: number; height: number; background: string }>`
    color: #000;
    display: flex;
    padding: 0 2px;
    border-radius: 5px;
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    background-color: ${({ background }) => `${background}`};
    input[type='search'] {
        border: none;
        width: ${({ width }) => `${width - 30}px`};
        background: transparent;
        margin: 0;
        padding: 2px 8px;
        font-weight: 300;
        font-size: 12px;
        color: inherit;
        border-radius: inherit;
        outline: none;
        &::-webkit-search-cancel-button {
            -webkit-appearance: none;
        }
    }

    button {
        overflow: hidden;
        width: 30px;
        height: ${({ height }) => `${height}px`};
        padding: 0;
        margin: 0;
        border: none;
        border-radius: inherit;
        background: transparent url(${MagnifierIcon}) no-repeat center;
        cursor: pointer;
        opacity: 0.7;
    }

    button:hover {
        opacity: 1;
    }
`;

export default StyledForm;
