import React, { useEffect, useState } from 'react';

import { ReminderInterface } from '../../utils/types';
import { getCompany } from '../../store/slices/clientCardSlice';
import { useAppDispatch } from '../../store';
import CommonModal from '../Common/CommonModal/CommonModal';
import ReminderItem from './ReminderItem/ReminderItem';
import AddReminder from '../AddReminder/AddReminder';
import StyledForm from './RemindersList.style';

type RemindersListProps = {
    close: () => void;
    width: number;
    companyId: number;
    reminders: ReminderInterface[];
    taskId?: number;
};

const RemindersList = ({ close, width, companyId, reminders, taskId }: RemindersListProps) => {
    const [isAddReminderOpen, setAddReminderOpen] = useState(false);
    const dispatch = useAppDispatch();

    const getReminderId = (reminder: ReminderInterface) => {
        let id = 0;
        if ('companyReminderId' in reminder) {
            id = reminder.companyReminderId;
        } else if ('taskReminderId' in reminder) {
            id = reminder.taskReminderId;
        }
        return id;
    };

    useEffect(() => {
        dispatch(getCompany({ companyId }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <CommonModal hasCloseBtn={true} close={close} header="Напоминания">
            <StyledForm width={width}>
                {reminders.length ? (
                    reminders.map((reminder) => (
                        <ReminderItem
                            key={getReminderId(reminder)}
                            reminderId={getReminderId(reminder)}
                            companyId={companyId}
                            reminder={reminder}
                            taskId={taskId}
                        />
                    ))
                ) : (
                    <p className="reminder-list__no-reminders">Напоминаний нет</p>
                )}
                <button
                    className="reminder-list__button"
                    type="button"
                    onClick={() => setAddReminderOpen(true)}
                >
                    Добавить напоминание
                </button>
            </StyledForm>
            {isAddReminderOpen && (
                <AddReminder
                    close={() => setAddReminderOpen(false)}
                    width={450}
                    companyId={companyId}
                    taskId={taskId}
                />
            )}
        </CommonModal>
    );
};

export default RemindersList;
