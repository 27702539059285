import React from 'react';

type AddProductTitleProps = {
    productTypeTitle: string;
    productTitle: string;
    isLoan: boolean;
};

const AddProductTitle = ({ productTypeTitle, productTitle, isLoan }: AddProductTitleProps) => {
    if (isLoan) {
        return (
            <h3 className="add-product__title">
                {productTypeTitle} {productTitle}
            </h3>
        );
    }
    return (
        <h3 className="add-product__title">
            {productTypeTitle} <span>- {productTitle}</span>
        </h3>
    );
};

export default AddProductTitle;
