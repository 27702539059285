import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';

import { getError, isResponseOK } from '../../../utils/functions';
import { RootState } from '../..';
import { InitialStateType } from './types';
import { CashbackInterface } from '../../../utils/types';
import { StatusResponseType } from '../../../api/clientDatabaseApi/clientDatabaseApi.types';
import { companyCashbackApi } from '../../../api/companyCashbackApi/companyCashbackApi';
import {
    GetCashbackPropsType,
    UpdateCashbackPropsType,
} from '../../../api/companyCashbackApi/companyCashbackApi.types';

export const getAllCompanyCashback = createAsyncThunk<
    {
        cashback: CashbackInterface[];
        records: number;
        amount: number;
    },
    GetCashbackPropsType,
    {
        rejectValue: string;
    }
>('clientTask/getAllCompanyCashback', async ({ ...props }, { rejectWithValue }) => {
    try {
        const res = await companyCashbackApi.getCashback({ ...props });
        if (res?.message === 'Success') {
            const { records, amount } = res.data.total;
            return {
                cashback: res.data.cashbackList,
                records,
                amount,
            };
        } else {
            throw res?.data;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const updateCompanyCashback = createAsyncThunk<
    StatusResponseType,
    UpdateCashbackPropsType,
    {
        rejectValue: string;
    }
>('clientTask/updateCompanyCashback', async ({ ...props }, { rejectWithValue }) => {
    try {
        const res = await companyCashbackApi.updateCashback({ ...props });
        if (isResponseOK(res)) {
            return res;
        } else {
            throw res;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

const initialState: InitialStateType = {
    companyCashbackList: [],
    sortBy: 'transactionDate',
    order: 'ASC',
    totalAmount: 0,
    records: 0,
    loading: {
        getCashback: false,
        updateCashback: false,
    },
    isRequestFulfilled: {
        updateCashback: false,
    },
};

export const companyCashbackSlice = createSlice({
    name: 'companyCashback',
    initialState,
    reducers: {
        clearClientTaskState: (state) => {
            state = initialState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllCompanyCashback.pending, (state) => {
            state.loading.getCashback = true;
        });
        builder.addCase(getAllCompanyCashback.fulfilled, (state, { payload }) => {
            state.companyCashbackList = payload.cashback;
            state.totalAmount = payload.amount;
            state.records = payload.records;
            state.loading.getCashback = false;
        });
        builder.addCase(getAllCompanyCashback.rejected, (state) => {
            state.loading.getCashback = false;
        });
        builder.addCase(updateCompanyCashback.pending, (state) => {
            state.loading.updateCashback = true;
            state.isRequestFulfilled.updateCashback = false;
        });
        builder.addCase(updateCompanyCashback.fulfilled, (state) => {
            state.loading.updateCashback = false;
            state.isRequestFulfilled.updateCashback = true;
            toast.success('Изменения сохранены');
        });
        builder.addCase(updateCompanyCashback.rejected, (state, { payload }) => {
            state.loading.updateCashback = false;
            if (typeof payload !== 'string') return;
            toast.error(payload);
        });
    },
});

export const { clearClientTaskState } = companyCashbackSlice.actions;

export const selectCompanyCashback = (state: RootState) => state.companyCashback;
