import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectClientCard } from '../../../../store/slices/clientCardSlice';
import AddBankTask from '../../../../components/AddBankTask/AddBankTask';
import ClientRequestsTable from './ClientRequestsTable/ClientRequestsTable';
import StyledClientRequests from './ClientRequests.style';
import {
    ClientTasksCountersType,
    ClientTasksType,
    TaskProductENUM,
    TaskProductTypes,
} from '../../../../utils/types';
import ClientRequestsTableHeader from './ClientRequestsTableHeader/ClientRequestsTableHeader';
import {
    clientBankGuaranteeTasksTableColumns,
    clientLoanTasksTableColumns,
} from '../../../../utils/data';
import { ClientRequestsTableHeadersENUM } from '../../../../utils/consts';
import { decomposeClientTasks } from '../../../../utils/functions';

const ClientRequests = () => {
    const { company } = useSelector(selectClientCard);
    const [isBankGuaranteeSectionOpen, setBankGuaranteeSectionOpen] = useState(true);
    const [isLoanSectionOpen, setLoanSectionOpen] = useState(true);
    const [isAddBankTaskModalOpen, setAddBankTaskModalOpen] = useState(false);
    const [clientTasks, setClientTasks] = useState<ClientTasksType>({
        loanTasks: [],
        bankGuaranteeTasks: [],
    });
    const [clientTaskCounters, setClientTaskCounters] = useState<ClientTasksCountersType>({
        activeLoanTasks: 0,
        activeBankGuaranteeTasks: 0,
        closedLoanTasks: 0,
        closedBankGuaranteeTasks: 0,
    });
    const [clientTaskProductType, setClientTaskProductType] = useState<TaskProductTypes>(
        TaskProductENUM.BANK_GUARANTEE,
    );
    useEffect(() => {
        if (!company || !company.tasks) {
            return;
        }
        const tasksData = decomposeClientTasks(company.tasks);
        setClientTasks(tasksData.tasks);
        setClientTaskCounters(tasksData.counters);
    }, [company]);

    const toggleBankGuaranteeSectionOpenness = () => {
        setBankGuaranteeSectionOpen((prev) => !prev);
    };

    const toggleLoanSectionOpenness = () => {
        setLoanSectionOpen((prev) => !prev);
    };

    const onClickAddBankTask = (taskType: TaskProductTypes) => {
        setClientTaskProductType(taskType);
        setAddBankTaskModalOpen(true);
    };

    return (
        <StyledClientRequests>
            <div className="client-requests__header">
                <h3>Текущие задачи по клиенту</h3>
            </div>
            <ClientRequestsTableHeader
                header={ClientRequestsTableHeadersENUM.BANK_GUARANTEE}
                activeTaskCount={clientTaskCounters.activeBankGuaranteeTasks}
                closedTaskCount={clientTaskCounters.closedBankGuaranteeTasks}
                isSectionOpen={isBankGuaranteeSectionOpen}
                onClickAddBankTask={() => onClickAddBankTask(TaskProductENUM.BANK_GUARANTEE)}
                toggleSectionOpenness={toggleBankGuaranteeSectionOpenness}
            />
            {isBankGuaranteeSectionOpen && company ? (
                <ClientRequestsTable
                    type={TaskProductENUM.BANK_GUARANTEE}
                    clientTasksTableColumns={clientBankGuaranteeTasksTableColumns}
                    tasks={clientTasks.bankGuaranteeTasks}
                    companyId={company.companyId}
                />
            ) : null}
            <ClientRequestsTableHeader
                header={ClientRequestsTableHeadersENUM.LOAN}
                activeTaskCount={clientTaskCounters.activeLoanTasks}
                closedTaskCount={clientTaskCounters.closedLoanTasks}
                isSectionOpen={isLoanSectionOpen}
                onClickAddBankTask={() => onClickAddBankTask(TaskProductENUM.LOAN)}
                toggleSectionOpenness={toggleLoanSectionOpenness}
            />
            {isLoanSectionOpen && company ? (
                <ClientRequestsTable
                    type={TaskProductENUM.LOAN}
                    clientTasksTableColumns={clientLoanTasksTableColumns}
                    tasks={clientTasks.loanTasks}
                    companyId={company.companyId}
                />
            ) : null}
            {isAddBankTaskModalOpen && company?.companyId ? (
                <AddBankTask
                    width={468}
                    companyId={company.companyId}
                    clientTaskProductType={clientTaskProductType}
                    close={() => setAddBankTaskModalOpen(false)}
                    task={null}
                />
            ) : null}
        </StyledClientRequests>
    );
};

export default ClientRequests;
