import React from 'react';
import clsx from 'clsx';

import {
    bankGuaranteeTypesInAddPartnerPage,
    federalLawsInAddPartnerPage,
    loanTypesInAddTaskPage,
    numericFieldsInAddTask,
} from '../../../utils/data';
import { getUserName } from '../../../utils/functions';
import CustomSelect from '../../Common/CustomSelect/CustomSelect';
import BulletListIcon from '../../../assets/images/bullet-list-icon.png';
import {
    AddBankFieldsENUM,
    AddBankTaskInputProps,
    AddBankTaskSelectFields,
} from './AddBankTaskInput.types';
import { FederalLawsTypeENUM, TaskGuaranteeProductENUM } from '../../../utils/types';

const AddBankTaskInput = ({
    task,
    field,
    errors,
    isIncludingEndDate,
    federalLaw,
    allUsers,
    register,
    setIncludingEndDate,
    changNumericFieldHandler,
    dateChangeHandler,
}: AddBankTaskInputProps) => {
    const getDateFieldName = (index: number) => {
        return index
            ? AddBankFieldsENUM.BANK_GUARANTEE_TERM_TO
            : AddBankFieldsENUM.BANK_GUARANTEE_TERM_FROM;
    };

    const getBankGuaranteeTypes = () => {
        const bankGuaranteeTypes = [...bankGuaranteeTypesInAddPartnerPage];

        if (federalLaw === FederalLawsTypeENUM.COMMERCE) {
            const applicationSecurityIdx = bankGuaranteeTypes.findIndex(
                ({ name }) => name === TaskGuaranteeProductENUM.APPLICATION_SECURITY,
            );

            if (applicationSecurityIdx !== -1) {
                bankGuaranteeTypes.splice(applicationSecurityIdx, 1);
            }
        }

        return bankGuaranteeTypes;
    };
    const getListByField = (field: AddBankTaskSelectFields) => {
        switch (field) {
            case AddBankFieldsENUM.EXECUTOR_ID:
                return allUsers;
            case AddBankFieldsENUM.FEDERAL_LAW:
                return federalLawsInAddPartnerPage;
            case AddBankFieldsENUM.BANK_GUARANTEE_TYPE:
                return getBankGuaranteeTypes();
            case AddBankFieldsENUM.LOAN_TYPE:
                return loanTypesInAddTaskPage;
            default:
                return [];
        }
    };
    switch (field.type) {
        case 'input':
            return !numericFieldsInAddTask.includes(field.fieldName) ? (
                <input
                    className={clsx({
                        'add-bank-guarantee__error-input': errors[field.fieldName]?.message,
                    })}
                    id={field.fieldName}
                    required={false}
                    {...register(field.fieldName)}
                />
            ) : (
                <input
                    className={clsx({
                        'add-bank-guarantee__error-input': errors[field.fieldName]?.message,
                    })}
                    id={field.fieldName}
                    required={false}
                    {...register(field.fieldName)}
                    onChange={(e) => changNumericFieldHandler(e.target.value, field.fieldName)}
                />
            );
        case 'date':
            return field.fieldName !== AddBankFieldsENUM.CONTRACT_CONCLUSION_DATE ? (
                <>
                    <div className="add-bank-guarantee__date-period">
                        <div className="add-bank-guarantee__date-picker">
                            <p>дней</p>
                            <input
                                className={clsx({
                                    'add-bank-guarantee__error-input':
                                        errors[field.fieldName]?.message,
                                })}
                                id={field.fieldName}
                                required={false}
                                {...register(field.fieldName)}
                                onChange={(e) => dateChangeHandler(e.target.value)}
                            />
                        </div>
                        {Array.from(Array(2).keys()).map((key) => (
                            <div key={key} className="add-bank-guarantee__date-picker">
                                <p>{key ? 'по' : 'c'}</p>
                                <input
                                    className={clsx({
                                        'add-bank-guarantee__error-input':
                                            errors[getDateFieldName(key)]?.message,
                                    })}
                                    id={getDateFieldName(key)}
                                    type="date"
                                    required={false}
                                    {...register(getDateFieldName(key))}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="add-bank-guarantee__checkbox">
                        Включая конечную дату:
                        <input
                            checked={isIncludingEndDate}
                            type="checkbox"
                            onChange={(e) => setIncludingEndDate(e.target.checked)}
                        />
                    </div>
                </>
            ) : (
                <div className="add-bank-guarantee__conclusion-date">
                    <input
                        className={clsx({
                            'add-bank-guarantee__error-input': errors[field.fieldName]?.message,
                        })}
                        id={field.fieldName}
                        type="date"
                        required={false}
                        {...register(field.fieldName)}
                    />
                </div>
            );
        case 'dataset':
            return (
                <CustomSelect width={451} height={24} backgroundImage={BulletListIcon}>
                    <select
                        className={clsx({
                            'add-bank-guarantee__error-input': errors[field.fieldName]?.message,
                        })}
                        disabled={
                            field.fieldName === AddBankFieldsENUM.BANK_GUARANTEE_TYPE && !federalLaw
                        }
                        {...register(field.fieldName)}
                    >
                        <option selected disabled hidden></option>
                        {getListByField(field.fieldName as AddBankTaskSelectFields).map((item) => (
                            <option
                                key={'name' in item ? item.name : item?.userId}
                                value={'name' in item ? item.name : item?.userId}
                            >
                                {'label' in item ? item.label : getUserName(item)}
                            </option>
                        ))}
                    </select>
                </CustomSelect>
            );
        case 'textarea':
            return (
                <textarea
                    className={clsx('add-bank-guarantee__comment', {
                        'add-bank-guarantee__error-input': errors[field.fieldName]?.message,
                        'add-bank-guarantee__short-comment': task,
                    })}
                    id={field.fieldName}
                    required={false}
                    {...register(field.fieldName)}
                />
            );
        default:
            return null;
    }
};

export default AddBankTaskInput;
