import React from 'react';
import { useSelector } from 'react-redux';

import { selectStaff } from '../../../store/slices/staffSlice';
import { getManagersList } from '../../../utils/functions';
import CustomSelect from '../../../components/Common/CustomSelect/CustomSelect';
import BulletListIcon from '../../../assets/images/bullet-list-icon.png';
import StyledFilterWrapper from './ManagerAndExecutorFilter.style';

export type userTypesInFilter = 'manager' | 'executor';

type filterParamsType = {
    manager: string;
    executor: string;
};

const filters: {
    user: userTypesInFilter;
    label: string;
}[] = [
    { user: 'manager', label: 'Менеджер' },
    { user: 'executor', label: 'Исполнитель' },
];

type FilterProps = {
    // eslint-disable-next-line no-unused-vars
    filterChangeHandler: (value: string, field: userTypesInFilter) => void;
    filterParams: filterParamsType;
    disabledValue?: string;
    disabledFilter?: 'manager' | 'executor' | null;
    disabledAllFilters?: boolean;
};

const Filter = ({
    filterChangeHandler,
    filterParams,
    disabledValue,
    disabledFilter,
    disabledAllFilters,
}: FilterProps) => {
    const { allUsers } = useSelector(selectStaff);
    const managers = [{ fullName: 'Все', userId: 0 }, ...getManagersList(allUsers)];

    return (
        <StyledFilterWrapper>
            {filters.map((filter) => (
                <div key={filter.user} className="filter__executor-container">
                    <p>{filter.label}</p>{' '}
                    <CustomSelect
                        width={262}
                        height={20}
                        backgroundImage={BulletListIcon}
                        backgroundColor="#f9f9f9"
                    >
                        <select
                            disabled={disabledFilter === filter.user || disabledAllFilters}
                            value={filterParams[filter.user]}
                            onChange={(e) => filterChangeHandler(e.target.value, filter.user)}
                        >
                            {managers.map((item) => (
                                <option key={item.userId + filter.user} value={item.userId}>
                                    {disabledFilter === filter.user ? disabledValue : item.fullName}
                                </option>
                            ))}
                        </select>
                    </CustomSelect>
                </div>
            ))}
        </StyledFilterWrapper>
    );
};

export default Filter;
