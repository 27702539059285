import styled from 'styled-components/macro';

const StyledTable = styled.div`
    font-weight: 300;
    font-size: 12px;
    margin-bottom: 10px;

    .calls-table__column {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-weight: 600;

        img {
            width: 12px;
            height: 12px;
            margin-left: 5px;
        }
    }

    .calls-table__headings,
    .calls-table__row {
        display: grid;
        grid-template-columns:
            minmax(50px, 100px) minmax(70px, 160px) minmax(140px, 200px) minmax(160px, 1fr)
            minmax(100px, 180px) minmax(120px, 180px) minmax(70px, 120px) minmax(100px, 180px) 60px;
        grid-column-gap: 10px;
        padding: 5px 7px;
        align-items: center;
        a {
            width: fit-content;
            min-width: 20px;
        }
    }

    .calls-table__tab-row {
        grid-template-columns:
            minmax(50px, 100px) minmax(70px, 160px) minmax(140px, 200px) minmax(100px, 180px)
            minmax(70px, 120px) minmax(100px, 180px) 60px;
    }

    .calls-table__rows {
        padding-bottom: 96px;
    }

    .calls-table__headings {
        background: #e5e5e5;
    }

    .calls-table__link {
        color: #0038ff;
        text-decoration: underline;
        cursor: pointer;
    }

    .calls-table__disabled-link {
        cursor: auto;
    }

    .calls-table__loading {
        color: #d9d9d9;
    }

    .calls-table__even-row {
        background: #f5f5f5;
    }

    .calls-table__loader {
        text-align: center;
        margin: 50px;
    }

    .calls-table__phone {
        display: flex;
        align-items: center;
        img {
            margin-right: 5px;
        }
    }

    .calls-table__duration {
        width: fit-content;
    }

    .calls-table__no-data {
        width: max-content;
        height: 15px;
        border-radius: 1px;
        background-color: #d9d9d9;
        padding: 0 5px;
        vertical-align: middle;
    }

    .calls-table__management {
        display: flex;
        .tooltip__container {
            overflow-y: hidden;
        }
    }

    .calls-table__button {
        background: transparent;
        border: none;
        margin: 0 5px;
        padding: 0px;
        height: 20px;
        width: 20px;
        align-content: center;
    }
    .calls-table__company {
        p {
            margin-top: 4px;
            font-size: 10px;
            line-height: 10px;
        }
    }
    .calls-table__duration {
        grid-column: 7;
    }
    .calls-table__tab-duration {
        grid-column: 5;
    }
    .calls-table__amount {
        grid-column: 9;
    }
    .calls-table__tab-amount {
        grid-column: 7;
    }
`;

export default StyledTable;
