import React from 'react';

import StyledNews from './News.style';

const Header = () => (
    <StyledNews>
        <p>новости сервиса:</p>
        <div className="news_block">
            <span>{'читать все новости >'}</span>
        </div>
    </StyledNews>
);

export default Header;
