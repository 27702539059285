import styled from 'styled-components';

export const StyledInterestingsPicker = styled.div<{ gap: number }>`
    display: flex;
    gap: ${({ gap }) => gap}px;
    align-items: center;
`;

export const StyledInterestingsBlock = styled.div<{ isActive?: boolean; isCredit?: boolean }>`
    background: ${({ isActive, isCredit }) =>
        isActive ? (isCredit ? '#FF0000' : '#FFCA00') : '#e2e5e7'};
    color: ${({ isActive, isCredit }) => (isCredit ? 'white' : isActive ? 'black' : 'white')};
    padding: 3px 7px;
    height: 20px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 900;
    font-size: 9.5px;
    line-height: 13.3px;
`;
